<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import PlayersTable from './components/PlayersTable.vue';
  import { ref } from 'vue';
  import PlayersSidebar from './PlayersSidebar.vue';

  const { t } = useLocaleStore();
  const search = ref<string>('');
</script>

<template>
  <LayoutPage
    :header="t('players.header')"
    always-absolute
    :default-search="e => search = e"
  >
    <PlayersTable :search="search" />

    <template #side-bar>
      <PlayersSidebar />
    </template>
  </LayoutPage>
</template>