<script setup lang='ts'>
  import { CheckDto } from '@/api/backend/court/check/check.dto';

  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import CheckVerdictDetails from './CheckVerdictDetails.vue';
  import CheckVerdictProofs from './CheckVerdictProofs.vue';
  import CheckVerdictMass from './CheckVerdictMass.vue';
  import CheckVerdictComment from './CheckVerdictComment.vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { computed } from 'vue';
  import Divider from '@/components/Divider.vue';

  const props = defineProps<{
    check: CheckDto | undefined,
    bans: BanDto[] | undefined
  }>();

  const proofs = computed(() => {
    if (!props.bans?.length || !props.check) {
      return [];
    }
    // todo: переделать на suspect_Steam_id, которого пока нет
    const mainBan = props.bans.find(v => v.steam_id === props.check?.suspect.steam_id);
    
    return mainBan?.proofs;
  });
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="verdict-header">
      <PlayerBox
        size="large"
        style="max-width: 70%; width: 270px;"
        :player="check?.suspect ?? null"
        disable-highlight
      />
    </div>

    <div v-if="check" class="verdict-content">
      <CheckVerdictDetails :check="check" />

      <template v-if="proofs?.length">
        <Divider dashed />

        <CheckVerdictProofs :proofs="proofs" />
      </template>

      <template v-if="check.comment">
        <Divider dashed />

        <CheckVerdictComment :comment="check.comment" />
      </template>
      
      <template v-if="check.status === 'FinishedBan'">
        <Divider dashed />

        <CheckVerdictMass :bans="bans" />
      </template>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.verdict-header {
  @apply p-5;
  @apply border-b border-grey-1000;
}
.verdict-content {
  @apply p-5;
  @apply flex flex-col gap-5;
  .divider {
    @apply w-full;
    height: 1px;
    @apply bg-grey-850;
  }
}
</style>