<script setup lang="ts">
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { PlayerAllStatsDto } from '@/api/backend/court/player/player.dto';
  import Loading from '@/components/Loading.vue';
  import BarChartBase from '@/components/quality/chart/BarChartBase.vue';
  import DonutChartBase from '@/components/quality/chart/DonutChartBase.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import dayjs from 'dayjs';
  import { AxiosError } from 'axios';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import BadVersionTip from '@panel/components/BadVersionTip.vue';
  import BaseTip from '@panel/components/BaseTip.vue';

  const { t } = useLocaleStore();
  const _court = useCourtStore();

  const props = defineProps<{ steamId: string; offset: number }>();

  const data = ref<PlayerAllStatsDto>();

  onMounted(async () => updateData());

  watch(() => props.offset, () => updateData());

  const updateData = async () => {
    try {
      data.value = undefined;

      const result = await useCourtApi().player.getStats(props.steamId, +dayjs().subtract(props.offset, 'days').startOf('day'), +dayjs().endOf('day'));

      data.value = result;
    }
    catch (err) {
      if ((err as AxiosError).message?.includes('доступна начиная с тарифа')){
        const stats: Record<string, any> = {};

        for (let i = 0; i < 12; i++) {
          stats[(Date.now() - i * 24 * 60 * 60 * 1_000).toString()] = {
            date        : Date.now() - i * 24 * 60 * 60 * 1_000,
            hours_played: Math.random() * 16
          };
        }

        data.value = {
          steam: {
            total_hours: 500
          },
          reports: {
            total_outcome: 300,
          },
          kills: {
            kills     : 150,
            deathes   : 125,
            bone_kills: {
              head : 15,
              torso: 50,
              limbs: 50
            },
            kda: +(150 / 125).toFixed(2)
          },
          playTime: {
            total: 521,
            stats: stats
          }
        };
      
        return;
      }

      throw err;
    }
  };

  const playTimeData = computed(() => {
    if (!data.value) {
      return null;
    }

    const vals = Object.values(data.value.playTime.stats).map((v) => ({ x: +v.date, y: +v.hours_played }));
  

    if (props.offset == 365) {
      const byMonthes: Record<number, number> = {};

      vals.forEach(v => {
        const monthStart = dayjs(v.x).startOf('month').add(dayjs().utcOffset(), 'minute');

      
        byMonthes[+monthStart] = (byMonthes[+monthStart] ?? 0) + v.y;
      });

      return Object.entries(byMonthes).map(v => ({ x: +v[0], y: +v[1]}));
    }

    return vals;
  });
  
</script>

<template>
  <Loading :can-view="!!data" class="!bg-transparent" />

  <CourtNoSubscriptionContent
    v-if="data"
    type="PlayerStatistics"
    color="bg-grey-900/80"
  >
    <BadVersionTip version="1.10.2" class="mb-2" />

    <div class="stats">
      <div class="stats-cards">
        <div class="ra-card">
          <p class="title">{{ 'K/D' }}</p>
          <p class="value">{{ data.kills.kda.toFixed(2) ?? '—' }}</p>
        </div>
        <div class="ra-card">
          <p class="title">{{ t('modal.player.general.steam.hours') }}</p>
          <p class="value">{{ data.steam.total_hours ?? t('general.unknown') }}</p>
        </div>
        <div class="ra-card">
          <p class="title">{{ t('player-stats-general-hours-on-server') }}</p>
          <p class="value">{{ t('player-stats-general-hours-on-server-value', {h: data.playTime.total.toFixed(0) ?? '—'}) }}</p>
        </div>
        <div class="ra-card">
          <p class="title">{{ t('player-stats-general-reports') }}</p>
          <p class="value">{{ data.reports.total_outcome ?? '—' }}</p>
        </div>
      </div>

      <div class="stats-cards graph">
        <div class="ra-card big">
          <div class="flex justify-between">
            <div class="row">
              <div class="col">
                <p class="title">
                  <span class="text-[#2A9D90]">•</span>
                  {{ t('player-stats-general-kills') }}
                </p>
                <p class="value">{{ data.kills.kills ?? '0' }}</p>
              </div>
              <div class="col">
                <p class="title">
                  <span class="text-[#E76E50]">•</span>
                  {{ t('player-stats-general-deaths') }}
                </p>
                <p class="value">{{ data.kills.deathes ?? '0' }}</p>
              </div>
            </div>
            <DonutChartBase
              style="max-height: 140px"
              :series="[{ name: t('player-stats-general-kills'), value: data.kills.kills, color: '#2A9D90'}, { name: t('player-stats-general-deaths'), value: data.kills.deathes, color: '#E76E50'}]"
            />
          </div>
        </div>
        <div class="ra-card big">
          <div class="flex justify-between">
            <div class="row">
              <div class="col">
                <p class="title">
                  <span class="text-[#E76E50]">•</span>
                  {{ t('player-stats-general-in-head') }}
                </p>
                <p class="value">{{ data.kills.bone_kills['head'] ?? '0' }}</p>
              </div>
              <div class="col">
                <p class="title">
                  <span class="text-[#E8C468]">•</span>
                  {{ t('player-stats-general-in-torso') }}
                </p>
                <p class="value">{{ data.kills.bone_kills['torso'] ?? '0' }}</p>
              </div>
              <div class="col">
                <p class="title">
                  <span class="text-[#2A9D90]">•</span>
                  {{ t('player-stats-general-in-limb') }}
                </p>
                <p class="value">{{ data.kills.bone_kills['limbs'] ?? '0' }}</p>
              </div>
            </div>
            <DonutChartBase
              style="max-height: 140px"
              :series="[{ name: t('player-stats-general-in-head'), value: data.kills.bone_kills['head'] || 0, color: '#E76E50'}, { name: t('player-stats-general-in-torso'), value: data.kills.bone_kills['torso'] || 0, color: '#E8C468'}, { name: t('player-stats-general-in-limb'), value: data.kills.bone_kills['limbs'] || 0, color: '#2A9D90'}]"
            />
          </div>
        </div>
      </div>

      <BaseTip
        type="warning"
        disposable="basetip-graph-hourse-played"
      >
        {{ t('basetip-graph-hourse-played') }}
      </BaseTip>

      <template
        v-if="playTimeData && props.offset > 1"
      >
        <BarChartBase
          :title="t('player-stats-general-graph-hourse-played')"
          class="h-[220px]"
          :mode="props.offset !== 365 ? 'days' : 'monthes'"
          :series="[{name: t('general.hours'), data: playTimeData, color: '#E9536F'} ]"
        />
      </template>
      <template v-else>
        <div class="h-[220px] w-full ra-card flex justify-center items-center">
          <EmptyImage
            :title="props.offset == 1 ? t('player-stats-general-graph-nodata-title') : t('player-stats-general-graph-nodata')"
            :subtitle="props.offset == 1 ? t('player-stats-general-graph-nodata-1d') : t('player-stats-general-graph-nodata-no')"
          />
        </div>
      </template>
    </div>
  </CourtNoSubscriptionContent>
</template>

<style lang="scss" scoped>
.stats {
  @apply flex flex-col gap-2.5;

  .stats-cards {
    @apply grid grid-cols-4 gap-2.5;

    &.graph {
      @apply grid-cols-2;
    }

    .ra-card {
      @apply w-full;

      .row {
        @apply grid grid-rows-3 gap-2 justify-between;
      }

      &.big {
        .value {
          @apply text-xl;
        }
      }
    }
  }
}

[device='mobile'] {
  .stats-cards {
    @apply grid-cols-2;

    &.graph {
      @apply grid-cols-1;
    }
  }
}
</style>
