<script setup lang="ts">
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Loader from '../Loader.vue';

  const props = defineProps<{
    mobile: boolean,
    active?: boolean,
    badge?: number,
    preset?: 'destructive'
    noArrow?: boolean
  }>();

  const isBadgeVisible = () => {
    const val = props.badge;
    if (!val) {
      return false;
    }

    if (val == null || val == 0 || val == -1) {
      return false;
    }

    return true;
  };
</script>

<template>
  <div
    class="menu-item"
    :class="{ '-active': active, '-mobile': mobile, [`-${preset}`]:true }"
  >
    <slot />
    <div class="ml-auto flex items-center gap-2.5">
      <div v-if="isBadgeVisible()" class="badge">
        <template v-if="badge == -1">
          <Loader size="12px" />
        </template>
        <template v-else-if="(badge ?? 0) > 0">
          {{ badge }}
        </template>
      </div>
      <Svg.chevron_right v-if="mobile && !noArrow" class="arrow" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  @apply cursor-pointer select-none;
  @apply flex gap-2 items-center;
  @apply fill-grey-400;
  @apply text-grey-400 font-medium;
  @apply p-1.5;
  @apply rounded-md;

  :slotted(svg) {
    @apply shrink-0;
    @apply w-[18px];
  }

  .badge {
    @apply h-5 min-w-5 px-1 text-center leading-[20px] rounded-md;
    @apply bg-grey-750;
    @apply text-grey-400;
    @apply text-12-500;
    @apply relative flex items-center justify-center;
    animation: fadeIn 0.1s ease-in-out;
  }

  &:hover {
    @apply text-grey-300;
    @apply fill-grey-300;
  }

  &.-active {
    @apply bg-grey-750;
    @apply text-grey-50;
    @apply fill-grey-50;
  }

  &.-mobile {
    @apply rounded-none;
    @apply overflow-hidden;
    @apply bg-grey-850;
    @apply p-3 gap-2.5;
    @apply fill-grey-400;
    @apply text-grey-400;
    @apply text-18-500;
    @apply border-b border-grey-800;

    :slotted(svg) {
      @apply w-[20px];
    } 

    &:first-child {
      @apply rounded-t-md;
    }

    &:last-child {
      @apply rounded-b-md;
      @apply border-none;
    }

    &.-active {
      @apply bg-grey-850;
    }

    &.-destructive {
      @apply text-red-500;
      @apply fill-red-500;
    }

    .badge {
      @apply text-14-500;
    }

    .arrow {
      @apply w-[20px] fill-grey-600;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>