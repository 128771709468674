<script lang="ts">
  export type CheckboxInputProps = {
    text?: string,
    circle?: boolean, 
    disabled?: boolean,
    
    checked?: boolean;
  }
</script>

<script setup lang='ts'>
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const props = withDefaults(defineProps<CheckboxInputProps>(), {
    checked: undefined
  });

  const emits = defineEmits(['onChecked', 'onUnchecked']);
  const modelValue = defineModel<boolean>({ default: undefined });

  const click = () => {
    if (props.checked) {
      emits('onUnchecked');
    } else {
      emits('onChecked');
    }
  };
</script>

<template>
  <label
    class="checkbox-container"
    :class="{circle, 'opacity-30 pointer-events-none grayscale-[0.9]': disabled }"
    @click="click"
  >{{ text }}
    <input
      v-model="modelValue"
      :disabled="checked !== undefined"
      type="checkbox"
      :checked="checked"
    >
    <span class="checkbox-mark">
      <Svg.checkmark class="icon" />
    </span>
  </label>
</template>

<style lang='scss' scoped>
.checkbox-container {
  @apply relative;
  @apply cursor-pointer;
  @apply flex;
  padding-left: 24px;
  user-select: none;
  @apply text-grey-350;
  
  // ховер на всю строку
  &:hover {
    .checkbox-mark {
      @apply border-primary-700;
    }
  }

  // input всегда скрыт
  input {
    @apply absolute;
    @apply opacity-0;
    @apply h-0 w-0;

    // стили, когда checked
    &:checked + .checkbox-mark {
      background-color: #2196F3;
      @apply border-primary-700;
      .icon {
        visibility: visible;
      }
    }
  }


  // стили для псевдо инпута
  .checkbox-mark {
    @apply absolute left-0;
    top: 2px;
    @apply w-4 h-4;
    @apply border-grey-600;
    border-width: 1.5px;
    border-radius: 4px;
    @apply bg-grey-850;
    

    .icon {
      @apply absolute left-1/2 top-1/2;
      @apply -translate-y-1/2 -translate-x-1/2;
      width: 16px;
      @apply fill-grey-50;
      visibility: hidden;
    }
  }

  // стили для круглого чекбокса
  &.circle {

    &:hover {
      .checkbox-mark {
        @apply ring-primary-700;
      }
    }

    // стили для круглого маркера, черный через бордер, синий через ринг
    .checkbox-mark {
      @apply ring-1.5 ring-grey-600;
      @apply rounded-half;
      @apply bg-grey-850;
      @apply border-grey-850;
      border-width: 2px;
      width: 13px;
      height: 13px;
      margin-left: 1.5px;
      
      .icon {
        visibility: hidden !important;
      }
    }

    input {
      &:checked + .checkbox-mark {
        @apply bg-primary-700 ring-primary-700;
        @apply border-grey-850;
      }
    }
  }
}
</style>