<script setup lang="ts">
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { computed, onMounted, ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ServerFullDto, ServerPairDto } from '@/api/backend/court/servers/servers.dto';
  import DividedCard from '@/components/DividedCard.vue';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { Svg } from '@src/assets/auto_gen_types.dto'; 
  import AssetImage from '@/components/quality/AssetImage.vue';
  import { usePluginMenu } from '@court/menus/plugin.menu';
  import Loader from '@/components/Loader.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();
  const _panel = usePanelStore();

  const props = defineProps<{ server?: ServerFullDto, pairData?: ServerPairDto }>();

  const copyCommand = computed(() => {
    if (!props.pairData) {
      return ``;
    }

    return `ra.pair ${props.pairData.code}`;
  });

  const defaultAvatarUrl = 'https://i.imgur.com/PtUV9hj.jpg'; 
  const avatarCopy = ref<string | undefined>(defaultAvatarUrl);

  onMounted(() => {
    avatarCopy.value = props.server?.avatar_url;
  });
</script>

<template>
  <DividedCard>
    <template #header>
      <div class="command-header">
        <div class="project-connect">
          <template v-if="server">
            <template v-if="server.avatar_url">
              <AvatarExtended
                class="avatar-box"
                :url="avatarCopy"
                active
                @error="avatarCopy = defaultAvatarUrl"
              />
            </template>
            <template v-else>
              <AssetImage icon="rustlogo_dirty.jpg" class="avatar-box bg-grey-850" />
            </template>
          </template>
          <template v-else>
            <AssetImage icon="rustlogo_dirty.jpg" class="avatar-box bg-grey-850" />
          </template>

          <Svg.pair class="w-6 h-6 animate-pulse" />

          <AvatarExtended
            class="avatar-box"
            :url="_panel.project?.avatar ? useConfigStore().Urls.Images(_panel.project.avatar) : ''"
            active
          />
        </div>
        <span class="text-lg font-medium text-center mt-2.5">{{ server ? t('modal.server.connect.reconnect.title') : t('modal.server.connect.title') }}</span>
        <p class="text-center text-grey-400 font-normal">
          {{ server ? server.name : t('modal.server.connect.subtitle', { project: _panel.project?.name}) }}
        </p>
      </div>
    </template>

    <template #default>
      <div class="step">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="step-text" v-html="t('modal.server.connect.download')" />
        <div class="command" @click="(e) => usePluginMenu(e)">
          <div class="flex flex-row items-center gap-2">
            <Svg.file class="w-5 h-5 fill-grey-200" />
            <span> {{ `RustApp.cs` }} </span>
          </div>
          <Svg.download class="icon-box" />
        </div>
      </div>

      <div class="step pt-5">
        <div class="step-text">
          {{ t('modal.server.connect.pair') }}
        </div>
        <div class="command" @click="useCopyClipboard(copyCommand, true)">
          <div class="flex flex-row items-center gap-2">
            <span> {{ copyCommand }} </span>
          </div>
          <Svg.copy class="icon-box" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="wait">
        <Loader
          class="!relative"
          size="20px"
          color="#0099ff"
        />
        <p>{{ t('modal.server.connect.wait') }}</p>
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>
.command-header {
  @apply flex flex-col items-center w-full py-3;
  .project-connect {
    @apply flex items-center gap-2.5;
    .avatar-box {
      width: 60px;
      @apply rounded-half aspect-square;
    }
  }
}


.step {
  @apply flex gap-2.5 flex-wrap;
  .command {
    @apply w-full;
    @apply bg-grey-850;
    @apply flex flex-row justify-between items-center;
    @apply rounded-md;
    @apply p-2.5;
    @apply text-grey-200 tracking-wider;
    @apply font-medium;
    @apply leading-none;
    white-space: nowrap;
    @apply overflow-x-hidden;
    text-overflow: ellipsis;
    @apply cursor-pointer;
    @apply select-none;

    .icon-box {
      width: 20px;
      @apply fill-grey-600;
    }
    &:hover {
      .icon-box {
        @apply fill-grey-200;
      }
    }
    &:active {
      @apply text-grey-300;
      svg {
        @apply fill-grey-400;
      }
      .icon-box {
        @apply fill-grey-400;
      }
    }
  }

  .step-text {
    @apply text-grey-400;
    &:deep(.bright) {
      @apply text-grey-100;
    }

    span {
      @apply text-white;
    }
  }
  .svg-box {
    width: 17px;
    border-radius: 4px;
    @apply aspect-square;
    @apply flex justify-center;
    @apply p-1;

    &.checked {
      @apply shadow-middle shadow-ra-online/25;
      @apply px-0.5;
      @apply bg-ra-online;
    }
  }
}

.wait {
  @apply flex items-center justify-center w-full;
  @apply py-1 gap-2;

  p {
    @apply text-primary-700;
  }
}
</style>