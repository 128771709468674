<script setup lang='ts'>
  import { CheckActivityMetaMessageFromModeratorDto, CheckDto } from '@/api/backend/court/check/check.dto';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { onMounted, watch } from 'vue';
  import { useClientComposable } from '@court/components/client/client.composable';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useConfigStore } from '@/stores/config/config.store';

  const props = defineProps<{ 
    check: CheckDto | null,
    meta: CheckActivityMetaMessageFromModeratorDto, 
    reversed: boolean,
    created: number,
  }>();

  const { t } = useLocaleStore();

  const { client, load } = useClientComposable();
  onMounted(() => load(props.meta.client_id));
  watch(() => props.meta, () => load(props.meta.client_id), { deep: true });
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :created="created"
    circle-class="bg-grey-1000 !border-ra-offline"
  >
    <template #circle>
      <img :src="useConfigStore().Urls.Images(client?.avatar ?? '')" class="rounded-half aspect-square object-cover">
    </template>

    <template #content>
      <div class="user-message">
        <p class="name">{{ client?.name ?? t('loading.player') }}</p>
        <p class="message">{{ meta.message }}</p>
      </div>
    </template>
  </CheckActivityBase>
</template>

<style lang='scss' scoped>
.user-message {
  @apply w-fit max-w-xl py-2 px-2.5 rounded-md overflow-hidden;
  @apply bg-grey-900;
  .name {
    @apply text-grey-50 font-medium text-base;
  }
  .message {
    @apply text-grey-400 text-sm;
  }
}
</style>