<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ProjectCreateOrUpdatePayload } from '@/api/backend/core/project/project.dto';
  import { ampli } from '@src/ampli';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { EnvVariables } from '@/stores/config/env.variables';
  import { useConfigStore } from '@/stores/config/config.store';

  export type ProjectCreateModalOpts = {
  }

  export const ActivateProjectCreateModal = async (props: ProjectCreateModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('project-create', props);
  };
</script>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';

  import DividedCard from '@/components/DividedCard.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useValidators } from '@src/composable/validate.composable';
  import { shuffle } from 'lodash';
  import UploaderArea from '@/components/quality/uploader/UploaderArea.vue';
  import { UploadedFile } from '@/components/quality/uploader/utils/uploader.dto';

  const _app = useAppStore();
  const _validators = useValidators();

  const { t } = useLocaleStore();

  const props = defineProps<ModalInjectedProps>();

  const info = ref<ProjectCreateOrUpdatePayload>({
    domain     : '',
    name       : '',
    contact_url: '',
    avatar     : shuffle(useConfigStore().Avatars.LibraryProject)[0],
  });

  const projectAvatar = ref<UploadedFile[]>([]);

  watch(() => projectAvatar.value, (newValue) => {
    if (newValue?.length && newValue[0].s3_id) {
      info.value.avatar = newValue[0].s3_id ?? '';
    }
  }, {
    deep: true
  });

  const createProject = async () => {
    if (!_app.projects) {
      return;
    }

    await useCoreApi().project.create(info.value);

    await _app.warmup();

    ampli.clientProjectCreate({ project_name: info.value.name, project_url: info.value.contact_url });

    props.injected.close();
  };

  const nameRef = ref<InstanceType<typeof TextInput>>();
  const domainRef = ref<InstanceType<typeof TextInput>>();
  const contactRef = ref<InstanceType<typeof TextInput>>();
</script>

<template>
  <div class="project-create-modal">
    <DividedCard>
      <template #header>
        {{ t('modal.project-create.header.title') }}
      </template>
      <template #default>
        <div class="inputs">
          <TextInput
            ref="nameRef"
            v-model="info.name"
            :label="t('modal.project-create.name.label')"
            :placeholder="t('modal.project-create.name.placeholder')"
            :validator="_validators.ProjectName"
            no-border
          />

          <TextInput
            ref="domainRef"
            v-model="info.domain"
            :label="t('modal.project-create.domain.label')"
            :placeholder="t('modal.project-create.tag.placeholder')"
            :validator="_validators.ProjectDomain"
            no-border
            no-padding
          >
            <div>
              <span>{{ EnvVariables.DomainSite }}/</span>
            </div>
          </TextInput>

          <TextInput
            ref="contactRef"
            v-model="info.contact_url"
            :label="t('modal.project-create.url.label')"
            :placeholder="t('modal.project-create.url.placeholder')"
            :validator="_validators.Url"
            no-border
          />
          <UploaderArea
            v-model="projectAvatar"
            :header="t('modal.project-create.uploader-text')"
            type="avatar-project"
            :multiple="false"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-2 w-full">
          <Button
            preset="default"
            :text="t('general.close')"
            :action="
              () => {
                props.injected.close();
              }
            "
          />
          <Button
            preset="primary"
            :text="t('modal.project-create.create-button')"
            :disabled="!!nameRef?.activeException || !!contactRef?.activeException || !!domainRef?.activeException"
            :action="createProject"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.project-create-modal {
  width: 95vw;
  max-width: 420px;
}
.inputs {
  @apply grid grid-cols-1 gap-4;
}
</style>