<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { ampli } from '@src/ampli';
  import { ActivatePlayerDetailsModal } from '../player-details/PlayerDetailsModal.vue';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';

  export type PlayerBanCreateModalOpts = {
    player: PlayerFullDto,
    team: PlayerFullDto[],

    callback: (data: BanFinalResult) => void,

    enableCheckStep?: boolean
  }

  export const ActivateBanCreateModal = async (props: PlayerBanCreateModalOpts) => {
    const _modal = useModalsStore();

    return await _modal.open('ban-create', props, true);
  };
</script>

<script setup lang='ts'>
  import StepperCard from '@/components/stepper/StepperCard.vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';

  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import BanStepGeneral from './steps/BanCreateStepGeneral.vue';
  import BanStepMass from './steps/BanCreateStepMass.vue';
  import BanStepIp from './steps/BanCreateStepIp.vue';
  import BanStepProof from './steps/BanCreateStepProof.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Step } from '@/components/stepper/stepper.dto';
  import BanStepCheckFinish from './steps/BanCreateStepCheckFinish.vue';
  import { BanFinalResult } from './types/ban.dto';
  import { BanCheckStatusResult, BanDataStorage, useBanData } from './stores/ban.storage';

  const props = defineProps<PlayerBanCreateModalOpts & ModalInjectedProps>();

  const { t } = useLocaleStore();

  const _panel = usePanelStore();

  const suspectName = computed(() => props.player.steam_name);
  const suspectAvatar = computed(() => props.player.steam_avatar);

  const computedSteps = computed(() => {
    const result: Step<'check-finish' | 'main' | 'mass' | 'ip' |'proof'>[] = [];

    if (props.enableCheckStep) {
      result.push({
        name  : 'check-finish',
        header: {
          title   : t('modal.ban.verdict.header.title'),
          subtitle: t('modal.ban.verdict.header.subtitle', { 
            name: suspectName.value
          }),
          img  : suspectAvatar.value,
          click: () => ActivatePlayerDetailsModal(props.player.steam_id)
        },

        nextDisabledCondition: () => !checkFinishReason.value.status,
      });
    }

    if (props.enableCheckStep && checkFinishReason.value.status !== 'FinishedBan') {
      return result;
    }

    result.push({
      name  : 'main',
      header: {
        title   : t('modal.ban.general.header.title'),
        subtitle: t('modal.ban.general.header.subtitle', { 
          name: suspectName.value
        }),
        img  : suspectAvatar.value,
        click: () => ActivatePlayerDetailsModal(props.player.steam_id)
      },
      
      nextDisabledCondition: () => !banStorage.value?.target.reason,
    });

    if (banStorage.value?.team.enabled) {
      result.push({
        name  : 'mass',
        header: {
          title   : t('modal.ban.general.header.title'),
          subtitle: t('modal.ban.mass.header.subtitle'),
          img     : suspectAvatar.value,
          click   : () => ActivatePlayerDetailsModal(props.player.steam_id)
        },

        nextDisabledCondition: () => banStorage.value?.team.players.length === 0 || !banStorage.value?.team.reason?.length
      });
    }

    if (Object.keys(banStorage.value?.ips ?? {}).length > 1 && banStorage.value?.team.enabled && banStorage.value.team.ip_active) {
      result.push({
        name  : 'ip',
        header: {
          title   : t('modal.ban.general.header.title'),
          subtitle: t('modal.ban.ip.header.subtitle'),
          img     : suspectAvatar.value,
          click   : () => ActivatePlayerDetailsModal(props.player.steam_id)
        }
      });
    }

    result.push({
      name  : 'proof',
      header: {
        title   : t('modal.ban.general.header.title'),
        subtitle: t('modal.ban.proofs.header.subtitle'),
        img     : suspectAvatar.value,
        click   : () => ActivatePlayerDetailsModal(props.player.steam_id)
      },

      nextDisabledCondition: () => !!banStorage.value?.proofs.some(v => !v.s3_id && v.file)
    });

    return result;
  });


  const checkFinishReason = ref<BanCheckStatusResult>({
    status : undefined,
    comment: ''
  }); 

  const banStorage = ref<BanDataStorage>();

  const subscription = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }

    subscription.value = useCentrifugoApi().subscribeBanUpdate(_panel.project.id, (ban) => {
      if (ban.steam_id === banStorage.value?.target.steamId && ban.computed_is_active) {
        ampli.clientBanTogether({ ban_id: ban.id, target_client_id: ban.client_id }); 

        props.injected.setBeforeClose(async () => {
          await ActivateConfirmModal({
            type: 'warn',

            title      : t('ban-create-multiple-title'),
            description: t('ban-create-multiple.subtitle')
          });

          return true;
        });

        props.injected.close();
        return;
      }
    });
  });

  onUnmounted(() => subscription.value?.unsubscribe());

  watch(() => checkFinishReason.value.comment, (v) => {
    if (banStorage.value == null) {
      return;
    }

    banStorage.value.comment = v;
  });
  watch(() => banStorage.value?.comment, (v) => {
    if (checkFinishReason.value == null || !v) {
      return;
    }
    
    checkFinishReason.value.comment = v;
  });

  onMounted(() => {
    banStorage.value = useBanData(props.player.steam_id, { player: props.player, players: props.team }).value;

    const copy = useBanData(props.player.steam_id, { player: props.player, players: props.team }).value;
    
    props.injected.setBeforeClose(async () => {
      const checkFinishReasonChanges = !!checkFinishReason.value.status || !!checkFinishReason.value.comment.length;
      const banStorageChanges = JSON.stringify(banStorage.value) !== JSON.stringify(copy);

      if (!banStorageChanges && !checkFinishReasonChanges) {
        return true;
      }

      return await ActivateConfirmModal({
        type       : 'warn',
        title      : t('general.title.changes-can-be-lost'),
        description: t('general.subtitle.changes-can-be-lost'),
        onConfirmed: () => {}
      });
    });
  });

  const setUpdater = (func: () => void) => {
    banStorage.value!.updater = () => setTimeout(func);
  };

  const finish = async () => {
    const obj: BanFinalResult = {
      bans: []
    };

    if (checkFinishReason.value.status) {
      obj.check = {
        status : checkFinishReason.value.status,
        comment: checkFinishReason.value.comment        
      };

      if (obj.check.status !== 'FinishedBan') {
        await props.callback(obj);

        props.injected.setBeforeClose(undefined);

        props.injected.close();
        return;
      }
    }

    if (!banStorage.value) {
      await props.callback(obj);
      
      props.injected.setBeforeClose(undefined);

      props.injected.close();
      return;
    }

    if (banStorage.value.proofs.some(v => !v.s3_id)) {
      ActivateConfirmModal({
        type       : 'default',
        title      : t('bans.upload-not-loaded-title'),
        description: t('bans.upload-not-loaded-subtitle'),
      });
      return;
    }

    const proofs = banStorage.value.proofs.map(v => v.s3_id) as string[];

    if (obj.check) {
      obj.check.verdict = banStorage.value.target.reason;
    }
    
    obj.bans?.push({
      steam_id: banStorage.value.target.steamId,
      
      ban_ip_active: banStorage.value.ips[banStorage.value.target.steamId],
      ban_ip       : banStorage.value.target.player?.ip,

      reason    : banStorage.value.target.reason,
      expired_at: banStorage.value.target.expired,

      proofs    : proofs,
      server_ids: banStorage.value.server_ids,
      comment   : banStorage.value.comment
    });

    if (banStorage.value.team.enabled) {
      banStorage.value.team.players.forEach(player => {
        obj.bans?.push({
          steam_id: player.steam_id,


          ban_ip       : player.ip,
          ban_ip_active: banStorage.value!.ips[player.steam_id] ?? false,
          
          reason    : banStorage.value!.team.reason,
          expired_at: banStorage.value!.team.expired,

          server_ids: banStorage.value!.server_ids,
          proofs    : banStorage.value!.proofs_global ? proofs : [],
          comment   : banStorage.value!.proofs_global ? banStorage.value?.comment : ''
        });
      });
    }

    subscription.value?.unsubscribe();

    await props.callback(obj);

    props.injected.setBeforeClose(undefined);

    props.injected.close();
  };
</script>

<template>
  <div class="ban-modal">
    <StepperCard
      v-if="banStorage"

      :steps="computedSteps"
      :callback="finish"
      @updater="setUpdater"
    > 
      <template #check-finish>
        <BanStepCheckFinish v-model="checkFinishReason" class="p-5" />
      </template>
      
      <template #main>
        <BanStepGeneral
          v-model="banStorage"
          class="p-5"
          :check="enableCheckStep"
        />
      </template>

      <template #mass>
        <BanStepMass v-model="banStorage" class="p-5" />
      </template>

      <template #ip>
        <BanStepIp v-model="banStorage" class="p-5" />
      </template>

      <template #proof>
        <BanStepProof v-model="banStorage" class="p-5" />
      </template>
    </StepperCard>
  </div>
</template>

<style lang='scss' scoped>
.ban-modal {
  width: 95vw;
  max-width: 430px;
}

:deep(.label) {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}


:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}
:deep(.content) {
  padding: 0 !important;
}

:deep(.expired-input) {
  .input-box {
    input {
      @apply rounded-e-none;
    }
  }
}
</style>