<script setup lang="ts">
  import { useCourtStore } from './stores/court.store';
  import { onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useCourtSubscriptionStore } from './stores/subscription.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import Button from '@/components/Button.vue';
  import { SettingsRoutes } from '@settings/settings.routes';

  const { t } = useLocaleStore();
  const _court = useCourtStore();
  const _router = useRouter();
  const _route = useRoute();

  const _permissions = usePermissionsStore();
  const _subscription = useCourtSubscriptionStore();

  onMounted(async () => {
    try {
      await _court.warmup();
    } catch (err) {
      _router.back();
    }
  });

  const goSupport = () => {
    window.open(`https://t.me/rustapp_help`, '_blank');
  };

</script>

<template>
  <div class="h-full router overflow-auto flex flex-col relative">
    <template v-if="(!_court.court?.is_banned && !_subscription.state?.pay_error && !_subscription.state?.usage_error) || _route.fullPath.includes('/settings/')">
      <RouterView :key="_route.path" />
    </template>
    <template v-else>
      <TitledPage no-divider custom-class="!min-h-screen h-full">
        <div class="w-full flex flex-col gap-5 justify-center items-center" style="height: calc(100vh - 10rem)">
          <template v-if="_subscription.state?.pay_error">
            <EmptyImage
              :title="t('payments.project-forezen.title')"
              :subtitle="t('payments.project-forezen.cash.subtitle')"
              type="frozen"
            />

            <Button
              v-if="_permissions.isAdminOrOwner()"
              preset="default"
              :text="t('payments.go-to-tariff')"
              @click="_router.push({ name: SettingsRoutes.Billing })"
            />
          </template>
          <template v-else-if="_subscription.state?.usage_error">
            <EmptyImage
              :title="t('payments.project-forezen.title')"
              :subtitle="t('payments.project-forezen.limits.subtitle')"
              type="frozen"
            />
            <Button 
              v-if="_permissions.isAdminOrOwner()"
              preset="default-light"
              :text="t('payments.change-plan')"
              @click="_router.push({ name: SettingsRoutes.Billing })"
            />
          </template>
          <template v-else-if="_court.court?.is_banned">
            <EmptyImage
              :title="t('payments.project-ban.title')"
              :subtitle="t('payments.project-ban.subtitle')"
              type="frozen"
            />

            <Button
              v-if="_permissions.isAdminOrOwner()"
              preset="default"
              :text="t('go-telegram')"
              @click="goSupport()"
            />
          </template>
        </div>
      </TitledPage>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.plan {
  @apply flex gap-2;
  @apply p-2 mb-5 rounded-md;
  @apply bg-amber-400/10 text-amber-400;

  svg {
    @apply w-5 flex-shrink-0 mx-1;
    @apply fill-amber-400;
  }
}
</style>
