<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useConfigStore } from '@/stores/config/config.store';

  export type AvatarPickerModalOpts = {
    current: () => string; 
    callback: (image: string) => unknown; 
    images: string[]; 
  }

  export const ActivateAvatarPickerModal = async (props: AvatarPickerModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('avatar-picker', props);
  };
</script>

<script setup lang="ts">
  import CheckIcon from '@src/assets/icons/checkmark.svg';
  import { useLocaleStore } from '@/stores/locale.store';
  import { onMounted, ref } from 'vue';
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';

  const { t } = useLocaleStore();

  const props = defineProps<AvatarPickerModalOpts & ModalInjectedProps>();

  const active = ref<string>();
  const sorted = ref<string[]>();

  onMounted(() => {
    if (props.images.includes(props.current())) {
      active.value = props.current();
    }

    sorted.value = [...props.images].sort((a, b) => +(b === props.current()) - +(a === props.current()));
  });
</script>

<template>
  <div style="max-width: 420px">
    <DividedCard no-content-padding>
      <template #header>
        <p>{{ t('modal.avatar-lib.header') }}</p>
      </template>
      <template #default>
        <div class="px-5" style="max-height: 350px;">
          <div class="avatar-row flex-wrap pb-5 pt-5">
            <template v-for="value in sorted" :key="value">
              <div class="relative">
                <img
                  :src="useConfigStore().Urls.Images(value)"
                  class="avatar"
                  :class="{active: active === value }"
                  @click="active = value"
                >

                <div v-if="active === value" class="check-wrapper">
                  <div class="check">
                    <CheckIcon class="fill-white" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex w-full justify-end gap-2">
          <Button
            :text="t('modal.client.logout')"
            preset="default"
            @click="injected.close()"
          />

          <Button
            :text="t('modal.client.select')"
            preset="primary"
            :disabled="!active"
            :action="() => {
              callback(active!);

              injected.close()
            }"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
  .avatar-row {
    @apply grid grid-cols-4 items-center gap-4;

    .upload {
      @apply aspect-square rounded-half cursor-pointer bg-white/20;

      width: 50px;
    }

    .avatar {
      @apply aspect-square rounded-half cursor-pointer object-cover;
      @apply border-primary-700 bg-white/5;

      &.active {
        @apply border-2;
        padding: 3px;
      }
    }

    .check-wrapper {
      @apply absolute top-0 right-0 bg-grey-900 rounded-half;

      padding: 3px;
      height: 26px;
      width: 26px;

      .check {
        @apply h-full w-full rounded-half bg-primary-700 flex justify-center items-center;

        svg {
          @apply w-4;
        }
      }
    }

    &, * {
      transition: all .05s;
    }
  }
</style>