<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { PlayerNameHistoryDto } from '@/api/backend/court/player/player.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { onMounted, ref } from 'vue';
  import PlayerDetailsCard from '../../card/PlayerDetailsCard.vue';
  import { useTimeStore } from '@/stores/time.store';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerDetailsCardBadge from '../../card/PlayerDetailsCardBadge.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import BaseTip from '@panel/components/BaseTip.vue';

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const props = defineProps<{ steamId: string }>();

  const data = ref<PlayerNameHistoryDto[] | null>(null);
  const loader = new PaginationService((page, limit) => useCourtApi().player.browsePlayerNameHistory(props.steamId, {
    page,
    limit
  }), 20);
  
  onMounted(() => next());

  const next = async () => {
    const { results } = await loader.next();

    if (!data.value) {
      data.value = [];
    }

    data.value?.push(...results);
  };
</script>

<template>
  <template v-if="data && data.length == 0">
    <div class="empty-image-container">
      <EmptyImage
        type="empty"
        :title="t('general.empty.no-data.title')"
        :subtitle="t('general.empty.no-data.subtitle')"
      />
    </div>
  </template>
  <template v-else>
    <Loading :can-view="data != null" class="!bg-transparent" />

    <template v-if="data != null">
      <BaseTip
        type="hint"
        disposable="nickname-history"
        class="mb-5"
      >
        {{ t('basetip-nickname-history') }}
      </BaseTip>
    
      <div class="content">
        <template v-for="value in data" :key="value.created_at">
          <PlayerDetailsCard
            :date-from="_time.format(value.created_at, 'DD.MM.YY # HH:mm', false)"
            :rows="[t('general.nickname')]"
          >
            <template v-if="value.project_id == -1" #badges>
              <PlayerDetailsCardBadge preset="blue" :tooltip-text="t('general.data-from-another-project')">
                <template #default>
                  <Svg.share />
                </template>
              </PlayerDetailsCardBadge>
            </template>
            <template #row0>
              {{ value.steam_name }}
            </template>
          </PlayerDetailsCard>
        </template>
      </div>
    </template>

    <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
  </template>
</template>

<style scoped lang="scss">
.content {
  @apply flex flex-col gap-2.5;
}
</style>