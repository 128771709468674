<script setup lang="ts" generic="T extends string">
  import { computed, ref } from 'vue';
  import { TabbedNavigationHeader } from './tabbed-navigation.dto';
  import { onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import Divider from '@/components/Divider.vue';

  const props = defineProps<{
    headers: TabbedNavigationHeader<T>[];
  }>();
  
  const _route = useRoute();
  const _router = useRouter();

  const selected = ref<T>();

  onMounted(() => resurrect());

  const activeHeaders = computed(() => {
    return props.headers.filter(v => !v.disabled || !v.disabled());
  });

  const resurrect = () => {
    const exists = props.headers.find(v => v.key === _route.query.section);

    select(exists?.key || props.headers[0].key);
  };

  const select = (val: T) => {
    selected.value = val;

    setTimeout(() => {
      _router.replace({
        ..._route,
      
        query: {
          ...(_route.query ?? {}),
          section: val
        }
      });
    }, 50);
  };
  
</script>

<template>
  <div v-if="selected" class="tabbed-navigation">
    <div v-if="activeHeaders.length > 1" class="header">
      <template v-for="value in activeHeaders" :key="value.key">
        <div
          v-if="$slots[value.key]"
          class="element"
          :class="{ selected: selected === value.key }"
          @click="select(value.key)"
        >
          <template v-if="value.icon">
            <component :is="value.icon" class="h-3 w-3" />
          </template>
          {{ value.text }}
        </div>
      </template>
    </div>

    <Divider v-else class="mb-8" />

    <div class="content" :class="{ 'pt-8': activeHeaders.length > 1 }">
      <template v-for="value in activeHeaders" :key="value.key">
        <div v-show="value.key === selected">
          <slot :name="value.key" />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tabbed-navigation {
  .header {
    @apply border-b border-grey-900;
    @apply flex gap-4 overflow-scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .element {
      @apply text-grey-50/50;
      @apply cursor-pointer shrink-0;

      @apply pb-2.5;

      &.selected {
        @apply cursor-pointer text-grey-50;
        @apply border-b-2 border-primary-700;
        @apply pointer-events-none;

        outline-offset: 25px;
      }

      &:hover {
        @apply text-grey-50/75;
      }
    }
  }

  .content {
    @apply text-grey-50;
  }
}
</style>