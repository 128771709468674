<script lang="ts"> 
  export type ChartSeries = { 
    name: string; 
 
    color: string; 
    customStrokeColor?: string; 
 
    dashValue?: number; 
    fillGradientOpacity?: number; 
 
    defaultHidden?: boolean; 
 
    value: number;
  }  
 
  export type ChartSeriesData = number;
</script> 
 
<script lang="ts" setup> 
  import { ApexOptions } from 'apexcharts'; 
  import { ref } from 'vue'; 
  import { computed } from 'vue'; 
  import { useLocaleStore } from "@/stores/locale.store";
  
  const { t } = useLocaleStore();
 
  const props = defineProps<{ 
    series: ChartSeries[] | null; 
    options?: ApexOptions; 
 
    sharedGroup?: string; 
  }>(); 
 
  const chartId = ref(Math.random().toString()); 
  const useCumulativeChart = ref<boolean>(false); 
 
  const computedSeries = computed(() => { 
    if (!props.series) { 
      return []; 
    } 
 
    const dataExists = props.series?.some(v => v.value > 0);
    if (dataExists) {
      return props.series.map(v => v.value);
    }
 
    return props.series.map(v => v.value || 1); 
  }); 
 
  const computedOptions = computed(() => { 
    if (!computedSeries.value) { 
      return; 
    } 
    
    const dataExists = props.series?.some(v => v.value > 0);

    const defaultOptions: ApexOptions = {
      labels: props.series?.map(v => v.name),
      
      chart: { 
        id   : chartId.value, 
        group: props.sharedGroup, 
        
        toolbar: { 
          show: false, 
        },

        sparkline: {
          enabled: true,
        },
        
        
        type: 'donut', 

        dropShadow: { 
          enabled: false, 
          blur   : 12 
        }, 
        
        foreColor: "#ccc",

        animations: {
          enabled: true,
          easing : 'easeinout',
          speed  : 200,
        },
      },

      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      
      stroke: { 
        width: 0, 
      }, 

      dataLabels: { 
        enabled: false,
      }, 

      tooltip: { 
        enabled: !!dataExists,
        style  : {
          fontFamily: 'Inter',
        },
      }, 
      
      colors: (props.series ?? []).map(v => dataExists ? v.color ?? '' : '#2D2D2D'),

      legend: { 
        show    : false,
        floating: true,
      }, 

      fill: { 
        colors: (props.series ?? []).map(v => dataExists ? v.color ?? '' : '#2D2D2D'),
      }
    };
 
    return Object.assign(defaultOptions, props.options); 
  }); 
 
</script> 
 
<template> 
  <div class="aspect-square">
    <apexchart 
      :options="computedOptions" 
      :series="computedSeries" 
      height="100%"
      width="100%"
    /> 
  </div>
</template> 
 
 
<style  lang="scss" scoped> 
 :deep(.apexcharts-canvas), :deep(.apexcharts-yaxis) { 
  .apexcharts-tooltip {
    @apply backdrop-blur-lg;
    -webkit-backdrop-filter: blur(12px); 
    box-shadow: none; 
  }

  .apexcharts-tooltip-series-group {
    padding: 0px 6px;
  }
} 
</style>