<script setup lang='ts'>
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import { computed, onMounted, watch } from 'vue';
  import { useClientComposable } from './client.composable';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { useSituationStore } from '@panel/stores/situation.store';
  import Highlight from '@/components/Highlight.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const props = defineProps<{ 
    clientId?: number, 
    preload?: ClientDto,
    search?: string;
  }>();

  const _situation = useSituationStore();
  const { client, subtext, load } = useClientComposable();
  
  onMounted(async () => {
    if (props.preload) {
      client.value = props.preload;
    }

    if (props.clientId) {
      await load(props.clientId);
    }
  });

  watch(() => props.clientId, (v) => load(v));

  const status = computed(() => {
    if ((props.clientId ?? client.value?.id) === undefined) {
      return undefined;
    }

    return Object.values(_situation.clients).some(v => v == props.clientId?.toString()) ? 'bg-ra-online' : 'bg-ra-offline';
  });
</script>

<template>
  <template v-if="client">
    <ListItem
      :image-url="client.avatar ? useConfigStore().Urls.Images(client.avatar) : undefined"
      :image-status-color="status ? status : undefined"
      class="client"
      @click="ActivateClientDetailsModal(client.tag, client)"
    >
      <template #title>
        <div class="inline">
          <Highlight :visible-text="client.name ?? ''" :search="search ? [search] : undefined" />

          <slot name="badges" />
        </div>
      </template>
      <template #message>
        <Highlight :visible-text="subtext ?? ''" :search="search ? [search] : undefined" />
      </template>
    </ListItem>
  </template>
  <template v-else>
    <ListItem skeleton="avatar" class="w-full" />
  </template>
</template>

<style lang='scss' scoped>
.client {
  @apply cursor-pointer;
  @apply transition-all ease-in-out delay-0 duration-100;

  &:hover {
    @apply opacity-80;
  }
}
</style>