<script setup lang="ts">
  import { AuthSignUpDto } from '@/api/backend/core/auth/auth.dto';
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useConfigStore } from '@/stores/config/config.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useValidators } from '@src/composable/validate.composable';
  import { SignUpData } from '@src/views/client/types/auth.dto';
  import { shuffle } from 'lodash';
  import { ref } from 'vue';

  const { t } = useLocaleStore();
  const _validators = useValidators();

  const props = defineProps<{ 
    credentials: SignUpData;
    confirm: (token: string) => Promise<unknown>;
  }>();

  const nameInput = ref<InstanceType<typeof TextInput>>();
  const tagInput = ref<InstanceType<typeof TextInput>>();
  const button = ref<InstanceType<typeof Button>>();
    
  const data = ref<{
    name: string,
    tag: string
  }>({
    name: '',
    tag : ''
  });

  const finish = async () => {
    let token: string | undefined;

    const sharedData: AuthSignUpDto = {
      name  : data.value.name,
      tag   : data.value.tag,
      avatar: shuffle(useConfigStore().Avatars.LibraryStaff)[0]
    };

    if ('email' in props.credentials) {
      token = await useCoreApi().auth.classicSignUp({
        email   : props.credentials.auth_email,
        password: props.credentials.auth_password,
        
        ...sharedData
      });
    } else if ('google' in props.credentials) {
      token = await useCoreApi().auth.googleSignUp({
        credential: props.credentials.google_credential,
        token     : props.credentials.google_token,
        
        ...sharedData
      });
    } else if ('steam' in props.credentials) {
      token = await useCoreApi().auth.steamSignUp({
        query: props.credentials.steam_query,
        
        ...sharedData
      });
    }

    if (!token) {
      useRuntimeNotification(`error`, `Что-то пошло не так...`);
      return;
    }

    await props.confirm(token);
  };
</script>

<template>
  <div class="auth">
    <div class="header">
      <p class="title">{{ t('auth-sign-up-complete') }}</p>
      <span class="subtitle">
        {{ t('auth-sign-up-compelte-subtitle') }}
      </span>
    </div>

    <div class="content">
      <div class="inputs">
        <TextInput 
          ref="nameInput" 
          v-model="data.name" 
          autofocus 
          name="name" 
          autocomplete="name"
          :label="t('signup.nickname.input.label')"
          placeholder="Garry Newman" 
          :validator="_validators.DisplayName" 
          height="42" 
          @on-enter="() => button?.submit()" 
        />

        <TextInput 
          ref="tagInput" 
          v-model="data.tag" 
          name="tag" 
          autocomplete="given-name"
          :label="t('signup.username.input.label')"
          placeholder="garry" 
          :validator="_validators.Tag" 
          height="42" 
          @on-enter="() => button?.submit()" 
        > 
          <div>  
            <span class="font-normal">{{ '@' }}</span>  
          </div> 
        </TextInput>
      </div>

      <Button
        ref="button"
        :disabled="!nameInput || !!nameInput?.activeException || !tagInput || !!tagInput?.activeException" 
        preset="primary"
        class="btn"
        :text="t('auth.button.sign-up-complete')"
        :action="finish"
      />
    </div>
  </div>
</template>