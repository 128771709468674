import { PaginatedResponse, PaginationQuery } from "@/api/backend/pagination.service";
import { BanBrowseDto, BanDto, BanEditPayloadDto, BanStatsDto, BanSyncAvailableSubscriptionsDto, BanSyncFollowersDto, BanSyncReasonsDto, BanSyncSettingsDto, BanSyncSubscriptionsDto } from "./ban.dto";
import DataLoader from "dataloader";
import { Axios } from "axios";

export const useCourtBanApi = (api: Axios) => {
  const browseBatchLoader = new DataLoader<number, BanDto | undefined>((keys) => browseBatched(keys), {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });

  const browseBatched = async (keys: readonly number[]) => {
    const data = await browse({ page: 0, limit: keys.length, ban_ids: keys as number[], sort_by: 'created' });

    return keys.map(v => data.results.find(d => d.id == v));
  };
  
  const stats = async (from: number, to: number, client_id?: number) => {
    const results = await api.get<BanStatsDto>(`/ban/stats`, {
      params: {
        from: from, 
        to  : to,
        client_id
      }
    });

    return results.data;
  };

  const browse = async (opts: BanBrowseDto & PaginationQuery): Promise<PaginatedResponse<BanDto>> => {
    const results = await api.get<PaginatedResponse<BanDto>>("/ban/browse", {
      params: {
        ...opts
      },
    });

    return results.data;
  };

  const edit = async (ban_id: number, data: BanEditPayloadDto) => {
    const result = await api.put(`/ban/${ban_id}`, data);

    return result.data;
  };

  const unban = async (ban_id: number, comment?: string) => {
    return await api.delete(`/ban/${ban_id}`, {
      params: {
        comment
      }
    });
  };

  const getSettings = async () => {
    return await api.get<BanSyncSettingsDto | null>(`/ban/ban-sync-settings`);
  };

  const setSettings = async (is_public: boolean, share_details: boolean, share_moders: boolean) => {
    return await api.post(`/ban/ban-sync-settings`, {
      is_public,
      share_details,
      share_moders
    });
  };

  const getSubscriptions = async () => {
    return await api.get<BanSyncSubscriptionsDto[]>(`/ban/ban-sync-subscriptions`);
  };

  const setSubscriptions = async (project_id_original: number, auto_kick: boolean, auto_kick_regex: string, auto_kick_time_limit: number, send_notification: boolean) => {
    return await api.post(`/ban/ban-sync-subscriptions`, {
      project_id_original,

      auto_kick,
      auto_kick_regex,
      auto_kick_time_limit,

      send_notification
    });
  };

  const deleteSubscriptions = async (project_id_original: number) => {
    return await api.delete(`/ban/ban-sync-subscriptions/${project_id_original}`);
  };

  const getSubscriptionsAvailable = async () => {
    return await api.get<BanSyncAvailableSubscriptionsDto[]>(`/ban/ban-sync-available-subscriptions`);
  };

  const getFollowers = async () => {
    return await api.get<BanSyncFollowersDto[]>(`/ban/ban-sync-followers`);
  };

  const getReasons = async (project_id_original: number) => {
    return await api.get<BanSyncReasonsDto[]>(`/ban/ban-sync-reasons/${project_id_original}`);
  };

  const globalUnbanCalculate = async (
    interval_start?: number,
    interval_end?: number,
    reason_include?: string,
    reason_exclude?: string) => {
    return await api.get<{ total: number, unban: number }>(`/ban/global-unban`, {
      params: {
        interval_start,
        interval_end,
        reason_include,
        reason_exclude
      }
    });
  };

  const globalUnbanProcess = async (
    interval_start?: number,
    interval_end?: number,
    reason_include?: string,
    reason_exclude?: string) => {
    return await api.post<{ total: number, unban: number }>(`/ban/global-unban`, {}, {
      params: {
        interval_start,
        interval_end,
        reason_include,
        reason_exclude
      }
    });
  };

  return {
    browseBatchLoader,
    browseBatched,
    stats,
    browse,
    edit,
    unban,
    getSettings,
    setSettings,
    getSubscriptions,
    setSubscriptions,
    deleteSubscriptions,
    getSubscriptionsAvailable,
    getFollowers,
    getReasons,
    globalUnbanCalculate,
    globalUnbanProcess,
  };
};
