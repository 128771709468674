import { PermissionsDictionary } from "@/api/backend/core/project/project.dto";
import { useLocaleStore } from "@/stores/locale.store";

export type PermissionsSection = {
  name: string;
  descriptions: PermissionDescription<PermissionVariantSingle | PermissionVariantTuple>[];
}

export type PermissionDescription<T extends (PermissionVariantSingle | PermissionVariantTuple) = PermissionVariantSingle | PermissionVariantTuple> = {
  name: string;
  // Если здесь указан пермишен, то этот переключатель доступен только вместе с ним
  enabled_by?: (keyof PermissionsDictionary)[] | keyof PermissionsDictionary;

  // Определяет текст и наличие кружочка с вопросиком
  info?: string;
  // Определяет текст и наличие восклицательного знака с предупреждением
  warning?: string;
} & T

export type PermissionVariantSingle = {
  permission: keyof PermissionsDictionary;
}

export type PermissionVariantTuple = {
  variants: PermissionDescription<PermissionVariantSingle>[];
}

export const usePermissionStorage = () => {
  const { t } = useLocaleStore();

  const permissionsSections: PermissionsSection[] = [{
    name        : t('permissions.main.title'),
    descriptions: [{
      name      : t('permissions.main.clients-edit'),
      permission: 'Core_ClientsEdit',
      warning   : t('permissions.main.clients-edit-warning')
    }, {
      name      : t('permissions.main.clients-audit'),
      permission: 'Court_AuditRead',
      info      : t('permissions.main.clients-audit-warning')
    }]
  }, {
    name        : t('permissions.players.title'),
    descriptions: [{
      name      : t('permissions.players.player-details-read'),
      permission: 'Court_PlayerReadDetails'
    }, {
      name      : t('permissions.players.player-view-gameinfo'),
      permission: 'Court_PlayerViewGameInfo',
      enabled_by: 'Court_PlayerReadDetails',
    }, {
      name      : t('permissions.players.player-kick'),
      permission: 'Court_PlayerKick',
      enabled_by: 'Court_PlayerReadDetails',
    }, {
      name      : t('permissions.players.change-ignore-opts'),
      permission: 'Court_PlayerEditIgnoreReportsTime',
      enabled_by: 'Court_PlayerReadDetails',
      info      : t('permissions.players.change-ignore-opts-desc')
    }, {
      name      : t('permissions.players.see-history'),
      permission: 'Court_PlayerHistoryRead',
      enabled_by: 'Court_PlayerReadDetails',
      info      : t('permissions.players.see-history-desc')
    }, {
      name      : t('permissions.players.stats-perm'),
      permission: 'Court_PlayerStatsRead',
      enabled_by: 'Court_PlayerReadDetails',
    }],
  }, {
    name        : t('permissions.ban.title'),
    descriptions: [{ 
      name      : t('permissions.players.player-ban-read'), 
      permission: 'Court_PlayerBanRead'
    }, {
      name      : t('permissions.players.player-ban'),
      permission: 'Court_PlayerBan',
      enabled_by: 'Court_PlayerReadDetails'
    }, {
      name      : t('permissions.players.player-ban-edit'),
      enabled_by: 'Court_PlayerBan',
      variants  : [{
        name      : t('permissions.players.ban-edit-general'),
        info      : t('permissions.players.ban-edit-general-info'),
        permission: 'Court_PlayerBanEditGeneral'
      }, {
        name      : t('permissions.players.ban-edit-full'),
        warning   : t('permissions.players.ban-edit-full-warning'),
        permission: 'Court_PlayerBanEditFull'
      }, {
        name      : t('permissions.players.ban-unban'),
        permission: 'Court_PlayerBanUnban'
      }]
    },/*{
      name      : t('permissions.players.player-ban-edit-other'),
      enabled_by: 'Court_PlayerBanEditGeneral',
      permission: 'Court_PlayerBanEditOthers'
    }*/]
  }, {
    name        : t('permissions.chat.title'),
    descriptions: [{
      name    : t('permissions.chat.read-chat.title'),
      variants: [{
        name      : t('permissions.chat.read-chat.read-public'),
        permission: 'Court_ChatReadPublic'
      }, {
        name      : t('permissions.chat.read-chat.read-direct'),
        warning   : t('permissions.chat.read-chat.read-direct-warning'),
        permission: 'Court_ChatReadDirect'
      }]
    }, {
      name      : t('permissions.chat.write-chat.title'),
      enabled_by: 'Court_ChatReadPublic',
      variants  : [{
        name      : t('permissions.chat.write-chat.direct-reply'),
        permission: 'Court_ChatWriteReply'
      }, {
        name      : t('permissions.chat.write-chat.global-chat'),
        warning   : t('permissions.chat.write-chat.global-chat-warning'),
        permission: 'Court_ChatWriteGlobal'
      }]
    }]
  }, {
    name        : t('permissions.reports.title'),
    descriptions: [{
      name      : t('permissions.reports.read-reports'),
      permission: 'Court_ReportRead'
    }, {
      name      : t('permissions.reports.delete-report-player'),
      enabled_by: 'Court_ReportRead',
      permission: 'Court_ReportDelete'
    }, {
      name      : t('permissions.reports.delete-report-global'),
      enabled_by: 'Court_ReportDelete',
      permission: 'Court_ReportDeleteGlobal'
    }]
  }, {
    name        : t('permissions.checks.title'),
    descriptions: [{
      name      : t('permissions.checks.read-checks'),
      enabled_by: ['Court_PlayerBanRead', 'Court_PlayerReadDetails'],
      permission: 'Court_CheckRead',
    }, {
      name      : t('permissions.checks.call-check'),
      permission: 'Court_CheckProcess',
      enabled_by: ['Court_CheckRead', 'Court_PlayerReadDetails', 'Court_PlayerBan']
    }, {
      name      : t('permissions.checks.call-check-ignore-afk'),
      permission: 'Court_CheckStartIgnoreAfk',
      enabled_by: ['Court_CheckProcess']
    }, {
      name      : t('permissions.checks.call-check-ignore-rb'),
      permission: 'Court_CheckStartIgnoreRaidBlock',
      info      : t('permissions.checks.call-check-ignore-rb-info'),
      enabled_by: ['Court_CheckProcess']
    }, {
      name      : t('permissions.checks.call-check-ignore-no-reports'),
      permission: 'Court_CheckStartIgnoreReports',
      enabled_by: ['Court_CheckProcess']
    }]
  }, {
    name        : t('permissions.actions.title'),
    descriptions: [{
      name      : t('permissions.action.group-a'),
      permission: 'Court_CustomActionsGreen'
    }, {
      name      : t('permissions.action.group-b'),
      permission: 'Court_CustomActionsYellow'
    }, {
      name      : t('permissions.action.group-c'),
      permission: 'Court_CustomActionsRed'
    }]
  }, {
    name        : t('permissions.servers.title'),
    descriptions: [{
      name      : t('permissions.servers.view'),
      permission: 'Court_ServerRead',
    }, {
      name      : t('permissions.servers.connect-new-servers'),
      enabled_by: 'Court_ServerRead',
      permission: 'Court_ServerManage',
    }],
  }, {
    name        : t('permissions.other.title'),
    descriptions: [{
      name      : t('permissions.other.alert-read'),
      permission: 'Court_AlertRead'
    }, {
      name      : t('permissions.other.signage-control'),
      permission: 'Court_SignageControl'
    }]
  }];

  return {permissionsSections};
};