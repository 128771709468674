<script setup lang='ts'>
  import { computed, onMounted, ref, watch } from 'vue';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { CheckActivityMetaPlayerTeamChangedDto, CheckDto } from '@/api/backend/court/check/check.dto';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    check: CheckDto,
    meta: CheckActivityMetaPlayerTeamChangedDto, 
    reversed: boolean,
    created: number,
  }>();

  const players = ref<PlayerMinimalDto[]>();

  onMounted(() => load());
  watch(() => props, () => load(), { deep: true });

  const load = async () => {
    const playersToLoad: string[] = [props.meta.target_steam_id];

    if (props.meta.initiator_steam_id) {
      playersToLoad.push(props.meta.initiator_steam_id);
    }

    const result = await useCourtApi().player.browseMinimal({ steam_ids: playersToLoad, page: 0, limit: 2, offline: true });

    players.value = result.results;
  };

  const computedInitiator = computed(() => players.value?.find(v => v.steam_id === props.meta.initiator_steam_id));
  const computedTarget = computed(() => players.value?.find(v => v.steam_id === props.meta.target_steam_id));
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :created="created"
    circle-class="bg-grey-1000 !border-ra-yellow"
  >
    <template #content>
      <div class="user-message">
        <p class="title">{{ t('check.activity.teamchanged.title') }}</p>

        <template v-if="props.meta.initiator_steam_id === props.meta.target_steam_id">
          <template v-if="check.suspect.steam_id === props.meta.initiator_steam_id">
            <p class="subtitle">{{ t('check.activity.teamchanged.himself') }}</p>
          </template>
          <template v-else>
            <span class="subtitle">
              <a class="player" @click="ActivatePlayerDetailsModal(props.meta.initiator_steam_id)">
                {{ computedInitiator?.steam_name ?? 'Неизвестно' }}
              </a>
              {{ t('check.activity.teamchanged.teammate') }}
            </span>
          </template>
        </template>
        <template v-else>
          <template v-if="players == null">
            <Skeleton class="rounded-md" style="height: 20px; width: 200px;" />
          </template>
          <template v-else>
            <span class="subtitle inline">
              <a class="player" @click="ActivatePlayerDetailsModal(props.meta.initiator_steam_id)">
                {{ computedInitiator?.steam_name ?? 'Неизвестно' }}
              </a>
              <span class="px-1">
                {{ t('check.activity.teamchanged.kicked') }}
              </span>
              <a class="player" @click="ActivatePlayerDetailsModal(props.meta.target_steam_id)">
                {{ computedTarget?.steam_name ?? 'Неизвестно' }}
              </a>
            </span>
          </template>
        </template>
      </div>
    </template>
  </CheckActivityBase>
</template>

<style lang='scss' scoped>
.title {
  @apply text-grey-50;
  @apply text-lg;
  @apply font-medium;
}
.subtitle {
  @apply text-grey-400;

  .player {
    @apply text-ra-yellow cursor-pointer;
    
    &:hover {
      @apply underline underline-offset-4;
    }
  }
}
</style>