<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Switch from './Switch.vue';
  import { CourtFeatureName, useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlanIcon from '@court/components/plans/PlanIcon.vue';
  import { useRuntimeNotification } from '../RuntimeNotification.vue';
  import TooltipBase from '../tooltip/TooltipBase.vue';

  const emits = defineEmits(['update:modelValue']);
  const props = defineProps<{ 
    beforeToggle?: () => Promise<void>;
    header: string; 
    placeholder?: string; 
    disabled?: boolean;
    small?: boolean;
    subscription?: CourtFeatureName;
  }>();

  const { t } = useLocaleStore();

  const _subscription = useCourtSubscriptionStore();

  const modelValue = defineModel<boolean>({ required: true });
  const child = ref<InstanceType<typeof Switch> | null>(null);
  
  const isLoading = computed(() => props.subscription && !_subscription.state);
  const needSubscription = computed(() => props.subscription && _subscription.needSubscription(props.subscription) as ('basic' | 'pro' | 'enterprise' | undefined));

  const beforeToggleInProgress = ref<boolean>(false);
  const childToggle = async () => {
    if (needSubscription.value) {
      needSubscriptionConfirm();
      return;
    }

    if (isLoading.value || needSubscription.value || beforeToggleInProgress.value) return;

    try {
      beforeToggleInProgress.value = true;

      props.beforeToggle?.().catch(err => {
        useRuntimeNotification('error', 'Не удалось переключить состояние');
        child.value?.toggle();
      });

      child.value?.toggle();
    }
    finally {
      beforeToggleInProgress.value = false;
    }
  };

  const needSubscriptionConfirm = () => { 
    ActivateConfirmModal({ 
      type       : 'warn', 
      title      : t('court.no-sub.confirm.title'), 
      description: t('court.no-sub.confirm.subtitle', {plan: needSubscription}),
    }); 
  }; 
</script>

<template>
  <div
    class="switch-wrapper cursor-pointer"
    :class="{ 'opacity-40 cursor-not-allowed pointer-events-none': disabled }"
    @click.prevent="childToggle"
  >
    <div class="switch-text">
      <div class="title">
        <p>{{ header }}</p>
        <template v-if="subscription && !isLoading && needSubscription">
          <TooltipBase :trigger="'hover'">
            <template #content>
              <div class="px-2 py-1">
                <span>
                  {{ t('required-plan-tooltip') }}
                </span>
                <span class="capitalize">{{ needSubscription }}</span>
              </div>
            </template>
            <PlanIcon :plan="`${needSubscription}`" @click="needSubscriptionConfirm" />
          </TooltipBase>
        </template>
      </div>
      <span class="subtitle ">{{ placeholder }}</span>
    </div>
    <slot />
    
    <div class="relative flex items-center h-full">
      <slot name="action" />
      <Switch
        ref="child"
        v-model="modelValue"
        :small="small"
        :readonly-value="needSubscription || disabled ? false : undefined"
        :class="{ 'opacity-40 pointer-events-none': isLoading }"
      />
      <slot name="after" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.switch-wrapper {
  @apply flex justify-between gap-2.5 items-center;
  @apply select-none;

  .switch-text {
    @apply flex flex-col;

    .title {
      @apply flex gap-1.5;
      @apply text-grey-50;
    }
    .subtitle {
      @apply text-grey-500 text-sm leading-tight;
    }
    @apply flex flex-col w-full;
  }
}
</style>
