<script lang="ts">

  export type ExecuteCommandModalProps = {
    request: CourtCommandRequestDto,
    response?: CourtCommandResponseDto;
  };

  export const ReactOnResponse = (command: CourtCommandDto, executeResponse: CourtExecuteCommandResponseDto, canOpen: boolean) => {
    const { response } = executeResponse;
    
    if (!response) {
      return;
    }

    const { t } = useLocaleStore();
    const _modal = useModalsStore();

    ampli.customActionUse({ access: command.access });
    
    if (!response.data) {
      useRuntimeNotification(`error`, t('command-not-executed-server-not-responded'));
      return;
    } else if (response.data.some(v => !!v.data)) {
      useRuntimeNotification(`success`, t('command-executed-show-response'), {
        overrideTime: 10_000
      }, canOpen ? {
        text  : t('command-executed-show-answer'),
        action: () => {
          _modal.open('command-execute', executeResponse);
        }
      } : undefined);
      return;
    } else {
      useRuntimeNotification(`success`, t('command-executed-success'));
      return;
    }
  };

  export const ActivateCommandExecuteModal = async (steam_id: string, command: CourtCommandDto) => {
    const _modal = useModalsStore();
  
    const executeResponse = await useCourtApi().commands.prepare(command.id, { steam_id });
    const { request, response } = executeResponse;

    if (!response) {
      _modal.open('command-execute', {
        request : request,
        response: response
      });
    }
    
    ReactOnResponse(command, executeResponse, true);
  };

  export const ActivateCommandExecuteModalResults = async (request: CourtCommandRequestDto, response: CourtCommandResponseDto) => {
    const _modal = useModalsStore();

    _modal.open('command-execute', {
      request : request,
      response: response
    });
  };
</script>

<script setup lang="ts">
  import DividedCard from "@/components/DividedCard.vue";
  import { CourtCommandDto, CourtCommandResponseDto, CourtCommandRequestDto, CourtExecuteCommandResponseDto } from "@/api/backend/court/commands/commands.dto";
  import { ref } from "vue";
  import { onMounted } from "vue";
  
  import { Svg } from "@src/assets/auto_gen_types.dto";
  import Button from "@/components/Button.vue";
  import { useLocaleStore } from "@/stores/locale.store";
  import { computed } from "vue";
  import { ModalInjectedProps, useModalsStore } from "@/stores/modals.store";

  import ExecuteCommandRequest from './components/CommandExecuteRequest.vue';
  import ExecuteCommandResponse from "./components/CommandExecuteResponse.vue";
  import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
  import { ampli } from "@src/ampli";
  import { useCourtApi } from "@/api/backend/court/court.api";

  const { t } = useLocaleStore();

  const props = defineProps<ExecuteCommandModalProps & ModalInjectedProps>();

  const serverId = ref<number>(0);
  const inputArguments = ref<Record<string, string>>({});
  const serverResponse = ref<CourtCommandResponseDto | null>();

  onMounted(() => {
    if (props.request) {
      serverId.value = props.request.server_id;
      inputArguments.value = props.request.args;
    }
    if (props.response) {
      serverResponse.value = props.response;
    }
  });

  const canExecute = computed(() => inputArguments.value && !Object.keys(inputArguments.value).some(k => !inputArguments.value || !inputArguments.value[k]?.length));

  const execute = async () => {
    if (!props.request || !inputArguments.value) {
      return;
    }

    serverResponse.value = undefined;

    const executeResponse = await useCourtApi().commands.execute(props.request.command.id, { 
      steam_id        : props.request.steam_id,
      custom_args     : inputArguments.value,
      custom_server_id: serverId.value
    });
    const { response } = executeResponse;

    ReactOnResponse(props.request.command, executeResponse, false);

    if (response && response.data?.some(v => !!v.data)) {
      serverResponse.value = response;
    } else {
      props.injected.close();
    }  
  };
</script>

<template>
  <DividedCard class="execute-command-modal">
    <template v-if="serverResponse" #header>
      <div class="header">
        <span class="title">{{ t('send-execute-command-header-response-title') }}</span>
      </div>
    </template>
    <template v-else-if="inputArguments" #header>
      <div class="header flex !flex-row justify-between items-center w-full">
        <div>
          <span class="title">{{ request.command.force_confirmation ? t('send-execute-command-header-confirmation-title') : t('send-execute-command-header-variables-title') }}</span>
          <div class="subtitle">
            <span>{{ request.command.group }}</span>
            <Svg.arrow class="arrow" />
            <span>{{ request.command.name }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <template v-if="serverResponse">
        <ExecuteCommandResponse :responses="serverResponse.data" />
      </template>
      <template v-else-if="request && inputArguments">
        <ExecuteCommandRequest
          v-model:arguments="inputArguments"
          v-model:server_id="serverId"
          :command="request.command"
        />
      </template>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2 w-full">
        <Button
          preset="default"
          :text="t('general.close')"
          :action="injected.close"
        />

        <Button
          v-if="!serverResponse"
          preset="primary"
          :text="t('send-execute-command-btn')"
          :disabled="!canExecute"
          :action="execute"
        />
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>
.execute-command-modal {
  width: 95vw;
  max-width: 420px;
  @apply relative;
  :deep(.content) {
    @apply overflow-visible #{!important};
  }
}

.header {
  @apply flex flex-col gap-0.5;
  @apply font-semibold;

  .title {
    @apply text-grey-50;
    @apply font-semibold;
    @apply block;
  }

  .subtitle {
    @apply flex flex-row gap-1;
    @apply text-grey-400;
    @apply text-sm;
    @apply font-medium;

    .arrow {
      @apply w-4 fill-grey-600 text-grey-600;
    }
  }
}

.fields-list {
  @apply flex flex-wrap gap-2.5;

  .field {
    @apply flex-grow justify-between;
    @apply w-[45%];
  }
}
.command-list {
  @apply flex flex-col gap-2.5;

  .command-box {
    @apply flex items-stretch;
    @apply h-full;
    @apply w-full;

    .prepend {
      @apply w-[37px];
      @apply bg-grey-800;
      @apply shrink-0;
      @apply flex items-center justify-center;
      @apply text-grey-200 text-14-400;
      @apply rounded-s-md;
    }
    .command {
      @apply bg-grey-850;
      @apply grow;
      @apply p-2.5;
      @apply rounded-e-md;
      @apply text-grey-400 text-14-400;
    }
  }
}

</style>