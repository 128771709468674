<script setup lang="ts">
  import { onMounted } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaChatMessage } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<AuditDto<AuditMetaChatMessage>>();

  const { t } = useLocaleStore();

  const player = ref<PlayerMinimalDto>();

  onMounted(async () => {
    player.value = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.reply_to ?? '');
  });

  const audit = ref<InstanceType<typeof AuditBase>>();
</script>

<template>
  <template v-if="!meta.reply_to">
    <AuditBase
      ref="audit"
      :client_id="client_id"
      :icon="Svg.chat"
      icon_styles="fill-grey-500"
      :date="created_at"
      ready
    >
      <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
      <span>{{ t('audit-chat-message-send') }}</span>
      <TooltipBase trigger="hover">
        <span class="msg">{{ 'сообщение' }}</span>

        <template #content>
          <div class="px-1.5 py-1 text-xs max-w-[260px]">
            <p>{{ meta.message }}</p>
          </div>
        </template>
      </TooltipBase>
      <span>{{ t('audit-chat-message-to-global') }}</span>
    </AuditBase>
  </template>
  <template v-else>
    <AuditBase
      ref="audit"
      :client_id="client_id"
      :icon="Svg.chat"
      icon_styles="fill-grey-500"
      :date="created_at"
      :ready="!!player"
    >
      <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
      <span>{{ t('audit-chat-message-send') }}</span>
      <TooltipBase trigger="hover">
        <span class="msg">{{ 'сообщение' }}</span>

        <template #content>
          <div class="px-1.5 py-1 text-xs max-w-[260px]">
            <p>{{ meta.message }}</p>
          </div>
        </template>
      </TooltipBase>
      <span>{{ t('audit-chat-message-to-pm') }}</span>
      <span class="bright cursor-pointer" @click="() => !!player ? ActivatePlayerDetailsModal(player.steam_id) : {}">{{ player?.steam_name }}</span>
    </AuditBase> 
  </template>
</template>

<style lang="scss" scoped>
.msg {
  @apply text-grey-400;
  @apply cursor-pointer;
  @apply underline underline-offset-4;

  &:hover {
    @apply text-grey-200;
  }
}
</style>