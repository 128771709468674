<script setup lang="ts">
  import { onMounted } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaPlayerBan } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<AuditDto<AuditMetaPlayerBan>>();

  const { t } = useLocaleStore();

  onMounted(() => load());

  const audit = ref<InstanceType<typeof AuditBase>>();
  const ban = ref<BanDto>();

  const load = async () => {
    ban.value = await useCourtApi().ban.browseBatchLoader.load(props.meta.ban_id);
  };
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="Svg.lock"
    icon_styles="fill-red-500"
    :date="created_at"
    :ready="!!ban"
  >
    <template v-if="!!ban">
      <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
      <span>{{ t('audit-edit-ban-player') }}</span>
      <span class="bright cursor-pointer" @click="() => !!ban?.player ? ActivateBanViewModal({ banId: ban.id, ban }) : {}">{{ ban.player?.steam_name || t('general.unknown') }}</span>
    </template>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>