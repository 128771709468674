<script setup lang="ts">
  import Divider from '@/components/Divider.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import Loading from '@/components/Loading.vue';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useLocaleStore } from '@/stores/locale.store';
  import { onMounted, onUnmounted, ref } from 'vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { PlayerAlertCustomApiMetaDto, PlayerAlertDto, PlayerAlertDugUpStashMetaDto, PlayerAlertJoinWithExternalBanMetaDto, PlayerAlertJoinWithIpBanMetaDto, PlayerAlertDetectRaBanMetaDto, PlayerAlertVpnDetectedMetaDto, PlayerAlertLicenseChangedMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import PlayerAlertJoinWithIpBan from '@court/pages/player-alerts/components/PlayerAlertJoinWithIpBan.vue';
  import PlayerAlertJoinWithExternalBan from '@court/pages/player-alerts/components/PlayerAlertJoinWithExternalBan.vue';
  import PlayerAlertDugUpStash from '@court/pages/player-alerts/components/PlayerAlertDugUpStash.vue';
  import PlayerAlertCustomApi from '@court/pages/player-alerts/components/PlayerAlertCustomApi.vue';
  import PlayerAlertDetectRaBan from '@court/pages/player-alerts/components/PlayerAlertDetectRaBan.vue';
  import PlayerAlertVpnDetected from '@court/pages/player-alerts/components/PlayerAlertVpnDetected.vue';
  import PlayerAlertLicenseChanged from '@court/pages/player-alerts/components/PlayerAlertLicenseChanged.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';

  const props = defineProps<{ steamId: string }>();

  const { t } = useLocaleStore();
  const playerAlerts = ref<PlayerAlertDto[]>();

  const _panel = usePanelStore();

  const loader = new PaginationService((page, limit) => useCourtApi().playerAlert.browseFull({
    steam_id: props.steamId,

    page,
    limit
  }), 10);

  onMounted(() => next());

  const playerAlertCreated = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    playerAlertCreated.value = useCentrifugoApi().subscribePlayerAlert(_panel.project.id, (alert) => {
      if (!JSON.stringify(alert).includes(props.steamId)) {
        return;
      }

      playerAlerts.value?.unshift(alert);
    });
  });

  onUnmounted(() => {
    playerAlertCreated.value?.unsubscribe();
  });
  
  const next = async () => {
    const { results } = await loader.next();

    if (!playerAlerts.value) {
      playerAlerts.value = [];
    }

    playerAlerts.value?.push(...results);
  };
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template v-if="playerAlerts && playerAlerts.length == 0">
      <div class="empty">
        <EmptyImage
          type="empty"
          :title="t('modal.player.alerts.empty.title')"
          :subtitle="t('modal.player.alerts.empty.subtitle')"
        />
      </div>
    </template>
    <template v-else>
      <div class="alerts">
        <Loading :can-view="playerAlerts != null" class="!bg-grey-900" />

        <template v-if="playerAlerts != null">
          <template v-for="(item, index) in playerAlerts" :key="item.id">
            <template v-if="item.type === 'join_with_ip_ban'">
              <PlayerAlertJoinWithIpBan :meta="(item.meta as PlayerAlertJoinWithIpBanMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'join_with_external_ban'">
              <PlayerAlertJoinWithExternalBan :meta="(item.meta as PlayerAlertJoinWithExternalBanMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'dug_up_stash'">
              <PlayerAlertDugUpStash :meta="(item.meta as PlayerAlertDugUpStashMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'custom_api'">
              <PlayerAlertCustomApi :meta="(item.meta as PlayerAlertCustomApiMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'detect_ra_ban'">
              <PlayerAlertDetectRaBan :meta="(item.meta as PlayerAlertDetectRaBanMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'vpn_detected'">
              <PlayerAlertVpnDetected :meta="(item.meta as PlayerAlertVpnDetectedMetaDto)" :date="item.created_at" />
            </template>
            <template v-if="item.type === 'license_changed'">
              <PlayerAlertLicenseChanged :meta="(item.meta as PlayerAlertLicenseChangedMetaDto)" :date="item.created_at" />
            </template>
          
            <Divider v-if="index != playerAlerts.length - 1" class="w-full my-4" />
          </template>

          <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
        </template>
      </div>
    </template>
  </ModalMenuExtendedPageLayout>
</template>

<style lang='scss' scoped>
.empty {
  @apply flex flex-col justify-center items-center h-full;
}
.alerts {
  @apply h-max min-h-full w-full relative overflow-hidden;

  &:deep(.json) {
    @apply bg-grey-950 text-sm;
  }

  &:deep(.icon) {
    @apply bg-grey-850;
    @apply border-2 border-dashed border-grey-750;
  }

  &:deep(.alert-skeleton) {
    width: 100% !important;
  }
}
</style>