<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto';
  import PlayerNavigationReportsVariant from './PlayerNavigationReportsVariant.vue';
  import Button from '@/components/Button.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { ref } from 'vue';

  const props = defineProps<{ steamId: string }>();

  const { t } = useLocaleStore();
  const emits = defineEmits<ModalMenuExtendedEvents>();

  const deleteReports = async () => {
    ActivateConfirmModal({
      type         : 'warn',
      title        : t('delete-reports-title'),
      description  : t('delete-reports-subtitle'),
      beforeConfirm: async ()=> {
        await useCourtApi().report.deleteAllOnPlayer(props.steamId);
  
        useRuntimeNotification('success', t('delete-reports-success'));
      },
    });
  };

  const lastBadgeValue = ref<number>();

  const badgeUpdate = (count: number) => {
    emits('badgeUpdate', count);

    lastBadgeValue.value = count;
  };
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template #[t(`report-filter-received`)]>
      <PlayerNavigationReportsVariant
        :steam-id="steamId"
        mode="received"
        @badge-update="(e) => badgeUpdate(e)"
      />
    </template>
    <template #[t(`report-filter-sent`)]>
      <PlayerNavigationReportsVariant
        :steam-id="steamId"
        mode="sent"
        @badge-update="(e) => badgeUpdate(e)"
      />
    </template>

    <!--<template #actions="{ selected }">
      <Button
        v-if="selected === t(`report-filter-received`)"
        preset="default"
        class="!h-full text-sm"
        :text="t('reports-delete-all-profile-button')"
        :disabled="!lastBadgeValue || lastBadgeValue == -1"
        @click="deleteReports()"
      />
    </template>-->
  </ModalMenuExtendedPageLayout>
</template>
