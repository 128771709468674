<script lang="ts">
  import { computed } from 'vue';
  import { CourtCommandDto, CourtCommandPayloadDto, CourtCommandPresetDto } from "@/api/backend/court/commands/commands.dto";
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Step } from '@/components/stepper/stepper.dto';
  import StepperCard from '@/components/stepper/StepperCard.vue';
  import CommandCreateInfo from './components/CommandCreateInfo.vue';
  import CommandCreateCommands from './components/CommandCreateCommands.vue';
  import { ref } from 'vue';
  import { onMounted } from 'vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { ampli } from '@src/ampli';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type CreateCommandProps = {
    create_from?: CourtCommandPresetDto;
    edit?: CourtCommandDto;
  }

  export const ActivateCommandCreateModal = (edit?: CourtCommandDto, create_from?: CourtCommandPresetDto) => {
    const _modal = useModalsStore();

    _modal.open('command-create', { edit, create_from });
  };

</script>

<script setup lang="ts">
  const { t } = useLocaleStore();
  const _court = useCourtStore();

  const props = defineProps<CreateCommandProps & ModalInjectedProps>();

  const command = ref<CourtCommandPayloadDto>({
    name  : '',
    group : '',
    access: '',
    
    commands: [''],

    allow_server_choose: false,
    allow_offline      : false,
    force_confirmation : false,

    active: true,

    created_at: Date.now()
  });

  onMounted(() => {
    if (props.edit) {
      command.value = JSON.parse(JSON.stringify(props.edit));
    }

    if (props.create_from) {
      command.value = {
        name  : props.create_from.name,
        group : props.create_from.group,
        access: props.create_from.access,

        commands: props.create_from.commands,
        
        allow_server_choose: props.create_from.allow_server_choose,
        allow_offline      : props.create_from.allow_offline,
        force_confirmation : props.create_from.force_confirmation,

        active: true,

        created_at: Date.now()
      };
    }
  });
  
  const computedSteps = computed(() => {
    const result: Step<'info' | 'commands'>[] = [];

    result.push({
      name  : 'info',
      header: {
        title   : t('customizations.actions.modal.header.title'),
        subtitle: t('customizations.actions.modal.header.1.subtitle'),
        svg     : Svg.code_brackets,
        svgOpts : 'w-10 h-10 bg-grey-1000 rounded-half p-2 fill-grey-600'
      },
      nextDisabledCondition: () => {
        return !command.value.name || !command.value.group || !command.value.access;
      }
    });

    result.push({
      name  : 'commands',
      header: {
        title   : t('customizations.actions.modal.header.title'),
        subtitle: t('customizations.actions.modal.header.2.subtitle'),
        svg     : Svg.code_brackets,
        svgOpts : 'w-10 h-10 bg-grey-1000 rounded-half p-2 fill-grey-600'
      },
      nextDisabledCondition: () => {
        if (!command.value.commands.length) {
          return true;
        }

        if (command.value.commands.some(v => !v || !v.length)) {
          return true;
        }

        return false;
      }
    });

    return result;
  });

  const updater = ref<() => void>();
  const setUpdater = (func: () => void) => {
    updater.value = func;
  };

  const finish = async () => {
    if (!props.edit) {
      await useCourtApi().commands.create(command.value);

      ampli.customActionCreate({ preset_id: props.create_from?.id });
    } else {
      await useCourtApi().commands.edit(props.edit.id, command.value);
    }

    await _court.warmupCommands();

    props.injected.setBeforeClose(async () => {
      await ActivateConfirmModal({
        type       : 'success',
        title      : t('customizations.action.confirm.success.title'),
        description: props.edit ? t('customizations.action.confirm.success.description.edit') : t('customizations.action.confirm.success.description.create')
      });

      return true;
    });

    props.injected.close();
  };

</script>

<template>
  <div class="command-create-modal">
    <StepperCard
      :steps="computedSteps"
      :callback="finish"
      @updater="setUpdater"
    > 
      <template #info>
        <CommandCreateInfo v-model="command" />
      </template>
    
      <template #commands>
        <CommandCreateCommands v-model="command" :updater="updater" />
      </template>
    </StepperCard>
  </div>
</template>

<style lang="scss" scoped>
.command-create-modal {
  width: 95vw;
  max-width: 430px;
}
</style>