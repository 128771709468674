<script setup lang='ts'>
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useSignagesStore } from './stores/signages.store';
  import SwitchDropdown from '@/components/switch/SwitchDropdown.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { computed } from 'vue';
  import { useCourtStore } from '@court/stores/court.store';

  const { t } = useLocaleStore();
  const _court = useCourtStore();
  const _signages = useSignagesStore();

  const serverChoosed = computed(() => {
    if (!_signages.filters.server_id) {
      return t('player.filters.server-all');
    }

    return _court.serverNameById(_signages.filters.server_id);
  });

  const serverChooser = computed((): ExtendedMenuItem[] => {
    const res: ExtendedMenuItem[] = [{
      label : t('player.filters.server-all'),
      active: () => _signages.filters.server_id == 0,
      action: () => {
        _signages.filters.server_id = 0;
      }
    }];
    
    if (_court.court?.servers) {
      [..._court.court.servers.filter(v => !v.deleted)].sort((a,b) => (a.order ?? 0) - (b.order ?? 0)).forEach(v => {
        res.push({
          label : v.name,
          active: () => _signages.filters.server_id === v.id,
          action: () => {
            _signages.filters.server_id = v.id;
          }
        });
      });
    }

    return res;
  });
</script>

<template>
  <div class="container">
    <SettingsContainer :title="t('reports.filters.title')">
      <SwitchDropdown
        v-model="_signages.filters.server_id"
        :header="t('signages.filters.servers-filter')"
        :choosed="serverChoosed"
        :opts="serverChooser"
      />
      <SwitchText
        v-model="_signages.filters.only_liked"
        :header="t('signages.filters.only-liked')"
      />
    </SettingsContainer>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply bg-grey-900 rounded-md;
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-y-auto;
}
.item {
  @apply flex;
  @apply gap-1;
  @apply bg-white/5;
  @apply rounded-md;
  @apply items-center;
  @apply p-1;
  @apply cursor-pointer;
  @apply text-sm;
  @apply text-grey-400;
}
</style>