<script setup lang="ts">
  import { useSlots } from 'vue';
  import { ref, computed } from 'vue';
  import Loader from '@/components/Loader.vue';

  interface ButtonProps {
    preset?: 'primary' | 'default' | 'default-stroke' | 'default-light' | 'destructive' | 'link' | 'warning' | 'transparent' | 'liked' | 'landing-tab' | 'landing-select' | 'white';
    
    href?: string;
    text?: string;

    action?: () => Promise<unknown> | unknown;
    loading?: boolean;
    active?: boolean;
    loadingColor?: string;

    disabled?: boolean;
  }

  const slots = useSlots();

  const props = withDefaults(defineProps<ButtonProps>(), {
    preset: undefined,

    text: undefined,
    
    action      : undefined,
    loading     : undefined,
    loadingColor: '#F2F2F2',

    disabled: undefined
  });

  const _actionProgress = ref<boolean>(false);

  const isProcessing = computed(() => _actionProgress.value || props.loading);

  const submit = async () => {
    if (!props.action) {
      return;
    }

    _actionProgress.value = true;

    try {
      await props.action();
    } 
    catch (err) {
      throw err;
    }
    finally {
      _actionProgress.value = false;
    }
  };

  defineExpose({ submit });
</script>

<template>
  <component
    :is="!href ? 'button' : 'a'"
    :href="href"
    target="_blank"
    :disabled="disabled || isProcessing"
    class="ra-button"
    :class="{
      disabled: !!props.disabled,
      icon: slots.icon,
      'preset': props.preset !== undefined,
      [preset ?? '']: true,
      '-active': active
    }"
    @click="submit"
  >
    <div
      class="wrapper flex justify-center gap-2"
      :class="[isProcessing ? `opacity-0` : `opacity-100`, { 'items-center': props.text }]"
    >
      <slot name="default" />
      <p
        v-if="props.text"
        :class="{ $slots }"
        :style="isProcessing ? `opacity: 0;` : 'opacity: 100;'"
      >
        {{ props.text }}
      </p>
    </div>
    <Loader
      v-if="isProcessing"
      :color="loadingColor"
      size="18px"
    />
  </component>
</template>

<style lang="scss">
.ra-button {
  // Позиционка
  @apply flex items-center justify-center;
  @apply font-medium;
  @apply overflow-hidden;
  @apply select-none;
  // @apply ease-in-out delay-0 duration-100;

  .wrapper {
    width: inherit;
  }

  svg {
    @apply w-5;
    // @apply ease-in-out delay-0 duration-100;
  }

  &.preset {
    &:not(.link) {
      @apply px-2.5 py-2;
    }
    
    &.primary {
      @apply rounded-md;
      @apply bg-primary-700 text-grey-50;

      svg {
        @apply fill-grey-50;
      }

      @media (hover: hover) {
        &:hover {
          @apply bg-primary-600;
        }
      }

      @media (hover: none) {
        &:active {
          @apply bg-primary-600;
        }
      }
    }

    &.default {
      @apply rounded-md;
      @apply bg-grey-800 text-grey-400;

      svg {
        @apply fill-grey-400;
      }

      @media (hover: hover) {
        &:hover {
          @apply bg-grey-750 text-grey-300;

          svg {
            @apply fill-grey-300;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply bg-grey-750 text-grey-300;

          svg {
            @apply fill-grey-300;
          }
        }
      }
    }

    &.default-stroke {
      @apply rounded-md;
      @apply text-grey-500;
      @apply border border-grey-850;

      svg {
        @apply fill-grey-500;
      }

      @media (hover: hover) {
        &:hover {
          @apply text-grey-300;
          @apply border-grey-750;

          svg {
            @apply fill-grey-300;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply text-grey-300;
          @apply border-grey-750;

          svg {
            @apply fill-grey-300;
          }
        }
      }
    }

    &.default-light {
      @apply rounded-md;
      @apply bg-white/10 text-grey-50/50;

      svg {
        @apply fill-grey-50/50;
      }

      @media (hover: hover) {
        &:hover {
          @apply bg-white/15 text-grey-50/75;

          svg {
            @apply fill-grey-50/75;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply bg-white/15 text-grey-50/75;

          svg {
            @apply fill-grey-50/75;
          }
        }
      }
    }

    &.transparent {
      @apply rounded-md;
      @apply bg-transparent text-grey-600;

      svg {
        @apply fill-grey-600;
      }

      &:hover {
        @apply text-grey-400;

        svg {
          @apply fill-grey-400;
        }
      }

      &:active {
        @apply text-grey-200;

        svg {
          @apply fill-grey-200;
        }
      }
    }

    &.warning { 
      @apply rounded-md; 
      @apply bg-amber-400/15 text-amber-400; 
 
      svg { 
        @apply fill-amber-400; 
      } 
 
      &:hover { 
        @apply bg-amber-400/20 text-amber-300; 
      } 
    }

    &.destructive {
      @apply rounded-md;
      @apply bg-red-600 text-grey-50;

      svg {
        @apply fill-grey-50;
      }

      @media (hover: hover) {
        &:hover {
          @apply bg-red-500 text-grey-50;

          svg {
            @apply fill-grey-50;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply bg-red-500 text-grey-50;

          svg {
            @apply fill-grey-50;
          }
        }
      }
    }

    &.liked {
      @apply rounded-md;
      @apply bg-rose-500 text-grey-50;

      svg {
        @apply fill-grey-50;
      }

      &:hover {
        @apply bg-[#F25A74] text-grey-50;

        svg {
          @apply fill-grey-50;
        }
      }
    }

    &.link {
      @apply text-primary-700;

      svg {
        @apply fill-primary-700;
      }

      &:hover {
        @apply fill-primary-600;
        @apply text-primary-600;
        @apply border-primary-600;

        svg {
          @apply fill-primary-600;
        }
      }
    }

    &.disabled { 
      @apply cursor-not-allowed;
      @apply text-grey-600;
      
      svg {
        @apply fill-grey-600;
      }

      &:not(.link) {
        @apply bg-grey-850;
      }

      &:hover {
        @apply text-grey-600;

        svg {
          @apply fill-grey-600;
        }
      }
    }

    &.landing-tab {
      @apply rounded-md;
      @apply text-14-500;
      @apply bg-transparent text-white/50;

      svg {
        @apply fill-grey-400;
      }

      @media (hover: hover) {
        &:hover {
          @apply text-grey-50;
          @apply bg-white/5;

          svg {
            @apply fill-grey-300;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply text-grey-200;
          @apply bg-white/5;

          svg {
            @apply fill-grey-300;
          }
        }
      }

      &.-active {
        @apply bg-grey-800;
        @apply text-grey-200;
        
        svg {
          @apply fill-grey-200;
        }
      }
    }

    &.landing-select {
      @apply rounded-full;
      @apply bg-transparent text-grey-300;
      @apply font-medium;

      svg {
        @apply fill-grey-300;
      }

      &:hover {
        @apply bg-grey-850;
        @apply text-grey-100;

        svg {
          @apply fill-grey-200;
        }
      }

      &.-active {
        @apply bg-grey-50;
        @apply text-grey-900;
        
        svg {
          @apply fill-grey-900;
        }
      }
    }

    &.white {
      @apply rounded-md;
      @apply bg-grey-50 text-grey-800;
      @apply font-medium;

      svg {
        @apply fill-grey-800;
      }

      @media (hover: hover) {
        &:hover {
          @apply bg-grey-100;
          @apply text-grey-1000;

          svg {
            @apply fill-grey-1000;
          }
        }
      }

      @media (hover: none) {
        &:active {
          @apply bg-grey-100;
          @apply text-grey-1000;

          svg {
            @apply fill-grey-1000;
          }
        }
      }

      &.-active {
        @apply bg-grey-50;
        @apply text-grey-900;
        
        svg {
          @apply fill-grey-900;
        }
      }
    }
  }
}
</style>