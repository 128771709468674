<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import DividedCard from '@/components/DividedCard.vue';
  import Button from '@/components/Button.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { CourtCommandPresetDto } from '@/api/backend/court/commands/commands.dto';
  import { onMounted } from 'vue';
  import Loading from '@/components/Loading.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateCommandCreateModal } from '../create/CommandCreateModal.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { firstBy } from 'thenby';
  import { usePluginDownload } from '@court/utils/plugin.utils';
  import { useCourtApi } from '@/api/backend/court/court.api';


  export const ActivateCommandPresetModal = () => {
    const _modal = useModalsStore();

    _modal.open('command-preset', {});
  };

</script>

<script setup lang="ts">
  import { ref } from 'vue';

  const { t } = useLocaleStore();

  const _court = useCourtStore();
  const commands = ref<CourtCommandPresetDto[]>();

  onMounted(async () => {
    commands.value = await useCourtApi().commands.getPresets();
  });

  defineProps<ModalInjectedProps>();

  const createFrom = (value: CourtCommandPresetDto) => {
    ActivateCommandCreateModal(undefined, value);
  };

  const isAdded = (value: CourtCommandPresetDto) => {
    if (!_court.commands) {
      return false;
    }

    return _court.commands.some(v => v.name === value.name && v.group === value.group && JSON.stringify(v.commands) === JSON.stringify(value.commands));
  };

  const installPlugin = (value: string) => {
    usePluginDownload().extensionDownload(value);
  };
</script>

<template>
  <DividedCard class="command-preset-modal">
    <template #header>
      <div class="header">
        <span class="title">{{ t('modal-preset-command-title') }}</span>
        <Svg.cross_large class="close" @click="injected.close" />
      </div>
    </template>
    <template #default>
      <Loading :can-view="!!commands?.length" class="!bg-grey-900" />
      
      <div v-if="commands" class="actions">
        <template v-for="value in [...commands].sort(firstBy('category', 'desc'))" :key="value.id">
          <div class="rounded-md overflow-hidden">
            <div
              v-if="value.requirements"
              class="bg-amber-400/20 w-full text-sm px-3 py-1 text-amber-400 cursor-pointer flex items-center justify-between"
              @click="installPlugin(value.requirements.plugin ?? '')"
            >
              {{ t('setup-plugin') }} {{ value.requirements.plugin }}
              <Svg.download class="h-4 w-4 fill-amber-400" />
            </div>
            <div
              class="action"
            >
              <ListItem
                :svg="{ component: Svg.code_brackets, classes: 'fill-grey-600' }"
                :title="value.name"
                :message="value.group"
                :skeleton="'avatar'"
              />
              <div class="right">
                <Button
                  preset="primary"
                  :disabled="isAdded(value)"
                  :text="isAdded(value) ? t('general.added') : t('general.add')"
                  class="!py-1.5 !px-2 !text-sm !rounded"
                  @click="createFrom(value)"
                />
              </div>
              <div class="badge" :class="value.access" />
            </div>
          </div>
        </template>
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>
.command-preset-modal {
  width: 95vw;
  max-width: 480px;
  @apply relative;

  :deep(.content) {
    @apply relative;
    height: 360px;
  }
}

.header {
  @apply flex justify-between items-center w-full;

  .title {
    @apply text-grey-50;
    @apply font-semibold;
    @apply block;
  }

  .close {
    @apply w-5;
    @apply fill-grey-400;
    @apply cursor-pointer;

    &:hover {
      @apply fill-grey-200;
    }
  }
}

.actions {
  @apply grid grid-cols-1 gap-2.5;

  .action {
    @apply flex items-center justify-between relative;
    @apply p-3;
    @apply bg-grey-850;

    :deep(.img-box) {
      @apply bg-grey-1000 p-2;
    }
    
    .right {
      @apply flex gap-2;

      svg {
        @apply w-5 fill-grey-600;
        @apply cursor-pointer;

        &:hover {
          @apply fill-grey-400;
        }
      }
    }

    .badge {
      position: absolute;
      left: 0px;
      @apply h-[20px] w-[2px];
      @apply rounded-r-full;

      &.green {
        @apply bg-lime-500;
        box-shadow: #84cc16 0px 0px 12px 0.5px;
      }
      &.yellow {
        @apply bg-amber-500;
        box-shadow: #f59e0b 0px 0px 12px 0.5px;
      }
      &.red {
        @apply bg-red-500;
        box-shadow: #ff5031 0px 0px 12px 0.5px;
      }
      &.admin {
        @apply bg-grey-200;
        box-shadow: #C5C5C5 0px 0px 12px 0.5px;
      }
    }
  }
}
</style>