<script setup lang='ts'>
  import Switch from '@/components/switch/Switch.vue';
  import { BanDataStorage } from '../stores/ban.storage';
  import { computed } from 'vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import BanIpWarning from '../components/BanIpWarning.vue';

  const storage = defineModel<BanDataStorage>({ required: true });

  const affectedPlayers = computed(() => {
    const players: PlayerFullDto[] = [];

    if (storage.value.target.player) {
      players.push(storage.value.target.player);
    }

    if (!storage.value.team.enabled) {
      return players;
    }

    players.push(...storage.value.team.players);

    return players;
  });
</script>

<template>
  <div class="players-ip-list">
    <template
      v-for="player in affectedPlayers"
      :key="player.steam_id"
    >
      <div 
        class="players-ip"
      >
        <PlayerBox
          class="truncate"
          :player="player"
          :subtext="player.ip"
        />
        <div class="flex items-center gap-1">
          <BanIpWarning
            :active="storage.ips[player.steam_id]"
            :ip="player.ip"
            :data="storage.ipsLoaders[player.ip]"
          />
          <Switch v-model="storage.ips[player.steam_id]" />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
.players-ip-list {
  @apply flex flex-col gap-5;
  height: fit-content;
  max-height: 280px;
  @apply overflow-y-auto;
  &.bold-scrollbar {
    @apply pr-2.5;
  }
  .players-ip {
    @apply flex justify-between items-center;
  }
}

</style>