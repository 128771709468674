
<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type ServerEditModalOpts = { 
    server: ServerFullDto;
    
    onUpdated?: () => Promise<void>;
  };

  export const ActivateServerEditModal = async (props: ServerEditModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('server-edit', props);
  };
</script>

<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { ServerEditDto, ServerFullDto } from '@/api/backend/court/servers/servers.dto';
  import { onMounted, ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useValidators } from '@src/composable/validate.composable';

  const { t } = useLocaleStore();
  const { close } = useModalsStore();

  const _validators = useValidators();
  const serverInput = ref<InstanceType<typeof TextInput>>();

  const props = defineProps<ServerEditModalOpts & ModalInjectedProps>();

  onMounted(async () => {
    original.value = JSON.parse(JSON.stringify(props.server));
    info.value = JSON.parse(JSON.stringify(props.server));
  });

  const original = ref<ServerEditDto>();
  const info = ref<ServerEditDto>();
  
  const submitForm = async () => {    
    if (!info.value) {
      return;
    }

    await useCourtApi().servers.edit(props.server.id, info.value); 

    await props.onUpdated?.().catch(err => undefined);

    close();

    useRuntimeNotification('success', t('modal.server.updated'));
  };

</script>

<template>
  <div v-if="info" class="server-edit-modal">
    <DividedCard>
      <template #header>
        <div class="modal-header">
          {{ t('modal.server.header.edit') }}
        </div>
      </template>
      <template #default>
        <div class="flex flex-col gap-5">
          <TextInput
            ref="serverInput"
            v-model="info.name"
            :preinput="info.name"
            :placeholder="t('modal.server.name.placeholder')"
            :label="t('modal.server.name.label')"
            :validator="_validators.ServerName"
            black
          />
          <SwitchText
            v-model="info.is_pirate"
            :header="t('modal.server.is-pirate.header')"
            :placeholder="t('modal.server.is-pirate.description')"
          />
          <SwitchText
            v-model="info.is_devblog"
            :header="t('modal.server.is-devblog.header')"
            :placeholder="t('modal.server.is-devblog.description')"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-2 w-full">
          <Button
            preset="default"
            :text="t('general.close')"
            :action="
              () => {
                props.injected.close();
              }
            "
          />
          <Button
            preset="primary"
            :text="t('modal.server.submit.edit')"
            :disabled="JSON.stringify(original) === JSON.stringify(info) || !serverInput || !!serverInput.activeException"
            :action="() => submitForm()"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.server-edit-modal {
  width: 95vw;
  max-width: 420px;
}
  .inputs {
    @apply grid grid-cols-1 gap-2;
  }
</style>