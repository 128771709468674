
import { Axios } from "axios";
import { CourtDiscordPayloadDto, CourtPublic, CourtDiscordDto, CourtSubscriptionStateDto, CourtOptionsDto } from "./court.dto";

export const useCourtCourtApi = (api: Axios) => {
  const get = async() => {
    const result = await api.get<CourtPublic>("/court");

    return result.data;
  };

  const updateOptions = async(options: CourtOptionsDto) => {
    const result = await api.put<void>(`/court`, options);

    return result.data;
  }; 

  const getSubscription = async() => {
    const result = await api.get<CourtSubscriptionStateDto>(`/court/subscription`);

    result.data.available = result.data.available.sort((a,b ) => a.price - b.price);

    return result.data;
  };

  const setSubscription = async(subscription_id: number) => {
    const result = await api.put<void>(`/court/subscription`, {}, {
      params: {
        subscription_id
      }
    });

    return result.data;
  };

  const paySubscription = async() => {
    const result = await api.post<void>(`/court/subscription`);

    return result.data;
  };


  const getDiscord = async() => {
    const result = await api.get<CourtDiscordDto>('/court/discord');

    return result.data;
  };

  const testDiscord = async(payload: CourtDiscordPayloadDto) => {
    const result = await api.post('/court/discord-test', payload);

    return result.data;
  };

  const updateDiscord = async(payload: CourtDiscordPayloadDto) => {
    const result = await api.put('/court/discord', payload);

    return result.data;
  };
  
  return {
    get,
    updateOptions,
    getSubscription,
    setSubscription,
    paySubscription,
    getDiscord,
    testDiscord,
    updateDiscord,
  };
};
