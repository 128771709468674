import { defineStore } from "pinia";
import { CourtSubscriptionStateDto, SubscriptionStateDto } from "@/api/backend/court/court/court.dto";
import { computed, ref } from "vue";

import { ProjectWalletDto } from "@/api/backend/core/project/project.dto";
import { usePanelStore } from "@panel/stores/panel.store";
import { useCoreApi } from "@src/api/backend/core/core.api";
import { useCourtApi } from "@/api/backend/court/court.api";

import dayjs from 'dayjs';

export const usePaymentsStore = defineStore('payments', () => {
  const _panel = usePanelStore();
  const _coreApi = useCoreApi();
  
  const wallet = ref<ProjectWalletDto>();

  const subscriptions = ref<{
    court: CourtSubscriptionStateDto | undefined
  }>({
    court: undefined
  });

  const warmup = async (clean = true) => {
    if (!_panel.project) {
      return;
    }

    if (clean) {
      wallet.value = undefined;
  
      subscriptions.value = {
        court: undefined
      };
    }

    const [balance, court] = await Promise.all([
      _coreApi.project.getWallet(_panel.project.id),
      useCourtApi().court.getSubscription(),
    ]);

    wallet.value = balance;

    subscriptions.value = {
      court: court,
    };
  };

  const totalSubscriptionPrice = computed(() => {
    return Object.values(subscriptions.value).reduce((acc,v) => acc + (v?.current?.price ?? 0), 0);
  });

  const estimatedDailyPrice = computed(() => {
    return +(totalSubscriptionPrice.value / 30).toFixed(2);
  });

  const isAnySubscriptionNeedPay = computed(() => Object.values(subscriptions.value).some(v => v ? isSubscriptionNeedPay(v) : false));

  const isSubscriptionNeedPay = (subscription: SubscriptionStateDto) => (!subscription.paid_until || Date.now() > subscription.paid_until) && subscription.pay_error;

  const isSubscriptionUnderLimit = (subscription: SubscriptionStateDto) => {
    return isUsageUnderLimit(subscription.usage, subscription.current.limits);
  };

  const estimatedDateEnd = computed(() => {
    if (!wallet.value || !subscriptions.value.court || !subscriptions.value.court.paid_until) {
      return;
    }

    let estimatedDays = Math.min(wallet.value.balance / estimatedDailyPrice.value, 365);
    if (isNaN(estimatedDays)) {
      estimatedDays = 0;
    }

    const estimatedDateEnd = dayjs(subscriptions.value.court.paid_until).add(estimatedDays, 'days');

    return Date.now() < +estimatedDateEnd ? estimatedDateEnd : undefined;
  });

  const isUsageUnderLimit = (usage: Record<string, number> | undefined, limits: Record<string, number> | undefined) => {
    if (!usage || !limits) {
      return true;
    }

    for (const key in usage) {
      if (limits[key] === undefined) {
        continue;
      }

      if (usage[key] > limits[key]) {
        return false;
      }
    }

    return true;
  };

  return { 
    warmup,

    wallet, 
    subscriptions, 
    
    totalSubscriptionPrice, 
    estimatedDailyPrice,
    
    isSubscriptionUnderLimit, 
    isUsageUnderLimit, 
    
    isSubscriptionNeedPay, 
    isAnySubscriptionNeedPay,
    
    estimatedDateEnd
  };
});