/* eslint-disable no-unused-vars */
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';


import { ClientPersonalDto } from '@/api/backend/core/client/client.dto';
import { ProjectClientPersonalDto } from '@/api/backend/core/project/project.dto';
import { DebugService } from '@/api/backend/debug.service';
import { useLocaleStore } from './locale.store';
import { useAdaptiveStore } from './adaptive.store';
import { useSituationStore } from '@panel/stores/situation.store';
import { ampli } from '@src/ampli';
import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
import { useCoreApi } from '@src/api/backend/core/core.api';
import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
import { useDataStore } from './data.store';

export const useAppStore = defineStore('app', () => {
  const _data = useDataStore();

  const debug = new DebugService(`AppStore:#6ab79e`);
  const access_token = _data.sync('access_token', ref<string>(''));

  const _coreApi = useCoreApi();

  useAdaptiveStore();
  useLocaleStore();

  /**
   * У клиента и проекта есть три состояния
   * undefined - загружается, null - загружено с ошибкой, data - всё хорошо
   */
  const client = ref<ClientPersonalDto | null>();
  const projects = ref<ProjectClientPersonalDto[] | null>();

  const warmup = async (clean?: boolean, ignoreCentrifugo?: boolean) => {  
    debug.debug(`Загрузка данных для базового приложения (пользователь и проекты)`);

    try {
      if (clean) {
        client.value = undefined;
        projects.value = undefined;
      }
  
      if (!access_token.value.length) {
        client.value = null;
        projects.value = null;  
        
        debug.debug(`Пользователь не авторизован, не делаем стандартные запросы`);
        return;
      }
  
      try {
        const [clientPublic, projectsPublic] = await Promise.all([
          _coreApi.auth.handshake(),
          _coreApi.project.getMy(),
        ]);
  
        client.value = clientPublic;
        projects.value = projectsPublic;

        ampli.identify(`${client.value.id}-${client.value.tag}@rustapp.user`);

        ampli.clientHandshake();
  
        debug.debug(`Данные загружены, приложение готово к работе`, { client: client.value, projects: projects.value });
  
      } catch (err) {
        debug.warn(`Авторизация завершилась ошибкой`, err);

        if ((err as any)?.statusCode !== 401) {
          useRuntimeNotification(`warning`, `Ошибка подключения к серверу авторизации, переподключаемся...`, {
            overrideTime: 5_000
          });

          await new Promise((res) => setTimeout(res, 5_000));

          await warmup();
          return;
        }
  
        client.value = null;
        projects.value = null;  
      }
    }
    catch (err) {
      throw err;
    }
    finally {
      if (!ignoreCentrifugo) {
        useCentrifugoApi().connect(client.value?.centrifugo_token ?? undefined);
    
        useSituationStore().warmup();
      }
    }
  };

  const updateToken = async (token: string) => {
    access_token.value = token;

    await warmup();
  };

  const logout = () => {
    access_token.value = '';

    window.location.reload();
  };

  return {
    warmup,
    logout,
    access_token,

    client,
    updateToken,
    projects,

    // Загружено, если среди клиента/преокта нет undefined значений
    isReady : computed(() => ![client.value, projects.value].some(v => v === undefined)),
    // Авторизованы, если среди клиента/проекта нет undefined-like значений
    isAuthed: computed(() => ![client.value, projects.value].some(v => !v)),
  };
});
