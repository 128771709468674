import { RouteRecordRaw } from "vue-router";
import ChecksPageVue from "./pages/checks/ChecksPage.vue";
import ReportsPageVue from "./pages/reports/ReportsPage.vue";
import PathNotFound from "@/components/PathNotFound.vue";
import ChatPage from "./pages/chat/ChatPage.vue";
import ChatPageSidebar from "./pages/chat/ChatPageSidebar.vue";
import BansPage from "./pages/bans/BansPage.vue";
import PlayerAlertPage from "./pages/player-alerts/PlayerAlertPage.vue";
import PlayersPage from "./pages/player/PlayersPage.vue";
import ServersPage from "./pages/servers/ServersPage.vue";
import CustomizationPage from "./pages/customization/CustomizationPage.vue";
import IntegrationsPage from "./pages/integrations/IntegrationsPage.vue";
import WelcomePage from "./pages/welcome/WelcomePage.vue";
import StatsPageVue from "./pages/stats/StatsPage.vue";
import AuditPage from "./pages/audit/AuditPage.vue";
import SignagesPage from "./pages/signages/SignagesPage.vue";

export enum CourtRoutes {
  Dashboard = 'dashboard',
  Welcome = 'Welcome',

  Players = 'players',
  Chat = 'chat',
  Reports = 'reports',
  Checks = 'checks',
  
  Bans = 'bans',
  Alerts = 'alerts',

  Signages = 'signages',
  
  Audit = 'audit',
  Stats = 'stats',
  Servers = 'servers',
  Settings = 'settings',
  Discord = 'discord'
}

export const CourtRouter: RouteRecordRaw[] = [
  {
    path     : 'dashboard',
    name     : CourtRoutes.Dashboard,
    component: PathNotFound
  },
  {
    path     : 'welcome',
    name     : CourtRoutes.Welcome,
    component: WelcomePage
  },
  {
    path      : 'chat',
    name      : CourtRoutes.Chat,
    components: {
      default : ChatPage,
      rightbar: ChatPageSidebar
    },
  },
  {
    path     : 'checks/:id?',
    name     : CourtRoutes.Checks,
    component: ChecksPageVue,
  },
  {
    path     : 'bans',
    name     : CourtRoutes.Bans,
    component: BansPage,
  },
  {
    path     : 'alerts',
    name     : CourtRoutes.Alerts,
    component: PlayerAlertPage,
  },
  {
    path     : 'players',
    name     : CourtRoutes.Players,
    component: PlayersPage,
  },
  {
    path     : 'stats',
    name     : CourtRoutes.Stats,
    component: StatsPageVue
  },
  {
    path     : 'servers',
    name     : CourtRoutes.Servers,
    component: ServersPage,
  },
  {
    path     : 'settings',
    name     : CourtRoutes.Settings,
    component: CustomizationPage,
  },
  {
    path     : 'audit',
    name     : CourtRoutes.Audit,
    component: AuditPage
  },
  {
    path     : 'integrations',
    name     : CourtRoutes.Discord,
    component: IntegrationsPage,
  },
  {
    path     : 'reports',
    name     : CourtRoutes.Reports,
    component: ReportsPageVue,
  },
  {
    path     : 'signages',
    name     : CourtRoutes.Signages,
    component: SignagesPage
  }
];


