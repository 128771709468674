import { useAppStore } from "@/stores/app.store";
import { useDataStore } from "@/stores/data.store";
import { usePanelStore } from "@panel/stores/panel.store";
import { ActivateEnterTwoFactorConfirm } from "@src/modals/2fa/EnterTwoFactorModal.vue";
import axios, { AxiosError } from "axios";
import { ref } from "vue";

// Temp method ot fix access_token
const resurrect = () => {
  const str = localStorage.getItem('access_token');
  if (typeof str === 'string' && str.length > 0 && !str?.includes('"')) {
    localStorage.setItem('access_token', `"${str}"`);
  }
};

resurrect();

export const useApiBase = (url: string) => {
  const _app = useAppStore();
  const _panel = usePanelStore();

  const api = axios.create({  
    baseURL: url
  });

  api.interceptors.request.use((config) => {
    config.headers["authorization"] = `Bearer ${_app.access_token}`;

    return config;
  });

  api.interceptors.request.use((config) => {
    config.headers["project-id"] = _panel.project?.id;
    
    return config;
  });

  
  api.interceptors.response.use(
    (res) => res,
    async (error: AxiosError) => {
      const data = error.response?.data ?? (error.response as any);
      if (!data) {
        throw error;
      }

      if (data.message.includes('Need 2FA Confirmation Code')) {
        return await new Promise((res, rej) => {
          ActivateEnterTwoFactorConfirm(async (code) => {
            if (!code) {
              rej({ message: 'No code provided' });
              return;
            }

            const data = await api.request({
              ...error.config,

              headers: {
                ...error.config?.headers ?? {},

                '2fa-code': code
              }
            });

            res(data);
          });
        });
        return;
      }

      const ignoreMessages = ["jwt.expired"];
      if (ignoreMessages.includes(data?.message)) {
        throw error;
      }

      throw data;
    }
  );

  return { api };
};