<script setup lang='ts'>
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { usePlayerAlertStore } from './stores/player-alert.store';
  import BaseTip from '@panel/components/BaseTip.vue';
  import { onMounted, ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  
  const { t } = useLocaleStore();
  const _permission = usePermissionsStore();
  const _playerAlerts = usePlayerAlertStore();

  const available = ref<string[]>(['']);
  onMounted(async () => {
    await update();
  });
  
  const update = async () => {
    available.value = await useCourtApi().playerAlert.getCategories();
    if (!available.value.length) {
      return;
    }

    available.value = available.value.sort((a, b) => +(b == '') - +(a == ''));

    available.value.forEach(v => {
      if (!(v in _playerAlerts.filters.custom_api_categories)) {
        _playerAlerts.filters.custom_api_categories[v] = true;
      }
    });
  };

  const getName = (name: string) => {
    if (!name) {
      return t('no-category');
    }

    if (name.endsWith('• ')) {
      return `${name.replace('• ', '• ' + t('no-category'))}`;
    }

    return name;
  };

  const deleteCategory = (category: string) => {
    ActivateConfirmModal({
      type         : 'warn',
      title        : t('general.sure'),
      description  : t('category-will-be-deleted'),
      beforeConfirm: async () => {
        await useCourtApi().playerAlert.deleteCategory(category);

        _playerAlerts.filters.custom_api_categories[category] = false;

        await update();
      }
    });
  };
</script>

<template>
  <div class="container">
    <SettingsContainer :title="t('player-alerts.filters.title')">
      <SwitchText
        v-model="_playerAlerts.filters.show_join_with_external_ban"
        :header="t('player-alerts.filters.show_join_with_external_ban')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.detect_ra_ban"
        :header="t('player-alerts.filters.show_detect_ra_ban')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.show_join_with_ip_ban"
        :header="t('player-alerts.filters.show_join_with_ip_ban')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.vpn_detected"
        :header="t('player-alerts.filters.vpn_detected')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.show_dug_up_stash"
        :header="t('player-alerts.filters.show_dug_up_stash')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.license_changed"
        :header="t('player-alerts.filters.license-changed')"
      />
      <SwitchText
        v-model="_playerAlerts.filters.custom_api"
        :header="t('player-alerts.filters.custom-api')"
      />
    </SettingsContainer>
    <SettingsContainer
      v-if="available.length"
      :title="t('player-alerts.category.title')"
      :class="{ 'opacity-40 pointer-events-none': !_playerAlerts.filters.custom_api }"
    >
      <template v-for="value in available" :key="value">
        <SwitchText
          v-model="_playerAlerts.filters.custom_api_categories[value]"
          :header="getName(value)"
        >
          <template #action>
            <Svg.delete
              v-if="_permission.isAdminOrOwner()"
              class="fill-red-500 h-4 w-4 mr-2"
              @click.stop="deleteCategory(value)"
            />
          </template>
        </SwitchText>
      </template>
    </SettingsContainer>

    <BaseTip type="hint">
      {{ t('basetip-alert-sidebar-0') }}
      <a
        class="inline hyperlink underline"
        href="https://docs.rustapp.io/ru/dev/custom-alerts.html"
        target="_blank"
      >
        {{ 'API' }}
      </a>
      {{ t('basetip-alert-sidebar-1') }}
    </BaseTip>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply bg-grey-900 rounded-md;
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-y-auto;
}
.item {
  @apply flex;
  @apply gap-1;
  @apply bg-white/5;
  @apply rounded-md;
  @apply items-center;
  @apply p-1;
  @apply cursor-pointer;
  @apply text-sm;
  @apply text-grey-400;
}
</style>