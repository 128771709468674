import { defineStore } from "pinia";
import { ref } from "vue";


import { BanSyncSubscriptionsDto } from "@/api/backend/court/ban/ban.dto";
import { useCourtApi } from "@/api/backend/court/court.api";

export const useBanStore = defineStore("ban", () => {
  const subscription = ref<BanSyncSubscriptionsDto[]>();

  const warmup = async () => {
    const result = await useCourtApi().ban.getSubscriptions();
  
    subscription.value = result.data;
  };

  return {
    subscription,
    warmup
  };
});
