import './assets/styles/tailwind.css';
import 'floating-vue/dist/style.css';

import App from './App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';

import FloatingVue from 'floating-vue';
import vue3GoogleLogin from 'vue3-google-login';

import { createLocale } from './stores/locale.store';
import { dayJsInit } from './utils/setup/dayjs.setup';
import { useAppRouter } from './app.routes';
import PrimeVue, { defaultOptions } from 'primevue/config';
import { useErrorHandler } from './composable/error.composable';
import { ampli } from './ampli';

import ApexCharts from 'apexcharts';
import VueApexCharts from 'vue3-apexcharts';
import { EnvVariables } from './stores/config/env.variables';

import * as Sentry from "@sentry/vue";

ampli.load({ environment: 'default' });

const app = createApp(App);

const router = useAppRouter();

Sentry.init({
  app,
  dsn         : "https://940ba873656115c79221a8f0bb9c1037@o4508304846159872.ingest.de.sentry.io/4508304847274064",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate        : 1.0, 
  tracePropagationTargets : ['localhost', /^https:\/\/rustapp\.dev\//, /^https:\/\/rustapp\.io\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const i18n = createLocale(); 

app.use(vue3GoogleLogin, {
  clientId: EnvVariables.GoogleAuthClientId,
});

//
app.use(FloatingVue, {
  themes: {
    'no-arrow': {
      $extend: 'dropdown',
    },
  },
});

app.config.errorHandler = (e) => useErrorHandler(e);

app.use(VueApexCharts);
app.config.globalProperties.$apexcharts = ApexCharts;

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $apexcharts: typeof ApexCharts;
  }
}

app.use(PrimeVue, { 
  locale: { 
    ...defaultOptions.locale,

    firstDayOfWeek: 1,
    dayNamesShort : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin   : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],

    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  }
});
app.use(i18n);

dayJsInit();

app.use(router);
app.use(createPinia());
app.mount('#app');
