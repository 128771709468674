<script setup lang='ts'>
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import ModalMenuListItem from '@/components/modal-menu/components/ModalMenuListItem.vue';
  import ModalMenuSection from '@/components/modal-menu/components/ModalMenuSection.vue';
  import ModalMenuTab from '@/components/modal-menu/components/ModalMenuTab.vue';
  import { AllowedLanguage, useLocaleStore } from '@/stores/locale.store';
  import { useSoundStore } from '@/stores/sound.store';
  import { useInterfaceStore } from '@court/stores/interface.store';
  import { watch } from 'vue';
  import { ref, computed } from 'vue';

  const { t } = useLocaleStore(); 
  const _locale = useLocaleStore();
  
  defineProps<{
    mobile: boolean;
  }>();
  

  const languages = computed<SelectValue<AllowedLanguage>[]>(() => {
    return [
      {
        value: 'ru',
        text : t('account-menu.language.ru')
      },
      {
        value: 'en',
        text : t('account-menu.language.en')
      },
      {
        value: 'uk',
        text : t('account-menu.language.uk')
      }
    ];
  });

  const _interface = useInterfaceStore();
  const showBattlemetricsVariants = computed<SelectValue<boolean>[]>(() => {
    return [
      {
        value: true,
        text : t('general.yes')
      },
      {
        value: false,
        text : t('general.no')
      },
    ];
  });

  const currentLang = ref<AllowedLanguage>(_locale.lang);

  watch(() => currentLang.value, (newV) => {
    _locale.set(newV);
  });

  const _sound = useSoundStore();
  
</script>

<template>
  <ModalMenuTab
    :mobile="mobile"
  >
    <ModalMenuSection :title="t('modal.client.interface.general')" :mobile="mobile">
      <ModalMenuListItem
        :title="t('modal.client.profile.language.title')"
        :message="t('modal.client.profile.language.message')"
      >
        <!-- todo -->
        <template #right>
          <SelectInput
            v-model="currentLang" 
            :values="languages"
            right
          />
        </template>
      </ModalMenuListItem>
      <ModalMenuListItem
        :title="t('modal.client.interface-battlemetrics.title')"
        :message="t('modal.client.interface-battlemetrics.message')"
      >
        <!-- todo -->
        <template #right>
          <SelectInput
            v-model="_interface.settings.show_battlemetrics" 
            :values="showBattlemetricsVariants"
            right
          />
        </template>
      </ModalMenuListItem>
      <ModalMenuListItem
        :title="t('modal.client.contact-sound-title')"
        :message="t('modal.client.contact-sound-subtitle')"
      >
        <!-- todo -->
        <template #right>
          <SelectInput
            v-model="_interface.settings.play_sound" 
            :values="Object.entries(_sound.soundsAvailable).map(([key, value]) =>({ value: key, text: value.text }))"
            right
          />
        </template>
      </ModalMenuListItem>
    </ModalMenuSection>
  </ModalMenuTab>
</template>

<style lang='scss' scoped>
.avatar-changer {
  @apply w-fit;
  @apply cursor-pointer;
  @apply transition-all ease-in-out delay-0 duration-300;

  .title {
    @apply font-normal text-grey-50;
  }
  .edit {
    @apply text-primary-700;
    @apply lowercase;
  }

  &:hover {
    @apply opacity-75;
  }

  &.loading {
    @apply filter grayscale animate-pulse;
  }
}
</style>