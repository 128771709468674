import { useRoute, useRouter } from 'vue-router';
import { usePermissionsStore } from '@panel/stores/permissions.store';

import { useCopyClipboard } from '@src/composable/copy.composable';
import { useChatStore } from '@court/pages/chat/stores/chat.store';
import { useLocaleStore } from '@/stores/locale.store';
import { ActivateBanCreateModal } from '@court/modals/ban-control/BanCreateModal.vue';
import { ActivatePlayerKickModal } from '@court/modals/player-kick/PlayerKickModal.vue';
import { CourtRoutes } from '@court/court.routes';
import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
import { useContextMenu } from '@/stores/contextmenu.store';
import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
import { ampli } from '@src/ampli';
import { useCourtStore } from '@court/stores/court.store';
import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';
import dayjs from 'dayjs';
import { ActivateCommandExecuteModal } from '@court/modals/commands/execute/CommandExecuteModal.vue';
import { useAppStore } from '@/stores/app.store';
import { useCourtApi } from '@/api/backend/court/court.api';

export const usePlayerMenu = () => {
  const _app = useAppStore();
  const _route = useRoute();
  const _router = useRouter();
  const _chat = useChatStore();
  const _court = useCourtStore();
  const _permissions = usePermissionsStore();
  const { t } = useLocaleStore();
  
  const _contextMenu = useContextMenu();

  const startCheck = async (player: PlayerFullDto) => {
    if (player.active_check_id) {
      _router.push({ name: CourtRoutes.Checks, params: { id: player.active_check_id }});
      return;
    }
  
    (async () => {
      const confirm = await ActivateConfirmModal({
        type         : 'warn',
        title        : t('general.confirm-action'),
        description  : t('check-start-subtitle-check', {player: player.steam_name}),
        beforeConfirm: async () => {
        }
      });

      try {
        if (confirm) {
          const checkId = await useCourtApi().check.create(player.steam_id);

          _router.push({ name: CourtRoutes.Checks, params: { id: checkId }});
        }
      }
      catch (err: any) {
        if (err?.message?.includes('Check start failed, player is offline')) {
          useRuntimeNotification('warning', t('check-call-failed.offline'));
          return;
        }
        if (err?.message?.includes('Check start failed, player is AFK')) {
          useRuntimeNotification('warning', t('check-call-failed.afk'));
          return;
        }
        if (err?.message?.includes('Check start failed, player is raid-blocked')) {
          useRuntimeNotification('warning', t('check-call-failed.rb'));
          return;
        }
        if (err?.message?.includes('Check start failed, player has no reports')) {
          useRuntimeNotification('warning', t('check-call-failed.no-reports'));
          return;
        }

        throw err;
      }
    })();
  };

  const buildCheckOpt = (player: PlayerFullDto): ExtendedMenuItem | null => {
    if (!_permissions.can(['Court_CheckProcess'])) {
      return null;
    }

    if (_route.name === CourtRoutes.Checks && !_route.params.id && player.active_check_id && player.active_check_id == _route.params.id as any) {
      return null;
    }

    const is_player_in_check = player.active_check_id;

    return {
      label   : is_player_in_check ? t('modal.player.header.checking-now') : t('modal.player.header.check'),
      disabled: () => player.status === 'offline',
      action  : () => startCheck(player)
    } as ExtendedMenuItem;
  };

  const buildCopyOpts = (player: PlayerFullDto): ExtendedMenuItem | null => {
    return {
      label : t('modal.player.header.copy-steam-id'),
      action: () => {
        useCopyClipboard(player.steam_id, true);
      }
    };
  };

  const buildChatOpts = (player: PlayerFullDto): ExtendedMenuItem | null => {
    if (!_permissions.can(['Court_ChatReadPublic'])) {
      return null;
    }

    return {
      label : t('modal.player.header.messages'),
      action: () => {
        _router.push({ name: CourtRoutes.Chat });

        _chat.search = [{ steam_name: player.steam_name, steam_id: player.steam_id, steam_avatar: player.steam_avatar }];
        _chat.reply = { steam_name: player.steam_name, steam_id: player.steam_id, steam_avatar: player.steam_avatar, server_id: player.server_id };
      },
    };
  };

  const buildCustomCommandsOpts = (player: PlayerFullDto): ExtendedMenuItem | null => {
    if (!_court.commands) {
      return null;
    }

    const groupCommands: Record<string, CourtCommandDto[]> = {};

    _court.commands.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).forEach(v => {
      if (!v.active) {
        return;
      }

      if (!groupCommands[v.group]) {
        groupCommands[v.group] = [];
      }

      groupCommands[v.group]?.push(v);
    });

    if (!Object.keys(groupCommands).length) {
      return null;
    }

    const groupSubItems = Object.entries(groupCommands).map<ExtendedMenuItem>(([name, commands]) => {
      const obj: ExtendedMenuItem = {
        label: name,
        items: [
          { label: '', action: () => {} } // Фикс для пустого контекста :C
        ]
      };

      commands.forEach(value => {
        obj.items?.push({
          label : value.name,
          action: async () => {
            await ActivateCommandExecuteModal(player.steam_id, value);
          },
          disabled: () => {
            if (value.commands.some(cc => cc.includes('{staff_steam_id}')) && !_app.client?.steam?.steam_id) {
              return true;
            }

            if (!value.allow_offline && player.status === 'offline') {
              return true;
            }

            return false;
          }
        });
      });

      return obj;
    });

    return {
      label: t('custom-actions-context'), 
      items: [
        { label: '', action: () => {} }, // Фикс для пустого контекста :C
        ...groupSubItems
      ]
    };
  };

  const buildReportsOpts = (player: PlayerFullDto): ExtendedMenuItem | null => {
    const buildReportsIgnoreOpts = (): ExtendedMenuItem | null => {
      if (!_permissions.can(['Court_PlayerEditIgnoreReportsTime'])) {
        return null;
      }

      const periods: Record<string, string> = {
        '1'    : t('customization.bans.other.ignore.1'),
        '3'    : t('customization.bans.other.ignore.3'),
        '7'    : t('customization.bans.other.ignore.7'),
        '30'   : t('customization.bans.other.ignore.30'),
        '10000': t('customization.bans.other.ignore.forever'),
      };

      const variants: ExtendedMenuItem[] = [{
        label   : t('general.no'),
        disabled: () => !player.ignore_reports_until || Date.now() > player.ignore_reports_until,
        action  : async () => {
          await useCourtApi().player.editIgnoreReports(player.steam_id, 0);
        
          player.ignore_reports_until = Date.now();
        }
      }];

      Object.entries(periods).forEach(([value, text]) => {
        variants.push({
          label : text,
          action: async () => {
            ActivateConfirmModal({
              type         : 'warn',
              title        : t('ignore-reports-confirm'),
              description  : t('ignore-reports-confirm-subtitle'),
              beforeConfirm: async () => {
                await useCourtApi().player.editIgnoreReports(player.steam_id, +value);
      
                player.ignore_reports_until = +dayjs().add(+value, 'day');
              },
            });
          }
        });
      });

      return {
        label: t('player-sub-menu-ignore-reports'),
        items: variants,
      };
    };

    const buildReportsDelete = () => {
      if (!_permissions.can(['Court_ReportDelete'])) {
        return null;
      }

      return {
        label : t('delete-reports-context'),
        action: () => {
          ActivateConfirmModal({
            type         : 'warn',
            title        : t('delete-reports-title'),
            description  : t('delete-reports-subtitle'),
            beforeConfirm: async ()=> {
              await useCourtApi().report.deleteAllOnPlayer(player.steam_id);
  
              useRuntimeNotification('success', t('delete-reports-success'));
            },
          });
        },
        class: 'destructive'
      };
    };

    const objs: ExtendedMenuItem[] = [
      buildReportsIgnoreOpts(),
      buildReportsDelete(),
      { label: '', action: () => {} } // Фикс для пустого контекста :C
    ].filter(v => v) as ExtendedMenuItem[];

    if (objs.length == 1) {
      return null;
    }

    return {
      label: t('player-sub-menu-reports'),
      items: objs
    };
  };

  
  const playerBan = async (player: PlayerFullDto, team?: PlayerFullDto[]) => {
    await ActivateBanCreateModal({
      player  : player,
      team    : team ?? [],
      callback: async (data) => {
        if (!player) {
          return;
        }

        const bans = (data.bans ?? []);
        if (!bans) {
          return;
        }
    
        await useCourtApi().player.ban(player.steam_id, bans);
      },
      enableCheckStep: false
    });
  };

  const buildBanOpts = (player: PlayerFullDto, team?: PlayerFullDto[]): ExtendedMenuItem | null => {

    if (!_permissions.can(['Court_PlayerBan'])) {
      return null;
    }
    
    return {
      label   : t('modal.player.header.ban'),
      disabled: () => !!player.active_check_id,
      action  : () => playerBan(player, team),
      class   : 'destructive'
    };
  };

  const buildKickOpts = (player: PlayerFullDto): ExtendedMenuItem | null => {
    if (player.status === 'offline' || player.status === 'unstable') {
      return null;
    }

    if (!_permissions.can(['Court_PlayerKick'])) {
      return null;
    }

    return {
      label : t('modal.player.header.kick'),
      action: () => {
        if (!player) {
          return;
        }

        ActivatePlayerKickModal({ player: player });
      },
      class: 'destructive'
    };
  };

  const open = (e: MouseEvent, player: PlayerFullDto, team?: PlayerFullDto[]) => {
    ampli.clientUseContextMenu({ target: 'player-box' });

    const append: (ExtendedMenuItem | null)[] = [
      buildCheckOpt(player),
      buildCopyOpts(player),
      buildChatOpts(player),
      buildCustomCommandsOpts(player),
      buildReportsOpts(player),
      buildBanOpts(player, team),
      buildKickOpts(player)
    ];

    const validatedAppend = append.filter(v => v) as ExtendedMenuItem[];

    _contextMenu.open(e, validatedAppend);
  };

  return { open, playerBan, startCheck };
};
  