<script setup lang="ts">
  import { computed } from 'vue';
  import Loader from '@/components/Loader.vue';

  const props = withDefaults(defineProps<{ text?: string; canView?: boolean; size?: string; transition?: string }>(), {
    text      : 'Дополнительной информации о загрузке не предоставлено',
    canView   : false,
    size      : '30px',
    transition: '0s',
  });

  const withAnimation = computed(() => props.transition !== '0s');
</script>

<template>
  <Transition :name="withAnimation ? 'fade' : 'none'">
    <div
      v-if="!canView"
      class="base"
      :style="`transition: opacity ${transition}`"
    >
      <Loader :size="size" color="#8D8D8D" />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.base {
  @apply flex flex-col justify-center items-center gap-2;
  @apply absolute inset-0;
  @apply bg-grey-1000;
  @apply w-full h-full;

  &.fade-leave-to {
    opacity: 0;
  }

  @apply z-30;
}

</style>