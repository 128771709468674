<script lang="ts">
  export type ConnectTwoFactorModalProps = {
    link: string
  };
  
  export const ActivateConnectTwoFactorModal = (link: string) => {
    const _modal = useModalsStore();

    _modal.open('2fa-setup', {
      link
    });
  };

</script>

<script setup lang="ts">
  import { toDataURL } from 'qrcode';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { onMounted } from 'vue';
  import { watch } from 'vue';
  import { ref } from 'vue';
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import { ActivateEnterTwoFactorConfirm } from './EnterTwoFactorModal.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCoreApi } from '@/api/backend/core/core.api';

  const { t } = useLocaleStore();

  const _app = useAppStore();
  const props = defineProps<ConnectTwoFactorModalProps & ModalInjectedProps>();

  // Генерируем картинку
  const qrImage = ref<string>();

  onMounted(() => generateQrImage());
  watch(() => props.link, (v) => generateQrImage(v), { deep: true });

  const generateQrImage = (link = props.link) => {
    toDataURL(link, (err, url) => {
      qrImage.value = url;
    });
  };


  const finish = async () => {
    await ActivateEnterTwoFactorConfirm(async (code) => {
      if (!_app.client) {
        return;
      }
    
      if (!code) {
        return;
      }
      await useCoreApi().auth.enable2fa(code);
  
      _app.client.twofactor_active = true;;

      props.injected.close();
    });

  };
</script>

<template>
  <div class="modal">
    <DividedCard no-content-padding>
      <template #default>
        <div class="content">
          <div class="header">
            <p class="title">{{ t('2fa-connect-header-title') }}</p>
            <p class="subtitle">{{ t('2fa-connect-header-subtitle') }}</p>
          </div>

          <img
            v-if="qrImage"
            :src="qrImage"
            style="height: 200px; width: 200px;"
            class="rounded-md"
          >
        </div>
      </template>
      <template #footer>
        <Button
          preset="default"
          class="w-full"
          @click="injected.close()"
        >
          {{ t('general.cancel') }}
        </Button>
        <Button
          preset="primary"
          class="w-full"
          :action="finish"
        >
          {{ t('general.next') }}
        </Button>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  width: 95vw;
  max-width: 400px;
  min-height: 300px;

  .content {
    @apply flex flex-col gap-5 items-center p-10;

    .header {
      @apply flex flex-col gap-1;

      .title {
        @apply text-center;
        @apply text-xl font-semibold text-grey-50;
      }

      .subtitle {
        @apply text-center;
        @apply text-base text-grey-500;
      }
    }
  }
}
</style>