<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const { t } = useLocaleStore();
</script>

<template>
  <div class="section">
    <div>
      <div class="badge wip">
        <span>
          <Svg.lab />
        </span>
        <span>{{ t('welcome.cbt.roadmap.wip') }}</span>
      </div>
      <div class="list wip">
        <span>
          {{ t('roadmap-stable-work') }}
        </span>
        <span>
          {{ t('roadmap-chto-to') }}
        </span>
        <span>
          {{ t('roadmap-plugin-rf') }}
        </span>
        <span>
          {{ t('roadmap-servers-stats') }}
        </span>
      </div>
    </div>
    <div>
      <div class="badge planned">
        <span>
          <Svg.date />
        </span>
        <span>{{ t('welcome.cbt.roadmap.planned') }}</span>
      </div>
      <div class="list planned">
        <span>
          {{ t('roadmap-chat-ref') }}
        </span>
        <span>
          {{ t('roadmap-chat-mute') }}
        </span>
        <span>
          {{ t('roadmap-tags-rf') }}
        </span>
        <span>
          {{ t('roadmap-staff-stats-ref') }}
        </span>
        <span>
          {{ t('roadmap-multiacc') }}
        </span>
        <span>
          {{ t('roadmap-role-system') }}
        </span>
        <span>
          {{ t('roadmap-approve') }}
        </span>
      </div>
    </div>
    <div>
      <div class="badge future">
        <span>
          <Svg.clock />
        </span>
        <span>{{ t('welcome.cbt.roadmap.future') }}</span>
      </div>
      <div class="list future">
        <span>
          {{ t('roadmap-appeal-page') }}
        </span>
        <span>
          {{ t('roadmap-project-stats') }}
        </span>
        <span>
          {{ t('roadmap-discord-auth') }}
        </span>
        <span>
          {{ t('welcome.cbt.roadmap.4') }}
        </span>
      </div> 
    </div>
  </div>
</template>

<style lang='scss' scoped>
.section {
  @apply flex flex-col gap-5;
  .badge {
    @apply flex w-fit gap-1.5 p-1;
    @apply text-16-500;

    svg {
      @apply w-5;
    }

    &.wip {
      @apply bg-lime-500/20 text-lime-500 fill-lime-500;
    }
    &.planned {
      @apply bg-cyan-500/20 text-cyan-500 fill-cyan-500;
    }
    &.future {
      @apply bg-amber-500/20 text-amber-500 fill-amber-500;
    }
  }

  .list {
    @apply flex flex-col gap-2.5;
    @apply p-4 pb-0 pr-0;
    @apply text-grey-400 text-16-400;
    @apply border-l-2 border-dashed;
    list-style-type: disc;

    span {
      @apply text-grey-400;
    }

    &.wip {
      @apply border-lime-500/20;
    }
    &.planned {
      @apply border-cyan-500/20;
    }
    &.future {
      @apply border-amber-500/20;
    }
  }
}
</style>