import DataLoader from "dataloader";

import { ReportBrowseDto, ReportPlayerDto } from "../report/report.dto";
import { PlayerBrowseOpts, PlayerMinimalDto, PlayerFullDto, PlayerTeamDto, PlayerDetailsDto, PlayerIpSearchResponseDto, PlayerSearchIpDto, PlayerNameHistoryDto, PlayerIpHistoryDto, PlayerSessionHistoryDto, PlayerAllStatsDto, PlayerKillFeedEntry, PlayerKillsStats } from "./player.dto";
import { PaginatedResponse, PaginationQuery } from "@/api/backend/pagination.service";
import { BanCreatePayloadDto } from "../ban/ban.dto";
import { Axios } from "axios";
import { CheckDto } from "../check/check.dto";

export const useCourtPlayerApi = (api: Axios) => {
  const browseFullBatchLoader = new DataLoader<string, PlayerFullDto | undefined>((keys) => browseFullBatched(keys), {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });
  
  const browseMinimalBatchLoader = new DataLoader<string, PlayerMinimalDto | undefined>((keys) => browseMinimalBatched(keys), {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });
  
  const findByIpBatchLoader = new DataLoader<string, PlayerSearchIpDto | undefined>((keys) => findByIpBatched(keys), {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });

  const browseMinimalBatched = async (keys: readonly string[]) => {
    const data = await browseMinimal({ page: 0, limit: keys.length, offline: true, steam_ids: keys.filter(v => v.length == 17) as string[], sort: 'created_at' });

    return keys.map(v => data.results.find(d => d.steam_id === v));
  };

  const browseFullBatched = async (keys: readonly string[]) => {
    const data = await browseFull({ page: 0, limit: keys.length, offline: true, steam_ids: keys.filter(v => v.length == 17) as string[], sort: 'created_at' });

    return keys.map(v => data.results.find(d => d.steam_id === v));
  };

  const findByIpBatched = async (keys: readonly string[]) => {
    const data = await findByIp(keys as string[]);

    return keys.map(v => data.find(d => d.ip === v)?.result);
  };

  const browseFull = async (opts: PaginationQuery & PlayerBrowseOpts) => {
    const result = await api.get<PaginatedResponse<PlayerFullDto>>(`/players/browse-full`, {
      params: opts
    });

    return result.data;
  };

  const browseMinimal = async (opts: PaginationQuery & PlayerBrowseOpts) => {
    const result = await api.get<PaginatedResponse<PlayerMinimalDto>>(`/players/browse-minimal`, {
      params: opts
    });

    return result.data;
  };

  const browsePlayerTeam = async (steam_id: string, opts: PaginationQuery) => { 
    const result = await api.get<PaginatedResponse<PlayerTeamDto>>(`/players/${steam_id}/team-history`, {
      params: {
        ...opts
      }
    });

    return result.data;
  };

  const browsePlayerSessionHistory = async (steam_id: string, opts: PaginationQuery) => { 
    const result = await api.get<PaginatedResponse<PlayerSessionHistoryDto>>(`/players/${steam_id}/session-history`, {
      params: {
        ...opts
      }
    });

    return result.data;
  };

  const browsePlayerIpHistory = async (steam_id: string, opts: PaginationQuery) => { 
    const result = await api.get<PaginatedResponse<PlayerIpHistoryDto>>(`/players/${steam_id}/ip-history`, {
      params: {
        ...opts
      }
    });

    return result.data;
  };

  const browsePlayerNameHistory = async (steam_id: string, opts: PaginationQuery) => { 
    const result = await api.get<PaginatedResponse<PlayerNameHistoryDto>>(`/players/${steam_id}/name-history`, {
      params: {
        ...opts
      }
    });

    return result.data;
  };

  const findByIp = async (ips: string[]) => {
    const result = await api.get<PlayerIpSearchResponseDto[]>(`/players/find-by-ip`, {
      params: { ips }
    });

    return result.data;
  };

  const editIgnoreReports = async (steam_id: string, days: number) => {
    await api.put(`/players/${steam_id}/ignore-reports`, {}, {
      params: {
        days
      }
    });
  };

  const getReports = async (steam_id: string, opts: PaginationQuery & { variant: 'sent' | 'received' }) => {
    const result = await api.get<PaginatedResponse<ReportBrowseDto>>(`/players/${steam_id}/reports`, {
      params: { 
        ...opts
      }
    });

    return result.data;
  };

  const getStatsSmall = async (steam_id: string, time_from: number, time_to: number) => {
    const result = await api.get<PlayerKillsStats>(`/players/${steam_id}/small-stats`, {
      params: {
        time_from,
        time_to
      }
    });

    return result.data;
  };

  const getStats = async (steam_id: string, time_from: number, time_to: number) => {
    const result = await api.get<PlayerAllStatsDto>(`/players/${steam_id}/stats`, {
      params: {
        time_from,
        time_to
      }
    });

    return result.data;
  };

  const getKillFeed = async (steam_id: string, page: number, time_from: number, time_to: number) => {
    const result = await api.get<PaginatedResponse<PlayerKillFeedEntry>>(`/players/${steam_id}/kill-feed`, {
      params: {
        time_from,
        time_to,
        page,
        limit: 25
      }
    });

    return result.data;
  };

  
  const getChecks = async(steam_id: string, opts: PaginationQuery): Promise<PaginatedResponse<CheckDto>> => {
    const results = await api.get<PaginatedResponse<CheckDto>>(`/players/${steam_id}/checks`, {
      params: {
        ...opts
      },
    });

    return results.data;
  };

  const getDetails = async (steam_id: string) => {
    const result = await api.get<PlayerDetailsDto>(`/players/${steam_id}/details`);

    return result.data;
  };
  
  const kick = async (steam_id: string, reason: string, announce: boolean) => {
    const result = await api.put<PlayerDetailsDto>(`/players/${steam_id}/kick`, null, {
      params: { reason, announce }
    });

    return result.data;
  };  

  const ban = async (steam_id: string, bans: BanCreatePayloadDto[]) => {
    const result = await api.put<PlayerDetailsDto>(`/players/${steam_id}/ban`, { bans });

    return result.data;
  };

  return {
    browseFullBatchLoader,
    browseMinimalBatchLoader,
    findByIpBatchLoader,
    browsePlayerIpHistory,
    browsePlayerNameHistory,
    browsePlayerSessionHistory,
    browseMinimalBatched,
    browseFullBatched,
    findByIpBatched,
    browseFull,
    browseMinimal,
    browsePlayerTeam,
    getStatsSmall,
    findByIp,
    editIgnoreReports,
    getReports,
    getDetails,
    kick,
    getKillFeed,
    getChecks,
    getStats,
    ban,
  };
};
