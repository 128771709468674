<script lang="ts">
  export type GlobalUnbanModalProps = {}

  export const ActivateGlobalUnbanModal = () => {
    const _modal = useModalsStore();

    _modal.open('global-unban', {});
  };

</script>

<script setup lang="ts">
  import DividedCard from '@/components/DividedCard.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import dayjs from 'dayjs';
  import Calendar from 'primevue/calendar';
  import { onMounted, ref, watch } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import Button from '@/components/Button.vue';
  import { watchDebounced } from '@vueuse/core';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import Divider from '@/components/Divider.vue';

  const { t } = useLocaleStore();

  type UnbanSettings = {
    interval_specified: boolean,
    interval: [Date, Date],

    reason_specified: boolean,
    reason_include : string,
    reason_exclude : string
  }

  const props = defineProps<GlobalUnbanModalProps & ModalInjectedProps>();

  const settings = ref<UnbanSettings>({
    interval_specified: false,
    interval          : [
      dayjs().startOf('month').startOf('day').toDate(),
      dayjs().toDate()
    ],

    reason_specified: false,
    reason_include  : '',
    reason_exclude  : ''
  });

  const preview = ref<{
    total: number,
    unban: number
  }>();

  onMounted(() => generatePreview(settings.value));

  const isLoading = ref<boolean>(true);

  watch(() => settings.value, () => {
    isLoading.value = true;
  }, { deep: true });

  watchDebounced(() => settings.value, async (v) => {
    await generatePreview(v);
  }, { deep: true, debounce: 250 });

  const generatePreview = async (set: UnbanSettings) => {
    const interval_start = set.interval_specified ? +set.interval[0] : undefined;
    const interval_end = set.interval_specified ? +set.interval[1] : undefined;

    const reason_include = set.reason_specified && set.reason_include.length > 0 ? set.reason_include : undefined;
    const reason_exclude = set.reason_specified && set.reason_exclude.length > 0 ? set.reason_exclude : undefined;

    const result = await useCourtApi().ban.globalUnbanCalculate(
      interval_start,
      interval_end,

      reason_include,
      reason_exclude
    );
  
    preview.value = {
      total: result.data.total,
      unban: result.data.unban
    };

    isLoading.value = false;
  };

  const process = () => {

    ActivateConfirmModal({
      title        : t('general.sure'),
      description  : t('global-unban-process-text', { amount: t('general.players', { n: preview.value?.unban }) }),
      // prompt       : t('global-unban-confirm-amount', { amount: preview.value?.unban }),
      delay        : 10,
      type         : 'warn',
      beforeConfirm: async () => {
        const interval_start = settings.value.interval_specified ? +settings.value.interval[0] : undefined;
        const interval_end = settings.value.interval_specified ? +settings.value.interval[1] : undefined;

        const reason_include = settings.value.reason_specified && settings.value.reason_include.length > 0 ? settings.value.reason_include : undefined;
        const reason_exclude = settings.value.reason_specified && settings.value.reason_exclude.length > 0 ? settings.value.reason_exclude : undefined;
    
        await useCourtApi().ban.globalUnbanProcess(
          interval_start,
          interval_end,

          reason_include,
          reason_exclude
        );

        useRuntimeNotification('success', t('general-global-unban-process'));
      },
      onClosed: () => {
        props.injected.close();
      }
    });
  };

</script>

<template>
  <div class="global-unban-modal">
    <DividedCard>
      <template #header>
        <p>{{ t('modal.unban.header') }}</p>
      </template>
      <template #default>
        <div class="wrapper">
          <SwitchText
            v-model="settings.interval_specified"
            :header="t('modal.unban.settings.interval.title')"
            :placeholder="t('modal.unban.settings.interval.subtitle')"
          />
  
          <div
            v-if="settings.interval_specified" 
            class="interval-picker"
          >
            <Calendar
              v-model="settings.interval"
              selection-mode="range"
              :manual-input="false"
              :placeholder="t('modal.unban.settings.interval.input.placeholder')"
              class="!w-full !h-[40px]"
              input-class="cursor-pointer !bg-grey-1000 !h-[40px] rounded-md !w-full !text-grey-50"
              date-format="dd.mm.yy"
              :max-date="dayjs().toDate()"
              lang="ru"
            />
          </div>

          <SwitchText
            v-model="settings.reason_specified"
            :header="t('modal.unban.settings.reason.title')"
            :placeholder="t('modal.unban.settings.reason.subtitle')"
          />
          
          <div v-if="settings.reason_specified" class="inputs">
            <TextInput
              v-model="settings.reason_include"
              :label="t('modal.unban.settings.reason_include.input.label')"
              :placeholder="t('modal.unban.settings.reason.input.placeholder')"
            />
            <TextInput
              v-model="settings.reason_exclude"
              :label="t('modal.unban.settings.reason_exclude.input.placeholder')"
              :placeholder="t('modal.unban.settings.reason.input.placeholder')"
            />
          </div>
        </div>

        <Divider dashed class="my-5" />

        <div class="preview">
          <div class="row">
            <p class="name"> {{ t('modal.unban.settings.preview.total') }} </p>
            <p class="value" :class="{ 'animate-pulse opacity-50': isLoading }"> {{ t('general.amount-st', {amount: preview ? preview.total : '??'}) }} </p>
          </div>
          <div class="row">
            <p class="name"> {{ t('modal.unban.settings.preview.unban') }} </p>
            <p class="value green" :class="{ 'animate-pulse opacity-50': isLoading }"> {{ t('general.amount-st', {amount: preview ? preview.unban : '??'}) }} </p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end w-full gap-2.5">
          <Button
            :text="t('general.close')"
            preset="default"
            :action="injected.close"
          />
          <Button
            :text="t('general.unban')"
            preset="destructive"
            :disabled="isLoading || preview?.unban == 0"
            :action="process"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.global-unban-modal {
  width: 95vw;
  max-width: 420px;
}

.preview {
  @apply flex flex-col gap-2;
  @apply w-full;
  @apply text-grey-50;

  .row {
    @apply flex items-start justify-between gap-10;

    .name {
      @apply text-grey-400 shrink-0;
    }
    .value {
      @apply text-grey-200 text-end truncate;

      &.green {
        @apply text-lime-500;
      }
    }
  }
}

.wrapper {
  @apply flex flex-col gap-4;

  .inputs {
    @apply flex flex-col gap-2 p-2;
    @apply bg-grey-850 rounded-md;
  }
}
</style>