<script lang="ts">
  import type { MenuItem } from 'primevue/menuitem';
  import type { Component } from 'vue';
  import Divider from '@/components/Divider.vue';

  export type ExtendedMenuItem = MenuItem & {
    active?: () => boolean;
    action?: (() => Promise<false | void>) | (() => false | void);

    svg?: {
      component: Component,
      class: string
    };
    divider?: boolean;

    items?: ExtendedMenuItem[];
  };
</script>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import ContextMenu from 'primevue/contextmenu';
  import Button from '@/components/Button.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { useEventListener, useMagicKeys, whenever } from '@vueuse/core';

  import { Svg } from '@src/assets/auto_gen_types.dto';

  const _contextMenu = useContextMenu();

  const menu = ref<(InstanceType<typeof ContextMenu>) & { visible: boolean }>();


  onMounted(() => {
    if (!menu.value) {
      return;
    }

    _contextMenu.setup(menu.value);
  });
  
  useEventListener(window, 'mouseenter', (e) => {
    const el = (e?.target as HTMLElement)?.parentNode as HTMLElement;

    if (el?.tagName != 'LI') {
      return;
    }

    const y = el.getBoundingClientRect().top;
    const direction = y > window.innerHeight / 2 ? 'up' : 'down';
    
    el.setAttribute('direction', direction);
  }, { capture: true });

  const recalculatedItems = computed<(ExtendedMenuItem[]) | undefined>(() => {
    if (!_contextMenu.options) {
      return undefined;
    }

    const reformatItems = (items: ExtendedMenuItem[]): ExtendedMenuItem[] => {
      if (!items.map) {
        return [];
      }

      return items.map<ExtendedMenuItem>(v => {
        if (v.action) {
          return reformatItem(v);
        }
        
        if (!items.map) {
          return [];
        }

        if (v.items?.length) {
          v.items = reformatItems(v.items);
        }

        return v;
      });
    };

    const reformatItem = (item: ExtendedMenuItem) => {
      if (item.action) {
        item.items = [{}];
        item.command = () => {};
      }

      return item;
    };

    return reformatItems(_contextMenu.options);
  });

  const overrideClasses = ({ instance }: { instance: any }) => {
    if (instance.level < 1) {
      return {};
    }

    // За счёт recalculatedItems для любого action добавляется псевдо-оверлей, который не даёт закрыться контексту
    if (instance.items?.length === 1 ) {
      return { class: 'p-pseudo-hide' };
    }
  };

  const handleClick = async (item: ExtendedMenuItem): Promise<unknown> => {
    if (!item.action) {
      return;
    }

    const result = await item.action();
    if (result === false) {
      return;
    }

    menu.value?.hide();
  };

  useEventListener(window, 'click', (e) => {
    const target = e.target as HTMLElement;

    if (!target) {
      return;
    }
    
    const closest = target.closest(".p-component");
    if (!closest) {
      menu.value?.hide();
    }
  }, { capture: true });

  useEventListener(window, 'contextmenu', (e) => {
    if (menu.value?.visible) {
      menu.value?.hide();
      e.preventDefault();
      return;
    }
  }, { capture: true });

  const { Escape } = useMagicKeys();

  whenever(Escape, () => {
    menu.value?.hide();
  });

  const updateDirection = (e: MouseEvent, item: ExtendedMenuItem) => {
    const y = (e.target as HTMLElement).getBoundingClientRect().top;

    const direction = y > window.innerHeight / 2 ? 'up' : 'down';

    (e.target as HTMLElement).setAttribute('direction-test', direction);
  };
</script>

<template>
  <ContextMenu
    ref="menu"
    :model="recalculatedItems"
    :base-z-index="110"
    popup
    :pt="{
      menu: (opts) => overrideClasses(opts),
      submenu: (opts) => overrideClasses(opts),
    }"
  >
    <template #item="item">
      <Button
        v-if="item.item.label && !item.item.divider"
        :action="() => handleClick(item.item)"
        class="button whitespace-nowrap"
        :class="{ [item.item.class]: true, activeOrHighlighted: item.item.active?.(), 'opacity-40 pointer-events-none !hover:bg-none': typeof item.item.disabled === 'function' && (item.item.disabled as any)() }"
        @mouseenter="(e: MouseEvent) => updateDirection(e, item)"
      >
        <div class="w-full flex justify-between items-center">
          <div class="flex items-center w-full">
            <template v-if="item.item.svg">
              <component
                :is="item.item.svg.component"
                :class="item.item.svg.class"
                class="mr-2 h-4 w-4"
              />
            </template>
            <template v-else-if="item.item.icon">
              <img
                :src="item.item.icon"
                class="mr-2 h-5 w-5 rounded-half"
              >
            </template>
  
            <p class="truncate">
              {{ item.label }}
            </p>
          </div>
  
          <template v-if="item.hasSubmenu && (item.item?.items?.length ?? 0) > 1">
            <Svg.arrow class="arrow" />
          </template>
  
          <template v-if="item.item.active?.()">
            <Svg.checkmark class="w-4 fill-grey-50" />
          </template>
        </div>
      </Button>
      <template v-if="item.item.divider">
        <Divider class="my-2 w-full" />
      </template>
    </template>
  </ContextMenu>
</template>

<style lang="scss">
.p-tieredmenu[style*="transform-origin: center top;"] {
  transform: translateY(10px);
}
.p-tieredmenu[style*="transform-origin: center bottom;"] {
  transform: translateY(-10px);
}

.p-submenu-list[style*="left: -100%"], .p-submenu-list[style*="left: 0 !important"] {
  right: 100%;
  left: revert !important;
  width: fit-content;
  margin-right: 10px !important;
  margin-left: 0 !important;
}

.p-contextmenu, .p-submenu-list, .p-tieredmenu {
  @apply relative;

  &.p-contextmenu-enter-to {
    transition: none !important;
  }

  z-index: 50;
  
  .button {
    width: -webkit-fill-available;
  }

  @apply border border-transparent;
  @apply rounded-lg;
  @apply bg-grey-800;
  
  padding: 5px;
  min-width: 150px;
  max-width: 250px;
  box-shadow: rgba(20, 20, 20, 0.8) 0px 5px 20px;
}

.p-contextmenu-root-list, .p-submenu-list, .p-submenu-list, .p-tieredmenu-root-list {
  display: flex !important;
  
  @apply flex-col;
}

.p-menuitem {
  &[direction='up'] {
    .p-submenu-list {
      top: revert !important;
      bottom: calc(100% - 39px) !important;
    }
  }
}

.p-submenu-list {
  top: -5px !important;
  margin-left: 10px;
  position: absolute;
}

.p-pseudo-hide {
  display: none !important;
}

li.p-menuitem {
  @apply relative;

  > .p-menuitem-content {

    .button {
      @apply rounded;
      @apply flex items-center;
      @apply cursor-pointer;
      @apply px-2 py-1.5;
      @apply w-full;
      @apply text-start;
      @apply text-grey-200;
      @apply duration-0;

      &.destructive {
        @apply text-red-500;
      }
      
      .arrow {
        @apply ml-3;
        @apply fill-grey-300;
        @apply w-6;
      }

      &:hover {
        @apply bg-grey-700 text-grey-50;

        &.destructive {
          @apply bg-red-500/20 text-red-500;
        }

        .arrow {
          @apply fill-grey-50;
        }
      }
    }
  }
}

</style>