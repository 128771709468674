<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import StepperCard from '@/components/stepper/StepperCard.vue';
  import ClientManageInviteStep from './components/ClientManageInviteStep.vue';
  import ClientManagePermissionsStep, { PermissionsStepsOpts } from './components/ClientManagePermissionsStep.vue';
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { ProjectClientDto } from '@/api/backend/core/project/project.dto';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { useConfigStore } from '@/stores/config/config.store';

  export type ClientManageModalProps = {
    client?: ProjectClientDto;
  }

  export const ActivateClientManageModal = async (props: ClientManageModalProps) => {
    const _modal = useModalsStore();

    _modal.open('client-permissions', props);
  };
</script>

<script setup lang="ts">
  import { computed, onMounted } from 'vue';
  import { Step } from '@/components/stepper/stepper.dto';

  const props = defineProps<ClientManageModalProps & ModalInjectedProps>();

  const { t } = useLocaleStore();
  const _panel = usePanelStore();

  const computedSteps = computed(() => {
    const result: Step<'invite' | 'edit'>[] = [];
    
    if (!props.client?.client) {
      result.push({
        name  : 'invite',
        header: {
          title: t('permissions.invite.title'),
        },
        maxWidth             : '400px',
        nextDisabledCondition: () => !!inviteStepRef.value?.isFilled,
        nextMiddleware       : async () => {
          const client = await useCoreApi().utils.getClientByTag(tag.value).catch(err => undefined);
          if (!client) {
            ActivateConfirmModal({
              type       : 'warn',
              title      : t('permissions.invite.no-user-title'),
              description: t('permissions.invite.no-user-subtitle'),
            });

            return false;
          }

          if (_panel.projectClients.find(v => v.client.id === client.id)) {
            ActivateConfirmModal({
              type       : 'warn',
              title      : t('permissions.invite.no-user-title'),
              description: t('permissions.invite.user-exists-subtitle'),
            });
            
            return false;
          }

          loadedClient.value = client;

          return true;
        },
      });
    }

    const clientTag = `@${computedClient.value?.tag}`;

    result.push({
      name    : 'edit',
      maxWidth: '500px',
      header  : {
        title   : props.client ? t('permissions.edit-title') : t('permissions.edit-title-invite'),
        subtitle: props.client ? t('permissions.edit-subtitle', { tag: clientTag }) : t('permissions.edit-subtitle-invite', { tag: clientTag }),
        img     : computedClient.value?.avatar ? useConfigStore().Urls.Images(computedClient.value.avatar) : undefined
      },
      nextDisabledCondition: () => {
        if (!permissionsModel.value?.permissions) {
          return true;
        }

        if (permissionsModel.value?.permissions.length == 0 && !permissionsModel.value.isImmutable) {
          return true;
        }

        if (props.client) {
          const arePermissionsChanged = JSON.stringify([...props.client.permissions].sort()) != JSON.stringify([...permissionsModel.value.permissions].sort());

          if (!arePermissionsChanged && props.client?.is_immutable == permissionsModel.value?.isImmutable) {
            return true;
          }
        }


        return false;
      }
    });

    return result;
  });

  const tag = ref<string>('');

  const loadedClient = ref<ClientDto>();
  const inviteStepRef = ref<InstanceType<typeof ClientManageInviteStep>>();

  const updateHeightDelegate = ref<() => unknown>();
  const permissionsModel = ref<PermissionsStepsOpts>();

  onMounted(() => {
    permissionsModel.value = {
      permissions: props.client?.permissions ?? [],
      isImmutable: props.client?.is_immutable ?? false
    };
  });

  const computedClient = computed(() => {
    return props.client?.client || loadedClient.value;
  });

  const finish = async () => {
    if (!computedClient.value || !_panel.project || !permissionsModel.value) {
      return;
    }

    await useCoreApi().project.editClient(computedClient.value.id, _panel.project.id, permissionsModel.value.permissions, permissionsModel.value.isImmutable);

    await _panel.updateClients();

    
    props.injected.setBeforeClose(async () => {
      await ActivateConfirmModal({
        type       : 'success',
        title      : t('permissions.edit-ok-title'),
        description: !props.client ? t('permissions.invite-ok-subtitle') : t('permissions.edit-ok-subtitle'),
      });

      return true;
    });

    props.injected.close();
  };
</script>

<template>
  <div class="permissions-modal">
    <StepperCard
      :steps="computedSteps"
      :callback="finish"
      @updater="(updateHeight) => updateHeightDelegate = updateHeight"
    >
      <template #invite>
        <ClientManageInviteStep ref="inviteStepRef" v-model="tag" />
      </template>
      <template #edit>
        <ClientManagePermissionsStep
          v-if="computedClient && permissionsModel"
          v-model="permissionsModel"
          :client="computedClient"
          :injected="injected"
          :update-height="updateHeightDelegate"
        />
      </template>
    </StepperCard>
  </div>
</template>

<style lang="scss" scoped>
.permissions-modal {
  // height: 80vh;
  // max-height: 560px;

  width: 95vw;
  max-width: 480px;

  .admin-switch {
    @apply bg-grey-850;
    @apply p-2.5;
    @apply rounded-md;
    @apply items-start;

    &:deep(.text-subtitle) {
      @apply pt-1;
      @apply text-grey-300;
    }
  }

  .section {
    @apply flex flex-col gap-2.5;
    @apply mt-5;
    .subsection {
      @apply ml-5;
      @apply flex flex-col gap-1.5;
    }
  }
  &:deep(.swiper-slide) {
    .content {
      padding: 0;
    }
  }
}
</style>