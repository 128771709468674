<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';  
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { onMounted } from 'vue';
  import { ref } from 'vue';

  const { t } = useLocaleStore();
  const _locale = useLocaleStore();
  const emits = defineEmits(['signIn']);
  const props = defineProps<{ code?: string; email?: string; }>();
  const _validators = useValidators();

  const data = ref({
    email    : '',
    password1: '',
    password2: ''
  });

  const emailInput = ref<InstanceType<typeof TextInput>>();
  const button = ref<InstanceType<typeof Button>>();

  onMounted(() => {
    data.value.email = props.email || '';

    if (props.code) {
      restoreCheck();
    }
  });

  const restoreCheck = async () => {
    if (props.code) {
      try {
        await useCoreApi().auth.classicRestoreCheck(props.code);
      }
      catch (err: any) {
        if (err?.message?.includes?.('Confirmation object not found')) {
          ActivateConfirmModal({
            type       : 'error',
            title      : t('auth-restore-expired-code'),
            description: t('auth-restore-expired-code-subtitle'),
            onClosed   : () => {
              emits('signIn');
            }
          });
          return;
        }

        throw err;
      }
    }
  };

  const restoreStart = async () => {
    if (!emailInput.value || !!emailInput.value.activeException) {
      return;
    }

    try {
      await useCoreApi().auth.classicRestoreStart(data.value.email, window.location.origin + `/client/auth`, _locale.lang);
      
      ActivateConfirmModal({
        type       : 'success', 
        title      : t('auth-restore-check-email'),
        description: t('auth-restore-check-email-subtitle')
      });

      emits('signIn');
    }
    catch (err: any) {
      if (err?.message?.includes?.('User not found, or bad login method')) {
        useRuntimeNotification('error', t('auth-restore-bad-email'));
        return;
      }

      throw err;
    }
  };

  const restoreFinish = async () => {
    if (!emailInput.value || !!emailInput.value.activeException) {
      return;
    }

    if (!props.code) {
      return;
    }

    try {
      await useCoreApi().auth.classicRestoreComplete(props.code, data.value.password1);
    
      ActivateConfirmModal({
        type       : 'success', 
        title      : t('auth-restore-success'),
        description: t('auth-restore-success-subtitle'),
        onClosed   : () => {
          emits('signIn');
        }
      });
    }
    catch (err: any) {
      if (err?.message?.includes?.('Confirmation object not found')) {
        useRuntimeNotification('error', t('auth-restore-expired'));
        return;
      }

      throw err;
    }
  };
</script>

<template>
  <div class="auth">
    <div class="header">
      <p class="title">{{ t('auth-restore-header') }}</p>
      <span class="subtitle">
        <template v-if="code">
          {{ t('auth-restore-complete-subtitle') }}
        </template>
        <template v-else>
          {{ t('auth-restore-subtitle') }}
        </template>
      </span>
    </div>

    <div class="content">
      <div class="inputs">
        <TextInput 
          ref="emailInput" 
          v-model="data.email" 
          type="email" 
          name="email"
          autocomplete="email"
          inputmode="email"
          :label="t('auth.email.input.label')"
          placeholder="garry@example.com" 
          :validator="_validators.Email"
          :disabled="!!props.email"
          height="42" 
          @on-enter="() => button?.submit()" 
        />

        <template v-if="code">
          <TextInput 
            ref="passwordInput1" 
            v-model="data.password1" 
            type="password" 
            name="password"
            autocomplete="new-password"
            :label="t('auth.restore-password-1')"
            placeholder="•••••••••••••••••" 
            :validator="_validators.Password" 
            height="42" 
            @on-enter="() => button?.submit()" 
          />
        </template>
      </div>

      <Button
        ref="button"
        :disabled="!emailInput || !!emailInput?.activeException" 
        preset="primary"
        class="btn"
        :text="email ? t('auth.button.restore-complete') : t('auth.button.continue')"
        :action="email ? restoreFinish : restoreStart"
      />
    </div>
  </div>
</template>