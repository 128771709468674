<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import Divider from '@/components/Divider.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto'; 

  const { t } = useLocaleStore();
</script>

<template>
  <div class="section">
    <p class="inline-flex gap-2 !pb-2.5">
      <span>
        <Svg.lab class="w-5 fill-grey-50" />
      </span>
      <span class="text-18-500 text-grey-50">{{ t('welcome.cbt.context-menu.title') }}</span>
    </p>
    <p class="text-16-400 text-grey-400">{{ t('welcome.cbt.context-menu.subtitle') }}</p>

    <div class="items">
      <div class="item">
        <div class="image">
          <img src="https://i.imgur.com/wbNo26Y.png">
        </div>
        <div class="text">
          <p class="font-medium">{{ t('welcome.cbt.context-menu.items.1.title') }}</p>
          <p class="text-grey-400 text-sm">{{ t('welcome.cbt.context-menu.items.1.subtitle') }}</p>
        </div>
      </div>
      <div class="item">
        <div class="image">
          <img src="https://i.imgur.com/uV9TlYc.png">
        </div>
        <div class="text">
          <p class="font-medium">{{ t('welcome.cbt.context-menu.items.2.title') }}</p>
          <p class="text-grey-400 text-sm">{{ t('welcome.cbt.context-menu.items.2.subtitle') }}</p>
        </div>
      </div>
      <div class="item">
        <div class="image">
          <img src="https://i.imgur.com/26c2oJw.png">
        </div>
        <div class="text">
          <p class="font-medium">{{ t('welcome.cbt.context-menu.items.3.title') }}</p>
          <p class="text-grey-400 text-sm">{{ t('welcome.cbt.context-menu.items.3.subtitle') }}</p>
        </div>
      </div>
    </div>
  </div>

  <Divider class="my-8" />

  <div class="section">
    <p class="inline-flex gap-2 !pb-2.5">
      <span>
        <Svg.old_phone class="w-5 fill-grey-50" />
      </span>
      <span class="text-18-500 text-grey-50">{{ t('welcome.cbt.pwa.title') }}</span>
    </p>
    <p class="text-16-400 text-grey-400">{{ t('welcome.cbt.pwa.subtitle') }}</p>
  </div>
</template>

<style lang='scss' scoped>
.items {
  @apply grid grid-cols-3 gap-4 mt-5;

  .item {
    @apply rounded-lg overflow-hidden;
    @apply bg-grey-950 border border-grey-900;
    @apply cursor-default;
    transition: all 300ms ease 0s;

    .image {
      @apply object-cover;
      img {
        @apply w-full;
        filter: contrast(1.10);
      }
    }

    .text {
      @apply flex flex-col gap-1;
      @apply p-4;
      @apply border-t border-grey-900;
    }

    &:hover {
      @apply border-grey-850;
      box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.5);
      transform: translate3d(0px, -5px, 0px);
    }
  }
}
[device="mobile"] {
  .items {
    @apply grid-cols-1;
  }
}

.warning-list {
  @apply rounded-md bg-grey-950;
  @apply border border-white/5;
  p {
    @apply p-2.5;
    @apply text-grey-400;
    @apply border-b border-white/5;
    &:last-child {
      @apply border-none;
    }
  }
}
</style>