<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';  
  import { useValidators } from '@src/composable/validate.composable';
  import { ref } from 'vue';

  const { t } = useLocaleStore();
  const props = defineProps<{ confirm: (token: string) => Promise<unknown> }>();
  const emits = defineEmits(['signUp', 'restore']);
  const _validators = useValidators();

  const data = ref<{ email: string, password: string }>({
    email   : '',
    password: ''
  });

  const emailInput = ref<InstanceType<typeof TextInput>>();
  const passwordInput = ref<InstanceType<typeof TextInput>>();
  const button = ref<InstanceType<typeof Button>>();

  const classicSignIn = async () => {
    const { email, password } = data.value;

    try {
      const token = await useCoreApi().auth.classicSignIn(email, password);

      await props.confirm(token);
    }
    catch (err: any) {
      if (err?.message?.includes('email.not.exists') || err?.message?.includes('password.missmatch')) {
        useRuntimeNotification(`warning`, t('auth-sign-in-classic-bad-creds'));
        return;
      }

      throw err;
    }
  };
</script>

<template>
  <div class="auth">
    <div class="header">
      <p class="title">{{ t('auth-sign-in-header') }}</p>
      <span class="subtitle">
        {{ t('auth-sign-in-subtitle') }}
        <span class="link" @click="emits('signUp')">{{ t('auth-sign-in-subtitle-action') }}</span>.
      </span>
    </div>

    <div class="content">
      <div class="inputs">
        <TextInput 
          ref="emailInput" 
          v-model="data.email" 
          type="email"
          name="email" 
          autocomplete="email"
          inputmode="email"
          :label="t('auth.email.input.label')"
          placeholder="garry@example.com" 
          :validator="_validators.Email" 
          height="42"
        />

        <TextInput 
          ref="passwordInput"
          v-model="data.password" 
          type="password"
          :autocomplete="'current-password'"
          :label="t('auth.password.input.label')"
          placeholder="•••••••••••••••" 
          :validator="_validators.Password" 
          height="42" 
          @on-enter="() => button?.submit()" 
        >
          <template #action>
            <Button
              preset="link"
              class="!font-normal"
              @click="emits('restore')"
            >
              {{ t('auth.password.input.recover') }}
            </Button>
          </template>
        </TextInput>
      </div>

      <Button
        ref="button"
        :disabled="!emailInput || !!emailInput?.activeException ||!passwordInput || !!passwordInput?.activeException" 
        preset="primary"
        class="btn"
        :text=" t('auth.button.continue')"
        :action="classicSignIn"
      />
    </div>
  </div>
</template>