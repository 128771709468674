<script setup lang='ts' generic="T extends string">
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css';

  import SwiperInstance from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation } from 'swiper/modules';
  import Button from '@/components/Button.vue';
  import { computed, onMounted, ref } from 'vue';
  import StepperHeader from './StepperHeader.vue';
  import { Step } from './stepper.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useModalsStore } from '@/stores/modals.store';

  const { t } = useLocaleStore();
  const props = defineProps<{
    steps: Step<T>[],
    callback: () => Promise<unknown>
  }>();

  onMounted(() => {
    updateHeight();
  });
  
  const emit = defineEmits(['updater']);
  const _modal = useModalsStore();

  const prev = ref<HTMLElement | null>(null);
  const next = ref<HTMLElement | null>(null);

  const activeIndex = ref<number>(0);

  const currentSwiper = ref<SwiperInstance>();
  const slideHeight = ref(300);
  
  const getActiveHeader = () => {
    return props.steps[activeIndex.value].header;
  };

  const isLastSlide = (): boolean => {
    return activeIndex.value === props.steps.length - 1;
  };

  const updateHeight = () => {
    if (!currentSwiper.value) {
      return;
    }

    const activeSlide = currentSwiper.value.slides?.[activeIndex.value];
    const content = activeSlide?.children[0];
    slideHeight.value = content?.clientHeight ?? 300;
  };

  const isFirstSlide = (): boolean => {
    return activeIndex.value === 0;
  };

  onMounted(() => {
    emit('updater', updateHeight);
  });

  const isNextDisabled = computed(() => {
    return props.steps[activeIndex.value].nextDisabledCondition?.() ?? false;
  });

  const nextStep = async () => {
    const middleware = props.steps[activeIndex.value].nextMiddleware;
    if (!middleware) {
      await currentSwiper.value?.slideNext();
      
      return;
    }

    const result = await middleware().catch(err => false);
    if (!result) {
      return;
    }

    await currentSwiper.value?.slideNext();
  };

  const prevStep = async () => {
    if (!activeIndex.value) {
      _modal.close();
    }

    await currentSwiper.value?.slidePrev();
  };

  const finish = async () => {
    await props.callback();
  };
</script>

<template>
  <div class="stepper-card">
    <StepperHeader v-bind="getActiveHeader()" />
    <swiper
      class="w-full !overflow-y-visible"
      direction="horizontal"
      :pagination="{
        el: '.swiper-pagination',
      }"
      :watch-overflow="false"
      :speed="200"
      :navigation="{
        prevEl: prev,
        nextEl: next
      }"
      :simulate-touch="false"
      :allow-touch-move="false"
      :modules="[Pagination, Navigation]"
      @swiper="(s) => currentSwiper = s"
      @active-index-change="(e) => {
        activeIndex = e.activeIndex;
        updateHeight();
      }"
    >
      <template v-for="step in props.steps" :key="step.name">
        <SwiperSlide :style="{ height: `${slideHeight}px`, transition: 'height .1s' }">
          <div class="content">
            <slot :name="step.name" />
          </div>
        </SwiperSlide>
      </template>
    </swiper>
    <div class="footer">
      <div v-if="steps.length > 1" class="swiper-pagination" />
      <div
        ref="prev"
        class="ml-auto"
      >
        <Button
          v-if="!isFirstSlide()"
          :text="t('general.back')"
          :action="prevStep"
          preset="default"
          class="slidePrev-btn"
        />
        <Button
          v-else 
          :text="t('general.close')"
          :action="prevStep"
          preset="default"
          class="slidePrev-btn"
        />
      </div>
      <div>
        <Button
          v-if="!isLastSlide()"
          :text="steps[activeIndex].customNextButton ?? t('general.next')"
          :disabled="isNextDisabled"
          :action="nextStep"
          preset="primary"
          class="slideNevt-btn"
        />
        <Button
          v-else
          :text="t('general.finish')"
          :disabled="isNextDisabled"
          :action="finish"
          preset="primary"
        />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>

:deep(.swiper-pagination) {
  position: static;
  z-index: 0;
  width: fit-content;
  height: fit-content;

  .swiper-pagination-bullet {
    text-align: center;
    width: 6px;
    height: 6px;
    @apply rounded-md;
    @apply bg-grey-300;
    transition: 0.2s ease-in-out;
    &:only-child {
      display: inline-block !important;
    }
  }
  .swiper-pagination-bullet-active {
    @apply bg-grey-50;
    width: 16px;
  }
}

.stepper-card {
  overflow-y: clip;
  @apply flex flex-col;
  @apply h-full w-full;
  .content {
    @apply flex flex-col gap-5;
    @apply p-5;
  }
  .footer {
    @apply flex gap-3 items-center;
    @apply border-t border-grey-1000;
    @apply p-5;
  }
}

</style>