<script setup lang='ts'>
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { CheckActivityMetaStartedDto, CheckDto } from '@/api/backend/court/check/check.dto';
  import { onMounted, ref } from 'vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    meta: CheckActivityMetaStartedDto, 
    check: CheckDto,
    reversed: boolean,
    created: number,
  }>();

  const players = ref<PlayerFullDto[]>();

  onMounted(async () => {
    await load();
  });

  const load = async () => {
    if (!props.check.suspect_team.length) {
      return;
    }

    const response = await useCourtApi().player.browseFull({
      steam_ids: props.check.suspect_team,
      page     : 0,
      limit    : props.check.suspect_team.length,
      offline  : true
    });

    players.value = response.results;
  };
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :title="t('check.activity.started.title')"
    :subtitle="t('check.activity.started.subtitle')"
    :created="created"
    circle-class="bg-grey-700"
  />
</template>

<style lang='scss' scoped>
.title {
  @apply text-grey-50;
  @apply text-lg;
  @apply font-medium;
}
.subtitle {
  @apply flex gap-1 items-center w-fit;
  @apply text-grey-400;
}
</style>