<script setup lang='ts'>
  import TitledPage from '@/components/layout/TitledPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import General from './navigation/WelcomeGeneral.vue';
  import Roadmap from './navigation/WelcomeRoadmap.vue';
  import Media from './navigation/WelcomeMedia.vue';
  import TabbedNavigation from '@/components/tabbed-navigation/TabbedNavigation.vue';
  import { TabbedNavigationHeader } from '@/components/tabbed-navigation/tabbed-navigation.dto';

  const { t } = useLocaleStore();
  const _panel = usePanelStore(); 

  type WelcomeHeaders = 'need-to-know' | 'roadmap' | 'contact';

  const headers = computed((): TabbedNavigationHeader<WelcomeHeaders>[] => [
    {
      key : 'roadmap',
      text: t('welcome.cbt.roadmaps'),
    },
    {
      key : 'need-to-know',
      text: t('welcome.cbt.need'),
    },
    {
      key : 'contact',
      text: t('welcome.cbt.media'),
    },
  ]);
</script>

<template>
  <TitledPage
    no-divider
    :top-title="t('welcome.cbt.title', { project: _panel.project?.name})" 
    :top-subtitle="t('welcome.cbt.subtitle')" 
  >
    <div class="tabs">
      <TabbedNavigation
        :headers="headers"
        disable-swipe
        no-padding
        class="mt-8"
      >
        <template #need-to-know>
          <General />
        </template>
        <template #roadmap>
          <Roadmap />
        </template>
        <template #contact>
          <Media />
        </template>
      </TabbedNavigation>
    </div>
  </TitledPage>
</template>