import { ModalMenuExtendedHeader } from "@/components/modal-menu-extended/modal-menu-extended.dto";
import { useLocaleStore } from "@/stores/locale.store";
import { usePermissionsStore } from "@panel/stores/permissions.store";
import { Svg } from "@src/assets/auto_gen_types.dto";

export type PlayerDetailsNavigationHeaders = 
| 'general'
| 'team'
| 'reports'
| 'alerts'
| 'checks'
| 'stats'
| 'history'
| 'signs'
| 'bans';

export const usePlayerDetailsNavigationDictionary = (): Record<PlayerDetailsNavigationHeaders, ModalMenuExtendedHeader<PlayerDetailsNavigationHeaders>> => {
  const { t } = useLocaleStore();
  const _permissions = usePermissionsStore();

  return {
    'general': {
      value : 'general',
      text  : t('modal.player.nav.general'),
      hidden: false,
      icon  : Svg.window,
    },
    'team': {
      value    : 'team',
      text     : t('modal.player.nav.team'),
      hidden   : false,
      icon     : Svg.players,
      preloaded: true
    },
    'reports': {
      value    : 'reports',
      text     : t('modal.player.nav.reports'),
      hidden   : !_permissions.can(['Court_ReportRead']),
      icon     : Svg.report,
      preloaded: true
    },
    'stats': {
      value : 'stats',
      text  : t('modal.player.nav.stats'),
      icon  : Svg.statistics,
      hidden: !_permissions.can(['Court_PlayerStatsRead'])
    },
    'history': {
      value : 'history',
      text  : t('modal.player.nav.activity'),
      hidden: !_permissions.can(['Court_PlayerHistoryRead']),
      icon  : Svg.live_activity,
    },
    'alerts': {
      value : 'alerts',
      text  : t('modal.player.nav.alerts'),
      hidden: !_permissions.can(['Court_AlertRead']),
      icon  : Svg.alert,
    },
    'signs': {
      value : 'signs',
      text  : t('modal.player.nav.signs'),
      hidden: !_permissions.can(['Court_SignageControl']),
      icon  : Svg.images_circle,
    },
    'checks': {
      value    : 'checks',
      text     : t('modal.player.nav.checks'),
      hidden   : !_permissions.can(['Court_CheckRead']),
      preloaded: true,
      icon     : Svg.check,
    },
    'bans': {
      value    : 'bans',
      text     : t('modal.player.nav.bans'),
      hidden   : !_permissions.can(['Court_PlayerBanRead']),
      preloaded: true,
      icon     : Svg.lock,
    },
  };
};