import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
import { useLocaleStore } from "@/stores/locale.store";

export const useCopyClipboard = async (text: string, showNotify = false) => {
  const { t } = useLocaleStore();

  await navigator.clipboard.writeText(text);

  if (showNotify) {
    useRuntimeNotification('copy', t('general.copied'));
  }
};
