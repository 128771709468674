<script setup lang="ts"> 
  import TitledPage from '@/components/layout/TitledPage.vue'; 
  import { useLocaleStore } from '@/stores/locale.store'; 
  import { computed } from 'vue';
  import TabbedNavigation from '@/components/tabbed-navigation/TabbedNavigation.vue';
  import { TabbedNavigationHeader } from '@/components/tabbed-navigation/tabbed-navigation.dto';
  import SettingsClientList from './tabs/SettingsClientList.vue';
  import SettingsClientSettings from './tabs/SettingsClientSettings.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
 
  const { t } = useLocaleStore();
  const _permission = usePermissionsStore();

  type ClientsHeaders = 'list' | 'settings';

  const headers = computed<TabbedNavigationHeader<ClientsHeaders>[]>(() =>[{
    key : 'list',
    text: t('clients.tabs.list')
  }, {
    key     : 'settings',
    text    : t('clients.tabs.settings'),
    disabled: () => !_permission.isAdminOrOwner()
  }]);

</script> 

<template>
  <TitledPage
    :top-title="t('staff.title')"
    :top-subtitle="t('staff.subtitle')"
    class="custom-title-page"
    no-divider
  >
    <TabbedNavigation :headers="headers">
      <template #list>
        <SettingsClientList />
      </template>

      <template #settings>
        <SettingsClientSettings />
      </template>
    </TabbedNavigation>
  </TitledPage>
</template>
