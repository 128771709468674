
<script setup lang='ts'>
  import CheckboxInput, { CheckboxInputProps } from '@/components/quality/inputs/CheckboxInput.vue';
  import { PermissionDescription } from '../permissions-edit.dto';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  defineProps<{
    permission: PermissionDescription
  } & CheckboxInputProps>();

  const emits = defineEmits(['on-checked', 'on-unchecked']);
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex items-start gap-1.5">
    <CheckboxInput
      :circle="circle"
      :text="permission.name"
      :disabled="disabled" 
      :checked="checked"
      @on-checked="emits('on-checked')"
      @on-unchecked="emits('on-unchecked')"
    />

    <TooltipBase
      v-if="permission.info"
      trigger="hover"
      placement="top"
    >
      <div>
        <Svg.warning_circle
          class="w-4 mt-0.5 fill-grey-600"
        />
      </div>
      <template #content>
        <div
          class="px-2 py-2 text-sm"
          style="max-width: 300px;"
          v-html="permission.info"
        />
      </template>
    </TooltipBase>

    <TooltipBase
      v-if="permission.warning"
      trigger="hover"
      placement="top"
    >
      <div>
        <Svg.warning
          class="w-4 mt-0.5"
          :class="!disabled && checked ? 'fill-ra-afk' : 'fill-grey-600'"
        />
      </div>
      <template #content>
        <div
          class="px-2 py-2 text-sm"
          style="max-width: 300px;"
          v-html="permission.warning"
        />
      </template>
    </TooltipBase>
  </div>
</template>

<style lang='scss' scoped>

</style>