<script setup lang="ts">
  import { useRoute } from 'vue-router';
  import Modal from '@/components/Modal.vue';
  import Loading from './components/Loading.vue';
  import { useAppStore } from '@src/stores/app.store';
  import { useLocaleStore } from './stores/locale.store';
  import { useThemeStore } from './stores/theme.store';
  import { useDataStore } from './stores/data.store';
  import Situation from './components/layout/SituationAlert.vue';
  import MenuExtended from './components/quality/menu-extended/MenuExtended.vue';
  import RuntimeNotification from './components/RuntimeNotification.vue';
  import { onMounted } from 'vue';
  import { useErrorHandler } from './composable/error.composable';
  import { useSteamAuthFinish } from './composable/steam-connect.composable';

  const _app = useAppStore();
  const _data = useDataStore();
  const _steamConnector = useSteamAuthFinish();
  const { setTheme } = useThemeStore();
  const route = useRoute();

  onMounted(() => {
    window.addEventListener('unhandledrejection', (e) => useErrorHandler(e));
  });

  const { t } = useLocaleStore();

  setTheme(_data.read('theme') ?? 'dark');
</script>

<template>
  <Loading
    :text="t('loading.app')"
    :can-view="_app.isReady && !_steamConnector.connecting.value"
    transition="0.5s"
  />
  
  <Situation v-if="route.path !== '/client/auth' && route.path !== '/'" />

  <!-- eslint-disable-next-line vue/no-undef-components -->
  <RouterView v-if="_app.isReady" class="router" />

  <Modal />

  <MenuExtended />

  <RuntimeNotification />
</template>

<style lang="scss">
#app {
  @apply h-full w-screen;
  @apply flex flex-col;

  .router {
    flex: 1 1 auto;
  }
}
</style>