<script setup lang='ts'>
  import Divider from '@/components/Divider.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import { CourtRccDto } from '@/api/backend/court/court-integrations/court-integrations.dto';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { watchDebounced, watchDeep } from '@vueuse/core';
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import Button from '@/components/Button.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const rccKeys = ref<CourtRccDto>({
    rcc_api_key         : '',
    rcc_public_key      : '',
    rcc_error           : false,
    rcc_send_ban_updates: false,
    rcc_auto_call_check : true
  });

  onMounted(async () => {
    await getRcc();

    setTimeout(() => {
      
      watchDeep(rccKeys, (v, o) => {
        save(v.rcc_api_key, v.rcc_send_ban_updates, v.rcc_auto_call_check);
      }, { immediate: false, });
    }, 150);
  });

  const getRcc = async () => {
    rccKeys.value = await useCourtApi().integrations.getRcc();
  
    preInput.value = rccKeys.value.rcc_api_key;
  };

  const save = async (rcc_api_key: string, rcc_send_ban_updates: boolean, rcc_call_auto_check: boolean) => {
    try {
      await useCourtApi().integrations.updateRcc(rcc_api_key, rcc_send_ban_updates, rcc_call_auto_check);

      rccKeys.value.rcc_error = false;

      useRuntimeNotification('success', t('general.settings-saved'));
    }
    catch (err: any) {
      if (err?.message?.includes('Bad key or RCC')) {
        useRuntimeNotification(`warning`, t('bad-rcc-key'));

        rccKeys.value.rcc_error = true;
        return;
      }

      throw err;
    }
  };

  const preInput = ref<string>('');

  watchDebounced(preInput, () => {
    rccKeys.value.rcc_api_key = preInput.value;
  }, { debounce: 150 });
</script>

<template>
  <div class="relative">
    <CourtNoSubscriptionContent color="bg-grey-1000/80" type="RCC">
      <a
        class="info bg-dot-pattern"
        href="https://rustcheatcheck.ru/" 
        target="_blank"
      >
        <Svg.rcc class="!w-6 h-6 fill-grey-50 shrink-0" />
        <p>
          <span class="info-title">{{ t('integrations.rcc.title') }}</span>
          <span class="info-subtitle">{{ t('integrations.rcc.subtitle') }}</span>
        </p>
      </a>

      <Divider class="my-10" />

      <div class="section">
        <div class="header">
          <p class="title">
            {{ t('integrations.rcc.api.title') }}
          </p>
          <p class="subtitle">
            {{ t('integrations.rcc.api.subtitle') }}
          </p>
        </div>

        <TextInput
          v-model="preInput"
          theme="middle"
          :placeholder="t('integrations.rcc.alerts.placeholder')"
          :label="t('integrations.rcc.alerts.label')"
          type="password"
          autocomplete="new-password"
        >
          <template #action>
            <a href="https://rustcheatcheck.ru/panel/getapi" target="_blank">
              <Button
                preset="link"
                class="!font-normal"
                :text="t('integrations.rcc.alerts.action')"
              />
            </a>
          </template>
        </TextInput>

        <template v-if="rccKeys.rcc_error">
          <div class="bottom mt-4">
            <p class="title">{{ t('rcc-integrations-broken-header') }}</p>
            <p class="subtitle">{{ t('rcc-integrations-broken-subtitle') }}</p>
          </div>
        </template>
      </div>

      <Divider class="my-10" />

      <div class="section">
        <div class="header">
          <p class="title">
            {{ t('integrations.rcc.settings.title') }}
          </p>
        </div>

        <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
          <SwitchText
            v-model="rccKeys.rcc_send_ban_updates"
            :header="t('integrations.rcc.settings.send.ban')"
            :disabled="!rccKeys?.rcc_api_key?.length || rccKeys.rcc_error"
            :placeholder="t('integrations.rcc.settings.send.placeholder')"
          />

          <SwitchText
            v-model="rccKeys.rcc_auto_call_check"
            :header="t('integrations.rcc.settings.auto.call')"
            :disabled="!rccKeys?.rcc_api_key?.length || rccKeys.rcc_error"
            :placeholder="t('integrations.rcc.settings.auto.placeholder')"
          />
        </SettingsContainer>
      </div>

      <Divider class="my-10" />

      <div class="section">
        <div class="header">
          <p class="title">
            {{ t('integrations.rcc.advantages') }}
          </p>
        </div>
        <div class="items-row">
          <div class="item">
            <p class="text">
              {{ t('integrations.rcc.1') }}
            </p>
          </div>
          <div class="item">
            <p class="text">
              {{ t('integrations.rcc.2') }}
            </p>
          </div>
          <div class="item">
            <p class="text">
              {{ t('integrations.rcc.3') }}
            </p>
          </div>
          <div class="item">
            <p class="text">
              {{ t('integrations.rcc.4') }}
            </p>
          </div>
        </div>
      </div>
    </CourtNoSubscriptionContent>
  </div>
</template>

<style lang='scss' scoped>
.bottom {
  @apply p-4 rounded-md;
  @apply bg-grey-950;
  @apply border border-red-500;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
  .title {
    @apply text-base font-normal text-grey-50 mb-1;
  }
  .subtitle {
    @apply text-base font-normal;
    @apply text-grey-400;
  }
}

.info {
  @apply flex gap-5;
  @apply rounded-md;
  @apply p-5;
  @apply bg-grey-950;
  @apply border border-transparent;
  @apply cursor-pointer;

  .info-title {
    @apply block;
    @apply text-16-500;
    @apply text-grey-50;
    @apply mb-[5px];
  }

  .info-subtitle {
    @apply text-grey-400;
    @apply text-14-400;
  }

  &:hover {
    @apply border border-grey-850;
  }
}

.section {
  @apply flex flex-col gap-5;
  
  .header {
    @apply flex flex-col gap-2;

    .title {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    .subtitle {
      @apply text-grey-400;
      @apply text-sm;
    }
  }
}

.items-row {
  @apply divide-grey-850;
  
  .item {
    @apply cursor-default;
    @apply bg-grey-900;

    .text {
      @apply text-grey-400 text-sm;
    }

    &:hover {
      @apply bg-grey-850;
    }
  }
}
</style>