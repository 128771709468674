
<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import { ServerPublic } from '@/api/backend/court/court/court.dto';
  import { ServerPairDto } from '@/api/backend/court/servers/servers.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ampli } from '@src/ampli';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { isURL } from 'class-validator';
  import { onMounted, ref, watch } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();
  const _modal = useModalsStore();

  const emit = defineEmits(['pause', 'resume']);

  const props = defineProps<{ 
    server?: ServerPublic;
    pairData: ServerPairDto; 
    onUpdated: () => Promise<void>; 
  } & ModalInjectedProps>();

  onMounted(() => {
    update();

    if (!props.server) {
      return;
    }

    serverName.value = props.server.name;
  });

  watch(() => props.server, (v) => {
    if (!v) {
      return;
    }

    serverName.value = v.name;
  }, { deep: true });
  

  const serverName = ref<string>('');
  const update = () => {
    if (!props.pairData.plugin) {
      return;
    }

    if (props.pairData.plugin.banner_url && isURL(props.pairData.plugin.banner_url)) {
      bannerUrl.value = props.pairData.plugin.banner_url;

      if (['.png', '.jpg', '.jpeg'].find(v => bannerUrl.value.includes(v))) {
        bannerUrl.value += `.png`;
      }
    }
    if (props.pairData.plugin.avatar_big && isURL(props.pairData.plugin.avatar_big)) {
      avatarUrl.value = props.pairData.plugin.avatar_big;

      if (['.png', '.jpg', '.jpeg'].find(v => avatarUrl.value.includes(v))) {
        avatarUrl.value += `.png`;
      }
    }
  };

  const defaultBannerUrl = 'https://i.imgur.com/7gsgKSP.png';
  const defaultAvatarUrl = 'https://i.imgur.com/PtUV9hj.jpg'; 

  const bannerUrl = ref<string>(defaultBannerUrl); 
  const avatarUrl = ref<string>(defaultAvatarUrl); 
  
  const complete = async () => {
    if (!props.pairData.code) {
      return;
    }

    emit('pause');

    try {
      await useCourtApi().servers.completePair({
        code     : props.pairData.code,
        name     : serverName.value,
        server_id: props.server?.id
      });
      
      ampli.clientServerPairComplete({ server_name: serverName.value, result: 'success', server_id: props.server?.id });

      await props.onUpdated();
      
      props.injected.setBeforeClose(async () => {
        await ActivateConfirmModal({
          type       : 'success',
          title      : t('modal.server.connect.success.title'),
          description: t('modal.server.connect.success.subtitle')
        });

        return true;
      });
        
      props.injected.close();     
    }
    catch (err) {
      emit('resume');

      throw err;
    }
  };
  
  const _validators = useValidators();
  const serverInput = ref<InstanceType<typeof TextInput>>();
</script>

<template>
  <div class="confirm-header">
    <img
      class="banner"
      :src="bannerUrl"
      @error="bannerUrl = defaultBannerUrl"
    >
    <div class="server-info">
      <AvatarExtended
        class="avatar"
        :url="avatarUrl"
        basic-square
        :active="false"
        @error="avatarUrl = defaultAvatarUrl"
      />
      <div class="w-full">
        <p class="text-name">{{ pairData.plugin?.name }}</p>
        <p class="text-description">
          <span>{{ pairData.plugin?.online }}/{{ pairData.plugin?.slots }}</span>
          <span class="px-1 opacity-75">•</span>
          <span>{{ pairData.plugin?.level ?? 'Procedural' }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="confirm-body">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="label" v-html="server ? t('modal.server.connect.reconnect.confirm') : t('modal.server.connect.confirm')" />

    <TextInput 
      ref="serverInput"
      v-model="serverName" 
      :placeholder="t('modal.server.connect.name.placeholder')"
      :validator="_validators.ServerName"
    />

    <div v-if="pairData.plugin?.connected" class="already-connected">
      {{ t('modal.server.connect.warning-connected') }}
    </div>
  </div>
  <div class="confirm-footer">
    <div class="flex justify-end items-center gap-2 w-full">
      <Button
        preset="default"
        :text="t('general.toCancel')"
        :action="() =>_modal.close()"
      />

      <Button
        preset="primary"
        :disabled="!serverName || !serverInput || !!serverInput.activeException"
        class="!px-3"
        :action="complete"
        :text="t('servers.connect')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.confirm-header {
  height: 180px;
  width: 100%;
  @apply relative;
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    @apply z-10;
    @apply absolute inset-0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.75) 100%);
  }
  .server-info {
    @apply absolute bottom-5 left-5;
    @apply z-20;
    @apply flex items-center;
    max-width: 330px;
    .avatar {
      @apply w-[40px];
      @apply mr-2.5 flex-shrink-0;
    }
    .text-name {
      @apply text-grey-50 font-medium text-lg leading-none;
      @apply truncate;
    }
    .text-description {
      @apply text-sm text-white/50 leading-none;
      @apply pt-1;
    }
  }
}
.confirm-body {
  @apply p-5;
  @apply flex flex-col gap-2.5;
  @apply border-b border-grey-1000;
  .label {
    @apply text-grey-400;
    &:deep(.bright) {
      @apply text-grey-100;
    }
  }
  .already-connected {
    @apply bg-ra-afk/15;
    @apply text-ra-afk;
    @apply rounded-md;
    @apply p-2.5 mt-2.5;
  }
}
.confirm-footer {
  @apply p-5;
}
</style>