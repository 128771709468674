<script setup lang="ts">
  import Skeleton from '@/components/Skeleton.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import type { Component } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useClientComposable } from '@court/components/client/client.composable';
  import { onMounted } from 'vue';
  import { watch } from 'vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const _adaptive = useAdaptiveStore();

  const props = defineProps<{ 
    client_id: number;

    icon?: Component;
    icon_styles?: string;

    date?: number;

    ready?: boolean;
  }>();

  const _time = useTimeStore();

  const { client, load } = useClientComposable();

  onMounted(async () => {
    load(props.client_id);
  });

  watch(() => props.client_id, (v) => load(v));

  defineExpose({ client });
</script>

<template>
  <div class="flex items-center gap-4 w-full text-white">
    <template v-if="ready">
      <component
        :is="icon ?? Svg.arrow_corner_down_right"
        class="icon"
        :class="icon_styles"
      />
      <img
        class="avatar"
        style="object-fit: cover"
        :src="useConfigStore().Urls.Images(client?.avatar ?? '')"
      >
    </template>
    <template v-else>
      <Skeleton
        style="height: 20px; width: 20px"
        class="w-full rounded-md shrink-0"
      />
      <Skeleton
        style="height: 40px; width: 40px"
        class="w-full rounded-half shrink-0"
      />
    </template>

    <div class="w-full" :style="ready ? 'width: 100%' : _adaptive.isMobile ? 'width: 100%' : 'width: 45%'">
      <p class="row">
        <template v-if="ready">
          <slot />
        </template>
        <template v-else>
          <Skeleton
            style="height: 18px;"
            :style="`width: ${Math.random() * (70 - 90) + 70}%`" 
            class="rounded-md"
          />
        </template>
      </p>

      <template v-if="date && ready">
        <span class="date" :title="_time.format(date, 'DD MMM YYYY HH:mm:ss', true, true)">{{ _time.fromNow(date, false, true) }}</span>
      </template>
      <template v-else>
        <Skeleton
          style="height: 14px; width: 110px;"
          class="rounded-md mt-2"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  @apply w-5 h-5;
  @apply shrink-0;
}

.avatar {
  @apply overflow-hidden;
  @apply rounded-half;
  @apply shrink-0;
  @apply w-[40px] h-[40px];
}

.date {
  @apply text-12-400 sm:text-14-400;
  @apply text-grey-600;
}

.row {
  @apply flex flex-wrap gap-1;
  @apply leading-5;

  :slotted(span) {
    @apply text-grey-400;
    @apply whitespace-nowrap;
    @apply text-14-400 sm:text-16-400;

    &.bright {
      @apply text-grey-200;
      @apply font-medium;

      &:hover {
        @apply text-grey-50;
      }
    }

    &.light {
      @apply text-grey-200;
      @apply font-medium;
    }
  }
}
</style>