<script lang="ts">
  export type * from './nav.dto';
</script>

<script setup lang="ts" generic="T = {}">
  import { Ref, computed, onMounted, ref, watch } from 'vue';
  import { NavHeaders } from './nav.dto';

  const props = defineProps<{ 
    headers: NavHeaders<T>[], 
    headerClass?: string 
  }>();

  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  const selected = ref<NavHeaders<T>>() as Ref<NavHeaders<T> | null>;

  const navigationHolder = ref<HTMLElement>();
  const navigationsButtons = ref<Record<string, unknown>>({});

  const emits = defineEmits<{
    (e: 'select', header: NavHeaders<T>): void
  }>();

  onMounted(() => {
    select(computedDefaultHeader.value);
  });

  watch(() => props.headers, () => {
    setTimeout(() => select(computedDefaultHeader.value), 0);
  }, { deep: true });

  const computedDefaultHeader = computed(() => {
    return props.headers[0];
  });

  const select = (header: NavHeaders<T>, e?: Event) => {
    selected.value = header;

    scrollTo(header);

    emits('select', header);

    e?.preventDefault();
  };

  const scrollTo = (header: NavHeaders<T>) => {
    const navigationsButton = navigationsButtons.value[header.name];

    if (!navigationHolder.value || !navigationsButton) {
      return;
    }

    navigationHolder.value.scroll({ left: (navigationsButton as HTMLElement).offsetLeft - 200, behavior: 'smooth' });
  };

  const scrollable = ref<HTMLElement>();

  defineExpose({ scrollable: scrollable as Ref<HTMLInputElement> });
</script>

<template>
  <div class="w-full text-grey-50 flex-col h-full relative">
    <div
      class="absolute z-20"
      style="width: inherit; -webkit-backdrop-filter: blur(12px);"
      :class="headerClass ?? ''"
    >
      <div
        id="scrollable-nav"
        ref="navigationHolder"
        class="headers flex gap-4 overflow-x-scroll relative pl-5 pr-5 bg-grey-900/50 backdrop-blur-md"
        style="transition: 1s all ease;"
      >
        <template v-for="header in headers" :key="header.name">
          <div
            :ref="el => navigationsButtons[header.name] = el"
            :class="['section relative', 'flex items-center gap-1.5', { selected: header.name == selected?.name }]"
            @click="(e) => select(header, e)"
          >
            {{ header.name }}

            <div
              v-if="header.badge"
              :class="[
                'flex justify-center',
                selected?.name == header.name ? 'bg-white text-black' : 'bg-red-500 text-white',
              ]"
              style="border-radius: 5px; font-size: 10px; padding: 1.5px 4.4px 2px 4px; line-height: 1.2;"
            >
              {{ header.badge }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      id="scrollable"
      ref="scrollable"
      class="pr-5 pb-5 pt-14 absolute inset-0 z-10 gap-3 h-full overflow-y-auto overflow-x-hidden flex flex-col-reverse"
      style="padding-top: calc(40.8px + 20px)"
    >
      <slot v-bind="{ selected }" />
      <slot :name="selected?.name" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.headers::-webkit-scrollbar {
  display: none;
}
.headers {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.section {
  @apply whitespace-nowrap;
  @apply py-2.5;
  @apply cursor-pointer;
  @apply text-grey-400;
  @apply border-b-2 border-transparent;

  &:hover {
    @apply text-grey-300;
  }
  &.selected {
    @apply text-grey-50;
    @apply border-b-2 border-primary-700;
    @apply pointer-events-none;
  }
}
</style>
