import { Axios } from "axios";
import { ClientPersonalDto, ClientSteamDto } from "../client/client.dto";
import { AuthEmailAndGoogleValidationDto, AuthClassicSignUpDto, AuthGoogleSignUpDto, AuthSteamValidationDto, AuthSteamSignUpDto } from "./auth.dto";

export const useCoreAuthApi = (api: Axios) => {
  const classicValidate = async(email: string) => {
    const result = await api.get<AuthEmailAndGoogleValidationDto>('/auth/classic/validate', { params: { email }});

    return result.data;
  };

  const classicSignIn = async(email: string, password: string) => {
    const result = await api.post<string>(`/auth/classic/sign-in`, { email, password });

    return result.data;
  };

  const emailConfirmStart = async(lang: string) => {
    const result = await api.get<string>(`/auth/email-confirm`, { params: { lang }});

    return result.data;
  };

  const emailConfirmFinish = async(code: string) => {
    const result = await api.post<string>(`/auth/email-confirm`, {}, { params: { code }});

    return result.data;
  };

  const classicSignUp = async(opts: AuthClassicSignUpDto) => {
    const result = await api.post<string>(`/auth/classic/sign-up`, opts);

    return result.data;
  };

  const classicRestoreStart = async(email: string, redirect: string, lang: string) => {
    const result = await api.get<string>(`/auth/password-restore`, {
      params: {
        email,
        redirect,
        lang
      }
    });

    return result.data;
  };

  const classicRestoreCheck = async(code: string) => {
    const result = await api.put<string>(`/auth/password-restore`, {}, {
      params: {
        code
      }
    });

    return result.data;
  };

  const classicRestoreComplete = async(code: string, password: string) => {
    const result = await api.post<string>(`/auth/password-restore`, {}, {
      params: {
        code,
        password
      }
    });

    return result.data;
  };

  const googleValidate = async(token?: string, credential?: string) => {
    const result = await api.get<AuthEmailAndGoogleValidationDto>('/auth/google/validate', { params: { token, credential }});

    return result.data;
  };

  const googleSignIn = async(token?: string, credential?: string) => {
    const result = await api.post<string>(`/auth/google/sign-in`, { token, credential });

    return result.data;
  };

  const googleSignUp = async(opts: AuthGoogleSignUpDto) => {
    const result = await api.post<string>(`/auth/google/sign-up`, opts);

    return result.data;
  };

  const steamLink = async() => {
    const result = await api.get<string>('/auth/steam/link', {
      params: {
        return_url: window.location.href.split('?')[0]
      }
    });

    return result.data;
  };

  const steamValidate = async(query: string) => {
    const result = await api.get<AuthSteamValidationDto>('/auth/steam/validate', { params: { query }});

    return result.data;
  };

  const steamSignIn = async(query: string) => {
    const result = await api.post<string>(`/auth/steam/sign-in`, { query });

    return result.data;
  };

  const steamSignUp = async(opts: AuthSteamSignUpDto) => {
    const result = await api.post<string>(`/auth/steam/sign-up`, opts);

    return result.data;
  };

  const handshake = async() => {
    const result = await api.get<ClientPersonalDto>(`/auth/handshake`);

    return result.data;
  };

  const steamConnectStart = async() => {
    const result = await api.get<string>(`/auth/steam-connect`, {
      params: {
        return_url: window.location.href.split('?')[0] + `?integrations-connect-steam=show`
      }
    });

    return result.data;
  };

  const steamConnectFinish = async(query: string) => {
    const result = await api.post<ClientSteamDto>(`/auth/steam-connect`, {}, {
      params: {
        query
      }
    });

    return result.data;
  };

  const steamConnectDelete = async() => {
    const result = await api.delete<void>(`/auth/steam-connect`);

    return result.data;
  };

  const start2fa = async() => {
    const result = await api.get<string>(`/auth/2fa`);

    return result.data;
  };

  const enable2fa = async(code: string) => {
    const result = await api.post<void>(`/auth/2fa`, {}, {
      params: { code }
    });

    return result.data;
  };

  const disable2fa = async(code: string) => {
    const result = await api.delete<void>(`/auth/2fa`, {
      params: { code }
    });

    return result.data;
  };

  return {
    classicValidate,
    classicSignIn,
    emailConfirmStart,
    emailConfirmFinish,
    classicSignUp,
    classicRestoreStart,
    classicRestoreCheck,
    classicRestoreComplete,
    googleValidate,
    googleSignIn,
    googleSignUp,
    steamLink,
    steamValidate,
    steamSignIn,
    steamSignUp,
    handshake,
    steamConnectStart,
    steamConnectFinish,
    steamConnectDelete,
    start2fa,
    enable2fa,
    disable2fa,
  };
};