<script setup lang="ts">
  import { AssetImageTypes } from '@src/assets/auto_gen_types.dto';
  import { computed } from 'vue';

  const props = defineProps<{ icon: AssetImageTypes }>();

  const image = computed(() => {
    return new URL(`/src/assets/images/${props.icon}`, import.meta.url).href;
  });
</script>

<template>
  <img :src="image">
</template>