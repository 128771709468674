  import { computed } from "vue";

  import { useLocaleStore } from "@/stores/locale.store";
  
  import { ActivateClientInfoEditModal } from "@src/modals/client-info-edit/ClientInfoEditModal.vue";
  import { ExtendedMenuItem } from "@/components/quality/menu-extended/MenuExtended.vue";
  import { useContextMenu } from "@/stores/contextmenu.store";
import { useAppStore } from "@/stores/app.store";

  export const useAccountMenu = () => {
    const { t } = useLocaleStore();
    const _locale = useLocaleStore();
    const _contextMenu = useContextMenu();

    const menu = computed((): ExtendedMenuItem[] => {
      return [
        {
          label: t('account-menu.language.text'),
          items: [{
            label : t('account-menu.language.ru'),
            active: () => _locale.isRu,
            action: () => {
              _locale.set('ru');
            }
          }, {
            label : t('account-menu.language.en'),
            active: () => _locale.isEng,
            action: () => {
              _locale.set('en');
            }
          }, {
            label : t('account-menu.language.uk'),
            active: () => _locale.isUk,
            action: () => {
              _locale.set('uk');
            }
          }]
        },
        {
          label : t('account-menu.settings'),
          action: () => ActivateClientInfoEditModal({})
        },
        {
          label : t('account-menu.logout'),
          action: () => {
            useAppStore().logout();
          },
          class: 'destructive'
        },
      ];
    });

    const open = (e: MouseEvent) => {
      _contextMenu.open(e, menu.value);
    };

    return { open };
  };