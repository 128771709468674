<script setup lang="ts">
  import { Svg } from '@src/assets/auto_gen_types.dto';

  type PlanProps = {
    plan: string;
    width?: number;
    widthIcon?: number;
    noBackground?: boolean;
  }

  const props = withDefaults(defineProps<PlanProps>(), {
    width       : 20,
    widthIcon   : 14,
    noBackground: false,
  });
</script>

<template>
  <div
    v-if="!props.noBackground"
    class="plan-icon"
    :class="props.plan?.replace(/\d/, '')"
    :style="{ width: `${props.width}px`, height: `${props.width}px` }"
  >
    <Svg.lightning :style="`fill: url(#${props.plan?.replace(/\d/, '')});  width: ${props.widthIcon}px; height: ${props.widthIcon}px;`" />
  </div>
  <div v-else class="flex items-center justify-center">
    <Svg.lightning :style="`fill: url(#${props.plan?.replace(/\d/, '')});  width: ${props.widthIcon}px; height: ${props.widthIcon}px;`" />
  </div>
</template>

<style lang="scss" scoped>
.plan-icon {
  @apply flex items-center justify-center;
  @apply rounded-md;
  
  &.starter {
    @apply bg-gradient-to-r from-[#56CCF2]/15 to-[#2F80ED]/15;
  }
  &.basic {
    @apply bg-gradient-to-r from-[#D4FC79]/15 to-[#96E6A1]/15;
  }
  &.pro {
    @apply bg-gradient-to-r from-[#FFE259]/15 to-[#FFA751]/15;
  }
}
</style>
