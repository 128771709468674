<script setup lang='ts'>
  import { CentrifugoChatMessage } from '@/api/centrifugo/centrifugo.dto';
  import PlayerInline from '@court/components/player/PlayerInline.vue';
  import { useChatStore } from '../stores/chat.store';
  import { computed, onMounted } from 'vue';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { useClientComposable } from '@court/components/client/client.composable';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useChatMessageMenu } from '@court/menus/chat-message.menu';

  const props = defineProps<{ msg: CentrifugoChatMessage, target?: boolean }>();

  const _chat = useChatStore();
  const _adaptive = useAdaptiveStore();

  const fields = computed(() => {
    const prefix = props.target ? 'target_' : '';

    return {
      steam_id    : props.msg[`${prefix}steam_id`] ?? '',
      steam_name  : props.msg[`${prefix}steam_name`] ?? '',
      steam_avatar: props.msg[`${prefix}steam_avatar`] ?? '',
    }; 
  });

  const { client, load } = useClientComposable();

  onMounted(() => {
    if (!isModerator.value) {
      return;
    }

    load(+fields.value.steam_id);
  });

  const isModerator = computed(() => fields.value.steam_id.length != 17);

  const customClick = (e: MouseEvent) => {
    if (_adaptive.isMobile) {
      useChatMessageMenu().open(e, props.msg, props.target);
      return;
    }

    if (!client.value) {
      return;
    }

    if (props.msg.steam_id.length != 17) {
      ActivateClientDetailsModal(client.value.id, client.value);
      return;
    }

    return null;
  };
</script>

<template>
  <PlayerInline
    v-bind="fields"
    type="default"
    :hide-avatars="!_chat.design.avatars"
    :custom-click="client || _adaptive.isMobile ? (e) => customClick(e) : undefined"
    class="font-normal"
    :class="_chat.design.invertColors ? 'text-grey-400 hover:text-grey-500' : 'text-grey-50 hover:text-grey-150'"
  />
</template>

<style lang='scss' scoped>
:deep(.name) {
  @apply leading-5 #{!important};
}
</style>