<script setup lang='ts'>
  import { computed, ref } from 'vue';
  import Divider from '@/components/Divider.vue';
  import BillingNotify from '../components/BillingNotify.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Button from '@/components/Button.vue';
  import { ActivatePaymentsModal } from '@src/views/panel/modules/settings/modals/payments/PaymentModal.vue';
  import { ActivateCourtPlanModal } from '@court/modals/plan/CourtPlanModal.vue';
  import { usePaymentsStore } from '../store/payments.store';
  import { useMoney } from '@src/composable/money.composable';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import PaymentsCard from '../components/PaymentsCard.vue';
  import PlanIcon from '@court/components/plans/PlanIcon.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import { useTimeStore } from '@/stores/time.store';

  const { t } = useLocaleStore();
  const _payments = usePaymentsStore();
  const _subscription = useCourtSubscriptionStore();
  const { toMajorUnits, toLocaleBalance } = useMoney();
  const _time = useTimeStore();

  const usage = computed(() => ([
    {
      name   : t('billing.usage.servers'),
      icon   : Svg.server,
      current: _payments.subscriptions.court?.usage.servers,
      max    : _payments.subscriptions.court?.current.limits.servers,
    },
    {
      name   : t('billing.usage.players'),
      icon   : Svg.players,
      current: _payments.subscriptions.court?.usage.players,
      max    : _payments.subscriptions.court?.current.limits.players,
    }
  ]));

</script>

<template>
  <div class="payments-tab-general">
    <div class="flex flex-col gap-5">
      <BillingNotify
        v-if="_subscription.state?.pay_error && !_subscription.state?.is_trial"
        type="red"
        :title="t('billing.notify.frozen.title')"
        :subtitle="t('billing.notify.frozen.subtitle')"
      />

      <BillingNotify
        v-if="_payments.subscriptions.court != null && _subscription.state?.is_trial && !_subscription.state?.pay_error"
        type="yellow"
        :title="t('billing.notify.trial.title')"
        :subtitle="t('billing.notify.trial.subtitle', {
          date: _time.format(+(_payments.estimatedDateEnd ?? Date.now()), 'DD MMMM YYYY # HH:mm', true, true),
          plan: _subscription.planTypeBeautifier(_payments.subscriptions.court.current.type)
        })"
      />

      <BillingNotify
        v-if="_payments.subscriptions.court?.usage_error"
        type="red"
        :title="t('billing.notify.frozen.title')"
        :subtitle="t('billing.notify.usage-error.subtitle')"
      />

      <div class="cards">
        <PaymentsCard
          :title="t('billing.balance.title')"
          :info="_payments.estimatedDailyPrice == 0 ? t('billing.balance.free') : t('billing.balance.estimated-price', {price: toLocaleBalance(toMajorUnits(_payments.estimatedDailyPrice), undefined, 2)})"
          :skeleton="_payments.subscriptions.court == null"
        >
          <template #value>
            {{ _payments.wallet?.balance == undefined ? '-' : toLocaleBalance(toMajorUnits(_payments.wallet.balance), undefined, 2) }}
          </template>
          <template #button>
            <Button
              :text="t('billing.balance.top-up')"
              preset="primary"
              @click="() => ActivatePaymentsModal({})"
            />
          </template>
        </PaymentsCard>
      
        <PaymentsCard
          :title="t('billing.plan.current-plan')"
          :info="!_payments.estimatedDateEnd ? t('billing.plan.already-end') : _payments.estimatedDailyPrice == 0 ? t('billing.plan.free') : t('billing.plan.estimated-date', {date: _time.format(+_payments.estimatedDateEnd, 'DD.MM.YYYY # HH:mm', true, true)})"
          :skeleton="_payments.subscriptions.court == null"
        >
          <template #value>
            <PlanIcon
              :plan="_payments.subscriptions.court?.current.type ?? 'default'"
              :width-icon="25"
              no-background
            />
            {{ _payments.subscriptions.court == null ? '-' : _subscription.planTypeBeautifier(_payments.subscriptions.court.current.type) }}
          </template>
          <template #button>
            <Button
              :text="t('billing.plan.upgrade')"
              preset="white"
              :action="() => ActivateCourtPlanModal({ landing: false })"
            />
          </template>
        </PaymentsCard>
      </div>
    </div>

    <Divider />

    <div class="usage">
      <div class="title">
        <span class="text-head">{{ t('billing.usage.title') }}</span>
      </div>
      <div class="usage-container">
        <div v-for="limit in usage" :key="limit.name">
          <div class="main">
            <div class="text">
              <template v-if="limit.max != undefined && limit.current != undefined">
                <div class="usage-title">
                  <component :is="limit.icon" class="w-[20px] fill-grey-50 flex-shrink-0" />
                  <p class="truncate">{{ limit.name }}</p>
                </div>
                <div class="usage-value">
                  <span :class="{ 'text-red-500': (limit.current ?? 0) > (limit.max ?? 0) }">{{ limit.current.toLocaleString('ru-RU') }}</span>
                  <span class="text-grey-400">{{ `/ ${limit.max < 1_000_000 ? limit.max.toLocaleString('ru-RU') : '∞'}` }}</span>
                </div>
              </template>
              <template v-else>
                <Skeleton class="!max-w-[170px] !h-[20px] rounded !bg-grey-750" />
                <Skeleton class="!w-[70px] !h-[20px] rounded !bg-grey-750" />
              </template>
            </div>
            <div class="progress-bar">
              <template v-if="limit.max != undefined && limit.current != undefined">
                <div
                  v-if="(limit.max ?? 0) < 1000000"
                  class="h-full bg-grey-50"
                  :class="{ 'bg-red-500': (limit.current ?? 0) > (limit.max ?? 0) }"
                  :style="{ width: `${((limit.current ?? 0) / (limit.max ?? 100)) * 100}%` }"
                />
                <div
                  v-else
                  class="h-full bg-grey-750"
                />
              </template>
              <template v-else>
                <Skeleton class="!w-full !h-full !bg-grey-750" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.notify {
  @apply flex flex-col gap-1 p-5;
  @apply rounded-md;

  .title {
    @apply text-grey-50;

    svg {
      @apply inline w-5 align-text-top;
    }
  }
  .subtitle {
    @apply text-grey-400;
  }

  &.red {
    @apply bg-red-500/15;

    .title {
      svg {
        @apply fill-red-500;
      }
    }
  }

  &.yellow {
    @apply bg-amber-400/15;
    
    .title {
      svg {
        @apply fill-amber-400;
      }
    }
  }
}

.payments-tab-general {
  @apply flex flex-col;
  @apply overflow-y-auto;
  @apply gap-10;
}

.cards {
  @apply grid grid-cols-2 gap-5;

  .btn {
    button {
      @apply w-fit h-fit px-2 py-1.5;
      @apply rounded;
    }
  }
}

@media (max-width: 700px) {
  .cards {
    @apply grid-cols-1;
  }
}


.usage {
  .title {
    @apply flex items-center mb-5;

    .text-head {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
  }

  .usage-container {
    @apply flex flex-col gap-5;
    @apply p-5;
    @apply rounded-md;
    @apply bg-grey-900;

    .main {
      @apply flex flex-col gap-2.5;

      .text {
        @apply flex justify-between gap-5;

        .usage-title {
          @apply flex gap-1.5 truncate;
        }
        .usage-value {
          @apply flex gap-1 flex-shrink-0;
        }
      }

      .progress-bar {
        @apply w-full h-2 overflow-hidden;
        @apply rounded-sm;

        background-image: linear-gradient(45deg, #141414 25%, #181818 0, #181818 50%, #141414 0, #141414 75%, #181818 0, #181818);
        background-size: 1rem 1rem;
        animation: progress-bar-stripes 1s linear infinite;
      }
    }
  }
}

@keyframes progress-bar-stripes {
  0% {
      background-position: 1rem 0
  }
  to {
      background-position: 0 0
  }
}
</style>