import { AuditType } from "@/api/backend/court/audit/audit.dto";
import { useLocaleStore } from "@/stores/locale.store";
import { Svg } from "@src/assets/auto_gen_types.dto";

export const useAuditComposable = () => {
  const { t } = useLocaleStore();

  const getNameOf = (type: AuditType) => {
    switch (type) {
      case 'BanEdit': {
        return t('audit-types-ban-edit');
      }
      case 'ChatMessage': {
        return t('audit-types-chat-message');
      }
      case 'CommandEdit': {
        return t('audit-types-command-edit');
      }
      case 'CommandExecute': {
        return t('audit-types-command-execute');
      }
      case 'EditSettings': {
        return t('audit-types-edit-settings');
      }
      case 'EditSubscription': {
        return t('audit-types-edit-subscription');
      }
      case 'PlayerBan': {
        return t('audit-types-player-ban');
      }
      case 'PlayerCheck': {
        return t('audit-types-player-check');
      }
      case 'PlayerIgnore': {
        return t('audit-types-player-ignore');
      }
      case 'PlayerKick': {
        return t('audit-types-player-kick');
      }
      case 'PlayerReportDelete': {
        return t('audit-types-report-delete');
      }
      case 'SignageDelete': {
        return t('audit-types-signage-delete');
      }
    }
  };

  const getIconOf = (type: AuditType) => {
    switch (type) {
      case 'BanEdit': {
        return Svg.edit;
      }
      case 'ChatMessage': {
        return Svg.chat;
      }
      case 'CommandEdit': {
        return Svg.edit;
      }
      case 'CommandExecute': {
        return Svg.code_brackets;
      }
      case 'EditSettings': {
        return Svg.edit;
      }
      case 'EditSubscription': {
        return Svg.payment;
      }
      case 'PlayerBan': {
        return Svg.lock;
      }
      case 'PlayerCheck': {
        return Svg.check;
      }
      case 'PlayerIgnore': {
        return Svg.mute;
      }
      case 'PlayerKick': {
        return Svg.kick;
      }
      case 'PlayerReportDelete': {
        return Svg.delete;
      }
      case 'SignageDelete': {
        return Svg.images_1;
      }
    }
  };

  return { getNameOf, getIconOf };
};