import { defineStore } from "pinia";
import { onMounted, ref, watch } from "vue";

import { usePanelStore } from "@panel/stores/panel.store";
import { PlayerFullDto, PlayerStatusDto } from "@/api/backend/court/player/player.dto";
import { ProjectClientPersonalDto } from "@/api/backend/core/project/project.dto";
import { CentrifugoStatusPayload, CentrifugoSubscribe } from "@/api/centrifugo/centrifugo.dto";
import { useTimeStore } from "@/stores/time.store";
import { useLocaleStore } from "@/stores/locale.store";
import { useCentrifugoApi } from "@/api/centrifugo/centrifugo.api";

type PlayerCentrifugoStatus = {
  status: PlayerStatusDto;
  updated: number;
}

export type PlayerActualStatusDetails = {
  status: PlayerStatusDto;
  last_online_at: number;
}

export const usePlayerStatusStore = defineStore('player-status', () => {
  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _panel = usePanelStore();

  const state = ref<Record<string, PlayerCentrifugoStatus>>({});
  const subscription = ref<CentrifugoSubscribe>();

  onMounted(() => subscribe(_panel.project));
  watch(() => _panel.project, (val) => subscribe(val), { deep: true });

  const subscribe = (project?: ProjectClientPersonalDto) => {
    cleanup();

    if (!project) {
      return;
    }

    subscription.value = useCentrifugoApi().subscribePlayerStatus(project.id, (update) => onUpdatePlayerStatus(update));
  };

  const cleanup = () => {
    subscription.value?.unsubscribe();
    state.value = {};
  };

  const onUpdatePlayerStatus = (update: CentrifugoStatusPayload) => {
    Object.entries(update.players).forEach(([key, status]) => {
      state.value[key] = {
        status,
        updated: Date.now()
      };
    });
  };

  const getPlayerStatus = (player: PlayerFullDto): PlayerActualStatusDetails => {
    const obj: PlayerActualStatusDetails = {
      status        : player.status,
      last_online_at: player.last_online_at ?? 0
    };

    const last = state.value[player.steam_id];
    if (!last) {
      return obj;
    }

    if ((player.last_online_at ?? 0) > last.updated) {
      return obj;
    }

    obj.last_online_at = last.updated;
    obj.status = last.status;

    return obj;
  };

  const getPlayerWithStatus = (player: PlayerFullDto): PlayerFullDto => {
    const obj = getPlayerStatus(player);

    return {
      ...player,

      last_online_at: obj.last_online_at,
      status        : obj.status
    };
  };

  const getDefaultStatusColors = (status: PlayerStatusDto) => {
    const dict: Record<PlayerStatusDto, string> = {
      online  : 'bg-ra-online',
      offline : 'bg-ra-offline',
      afk     : 'bg-ra-afk',
      queued  : 'bg-ra-queued',
      joining : 'bg-ra-joining',
      unstable: 'bg-ra-unstable'
    };

    return dict[status];
  };

  const getDefaultStatusText = (player: PlayerFullDto) => {
    const time = _time.format(player.last_online_at || player.created_at, undefined);

    switch (player.status) {
      case 'online': {
        return t('modal.player.status.online');
      }
      case 'offline': {
        if (!(player.last_online_at)) {
          return t('modal.player.status.offline-no-time', { time });
        }

        return t('modal.player.status.offline', { time });
      }
      case 'afk': {
        return t('modal.player.status.afk');
      }
      case 'joining': {
        return t('modal.player.status.joining');
      }
      case 'queued': {
        return t('modal.player.status.queued');
      }
      case 'unstable': {
        return t('modal.player.status.unstable');
      }
      default: {
        return t('general.unknown');
      }
    }
  };

  return { state, getPlayerStatus, getPlayerWithStatus, getDefaultStatusColors, getDefaultStatusText };
});