<script setup lang='ts'>
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import PageHeader from '../PageHeader.vue';
  import { onBeforeMount, onBeforeUnmount, ref, useSlots } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto'; 
  import TextInput from '../inputs/TextInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import Button from '@/components/Button.vue';

  const props = defineProps<{
    header: string,
    autoOpen?: boolean,
    alwaysAbsolute?: boolean,

    defaultSearch?: (search: string) => void;
  }>();

  const { t } = useLocaleStore();
  const slots = useSlots();
  const emits = defineEmits<{
    (e: 'search', search: string): void
  }>();

  const _adaptive = useAdaptiveStore();  
  
  onBeforeMount(() => {
    if (props.autoOpen && slots['side-bar']) {
      _adaptive.editRightBar(true);
    }
  });

  onBeforeUnmount(() => {
    _adaptive.editRightBar(false, true);
  });

  const searchInput = ref<InstanceType<typeof TextInput>>();

  const searchModel = ref<string>('');

  const update = () => {
    props.defaultSearch?.(searchModel.value);
  };

  const isMobileSearchVisible = ref<boolean>(false);

  const toggleMobileSearch = (value: boolean) => {
    isMobileSearchVisible.value = value;
    if (value) {
      searchInput.value?.inputRef?.focus();
      return;
    }
    searchModel.value = '';
  };
</script>

<template>
  <div class="layout-page">
    <div
      id="page-content"
      class="content"
      :class="{'ignore-side': !$slots['side-bar'], 'always-absolute': alwaysAbsolute}"
    >
      <!--<div
        v-if="$slots['side-bar']"
        class="slide-helper -right-1"
        @touchstart="(e) => drag(e, -75)"
      />-->

      <PageHeader
        :title="header ?? 'UNKNOWN'"
      >
        <template #search>
          <div class="right flex items-center gap-2">
            <template v-if="defaultSearch || $slots.custom">
              <slot name="custom" />
              <template v-if="defaultSearch">
                <template v-if="_adaptive.isMobile">
                  <Button
                    preset="default"
                    class="!p-1.5"
                    :action="() => toggleMobileSearch(true)"
                  >
                    <Svg.search class="stroke-grey-500 stroke-2 !fill-none" />
                  </Button>
                  <div class="mobile-search" :class="{'-opened': isMobileSearchVisible}">
                    <TextInput
                      ref="searchInput"
                      v-model="searchModel"
                      :debounce="250"
                      :placeholder="t('players.search-text')"
                      height="32"
                      class="search-input"
                      @debounced-update="update"
                    >
                      <template #append>
                        <Svg.clear
                          class="h-6 w-6 fill-grey-600 pr-2 cursor-pointer"
                          :class="{ 'opacity-0': searchModel.length === 0 }"
                          @click="() => { searchModel = ''; update(); }"
                        />
                      </template>
                    </TextInput>
                    <Button
                      preset="default"
                      class="!p-1.5"
                      :action="() => toggleMobileSearch(false)"
                    >
                      <Svg.arrow_chavron />
                    </Button>
                  </div>
                </template>
                <template v-else>
                  <TextInput
                    ref="searchInput"
                    v-model="searchModel"
                    :debounce="250"
                    :placeholder="t('players.search-text')"
                    height="32"
                    class="search-input"
                    @debounced-update="update"
                  >
                    <template #append>
                      <Svg.clear
                        class="h-6 w-6 fill-grey-600 pr-2 cursor-pointer"
                        :class="{ 'opacity-0': searchModel.length === 0 }"
                        @click="() => { searchModel = ''; update(); }"
                      />
                    </template>
                  </TextInput>
                </template>
              </template>
            </template>
            <template v-else>
              <slot name="search" />
            </template>
          </div>

          <div
            v-if="$slots['side-bar']" 
            @click="_adaptive.editRightBar()"
          >
            <div v-if="$slots['right-icon']" class="cursor-pointer">
              <slot name="right-icon" />
            </div>

            <Button
              v-else
              preset="default"
              class="!p-1.5"
            >
              <Svg.settings_slider_three />
            </Button>
          </div>
        </template>
      </PageHeader>
      <slot />
    </div>
    <div
      v-if="$slots['side-bar']"
      id="page-bar"
      class="side-bar"
    >
      <slot name="side-bar" />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.mobile-search {
  @apply fixed -top-px inset-x-0;
  @apply h-0;
  @apply duration-200;
  @apply flex items-center gap-2.5;
  @apply bg-grey-900;
  @apply px-5;
  @apply overflow-hidden;

  &.-opened {
    @apply h-16;
    @apply py-2.5;
  }
}
.layout-page {
  @apply w-full h-full overflow-hidden;
  @apply relative;

  .content, .side-bar {
    transition: all $adaptive-resize-speed;
    @apply ease-in-out;
  }

  .content {
    @apply flex flex-col;
    overflow-x: auto; 
    @apply w-full h-full;
    @apply bg-grey-1000 relative;
    overscroll-behavior: none;
    
    flex-grow: 1;
    //z-index: 5;
    @apply z-20;

    .slide-helper {
      @apply absolute;
      top: 60px;
      bottom: 0;

      //z-index: 6;
      @apply z-30;

      width: 40px;
    }
    
    &.ignore-side {
      width: 100% !important;
    }
  }

  .side-bar {
    //z-index: 6;
    @apply z-30;
    @apply bg-grey-900;
    @apply p-5;
    @apply h-full;
    @apply border-l border-grey-1000;
    position: absolute;
    top: 0;
    left: 100%;
  }
}

[right-bar] {
  &[device='laptop'], &[device='tablet'] {
    .layout {
      .header {
        margin-right: 450px;
      }
    }
  }
  
  &[device='laptop'] {
    .layout {
      .right {
        display: none;
      }
    }
  }
}
[device='laptop'], [device='tablet'] {
  &[right-bar] {
    .layout {

      .right {
        display: none;
      }
    }
  }
}

[device='desktop'], [device='laptop'], [device='tablet'] {
  .layout {
    .slide-helper {
      display: none;
    }
  }
}

[right-bar] {
  .layout {
    .slide-helper {
      width: 500px;
    }
  }
}

[device="mobile"] {
  .side-bar {
    //z-index: 4;
    @apply z-10;
    width: 350px;
    transform: translateX(-100%);
  }
}

[device="tablet"], [device="laptop"], [device="desktop"] {
  .side-bar {
    width: 450px;
  }
}

[right-bar] {
  &[device="mobile"] {
    .content {
      transform: translateX(-350px);
    }
  }

  &[device="tablet"], &[device="laptop"], &[device="desktop"] {
    .side-bar {
      transform: translateX(-450px);
    }
  }

  &[device="desktop"] {
    .content {
      &:not(.always-absolute) {        
        width: calc(100% - 450px);
      }

      &.always-absolute {
        .header {
          margin-right: 450px;
        }
      }
    }
  }
}

.search-input {
  max-width: 200px;
  height: 32px !important;
  @apply grow;
  &:deep(.input-box) {
    height: 32px !important;
  }
  &:deep(input) {
    @apply px-2 #{!important};
  }
}

[device='mobile'] {
  .search-input {
    max-width: none;
  }
}
</style>