<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { PlayerSessionHistoryDto } from '@/api/backend/court/player/player.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useTimeStore } from '@/stores/time.store';
  import { computed, onMounted, ref } from 'vue';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerDetailsCardBadge from '../../card/PlayerDetailsCardBadge.vue';
  import Divider from '@/components/Divider.vue';
  import PlayerDetailsCard, { PlayerDetailsCardRow } from '../../card/PlayerDetailsCard.vue';

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _court = useCourtStore();
  const props = defineProps<{ steamId: string }>();

  const data = ref<PlayerSessionHistoryDto[] | null>(null);
  const loader = new PaginationService((page, limit) => useCourtApi().player.browsePlayerSessionHistory(props.steamId, {
    page,
    limit
  }), 20);
  
  onMounted(() => next());

  const next = async () => {
    const { results } = await loader.next();

    if (!data.value) {
      data.value = [];
    }

    data.value?.push(...results);
  };

  const getLicense = (entry: PlayerSessionHistoryDto) => {
    return entry.is_license ? t('player.license-official') : t('player.license-pirate');
  };

  const mergedData = computed(() => {
    if (!data.value) {
      return data.value;
    }

    const reversed = JSON.parse(JSON.stringify([...data.value])) as PlayerSessionHistoryDto[];

    const final: PlayerSessionHistoryDto[] = [];

    for (let i = 0; i < reversed.length; i++) {
      const cur = reversed[i];

      const next = reversed[i + 1];
      if (!next) {
        final.push(cur);
        continue;
      }

      if (next.disconnected_reason === 'rustapp-restart') { 
        cur.connected_at = next.connected_at;
        reversed.splice(i + 1, 1);
        cur.merged_amount = (cur.merged_amount ?? 0) + 1;
        i--;
        continue;
      }

      final.push(cur);
    }

    return final;
  });

  const overrideReason = (original: string) => {
    if (original === 'no-plugin-disconnect-event') {
      return t('general.unknown');
    }
    
    return original;
  };

  const rows_exit = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title     : t('player.history.session.session-duration'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('player.history.session.leave-reason'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });

  const rows_entry = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title     : t('general.ip'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('general.server'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('general.client-type'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });

</script>

<template>
  <template v-if="data && data.length == 0">
    <div class="empty-image-container">
      <EmptyImage
        type="empty"
        :title="t('general.empty.no-data.title')"
        :subtitle="t('general.empty.no-data.subtitle')"
      />
    </div>
  </template>
  <template v-else>
    <Loading :can-view="data != null" class="!bg-transparent" />

    <template v-if="data != null">
      <div class="content">
        <template v-for="(value, index) in mergedData" :key="value.connected_at">
          <div>
            <PlayerDetailsCard
              v-if="value.disconnected_at"
              :date-to="_time.format(value.disconnected_at)"
              :rows="rows_exit"
            >
              <template #badges>
                <PlayerDetailsCardBadge preset="red">
                  <template #default>
                    <p class="!px-1 text-sm">{{ t('general.exit') }}</p>
                  </template>
                </PlayerDetailsCardBadge>
              </template>
              <template #row0>
                {{ _time.difference(value.connected_at, value.disconnected_at) }}
              </template>
              <template #row1>
                {{ overrideReason(value.disconnected_reason ?? '') }}
              </template>
            </PlayerDetailsCard>

            <div v-if="value.disconnected_at" class="connector" />

            <PlayerDetailsCard
              :date-from="_time.format(value.connected_at)"
              :rows="rows_entry"
            >
              <template #badges>
                <PlayerDetailsCardBadge
                  preset="green"
                  :class="{ 'animate-pulse': !value.disconnected_at }"
                  :tooltip-text="value.disconnected_at ? '' : t('player.history.session.current')"
                >
                  <template #default>
                    <p class="!px-1 text-sm">{{ t('general.entry') }}</p>
                  </template>
                </PlayerDetailsCardBadge>
              </template>
              <template #row0>
                <span class="hyperlink" @click="ActivateIpDetailsModal(value.ip)">{{ value.ip }}</span>
              </template>
              <template #row1>
                {{ _court.serverNameById(value.server_id) }}
              </template>
              <template #row2>
                {{ getLicense(value) }}
              </template>
            </PlayerDetailsCard> 
          </div>

          <Divider
            v-if="!value.disconnected_at && index != (mergedData?.length ?? 0) - 1"
            :text="t('player.history.session.past-sessions')"
            dashed
          /> 
        </template>
      </div>
    </template>

    <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
  </template>
</template>

<style scoped lang="scss">
.connector {
  @apply h-3 w-0.5;
  @apply bg-grey-850;
  margin: 0 auto;
}
.content {
  @apply flex flex-col gap-5;
}
</style>