<script setup lang="ts">
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaEditSubscription } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';

  defineProps<AuditDto<AuditMetaEditSubscription>>();

  const { t } = useLocaleStore();

  const audit = ref<InstanceType<typeof AuditBase>>();

  const capitalized = (text: string) => {
    const withoutNumber = text.replace(/\d/, '');

    const firstLetter = withoutNumber.charAt(0);

    const firstLetterCap = firstLetter.toUpperCase();

    const remainingLetters = withoutNumber.slice(1);

    return firstLetterCap + remainingLetters;
  };
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="Svg.payment"
    icon_styles="fill-grey-500"
    :date="created_at"
    ready
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ t('audit-edit-subscription-changed') }}</span>
    <span class="light">{{ capitalized(meta.from) }}</span>
    <span>{{ t('audit-edit-subscription-to') }}</span>
    <span class="light">{{ capitalized(meta.to) }}</span>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>