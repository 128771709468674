<script setup lang="ts"></script>

<template>
  <div class="background">
    <RouterView />
  </div>
</template>

<style lang="scss" scoped>
.background {
  @apply flex h-full items-center justify-center;
  @apply bg-grey-900;
   
  background-image: url(https://s3.rustapp.io/custom/noise.png); 
  background-position: 0 0; 
  background-size: 1440px; 

  width: 100vw;
  height: 100vh;
}
</style>
