<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import { UploadedFile } from '../utils/uploader.dto';
  import UploaderAreaListItem from './UploaderAreaListItem.vue';

  defineProps<{ 
    header: string, 
    choose: () => void,
    multiple?: boolean
  }>();
  const emit = defineEmits(['delete']);

  const { t } = useLocaleStore();

  const files = defineModel<UploadedFile[]>({ required: true });

  const remove = (file: UploadedFile) => {
    const existsIndex = files.value.findIndex(v => (`${v.file?.lastModified}/${v.file?.name}` === `${file.file?.lastModified}/${file?.file?.name}` && v.file && file.file) || (v.s3_id === file.s3_id && !!v.s3_id));
    
    if (existsIndex === -1) {
      return;
    }

    files.value.splice(existsIndex, 1);
  };
</script>

<template>
  <div>
    <div class="uploader-list-header">
      <span class="label">
        {{ header }}
      </span>
      <span
        v-if="multiple"
        class="more"
        @click="choose()"
      >
        {{ t('modal.ban.proofs.files.more') }}
      </span>
    </div>
    <div
      key="list" 
      class="file-list"
      :class="{ 'bold-scrollbar': modelValue.length > 3}"
    >
      <TransitionGroup name="list" @after-leave="emit('delete')">
        <UploaderAreaListItem
          v-for="value in files"
          :key="value.file?.lastModified ?? value.s3_id"
          :image="value"
          @delete="() => remove(value)"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.file-list {
  max-height: 218px;
  @apply overflow-y-auto overflow-x-hidden;
  @apply flex flex-col gap-2.5;
  &.bold-scrollbar {
    padding-right: 10px; 
  }
}

.uploader-list-header {
  @apply flex justify-between;
  
  .label {
    @apply text-grey-400;
    @apply mb-1;
    @apply flex items-center justify-between;
  }  
  .more {
    @apply cursor-pointer;
    @apply text-primary-700;
    &:hover {
      @apply text-primary-600;
    }
  }
}
</style>