<script setup lang="ts">
  import { useConfigStore } from '@/stores/config/config.store';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { computed, ref } from 'vue';
  
  const props = defineProps<{
    // eslint-disable-next-line vue/prop-name-casing
    steam_id: string
    // eslint-disable-next-line vue/prop-name-casing
    steam_name: string;
    // eslint-disable-next-line vue/prop-name-casing
    steam_avatar?: string;
    type?: 'default' | 'small';

    hideAvatars?: boolean;
    disableClick?: boolean;
    customClick?: (e: MouseEvent) => unknown;
  }>();

  const _config = useConfigStore();
  const error = ref<boolean>(false);

  const computedAvatar = computed(() => {
    if (error.value) {
      return _config.Avatars.PlayerNotExistsPlaceholder;
    }

    return props.steam_avatar ?? _config.Avatars.PlayerNotExistsPlaceholder;
  });

  const onClick = (e: MouseEvent) => {
    if (props.disableClick) {
      return;
    }

    if (props.customClick) {
      props.customClick(e);
      return;
    }

    ActivatePlayerDetailsModal(props.steam_id);
  };
</script>

<template>
  <a
    :class="['player', type]"
    :href="customClick ? undefined : `?player=${steam_id}`"
    @click.prevent="e => onClick(e)"
  >
    <img
      v-if="!hideAvatars && steam_avatar"
      :class="['avatar', type]"
      :src="computedAvatar.replace('_full', '')" 
      class="mr-1.5"
      @error="error = true"
    >
    <span class="name">{{ steam_name }}</span>
  </a>
</template>

<style scoped lang="scss">
.player {
  &.default {
    @apply inline cursor-pointer;
  }
  &.small {
    @apply flex items-center cursor-pointer;
  }

  .name {
    @apply truncate text-16-400 w-full;
  }
}
.avatar {
  @apply bg-white/5 aspect-square w-5 h-5;
  &.default {
    @apply inline rounded-full;
    margin-bottom: 0.188em;
  }
  &.small {
    @apply rounded-full aspect-square;
  }
}
</style>