<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import BaseTip from './BaseTip.vue';
  import { useCourtStore } from '@court/stores/court.store';

  defineProps<{ version: string }>();

  const { t } = useLocaleStore();
  const _court = useCourtStore();
</script>

<template>
  <BaseTip v-if="!_court.isAllServerUpdatedUpTo(version)" type="danger">
    {{ t('general-not-updated-all', { version }) }}
  </BaseTip>
</template>

<style lang='scss' scoped>
.tip {
  @apply flex justify-between p-2 gap-2 rounded-md;
  @apply bg-grey-800;
  @apply text-grey-300 text-sm;

  .icon {
    @apply h-5 w-5 flex-shrink-0;
    @apply fill-grey-200;
  }

  .close {
    @apply h-4 w-4 flex-shrink-0;
    @apply fill-grey-50/25;
    @apply cursor-pointer;
  }

  &.info {
    @apply bg-grey-800 text-grey-200;

    .icon {
      @apply fill-grey-200;
    }

    .close {
      @apply fill-grey-200/50;
    }
  }

  &.warning {
    @apply bg-amber-400/15 text-amber-400;

    .icon {
      @apply fill-amber-400;
    }

    .close {
      @apply fill-amber-400/50;
    }
  }

  &.hint {
    @apply bg-primary-500/15 text-primary-500;

    .icon {
      @apply fill-primary-500;
    }

    .close {
      @apply fill-primary-500/50;
    }
  }

  &.danger {
    @apply bg-red-500/15 text-red-500;

    .icon {
      @apply fill-red-500;
    }

    .close {
      @apply fill-red-500/50;
    }
  }
}
</style>