<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto';
  import PlayerNavigationHistorySession from './PlayerNavigationHistorySession.vue';
  import PlayerNavigationHistoryIp from './PlayerNavigationHistoryIp.vue';
  import PlayerNavigationHistoryName from './PlayerNavigationHistoryName.vue';

  defineProps<{ steamId: string }>();

  const { t } = useLocaleStore();
  const emits = defineEmits<ModalMenuExtendedEvents>();
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template #[t(`history-filter-sessions`)]>
      <PlayerNavigationHistorySession :steam-id="steamId" />
    </template>
    <template #[t(`history-filter-ip`)]>
      <PlayerNavigationHistoryIp :steam-id="steamId" />
    </template>
    <template #[t(`history-filter-nicknames`)]>
      <PlayerNavigationHistoryName :steam-id="steamId" />
    </template>
  </ModalMenuExtendedPageLayout>
</template>
