<script lang="ts">
  import { useModalsStore } from '@/stores/modals.store';
  import { useMoney } from '@src/composable/money.composable';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { usePaymentsStore } from '@settings/pages/payments/store/payments.store';
  import PlanIcon from '@court/components/plans/PlanIcon.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import { CourtLimitsUsageDto, CourtSubscriptionTypes } from '@/api/backend/court/court/court.dto';
  import ButtonSelect from '@/components/ButtonSelect.vue';
  import { ref } from 'vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import Divider from '@/components/Divider.vue';

  export type ChangePlanModalOpts = {
    landing: boolean;
  }

  export const ActivateCourtPlanModal = async (props: ChangePlanModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('court-plan', props);
  };
</script>

<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { computed, onMounted } from 'vue';
  import { useWindowSize } from '@vueuse/core';

  const _time = useTimeStore();
  const _modal = useModalsStore();
  const _payments = usePaymentsStore();
  const _subscription = useCourtSubscriptionStore();

  const { toLocaleBalance, toMajorUnits } = useMoney();
  const { t, lang } = useLocaleStore();
  const { width: windowWidth } = useWindowSize();

  const props = defineProps<ChangePlanModalOpts>();

  onMounted( async () => {
    _subscription.warmup(props.landing, false).then(v => {
      mobileSelectValue.value = _subscription.state?.current.type ?? '1starter';
    });

    mobileSelectValue.value = _subscription.state?.current?.type ?? '1starter';
  });

  const isCurrentPlan = (type: string) => {
    return _subscription.state?.current.type === type;
  };

  const setPlan = async (id: number) => {
    if (!_subscription.state) {
      return;
    }

    const sub = _subscription.state.available.find(v => v.id === id);
    if (!sub) {
      return;
    }
    
    if (_payments.wallet && _payments.wallet.balance < sub.price) {
      const left = Math.round((sub.price - _payments.wallet.balance) / 100).toFixed(0);
      const leftText = t('general.money', +left);

      ActivateConfirmModal({
        type       : 'warn',
        title      : t('plan-need-money-title'),
        description: t('need-money-text', { amount: leftText })
      });
      return;
    }

    if (sub.limits.players < _subscription.state.usage.players) {
      useRuntimeNotification(`warning`, t('plan-warning-players-limit'));
      return;
    }

    if (sub.limits.servers < _subscription.state.usage.servers) {
      useRuntimeNotification(`warning`, t('plan-warning-servers-limit'));
      return;
    }

    if (!_subscription.state.current.price) {
      ActivateConfirmModal({
        type         : 'warn',
        title        : t('general.are-you-sure'),
        description  : t('plan-no-back-to-free-plan'),
        beforeConfirm: async () => setPlanCheckd(id)
      });
      return;
    }

    if (sub.price > _subscription.state.current.price) {
      ActivateConfirmModal({
        type         : 'warn',
        title        : t('general.are-you-sure'),
        description  : t('plan-confirm-cant-back-desc'),
        beforeConfirm: async () => setPlanCheckd(id)
      });
    } else if (_subscription.state.current.price && _subscription.state.current.price > sub.price) {
      ActivateConfirmModal({
        type         : 'warn',
        title        : t('general.are-you-sure'),
        description  : t('plan-temp-cant-back'),
        beforeConfirm: async () => setPlanCheckd(id),
      });
    }
  };

  const setPlanCheckd = async (id: number) => {
    try {
      await useCourtApi().court.setSubscription(id);

      await refreshPlan();
    }
    catch (err: any) {
      if (err?.message.includes('Not enough balance')) {
        useRuntimeNotification(`warning`, t('plan-not-enough-balance'));
        await refreshPlan();
        return;
      }

      if (err?.message.includes('Something went wrong')) {
        useRuntimeNotification(`error`, t('plan-somerhing-went-wrong'));
        await refreshPlan();
        return;
      }

      if (err?.message.includes(`can't change subscription back. Time left`)) {
        const secondsLeft = +err.message.split('Time left: ')[1];

        useRuntimeNotification(`error`, t('plan-cant-change-subs-back', {time: _time.difference(new Date(), new Date(Date.now() + secondsLeft))}), {
          overrideTitle: t('plan-cant-change-subs-back-title')
        });
        return;
      }

      throw err;
    }
  };

  const refreshPlan = async () => {
    await Promise.all([
      _subscription.warmup(props.landing, false),
      usePaymentsStore().warmup()
    ]);
  };

  const isMobileScreen = computed(() => windowWidth.value < 890);

  const getLimitText = (amount: number, key: keyof CourtLimitsUsageDto) => {
    switch (key) {
      case 'players':
        return t('change-plan.limits.players', { amount: amount >= 1_000_000 ? t('change-plan.limits.no-limit') : amount.toLocaleString(lang) });
        break;
      case 'servers': 
        return t('change-plan.limits.servers', amount >= 100 ? 0 : amount);
      default:
        break;
    }
  };

  const iconDict = {
    players: Svg.players,
    servers: Svg.server,
  };

  const mobileSelectOpts = computed(() => {
    return _subscription.getPlanList().map(v => {
      return {
        value: v.type,
        text : _subscription.planTypeBeautifier(v.type)
      };
    });
  });

  const mobileSelectValue = ref<CourtSubscriptionTypes>('1starter');
</script>

<template>
  <DividedCard
    v-if="_subscription.state"
    class="change-plan-modal"
    :class="{ 'small': _subscription.getPlanList().length == 3 }"
    no-content-padding
  >
    <template #header>
      <span class="text-grey-50 text-16-600 block">{{ t('change-plan.title') }}</span>
      <Svg.cross_large class="close" @click="_modal.close" />
    </template>

    <template #default>
      <div class="plan-list">
        <div
          v-if="isMobileScreen" 
          class="p-5 pb-0 shrink-0"
        >
          <ButtonSelect
            v-model="mobileSelectValue"
            :options="mobileSelectOpts"
          />
        </div>
        <template v-for="plan in _subscription.getPlanList()" :key="plan.type">
          <div
            v-show="!isMobileScreen || mobileSelectValue === plan.type"
            class="plan"
            :class="_subscription.planTypeBeautifier(plan.type)"
          >
            <p class="title">
              <span>
                {{ _subscription.planTypeBeautifier(plan.type) }}
              </span>
              <PlanIcon :plan="plan.type" />
            </p>
            <p class="flex items-center">
              <template v-if="plan.id === 0 || plan.id === 1">
                <TooltipBase trigger="hover">
                  <template #content>
                    <div class="p-1">
                      {{ t('time-limit-7d-tooltip') }}
                    </div>
                  </template>

                  
                  <span class="month">
                    {{ t('time-limit-7d') }}
                  </span>
                </TooltipBase>
              </template>
              <template v-else>
                <span class="plan-sum">
                  {{ plan.price >= 0 ? toLocaleBalance(toMajorUnits(plan.price), undefined, 0) : 'Custom' }}
                </span>
                <span v-if="plan.price >= 0" class="month">
                  {{ t('change-plan.per-month') }}
                </span>
              </template>
            </p>

            <div class="mt-5">
              <template v-if="isCurrentPlan(plan.type)">
                <Button
                  preset="default"
                  :text="t('change-plan.current')"
                  class="select-button w-full !py-2 !text-14-400"
                  disabled
                />
              </template>
              <template v-else>
                <Button
                  preset="default"
                  :text="t('change-plan.chose')"
                  class="select-button w-full !py-2 !text-14-500"
                  :class="_subscription.planTypeBeautifier(plan.type)"
                  :action="() => setPlan(plan.id)"
                />
              </template>
            </div>

            <Divider dashed class="my-5" />

            <div class="flex flex-col gap-2.5">
              <template v-for="(value, key) in plan.limits" :key="key">
                <p class="limit" :class="{ '-over': _subscription.state.usage[key] > value}">
                  <component :is="iconDict[key]" class="w-4" />
                  <span class="text-12-400">{{ getLimitText(value, key) }}</span>
                </p>
              </template>
            </div>
            
            <Divider dashed class="my-5" />

            <div class="feature-list">
              <template v-for="value in Object.values(_subscription.features).filter(v => v.from_plan === plan.type)" :key="value.text">
                <TooltipBase trigger="hover" disable-auto-hide>
                  <template v-if="value.tooltip" #content>
                    <div class="p-2 text-xs flex flex-col gap-2" style="max-width: min(95vw, 400px)">
                      <p v-if="value.tooltip.text">{{ value.tooltip.text }}</p>
                      <img
                        v-if="value.tooltip.image"
                        :src="value.tooltip.image"
                        class="rounded-md object-cover"
                        style="max-height: 200px"
                      >
                    </div>
                  </template>
                  
                  <div class="feature">
                    <div class="box" :class="_subscription.planTypeBeautifier(plan.type)">
                      <Svg.checkmark class="w-4" />
                    </div>
                    <span :class="{ 'cursor-pointer hover:text-grey-300': value.tooltip }">{{ value.text }}</span>
                  </div>
                </TooltipBase>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>
.change-plan-modal {
  width: 95vw;
  max-width: 1000px;

  height: 90vh;
  max-height: 620px;

  &.small {
    max-width: 800px;
  }

  @media (max-width: 890px) {
    max-width: 400px;
  }

  &:deep(.content) {
    @apply grow;
    max-height: none;
  }
  
  .plan-list {
    @apply grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));


    .plan {
      @apply p-5;
      @apply h-full;
      @apply flex flex-col;
      @apply border-r border-grey-1000;
      @apply relative;

      &::before {
        @apply content-[""];
        @apply absolute;
        @apply inset-0;
        @apply opacity-5;
        pointer-events: none;
      }
      
      &.Starter {
        &::before {
          background: radial-gradient(circle at left top, rgba(67, 166, 240, 50) 0%, rgba(67, 166, 240, 0) 80%);
        }
      }
      &.Basic {
        &::before {
          background: radial-gradient(circle at left top, rgba(180, 241, 142, 50) 0%, rgba(180, 241, 142, 0) 80%);
        }
      }
      &.Pro {
        &::before {
          background: radial-gradient(circle at left top, rgba(255, 198, 85) 0%, rgba(255, 198, 85, 0) 80%);
        }
      }


      .title {
        @apply text-grey-50;
        @apply text-20-600;
        @apply flex gap-1.5 items-center;
        @apply mb-2.5;
      }

      &:last-child {
        @apply border-r-0;
      }
      
      .plan-sum {
        @apply text-grey-50;
        @apply text-[20px];
      }

      .month {
        @apply text-[16px];
        @apply text-grey-400;
      }

      .limit {
        @apply flex items-center gap-1;
        @apply text-grey-50;

        &.-over {
          @apply text-orange-400;
          
          svg {
            @apply fill-orange-400;
          }
        }

        svg {
          @apply fill-grey-200;
        }
      }

      .feature-list {
        @apply flex flex-col gap-2.5;

        .feature {
          @apply flex items-start gap-1.5;
          @apply text-grey-50;
          @apply text-12-400;

          .box {
            @apply w-4 rounded-[4px];

            &.Free {
              @apply bg-[#D1D1D1]/15;
              @apply fill-[#D1D1D1];
            }
            &.Starter {
              @apply bg-[#43A6F0]/15;
              @apply fill-[#43A6F0];
            }
            &.Basic {
              @apply bg-[#B4F18E]/15;
              @apply fill-[#B4F18E];
            }
            &.Pro {
              @apply bg-[#FFC555]/15;
              @apply fill-[#FFC555];
            }
          }
        }
      }

      
      .select-button {
        &.Starter {
          @apply bg-[#43A6F0]/15;
          @apply text-[#43A6F0];

          &:hover {
            @apply bg-[#43A6F0]/20;
          }
        }
        &.Basic {
          @apply bg-[#B4F18E]/15;
          @apply text-[#B4F18E];

          &:hover {
            @apply bg-[#B4F18E]/20;
          }
        }
        &.Pro {
          @apply bg-[#FFC555]/15;
          @apply text-[#FFC555];

          &:hover {
            @apply bg-[#FFC555]/20;
          }
        }
      } 
    }
    @media (max-width: 890px) {
      @apply flex flex-col;
      grid-template-columns: none;

      .plan {
        @apply border-none;
        &::before {
          @apply content-none;
        }
      }
    }
  }

  ////

  .row {
    @apply flex;

    .feature-value,
    .category-title,
    .feature-name,
    .plan {
      @apply basis-1/4;
    }

    
    .plan {
      @apply pt-5 px-2;
      @apply sticky top-5;
      @apply bg-grey-900;

      &.current {
        &::before {
          content: "";
          @apply absolute inset-0 -z-10;
          @apply bg-grey-950;
          @apply rounded-t-md;
        }
      }
      
      .plan-header {
        .rap {
          @apply flex gap-2 items-center mb-4;

          .title {
            @apply text-grey-50;
            @apply text-20-500;
            @apply capitalize;
          }
        }

        .plan-sum {
          @apply text-grey-50;
          @apply text-24-500;
        }

        .month {
          // @apply ml-2;
          @apply text-16-400;
          @apply text-grey-400;
        }
      }
    }
    
    .category-title {
      @apply text-grey-50;
      @apply text-18-500;
      @apply leading-10;

      @apply flex items-end;
    }
    .feature-value {
      @apply relative z-20;
      @apply fill-primary-700;
      @apply text-center;
      // @apply flex items-center justify-center;
      @apply text-18-400;
      @apply leading-10;
      @apply h-10;
      @apply text-grey-100;

      p {
        @apply h-[39px];
      }
      
      svg:only-child {
        @apply mx-auto;
      }
      &.current {
        &::before {
          content: "";
          @apply absolute inset-0 -bottom-[1px] -z-10;
          @apply bg-grey-950;
        }
      }

      &.fake {
        @apply pt-5;
        @apply h-16;
      }
    }
    
    .feature-name {
      @apply text-grey-400;
      @apply text-16-400;
      @apply leading-10;
      @apply h-10;
    }
  }
}

@media (max-width: 1000px) {
  .change-plan-modal {
    @apply overflow-y-auto;
    .row {
      @apply flex-wrap gap-5;
      .plan {
        position: static;
        @apply w-full;
        @apply pb-5;
        @apply basis-full;
        @apply border border-grey-800;
        @apply rounded-md;

        &.current {
          @apply border-primary-900;
        }

        &.fake {
          @apply hidden;
        }

        .plan-header {
          .title {
            @apply text-16-500;
          }

          .plan-sum {
            @apply text-20-500;
          }

          .month {
            @apply text-12-400;
          }
        }
      }
    }
  }
}

.close {
  @apply w-5;
  @apply fill-grey-400;
  @apply cursor-pointer;

  &:hover {
    @apply fill-grey-200;
  }
}
</style>