<script setup lang='ts'>
</script>

<template>
  <div class="">
    <RouterView
      name="navigation"
    />

    <div class="extra-space" />
  </div>
</template>

<style lang='scss' scoped>
[device='mobile'] {
  .extra-space {
    @apply absolute left-full bottom-0 top-0 w-10;
  }
} 
</style>