
import { Axios } from "axios";
import { CourtApiDto } from "./court-api.dto";

export const useCourtCourtApiApi = (api: Axios) => {
  const get = async () => {
    const response = await api.get<CourtApiDto>('/court-api');
  
    return response.data;
  };

  const toggle = async (value: boolean) => {
    const response = await api.post<void>('/court-api/toggle', { }, {
      params: {
        value
      }
    });
  
    return response.data;
  };

  const toggleRoutes = async (routes: string[]) => {
    const response = await api.post<void>('/court-api/public-api-routes', routes);
  
    return response.data;
  };

  const regenerate = async () => {
    const response = await api.post<CourtApiDto>('/court-api/regenerate');
  
    return response.data;
  };

  return {
    get,
    toggle,
    toggleRoutes,
    regenerate,
  };
};
