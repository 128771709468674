<script lang="ts" setup>
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { CourtCommandPayloadDto } from '@/api/backend/court/commands/commands.dto';
  import CheckboxInput from '@/components/quality/inputs/CheckboxInput.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { computed } from 'vue';

  const { t } = useLocaleStore();
  const _court = useCourtStore();

  const data = defineModel<CourtCommandPayloadDto>({ required: true });

  const groupSuggester = () => {
    return (_court.commands ?? []).reduce<string[]>((acc, v) => {
      if (!acc.includes(v.group)) {
        acc.push(v.group);
      }

      return acc;
    }, []);
  };

  const availableAccessType: Record<string, string> = {
    green : t('customization.actions.modal.level.green'),
    yellow: t('customization.actions.modal.level.yellow'),
    red   : t('customization.actions.modal.level.red'),
    admin : t('customization.actions.modal.level.admin')
  };

  const accessTypeSuggester = computed(() => {
    return Object.entries(availableAccessType).map<SelectValue<string>>(([name, text]) => {
      return {
        value    : name,
        text,
        icon     : Svg.wall,
        iconClass: name
      };
    });
  });
</script>

<template>
  <div class="content">
    <TextInput
      v-model="data.name"
      :label="t('customization.actions.modal-create.input.name.label')"
      :placeholder="t('customization.actions.modal-create.input.name.placeholder')"
    />

    <div>
      <p class="label">{{ t('customization.actions.modal-create.input.group.label') }}</p>
      <SuggesterInput
        v-model="data.group"
        :suggester="groupSuggester"
        :compiler="(value) => (value)"
        class="!p-2"
        :placeholder="t('customization.actions.modal-create.input.group.placeholder')"
        :icon="Svg.select"
        insta-suggest
      >
        <template #suggest="{ value }">
          <div class="suggest">
            <p>{{ value }}</p>
          </div>
        </template>
      </SuggesterInput>
    </div>

    <div>
      <div class="flex items-center gap-1 mb-1">
        <p class="label !mb-0">{{ t('customization.actions.modal-create.input.level.label') }}</p>

        <TooltipBase trigger="hover">
          <template #content>
            <div class="px-2 py-1 max-w-[350px] text-sm">
              {{ t('access-level-command-tooltip') }}
            </div>
          </template>

          
          <Svg.circle_info class="w-4 h-4 fill-grey-400" />
        </TooltipBase>
      </div>
      <SelectInput
        v-model="data.access"
        class="select-input-command"
        variant="modal"
        :values="accessTypeSuggester"
        :placeholder="t('customization.actions.modal-create.input.level.placeholder')"
      />
    </div>
    

    <div class="flex flex-col gap-2">
      <TooltipBase
        trigger="hover"
        placement="top"
        distance="12"
        skidding="-188"
      >
        <template #content>
          <div class="tooltip-content">
            {{ t('tooltip.action.modal-create.online') }}
          </div>
        </template>
      
        <CheckboxInput
          v-model="data.allow_offline"
          :text="t('checkbox.action.modal-create.online')"
        />
      </TooltipBase>
      <TooltipBase
        trigger="hover"
        placement="top"
        distance="12"
        skidding="-188"
      >
        <template #content>
          <div class="tooltip-content">
            {{ t('tooltip.action.modal-create.server') }}
          </div>
        </template>
      
        <CheckboxInput
          v-model="data.allow_server_choose"
          :text="t('checkbox.action.modal-create.server')"
        />    
      </TooltipBase>
      <TooltipBase
        trigger="hover"
        placement="top"
        distance="12"
        skidding="-188"
      >
        <template #content>
          <div class="tooltip-content">
            {{ t('tooltip.action.modal-create.accept') }}
          </div>
        </template>
      
        <CheckboxInput
          v-model="data.force_confirmation"
          :text="t('checkbox.action.modal-create.accept')"
        />
      </TooltipBase>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.content {
  @apply flex flex-col gap-4;
}

.label {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}

.suggester-input {
  ::placeholder {
    @apply pl-0.5;
  }
}


:deep(.select-input-command) {
  svg {
    @apply w-4;
  
    &.green {
      @apply fill-lime-500 #{!important};
    }
    &.yellow {
      @apply fill-amber-500 #{!important};
    }
    &.red {
      @apply fill-red-500 #{!important};
    }
    &.admin {
      @apply fill-grey-200 #{!important};
    }
  }
}


.item {
  @apply flex gap-1.5;
  @apply py-1 px-1.5;
  @apply text-base leading-5;

  svg {
    @apply w-4;
  }

  &.green {
    svg {
      @apply fill-lime-500;
    }
  }
  &.yellow {
    svg {
      @apply fill-amber-500;
    }
  }
  &.red {
    svg {
      @apply fill-red-500;
    }
  }
  &.admin {
    svg {
      @apply fill-grey-200;
    }
  }
}

.tooltip-content {
  @apply px-2 py-1 max-w-[250px] text-sm;
}

.suggest {
  @apply p-1.5;
}
</style>