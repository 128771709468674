<script setup lang="ts">  
  import { ProjectClientDto } from '@/api/backend/core/project/project.dto';
  import { useAppStore } from '@/stores/app.store'; 
  import { useLocaleStore } from '@/stores/locale.store'; 
  import { usePanelStore } from '@panel/stores/panel.store'; 
  import { ActivateClientManageModal } from '@settings/modals/permissions-edit/ClientManageModal.vue'; 
  import { Svg } from '@src/assets/auto_gen_types.dto'; 
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue'; 
  import { shuffle } from 'lodash'; 
  import { firstBy } from 'thenby'; 
  import { computed, onMounted } from 'vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue'; 
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import Divider from '@/components/Divider.vue';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { useWindowSize } from '@vueuse/core';
  import { useSituationStore } from '@panel/stores/situation.store';
  import { useRouter } from 'vue-router';
  import { CourtRoutes } from '@court/court.routes';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();

  const _app = useAppStore(); 
  const _router = useRouter();
  const _panel = usePanelStore();
  const _situation = useSituationStore();
  const _permissions = usePermissionsStore();

  const { width } = useWindowSize();

  onMounted(() => { 
    _panel.updateClients(); 
  }); 

  const dropdownOptions = (client: ProjectClientDto): ExtendedMenuItem[] => {
    return [{ 
      label : t('staff.edit-dropdown.open-profile'), 
      action: () => ActivateClientDetailsModal(client.client.id, client.client) 
    }, {
      label : t('staff.edit-dropdown.open-stats'),
      action: () => {
        _router.push({ name: CourtRoutes.Stats, query: { client: client.client.id }});
      }
    }, { 
      label   : t('staff.edit-dropdown.edit-permissions'), 
      action  : () => ActivateClientManageModal({client}),
      disabled: () => !canEditRight(client),
    }, { 
      label : t('staff.edit-dropdown.kick'), 
      action: async () => {   
        ActivateConfirmModal({ 
          type         : 'warn', 
          title        : t('staff.kick.title'), 
          description  : t('staff.kick.subtitle'), 
          beforeConfirm: async () => {
            if (!_panel.project) {
              return;
            }

            await useCoreApi().project.deleteClient(client.client.id, _panel.project.id); 

            await _panel.updateClients(); 
          },
        }); 
      },
      disabled: () => !canEditRight(client),
      class   : 'destructive'
    }];  
  }; 

  const isOwner = (client: ProjectClientDto) => client.client.id === getPanelOwnerId.value; 
  const isAdmin = (client: ProjectClientDto) => client.is_immutable; 

  const getPanelOwnerId = computed(() => _panel.project?.owner_client_id); 

  const getOrderedClients = computed(() => { 
    const arr = shuffle([..._panel.projectClients]); 

    return arr.sort( 
      firstBy<ProjectClientDto>((a,b) => +isOwner(b) - +isOwner(a)) 
        .thenBy<ProjectClientDto>((a,b) => +isAdmin(b) - +isAdmin(a)) 
        .thenBy((a,b) => a.client.id - b.client.id) 
    ); 
  }); 

  const canEditRight = (targetClient: ProjectClientDto) => { 
    const client = _panel.projectClients.find(v => v.client.id === _app.client?.id); 
    if (!client) { 
      return false; 
    }

    if (client.client.id === targetClient.client.id) {
      return false;
    }

    // Владельца не может никто
    if (isOwner(targetClient)) {
      return false;
    }

    // Вледелец может всё
    if (isOwner(client)) {
      return true;
    }

    // Этот не может ничего
    if (!_permissions.can(['Core_ClientsEdit'])) {
      return false;
    }

    // Владельца не может никто
    if (isOwner(targetClient)) {
      return false;
    }

    return !isAdmin(targetClient); 
  }; 
  const perRow = computed(() => {
    if (width.value <= 601) {
      return 2;
    }
    if (width.value <= 750) {
      return 3;
    }
    return 4;
  });

  // узнает, сколько не хватает количеству картинок, чтобы делиться на perRow без остатка
  const amountForFullRow = computed(() => {
    const remainder = getOrderedClients.value.length % perRow.value;
    return remainder ? (perRow.value - remainder) : 0;
  });


  const status = (clientId: number) => {
    return Object.values(_situation.clients).some(v => v == clientId.toString()) ? 'bg-ra-online' : 'bg-ra-offline';
  };

</script>

<template>
  <div class="staff-page">
    <div class="title">
      <span class="text-head">{{ t('staff.clients') }}</span>
      <span
        v-if="_permissions.can(['Core_ClientsEdit'])"
        class="text-add"
        @click="ActivateClientManageModal({})"
      >{{ t('staff.add') }}</span>
    </div>
    <div class="clients">
      <template v-if="getOrderedClients.length == 0">
        <div class="clients-grid">
          <template v-for="(_) in 4" :key="_">
            <div class="client-card empty" />
          </template>
        </div>
      </template>
      <template v-else>
        <div class="clients-grid">
          <template v-for="value in getOrderedClients" :key="value.client.id">
            <div class="client-card" @click="(e) => useContextMenu().open(e, dropdownOptions(value))">
              <img
                :src="useConfigStore().Urls.Images(value.client.avatar || '')"
                class="blur-avatar"
              >
              
              <div class="background" /> 

              <div class="client">
                <div class="image avatar">
                  <div class="image-mask"> 
                    <svg role="none" class="w-full h-full">
                      <mask :id="value.client.id.toString()">
                        <circle class="mask-circle" />
                        <circle class="cut" />
                      </mask>
    
                      <g :mask="`url(#${value.client.id})`">
                        <image
                          class="w-full h-full avatar"
                          x="0"
                          y="0"
                          preserveAspectRatio="xMidYMid slice"
                          :xlink:href="useConfigStore().Urls.Images(value.client.avatar || '')"
                        />
                      </g>
                    </svg>            
    
                    <div :class="['badge', status(value.client.id)]" />
                  </div>
                </div>
                <p class="name">{{ value.client.name }}</p>
                <p class="username">{{ '@' + value.client.tag }}</p>
              </div>

              <div v-if="value" class="role-icon">
                <TooltipBase 
                  trigger="hover" 
                  placement="top"
                > 
                  <div class="flex items-center gap-2"> 
                    <Svg.crown 
                      v-if="isOwner(value)" 
                      class="crown" 
                    /> 
                    <Svg.diamond 
                      v-else-if="isAdmin(value)"
                      class="diamond" 
                    /> 
                  </div> 
                  <template #content> 
                    <div 
                      v-if="isOwner(value)" 
                      class="tooltip-text" 
                    > 
                      <p>{{ t('staff.owner') }}</p> 
                    </div> 
                    <div 
                      v-else-if="isAdmin(value)" 
                      class="tooltip-text" 
                    > 
                      <p>{{ t('staff.admin') }}</p> 
                    </div> 
                  </template> 
                </TooltipBase> 
              </div>
            </div>
          </template>
          <template v-for="(_) in amountForFullRow" :key="_">
            <div class="client-card empty" />
          </template>
        </div>
      </template>
    </div>

    <template v-if="_permissions.can(['Core_ClientsEdit'])">
      <Divider class="my-8" />

      <div class="bottom">
        <p class="title">{{ t('staff.bottom.warning.title') }}</p>
        <p class="subtitle">{{ t('staff.bottom.warning.subtitle') }}</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
$per-row: v-bind('perRow');
.staff-page {
  @apply flex flex-col;
  .title {
    @apply flex items-center mb-5;

    .text-head {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    
    .text-add {
      @apply ml-auto;
      @apply cursor-pointer;
      @apply text-primary-700;

      &:hover {
        @apply text-primary-600;
      }
    }
  }

  .clients-grid {
    
  --image-size: 50px;

  --title-size: 16px;
  --message-size: 14px;

  --badge-size: 9px;
  --border-size: 3px;
  
  --gap-size: 8px;

  
$image-size: var(--image-size);

$badge-size: var(--badge-size);
$border-size: var(--border-size);

$gap-size: var(--gap-size);

// Считаем доп. поле для маски
$cut-badge-size: calc(var(--badge-size) + calc(var(--border-size) * 2));

.image {
  @apply relative flex justify-center items-center;

  width: $image-size;
  height: $image-size;
  flex-basis: $image-size;

  .img-box, img, image, svg {
    @apply rounded-half w-full h-full object-cover;
  }
  
  .image-mask {
    width: $image-size;
    height: $image-size;
    
    .mask-circle {
      cx: calc($image-size / 2);
      cy: calc($image-size / 2);
      r: calc($image-size / 2);
      fill: white;
    }

    .cut {
      fill: black;
      r: calc($cut-badge-size / 2);
      transform: translate(calc($image-size - calc($cut-badge-size / 2)), calc($image-size - calc($cut-badge-size / 2)));
    }
  }
  
  .badge {
    @apply absolute;
    @apply rounded-full;
    @apply transition-colors ease-in-out delay-0 duration-1000;
    
    bottom: $border-size;
    right: $border-size;
    
    height: $badge-size;
    width: $badge-size;
  }
}

    @apply grid gap-2.5;
    grid-template-columns: repeat($per-row, 1fr);

    .client-card {
      @apply aspect-[1.1] overflow-hidden relative;
      @apply rounded-md;
      @apply cursor-pointer;
      @apply bg-grey-900;

      .blur-avatar {
        @apply h-full w-full;
        filter: blur(10px);
        object-fit: cover;
      }

      .background {
        @apply absolute inset-0 h-full w-full bg-grey-900/80;
      }

      .client {
        @apply flex flex-col h-full w-full absolute inset-0 items-center justify-center;

        &.avatar, .avatar {
          @apply h-[50px] w-[50px] rounded-half mb-2.5;
          object-fit: cover;
        }
        .name {
          width: 80%;

          @apply truncate text-center;
          @apply text-grey-50 text-base font-semibold;
        }
        .username {
          width: 80%;

          @apply truncate text-center;

          @apply text-grey-400 text-sm font-medium;
        }
      }

      .role-icon {
        @apply absolute left-0 top-0 p-2.5;

        svg {
          @apply w-5 inline mb-0.5;
        }
        .crown {
          @apply fill-ra-yellow;
        }
        .diamond {
          @apply fill-grey-400;
        }
      }

      &.empty {
        @apply bg-grey-950;
        @apply border-grey-950;
        @apply pointer-events-none;
      }

      &:hover {
        .background {
          @apply bg-grey-850/80;
        }
      }
    }
  }

  .bottom {
    .title {
      @apply text-base font-normal text-grey-50 mb-1;
    }
    .subtitle {
      @apply text-base font-normal;
      @apply text-grey-400;
    }
  }
}
</style>