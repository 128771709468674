import { useDataStore } from "@/stores/data.store";
import { SoundType } from "@/stores/sound.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export type ReportsHighlight = {
  minimal: number;
  hideOthers: boolean;
}
export type ReportsFilters = {
  minimalToShow: number,
  minimalToHighlight: number,
  hide_offline: boolean,
  unique_initiators: boolean;

  sort: 'date' | 'count'
}
export type ReportsSounds = {
  soundType: SoundType;
  muteLowAmount: boolean;
}

export const useReportsStore = defineStore('reports', () => {
  const _data = useDataStore();

  const filters = _data.sync('report_filters', ref<ReportsFilters>({
    minimalToShow     : 1,
    minimalToHighlight: 5,
    hide_offline      : false,
    unique_initiators : false,
    
    sort: 'date'
  }), true);

  const sounds = _data.sync('report_sounds', ref<ReportsSounds>({
    soundType    : 'Ping',
    muteLowAmount: true
  }), true);

  return { 
    sounds,
    filters
  };
});