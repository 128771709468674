<script lang="ts">
  export type BanSyncAvailableProps = {
    public: boolean;
  }

  export const ActivateBanSyncAvailableModal = (props: BanSyncAvailableProps) => {
    const _modal = useModalsStore();

    _modal.open('ban-sync-available', props);
  };
</script>

<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import { BanSyncAvailableSubscriptionsDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { computed, onMounted, ref } from 'vue';
  import { useBanStore } from '@court/stores/ban.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { firstBy } from 'thenby';
  import { ActivateBanSyncEditModal } from '../ban-sync-edit/BanSyncEditModal.vue';
  import ProjectListItem from '@panel/components/project/ProjectListItem.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import Divider from '@/components/Divider.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';
  import BaseTip from '@panel/components/BaseTip.vue';

  const props = defineProps<BanSyncAvailableProps & ModalInjectedProps>();

  const { t } = useLocaleStore();
  const _adaptive = useAdaptiveStore();
  const _panel = usePanelStore();
  const _ban = useBanStore();

  const available = ref<BanSyncAvailableSubscriptionsDto[]>();

  onMounted(async () => {
    const result = await useCourtApi().ban.getSubscriptionsAvailable();

    available.value = [...result.data];
  });

  const ordered = computed(() => {
    if (!available.value) {
      return null;
    }

    return [...available.value]
      .filter(v => v.project_original.avatar != useConfigStore().Avatars.LibraryProject[0])
      .sort(
        firstBy((a: BanSyncAvailableSubscriptionsDto, b: BanSyncAvailableSubscriptionsDto) => +b.project_original.verified - +a.project_original.verified)
          .thenBy((a, b) => +b.followers - +a.followers)
      );
  });

  const subscribe = async (ban_sync_available: BanSyncAvailableSubscriptionsDto) => {
    if (!props.public) {
      
      await ActivateConfirmModal({
        type       : 'warn',
        title      : t('ban-sync-list-confirm-no-share.title'),
        description: t('ban-sync-list-confirm-no-share.desc')
      });
      return;
    }

    ActivateBanSyncEditModal(ban_sync_available);
  };
</script>

<template>
  <div class="modal">
    <DividedCard :is-loading="!available" no-content-padding>
      <template #header>
        <div class="header">
          <span class="title">{{ t('ban-sync-modal-edit-title') }}</span>
          <Svg.cross_large class="close" @click="injected.close" />
        </div>
      </template>

      <template #default>
        <div class="projects">
          <template v-if="available != undefined">
            <BaseTip
              type="hint"
              disposable="public-banlists"
            >
              {{ t('basetip-public-banlists') }}
            </BaseTip>
            <template v-for="value in ordered" :key="value.project_id_original">
              <div class="project">
                <div class="top">
                  <ProjectListItem :project="value.project_original" :subtext="t('ban-sync-edit-modal-item-subtext', value.followers)" />
                  <div class="right">
                    <template v-if="value.project_id_original === _panel.project?.id">
                      <Button
                        :preset="'default'"
                        :text="t('general.itsyou')"
                        disabled
                        class="!py-1.5 !px-2 !text-sm"
                      />
                    </template>
                    <template v-else>
                      <Button
                        :preset="!_ban.subscription?.some(v => v.project_id_original === value.project_id_original) ? 'primary' : 'default'"
                        :text="!_ban.subscription?.some(v => v.project_id_original === value.project_id_original) ? t('general.subscribe') : t('general.subscribed')"
                        class="!py-1.5 !px-2 !text-sm"
                        :disabled="_ban.subscription?.some(v => v.project_id_original === value.project_id_original)"
                        @click="subscribe(value)"
                      />
                    </template>
                  </div>
                </div>
                <template v-if="!_adaptive.isMobile">
                  <Divider />
                  <div class="lower">
                    <div>
                      <p class="text">{{ t('ban-sync-modal-value-bans') }}</p>
                      <span class="value">{{ value.total_bans.toLocaleString('de-DE') }}</span>
                    </div>
                    <div>
                      <p class="text">{{ t('ban-sync-modal-value-pirate') }}</p>
                      <span class="value">{{ parseFloat((value.no_license_percent * 100).toFixed(1)) + '%' }}</span>
                    </div>
                    <div>
                      <p class="text">{{ t('ban-sync-modal-value-details') }}</p>
                      <span class="value">{{ value.share_details == true ? t('general.open') : t('general.hidden') }}</span>
                    </div>
                    <div>
                      <p class="text">{{ t('ban-sync-modal-value-staff') }}</p>
                      <span class="value">{{ value.share_moders == true ? t('general.open') : t('general.hidden') }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  width: 100vw;
  max-width: min(540px, 95vw);

  height: 100vh;
  max-height: min(600px, 95vh);

  :deep(.content) {
    max-height: 100%;
  }

  .header {
    @apply flex justify-between items-center w-full;

    .title {
      @apply text-grey-50;
      @apply font-semibold;
      @apply block;
    }

    .close {
      @apply w-5;
      @apply fill-grey-400;
      @apply cursor-pointer;

      &:hover {
        @apply fill-grey-200;
      }
    }
  }

  .projects {
    @apply grid grid-cols-1 p-5 gap-5;

    .project {
      @apply flex flex-col gap-3 p-3;
      @apply bg-grey-1000 rounded-md;

      .top {
        @apply flex items-center justify-between;

        .right {
          @apply flex gap-2 mr-0.5;

          svg {
            @apply w-5 fill-grey-600;
            @apply cursor-pointer;

            &:hover {
              @apply fill-grey-400;
            }
          }
        }
      }
      .lower {
        @apply flex justify-between;

        .text {
          @apply text-xs text-grey-500;
        }
        .value {
          @apply text-sm text-grey-300;
        }
      }
    }
  }
}
</style>