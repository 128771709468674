<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import { CourtFeatureName, useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ref } from 'vue';
  import Button from '@/components/Button.vue';

  const { t } = useLocaleStore();

  const props = defineProps<{ type: CourtFeatureName; color: string; }>();

  const _subscription = useCourtSubscriptionStore();

  const isLoading = computed(() => !_subscription.state);
  const needSubscription = computed(() => _subscription.needSubscription(props.type) as ('starter' | 'basic' | 'pro' | 'enterprise' | undefined));

  const hide = ref<boolean>(false);
</script>

<template>
  <div class="no-plan-content" :class="{ 'px-1 overflow-y-hidden': needSubscription && !hide }">
    <template v-if="isLoading">
      <Loading :can-view="false" />
    </template>
    <template v-else>
      <slot />
      <template v-if="needSubscription && !hide">
        <div
          class="root-cont"
          :class="[color]"
        >
          <div class="icon" :class="needSubscription">
            <Svg.lightning />
          </div>
        
          <div class="text">
            <p class="title">
              {{ t('court.no-sub.title') }}
            </p>
            <p class="subtitle">
              {{ t('court.no-sub.subtitle', {plan: needSubscription}) }}
            </p>
          </div>

          <Button
            preset="default-light"
            class="mt-2 w-fit"
            @click="hide = true"
          >
            {{ t('court.no-sub.btn') }}
          </Button>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.root-cont {
  @apply absolute inset-0 h-full w-full flex justify-center items-center;
  @apply backdrop-blur-sm;
  -webkit-backdrop-filter: blur(4px);
}

.no-plan-content {
  @apply h-full;

  .root-cont {
    @apply flex flex-col gap-3 items-center;
    z-index: 600;

    .icon {
      @apply p-2 rounded-lg;
      
      &.starter {
        @apply bg-gradient-to-r from-[#56CCF2]/15 to-[#2F80ED]/15;
        @apply fill-[#43A6F0];
      }
      &.basic {
        @apply bg-gradient-to-r from-[#D4FC79]/15 to-[#96E6A1]/15;
        @apply fill-[#B4F18E];
      }
      &.pro {
        @apply bg-gradient-to-r from-[#FFE259]/15 to-[#FFA751]/15;
        @apply fill-[#FFC555];
      }

      svg {
        @apply w-6;
      }
    }

    .text {
      @apply flex flex-col gap-1 text-center;

      .title {
        @apply text-grey-100 font-semibold;
      }
      .subtitle {
        @apply text-grey-300 text-sm;
        max-width: 300px;
      }
    }
  }

}
</style>