<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { PlayerFullDto, PlayerKillFeedHitEntry, PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type CombatlogModalProps = {
    target_steam_id: string;
    connected_steam_ids: string[]
    hit_history: PlayerKillFeedHitEntry[];
  }

  export const ActivateCombatlogModal = async (props: CombatlogModalProps) => {
    const _modal = useModalsStore();

    _modal.open('combatlog', props);
  };
</script>

<script setup lang="ts">
  import { onMounted, computed, ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  
  import DividedCard from '@/components/DividedCard.vue';
  import Divider from '@/components/Divider.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const _adaptive = useAdaptiveStore();
  const { t } = useLocaleStore();

  const props = defineProps<CombatlogModalProps & ModalInjectedProps>();

  const keys = Object.keys(<PlayerKillFeedHitEntry>{}) as (keyof PlayerKillFeedHitEntry)[];

  type TableInfo = {
    headerName: string;
    headerClass: string;
    rowData: (v: PlayerKillFeedHitEntry) => string;
    rowClasses?: (v: PlayerKillFeedHitEntry) => string; 
  }

  const tableInfo = ref<{
    [K in keyof PlayerKillFeedHitEntry]: TableInfo
  }>({
    time: {
      headerName : 'Time',
      headerClass: 'max-w-[60px]',
      rowData    : (v) => v.time.toFixed(2)
    },
    attacker: {
      headerName : 'Attacker',
      headerClass: 'min-w-[100px] max-w-[100px]',
      rowData    : (v) => {
        const id = v.attacker === 'you' ? props.target_steam_id : v.attacker_steam_id;

        return id ? players.value?.find(p => p.steam_id === id)?.steam_name ?? 'Unknown' : v.attacker;
      },
      rowClasses: (v) => v.attacker === 'you' ? '!text-primary-300' : ''
    },
    target: {
      headerName : 'Target',
      headerClass: 'min-w-[100px] max-w-[100px]',
      rowData    : (v) => {
        const id = v.target === 'you' ? props.target_steam_id : v.target_steam_id;

        return id ? players.value?.find(p => p.steam_id === id)?.steam_name ?? 'Unknown' : v.target;
      },
      rowClasses: (v) => v.target === 'you' ? '!text-primary-300' : ''
    },
    weapon: {
      headerName : 'Weapon',
      headerClass: 'max-w-[100px]',
      rowData    : (v) => v.weapon.includes('.entity.prefab')
        ? v.weapon.match(/\/([^\/]+)\.(entity|weapon)\.prefab$/)?.[1] || v.weapon
        : v.weapon
    },
    ammo: {
      headerName : 'Ammo',
      headerClass: 'max-w-[100px]',
      rowData    : (v) => v.ammo ?? ''
    },
    bone: {
      headerName : 'Bone',
      headerClass: 'max-w-[80px]',
      rowData    : (v) => v.bone,
      rowClasses : (v) => v.bone === 'head' ? 'warning' : ''
    },
    distance: {
      headerName : 'Distance',
      headerClass: 'max-w-[70px]',
      rowData    : (v) => v.distance.toFixed(2),
      rowClasses : (v) => v.distance > 100 ? 'warning' : ''
    },
    hp_old: {
      headerName : 'Old_hp',
      headerClass: 'min-w-[70px] max-w-[70px]',
      rowData    : (v) => v.hp_old.toFixed(2)
    },
    hp_new: {
      headerName : 'New_hp',
      headerClass: 'min-w-[70px] max-w-[70px]',
      rowData    : (v) => v.hp_new.toFixed(2)
    },
    info: {
      headerName : 'Info',
      headerClass: 'max-w-[80px]',
      rowData    : (v) => v.info ?? ''
    }
  });

  const players = ref<PlayerFullDto[]>();

  onMounted(async () => {
    if (!props.connected_steam_ids.length) {
      players.value = [];
      return;
    }

    const arr = Array.from(new Set([...props.connected_steam_ids, props.target_steam_id]));

    const data = await useCourtApi().player.browseFull({ steam_ids: arr, page: 0, limit: 100, offline: true });
  
    players.value = data.results;
  });

  const perRow = computed(() => {
    if (_adaptive.isMobile) {
      return 2;
    }
    return 4;
  });

  const amountForFullRow = computed(() => {
    const totalItems = players.value?.length ?? props.connected_steam_ids.length;
    const remainder = totalItems % perRow.value;
    return remainder ? (perRow.value - remainder) : 0;
  });
</script>

<template>
  <div class="combatlog-modal">
    <DividedCard>
      <template #header>
        <div class="header">
          <span class="title">{{ t('combatlog-modal-header-title') }}</span>
          <Svg.cross_large class="close" @click="props.injected.close()" />
        </div>
      </template>
      <template #default>
        <div class="table-container">
          <table class="table">
            <thead>
              <tr>
                <th
                  v-for="(column, key) in tableInfo"
                  :key="key"
                  class="table-header"
                  :class="column?.headerClass"
                >
                  {{ column?.headerName }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(hit, index) in hit_history" :key="index">
                <td
                  v-for="column in tableInfo"
                  :key="`${index}-${column}`"
                  class="row"
                  :class="[column?.headerClass, column?.rowClasses?.(hit)]"
                  :title="column?.rowData(hit)"
                >
                  {{ column?.rowData(hit) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <template v-if="props.connected_steam_ids.length != 0">
          <Divider
            :text="t('combatlog-modal-connected-steam-ids')"
            dashed
            class="my-5"
          />

          <div class="players">
            <div
              v-for="value in players ?? props.connected_steam_ids"
              :key="value.toString()"
            >
              <div class="player">
                <PlayerBox
                  class="w-full"
                  :player="typeof value ==='string' ? null : value"
                />
              </div>
            </div>
            <template v-for="(_) in amountForFullRow" :key="_">
              <div class="player empty bg-dot-pattern" />
            </template>
          </div>
        </template>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.combatlog-modal {
  width: 95vw;
  max-width: 900px;

  :deep(.content) {
    max-height: 610px;
  }
}

.header {
  @apply flex justify-between items-center w-full;

  .title {
    @apply text-grey-50;
    @apply font-semibold;
    @apply block;
  }

  .close {
    @apply w-5;
    @apply fill-grey-400;
    @apply cursor-pointer;

    &:hover {
      @apply fill-grey-200;
    }
  }
}

.table-container {
  @apply overflow-auto;
  @apply bg-grey-850;
  @apply rounded-md;

  .table {
    @apply w-full overflow-hidden;
    @apply border-collapse border;
    @apply rounded-md;

    .table-header {
      @apply border border-grey-750 p-2;
      @apply font-normal text-sm text-grey-400 text-left;
      @apply truncate;
    }

    .row {
      @apply border border-grey-750 p-2;
      @apply font-normal text-sm text-grey-50;
      @apply truncate;

      &.warning {
        @apply text-amber-400;
      }
    }
  }
}

.players {
  @apply grid grid-cols-4 gap-2.5;

  .player {
    @apply flex items-center justify-start p-2;
    @apply w-full;
    @apply rounded-md;
    @apply bg-grey-850;
    @apply text-grey-500;

    &.empty {
      @apply bg-grey-850/25;
    }
  }
}

[device="mobile"] {
  .players {
    @apply grid-cols-2;
  }
}
</style>