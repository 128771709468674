<script lang="ts"> 
  export type ChartSeries = { 
    name: string; 
 
    color: string; 
    customStrokeColor?: string; 
 
    dashValue?: number; 
    fillGradientOpacity?: number; 
 
    defaultHidden?: boolean; 
 
    data: ChartSeriesData[] 
  }  
 
  export type ChartSeriesData = { 
    x: number | null; // date timestamp 
    y: number; // value 
  } 
</script> 
 
<script lang="ts" setup> 
  import Loading from '@/components/Loading.vue'; 
  import { ApexOptions } from 'apexcharts'; 
  import { ref } from 'vue'; 
  import { computed } from 'vue'; 
  import CheckboxInput from '../inputs/CheckboxInput.vue';
  import { useLocaleStore } from "@/stores/locale.store";
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  
  const { t } = useLocaleStore();
 
  const props = defineProps<{ 
    title: string; 
 
    series: ChartSeries[] | null; 
    options?: ApexOptions; 
 
    sharedGroup?: string; 
  }>(); 
 
  const chartId = ref(Math.random().toString()); 
  const useCumulativeChart = ref<boolean>(false); 
 
  const computedSeries = computed(() => { 
    if (!props.series) { 
      return null; 
    } 
 
    if (useCumulativeChart.value) { 
      return props.series.map(s => { 
        return { 
          ...s, 
 
          data: s.data.reduce<ChartSeriesData[]>((acc, v) => { 
            const prev = acc[acc.length - 1]; 
 
 
            acc.push({ 
              x: v.x, 
              y: (prev?.y ?? 0) + (v?.y ?? 0) 
            }); 
 
            return acc; 
          }, []) 
        }; 
      }); 
    } 
 
    return props.series; 
  }); 
 
  const computedOptions = computed(() => { 
    if (!computedSeries.value) { 
      return; 
    } 
 
    const defaultOptions: ApexOptions = { 
      chart: { 
        id     : chartId.value, 
        group  : props.sharedGroup, 
        toolbar: { 
          show: false, 
        }, 
        type: 'area', 
        zoom: { 
          enabled: true 
        }, 
        dropShadow: { 
          enabled: false, 
          blur   : 12 
        }, 
        foreColor: "#ccc", 
      }, 

      markers: { 
        size        : 0, 
        colors      : ["#1E1E1E"], 
        strokeColors: computedSeries.value.map(v => v.customStrokeColor ?? v.color), 
        strokeWidth : 2 
      }, 

      noData: { 
        text : 'No Data', 
        style: { 
          color     : '#fff', 
          fontSize  : '14px', 
          fontFamily: 'inherit' 
        } 
      }, 

      stroke: { 
        width    : 3, 
        curve    : 'straight', 
        dashArray: computedSeries.value.map(v => v.dashValue ?? 0) 
      }, 

      grid: { 
        show           : true, 
        borderColor    : '#272727', 
        strokeDashArray: 4, 
        xaxis          : { 
          lines: { 
            show: false, 
          }, 
 
        }, 
        yaxis: { 
          lines: { 
            show: true 
          } 
        }, 
        padding: { 
          top   : 0, 
          right : 0, 
          bottom: 0, 
          left  : 0 
        },  
      }, 

      xaxis: { 
        type  : 'datetime', 
        labels: { 
          show : true, 
          style: { 
            cssClass: 'apexcharts-xaxis-label', 
          },
        }, 
 
        axisBorder: { 
          show : false, 
          color: '#2D2D2D', 
        }, 
        axisTicks: { 
          show : false, 
          color: '#2D2D2D', 
        }, 
        tooltip: { 
          enabled: false, 
        }, 
        crosshairs: { 
          show  : true, 
          width : 1,      
          stroke: {
            color    : '#8D8D8D', 
            width    : 1, 
            dashArray: 4, 
          } 
        }, 
      }, 

      yaxis: { 
        show    : true, 
        floating: true, 
        labels  : { 
          show   : true, 
          align  : 'left', 
          offsetY: -10, 
          offsetX: 4, 
          style  : { 
            cssClass: 'apexcharts-yaxis-label', 
          },
          formatter: function(value) {
            return Number(value).toFixed(0);
          }
        }, 
      }, 

      dataLabels: { 
        enabled: false 
      }, 

      tooltip: { 
        enabled: true, 
        theme  : 'false', 
        x      : { 
          show  : true, 
          format: 'dd MMMM', 
        },
        style: {
          fontFamily: 'Inter',
        },
      }, 

      legend: { 
        onItemHover: { 
          highlightDataSeries: true, 
        }, 
         
        show           : true, 
        position       : 'top', 
        horizontalAlign: 'center',
        fontFamily     : 'Inter',
        floating       : true,
        offsetY        : 6,
        markers        : { 
          width : 10, 
          height: 10, 
          radius: 2, 
        } as any, 
      }, 
      
      fill: { 
        type    : 'gradient', 
        gradient: { 
          opacityFrom: computedSeries.value.map(v => v.fillGradientOpacity ?? 0), 
          opacityTo  : 0, 
        }, 
 
      } 
    }; 
 
    return Object.assign(defaultOptions, props.options); 
  }); 
 
</script> 
 
<template> 
  <div class="item relative"> 
    <Loading :can-view="!!(computedOptions && computedSeries)" class="!bg-grey-900" /> 
 
    <div class="header"> 
      <div class="flex justify-between items-center"> 
        {{ title }} 
 
        <div class="flex gap-1 items-center">
          <CheckboxInput 
            v-model="useCumulativeChart" 
            :text="t('moderator-stats-chart-general')" 
          />
          <TooltipBase trigger="hover">
            <div>
              <Svg.circle_info class="w-4 fill-grey-400" />
            </div>

            <template #content>
              <div class="px-1.5 py-1 text-xs text-center" style="max-width: 200px;">
                <p>{{ t('moderator-stats-chart-general-tooltip') }}</p>
              </div>
            </template>
          </TooltipBase>
        </div>
      </div> 
    </div> 
    <div class="chart"> 
      <template v-if="computedOptions && computedSeries"> 
        <apexchart 
          width="100%" 
          height="100%"  
          class="h-full w-full" 
          :options="computedOptions" 
          :series="computedSeries" 
        /> 
      </template> 
    </div> 
  </div> 
</template> 
 
 
<style  lang="scss" scoped> 
 
:deep(.apexcharts-canvas), :deep(.apexcharts-yaxis) { 
  .apexcharts-tooltip { 
    @apply bg-grey-800 text-grey-200;
    box-shadow: rgba(20, 20, 20, 0.8) 0px 5px 20px;
  }
 
  .apexcharts-yaxis-label { 
    @apply fill-grey-700; 
  } 
  .apexcharts-xaxis-label { 
    @apply fill-grey-700; 
  } 
  .apexcharts-xaxis-label, .apexcharts-yaxis-label { 
    @apply font-medium; 
  } 
 
  .apexcharts-tooltip-title { 
    @apply bg-grey-700 text-grey-50; 
    @apply font-semibold; 
    margin-bottom: 6px; 
  } 
  .apexcharts-tooltip-series-group { 
    @apply text-grey-400; 
    padding: 0px 6px 6px 6px; 
  } 
  .apexcharts-tooltip-marker { 
    margin-right: 6px; 
    width: 8px; 
    height: 8px; 
    border-radius: 2px; 
  } 
  .apexcharts-tooltip-text-y-value { 
    @apply ml-0; 
    @apply text-grey-200; 
  } 
  .apexcharts-tooltip-y-group { 
    @apply p-0; 
  } 
 
  .apexcharts-legend-series { 
    margin: 0px !important; 
  } 
  .apexcharts-legend { 
    @apply flex gap-2; 
  } 
  .apexcharts-legend-text { 
    @apply text-grey-400 #{!important}; 
  } 
  .apexcharts-legend-marker { 
    margin-right: 5px; 
  } 
} 
 
.item { 
  @apply flex flex-col relative; 
  @apply rounded-md; 
  @apply bg-grey-950 border border-grey-900; 
 
  .chart { 
    @apply grow; 
  } 
 
  .header { 
    @apply p-2.5; 
    @apply shrink-0; 
    @apply bg-grey-900 text-grey-50 font-medium;
    @apply rounded-t-md;
  } 
} 
 
</style>