<script setup lang='ts'>
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';

  const { t } = useLocaleStore();

  defineProps<{ 
    meta: { 
      reason?: string
    }, 
    reversed: boolean,
    created: number,
  }>();
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :title="t('check.activity.disconnected.title')"
    :subtitle="`${t('check.activity.disconnected.reason')} ${meta.reason}`"
    :created="created"
    circle-class="bg-grey-1000 !border-red-500"
  />
</template>

<style lang='scss' scoped>

</style>