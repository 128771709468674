<script setup lang="ts">
  import Highlight from '@/components/Highlight.vue';
  import { PlayerStatusDto, PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { computed } from 'vue';
  import { useLocaleStore } from '@src/stores/locale.store';
  import { usePlayerStatusStore } from '@court/stores/player-status.store';
  import { useTimeStore } from '@/stores/time.store';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { usePlayerMenu } from '@court/menus/player.menu';
  import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _config = useConfigStore();
  const _playerMenu = usePlayerMenu();

  const props = withDefaults(
    defineProps<{
      player: PlayerFullDto | null;
      subtext?: string;
      
      size?: 'default' | 'large' | 'small' | 'm-small' | 'x-small';
      vertical?: boolean;

      hideText?: boolean;

      // Будет подсвечивать найденное
      search?: string[];

      disableHover?: boolean;
      // Профиль не будет открываться по клику на игрока
      disableClick?: boolean;
      // Заставит грузить не полную версию аватарки
      useJackalAvatar?: boolean;

      customClick?: (e: MouseEvent) => unknown;
    }>(),
    {
      size   : 'default',
      subtext: undefined,
    },
  );


  const computedPlayer = computed(() => {
    if (!props.player) {
      return null;
    }

    return _playerStatus.getPlayerWithStatus(props.player);
  });

  const _playerStatus = usePlayerStatusStore();


  const computedSubText = computed(() => {
    if (props.subtext) {
      return props.subtext;
    }

    const player = computedPlayer.value;
    if (!player) {
      return '';
    }

    return _playerStatus.getDefaultStatusText(player);
  });

  const computedAvatar = computed(() => {
    if (!computedPlayer.value?.steam_avatar) {
      return undefined;
    }

    const url = computedPlayer.value.steam_avatar;
    if (!props.useJackalAvatar) {
      return url;
    }

    return url.replace('_full', '');
  });

  const onClick = (e: MouseEvent) => {
    if (props.customClick) {
      props.customClick(e);
      return;
    }

    if (!computedPlayer.value || props.disableClick) {
      return;
    }

    if (!computedPlayer.value.server_id) {
      if (computedPlayer.value.steam_avatar != _config.Avatars.PlayerNotExistsPlaceholder) {
        ActivateConfirmModal({
          type       : 'warn',
          title      : t('modal.player.unknown-alian-title'),
          description: t('modal.player.unknown-alian-subtitle'),
        });
        return;
      }

      ActivateConfirmModal({
        type       : 'warn',
        title      : t('modal.player.unknown-title'),
        description: t('modal.player.unknown-subtitle'),
      });
      return;
    }

    ActivatePlayerDetailsModal(computedPlayer.value.steam_id, computedPlayer.value);
  };
</script>

<template>
  <template v-if="!computedPlayer">
    <ListItem skeleton="avatar-status" :message="computedSubText" />
  </template>
  <template v-else> 
    <div
      class="player-box"
      @contextmenu="(e) => player ? _playerMenu.open(e, player) : undefined"
      @click.stop.prevent="onClick"
    >
      <a :href="`?player=${computedPlayer.steam_id}`">
        <ListItem
          class="player-box"
          skeleton="avatar"
          :vertical="vertical"
          :size="size"
          :hide-text="hideText"
          :class="disableHover ? '' : 'hover'"
          :image-url="computedAvatar"
          :image-status-color="_playerStatus.getDefaultStatusColors(computedPlayer.status)"
        >
          <template v-if="!hideText" #title>
            <Highlight
              :visible-text="computedPlayer.steam_name ?? ''"
              :search="search"
            />
          </template>
          <template v-if="!hideText" #message>
            <Highlight
              :visible-text="computedSubText ?? ''"
              :search="search"
            />
          </template>
        </ListItem>
      </a>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.hover {
  @apply transition-all ease-in-out delay-0 duration-100;
  @apply cursor-pointer select-none;
  &:hover {
    @apply opacity-80;
  }
}
</style>