<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import { AuditDict, AuditType } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { useAuditComposable } from './../audit.composable';
  import type { Component } from 'vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';

  const { t } = useLocaleStore();

  const _panel = usePanelStore();
  const _auditComposable = useAuditComposable();
  const _permissions = usePermissionsStore();

  const choosedClientId = ref<number | null>(null);
  const choosedType = ref<AuditType | null>(null);

  const types = computed(() => {
    const values = Object.keys(AuditDict).map(v => {
      if (v === 'SignageDelete' && !_permissions.can(['Court_SignageControl'])) {
        return undefined;
      }
      if (v === 'BanEdit' && !_permissions.can(['Court_PlayerBanRead'])) {
        return undefined;
      }

      return {
        value: v,
        text : _auditComposable.getNameOf(v as AuditType),
        icon : _auditComposable.getIconOf(v as AuditType) as Component,
      };
    }).filter(v => v) as SelectValue<string>[]; 


    return [
      {
        value: null,
        text : t('audit-sidebar-all'),
        icon : Svg.document
      },
      ...values
    ];
  });

  const clients = computed(() => {
    const values = _panel.projectClients.map(v => {
      return {
        value: v.client.id,
        text : v.client.name,
        icon : v.client.avatar,
      };
    }); 
    return [
      {
        value: null,
        text : t('audit-sidebar-all-clients'),
        icon : Svg.user
      },
      ...values
    ];
  });

  defineExpose({ choosedClientId, choosedType });
</script>

<template>
  <div class="btn-group">
    <SelectInput
      v-model="choosedClientId"
      class="select-mobile"
      variant="tonal"
      :values="clients"
      hide-arrow
      right
    />
    <SelectInput
      v-model="choosedType"
      class="select-mobile"
      variant="tonal"
      :values="types"
      hide-arrow
      right
    />
  </div> 
</template>

<style lang='scss' scoped>
:deep(.select-mobile) {
  @media (max-width: 700px) {
    .activator {
      span {
        display: none;
      }
    }
  }
}


.btn-group {
  @apply flex items-center gap-2.5;

  .btn {
    @apply flex items-center gap-1;
    @apply p-1.5;
    @apply bg-grey-800;
    @apply rounded-md;
    @apply cursor-pointer;
    @apply text-grey-400 font-medium;

    svg {
      @apply w-5 h-5;
      @apply fill-grey-400;
    }

    &:hover {
      @apply bg-grey-750 text-grey-200;

      svg {
        @apply fill-grey-200;
      }
    }
  }
}

@media (max-width: 700px) {
  span {
    display: none !important;
  }
}

.container {
  @apply bg-grey-900 rounded-md;
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-y-auto;
}
.item {
  @apply flex;
  @apply gap-1;
  @apply bg-white/5;
  @apply rounded-md;
  @apply items-center;
  @apply p-1;
  @apply cursor-pointer;
  @apply text-sm;
  @apply text-grey-400;
}
</style>