<script setup lang="ts"> 
  import { useLocaleStore } from '@/stores/locale.store'; 
  import { computed, ref, watch } from 'vue'; 
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto'; 
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue'; 
  import PlayerNavigationBansVariant from './PlayerNavigationBansVariant.vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useBanStore } from '@court/stores/ban.store';
 
  const emits = defineEmits<ModalMenuExtendedEvents>(); 
  const _ban = useBanStore();
  const props = defineProps<{ steamId: string }>(); 
 
  const { t } = useLocaleStore(); 
 
  const lastCount = ref<number>(); 
  const badgeUpdate = (count: number) => { 
    emits('badgeUpdate', count); 
     
    lastCount.value = count; 
  }; 
 
  const allVariant = ref<InstanceType<typeof PlayerNavigationBansVariant>>();

  const lastActiveBan = ref<BanDto>();

  watch(() => allVariant.value?.activeBan, (v) => {
    if (!v) {
      return;
    }
    
    lastActiveBan.value = v;
  });

  defineExpose({ activeBan: lastActiveBan });
</script> 
 
<template> 
  <ModalMenuExtendedPageLayout> 
    <template #[t(`bans-filter-all`)]>
      <PlayerNavigationBansVariant
        ref="allVariant"
        :steam-id="steamId"
        variant="all"
        @badge-update="count => badgeUpdate(count)"
      />
    </template> 
    <template #[t(`bans-filter-own`)]>
      <PlayerNavigationBansVariant
        :steam-id="steamId"
        variant="own"
        @badge-update="count => badgeUpdate(count)"
      />
    </template> 
    <template #[t(`bans-filter-other`)]>
      <PlayerNavigationBansVariant
        :steam-id="steamId"
        variant="other"
        @badge-update="count => badgeUpdate(count)"
      />
    </template>
  </ModalMenuExtendedPageLayout> 
</template> 
 
<style lang='scss' scoped> 
.staff { 
  @apply cursor-pointer; 
   
  :deep(.nickname) { 
    @apply leading-tight; 
    @apply text-primary-700 font-semibold; 
 
    &:hover { 
      @apply text-primary-500; 
    } 
  } 
} 
</style> 
