<script setup lang="ts">
  import ImagesGrid from '@/components/ImagesGrid.vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';

  const props = defineProps<{
    ban: BanDto
  }>();

  const { t } = useLocaleStore();

  const isHidden = computed(() => {
    return props.ban.proofs.find(v => v === 'x-factor-hidden');
  });
</script>

<template>
  <p class="title !mb-2.5">{{ t('modal.ban-view.proofs') }}</p>
  <template v-if="!isHidden">
    <ImagesGrid
      :images="ban.proofs"
      :max="4"
      :per-row="4"
    />
  </template>
  <template v-else>
    <p class="ban-view-hidden">
      {{ t('modal-ban-view-proofs-hidden') }}
    </p>
  </template>
</template>

<style lang="scss" scoped>
.title {
  @apply text-grey-50;
  @apply font-medium pt-5;
}
.proof-list {
  @apply flex gap-1.5;
  .proof {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    @apply cursor-pointer;
    @apply transition-all ease-in-out delay-0 duration-100;

    img {
      @apply bg-grey-800;
      @apply w-full h-full;
      object-fit: cover;
      border-radius: 6px;
    }

    &:hover {
      @apply scale-95;
    }
  }

  .last-proof {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    @apply relative;
    cursor: pointer;
    img {
      @apply bg-grey-800;
      @apply w-full h-full;
      object-fit: cover;
      opacity: 0.5;
      border-radius: 6px;
      @apply transition-all ease-in-out delay-0 duration-100;
    }
    .blur {
      @apply absolute inset-0;
      backdrop-filter: blur(2px);
      @apply z-10;
      border-radius: 6px;
    }
    .more {
      @apply z-20;
      @apply absolute top-1/2 left-1/2;
      @apply -translate-x-1/2 -translate-y-1/2;
      @apply text-grey-50;
    }

    &:hover {
      img {
        @apply opacity-60;
      }
    }
  }
}
</style>