import { ProjectPublicDto } from "@/api/backend/core/project/project.dto";
import { PlayerIpDto } from "../player/player.dto";

export type PlayerAlertBrowseOpts = {
  type?: string[];
  categories?: string[];
  hide_resolved?: boolean;
  steam_id?: string;
}

export const PlayerAlertDict = {
  join_with_ip_ban      : 'join_with_ip_ban',
  join_with_external_ban: 'join_with_external_ban',
  dug_up_stash          : 'dug_up_stash',
  detect_ra_ban         : 'detect_ra_ban',
  custom_api            : 'custom_api',
  vpn_detected          : 'vpn_detected',
  license_changed       : 'license_changed'
};

export type PlayerAlertType = keyof (typeof PlayerAlertDict);

export type PlayerAlertDto = {
  id: number;

  project_id: number;
  server_id?: number;

  type: PlayerAlertType;
  meta: object;

  hide_in_table: boolean;
  created_at: number;
  resolve_at?: number;
}

export type PlayerAlertJoinWithIpBanMetaDto = {
  steam_id: string;
  ip: string;
  ban_id: number;
}

export type PlayerAlertJoinWithExternalBanMetaDto = {
  steam_id: string;

  bans: object[];
}

export type PlayerAlertDetectRaBanMetaDto = {
  steam_id: string;

  bans: PlayerAlertDetectRaBanEntry[];
}

export type PlayerAlertDetectRaBanEntry = {
  project_id: number;
  project?: ProjectPublicDto;

  ban_id: number;
  ban_reason: string;

  auto_kicked: boolean;
}

export type PlayerAlertDugUpStashMetaDto = {
  steam_id: string;
  owner_steam_id: string;
  position: string;
  square: string;
}

export type PlayerAlertCustomApiMetaDto = {
  msg: string;
  category?: string;
  custom_icon?: string;
  steam_ids?: string[];
  data?: Record<string, unknown>
}

export type PlayerAlertVpnDetectedMetaDto = {
  steam_id: string;

  ip: string;

  ip_details: PlayerIpDto;
}

export type PlayerAlertLicenseChangedMetaDto = {
  steam_id: string;

  last_license_joined_at: number;
}