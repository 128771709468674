<script setup lang='ts'>
  import TextInput from '@/components/inputs/TextInput.vue';
  import CheckboxInput from '@/components/quality/inputs/CheckboxInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import UploaderArea from '@/components/quality/uploader/UploaderArea.vue';
  import { watch } from 'vue';
  import { BanDataStorage } from '../stores/ban.storage';

  const { t } = useLocaleStore();

  const storage = defineModel<BanDataStorage>({ required: true });

  watch(() => storage.value.proofs, () => {
    storage.value.updater?.();
  }, { deep: true });
</script>

<template>
  <div class="flex flex-col gap-4">
    <TextInput
      v-model="storage.comment"
      textarea
      no-border
      height="80"
      :label="t('modal.ban.proofs.comment.title')"
      :placeholder="t('modal.ban.proofs.comment.subtitle')"
      class="!align-text-top"
    />
    <UploaderArea
      v-model="storage.proofs"
      :header="t('modal.ban.proofs.files.subtitle')"
      type="ban-proofs"
      :label="t('modal.ban.proofs.files.label')"
      multiple
      @delete="storage.updater?.()"
    />
    <CheckboxInput
      v-if="storage.team.enabled"
      v-model="storage.proofs_global"
      :text="t('modal.ban.proofs.mass')"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>