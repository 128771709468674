import { Axios } from "axios";
import { CoreStats, NewsDto, UploadAvailableType } from "./utils.dto";
import { ClientDto } from "../client/client.dto";

export const useCoreUtilsApi = (api: Axios) => {
  const getNews = async (news_id?: number) => {
    const result = await api.get<NewsDto | null>('/utils/news', {
      params: {news_id}
    });
  
    return result.data;
  };

  const getStats = async () => {
    const result = await api.get<CoreStats>('/utils/stats');
  
    return result.data;
  };

  const getPermissions = async () => {
    const result = await api.get<string[]>(`/utils/permissions`);

    return result.data;
  };

  const canCreateProject = async () => {
    const result = await api.get<boolean>(`/utils/can-project-create`);

    return result.data;
  };

  const getClientById = async (id: number) => {
    const result = await api.get<ClientDto>(`/utils/client-by-id`, { params: { id }});

    return result.data;
  };

  const getClientByTag = async (tag: string) => {
    const result = await api.get<ClientDto>(`/utils/client-by-tag`, { params: { tag }});

    return result.data;
  };

  const upload = async (file: File, type: UploadAvailableType) => {
    const form = new FormData();

    form.append("file", file, file.name);

    const result = await api.post<string>(`/utils/upload`, form, {
      params: { type }
    });

    return result.data;
  };

  return {
    getNews,
    getStats,
    getPermissions,
    canCreateProject,
    getClientById,
    getClientByTag,
    upload,
  };
};