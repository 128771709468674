
import { Axios } from "axios";
import { CourtRccDto } from "./court-integrations.dto";

export const useCourtIntergrationsApi = (api: Axios) => {
  const getRcc = async () => {
    const result = await api.get<CourtRccDto>('/court-integrations/rcc');

    return result.data;
  };

  const updateRcc = async (rcc_api_key: string, rcc_send_ban_updates: boolean, rcc_auto_call_check: boolean) => {

    const result = await api.put('/court-integrations/rcc', { rcc_api_key, rcc_send_ban_updates, rcc_auto_call_check });

    return result.data;
  };

  return {
    getRcc,
    updateRcc
  };
};
