<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useConfirmEnter } from '@src/composable/confirm.composable';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type PlayerKickModalOpts = {
    player: PlayerFullDto
  };

  export const ActivatePlayerKickModal = async (props: PlayerKickModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('player-kick', { player: props.player });
  };
</script>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  
  import DividedCard from '@/components/DividedCard.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  // import SwitchText from '@/components/switch/SwitchText.vue';
  import Button from '@/components/Button.vue';

  const { t } = useLocaleStore();

  const _modal = useModalsStore();

  const props = defineProps<PlayerKickModalOpts & ModalInjectedProps>();

  const info = ref<{ reason: string, announce: boolean }>({
    reason  : '',
    announce: false
  });

  const confirmButton = ref<InstanceType<typeof Button>>();
  useConfirmEnter(() => confirmButton?.value?.submit?.());

  const kick = async () => {
    const result = await useCourtApi().player.kick(props.player.steam_id, info.value.reason, info.value.announce);

    if (result) {
      props.injected.setBeforeClose(async () => {
        await ActivateConfirmModal({
          type       : 'success',
          title      : t('modal.player.kick-success-title'),
          description: t('modal.player.kick-success-subtitle')
        });

        return true;
      });
    } else {
      props.injected.setBeforeClose(async () => {
        await ActivateConfirmModal({
          type       : 'error',
          title      : t('modal.player.kick-error-title'),
          description: t('modal.player.kick-error-subtitle')
        });

        return true;
      });
    }

    await _modal.close();
  };
</script>

<template>
  <div class="player-kick-modal">
    <DividedCard>
      <template #header>
        <div class="header">
          <p>
            <span class="title">{{ t('modal.kick.header.title') }}</span>
            <span class="subtitle">{{ t('modal.kick.header.subtitle', { player: props.player.steam_name }) }}</span>
          </p>
          <div class="append">
            <img class="bg-white/5" :src="props.player.steam_avatar">
          </div>
        </div>
      </template>
      <template #default>
        <div class="inputs">
          <TextInput
            v-model="info.reason"
            :label="t('modal.kick.reason.label')"
            :placeholder="t('modal.kick.reason.placeholder')"
            no-border
            autofocus
          />
          <!--<SwitchText
            v-model="info.announce"
            :header="t('modal.kick.alert.header')"
            :placeholder="t('modal.kick.alert.description')"
          />-->
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-2 w-full">
          <Button
            preset="default"
            :text="t('general.close')"
            :action="() => { 
              props.injected.close();
            }"
          />
          <Button
            ref="confirmButton"
            preset="primary"
            :text="t('general.kick')"
            :disabled="!info.reason"
            :action="kick"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.player-kick-modal {
  width: 95vw;
  max-width: 420px;
}
.header {
  @apply flex items-center justify-between w-full;
  @apply text-grey-50 font-semibold;
  .title {
    @apply text-white;
    @apply font-semibold;
    @apply block;
  }
  .subtitle {
    @apply text-grey-400;
    @apply font-normal;
    @apply text-sm;
    @apply block;
  }
  .append {
    img {
    @apply w-11;
    @apply rounded-half;
    }
  }
}
.inputs {
  @apply grid grid-cols-1 gap-4;
}
</style>