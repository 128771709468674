<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import { ServerFullDto } from '@/api/backend/court/servers/servers.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, ref } from 'vue';
  import SettingsIcon from '@src/assets/icons/settings.svg';
  import { ActivateServerPairModal } from '@court/modals/server-pair/ServerPairModal.vue';
  import { ActivateServerEditModal } from '@court/modals/server-edit/ServerEditModal.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import Skeleton from '@/components/Skeleton.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { usePluginDownload } from '@court/utils/plugin.utils';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { ampli } from '@src/ampli';
  import { GetPluginVariants, usePluginMenu } from '@court/menus/plugin.menu';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<{ 
    server: ServerFullDto | null;
    loaded: boolean;

    onUpdated: () => Promise<void>;
  }>();

  const _context = useContextMenu();
  const _permissions = usePermissionsStore();

  const serverStatus = computed(() => (!props.server?.state?.isStable ? 'offline' : 'online'));
  const isUpdated = computed(() => props.server?.updated ?? true);
  const { t } = useLocaleStore();

  const defaultBannerUrl = 'https://i.imgur.com/7gsgKSP.png';
  const defaultAvatarUrl = 'https://i.imgur.com/PtUV9hj.jpg'; 
  
  const bannerCopy = ref<string | undefined>(defaultBannerUrl);
  const avatarCopy = ref<string | undefined>(defaultAvatarUrl);

  onMounted(() => {
    bannerCopy.value = props.server?.banner_url;
    avatarCopy.value = props.server?.avatar_url;
  });

  const handleClick = () => {
    if (!props.server) {
      return;
    }

    if (!_permissions.can(['Court_ServerManage'])) {
      return;
    }

    if (!isUpdated.value) {
      updateModal();
      return;
    }

    ActivateServerPairModal({ server: props.server, onUpdated: props.onUpdated });
  };

  const updateModal = () => {
    const { pluginDownload } = usePluginDownload();

    pluginDownload('default');

    ActivateConfirmModal({
      type       : 'warn',
      title      : t('plugin-update-title'),
      description: t('plugin-update-subtitle')
    });
    return;
  };

  const getMenuFor = (server: ServerFullDto): ExtendedMenuItem[] => {
    return [{
      label : t('server-context-reconnect'),
      action: () => {
        handleClick();
      }
    }, {
      label : t('server-context-settings'),
      action: () => {
        ActivateServerEditModal({ server, onUpdated: props.onUpdated });
      }
    }, {
      label: t('server-context-download'),
      items: GetPluginVariants(t)
    }, {
      label : t('server-context-delete'),
      action: () => {
        deleteServer(server);
      },
      class: "destructive"
    }];    
  };
  
  const deleteServer = async (server: ServerFullDto) => {
    return await ActivateConfirmModal({
      type         : 'error',
      title        : t('modal.server.delete-confirm.title'),
      description  : t('modal.server.delete-confirm.subtitle'),
      beforeConfirm: async () => {
        await useCourtApi().servers.deleteServer(server.id);

        await props.onUpdated?.().catch(err => undefined);

        ampli.clientServerDelete({ server_id: server.id, server_name: server.name });

        Object.assign(server, { deleted: Date.now() });
      },
    });
  };

  const computedOnline = computed(() => {
    if (!props.server?.state) {
      return null;
    }

    const total = Math.max(props.server.state.max_online, props.server.state.online);

    return {
      active : (props.server.state.active_amount / total) * 100,
      joining: (props.server.state.joining_amount / total) * 100,
      queued : (props.server.state.queue_amount / total) * 100,
    };
  });
</script>

<template>
  <template v-if="!server">
    <div
      class="server"
    >
      <div class="banner-wrapper">
        <Skeleton
          class="banner"
        />
      </div>
      <div class="server-body">
        <div class="flex gap-2">
          <Skeleton
            class="!h-[38px] !w-[38px] rounded-[9px] flex-shrink-0"
          />
          <div class="w-full flex flex-col justify-between">
            <Skeleton
              style="height: 16px;"
              class="server-name rounded-md !w-2/4"
            />
            <Skeleton
              style="height: 14px;"
              class="server-description rounded-md !w-1/3"
            />
          </div>
        </div>
        <Skeleton
          style="height: 8px;"
          class="rounded-md"
        />
        <div class="flex justify-between">
          <Skeleton
            style="height: 16px;"
            class="rounded-md !w-[80px]"
          />
          <Skeleton
            style="height: 16px;"
            class="rounded-md !w-[45px]"
          />
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="server">
    <div
      :key="server.id"
      class="server"
    >
      <div class="banner-wrapper">
        <img
          :src="bannerCopy || defaultBannerUrl"
          class="banner"
          @error="bannerCopy = defaultBannerUrl"
        >
      </div>
      <div class="server-body">
        <div class="flex justify-between">
          <div class="server-info">
            <div class="avatar-wrapper">
              <img
                :src="avatarCopy || defaultAvatarUrl"
                clas="avatar" 
                @error="avatarCopy = defaultAvatarUrl"
              >
              <div :class="`status ${serverStatus}`" />
            </div>
            <div>
              <div class="name">
                <TooltipBase trigger="hover"> 
                  <p class="server-name">{{ server.name }}</p>
       
                  <template #content> 
                    <div class="px-1.5 py-1 text-xs text-center max-w-[240px]"> 
                      <p>{{ server.hostname }}</p> 
                    </div> 
                  </template> 
                </TooltipBase>
              </div>
              <p class="server-description">
                {{ server.state?.level ?? 'Procedural' }}
              </p>
            </div>
          </div>

          <div>
            <Button
              v-if="_permissions.can(['Court_ServerManage'])"
              preset="default"
              class="settings aspect-square !px-1.5 !py-1.5"
              @click="(e) => server ? _context.open(e, getMenuFor(server)) : null"
            >
              <SettingsIcon class="h-5" />
            </Button>
          </div>
        </div>


        <div class="progress-bar">
          <template v-if="computedOnline && server.state">
            <div class="online" :style="`width: ${computedOnline.active}%`" />
            <div class="joining" :style="`width: ${computedOnline.joining}%`" />
            <div class="queue" :style="`width: ${computedOnline.queued}%`" />
          </template>
        </div>
        
        <div class="server-status">
          <template v-if="computedOnline && server.state">
            <div class="players-count">
              <Svg.players />
              <span>{{ server.state!.active_amount }}/{{ server.state!.max_online }}</span>

              <template v-if="!server.state!.queue_amount && server.state!.joining_amount">
                <span class="text-grey-700">•</span>
                <!-- todo: pluralization -->
                <span>{{ t('server-joining-amount', { amount: server.state!.joining_amount }) }}</span>
              </template>
              <template v-if="server.state!.queue_amount">
                <span class="text-grey-700">•</span>
                <span>{{ t('server-queue-amount', { amount: server.state!.queue_amount }) }}</span>
              </template>
            </div>
          </template>
          <template v-else>
            <span class="offline">{{ t('server-offline') }}</span>
          </template>

          <div
            class="version"
            :class="{ 'update-required cursor-pointer': !server.updated }"
            @click="(e) => server?.updated ? null : usePluginMenu(e)"
          >
            <Svg.warning v-if="!server.updated" style="margin-top: 0.5px;" />
            {{ 'v' + server.version }}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.server {
  @apply relative;
  @apply rounded-md;
  @apply overflow-hidden;
  @apply bg-grey-900;
  @apply cursor-grab;

  &.loaded {
    @apply bg-grey-950;
  }

  .banner-wrapper {
    @apply relative;
    @apply w-full;
    height: 140px;

    .banner {
      @apply absolute;
      @apply top-0 left-0 w-full h-full;
      @apply object-cover;
      @apply bg-grey-850;
    }
  }

  .server-body {
    @apply flex flex-col gap-4 p-4;

    .server-info {
      @apply flex items-center gap-2;

      .avatar-wrapper {
        @apply relative shrink-0;
        @apply bg-grey-900;
        width: 38px;
        height: 38px;
        border-radius: 9px;

        img {
          border-radius: 9px;
        }

        .status {
          @apply absolute;
          bottom: 0px;
          right: 0px;
          width: 9px;
          height: 9px;
          @apply z-20;
          @apply ring-[3px] ring-grey-900;
          @apply rounded-half;
          @apply bg-grey-350;

          &.online {
            @apply bg-ra-online;
          }
        }
      }

      .name {
        @apply flex items-center;

        .server-name {
          @apply max-w-[200px] truncate;
          @apply text-grey-50 font-medium;
        }

        svg {
          @apply w-4;
          @apply cursor-pointer;
          @apply fill-grey-500 hover:fill-grey-50;
        }
      }

      .server-description {
        @apply text-[14px] text-grey-400;
      }
    }

    .progress-bar {
      @apply h-[8px] w-full gap-0.5 rounded-md bg-grey-1000 flex overflow-hidden;

      background-image: linear-gradient(45deg, #181818 25%, transparent 0, transparent 50%, #181818 0, #181818 75%, transparent 0, transparent);
      background-size: 1rem 1rem;
      animation: progress-bar-stripes 1s linear infinite;

      .online {
        @apply bg-green-500 h-full;
      }
      .joining {
        @apply bg-blue-500 h-full;
      }
      .queue {
        @apply bg-red-500 h-full;
      }
    }

    .server-status {
      @apply flex justify-between items-center text-grey-300;

      .players-count {
        @apply flex justify-start items-center gap-1;
        @apply text-xs;

        svg {
          @apply h-3.5 w-3.5 fill-grey-300;
        }
      }

      .offline {
        @apply text-xs text-red-500;
      }

      .version {
        @apply text-xs flex items-center;

        &.update-required {
          @apply text-amber-400 drop-shadow-[15px_5px_20px_rgba(251,191,36,1)];
        }

        svg {
          @apply h-3.5 w-3.5 fill-amber-400 mr-0.5;
        }
      }
    }
  }
}

.settings {
  svg {
    @apply ease-in-out delay-0 duration-500;
  }
  
  &:hover {
    svg {
      @apply rotate-180;
    }
  }
}

@keyframes progress-bar-stripes {
  0% {
      background-position: 1rem 0
  }
  to {
      background-position: 0 0
  }
}
</style>