<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { BanDataStorage } from '../stores/ban.storage';
  import BanTimePicker from '../components/BanTimePicker.vue';
  import BanReasonPicker from '../components/BanReasonPicker.vue';
  import BanServerPicker from '../components/BanServerPicker.vue';
  import BanIpWarning from '../components/BanIpWarning.vue';
  import { computed } from 'vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';

  defineProps<{ check?: boolean }>();

  const { t } = useLocaleStore();

  const _permissions = usePermissionsStore();
  const storage = defineModel<BanDataStorage>({ required: true });

  const canInteract = computed(() => {
    // если происходит создание бана
    if (!storage.value.ban) {
      return true;
    }

    // если он может редактировать фулл информацию
    if (_permissions.can(['Court_PlayerBanEditFull'])) {
      return true;
    }

    return false;
  });
</script>

<template>
  <div class="flex flex-col gap-5">
    <BanReasonPicker v-model="storage.target.reason" :check="check" />

    <BanServerPicker v-if="canInteract" v-model="storage" />

    <BanTimePicker
      v-if="canInteract"
      v-model="storage.target.expired"
      :edit="!!storage.ban"
      :update="() => storage.updater?.()"
    />

    <SwitchText
      v-if="storage.target.ip && canInteract"
      v-model="storage.ips[storage.target.steamId]"
      :header="t('modal.ban.general.ip.title')"
    >
      <BanIpWarning 
        :active="storage.ips[storage.target.steamId]"
        :ip="storage.target.ip"
        :data="storage.ipsLoaders[storage.target.ip]"
      />
    </SwitchText>

    <SwitchText
      v-if="!storage.ban"
      v-model="storage.team.enabled"
      :header="t('modal.ban.general.team.title')"
    />
  </div>
</template>

<style lang='scss' scoped>
.ip {
  @apply relative h-4 w-6 flex justify-center items-center;
}

::-webkit-scrollbar-button::start {
  @apply bg-red-500;
  height: 6px;
}

:deep(.server-suggester) {
  .suggestions-wrapper {
    // max-height: 250px;
  }
  ::placeholder {
    @apply pl-0.5;
  }
}

:deep(.base) {
  @apply bg-grey-1000/0;
}
</style>