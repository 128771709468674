<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import QuotesIcon from '@src/assets/icons/quote.svg';
  import { computed } from 'vue';

  const props = defineProps<{
    comment: string
  }>();

  const { t } = useLocaleStore();

  const splittedComment = computed(() => {
    if (!props.comment) {
      return [];
    }

    let comment = props.comment ?? '';

    while (comment.includes('\n\n\n')) {
      comment = comment.replace('\n\n\n', '');
    }

    if (!comment.split('').some(v => v != '\n')) {
      return [];
    }

    return comment.split('\n').map(v => v.length == 0 ? ' ' : v);
  });
</script>

<template>
  <div class="container">
    <p class="title">{{ t('check.verdict.details.comment') }}</p> 
    
    <div class="comment">
      <div>
        <template v-for="(value, _) in splittedComment" :key="`${value}-${_}`">
          <p>
            {{ value }}
          </p>
        </template>
      </div>
      <QuotesIcon />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply flex flex-col gap-2.5;

  .title {
    @apply text-grey-50;
    @apply font-medium;
  }
  .comment {
    @apply flex items-start justify-between gap-2.5;
    @apply p-2;
    @apply border-l-3 border-grey-700;
    @apply bg-grey-850;
    @apply text-grey-400;
    @apply rounded-r-md;
    @apply break-all;

    svg {
      @apply w-4 fill-grey-400 flex-shrink-0;
    }
  }
}
</style>