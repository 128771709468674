<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import { CoreStats } from '@/api/backend/core/utils/utils.dto';
  import Button from '@/components/Button.vue';
  import Divider from '@/components/Divider.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useConfigStore } from '@/stores/config/config.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useSituationStore } from '@panel/stores/situation.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useWindowSize } from '@vueuse/core';
  import { computed, onMounted, ref } from 'vue';


  const { t } = useLocaleStore();
  const { width } = useWindowSize();
  const _adaptive = useAdaptiveStore();
  const isMobile = computed(() => width.value <= transformWidth);

  const selectedTab = ref<string>('players');
  const _situation = useSituationStore();
  
  const stats = ref<CoreStats>();

  onMounted(async () => {
    const data = await useCoreApi().utils.getStats();

    stats.value = data;
  });

  const formatNumber = (value?: number) => {
    if (!value) {
      return 'N/A';
    }

    if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "mil"; // миллионы
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(0) + "k"; // тысячи
    } else {
      return value.toString(); // без изменений для чисел меньше 1000
    }
  };
  
  const transformWidth = 602;

  const tabs = [{
    icon   : Svg.players,
    text   : t('site-title.variable.router-players'),
    flag   : 'players',
    content: 'https://s3.rustapp.io/landing/players.webp',
  },{
    icon   : Svg.chat,
    text   : t('site-title.variable.router-chat'),
    flag   : 'chat',
    content: 'https://s3.rustapp.io/landing/chat.webp'
  },{
    icon   : Svg.report,
    text   : t('site-title.variable.router-reports'),
    flag   : 'reports',
    content: 'https://s3.rustapp.io/landing/reports.webp'
  },{
    icon   : Svg.check,
    text   : t('site-title.variable.router-checks'),
    flag   : 'checks',
    content: 'https://s3.rustapp.io/landing/checks.webp'
  },{
    icon   : Svg.images_circle,
    text   : t('site-title.variable.signages'),
    flag   : 'signages',
    content: 'https://s3.rustapp.io/landing/signages.webp'
  },{
    icon   : Svg.lock,
    text   : t('site-title.variable.router-bans'),
    flag   : 'bans',
    content: 'https://s3.rustapp.io/landing/bans.webp'
  },{
    icon   : Svg.statistics,
    text   : t('site-title.variable.router-stats'),
    flag   : 'stats',
    content: 'https://s3.rustapp.io/landing/stats.webp'
  }];

  const computedSubText = computed(() => {
    return '';
  });
</script>

<template>
  <div class="section-main bg-dot-pattern-2" :class="{'-mobile': _adaptive.isMobile}">
    <div class="offer">
      <div class="stats">
        <div class="icon">
          <Svg.electrocardiogram />
        </div>
        <template v-if="!stats?.counters">
          <Skeleton v-if="!isMobile" class="!w-[120px] !h-[14px] rounded-full" />
          <Skeleton class="!w-[160px] !h-[14px] rounded-full" />
          <Skeleton class="!w-[80px] !h-[14px] rounded-full" />
        </template>
        <template v-else>
          <p v-if="!isMobile" class="text-14-400">
            <span class="text-grey-50">{{ stats?.counters.online_servers ?? '0' }}&nbsp;</span>
            <span class="text-grey-400 truncate">{{ t('landing.new.servers') }}</span>
          </p>
          <p class="text-14-400">
            <span class="text-grey-50">{{ stats?.counters.online_players ?? '0' }}&nbsp;</span>
            <span class="text-grey-400 truncate">{{ t('landing.new.players') }}</span>
          </p>
          <p class="text-14-400">
            <span class="text-grey-50">{{ Object.keys(_situation.clients).length || '0' }}&nbsp;</span>
            <span class="text-grey-400 truncate">{{ t('landing.new.users') }}</span>
          </p>
        </template>
      </div>

      <p class="offer-title">{{ t('landing.new.h1') }}</p>
      <p class="offer-subtitle">{{ t('landing.new.h2') }}</p>
        
      <div class="flex gap-2.5 items-center mt-6">
        <RouterLink to="/profile">
          <Button
            preset="primary"
            class="!px-3.5 !py-2.5"
            style="box-shadow: 0px 4px 40px 0px rgba(0, 153, 255, 0.25);"
          >
            {{ t('landing.new.start') }}
          </Button>
        </RouterLink>
        <RouterLink to="/pricing">
          <Button
            preset="default"
            class="!px-3.5 !py-2.5"
          >
            {{ t('landing.new.pricing') }}
          </Button>
        </RouterLink>
      </div>
    </div>
    <div class="demo">
      <div class="tabs">
        <template v-for="item in tabs" :key="item.flag">
          <Button
            :action="() => selectedTab = item.flag"
            preset="landing-select"
            :active="selectedTab === item.flag"
          >
            <component :is="item.icon" />
            {{ item.text }}
          </Button>
        </template>
      </div>
      <div class="preview">
        <img
          :src="tabs.find(v => v.flag === selectedTab)?.content"
          width="2000"
          height="1109"
        >
      </div>
    </div>
  </div>

  <div class="middle" :class="{'-mobile': _adaptive.isMobile}">
    <div class="section-projects" :class="{'-mobile': _adaptive.isMobile}">
      <span class="text-grey-400 text-center" v-html="t('landing.new.trusted-proj')" />
      <div class="scroll-container">
        <div class="scroll-content">
          <template v-if="!stats?.reviews">
            <ListItem
              v-for="n in 14"
              :key="n"
              :skeleton="'avatar'"
              class="project skeleton"
            />
          </template>
          <template v-else>
            <template v-for="value in (stats?.reviews?.sort((a, b) => a.id - b.id) || [])" :key="value.id">
              <ListItem
                :image-url="useConfigStore().Urls.Images(value.project.avatar)"
                :title="value.project.name"
                :message="value.project.domain"
                :skeleton="'avatar'"
                class="project"
              />
            </template>
          </template>
        </div>
        <div class="scroll-content">
          <template v-if="!stats?.reviews">
            <ListItem
              v-for="n in 14"
              :key="n"
              :skeleton="'avatar'"
              class="project skeleton"
            />
          </template>
          <template v-else>
            <template v-for="value in (stats?.reviews?.sort((a, b) => a.id - b.id) || [])" :key="value.id">
              <ListItem
                :image-url="useConfigStore().Urls.Images(value.project.avatar)"
                :title="value.project.name"
                :message="value.project.domain"
                :skeleton="'avatar'"
                class="project"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="comments">
        <template v-if="!stats?.reviews">
          <div
            v-for="n in 6"
            :key="n"
            class="comment"
          >
            <p class="opacity-0">{{ 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.' }}</p>
          </div>
        </template>
        <template v-else>
          <template v-for="value in (stats?.reviews?.sort((a, b) => a.id - b.id) || [])" :key="value.id">
            <div v-if="!!value.text_ru" class="comment">
              <img
                :src="useConfigStore().Urls.Images(value.project.avatar)"
                class="blur-avatar"
              >
              <ListItem
                :image-url="useConfigStore().Urls.Images(value.project.avatar)"
                :title="value.project.name"
                :message="value.project.domain"
                :skeleton="'avatar'"
                class="mb-2.5"
              />
              <span class="comment-text">
                {{ t('landing.new.project.comment', {ru: value.text_ru, en: value.text_en}) }}
              </span>
            </div>
          </template>
        </template>
      </div>
    </div>

    <div class="section-stats" :class="{'-mobile': _adaptive.isMobile}">
      <div class="stat">
        <span class="value">{{ formatNumber(stats?.counters.total_players) }}</span>
        <span class="text">{{ t('landing.stats.players') }}</span>
      </div>
      <Divider
        v-if="!_adaptive.isMobile && !_adaptive.isTablet"
        vertical
        class="!h-[40px]"
      />
      <div class="stat">
        <span class="value">{{ formatNumber(stats?.counters.total_checks) }}</span>
        <span class="text">{{ t('landing.stats.checks') }}</span>
      </div>
      <Divider
        v-if="!_adaptive.isMobile && !_adaptive.isTablet"
        vertical
        class="!h-[40px]"
      />
      <div class="stat">
        <span class="value">{{ formatNumber(stats?.counters.total_bans) }}</span>
        <span class="text">{{ t('landing.stats.bans') }}</span>
      </div>
      <Divider
        v-if="!_adaptive.isMobile && !_adaptive.isTablet"
        vertical
        class="!h-[40px]"
      />
      <div class="stat">
        <span class="value">{{ formatNumber(stats?.counters.total_reports) }}</span>
        <span class="text">{{ t('landing.stats.reports') }}</span>
      </div>
      <Divider
        v-if="!_adaptive.isMobile && !_adaptive.isTablet"
        vertical
        class="!h-[40px]"
      />
      <div class="stat">
        <span class="value">{{ formatNumber(stats?.counters.total_messages) }}</span>
        <span class="text">{{ t('landing.stats.messages') }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-main {
  @apply flex flex-col justify-between gap-20 items-center;
  @apply pt-[140px];
  @apply w-full;
  @apply overflow-hidden;
  mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
  
  .offer {
    @apply w-full max-w-[580px] mx-auto;
    @apply flex flex-col items-center;

    .offer-title {
      @apply text-[40px] text-center;
      @apply font-black;
      background: radial-gradient(circle at center, #F2F2F2 40%, #b6b6b6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .offer-subtitle {
      @apply text-grey-200;
      @apply text-[22px] text-center;
      @apply text-grey-200;
      @apply mt-4;
    }

    .stats {
      @apply bg-grey-900;
      @apply rounded-full;
      @apply border border-grey-850;
      @apply flex items-center gap-2.5;
      @apply mb-10;
      @apply p-1 pr-2.5;
      @apply mx-auto;

      .icon {
        @apply bg-ra-online/15 rounded-full p-1;

        svg {
          @apply w-[14px] animate-pulse;
        }
      }
    }
  }

  .demo {
    animation: fadeIn 0.5s ease-out;
    
    .tabs {
      @apply max-w-full;
      @apply flex gap-2.5 justify-center flex-wrap;
      @apply my-[30px];
    }

    .preview {
      @apply w-full;
      @apply px-5;
      @apply w-[100vw];
      @apply max-w-[1064px];

      img {
        @apply rounded-t-xl;
        @apply border border-b-0 border-grey-850;
        @apply bg-grey-900;
        box-shadow: 0px 60px 100px 50px rgba(30, 30, 30, 0.7);
      }
    }
  }

  &.-mobile {
    @apply pt-[100px] px-6 gap-10;
    mask-image: linear-gradient(to bottom, black 85%, transparent 100%);

    .offer {
      .offer-title {
        @apply text-[34px];
      }
      .offer-subtitle {
        @apply text-base;
      }
    }

    .preview {
      img {
        @apply rounded-t-md;
        box-shadow: 0px 60px 140px 50px rgba(30, 30, 30, 0.75);
      }
    }
  }
}

.middle {
  @apply flex flex-col py-20 gap-20;

  .section-projects {
    @apply flex flex-col justify-center items-center gap-5;

    .scroll-container {
      @apply overflow-hidden;
      @apply max-w-[800px];
      white-space: nowrap;
      mask-image: linear-gradient(to right, transparent, black 40%, black 60%, transparent);
      -webkit-mask-image: linear-gradient(to right, transparent, black 40%, black 60%, transparent);

      .scroll-content {
        @apply inline-flex;
        animation: scroll 60s linear infinite;

        .project {
          @apply ml-5;

          &.skeleton {
            :deep(.lines) {
              @apply w-[100px];
            }
          }
        }
      }
    }

    .comments {
      @apply max-w-[1000px] mt-16;

      direction: ltr;
      column-count: 3;
      column-gap: 1rem;

      .comment {
        @apply relative inline-block p-5 mb-4 w-full overflow-hidden align-top;
        @apply rounded-xl;
        @apply bg-grey-950;
        @apply border border-grey-900;
        @apply cursor-default;
        @apply transition-all duration-200 ease-in-out;

        .comment-text {
          @apply text-grey-400;
        }

        .blur-avatar {
          @apply absolute top-0 left-0 w-32 h-32;
          @apply blur-3xl opacity-20;
          @apply pointer-events-none;
        }

        @media (hover: hover) {
          &:hover {
            @apply scale-105 rotate-3;
            @apply bg-grey-900;
          }
        }

        @media (hover: none) {
          &:active {
            @apply scale-105 rotate-3;
            @apply bg-grey-900;
          }
        }
      }
    }

    &.-mobile {
      @apply px-10;

      .scroll-container {
        @apply w-full;
      }

      .comments {
        column-count: 1;
      }
    }
  }

  .section-stats {
    @apply flex flex-wrap justify-center items-center gap-6;

    .stat {
      @apply flex flex-col items-center;

      .value {
        @apply text-grey-50 text-[42px] font-bold;
      }

      .text {
        @apply text-grey-400 text-[18px] font-medium;
      }
    }

    &.-mobile {
      @apply px-10;
    }
  }

  &.-mobile {
    @apply gap-28;
  }
}

@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>