<script setup lang="ts">
  import { PlayerAlertVpnDetectedMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { onMounted, ref } from 'vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<{
    meta: PlayerAlertVpnDetectedMetaDto;
    date: number;
  }>();

  const {generate} = useUnknownPlayer();
  const player = ref<PlayerFullDto>();
  
  onMounted(async () => {
    const result = await useCourtApi().player.browseFullBatchLoader.load(props.meta.steam_id);
    if (!result || !('steam_id' in result)) {
      player.value = generate(props.meta.steam_id);
      return;
    }
    player.value = result;
  });
</script>

<template>
  <PlayerAlertBase
    icon="player-alert-types/lock.key.png"
    :date="date"
    :ready="!!player"
  >
    <template v-if="player">
      <span> {{ t('alert-detect-vpn-1') }}</span>

      <span 
        class="bright cursor-pointer" 
        @click="ActivatePlayerDetailsModal(player.steam_id)"
      > 
        {{ player.steam_name }} 
      </span>

      <span> {{ t('alert-detect-vpn-2') }}</span>

      <span 
        class="!text-orange-300 cursor-pointer"
        @click="ActivateIpDetailsModal(meta.ip, meta.ip_details)"
      >
        {{ t('alert-detect-vpn-3') }}
      </span>
    </template>
  </PlayerAlertBase>
</template>