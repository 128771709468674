<script setup lang="ts">
  import { useConfigStore } from '@/stores/config/config.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateImageGalleryModal } from '@src/modals/image-gallery/ImageGalleryModal.vue';
  import { computed } from 'vue';

  const props = withDefaults(defineProps<{
    images: string[],
    perRow?: number,
    max?: number | undefined
  }>(), {
    perRow: 4,
    max   : undefined
  });

  const { t } = useLocaleStore();

  const openImage = (index: number) => {
    ActivateImageGalleryModal({ images: props.images.map(hash => ({ hash })), index });
  };

  // узнает, сколько не хватает количеству картинок, чтобы делиться на perRow без остатка
  const amountForFullRow = computed(() => {
    const remainder = computedImages.value.length % props.perRow;
    return remainder ? (props.perRow - remainder) : 0;
  });

  const computedImages = computed(() => {
    return props.max ? props.images.slice(0, props.max) : props.images;
  });

  const isLast = (index: number) => {
    const last = index === (computedImages.value.length - 1);
    const moreThenVisible = props.images.length > props.perRow;

    return last && moreThenVisible && props.max;
  };
</script>

<template>
  <div class="images">
    <template v-for="(img, index) in computedImages" :key="`img_${index}`">
      <div
        class="img-box"
        :class="{ 'last-image': isLast(index)}"
        @click="openImage(index)"
      >
        <img :src="useConfigStore().Urls.Images(img)" class="img">
        <template v-if="isLast(index)">
          <p class="more"> {{ t('general.more') }} </p>
          <div class="blurr" />
        </template>
      </div>
    </template>

    <!-- пустые клеточки -->
    <template v-for="(_) in amountForFullRow" :key="_">
      <div class="img-box empty" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
$gap: 10px;
$per-row: v-bind('perRow');
.images {
  @apply flex flex-wrap;
  @apply grid;
  grid-template-columns: repeat($per-row, calc((100% - ($per-row - 1) * $gap) / $per-row)) ;
  grid-gap: $gap;

  .img-box {
    @apply overflow-hidden;
    @apply bg-grey-850;
    @apply rounded-md;
    @apply border border-grey-850;
    @apply transition-all duration-200 ease-out;

    &.last-image {
      @apply rounded-md;
      @apply relative overflow-hidden;

      .blurr {
        @apply absolute inset-0;
        @apply bg-grey-1000/50;
        @apply z-10;
      }

      .more {
        @apply z-20;
        @apply absolute top-1/2 left-1/2;
        @apply -translate-x-1/2 -translate-y-1/2;
        @apply text-grey-50;
      }
    }

    &:hover {
      @apply cursor-pointer;
      @apply scale-105;
    }
    
    &.empty {
      @apply bg-grey-1000;
      @apply border-none;
      @apply pointer-events-none;
    }
  }

  .img {
    @apply bg-grey-850;
    @apply w-full;
    @apply aspect-square;
    @apply object-cover;
    @apply transition-all ease-in-out delay-0 duration-100;
  }
}
</style>