<script setup lang="ts">
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { usePlayerMenu } from '@court/menus/player.menu';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    team: PlayerFullDto[] | null,
    player: PlayerFullDto
  }>();

  const _playerMenu = usePlayerMenu();
</script>

<template>
  <div v-if="!team || team?.length == 0" class="flex items-center justify-center h-full">
    <EmptyImage
      type="empty"
      :title="t('modal.player.team.empty.title')"
      :subtitle="t('modal.player.team.empty.subtitle')"
    />
  </div>
  <div v-else class="flex flex-col divide-y divide-grey-850">
    <template
      v-for="teammate in team"
      :key="teammate?.steam_id"
    >
      <div
        class="teammate"
      >
        <PlayerBox
          :player="teammate"
          class="w-full"
          style="max-width: 250px;"
          disable-hover
          @click="() => teammate ? ActivatePlayerDetailsModal(teammate.steam_id, teammate) : null"
        />
        <Svg.dropdown class="arrow" @click="(e: MouseEvent) => _playerMenu.open(e, teammate)" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.teammate {
  @apply flex justify-between items-center py-5;

  .arrow {
    @apply w-[18px] fill-grey-500;
    @apply cursor-pointer;
    &:hover {
      @apply fill-grey-200;
    }
  }

  &:first-child {
    @apply pt-0;
  }
  &:last-child {
    @apply pb-0;
  }
}
</style>