<script setup lang="ts">
  import { UploadedFile } from '../utils/uploader.dto';
  import Loader from '@/components/Loader.vue';
  
  import Button from '@/components/Button.vue';
  import DeleteIcon from '@src/assets/icons/delete-stroke.svg';
  import { computed, onMounted, ref, watch } from 'vue';
  import axios from 'axios';
  import { useConfigStore } from '@/stores/config/config.store';

  const props = defineProps<{ image: UploadedFile }>();
  const emits = defineEmits(['delete']);

  const data = ref<{ name: string, size: number }>();

  onMounted(() => load());

  watch(() => props.image, () => load(), { deep: true });

  const bytesToSize = (bytes: number, seperator = "") => {
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    if (bytes == 0) return 'n/a';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
  
    return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`;
  };

  const load = async () => {
    if (!props.image.s3_id || props.image.file) {
      return;
    }

    setTimeout(() => {
      const files = document.getElementsByClassName('file');
      const lastFile = files[files.length - 1];
      lastFile?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 0);

    const response = await axios.get(useConfigStore().Urls.Images(props.image.s3_id), {
      headers: {
        "Content-Type": 'image/png'   
      },
      responseType: 'blob'
    });
  
    data.value = {
      name: response.headers['x-amz-meta-name'],
      size: +response.headers['content-length'],
    };
  };  

  const computedUrl = computed(() => {
    if (props.image.file) {
      return URL.createObjectURL(props.image.file);
    }

    if (props.image.s3_id) {
      return useConfigStore().Urls.Images(props.image.s3_id);
    }

    return '123';
  });

  const computedName = computed(() => {
    if (props.image.file) {
      return props.image.file.name;
    }

    return data.value?.name;
  });

  const computedSize = computed(() => {
    if (props.image.file) {
      return props.image.file.size;
    }

    return data.value?.size;
  });

  const isLoading = computed(() => !props.image.s3_id);
</script>

<template>
  <div class="file">
    <div class="relative overflow-hidden flex-shrink-0 rounded">
      <img 
        class="img"
        :src="computedUrl"
      >

      <div v-if="isLoading" class="absolute flex justify-center items-center  bg-black/80 h-full w-full inset-0">
        <Loader size="20px" />
      </div>
    </div>

    <p class="info truncate">
      <span class="title truncate">{{ computedName }}</span>
      <span>{{ bytesToSize(computedSize ?? 0) }}</span>
    </p>

    <Button
      class="delete-button"
      @click="emits('delete')"
    >
      <DeleteIcon class="delete" />
    </Button>

    <div class="bg" />
  </div>
</template>

<style lang="scss" scoped>

.file {
    position: relative;
    @apply rounded-md;
    @apply bg-grey-850;
    @apply p-2.5;
    @apply flex items-center gap-2.5;
    
    .img {
      width: 46px;
      @apply aspect-square;
      @apply object-cover;
      @apply bg-grey-850;
    }
    .info {
      @apply text-sm;
      @apply text-grey-400;
      flex-grow: 1;
      flex-shrink: 1;
      .title {
        line-height: 22px !important;
        max-width: 220px;
        @apply text-lg;
        @apply block;
        @apply text-grey-50;
      }
    }
    .delete-button {
      @apply p-2.5;
      svg {
        @apply w-6;
        @apply fill-grey-600;
      }
      &:hover + .bg {
        @apply rounded-md;
        background: radial-gradient(31.36% 192.42%  at 100.12% 50.76%, rgba(240, 80, 58, 0.15) 0%, rgba(240, 80, 58, 0.00) 100%);
      }
      &:hover {
        svg {
          @apply fill-red-500;
        }
      }
    }

    .bg {
      @apply -z-10;
      position: absolute;
      inset: 0;
      @apply bg-transparent;
    }
  }
</style>