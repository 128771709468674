<script setup lang="ts"> 
  import { useLocaleStore } from '@/stores/locale.store'; 
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { onMounted, ref, watch } from 'vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import SettingsNumeric from '@/components/inputs/numeric-input/SettingsNumeric.vue';

  const { t } = useLocaleStore();

  const _panel = usePanelStore();

  const data = ref<{
    'twofa_required': boolean,
    'steam_required': boolean
  }>({
    "twofa_required": true,
    'steam_required': true
  });

  onMounted(() => {
    if (!_panel.project) {
      return;
    }

    data.value.twofa_required = _panel.project.twofa_strict;
    data.value.steam_required = _panel.project.steam_strict;

    watch(() => data.value, async (v) => {
      if (!_panel.project) {
        return;
      }

      _panel.project.twofa_strict = v.twofa_required;
      _panel.project.steam_strict = v.steam_required;

      await useCoreApi().project.editClientSettings(_panel.project.id, v.twofa_required, v.steam_required);
    }, { deep: true }); 
  });

  const courtSettings = ref<{
    check_min_reports_to_start: number
  }>({
    check_min_reports_to_start: 0
  });
</script>

<template>
  <div class="title">
    <span class="text-head">{{ t('staff.settings.protection') }}</span>
  </div>
  <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
    <SwitchText
      v-model="data.twofa_required"
      class="discord-switch"
      
      :header="t('2fa-enabled-header')"
      :placeholder="t('2fa-enabled-subtitle')"
    />
    <SwitchText
      v-model="data.steam_required"
      class="discord-switch"
      
      :header="t('steam-enabled-header')"
      :placeholder="t('steam-enabled-subtitle')"
    />
  </SettingsContainer>
</template>

<style lang="scss" scoped>
.title {
  @apply flex items-center mb-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }
}
</style>