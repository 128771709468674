<script setup lang='ts'>

  import PanelLayoutProjectBar from './components/PanelLayoutProjectBar.vue';
  import PanelLayoutPageBar from './components/PanelLayoutPageBar.vue';
  
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { onMounted } from 'vue';
  import { useNewsStore } from '@/stores/news.store';
  import { useAppStore } from '@/stores/app.store';
  import { usePanelStore } from './stores/panel.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import Button from '@/components/Button.vue';
  import { useRoute } from 'vue-router';
  import { ActivateConnectTwoFactorModal } from '@src/modals/2fa/ConnectTwoFactorModal.vue';
  import { useSteamAuthStart } from '@src/composable/steam-connect.composable';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import { useCoreApi } from '@/api/backend/core/core.api';

  const { t } = useLocaleStore();
  const _app = useAppStore();
  const _panel = usePanelStore();
  const _news = useNewsStore();
  const _route = useRoute();
  const _adaptive = useAdaptiveStore();

  const hover = (active: boolean) => {
    if (!_adaptive.isTablet) {
      return;
    }

    _adaptive.editLeftBar(active);
  };

  onMounted(() => {
    _news.warmup();
  });

  const start2fa = async () => {
    const link = await useCoreApi().auth.start2fa();

    ActivateConnectTwoFactorModal(link);
  };

  const connectSteam = async () => {
    if (!_app.client) {
      return;
    }

    const link = await useSteamAuthStart();

    const steam = await useCoreApi().auth.steamConnectFinish(link);
      
    _app.client.steam = steam;
  };
</script>

<template>
  <div class="layout">
    <div
      class="flex left-bar relative"
      @mouseenter="hover(true)"
      @mouseleave="hover(false)"
    >
      <PanelLayoutProjectBar class="project-bar" />
      <PanelLayoutPageBar class="page-bar" />
    </div>
    
    <div class="content">
      <template v-if="_panel.project?.steam_strict && !_app.client?.steam?.steam_id && !_route.fullPath.includes('/settings/')">
        <TitledPage no-divider no-margin>
          <div class="section-protector">
            <EmptyImage
              :title="t('steam-title-error-title')"
              :subtitle="t('steam-title-error-subtitle')"
              type="lock"
            />

            <Button
              preset="default"
              :text="t('steam-connect-btn')"
              @click="connectSteam"
            />
          </div>
        </TitledPage>
      </template>
      <template v-else-if="_panel.project?.twofa_strict && !_app.client?.twofactor_active && !_route.fullPath.includes('/settings/')">
        <TitledPage no-divider no-margin>
          <div class="section-protector">
            <EmptyImage
              :title="t('2fa-title-error-title')"
              :subtitle="t('2fa-title-error-subtitle')"
              type="lock"
            />

            <Button
              preset="default"
              :text="t('2fa-connect-btn')"
              @click="start2fa"
            />
          </div>
        </TitledPage>
      </template>
      <template v-else>
        <RouterView
          name="content"
        />
      </template>

      <!--<div
        class="slide-helper -left-1"
        @touchstart="(e) => drag(e, 75)"
      />-->
    </div>
  </div>
</template>

<style lang='scss' scoped>

.section-protector {
  @apply flex flex-col items-center justify-center gap-5;
  
  height: calc(100vh - 64px);
}

.layout {
  @apply w-full h-full overflow-hidden relative;

  .left-bar, .content {
    &, * {
      transition: all $adaptive-resize-speed;
      @apply ease-in-out;
    }

    @apply absolute inset-0;
  }

  .left-bar {
    //z-index: 2222;
    @apply z-10;
  }

  .page-bar {
    @apply flex flex-col p-4;
  }

  .project-bar {
    @apply p-4;
  }

  .page-bar, .project-bar {
    @apply bg-grey-900;
    @apply border-r border-grey-1000;
  }

  .content {
    @apply absolute;
    @apply bg-grey-1000;

    //z-index: 9999;
    @apply z-20;

    .slide-helper {
      @apply absolute;
      top: 60px;
      bottom: 0;

      @apply z-30;
      //z-index: 99999;

      width: 40px;
    }
  } 

  --content-margin: 0px;
  --content-width: 100%;

  --page-bar-min-width: 66.8px;
  --page-bar-max-width: 260px;

  --left-bar-margin: 0px;

  --right-bar-width: 0px;
  --project-bar-width: 80px;
  --page-bar-width: var(--page-bar-max-width);
  --left-bar-width: calc(var(--page-bar-width) + var(--project-bar-width));
}

[device='desktop'], [device='laptop'], [device='tablet'] {
  .layout {
    .slide-helper {
      display: none;
    }
  }
}

[left-bar] {
  .layout {
    .slide-helper {
      width: 500px;
    }
  }
}

[device='tablet'] {
  .content {
    //z-index: 1111;
    @apply z-0;
  }

  &[left-bar] {
    .layout {
      --left-bar-margin: 0px;
      --page-bar-width: var(--page-bar-max-width);
    }
  }

  .layout {
    --left-bar-margin: calc(var(--project-bar-width) * -1);
    --page-bar-width: var(--page-bar-min-width);

    --content-margin: var(--page-bar-min-width);
    --content-width: calc(100% - var(--page-bar-min-width));
  }
}

[device='mobile'] {
  &:not([left-bar]):not([right-bar]) {
    .layout {
      .content {
        box-shadow: rgb(17 17 17 / 70%) -4px 0px 30px 0px;
      }
    }
  }

  &[left-bar] {
    .layout {
      --content-margin: var(--left-bar-width);

    }
  }

  &[right-bar] {
    .slide-helper {
      display: none;
    }
  }
}

[device='laptop'], [device='desktop'] {
  --left-size: 0px;
  --right-size: 0px;

  &[left-bar] {
    .layout {
      --left-size: var(--left-bar-width);

      --content-margin: var(--left-size);
    }
  }

  .layout {
    --content-width: calc(100% - var(--left-size) - var(--right-size));
  }
}

.layout {
  $content-margin: var(--content-margin);
  $content-width: var(--content-width);

  $page-bar-width: var(--page-bar-width);
  $project-bar-width: var(--project-bar-width);

  $left-bar-width: calc($page-bar-width + $project-bar-width);
  $left-bar-margin: var(--left-bar-margin);

  .content {
    left: $content-margin;

    width: $content-width;
    min-width: $content-width;
  }

  .left-bar {
    width: $left-bar-width;
    left: $left-bar-margin;

    .project-bar {
      width: $project-bar-width;
    }

    .page-bar {
      width: $page-bar-width;
    }
  }
}
</style>