<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import QuotesIcon from '@src/assets/icons/quote.svg';
  import { computed } from 'vue';
  import { ActivateBanViewModal } from '../BanViewModal.vue';

  const props = defineProps<{
    comment: string;
  }>();
  const { t } = useLocaleStore();

  const splittedComment = computed(() => {
    if (!props.comment) {
      return [];
    }

    let comment = (props.comment ?? '').split('~BAN_SYNC_COPY~')[0];
    if (comment.length == 0) {
      return [];
    }

    while (comment.includes('\n\n\n')) {
      comment = comment.replace('\n\n\n', '');
    }
  
    if (!comment.split('').some(v => v != '\n')) {
      return [];
    }

    return comment.split('\n').map(v => v.length == 0 ? ' ' : v);
  });  

  const isHidden = computed(() => {
    return props.comment && props.comment === 'x-factor-hidden';
  });
</script>

<template>
  <template v-if="splittedComment.length > 0">
    <p class="title">{{ t('modal.ban-view.comment') }}</p> 
  
    <template v-if="isHidden">
      <p class="ban-view-hidden">
        {{ t('modal-ban-view-comment-hidden') }}
      </p>
    </template>
    <template v-else>
      <div class="comment">
        <div>
          <template v-for="(value, _) in splittedComment" :key="`${value}-${_}`">
            <p>
              {{ value }}
            </p>
          </template>
        </div>
  
        <QuotesIcon />
      </div>
    </template>
  </template>
</template>

<style lang="scss" scoped>
.title {
  @apply text-grey-50 mb-2.5;
  @apply font-medium pt-5;
}

.comment {
  @apply flex items-start justify-between gap-2.5;
  @apply p-2;
  @apply border-l-3 border-grey-700;
  @apply bg-grey-850;
  @apply text-grey-400;
  @apply rounded-r-md;
  @apply break-all;

  svg {
    @apply w-4 fill-grey-400 flex-shrink-0;
  }
}

.copy-row {
  @apply overflow-hidden;
  @apply bg-[#a2a2a229] text-[#ffffffb5];
  @apply text-center w-full mt-2;
  @apply p-2;
  @apply rounded-md;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
}
</style>