import { useDataStore } from "@/stores/data.store";
import { SoundType } from "@/stores/sound.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useInterfaceStore = defineStore('interface', () => {
  const settings = useDataStore().sync('interface-data', ref<{
    show_battlemetrics: boolean,
    play_sound: SoundType,
  }>({
    show_battlemetrics: false,
    play_sound        : 'Din-Don'
  }));

  return { settings };
});