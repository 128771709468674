export const EnvVariables = {
  // Basic type env
  Env: import.meta.env.VITE_ENV as 'DEV' | 'PROD',

  // Main links for front/backend
  DomainSite: import.meta.env.VITE_DOMAIN,
  DomainApi : import.meta.env.VITE_API_DOMAIN,
  
  // External sources
  GoogleAuthClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,

  IsProd: function() {
    return this.Env === 'PROD';
  },
};

const missingEnvironment = Object.entries(EnvVariables).find(([key, value]) => !value.length && key != 'IsProd');

if (missingEnvironment?.[0]) {
  throw new Error(`Bad environment configuration. Missing key: ${missingEnvironment[0]}`);
}

if (EnvVariables.DomainSite.includes('/') || EnvVariables.DomainApi.includes('/')) {
  throw new Error(`Bad environment configuration. Domains contains '/'`);
}