<script setup lang="ts">
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerNavigationStatsGeneral from './PlayerNavigationStatsGeneral.vue';
  import PlayerNavigationStatsKillfeed from './PlayerNavigationStatsKillfeed.vue';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { ref } from 'vue';

  const props = defineProps<{ steamId: string }>();

  const { t } = useLocaleStore();

  const variants = ref<SelectValue<number>[]>([{
    text : t('navigation-stats-day-range-1'),
    value: 1
  }, {
    text : t('navigation-stats-day-range-7'),
    value: 7
  }, {
    text : t('navigation-stats-day-range-30'),
    value: 30
  }, {
    text : t('navigation-stats-day-range-365'),
    value: 365
  }]);
  const variant = ref<number>(7);

  const navigation = ref<InstanceType<typeof ModalMenuExtendedPageLayout>>();
</script>

<template>
  <ModalMenuExtendedPageLayout ref="navigation">
    <template #[t(`navigation-stats-general`)]>
      <PlayerNavigationStatsGeneral :steam-id="steamId" :offset="variant" />
    </template>
    <template #[t(`navigation-stats-kill-feed`)]>
      <PlayerNavigationStatsKillfeed :steam-id="steamId" />
    </template>

    <template v-if="navigation?.selected == t(`navigation-stats-general`)" #actions>
      <SelectInput
        v-model="variant"
        class="stats-select-input"
        :values="variants"
        :variant="'default'"
        right
      />
    </template>
  </ModalMenuExtendedPageLayout>
</template>

<style lang="scss" scoped>
:deep(.stats-select-input) {
  .select {
    .activator {
      @apply gap-1 p-0 #{!important};
      @apply text-14-500 #{!important};
    }
  }
}
</style>
