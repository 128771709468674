<script setup lang='ts'>
  import HotKey from '@/components/HotKey.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import SearchIcon from '@src/assets/icons/search.svg';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useMagicKeys, whenever } from '@vueuse/core';
  import { ActivatePlayerSearchModal } from '@court/modals/player-search/PlayerSearchModal.vue';
  import { ampli } from '@src/ampli';

  const { t } = useLocaleStore();
  const _adaptive = useAdaptiveStore();

  const keys = useMagicKeys({
    passive     : false,
    onEventFired: (e) => {
      if ((e.ctrlKey || e.metaKey) && ['f', 'а'].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    }
  });

  whenever(keys.ctrl_F, () => onKey());
  whenever(keys.ctrl_А, () => onKey());

  const onKey = () => {
    ampli.clientFastSearch({ by_action: 'key' });

    ActivatePlayerSearchModal({});
  };

  const onClick = () => {
    ampli.clientFastSearch({ by_action: 'click' });

    ActivatePlayerSearchModal({});
  };
</script>

<template>
  <div
    class="search"
    @click="onClick"
  >
    <div class="holder flex items-center justify-center gap-2">
      <SearchIcon class="w-5 h-5 stroke-grey-500 stroke-2" />
      <p 
        class="text-grey-600 font-medium"
      >
        {{ t('general.search') }}
      </p>
    </div>

    <div
      v-if="!_adaptive.isMobile"
      class="flex items-center gap-1"
    >
      <HotKey
        :keys="[{text: 'ctrl', code: 17}, { text: 'f', code: 70 }]"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.search {
    @apply flex w-full overflow-hidden;
    @apply inline-flex justify-between;
    @apply px-2 py-2;
    @apply rounded-md;
    @apply cursor-pointer;
    @apply bg-grey-850;
  }
</style>