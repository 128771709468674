<script setup lang='ts'>
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useValidators } from '@src/composable/validate.composable';

  import { computed, ref } from 'vue';

  const tag = defineModel<string>({ required: true });

  const targRef = ref<InstanceType<typeof TextInput>>();

  const _validators = useValidators();
  const { t } = useLocaleStore();

  const isFilled = computed(() => targRef.value?.activeException);

  defineExpose({ isFilled });
</script>

<template>
  <div class="h-fit p-5">
    <TextInput 
      ref="targRef"
      v-model="tag"
      autofocus
      :label="t('permissions.invite.label')"
      :placeholder="'garry'"
      :validator="_validators.Tag"
      no-border
    >
      <div> 
        <span class="font-normal">{{ '@' }}</span> 
      </div>
    </TextInput>
  </div>
</template>

<style lang='scss' scoped>

</style>