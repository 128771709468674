<script lang="ts">
  import { type Component } from 'vue';

  export interface SvgOpts {
    component: Component;
    classes?: string;
  }
</script>

<script setup lang='ts'>
  import Skeleton from '@/components/Skeleton.vue';
  import { v4 as uuidv4 } from 'uuid';
  import { ref, computed } from 'vue';

  const props = withDefaults(defineProps<{
    skeleton?: 'avatar' | 'avatar-status';

    /**
     * Общие данные для отображения
     */
    imageUrl?: string;
    noAvatar?: boolean;
    vertical?: boolean;
    hideText?: boolean;
    imageStatusColor?: string;

    svg?: SvgOpts;
    
    title?: string;
    titleWrap?: boolean;

    message?: string | null;
    messageWrap?: boolean;

    /**
     * Настройка стилистики
     */
    size?: 'default' | 'large' | 'small' | 'm-small' | 'x-small';
    contentAlign?: `items-${'start' | 'center' | 'bottom'}`;
  }>(), {
    imageUrl        : undefined,
    noAvatar        : false,
    imageStatusColor: undefined,
    svg             : undefined,

    title  : undefined,
    message: undefined,

    size        : 'default',
    contentAlign: 'items-center'
  });

  /**
   * Мы не знаем что это такое, но это очень нужно чтобы работали маски
   * Мы знаем почему мы сделали так, но как сделать так, чтобы не делать так - не знаем
   * Если бы мы знали, но мы не знаем
   */
  const isLoaded = ref<boolean>(false);
  const computedId = ref(uuidv4());

  const computedBadgeClasses = computed(() => {
    if (props.imageStatusColor) {
      return props.imageStatusColor;
    }

    if (props.skeleton != 'avatar-status') {
      return null;
    }

    return `skeleton max-w-sm bg-white/10 w-full h-full`;
  });
</script>

<template>
  <div
    class="image-lines-container"
    :class="[size, contentAlign, {'-no-avatar': noAvatar, vertical }]"
  >
    <div v-if="!noAvatar" class="image">
      <div
        class="img-box" 
      >
        <template v-if="svg">
          <component :is="svg.component" :class="svg.classes" />
        </template>
        <template v-else>
          <template v-if="computedBadgeClasses">
            <div class="image-mask"> 
              <svg role="none" class="w-full h-full">
                <mask v-if="computedBadgeClasses" :id="computedId">
                  <circle class="mask-circle" />
                  <circle class="cut" />
                </mask>

                <g :mask="`url(#${computedId})`">
                  <rect
                    v-if="!isLoaded"
                    x="0"
                    y="0"
                    width="150"
                    height="150"
                    class="skeleton max-w-sm fill-white/10 w-full h-full"
                  />
                  <image
                    class="w-full h-full"
                    x="0"
                    y="0"
                    preserveAspectRatio="xMidYMid slice"
                    :xlink:href="imageUrl"
                    :class="{ 'opacity-0': !isLoaded }"
                    @load="isLoaded = true"
                  />
                </g>
              </svg>            

              <template v-if="computedBadgeClasses">
                <div :class="['badge', computedBadgeClasses]" />
              </template>
            </div>
          </template>
          <template v-else-if="skeleton">
            <Skeleton v-if="!isLoaded" class="rounded-half" />
            <img
              v-if="imageUrl"
              class="w-full h-full"
              :src="imageUrl"
              :class="{ 'opacity-0': !isLoaded }"
              @load="isLoaded = true"
            >
          </template>
        </template>
      </div>
    </div>

    <div v-if="!hideText" class="lines">
      <p class="title" :class="{ 'no-wrap-line': !titleWrap }">
        <template v-if="$slots.title">
          <slot name="title" />
        </template>
        <template v-else-if="title"> 
          {{ title }}
        </template>
        <template v-else>
          <Skeleton />
        </template>
      </p>

      <p class="message" :class="{ 'no-wrap-line': !messageWrap }">
        <template v-if="message">
          {{ message }}
        </template>
        <template v-else-if="$slots.message">
          <slot name="message" />
        </template>
        <template v-else-if="message !== null">
          <Skeleton />
        </template>
      </p>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.image-lines-container {
  --image-size: 40px;

  --title-size: 16px;
  --message-size: 14px;

  --badge-size: 9px;
  --border-size: 3px;
  
  --gap-size: 8px;
 
  &.large {
    --image-size: 66px;

    --title-size: 18px;
    --message-size: 16px;

    --badge-size: 14px;
    --border-size: 4px;
    
    --gap-size: 14px;
  }
  &.vertical {
    --image-size: 80px;

    --title-size: 22px;
    --message-size: 18px;

    --badge-size: 16px;
    --border-size: 6px;

    --gap-size: 10px;

    @apply flex-col items-center;
    @apply text-center;

    .lines {
      width: calc(100% - var(--gap-size));
    }
  }

  &.small {
    --image-size: 28px;

    --title-size: 13px;
    --message-size: 10px;

    --badge-size: 5.6px;
    --border-size: 2.1px;

    --gap-size: 5px;
    .lines {
      .title {
        line-height: normal !important;
      }
      .message {
        line-height: normal !important;
      }
    }
  }
  &.m-small {
    --image-size: 24px;

    --title-size: 16px;
    --message-size: 12px;

    --badge-size: 6px;
    --border-size: 2px;

    --gap-size: 4px;
    .lines {
      .title {
        line-height: normal !important;
      }
      .message {
        line-height: normal !important;
      }
    }
  }
  &.x-small {
    --image-size: 20px;

    --title-size: 16px;
    --message-size: 12px;

    --badge-size: 5px;
    --border-size: 2px;

    --gap-size: 4px;
    .lines {
      .title {
        line-height: normal !important;
      }
      .message {
        line-height: normal !important;
      }
    }
  }
  &.-no-avatar {
    --image-size: 0px;
    --gap-size: 0px;
  }

  $image-size: var(--image-size);

  $badge-size: var(--badge-size);
  $border-size: var(--border-size);
  
  $gap-size: var(--gap-size);

  // Считаем доп. поле для маски
  $cut-badge-size: calc(var(--badge-size) + calc(var(--border-size) * 2));

  @apply flex justify-start relative;

  @apply overflow-hidden;

  gap: $gap-size;
  color: inherit;

  .image {
    @apply relative flex justify-center items-center;

    width: $image-size;
    height: $image-size;
    flex-basis: $image-size;

    .img-box, img, image, svg {
      @apply rounded-half w-full h-full object-cover;
    }
    
    .image-mask {
      width: $image-size;
      height: $image-size;
      
      .mask-circle {
        cx: calc($image-size / 2);
        cy: calc($image-size / 2);
        r: calc($image-size / 2);
        fill: white;
      }

      .cut {
        fill: black;
        r: calc($cut-badge-size / 2);
        transform: translate(calc($image-size - calc($cut-badge-size / 2)), calc($image-size - calc($cut-badge-size / 2)));
      }
    }

    .loader {
      @apply absolute w-full h-full inset-0 bg-black z-10; 
    }
    
    .badge {
      @apply absolute;
      @apply rounded-full;
      @apply transition-colors ease-in-out delay-0 duration-1000;
      
      bottom: $border-size;
      right: $border-size;
      
      height: $badge-size;
      width: $badge-size;
    }
  }

  .lines {
    width: calc(100% - $image-size - $gap-size);

    .title {
      &, * {
        @apply text-grey-50;
        @apply text-lg font-semibold;
        
        font-size: var(--title-size);
        line-height: calc(var(--title-size) + 6px);
      }

      .skeleton {
        @apply rounded-md;

        height: calc(var(--title-size) - 2px) !important;
        width: 100%;
      }
    }

    .message {
      &, * {
        @apply text-grey-400;
        @apply font-normal;
        
        font-size: var(--message-size);
        line-height: calc(var(--message-size) + 4px);
      }

      .skeleton {
        @apply rounded-md mt-1.5;

        height: calc(var(--message-size) - 2px) !important;
        width: 75%;
      }
    }
  }
}

.no-wrap-line {
  &, * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}
</style>