<script lang="ts" setup>
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useTimeStore } from '@/stores/time.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtStore } from '@court/stores/court.store';
  import { computed } from 'vue';
  import { CourtRoutes } from '@court/court.routes';
  import { useRouter } from 'vue-router';

  import Skeleton from '@/components/Skeleton.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import ClientInline from '@court/components/client/ClientInline.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useUnknownClient } from '@court/utils/player.utils';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';

  const props = defineProps<{
    ban: BanDto
  }>();

  const _time = useTimeStore();
  const _panel = usePanelStore();
  const _court = useCourtStore();
  const _permissions = usePermissionsStore();
  const _router = useRouter();
  const { t } = useLocaleStore();

  const serverTooltipText = (ban: BanDto) => {
    return ban.server_ids.map(v => _court.serverNameById(v)).join(', ');
  };

  const checkId = computed(() => {
    return props.ban.ban_group_uuid?.includes('check:') ? props.ban.ban_group_uuid.replace('check:', '') : null;
  });

  const openCheck = () => {
    if (!checkId.value) {
      return;
    } 

    _router.push({ name: CourtRoutes.Checks, params: { id: checkId.value }});
  };
</script>

<template>
  <div class="flex flex-col gap-2.5">
    <div class="row">
      <p class="name">
        <Svg.bubble />
        {{ t('modal.ban-view.reason') }}
      </p>
      <p
        v-if="ban.reason"
        :title="ban.reason"
        class="value"
      >
        {{ ban.reason }}
      </p>
      <Skeleton
        v-else
        class="skeleton ml-auto"
      />
    </div>

    <div class="row">
      <p class="name">
        <Svg.clock />
        {{ t('modal.ban-view.date') }}
      </p>
      <p v-if="ban.reason" class="value">{{ _time.format(ban.created_at, 'DD.MM.YY # HH:mm', true, true) }}</p>
      <Skeleton
        v-else
        class="skeleton ml-auto"
      />
    </div>

    <div class="row">
      <p class="name">
        <Svg.hourglass />
        {{ t('modal.ban-view.time') }}
      </p>
      <p v-if="ban.reason" class="value">{{ ban.expired_at ? t('bans.temporary', {date: _time.format(ban.expired_at, 'DD.MM.YY # HH:mm', true, true)}) : t('bans.forever') }}</p>
      <Skeleton
        v-else
        class="skeleton ml-auto"
      />
    </div>

    <div class="row">
      <p class="name">
        <Svg.server />
        {{ t('modal.ban-view.server') }}
      </p>
      <template v-if="ban">
        <p v-if="ban.server_ids.length == 0" class="value">{{ t('bans.all') }}</p>
        <p v-if="ban.server_ids.length == 1" class="value">{{ serverTooltipText(ban) }}</p>
        <p v-if="ban.server_ids.length > 1" class="value">
          <TooltipBase
            trigger="hover"
            distance="8"
            class="w-fit"
          >
            <p class="few">{{ t('bans.few') }}</p>
            <template #content>
              <div class="flex p-1.5 gap-1 text-center" style="max-width: 200px;">
                <p>{{ serverTooltipText(ban) }}</p>
              </div>
            </template>
          </TooltipBase>
        </p>
      </template>
      <Skeleton
        v-else
        class="skeleton ml-auto"
      />
    </div>

    <div v-if="ban.computed_is_active && !!ban.ban_ip" class="row">
      <p class="name">
        <Svg.world />
        {{ t('modal.ban-view.ip') }}
      </p>
      <p
        class="open"
        @click="ActivateIpDetailsModal(ban.ban_ip ?? '')"
      >
        {{ ban.ban_ip_active ? t('modal.ban-view.ip-banned') : t('modal.ban-view.ip-unbanned') }}
        <Svg.open />
      </p>
    </div>

    <div class="row">
      <p class="name">
        <Svg.user_circle />
        {{ t('modal.ban-view.moderator') }}
      </p>
      <p class="open cursor-pointer">
        <template v-if="!ban || ban.client_id">
          <ClientInline :client-id="ban?.client_id" />
        </template>
        <template v-else>
          <ClientInline :preload="useUnknownClient().generate()" />
        </template>
      </p>
    </div>

    <div v-if="checkId && _permissions.can(['Court_CheckRead'])" class="row">
      <p class="name">
        <Svg.check />
        {{ t('modal.ban-view.check') }}
      </p>
      <p
        v-if="ban.reason"
        class="open"
        :class="{'pointer-events-none': _panel.project?.id !== ban.project_id}"
        @click="_panel.project?.id === ban.project_id ? openCheck() : () => {}"
      >
        #{{ checkId }}
        <Svg.open v-if="_panel.project?.id === ban.project_id" />
      </p>
      <Skeleton
        v-else
        class="skeleton ml-auto"
      />
    </div>

    <div v-if="ban.edit_time && ban.edit_time != ban.created_at" class="row">
      <p class="name">
        <Svg.edit />
        {{ t('modal.ban-view.edit-moderator') }}
      </p>
      <p class="open cursor-pointer">
        <TooltipBase
          :trigger="'hover'"
          placement="right"
          distance="12"
        >
          <template #content>
            <div class="px-2 py-2">
              <span>
                <template v-if="!ban || ban.edit_client_id">
                  <ClientInline :client-id="ban?.edit_client_id" class="!inline" />
                </template>
                <template v-else>
                  <ClientInline :preload="useUnknownClient().generate()" class="!inline" />
                </template>
              </span>
            </div>
          </template>

          <div>
            <p>{{ _time.format(ban.edit_time, undefined, true) }}</p>
          </div>
        </TooltipBase>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.few {
  @apply hover:text-grey-50;
  @apply cursor-pointer;
  @apply underline underline-offset-2;
}
.skeleton {
  @apply w-24;
  height: 19px;
  @apply rounded-md;
}

.row {
  @apply flex items-start justify-between gap-10;

  svg {
    @apply w-[19px] h-[19px];
    @apply fill-grey-500;
  }
  .name {
    @apply flex gap-1 shrink-0;
    @apply text-grey-400;
  }

  .value {
    @apply text-end truncate items-center;
    @apply text-grey-200;
  }

  .open {
    @apply flex gap-1;
    @apply text-grey-200;
    @apply cursor-pointer;
    @apply truncate;
    
    &:hover {
      @apply text-grey-50;

      svg {
        @apply fill-grey-300;
      }
    }
  }

  p {
    line-height: 19px;
  }
}
</style>