<script setup lang="ts">
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import Loading from '@/components/Loading.vue';
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import { useRouter } from 'vue-router';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useTimeStore } from '@/stores/time.store';
  import ClientInline from '@court/components/client/ClientInline.vue';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import { useCheckUtils } from '@court/utils/check.utils';
  import { CourtRoutes } from '@court/court.routes';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import PlayerDetailsCard, { PlayerDetailsCardRow } from '../card/PlayerDetailsCard.vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Loader from '@/components/Loader.vue';
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';

  const { verdict } = useCheckUtils();

  const props = defineProps<{ steamId: string }>();
  const emits = defineEmits<ModalMenuExtendedEvents>();

  const { t } = useLocaleStore();
  const checks = ref<CheckDto[]>();

  const _time = useTimeStore();
  const _router = useRouter();
  const _panel = usePanelStore();

  const loader = new PaginationService((page, limit) => useCourtApi().player.getChecks(props.steamId, {
    page,
    limit
  }), 10);

  onMounted(() => {
    badgeUpdate(-1);
    next();
  });
  
  const centrifugoCheckStarted = ref<CentrifugoSubscribe>();
  const centrifugoCheckFinished = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoCheckStarted.value = useCentrifugoApi().subscribeCheckStarted(_panel.project.id, (check) => checkUpdate(check));
    centrifugoCheckFinished.value = useCentrifugoApi().subscribeCheckFinished(_panel.project.id, (check) => checkUpdate(check));
  });

  const lastCount = ref<number>();
  const badgeUpdate = (count: number) => {
    emits('badgeUpdate', count);

    lastCount.value = count;
  };

  const checkUpdate = (check: CheckDto) => {
    if (!checks.value) {
      return;
    }

    if (check.suspect.steam_id !== props.steamId) {
      return;
    }

    const origin = checks.value.find(v => v.id === check.id);
    if (origin) {
      Object.assign(origin, check);
      return;
    }  

    checks.value.unshift(check);

    badgeUpdate((lastCount.value ?? 0) + 1);
  };

  onUnmounted(() => {
    centrifugoCheckStarted.value?.unsubscribe();
    centrifugoCheckFinished.value?.unsubscribe();
  });
  
  const next = async () => {
    const { results, total } = await loader.next();

    if (!checks.value) {
      checks.value = [];
    }

    checks.value?.push(...results);

    if (total != null) {
      badgeUpdate(total);
    }
  };

  const openCheck = (check_id: number) => {
    _router.push({ name: CourtRoutes.Checks, params: { id: check_id.toString() }});
  };

  const lastCheck = computed(() => checks.value?.[0]);

  defineExpose({ lastCheck });

  const rows = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title : t('modal.player.checks.check.checked_by'),
        inline: false
      },
      {
        title     : t('modal.player.checks.check.verdict'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('modal.player.checks.check.comment'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template v-if="checks && checks.length == 0">
      <div class="flex flex-col justify-center items-center h-full">
        <EmptyImage
          type="empty"
          :title="t('modal.player.checks.empty.title')"
          :subtitle="t('modal.player.checks.empty.subtitle')"
        />
      </div>
    </template>
    <template v-else>
      <div class="h-max min-h-full w-full relative">
        <Loading :can-view="checks != null" class="!bg-transparent" />

        <template v-if="checks != null">
          <div class="flex flex-col gap-2.5">
            <template v-for="(check, index) in checks" :key="check.id">
              <PlayerDetailsCard
                :rows="rows" 
                :date-from="_time.format(check.created_at, 'DD.MM.YY # HH:mm', true, true)" 
              >
                <template #badges>
                  <div class="badge" :class="check.status">
                    <Svg.check_circle v-if="check.status === 'FinishedClear'" />
                    <Svg.lock v-if="check.status === 'FinishedBan'" />
                    <Svg.clear v-if="check.status === 'Canceled' || check.status === 'CanceledTimeout'" />
                    <Loader
                      v-if="check.status === 'Pending'"
                      size="16px"
                      color="#0099ff"
                      position="relative"
                    />
                  </div>
                </template>
                <template #row0>
                  <ClientInline class="staff" :client-id="check.client_id" />
                </template>
                <template #row1>
                  {{ verdict(check) }}
                </template>
                <template v-if="check.comment" #row2>
                  {{ check.comment }}
                </template> 
                <template #actions>
                  <Button
                    preset="default-light"
                    class="!p-1 !rounded"
                    :action="() => openCheck(check.id)"
                  >
                    <Svg.open class="fill-frey-50 !w-[20px]" />
                  </Button>
                </template>
              </PlayerDetailsCard>
            </template>
          </div>

          <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
        </template>
      </div>
    </template>
  </ModalMenuExtendedPageLayout>
</template>

<style lang='scss' scoped>
.badge {
  @apply rounded;
  @apply p-1;
  @apply bg-grey-900;

  svg {
    @apply w-[20px];
    @apply fill-grey-600;
  }

  &.Pending {
    @apply p-1.5;
    @apply bg-primary-700/15;

    svg {
      @apply fill-primary-700;
    }
  }

  &.FinishedClear {
    @apply bg-emerald-500/15;

    svg {
      @apply fill-emerald-500;
    }
  }

  &.FinishedBan {
    @apply bg-rose-500/15;

    svg {
      @apply fill-rose-500;
    }
  }
}

.staff {
  @apply cursor-pointer;
  
  :deep(.nickname) {
    @apply leading-tight;
    @apply text-primary-700 font-semibold;

    &:hover {
      @apply text-primary-500;
    }
  }
}
</style>