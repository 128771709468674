<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import PlayerInline from '@court/components/player/PlayerInline.vue';
  import BanTimePicker from '../components/BanTimePicker.vue';
  import { BanDataStorage } from '../stores/ban.storage';
  import BanReasonPicker from '../components/BanReasonPicker.vue';
  import { watch } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const suggester = async (text: string) => {
    if (!text) {
      return [];
    }

    const { results } = await useCourtApi().player.browseFull({
      search : text,
      offline: true,
      limit  : 5,
      page   : 0,
    });

    return results.filter(v => v.steam_id != storage.value.target.steamId);
  };

  const storage = defineModel<BanDataStorage>({ required: true });

  watch(() => storage.value.team.players, () => 
    storage.value?.updater?.(), { deep: true });
</script>

<template>
  <div class="flex flex-col gap-5">
    <div>
      <p class="label">{{ t('modal.ban.mass.team.label', { cur: storage.team.players.length, max: 10 }) }}</p>
      <SuggesterInput
        v-model="storage.team.players"
        class="select-players !p-1.5"
        :suggester="suggester"
        :max="10"
        :placeholder="t('modal.ban.mass.team.placeholder')"
      >
        <template #default="{value}">
          <PlayerInline
            v-bind="value" 
            type="small" 
            disable-click
            class="ban-player"
          />
        </template>
        <template #suggest="{ value }">
          <div class="w-full">
            <PlayerInline
              v-bind="value" 
              type="small" 
              disable-click
              class="suggested-player"
            />
          </div>
        </template>
      </SuggesterInput>
    </div>

    <BanReasonPicker
      v-model="storage.team.reason"
      :steam_id="storage.target.steamId"
      :steam_name="storage.target.player?.steam_name || 'unknown'"
    />

    <div>
      <BanTimePicker 
        v-model="storage.team.expired"
        :edit="!!storage.ban"
        :update="() => storage.updater?.()"
      />
    </div>

    <SwitchText
      v-model="storage.team.ip_active"
      :header="t('modal.ban.mass.ip.title')"
    />
  </div>
</template>

<style lang='scss' scoped>
.ban-player {
  @apply text-white;
  @apply p-1;
  border-radius: 4px;
  line-height: normal;

  &:hover {
    @apply line-through;
  }
  &:deep(span) {
    line-height: normal;
  }
}
.suggested-player {
  @apply text-white w-full overflow-hidden;
  @apply truncate;
  @apply rounded-md;
  @apply p-1.5;
}

:deep(.name) {
  @apply leading-5 #{!important};
}

.select-players {
  ::placeholder {
    @apply pl-0.5;
  }
}
</style>