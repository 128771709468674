<script setup lang='ts'>
  import { CheckActivityMetaFinishedDto } from '@/api/backend/court/check/check.dto';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    meta: CheckActivityMetaFinishedDto, 
    reversed: boolean,
    created: number,
  }>();

  const subtitle = computed(() => {
    switch (props.meta.status) {
      case 'Canceled': {
        return t('check.activity.finished.subtitle.canceled');
      }
      case 'CanceledTimeout': {
        return t('check.activity.finished.subtitle.canceled-timeout');
      }
      case 'FinishedBan': {
        return t('check.activity.finished.subtitle.finished-ban');
      }
      case 'FinishedClear': {
        return t('check.activity.finished.subtitle.finished-clear');
      }
      default: {
        return t('check.activity.finished.subtitle.unknown');
      }
    }
  });
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :title="t('check.activity.finished.title')"
    :subtitle="subtitle"
    :created="created"
    circle-class="bg-lime-400"
  />
</template>

<style lang='scss' scoped>

</style>