<script setup lang="ts">
  import Skeleton from '@/components/Skeleton.vue';
  import AssetImage from '@/components/quality/AssetImage.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { AssetImageTypes } from '@src/assets/auto_gen_types.dto';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const _adaptive = useAdaptiveStore();

  defineProps<{ 
    url?: string;
    icon?: AssetImageTypes;
    date?: number;

    ready?: boolean;
  }>();

  const _time = useTimeStore();
</script>

<template>
  <div class="flex items-center gap-4 w-full">
    <template v-if="(icon || url) && ready">
      <div class="icon">
        <AssetImage
          v-if="icon"
          :icon="icon"
          class="w-8 h-8"
        />

        <img
          v-else-if="url"
          :src="url"
          class="w-6 h-6 rounded-md"
        >
      </div>
    </template>
    <template v-else>
      <Skeleton
        style="height: 40px; width: 40px"
        class="w-full rounded-md shrink-0"
      />
    </template>

    <div class="w-full" :style="ready ? 'width: 100%' : _adaptive.isMobile ? 'width: 100%' : 'width: 45%'">
      <p class="row">
        <template v-if="ready">
          <slot />
        </template>
        <template v-else>
          <Skeleton
            style="height: 18px;"
            :style="`width: ${Math.random() * (70 - 90) + 70}%`" 
            class="alert-skeleton rounded-md"
          />
        </template>
      </p>

      <template v-if="date && ready">
        <span class="date" :title="_time.format(date, 'DD MMM YYYY HH:mm:ss', true, true)">{{ _time.fromNow(date, false, true) }}</span>

        <template v-if="$slots.date">
          <span class="date mx-2">•</span>
        </template>

        <slot name="date" />
      </template>
      <template v-else>
        <Skeleton
          style="height: 14px; width: 110px;"
          class="rounded-md mt-2"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  height: 40px;
  width: 40px;
  @apply bg-grey-950;
  @apply border-2 border-dashed border-grey-900;
  @apply rounded-md;
  @apply shrink-0;
  @apply mb-auto;
  @apply flex items-center justify-center;
}

.date {
  @apply text-12-400 sm:text-14-400;
  @apply text-grey-600;
}

.row {
  @apply gap-1;
  @apply leading-5;

  :slotted(span) {
    @apply text-grey-400;
    @apply text-14-400 sm:text-16-400;
    @apply mr-1;

    &.bright {
      @apply text-grey-200;
      @apply font-medium;

      &:hover {
        @apply text-grey-50;
      }
    }
  }
}
</style>