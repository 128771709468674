<script setup lang="ts">
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtStore } from '@court/stores/court.store';
  import SettingsNumeric from '@/components/inputs/numeric-input/SettingsNumeric.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';

  const { t } = useLocaleStore();
  const _court = useCourtStore();

</script>

<template>
  <div class="section">
    <p class="text-head">
      {{ t('general.common-parameters') }}
    </p> 
    <div v-if="_court.court?.options" class="section">
      <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
        <SettingsNumeric
          v-model="_court.court.options.check_start_min_report"
          :min="0"
          :max="15"
          :header="t('court-settings-min-reports-to-start-check')"
          :placeholder="_court.court.options.check_start_min_report ? t('check-start-min-reports', { N: _court.court.options.check_start_min_report }) : t('check-start-min-reports-none')"
        />
        <SwitchText
          v-model="_court.court.options.check_start_broadcast"
          :header="t('broadcast-check-started-title')"
          :placeholder="t('broadcast-check-started-subtitle')"
        />
        <SwitchText
          v-model="_court.court.options.check_finished_clear_broadcast"
          :header="t('broadcast-check-finished-clear-title')"
          :placeholder="t('broadcast-check-finished-clear-subtitle')"
        />
        <SwitchText
          v-model="_court.court.options.announce_reporters_after_action"
          :header="t('announce-reporters-after-actions-title')"
          :placeholder="t('announce-reporters-after-actions-subtitle')"
        />
      </SettingsContainer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  @apply flex justify-end;
}

.section {
  @apply flex flex-col gap-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }
}

.btn-unban {
  @apply h-fit mr-1.5 flex-shrink-0 p-0;
  @apply text-sm text-red-500 hover:text-red-400;
}
</style>