<script setup lang='ts'>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import ChecksPageBrowse from './ChecksPageBrowse.vue';
  import ChecksPageExact from './ChecksPageExact.vue';

  const _route = useRoute();

  const checkId = computed(() => _route.params.id);
</script>

<template>
  <template v-if="checkId">
    <ChecksPageExact :id="+checkId" />
  </template>

  <ChecksPageBrowse v-show="!checkId" />
</template>