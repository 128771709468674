<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { PlayerIpDto, PlayerSearchIpDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import Button from '@/components/Button.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type IpDetailsModalOpts = {
    ip: string;
    optIpDetails?: PlayerIpDto;
  };

  export const ActivateIpDetailsModal = async (ip: string, optIpDetails?: PlayerIpDto, virtual = false) => {
    const _modal = useModalsStore();

    _modal.open('player-ip', { ip, optIpDetails }, virtual);
  };
</script>

<script setup lang='ts'>
  import { onMounted, ref } from 'vue';

  const props = defineProps<IpDetailsModalOpts & ModalInjectedProps>();

  const { t } = useLocaleStore();

  onMounted(() => load());

  const details = ref<Partial<PlayerSearchIpDto>>({});

  const load = async () => {
    if (props.optIpDetails) {
      details.value.ip_details = props.optIpDetails;
    }

    try {
      const result = await useCourtApi().player.findByIpBatchLoader.load(props.ip);
      if (!result || (result.players.length == 0 && !result.ip_details)) {
        throw new Error(`Unknown IP`);
      }

      details.value = result;
    }
    catch (err: any) {
      if (err.message === 'Unknown IP') {
        props.injected.setBeforeClose(async () => {
          await ActivateConfirmModal({
            type       : 'error',
            title      : t('ip_details.unknown-ip-title'),
            description: t('ip_details.unknown-ip-subtitle'),
          });

          return true;
        });

        props.injected.close();       

        return;
      }

      throw err;
    }
  };
 
</script>

<template>
  <div class="ip-details-modal">
    <DividedCard>
      <template #header>
        <div class="flex justify-between items-center w-full">
          <p>{{ t('ip_details.header', { ip }) }}</p>
          <Svg.copy class="copy" @click="useCopyClipboard(ip, true)" />
        </div>
      </template>

      <template #default>
        <div class="content">
          <div class="rows">
            <div class="row">
              <div class="name">
                <Svg.earth />
                <p class="text">{{ t('ip_details.country') }}</p>
              </div>

              <div v-if="details.ip_details?.country_name" class="value flex items-center gap-1">
                <img
                  v-if="details.ip_details.country_code"
                  :style="`height: ${14}px; width: ${14}px;`"
                  :src="`https://hatscripts.github.io/circle-flags/flags/${details.ip_details.country_code?.toLowerCase()}.svg`"
                >
                <span>
                  {{ details.ip_details.country_name ?? 'N/A' }}
                </span>
              </div>
              <Skeleton
                v-else 
                class="skelet ml-auto"
              />
            </div>
            <div class="row">
              <div class="name">
                <Svg.route />
                <p class="text">{{ t('ip_details.city') }}</p>
              </div>

              <p v-if="details.ip_details?.country_name" class="value">{{ details.ip_details.city }}</p>
              <Skeleton
                v-else 
                class="skelet ml-auto"
              />
            </div>
            <div class="row">
              <div class="name">
                <Svg.signal />
                <p class="text">{{ t('ip_details.provider') }}</p>
              </div>

              <div v-if="details.ip_details?.country_name" class="flex gap-1">
                <p class="value" :title="details.ip_details.provider">{{ details.ip_details.provider }}</p>
                <TooltipBase v-if="details.ip_details.provider == 'NVIDIA Ltd'" trigger="hover"> 
                  <Svg.circle_info class="!fill-amber-400" />
       
                  <template #content> 
                    <div class="px-1.5 py-1 text-xs text-center max-w-[240px]"> 
                      <p>{{ t('ip_details.nvidia') }}</p> 
                    </div> 
                  </template> 
                </TooltipBase>
              </div>
              
              <Skeleton
                v-else 
                class="skelet ml-auto"
              />
            </div>
            <div class="row">
              <div class="name">
                <Svg.shield />
                <p class="text">{{ t('ip_details.proxy') }}</p>
              </div>

              <p v-if="details.ip_details?.country_name" class="value">{{ details.ip_details.proxy === undefined ? t('general.unknown') : details.ip_details.proxy ? t('general.yes') : t('general.no') }}</p>
              <Skeleton
                v-else 
                class="skelet ml-auto"
              />
            </div>
          </div>

          <div class="players">
            <p class="text-white text-16-500">{{ t('ip_details.players-with-same-ip') }}</p>
            <div class="items-row">
              <template v-if="!details.players">
                <PlayerBox
                  class="item"
                  :player="null"
                  disable-hover
                />
              </template>
              <template v-else-if="details.players.length == 0">
                <EmptyImage
                  :title="t('ip_details.empty.title')"
                  :subtitle="t('ip_details.empty.subtitle')"
                  class="empty"
                />
              </template>
              <template v-else>
                <template v-for="value in details.players" :key="value.steam_id">
                  <div class="item" @click.prevent="() => ActivatePlayerDetailsModal(value.steam_id, value)">
                    <PlayerBox
                      :player="value"
                      disable-hover
                    />
                    <Svg.open class="icon" />
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-2.5">
          <Button
            :text="t('general.close')"
            preset="default"
            :action="() => { injected.close() }"
          />
          <a
            :href="`https://proxycheck.io/threats/${ip}`"
            target="_blank"
          >
            <Button
              :text="t('ip_details.button.more-info')"
              preset="default"
              :action="() => { }"
            />
          </a>
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang='scss' scoped>

.empty {
  @apply p-5;
  @apply bg-grey-850;
}

.ip-details-modal {
  width: 95vw;
  max-width: 400px;
}

.content {
  @apply flex flex-col gap-4;
  .rows {
    @apply flex flex-col gap-2.5;
    .row {
      @apply flex items-center gap-5 justify-between;
      @apply w-full;

      svg {
        width: 19px;
        height: 19px;
        @apply fill-grey-500;
      }
      .name {
        @apply flex gap-1;

        .text {
          @apply truncate;
          @apply text-grey-400;
          line-height: 19px;
        }
      }
      .value {
        line-height: 19px;
        margin-left: auto;
        max-width: 200px;
        @apply text-grey-200;
        @apply truncate;
      }
    }
  }
  .players {
    @apply flex flex-col gap-2;
  }
}

.copy {
  @apply w-5;
  @apply fill-grey-700;
  @apply cursor-pointer select-none;

  &:hover {
    @apply fill-grey-600;
  }

  &:active {
    @apply fill-grey-500;
  }
}

.skelet {
  @apply w-24;
  height: 19px;
  @apply rounded-md;
}

:deep(.lines) {
  .title {
    .skeleton {
      width: 50% !important;
    }
  }
  .message {
    .skeleton {
      width: 30% !important;
    }
  }
}
</style>