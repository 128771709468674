<template>
  <div class="image">
    <div class="item opacity-100">
      <div class="avatar" />
      <div class="text">
        <div class="name h-3.5 w-28" />
        <div class="status w-20 h-2.5" />
      </div>
    </div>
    <div class="item opacity-[.60]">
      <div class="avatar" />
      <div class="text">
        <div class="name h-3.5 w-36" />
        <div class="status w-24 h-2.5" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image {
  @apply rounded-md overflow-hidden;
  .item {
    @apply flex flex-row gap-2;
    @apply p-2.5;
    @apply bg-grey-850;
    width: 240px;
    height: 52px;
    .avatar {
      @apply aspect-square h-8 rounded-full;
      @apply bg-grey-700 animate-pulse;
    }
    .text {
      @apply flex flex-col justify-between w-full h-full;
      @apply animate-pulse;
      .name {
        @apply bg-grey-700 rounded-md;
      }
      .status {
        @apply bg-grey-750 rounded-md;
      }
    }
  }
}
</style>