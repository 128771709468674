s<script setup lang='ts'>
  import ImagesGrid from '@/components/ImagesGrid.vue';
  import { useLocaleStore } from '@/stores/locale.store';

  defineProps<{
    proofs: string[]
  }>();

  const { t } = useLocaleStore();
</script>

<template>
  <div class="container">
    <div class="flex justify-between">
      <p class="title">{{ t('check.verdict.details.proofs') }}</p>
      <p class="subtitle">{{ t('general.amount-st', { amount: proofs.length}) }}</p>
    </div>
    <ImagesGrid :images="proofs" />
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply flex flex-col gap-2.5;

  .title {
    @apply text-grey-50;
    @apply font-medium;
  }
  .subtitle {
    @apply text-grey-400;
  }
}
</style>