import { PaginatedResponse } from "@/api/backend/pagination.service";

import { ChatSearchOpts, ChatMessageDto, ChatSendOpts } from "./chat.dto";
import { Axios } from "axios";

export const useCourtChatApi = (api: Axios) => {
  const browse = async(opts: ChatSearchOpts): Promise<PaginatedResponse<ChatMessageDto>> => {
    const results = await api.get<PaginatedResponse<ChatMessageDto>>("/chat/browse", {
      params: {
        ...opts
      },
    });

    return results.data;
  };

  const send = async(opts: ChatSendOpts): Promise<number[]> => {
    const results = await api.post<number[]>("/chat", opts);

    return results.data;
  };

  return { browse, send };
};
