<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { PlayerSearchIpDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed } from 'vue';

  const props = defineProps<{
    ip: string,
    // Оч. сложно, данные    грузим  ошибка   ещё не грузили
    data: PlayerSearchIpDto | null | false | undefined,

    // решил ли банить
    active: boolean
  }>();

  const { t } = useLocaleStore();

  const isProcessing = computed(() => props.data !== undefined);
  const isLoading = computed(() => props.data === null);

  const isErrored = computed(() => props.data === false);

  const readyData = computed(() => {
    if (!props.data || !('players' in props.data)) {
      return null;
    }

    return props.data as PlayerSearchIpDto;
  });

  const test = () => {
    ActivateIpDetailsModal(props.ip, readyData.value?.ip_details, true);
  };
</script>

<template>
  <div v-if="isProcessing" class="ip relative w-[20px]">
    <template v-if="isLoading">
      <Loading :can-view="false" size="15px" />
    </template>
    <template v-else-if="isErrored">
      <Svg.warning class="h-4 w-4 fill-red-500" />
    </template>
    <template v-else-if="readyData">
      <template v-if="readyData.players.length > 1">
        <TooltipBase
          trigger="hover"
          placement="top"
        >
          <div @click.stop.prevent="test">
            <Svg.warning_circle v-if="active" class="h-4 w-4 cursor-pointer fill-yellow-500" />
          </div>
          <template #content>
            <div class="px-2 py-2" style="max-width: 250px;">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="text-12-400" v-html="t('modal.ban.ip-warning', { amount: readyData.players.length - 1 })" />
            </div>
          </template>
        </TooltipBase>
      </template>
    </template>
  </div>
</template>