<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { PlayerAlertCustomApiMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { computed } from 'vue';
  import JsonViewer from '@/components/json-viewer/JsonViewer.vue';
  import Button from '@/components/Button.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { isURL } from 'class-validator';
  import { AssetImageTypes } from '@src/assets/auto_gen_types.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<{
    meta: PlayerAlertCustomApiMetaDto;
    date: number;
  }>();

  const { t } = useLocaleStore();
  const { generate } = useUnknownPlayer();

  const players = ref<PlayerMinimalDto[]>();

  onMounted(async () => {
    if (!steamIds.value) {
      players.value = [];
      return;
    }

    const result = await useCourtApi().player.browseMinimalBatchLoader.loadMany(steamIds.value);

    players.value = result.filter(v => 'steam_id' in (v ?? {})) as PlayerMinimalDto[];
  });

  const openPlayer = (id: string) => {
    ActivatePlayerDetailsModal(id);
  };

  const steamIds = computed(() => {
    return props.meta.msg.match(/\d{17}/g);
  });

  const getPlayer = (steamId: string) => {
    const exists = players.value?.find(v => v.steam_id === steamId);
    if (exists) {
      return exists;
    }

    return generate(steamId);
  };

  const splitted = computed(() => {
    const regex = /\d{17}/g;
    const numbers = props.meta.msg.match(regex);
    if (!numbers) {
      return [props.meta.msg];
    }
    
    const parts = props.meta.msg.split(regex);
    const finalArray = [];
    for (let i = 0; i < parts.length; i++) {
      finalArray.push(parts[i]);
      if (numbers[i]) {
        finalArray.push(numbers[i]);
      }
    }
    return finalArray;
  });

  const showData = ref<boolean>(false);

  const getImage = (): { url?: string; icon?: AssetImageTypes } => {
    if (props.meta.custom_icon) {
      if (isURL(props.meta.custom_icon)) {
        return {
          url: props.meta.custom_icon
        };
      } else {
        return {
          icon: 'player-alert-types/codelock.png'
        };
      }
    }

    return {
      icon: 'player-alert-types/custom.png'
    };
  };
</script>

<template>
  <PlayerAlertBase
    :url="getImage().url"
    :icon="getImage().icon"
    :date="date"
    :ready="!!players"
  >
    <template v-if="!meta.steam_ids || !!players">
      <template v-for="(chunk, _) in splitted" :key="chunk + _">
        <template v-if="chunk.trim().match(/\d{17}/)">
          <span 
            class="bright cursor-pointer" 
            @click="openPlayer(chunk.trim())"
          >{{ getPlayer(chunk.trim()).steam_name }}</span>
        </template>
        <template v-else-if="chunk.trim().length > 0">
          <span>{{ chunk.trim() }}</span>
        </template>
      </template>

      <JsonViewer
        v-if="meta.data && showData"
        :value="meta.data"
        preview-mode
        class="json"
      />
    </template>

    <template v-if="!showData && meta.data" #date>
      <Button
        preset="link"
        class="text-12-400 sm:text-14-400 font-base !inline"
        @click="showData = true"
      >
        {{ t('show-data-extended') }}
      </Button>
    </template>
  </PlayerAlertBase>
</template>

<style lang="scss" scoped>
.json {
  @apply bg-grey-900 rounded-md p-2 w-fit mt-2;
}
</style>