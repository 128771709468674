<script setup lang="ts">
  import NumericInput from './NumericInput.vue';
  const modelValue = defineModel<number>({required: true });

  defineProps<{
    header: string,
    placeholder?: string,
    disabled?: boolean,
    min?: number,
    max?: number
  }>();
</script>

<template>
  <div class="numeric-wrapper" :class="{ 'opacity-40': disabled }">
    <div class="numeric-text">
      <span class="title">{{ header }}</span>
      <span class="subtitle">{{ placeholder }}</span>
    </div>
    <NumericInput
      v-model="modelValue"
      :disabled="disabled"
      input-height="24px"
      :min="min"
      :max="max"
      center
    />
  </div>
</template>

<style lang="scss" scoped>
.numeric-wrapper {
  @apply flex justify-between gap-2.5 items-center;
  @apply select-none;
  .numeric-text {
    @apply flex flex-col;

    .title {
      @apply text-grey-50;
    }
    .subtitle {
      @apply text-grey-500 text-sm leading-tight;
    }
  }
}
</style>