<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import LayoutPage from './layout/LayoutPage.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  
  const { t } = useLocaleStore();
</script>

<template>
  <LayoutPage header="Назад">
    <EmptyImage
      type="empty"
      :title="t('404.header')"
      :subtitle="t('404.text')"
      class="page-error"
    />
  </LayoutPage>
</template>

<style lang="scss" scoped>
.page-error {
  @apply w-full h-full flex flex-col justify-center items-center;
  @apply text-center;
}
</style>
