export const SignageMock = [
    'https://r2.rustapp.io/plugin-signs/1716831485244-b38dd64236b50e20dcff373c.png',
    'https://r2.rustapp.io/plugin-signs/1716831443291-a8791e321ee3ca11876900f2.png',
    'https://r2.rustapp.io/plugin-signs/1716831409183-499e0cfcdc1dfbcd5d77f9fc.png',
    'https://r2.rustapp.io/plugin-signs/1716822646310-39fc5c4dfacea94e49b0855c.png',
    'https://r2.rustapp.io/plugin-signs/1716818356724-7ec08e71a84c57851efe9c61.png',
    'https://r2.rustapp.io/plugin-signs/1716812335999-9575295a808e1d0e69520311.png',
    'https://r2.rustapp.io/plugin-signs/1716809868099-967e82e34948bbc1d31d1a40.png',
    'https://r2.rustapp.io/plugin-signs/1716724600689-27844800a621e652d56655c3.png',
    'https://r2.rustapp.io/plugin-signs/1716743203321-5b142442597edaae980702b3.png',
    'https://r2.rustapp.io/plugin-signs/1716832079431-622fec951d8a70ce981bfdbd.png',
    'https://r2.rustapp.io/plugin-signs/1716830540845-cf0d7dfe3bc642ddacc153f8.png',
    'https://r2.rustapp.io/plugin-signs/1716826163744-e704975782c1e6ebf9b1f945.png'
];