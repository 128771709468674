import { defineStore } from 'pinia';
import { usePanelStore } from './panel.store';
import { computed } from 'vue';
import { PermissionsDictionary } from '@/api/backend/core/project/project.dto';
import { useAppStore } from '@/stores/app.store';

export const usePermissionsStore = defineStore('permissions', () => {
  const _app = useAppStore();
  const _panel = usePanelStore();

  const clientPermissions = computed(() => {
    if (!_panel.project) {
      return undefined;
    }
    
    return { permissions: _panel.project.permissions, is_immutable: _panel.project.is_immutable };
  });

  const can = (permission: (keyof PermissionsDictionary)[]) => {
    if (!_app.client || !_panel.project) {
      return false;
    }

    if (!clientPermissions.value) {
      return false;
    }

    return permission.some(v => clientPermissions.value?.permissions.includes(v)) || isAdminOrOwner();
  };

  const isAdminOrOwner = () => {
    if (!_app.client || !_panel.project || !clientPermissions.value) {
      return false;
    }

    return _app.client.id === _panel.project.owner_client_id || clientPermissions.value?.is_immutable;
  };

  const isOwner = () => {
    if (!_app.client || !_panel.project || !clientPermissions.value) {
      return false;
    }

    return _app.client.id === _panel.project.owner_client_id;
  };

  return {
    clientPermissions,
    can,
    isAdminOrOwner,
    isOwner
  };
});
