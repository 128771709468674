<script setup lang='ts'>
  import { CheckActivityMetaRccCall } from '@/api/backend/court/check/check.dto';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    meta: CheckActivityMetaRccCall, 
    reversed: boolean,
    created: number,
  }>();

  const subtitle = computed(() => {
    switch (props.meta.status) {
      case 'success': {
        return t('check.activity.rcc-call.subtitle.succes');
      }
      case 'already': {
        return t('check.activity.rcc-call.subtitle.already');
      }
      case 'error': {
        return t('check.activity.rcc-call.subtitle.error');
      }
    }

    return t('general.unknown');
  });

  const color = computed(() => {
    switch (props.meta.status) {
      case 'success': {
        return 'bg-grey-1000 !border-primary-600';
      }
      case 'already': {
        return 'bg-grey-1000 !border-amber-400 border-dotted';
      }
      case 'error': {
        return 'bg-red-500';
      }
    }

    return 'bg-red-500';
  });
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :title="t('check.activity.rcc-call.title')"
    :subtitle="subtitle"
    :created="created"
    :circle-class="color"
  />
</template>

<style lang='scss' scoped>

</style>