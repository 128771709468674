<script lang="ts">
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import Button from './Button.vue';

  export type RuntimeNotificationType = 'copy' | 'info' | 'success' | 'warning' | 'error' | 'alert';

  export type RuntimeNotificationOpts = {
    overrideTitle?: string;
    overrideIcon?: Component;
    overrideIconColor?: string;
    overrideTime?: number;
  }

  export type RuntimeNotificationEntry = {
    id: number,
    type: RuntimeNotificationType;

    text: string;
    data?: RuntimeNotificationOpts;

    onClick?: RuntimeNotitificationEntryClickOpts;
  };

  export type RuntimeNotitificationEntryClickOpts = {
    text: string;
    action: () => void; 
  }

  const items = ref<RuntimeNotificationEntry[]>([]);
  
  export const useRuntimeNotification = (type: RuntimeNotificationType, message: string, opts?: RuntimeNotificationOpts, onClick?: RuntimeNotitificationEntryClickOpts) => {
    const id = Math.random();
    
    items.value.unshift({
      id: id,
      type,

      text: message,
      data: opts,

      onClick
    });
    
    if (items.value.length > 4) {
      items.value.length = 4;
    }
    
    setTimeout(() => closeNotification(id), opts?.overrideTime ?? 4_000);
  };

  const closeNotification = (id: number) => {
    const indexOf = items.value.findIndex(v => v.id === id);
    if (indexOf >= 0) {
      items.value.splice(indexOf, 1);
    }
  };
</script>

<script setup lang="ts">
  import { ref, type Component } from 'vue';

  const { t } = useLocaleStore();

  const getIcon = (entry: RuntimeNotificationEntry) => {
    if (entry.data?.overrideIcon) {
      return entry.data.overrideIcon;
    }

    switch (entry.type) {
      case 'copy': {
        return Svg.copy;
      }
      case 'info': {
        return Svg.circle_info;
      }
      case 'warning': {
        return Svg.warning;
      }
      case 'error': {
        return Svg.warning_circle;
      }
      case 'success': {
        return Svg.check_circle;
      }
      case 'alert': {
        return Svg.alert;
      }

      default: {
        return Svg.warning;
      }
    }
  };

  const getColor = (entry: RuntimeNotificationEntry) => {
    if (entry.data?.overrideIconColor) {
      return entry.data.overrideIconColor;
    }

    switch (entry.type) {
      case 'warning': {
        return 'fill-orange-300';
      }
      case 'error': {
        return 'fill-red-500';
      }
      case 'success': {
        return 'fill-lime-500';
      }
      case 'alert': {
        return 'fill-primary-500';
      }

      default: {
        return 'fill-grey-200';
      }
    }
  };

  const getTitle = (entry: RuntimeNotificationEntry) => {
    if (entry.data?.overrideTitle) {
      return entry.data.overrideTitle;
    }

    switch (entry.type) {
      // Тут можно для других типов другие заголовки подставлять
      case 'error': {
        return t('general.error');
      }
      default: {
        return '';
      }
    }
  };
</script>

<template>
  <div class="fixed runtime-notification-wrapper pointer-events-none">
    <TransitionGroup name="list">
      <template v-for="props in items" :key="props.id">
        <div
          class="notification pointer-events-auto cursor-pointer"
          @click="closeNotification(props.id)"
        >
          <div>
            <component
              :is="getIcon(props)"
              class="w-5"
              :class="getColor(props)"
            />
          </div>
          <div class="flex flex-col w-full">
            <p class="text-grey-50 text-sm font-semibold custom-break">
              {{ getTitle(props) }}
            </p>
            <p class="text-grey-200 text-sm custom-break">
              {{ props.text }}
            </p>

            <Button
              v-if="props.onClick"
              class="btn"
              :action="() => props.onClick?.action()"
            >
              {{ props.onClick.text }}
            </Button>
          </div>
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>

<style lang="scss">
</style>

<style lang="scss" scoped>
.btn {
  @apply mt-2 py-1 px-2 w-fit;
  @apply rounded;
  @apply bg-grey-700;
  @apply text-xs font-light text-grey-50;

  &:hover {
    @apply bg-grey-650;
  }
}

.runtime-notification-wrapper {
  @apply fixed flex flex-col-reverse pl-4 pr-4 pb-4 z-50 gap-4;

  height: 400px;
  width: 340px;

  bottom: 0;
  right: 0;
}

[device="mobile"] {
  .runtime-notification-wrapper {
    width: 100% !important;
  }
}

.custom-break {
  overflow-wrap: break-word;
  word-break: break-word;
}

.notification {
  @apply p-3 flex gap-2;
  @apply rounded-lg;
  @apply bg-grey-800;
  @apply border border-grey-50/5;
  @apply shadow-xl;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(340px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  transform: translateX(340px);
}
</style>
