<script setup lang='ts'>
  import { CheckActivityMetaMessageFromPlayerDto, CheckDto } from '@/api/backend/court/check/check.dto';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { CourtRoutes } from '@court/court.routes';
  import { useRouter } from 'vue-router';
  import { ampli } from '@src/ampli';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';
import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();
  const _router = useRouter();
  const _config = useConfigStore();

  const props = defineProps<{ 
    check: CheckDto | null,
    meta: CheckActivityMetaMessageFromPlayerDto, 
    reversed: boolean,
    created: number,
  }>();

  const player = ref<PlayerMinimalDto>();

  onMounted(async () => {
    if (props.meta.steam_id && props.meta.steam_id !== props.check?.suspect.steam_id) {
      const response = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.steam_id);

      player.value = response;
    }
  });

  const steamName = computed(() => {
    if (!props.check || (props.meta.steam_id !== props.check?.suspect.steam_id && !player.value)) {
      return t('check.activity.message.suspect');
    }

    if (player.value) {
      return player.value.steam_name;
    }

    return props.check.suspect.steam_name;
  });

  const steamAvatar = computed(() => {
    if (!props.check || (props.meta.steam_id !== props.check?.suspect.steam_id && !player.value)) {
      return _config.Branding.RustAppAvatar;
    }

    if (player.value) {
      return player.value.steam_avatar;
    }

    return props.check.suspect.steam_avatar;
  });

  const sameChecks = ref<CheckDto[]>();

  onMounted(() => load());

  const load = async () => {
    if (!props.meta.is_contact) {
      return;
    }

    const response = await useCourtApi().check.browse({ contact: props.meta.message, page: 0, limit: 5 });

    sameChecks.value = response.results.filter(v => v.id !== props.check?.id);

    if (sameChecks.value.length) {
      ampli.clientCheckDiscordSeen({ check_contact: props.meta.message });
    }
  };

  const openCheck = (sameCheck_id: number) => {
    _router.push({ name: CourtRoutes.Checks, params: { id: sameCheck_id.toString() }});
  };
</script>

<template>
  <template v-if="meta.is_contact">
    <CheckActivityBase
      :reversed="reversed"
      :created="created"
      circle-class="bg-grey-1000 !border-ra-discord"
    >
      <template #content>
        <div>
          <p class="title">{{ t('check.activity.message.discord') }}</p>
          <div class="subtitle action cursor-pointer" @click="useCopyClipboard(meta.message, true)">
            <p class="truncate" style="max-width: 500px">{{ meta.message }}</p>
            <Svg.copy />
          </div> 

          <template v-if="sameChecks && sameChecks.length > 0">
            <div class="match">
              <div class="p-2">
                <p class="text-amber-400 text-sm">{{ t('check.activity.message.match') }}</p>
              </div>
              <template v-for="sameCheck in sameChecks" :key="sameCheck.id">
                <PlayerBox
                  :player="sameCheck.suspect"
                  :subtext="t('check.activity.message.discord-match', { check: sameCheck.id })"
                  disable-click
                  :custom-click="() => openCheck(sameCheck.id)"
                  class="player-box"
                  style="max-width: 200px;"
                />
              </template>
            </div>
          </template>
        </div>
      </template>
    </CheckActivityBase>
  </template>
  <template v-else-if="(props.meta.steam_id == check?.suspect.steam_id || !!player) && !meta.target_steam_id">
    <CheckActivityBase
      :reversed="reversed"
      :title="steamName"
      :created="created"
      :subtitle="meta.message"
      circle-class="bg-grey-1000 !border-ra-offline"
    >
      <template #circle>
        <img :src="steamAvatar" class="rounded-half aspect-square object-cover">
      </template>

      <template #content>
        <div class="user-message" :class="{'team': meta.is_team_chat, 'other-player': meta.steam_id !== check?.suspect.steam_id}">
          <p class="name">
            <a v-if="meta.is_team_chat" class="text-lime-400">{{ '[TEAM]' }}</a>
            {{ steamName }}
          </p>
          <span class="message">{{ meta.message }}</span>
        </div>
      </template>
    </CheckActivityBase>
  </template>
</template>

<style lang='scss' scoped>
.user-message {
  @apply w-fit max-w-xl min-w-[100px];
  @apply py-2 px-2.5 rounded-md;
  @apply bg-grey-900;

  .name {
    @apply text-grey-50 font-medium;
  }
  .message {
    @apply text-grey-400 text-sm;
    word-break: break-word;
  }
}

.title {
  @apply text-grey-50;
  @apply text-lg;
  @apply font-medium;
}

.subtitle {
  @apply flex gap-1 items-center w-fit;
  @apply text-grey-400;

  .player {
    @apply text-ra-yellow cursor-pointer;
    
    &:hover {
      @apply underline underline-offset-4;
    }
  }

  svg {
    @apply fill-grey-400 shrink-0;
    height: 18px;
    width: 18px;
  }

  &.action:hover {
    @apply text-grey-200;
    @apply select-none;

    svg {
      @apply fill-grey-200;
    }
  }
}

.match {
  @apply w-fit mt-4 divide-y rounded-md overflow-hidden;
  @apply bg-amber-400/10 divide-amber-400/5;

  .player-box {
    @apply p-2 w-full;
    :deep(.lines) {
      .title {
        @apply text-amber-400;
      }
      .message {
        @apply text-amber-400/50;
      }
    }
  }
}
</style>