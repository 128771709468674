<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { CourtApiDto } from '@/api/backend/court/court-api/court-api.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateCourtPlanModal } from '@court/modals/plan/CourtPlanModal.vue';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { onMounted, watch } from 'vue';
  import { ref } from 'vue';
  import Divider from '@/components/Divider.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();
  const _subscription = useCourtSubscriptionStore();

  const data = ref<CourtApiDto>({
    api_key                    : '',
    api_consumers              : [''],
    api_enabled                : false,
    public_api_key             : '',
    public_api_routes          : {},
    public_api_routes_available: ['/public/checks', '/public/bans', '/public/servers']
  });
  
  const pseudoInput = ref<string>('••••••••••••••••••••••••••••••••••••••');

  let loaded = false;

  onMounted(async () => {
    data.value = await useCourtApi().api.get();
  
    data.value.public_api_routes_available.forEach(v => {
      data.value.public_api_routes[v] = data.value.public_api_routes[v] ?? false;
    });

    setTimeout(() => {
      loaded = true;
    }, 250);
  });

  watch(() => data.value.public_api_routes, async (v) => {
    if (!loaded) {
      return; 
    }

    await useCourtApi().api.toggleRoutes(Object.entries(v).filter(([key, value]) => value).map(v => v[0]));
  }, { deep: true });

  const toggle = async () => {
    await useCourtApi().api.toggle(!data.value.api_enabled);
  };

  const regenerate = async () => {
    data.value = await useCourtApi().api.regenerate();
  };

  const redirectSwagger = () => {
    window.open('https://court.rustapp.io/public-docs', '_blank');
  };
</script>

<template>
  <div class="section">
    <div class="header">
      <div class="flex gap-1.5 items-center">
        <span class="title">
          {{ 'RustApp API' }}
        </span>
        <a class="flex gap-1 hyperlink" @click="redirectSwagger">
          {{ '[Swagger]' }}
        </a>
      </div>
      <span class="subtitle">{{ t('public-api-desc') }}</span>
    </div>

    <div v-if="!((_subscription.state?.current.type ?? '1free') < '3pro')">
      <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
        <SwitchText
          v-model="data.api_enabled"
          :before-toggle="toggle"
          :header="t('public-api-enable-access')"
          :placeholder="t('public-api-enable-access-desc')"
        />
      </SettingsContainer>

      <TextInput
        v-model="pseudoInput"
        theme="middle"
        disabled
        :label="'Private API key'"
        class="mt-6"
      >
        <template #action>
          <Button preset="link" :action="regenerate">
            {{ t('public-api-your-api-key-regenerate') }}
          </Button>
        </template>

        <template #append>
          <Button
            preset="default"
            class="h-full flex items-center !rounded-none"
            @click="useCopyClipboard(data.api_key, true)"
          >
            <Svg.copy class="h-4 w-4 fill-grey-800" />
          </Button>
        </template>
      </TextInput>

      <TextInput
        v-model="pseudoInput"
        theme="middle"
        disabled
        :label="'Public API key'"
        class="mt-6"
      >
        <template #append>
          <Button
            preset="default"
            class="h-full flex items-center !rounded-none"
            @click="useCopyClipboard(data.public_api_key, true)"
          >
            <Svg.copy class="h-4 w-4 fill-grey-800" />
          </Button>
        </template>
      </TextInput>
    </div>

    <div v-else class="warning">
      <p class="title">{{ t('public-api-no-access') }}</p>
      <span class="subtitle">{{ t('public-api-no-access-plan') }}</span>

      <Button
        preset="primary"
        class="btn"
        @click="ActivateCourtPlanModal({ landing: false })"
      >
        {{ t('improve-plan') }}
      </Button>
    </div>
  </div>

  <Divider class="my-8" />

  <div class="section">
    <div class="header">
      <p class="title">
        {{ t('public-api-options-title') }}
      </p>
      <span class="subtitle">{{ t('public-api-options-desc') }}</span>
    </div>

    <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
      <template v-for="value in data.public_api_routes_available" :key="value">
        <SwitchText
          v-model="data.public_api_routes[value]"
          class="w-full"
          :header="t('public-api-options-route', { route: value })"
        />
      </template>
    </SettingsContainer>
  </div>

  <Divider class="my-8" />

  <div class="section">
    <div class="header">
      <p class="title">
        {{ 'IP & User-Agents' }}
      </p>
      <span class="subtitle">{{ t('public-api-consumers-desc') }}</span>
    </div>

    <div class="flex flex-col gap-2">
      <template v-if="!data.api_consumers.length">
        <div class="empty-list">
          <EmptyImage
            :title="t('public-api-empty-list')"
            :subtitle="t('public-api-empty-list-desc')"
            type="empty"
          />
        </div>
      </template>

      <div class="items-row">
        <template v-for="value in data.api_consumers" :key="value">
          <div class="item">
            <ListItem
              :title="value.split('~')[0]"
              :message="value.split('~')[1]"
              no-avatar
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  @apply fill-grey-600 w-5 cursor-pointer;

  &:hover {
    @apply fill-grey-400;
  }
}

.section {
  @apply flex flex-col gap-5;

  .header {
    @apply flex flex-col gap-2;

    .title {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    .subtitle {
      @apply text-grey-400;
      @apply text-sm;
    }
  }

  :deep(.bright) {
    @apply text-amber-500;
  }
}

.warning {
  @apply p-4 rounded-md;
  @apply bg-grey-950;
  @apply border border-amber-500;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
  .title {
    @apply text-base font-medium text-grey-50 mb-1;
  }
  .subtitle {
    @apply text-sm font-normal;
    @apply text-grey-400;
  }
  .btn {
    @apply mt-4 px-2 py-1.5 #{!important};
    @apply text-sm;
  }
}

.swagger {
  @apply h-5 w-5;
}

.empty-list {
  @apply flex items-center justify-center;
  @apply h-full min-h-[225px];
  @apply rounded-md;
  @apply bg-grey-900;
}

.items-row {
  @apply divide-grey-850;
  
  .item {
    @apply cursor-default;
    @apply bg-grey-900;

    &:hover {
      @apply bg-grey-850;
    }
  }
}
</style>