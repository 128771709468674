<script lang="ts" setup>
  import Button from '@/components/Button.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useWindowSize } from '@vueuse/core';
  import { ActivatePrivacyModal } from './modals/PrivacyModal.vue';
  import { ActivateTermsModal } from './modals/TermsModal.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { RouterLink, RouterView, useRouter } from 'vue-router';
  
  const { t } = useLocaleStore();
  const _adaptive = useAdaptiveStore();
  const _locale = useLocaleStore();
  
</script>

<template>
  <div class="landing-page">
    <header :class="{'-mobile': _adaptive.isMobile}">
      <RouterLink to="/" class="logo">
        <Svg.rustapp class="w-[26px] fill-white mt-0.5" />
        <p class="text-white text-18-600">{{ t('site-title.general-without-dot') }}</p>
      </RouterLink>

      <div class="buttons">
        <template v-if="!_adaptive.isMobile">
          <RouterLink to="/pricing">
            <Button preset="landing-tab">
              {{ t('landing.new.pricing') }}
            </Button>
          </RouterLink>
          <Button preset="landing-tab" href="https://docs.rustapp.io/ru/start/getting-started.html">
            {{ t('landing.new.docs') }}
          </Button>
        </template>
        <RouterLink to="/profile">
          <Button
            preset="white"
            class="!text-14-500"
          >
            {{ t('landing.new.open-app') }}
          </Button>
        </RouterLink>
        <!--<Button v-if="_adaptive.isMobile" preset="landing-tab">
          <Svg.menu_dots class="w-6" />
        </Button>-->
      </div>
    </header>

    <main>
      <RouterView />

      <footer :class="{'-mobile': _adaptive.isMobile}">
        <div class="footer-content">
          <span class="text-grey-500">{{ 'RustApp © 2024' }}</span>
          <div class="terms-privacy">
            <span class="link" @click="ActivateTermsModal">{{ t('landing.terms') }}</span>
            <span class="link" @click="ActivatePrivacyModal">{{ t('landing.privacy') }}</span>
          </div>
          <div class="socials">
            <a href="https://github.com/rust-app-io" target="_blank">
              <Svg.github class="link w-[20px]" />
            </a>
            <a href="https://t.me/rustapp" target="_blank">
              <Svg.telegram class="link w-[20px]" />
            </a>
            <a href="https://vk.com/rustapp" target="_blank">
              <Svg.vk class="link w-[20px]" />
            </a>
            <a href="https://x.com/rustapp" target="_blank">
              <Svg.x class="link w-[20px]" />
            </a>
          </div>
          <span class="love">
            <Svg.heart class="fill-[#CB4417] w-[20px]" />
            {{ 'Crafted with passion and love' }}
          </span>
        </div>
      </footer>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.landing-page {
  @apply flex flex-col min-h-full;
  @apply bg-grey-1000;

  header {
    @apply absolute flex justify-between max-w-[600px] w-full p-2 pl-3;
    @apply top-[2%] left-1/2 z-10;
    @apply transform -translate-x-1/2;
    @apply rounded-[12px] bg-grey-1000/50 backdrop-blur-[10px];
    -webkit-backdrop-filter: blur(10px);

    .logo {
      @apply flex gap-2 items-center;
    }

    .buttons {
      @apply flex items-center gap-2.5;
    }

    &.-mobile {
      @apply top-0 rounded-none border-b border-white/5;
      @apply shadow-none;
    }
  }

  main {
    @apply grow;
    @apply overflow-y-auto;
  }

  footer {
    @apply flex justify-between items-center;
    @apply px-4;
    @apply border-t border-transparent;
    border-image: linear-gradient(90deg, #27272700, #272727, #27272700) 1;

    .footer-content {
      @apply flex flex-wrap items-center justify-center w-full py-6 gap-10;

      .terms-privacy {
        @apply flex gap-5;
      }

      .socials {
        @apply flex gap-5;
      }

      .love {
        @apply flex gap-1 items-center;
        background: radial-gradient(circle at left, #CB4417 0%, #6F6F6F 60%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }

    &.-mobile {
      .footer-content {
        @apply gap-5;
      }
    }
  }
}

.link {
  @apply text-grey-200 fill-grey-200;
  @apply cursor-pointer;

  &:hover {
    @apply text-grey-50 fill-grey-50;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
</style>