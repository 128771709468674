<script lang="ts">
  import { watchDebounced } from '@vueuse/core';
  import { useLocaleStore } from '@/stores/locale.store';

  export type InputOpts = {
    label?: string;

    placeholder?: string;
    // eslint-disable-next-line no-unused-vars

    type?: 'text' | 'new-password' | 'current-password' | 'password' | 'email' | 'number';
    theme?: 'light' | 'middle' | 'default' | 'dark' | 'blur';

    noPadding?: boolean;
    height?: string;
    pattern?: string;

    autofocus?: boolean;
    autocomplete?: string;
    inputmode?: "text" | "search" | "url" | "email" | "none" | "numeric" | "tel" | "decimal";
    required?: boolean;
    name?: string;
    maxLength?: number;
    max?: number;

    textarea?: boolean;
    
    disabled?: boolean;
    validator?: (value: string) => string | null;
    debounce?: number;
  }

</script>

<script setup lang="ts">
  import { watch, ref, onMounted } from 'vue';
  import { computed } from 'vue';

  const { t } = useLocaleStore();
  const props = defineProps<InputOpts>();
  const modelValue = defineModel<string | number>({ required: true });

  const basicInputHeight = computed(() => Math.max(40, +(props.height ?? '40')));
  
  const inputRef = ref<HTMLInputElement>();

  const emits = defineEmits(['debouncedUpdate', 'onEnter']);

  /**
   * Обработка ошибки 
   */

  // Этот компьютед всегда знает, ошибочно ли заполнено поле
  const activeException = computed(() => {
    if (props.required && !modelValue.value.toString().length) {
      return t('validator.required');
    } 

    if (!props.validator) {
      return null;
    }
    
    return props.validator(modelValue.value.toString());
  });

  if (props.debounce) {
    watchDebounced(modelValue, () => emits('debouncedUpdate'), { debounce: props.debounce, maxWait: 2000 });
  }

  // Делает ошибку из компьютеда "активной"
  const applyException = () => {
    if (modelValue.value?.toString()?.length == 0) {
      appliedException.value = null;
      return;
    }

    appliedException.value = activeException.value;
  };

  watchDebounced(modelValue, () => {
    if (props.disabled) {
      return;
    }

    applyException();
  }, { debounce: 1000 });

  watch(() => props.disabled, (v) => {
    if (!v) {
      return;
    }

    cleanException();
  }, { deep: true });

  const cleanException = () => {
    appliedException.value = null;
  };

  // Если сюда записана ошибка из компьютеда, светится ошибка под полем
  const appliedException = ref<string | null>(null);

  /**
   * Работа с авто-фокусом
   */
  onMounted(() => {
    if (props.autofocus) {
      inputRef.value?.focus();
    }
  });

  watch(
    () => props.autofocus,
    () => { 
      if (props.autofocus) {
        inputRef.value?.focus();
      }
    },
  );

  const enterHandle = () => {
    applyException();

    if (!appliedException.value) {
      emits('onEnter');
    }
  };

  const getBackground = computed(() => {
    switch (props.theme) {
      case 'light': {
        return 'bg-grey-850 placeholder:text-grey-600';
      }

      case 'middle': {
        return 'bg-grey-900 placeholder:text-grey-600';
      }

      case 'dark': {
        return 'bg-grey-950 placeholder:text-grey-750';
      }

      case 'blur': {
        return 'bg-grey-1000/25 placeholder:text-grey-750';
      }

      case 'default':
      default: {
        return 'bg-grey-1000 placeholder:text-grey-750';
      }
    }
  });

  const select = () => {
    inputRef?.value?.focus?.();
    inputRef?.value?.select?.();
  };

  const onBlur = () => {
    setTimeout(() => {
      if (props.disabled) {
        return;
      }

      applyException();
    }, 150);
  };

  defineExpose({ activeException, select, applyException, inputRef });
</script>

<template>
  <div
    :class="[
      'input-container',
      {
        error: !!appliedException,
        'backdrop-blur-md': theme === 'blur'
      },
    ]"
  >
    <template v-if="props.label || $slots.action">
      <div class="input-label truncate">
        <label for="input" class="truncate text-ellipsis"> {{ props.label }} </label>
        <slot name="action" />
      </div>
    </template>

    <div
      :style="`height: ${basicInputHeight}px;`"
      class="input-box"
      :class="{
        [getBackground]: true,
        '!pl-0': $slots.default,
        '!pr-0': $slots.append
      }"
    >
      <template v-if="$slots.default">
        <div class="prepend">
          <slot name="default" />
        </div>
      </template>

      <template v-if="!textarea">
        <input
          ref="inputRef"
          v-model="modelValue"
          :autofocus="autofocus"
          :maxlength="maxLength"
          :max="max"
          :placeholder="props.placeholder"
          :name="name"
          :pattern="pattern"
          :class="{
            [getBackground]: true,
            '!pl-2.5': !$slots.default,
            '!pr-2.5': !$slots.append,
          }"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :inputmode="inputmode"
          @keyup.enter="enterHandle()"
          @focusout="onBlur"
          @input="cleanException"
        >
      </template>
      <template v-else>
        <textarea
          ref="inputRef"
          v-model="modelValue"
          :autofocus="autofocus"
          :maxlength="maxLength"
          :max="max"
          :pattern="pattern"
          :placeholder="props.placeholder"
          :class="{
            [getBackground]: true,
          }"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled"
          @keyup.enter="enterHandle()"
          @focusout="onBlur"
          @input="cleanException"
        />
      </template>


      <template v-if="$slots.append">
        <div class="append slot">
          <slot name="append" />
        </div>
      </template>
    </div>

    <div v-show="!!appliedException" class="exception">
      <p> {{ appliedException ?? 'ㅤ' }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
:slotted(div) {
  @apply pl-2.5;
}
  .input-container {
    .input-label {
      @apply inline-flex justify-between w-full mb-1;
      @apply text-grey-400;
    }

    .input-box {
      @apply rounded-md;
      @apply flex;
      overflow: hidden;
      .prepend {
        flex-shrink: 0;
        @apply flex items-center justify-center;
        @apply text-grey-500;
      }

      input,
      textarea {
        width: 100%;
        flex-grow: 1;
        @apply h-full;
        @apply text-grey-50;

        &:disabled {
          @apply text-grey-750;
          cursor: not-allowed;
        }
      }

      textarea {
        resize: none;
        @apply p-2.5;
      }

      .append {
        flex-shrink: 0;
        @apply flex items-center justify-center;
      }
    }

    .exception {
      @apply mt-0.5;
      @apply text-red-500/75 text-xs;
      p {
        line-height: 14px;
      }
    }

    &.error {
      .input-box {
        @apply ring-red-500/50 ring-1;

        textarea,
        input {
          @apply text-red-500;
        }

        .prepend {
          @apply text-red-500;
        }
      }
    }
  }

</style>
