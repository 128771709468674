<script lang="ts">
  export * from './modal-menu-extended.dto';
</script>

<script setup lang='ts' generic="T extends string">
  import { computed, useSlots } from 'vue';
  import { ModalMenuExtendedHeader } from './modal-menu-extended.dto';
  import { ref, watch } from 'vue';
  import { onMounted } from 'vue';
  import { useWindowSize } from '@vueuse/core';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ModalMenuExtendedNavigationButton from './ModalMenuExtendedNavigationButton.vue';
  import { useModalsStore } from '@/stores/modals.store';
  import { useRoute, useRouter } from 'vue-router';

  const props = defineProps<{
    headers: ModalMenuExtendedHeader<T>[],
    mobileWidth: number,
    noHeader?: boolean;

    headerImage?: string,
    defaultSection?: string,
    dropdownFn?: (e: any) => void
  }>();

  const _route = useRoute();
  const _router = useRouter();
  const slots = useSlots();
  const { width } = useWindowSize();

  const shownHeaders = computed(() => {
    return props.headers.filter(v => !v.hidden);
  });

  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  const lastTab = ref<string | undefined>(props.defaultSection ?? '');
  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  const currentTab = ref<string | undefined>(props.defaultSection ?? '');

  const select = (name?: string) => {
    currentTab.value = name;

    if (name) {
      lastTab.value = JSON.parse(JSON.stringify(name));
    }
  };

  const emit = defineEmits(['dropdown-activated']);

  const close = () => {
    useModalsStore().close();
  };

  const avatarLoadingError = ref<boolean>(false);
  const isMobile = computed(() => width.value < props.mobileWidth);

  onMounted(() => {
    if (isMobile.value || (currentTab.value?.length ?? 0) > 2) {
      return;
    }

    select(props.headers[0].value);
  });

  watch(() => isMobile.value, (newValue) => {
    if (!newValue && !currentTab.value) {
      select(props.headers[0]?.value);
    }
  });

  const badges = ref<Record<string, number>>({});

  const onBadgeUpdated = (header: ModalMenuExtendedHeader<T>, badge: number) => {
    if (badges.value[header.value] > 0 && badge < 0) {
      return;
    }

    badges.value[header.value] = badge;
  };

</script>

<template>
  <div class="modal-menu-extended" :class="{ '-mobile': isMobile, '-opened': !!currentTab?.length }">
    <Transition name="slide-left">
      <div v-show="(!isMobile || !currentTab)" class="nav">
        <div class="nav-controls">
          <div class="controls-button" @click="close">
            <Svg.cross_large />
          </div>
          <div
            v-if="dropdownFn"
            class="controls-button"
            @click="(e) => dropdownFn?.(e)"
          >
            <Svg.dropdown />
          </div>
        </div>

        <div class="overflow-y-auto">
          <div class="header">
            <div class="header-bg">
              <div class="bg-image">
                <img
                  v-if="!avatarLoadingError"
                  :src="headerImage"
                  :onerror="() => avatarLoadingError = true"
                >
              </div>

              <div class="bg-blur" />
              <div class="bg-gradik" />
            </div>

            <div class="w-full">
              <slot name="header" v-bind="{ isMobile }" />
            </div>
            <slot name="actions" v-bind="{ isMobile }" />
          </div>

          <div v-if="$slots['custom-menu'] && isMobile" class="menu !pb-0">
            <slot name="custom-menu" />
          </div>

          <div class="menu">
            <template v-for="header in shownHeaders" :key="header.name">
              <ModalMenuExtendedNavigationButton
                :mobile="isMobile"
                :active="currentTab == header.value"
                :badge="badges[header.value]"
                @click="select(header.value)"
              >
                <component :is="header.icon" />
                {{ header.text }}
              </ModalMenuExtendedNavigationButton>
            </template>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="slide-right">
      <div v-show="(!isMobile || currentTab)" class="content">
        <div class="content-controls" v-if="!noHeader">
          <div class="controls-button " @click="select(undefined)">
            <Svg.menu />
          </div>

          <slot name="nav" v-bind="{ isMobile }" />

          <div class="controls-button" @click="close">
            <Svg.cross_large />
          </div>
        </div>

        <template v-for="header in shownHeaders" :key="header.value">
          <div
            v-show="currentTab == header.value || (isMobile && lastTab == header.value)"
            v-if="currentTab == header.value || header.preloaded || (isMobile && lastTab == header.value)"
            class="content-inner"
          >
            <slot
              :name="header.value"
              v-bind="{ isMobile, setBadge: (badge: number) => onBadgeUpdated(header, badge) }"
            />
          </div>
        </template>
      </div>
    </Transition>
  </div>
</template>

<style lang='scss' scoped>
.modal-menu-extended {
  @apply flex;
  @apply h-full;

  .controls-button {
    svg {
      @apply w-6 h-6;
      @apply fill-grey-400;
    }
  }

  .nav {
    @apply z-50;
    @apply overflow-auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @apply flex flex-col shrink-0;
    @apply w-[240px];
    @apply bg-grey-850;

    .nav-controls {
      @apply hidden;
    }

    .header {
      @apply p-5 pb-5;
      @apply relative;
      @apply flex flex-col gap-5;

      .header-bg {
        @apply absolute top-0 left-0 bottom-1 -z-10;
        @apply w-full aspect-square;

        .bg-image {
          @apply relative;
          @apply contrast-[.95];
          @apply w-full h-full;
          @apply overflow-hidden;
          object-fit: cover;

          img {
            @apply w-full;
            transform: translateY(30%);
            @apply absolute left-0 bottom-0 bottom-px;
          }
        }

        .bg-blur {
          @apply absolute top-0 left-0 -bottom-1 z-20;
          // @apply overflow-hidden;
          @apply w-full;
          -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
        }

        .bg-gradik {
          @apply absolute top-0 left-0 -bottom-1 z-10;
          // @apply overflow-hidden;
          @apply w-full;

          background: radial-gradient(64% 100% at 50% -2%, rgba(39, 39, 39, 0.5) 0%, #272727 100%), transparent 50% / cover no-repeat;
        }
      }
    }

    .menu {
      @apply flex flex-col gap-0.5;
      @apply p-5 pt-0;

      &::-webkit-scrollbar {
        display: none;
      }

      
    }
  }

  .content {
    @apply grow;
    overflow-y: auto;
    @apply h-full;
    @apply flex flex-col;

    .content-controls {
      @apply hidden;
    }

    .content-inner {
      @apply grow overflow-y-hidden;
    }
  }

  .footer {
    @apply hidden;
  }


  // ---- MOBILE ---- //
  &.-mobile {
    @apply min-h-min;
    max-height: 100%;
    @apply duration-200;

    // &.-opened {
    //   transform: translateX(-100%);
    // }
    .nav {
      @apply w-full;
      @apply overflow-visible;
      @apply bg-grey-900;
      @apply relative;
      @apply p-0;

      .nav-controls {
        @apply bg-grey-900;
        @apply border-b border-grey-1000;
        @apply w-full;
        @apply flex justify-between;
        @apply p-5;
      }

      .header {
        @apply p-5 pb-0;
        @apply items-center;

        .header-bg {
          @apply hidden;
          height: 233px;

          .bg-blur {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(30, 30, 30, 1) 100%);
          }
        }
      }

      .menu {
        @apply gap-0;
        @apply px-5 pt-5;
      }
    }

    .content {
      @apply w-full;
      @apply p-0;
      @apply flex flex-col;
      @apply overflow-visible;
      @apply shrink-0;

      .content-controls {
        @apply bg-grey-900;
        @apply border-b border-grey-1000;
        @apply w-full;
        @apply flex justify-between;
        @apply p-5;
      }

      .content-inner {
        @apply grow overflow-y-hidden;
      }
    }

    .footer {
      @apply block;
    }
  }
}

.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-left-enter-active {
  transition: all .2s ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-to,
.slide-right-leave-from {
  transform: translateX(-100%);
}
</style>