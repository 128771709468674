<script setup lang="ts">
  import { computed } from 'vue';

  const props = withDefaults(defineProps<{
    vertical?: boolean,
    dashed?: boolean,
    text?: string,
    width?: string
  }>(), {
    vertical: false,
    text    : '',
    width   : '2px'
  });

  const cssVars = computed(() => {
    return {
      '--divider-height': props.width,
    };
  });
</script>

<template>
  <div
    :style="cssVars"
    :class="['divider-wrapper', { vertical, 'dashed-divider': props.dashed }]"
  >
    <div class="line" />
    <p v-if="text?.length" class="text">
      {{ text }}
    </p>
    <div class="line" />
  </div>
</template>

<style scoped lang="scss">
.divider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    @apply text-grey-700 whitespace-nowrap;
    @apply mx-2;
  }

  .line {
    @apply bg-white/5;
    height: 1px;
    width: -webkit-fill-available;
    border-radius: 2px;
  }

  &.vertical {
    flex-direction: column;
    height: auto;
    .line {
      width: 1px;
      height: -webkit-fill-available;
    }
  }

  &.dashed-divider .line {
    @apply bg-transparent;
    background-image: linear-gradient(to right, transparent 50%, #2D2D2D 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
  }
}
</style>