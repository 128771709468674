<script lang="ts">
  import { useModalsStore } from '@/stores/modals.store';
  import Loading from '@/components/Loading.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  export type ImageGalleryModalOpts = {
    images: {
      hash: string
    }[],
    index: number
  }

  export const ActivateImageGalleryModal = async (props: ImageGalleryModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('image-gallery', props);
  };
</script>

<script setup lang='ts'>
  import { ref } from 'vue';
  import 'swiper/css/thumbs';
  import 'swiper/css';
  import 'swiper/css/zoom';
  import 'swiper/css/free-mode';
  import 'swiper/css/navigation';
  import type SwiperInstance from 'swiper';
  import { FreeMode, Navigation, Thumbs, Keyboard, Zoom } from 'swiper/modules';
  import { Swiper, SwiperSlide } from 'swiper/vue';

  defineProps<ImageGalleryModalOpts>();

  const _modal = useModalsStore();

  const thumbsSwiper = ref<SwiperInstance | null>(null);
  const setThumbsSwiper = (swiper: SwiperInstance) => {
    thumbsSwiper.value = swiper;
  };

  const close = (e: MouseEvent) => {
    const element = e.target as HTMLElement;
    const notClosableAreas = ['image', 'thumb-img', 'swiper-button-next', 'swiper-button-prev'];
    if (!element) {
      return;
    }

    const elementClasses = element.className.split(' ');

    if(!notClosableAreas.some(v => elementClasses.includes(v))) {
      _modal.close();
    }
  };

  const loadedImages = ref<Record<string,boolean>>({});
</script>

<template>
  <!-- Оставь надежду, всяк сюда входящий -->
  <div class="gallery-container" @click="close">
    <div class="gallery-main">
      <Swiper
        :initial-slide="index"
        :space-between="10"
        navigation
        zoom
        :keyboard="{ enabled: true}"
        :breakpoints="{
          300: {
            thumbs: { swiper: thumbsSwiper, autoScrollOffset: 0, }
          },
          640: {
            thumbs: { swiper: thumbsSwiper, autoScrollOffset: 2 }
          }
        }"
        :thumbs="{ swiper: thumbsSwiper, autoScrollOffset: 2}"
        :modules="[FreeMode, Navigation, Thumbs, Keyboard, Zoom]"
        class="image-swiper"
      >
        <swiper-slide
          v-for="(img, _) in images"
          :key="`${img.hash}_${_}`"
          class=""
        >
          <div class="swiper-zoom-container">
            <Loading v-if="!loadedImages[`main_${img.hash}`]" class="!bg-transparent" />
            <img
              class="image"
              :class="{'hidden': !loadedImages[`main_${img.hash}`]}"
              :src="useConfigStore().Urls.Images(img.hash)"
              @load="loadedImages[`main_${img.hash}`] = true"
            >
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="gallery-thumbs">
      <swiper
        :space-between="10"
        :slides-per-view="'auto'"
        free-mode
        :keyboard="{ enabled: true}"
        watch-slides-progress
        :modules="[ Thumbs, FreeMode ]"
        class="thumbs-images-swiper"
        @swiper="setThumbsSwiper"
      >
        <swiper-slide
          v-for="(img, _) in images"
          :key="`thumb_${img.hash}_${_}`"
          class="!w-fit"
        >
          <div v-if="!loadedImages[`main_${img.hash}`]" class="thumb-img bg-grey-1000" />
          <img
            class="thumb-img"
            :class="{'hidden': !loadedImages[`main_${img.hash}`]}"
            :src="useConfigStore().Urls.Images(img.hash)"
            @load="loadedImages[`thumb_${img.hash}`] = true"
          >
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.gallery-container {
  width: 95vw; 
  height: 100vh; 

  @apply flex flex-col;

  .gallery-main {
    cursor: pointer;
    flex-grow: 1;
    padding-top: 5vh;
    margin-bottom: 16vh;

    :deep(.image-swiper) {
      @apply h-full;

      .swiper-button-next,
      .swiper-button-prev {
        
        &:after {
          padding: 15px;
          font-size: 25px !important;
          font-weight: bold;
        }

        @apply text-grey-50 drop-shadow;
        @apply transition-all ease-in-out delay-0 duration-100;

        &.swiper-button-disabled {
          pointer-events: all;
        }
      }
    }
    .swiper-zoom-container {
      @apply flex;
      height: 75vh;
    }
    .swiper-slide {
      &.swiper-slide-zoomed {

        .image {
          cursor: move;
        }
      }
    }
    .image {
      @apply object-contain;
      @apply rounded-md;
      background-image: radial-gradient(#A6A09A 1px, #CDC6BF 1px);
      background-size: 10px 10px;
      cursor: default;
      height: auto;
      user-select: none;
      max-height: 100%;
    }
  }

  .gallery-thumbs {
    @apply fixed left-2.5 right-2.5;
    bottom: 4vh;
      
    .thumbs-images-swiper {
      @apply rounded-md;
      width: fit-content;

      .swiper-slide {
        cursor: pointer;
        &:last-child {
          margin-right: 0 !important;
        }

        .thumb-img {
          width: 8vh;
          min-width: 40px;
          height: 10vh;
          filter: grayscale(1);
          @apply rounded-md;
          @apply object-cover;
          transition: all .2s;
          background-image: radial-gradient(#A6A09A 1px, #CDC6BF 1px);
          background-size: 10px 10px;
        }

        &.swiper-slide-thumb-active {
          .thumb-img {
            width: 10vh;
            filter: grayscale(0);
            @apply aspect-square;
          }
        }
      }
    }
    
  }
}
</style>