

<script setup lang="ts" generic="T">
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed, ref } from 'vue';
  import { vOnClickOutside } from '@vueuse/components';
  import type { Component } from 'vue';
  import { isBoolean } from 'lodash';
  import { useConfigStore } from '@/stores/config/config.store';
  
  export type SelectValue<T> = {
    text?: string;
    value: T;
    icon?: string | Component | undefined;
    iconClass?: string
  }
  
  export type SelectWrapperProps<T> = {
    id?: string;
    label?: string;
    hideArrow?: boolean;
    variant?: 'default' | 'tonal' | 'tonal-dark' | 'modal';
    placeholder?: string;
    right?: boolean;
    up?: boolean;
    values: SelectValue<T>[];
    noLabelPadding?: boolean;
  }

  const props = withDefaults(defineProps<SelectWrapperProps<T>>(), {
    variant    : 'default',
    id         : '',
    label      : undefined,
    placeholder: ''
  });

  const opened = ref<boolean>(false);

  const toggle = (value?: boolean) => {
    opened.value = isBoolean(value) ? value : !opened.value;
  };

  const modelValue = defineModel<T>();

  const select = (selected: SelectValue<T>) => {
    modelValue.value = selected.value;
  };

  const selectedOption = computed(() => {
    return props.values.find(v => v.value === modelValue.value);
  });
</script>

<template>
  <div
    v-on-click-outside="() => toggle(false)"
    class="select-wrapper"
    @click="toggle()"
  >
    <label
      v-if="label"
      for="select"
      class="select-label break-all truncate text-ellipsis w-full"
      :class="{ '!mb-0': 'noLabelPadding'}"
    >
      {{ label }}
    </label>

    <div
      class="select"
      :class="variant"
    >
      <div
        class="activator"
        :class="{'-opened': opened }"
      >
        <template v-if="typeof selectedOption?.icon === 'string'">
          <img
            v-if="selectedOption?.icon"
            class="avatar"
            :src="useConfigStore().Urls.Images(selectedOption.icon ?? '')"
          >
        </template>
        <template v-else-if="selectedOption?.icon !== undefined">
          <component
            :is="selectedOption.icon"
            v-if="selectedOption?.icon"
            class="icon"
            :class="selectedOption.iconClass"
          />
        </template>
        <span>{{ selectedOption?.text || placeholder }}</span>
        <Svg.arrow
          v-if="!hideArrow"
          class="arrow-icon"
          :class="{ 'rotate-90': !opened, '-rotate-90': opened }"
        />
      </div>
      <div
        v-if="opened"
        class="options-list"
        :class="{'-right': right, '-up': up}"
      >
        <template
          v-for="(item, _) in values"
          :key="_"
        >
          <div 
            v-if="item.text"
            :class="['option']"
            @click="select(item)"
          >
            <template v-if="typeof item.icon === 'string'">
              <img
                v-if="item.icon"
                class="avatar"
                :src="useConfigStore().Urls.Images(item.icon ?? '')"
              >
            </template>
            <template v-else-if="item?.icon !== undefined">
              <component
                :is="item.icon"
                v-if="item.icon"
                class="icon"
                :class="item.iconClass"
              />
            </template>
            <span>
              {{ item.text }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-wrapper {
  @apply select-none;

  .select-label {
    @apply inline-block;
    @apply mb-1;
    @apply text-grey-400;
  }

  .avatar {
    @apply bg-white/5;
    @apply inline rounded;
    @apply shrink-0;
    @apply aspect-square object-cover;
    @apply w-4;
  }
  .icon {
    @apply w-4 aspect-square;
    @apply shrink-0;
    @apply fill-grey-200;
    @apply duration-200;
  }

  .select {
    @apply relative;

    .activator {
      @apply text-gray-50;
      @apply duration-200;
      @apply relative w-full;
      @apply cursor-pointer;
      @apply flex items-center gap-1.5;

      @apply w-full;
      @apply rounded-md;

      @apply py-1 pr-2;

      .arrow-icon {
        @apply w-5 ml-auto;
        @apply fill-grey-500;
        @apply duration-200;
      }
    }

    .options-list {
      @apply absolute z-10 left-0;
      @apply my-1;
      @apply overflow-y-auto;
      @apply max-w-[250px] max-h-[225px];
      @apply rounded-md;
      @apply bg-grey-800;
      @apply p-1.5;
      @apply shadow-xl;

      &.-right {
        @apply right-0 left-auto;
      }

      &.-up {
        bottom: 100%;
      }

      .option {
        @apply whitespace-nowrap;
        @apply cursor-pointer;
        @apply flex items-center gap-1.5;
        @apply px-1.5 py-1;
        @apply rounded;
        @apply text-grey-300;

        span {
          @apply truncate;
        }

        .icon {
          @apply fill-grey-300;
          @apply duration-0;
        }
        
        @media (hover: hover) {
          &:hover {
            @apply bg-grey-700;
            @apply text-grey-50;

            .icon {
              @apply fill-grey-50;
            }
          }
        }

        // TODO: Hougan Not sure, but it doesnot work without this
        &:hover {
          @apply bg-grey-700;
          @apply text-grey-50;

          .icon {
            @apply fill-grey-50;
          }
        }

        &.-active {
        }
      }
    }
    &.modal {
      .select-label {
        @apply text-grey-400;
        @apply mb-1;
        transition: none;
        @apply flex items-center justify-between;
      }
      .activator {
        height: 40px;

        @apply p-2;
        @apply bg-grey-850;
        @apply text-grey-200;
        @apply text-16-500;

        &.-opened,
        &:hover {
          @apply text-grey-200;
        }

      }
      .options-list {
        @apply max-w-full;
        @apply w-full right-0;

        .option {
          @apply text-16-400;
          @apply py-1.5;
          @apply text-grey-50;
        }
      }
    }

    &.tonal {
      .activator {
        @apply p-1.5;
        @apply bg-grey-800;
        @apply text-grey-400;
        @apply text-16-500;
        
        .icon {
          @apply w-5;
          @apply fill-grey-400;
        }

        .avatar {
          @apply w-5;
        }

        &.-opened,
        &:hover {
          @apply bg-grey-750;
          @apply text-grey-200;

          .icon {
            @apply fill-grey-200;
          }
        }
      }

      .options-list {
        @apply mt-2.5;
        .option {
          @apply px-1.5 py-0.5;
          @apply text-base font-medium;
        }
      }
    }

    &.tonal-dark {
      .activator {
        @apply p-1.5;
        @apply bg-grey-1000;
        @apply text-grey-200;
        @apply text-16-500;
        
        .icon {
          @apply w-5;
          @apply fill-grey-400;
        }

        .avatar {
          @apply w-5;
        }
      }

      .options-list {
        @apply mt-2.5;
        .option {
          @apply px-1.5 py-0.5;
          @apply text-base font-medium;
        }
      }
    }
  }
}
</style>