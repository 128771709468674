<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useCourtStore } from '@court/stores/court.store';
  import { useInterfaceStore } from '@court/stores/interface.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const props = defineProps<{
    player: PlayerFullDto;
    isMobile: boolean
  }>();

  const _court = useCourtStore();
  const _interface = useInterfaceStore();
</script>


<template>
  <Button
    v-if="_interface.settings.show_battlemetrics"
    :href="`https://www.battlemetrics.com/rcon/players?filter%5Bsearch%5D=${player.steam_id}&filter%5Bservers%5D=false&filter%5BplayerFlags%5D=&sort=score&showServers=false`"
    :preset="isMobile ? 'default' : 'default-light'"
    class="btn"
    :class="{'mobile': isMobile}"
  >
    <Svg.battlemetrics />
  </Button>
  <Button
    v-if="_court.court?.rcc_status"
    :href="`https://rustcheatcheck.ru/panel/player/${player.steam_id}`"
    :preset="isMobile ? 'default' : 'default-light'"
    class="btn"
    :class="{'mobile': isMobile}"
  >
    <Svg.rcc />
  </Button>
  <Button
    :href="`https://steamcommunity.com/profiles/${player.steam_id}/`"
    :preset="isMobile ? 'default' : 'default-light'"
    class="btn"
    :class="{'mobile': isMobile}"
  >
    <Svg.steam />
  </Button>
</template>

<style lang='scss' scoped>
.btn {
  @apply w-full p-1.5 #{!important};

  &.mobile {
    @apply p-2 #{!important};
  }

  svg {
    @apply w-5;
  }
}
</style>