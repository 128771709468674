import { useLocaleStore } from '@/stores/locale.store';
import { isAlpha, isEmail, isIP, isLowercase, isPort, length, maxLength, minLength, isURL, matches, isPositive } from 'class-validator';
import { useMoney } from './money.composable';

export const useValidators = () => {
  const { t } = useLocaleStore();

  const Email = (value: string) => (!isEmail(value) ? t('validator.email') : null);
  const Password = (value: string) => (!minLength(value, 8) ? t('validator.min-length', { min: 8 }) : null);

  const DisplayName = (value: string) => {
    if (!minLength(value, 4)) {
      return t('validator.name.min');
    }

    if (!maxLength(value, 14)) {
      return t('validator.name.max');
    } 
  
    return null;
  };

  const Tag = (value: string) => {
    if (!minLength(value, 4)) {
      return t('validator.tag.min');
    }

    if (!maxLength(value, 14)) {
      return t('validator.tag.max');
    } 
    
    if (!matches(value, /^[a-z0-9_]*$/)) {
      return t('validator.tag.alpha');
    }
  
    return null;
  };

  const Bio = (value: string) => {
    if (!maxLength(value, 256)) {
      return t('validator.bio.max');
    }
    return null;
  };

  const Port = (value: number) => {
    return !isPort(value.toString()) ? t('validator.port') : null;
  };

  const Ip = (value: string) => {
    return !isIP(value) ? t('validator.ip') : null;
  };

  const ProjectName = (value: string) => {
    if (!length(value, 6, 32)) {
      return t('validator.length', { min: 6, max: 32 });
    }

    return null;
  };

  const ServerName = (value: string) => {
    if (!length(value, 3, 20)) {
      return t('validator.length', { min: 3, max: 20 });
    }

    return null;
  };

  const ProjectDomain = (value: string) => {
    if (!isAlpha(value) || !isLowercase(value)) {
      return t('validator.only-lower-alpha');
    }

    if (!length(value, 3, 16)) {
      return t('validator.length', { min: 3, max: 16 });
    }

    return null;
  };

  const Url = (value: string) => {
    if (!isURL(value)) {
      return t('validator.url');
    }

    return null;
  };

  const Deposite = (value: string) => {
    if (!isPositive(+value)) {
      return t('validator.number');
    }

    const { lang } = useLocaleStore();
    const { toLocaleBalance } = useMoney();
    const minDeposite = lang === 'en' ? 1 : 100;

    if (+value < minDeposite) {
      return t('validator.minsum', { min: toLocaleBalance(minDeposite) });
    }

    return null;
  };

  const IsRegex = (value: string) => {
    try {
      new RegExp(value).test('sfasafas');

      return null;
    }
    catch {
      return t('validator.regex');
    }
  };

  return {
    Email,
    DisplayName,
    Tag,
    Bio,
    Password,
    Port,
    Ip,
    ProjectName,
    ProjectDomain,
    ServerName,
    Url,
    Deposite,
    IsRegex
  };
};
