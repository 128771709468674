<script lang="ts">
  export * from './modal-menu.dto';
</script>

<script setup lang='ts' generic="T extends string">
  import { computed } from 'vue';
  import { ModalMenuHeaderDef } from './modal-menu.dto';
  import { ref } from 'vue';
  import { onMounted } from 'vue';
  import { useWindowSize } from '@vueuse/core';

  const props = defineProps<{
    headers: ModalMenuHeaderDef<T>[],
    mobileWidth: number,
  }>();

  const { width } = useWindowSize();
  
  const shownHeaders = computed(() => {
    return props.headers.filter(v => !v.hidden);
  });

  const currentTab = ref<string>();

  const select = (name: string) => {
    currentTab.value = name;
  };

  onMounted(() => {
    select(props.headers[0]?.value);
  });

  const isMobile = computed(() => width.value < props.mobileWidth);

</script>

<template>
  <div class="modal-menu" :class="{'-mobile': isMobile}">
    <div class="nav">
      <div class="header">
        <slot name="header" v-bind="{ isMobile }" />
      </div>

      <div class="menu-wrapper">
        <div class="menu">
          <template v-for="header in shownHeaders" :key="header.name">
            <div
              class="menu-item"
              :class="{ '-active': currentTab == header.value}"
              @click="select(header.value)"
            >
              <component :is="header.icon" class="icon" />
              {{ header.text }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-for="header in headers" :key="header.value">
        <slot
          v-if="currentTab == header.value"
          :name="header.value"
          v-bind="{ isMobile }"
        />
      </template>
      <slot />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal-menu {
  @apply flex;
  @apply overflow-hidden;
  .nav {
    @apply p-5 pb-0;
    @apply flex flex-col gap-5 shrink-0;
    @apply overflow-hidden;
    @apply w-[240px];
    @apply bg-grey-850;
    .header {

    }
    .menu-wrapper {
      @apply overflow-auto;
      @apply mb-5;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
      .menu {
        @apply overflow-auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }


        .menu-item {
          @apply cursor-pointer select-none;
          @apply flex gap-2;
          @apply fill-grey-450;
          @apply text-grey-450 font-medium;
          @apply py-1.5 px-2;
          @apply rounded-md;
          
          .icon {
            @apply shrink-0;
            @apply w-[18px];
          }

          &:hover {
            @apply text-grey-300;
            @apply fill-grey-300;
          }

          &.-active {
            @apply bg-grey-750;
            @apply text-grey-50;
            @apply fill-grey-50;
          }
        }
      }
    }
  }
  .content {
    @apply grow;
    overflow-y: auto;
  }
  .footer {
    @apply hidden;
  }


  // ---- MOBILE ---- //
  &.-mobile {
    @apply min-h-min;
    @apply flex-col;
    max-height: full;
    overflow-y: hidden;
    .nav {
      @apply w-full;
      @apply overflow-visible;
      @apply bg-transparent;
      @apply relative;
      
      @apply p-0;

      // border
      &::after {
        @apply content-[""];
        @apply absolute z-10 inset-x-0 bottom-0;
        @apply h-px;
        @apply bg-grey-1000;
      }
      .header {
        @apply p-5 pb-0;
      }
      .menu-wrapper {
        @apply relative;
        @apply mb-0;

        // mobile shadows
        &::before {
          @apply content-[""];
          @apply w-5;
          @apply absolute z-30 left-0 top-0 bottom-px;
          background: linear-gradient(90deg, rgb(30, 30, 30) 0%, rgba(255,255,255,0) 100%);
        }
        &::after {
          @apply content-[""];
          @apply w-5;
          @apply absolute z-30 right-0 top-0 bottom-px;
          background: linear-gradient(-90deg, rgb(30, 30, 30) 7%, rgba(255,255,255,0) 100%);
        }
        .menu {
          @apply m-0;
          @apply px-5;
          @apply flex shrink-0 gap-5;
          @apply relative;


          .menu-item {
            @apply p-0 pb-2;
            @apply relative;

            .icon {
              @apply hidden;
            }

            &.-active {
              @apply bg-transparent;

            // active marker
              &::after {
                @apply content-[""];
                @apply absolute z-20 inset-x-0 bottom-0;
                @apply h-0.5;
                @apply bg-primary-700;
              }
            }
            
          }
        }
      }
    }

    .content {
      @apply flex flex-col;
    }
    .footer {
      @apply block;
    }
  }
}
</style>