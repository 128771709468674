
import { CentrifugoSituation } from '@/api/centrifugo/centrifugo.dto';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useDataStore } from '@/stores/data.store';
import { ampli } from '@src/ampli';
import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';


export const useSituationStore = defineStore('situation', () => {
  const _data = useDataStore();

  const lastSituationId = _data.sync('closed_situation', ref<string>('null'));

  // Always check for situation?.id, потому что вместо нее может быть пустой объект
  const situation = ref<CentrifugoSituation | null>(null);
  const clients = ref<Record<string, string>>({});
  
  const current = computed(() => {
    if (lastSituationId.value === situation?.value?.id) {
      return null;
    }

    return situation;
  });

  const warmup = async () => {
    clients.value = {};

    useCentrifugoApi().subscribeRefresh(() => location.reload());

    const sub = useCentrifugoApi().subscribeSituation((data) => set(data));
    
    useCentrifugoApi().onError((err) => {
      set({
        id: 'error-centrifugo-default' + Math.random(),

        name: 'error-centrifugo-default',

        text_ru: 'Не удалось подключиться к серверу событий, попробуйте перезагрузить страницу или проверьте подключение к интернету.',
        text_en: 'Failed to connect to the event server, try reloading the page or check your internet connection.',

        type: 'connection'
      });

      ampli.centrifugoTroubles({ error: JSON.stringify(err) });
    });

    sub.sub.on('subscribed', async () => {
      const [history, presence] = await Promise.all([
        sub.sub.history({ limit: 1 }),
        sub.sub.presence()
      ]);

      Object.entries(presence.clients).forEach(([client, user]) => clients.value[client] = user.user);
  
      set(history.publications[0]?.data);

      sub.sub.on('join', (ctx) => {
        clients.value[ctx.info.client] = ctx.info.user;
      });
    });

    sub.sub.on('leave', (ctx) => {
      delete (clients.value[ctx.info.client]);
    });
  };

  const set = (centrifugoSituation: CentrifugoSituation | null) => {
    situation.value = centrifugoSituation;
  };

  const close = () => {
    lastSituationId.value = situation.value?.id ?? 'null';
  };

  return {
    current,
    close,
    warmup,
    clients
  };
});