<script setup lang='ts'>
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useReportsStore } from './stores/report.store';
  import SwitchDropdown from '@/components/switch/SwitchDropdown.vue';
  import { useSoundStore } from '@/stores/sound.store';
  import SettingsNumeric from '@/components/inputs/numeric-input/SettingsNumeric.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = useLocaleStore();
  const _router = useRouter();
  const _sound = useSoundStore();
  const _reports = useReportsStore();

  const sortLabels: Record<'date' | 'count', string> = {
    'date' : t('reports.sort-by-date'),
    'count': t('reports.sort-by-amount')
  };

  const sortDropdown = computed((): ExtendedMenuItem[] => {
    return [{
      label : sortLabels['date'],
      active: () => _reports.filters.sort === 'date',
      action: () => {
        _reports.filters.sort = 'date';
      }
    }, {
      label : sortLabels['count'],
      active: () => _reports.filters.sort === 'count',
      action: () => {
        _reports.filters.sort = 'count';
      }
    }];
  });
</script>

<template>
  <div class="container">
    <SettingsContainer :title="t('reports.filters.title')">
      <SwitchDropdown
        v-model="_reports.filters.sort"
        :header="t('reports.filters.sort')"
        :choosed="sortLabels[_reports.filters.sort]"
        :opts="sortDropdown"
      />

      <SettingsNumeric
        v-model="_reports.filters.minimalToShow"
        :min="1"
        :max="50"
        :header="t('reports.filters.hide')"
      />
      <SettingsNumeric
        v-model="_reports.filters.minimalToHighlight"
        :min="1"
        :max="50"
        :header="t('reports.filters.highlight')"
      />
      <SwitchText
        v-model="_reports.filters.hide_offline"
        :header="t('reports.filters.hide-offline-title')"
      />
      <SwitchText
        v-model="_reports.filters.unique_initiators"
        :header="t('reports.filters.hide-duplications')"
      />
    </SettingsContainer>

    <SettingsContainer :title="t('reports.sound.title')">
      <SwitchDropdown
        v-model="_reports.sounds.soundType"
        :header="t('reports.sound.pick-sound')"
        :choosed="_sound.soundsAvailable[_reports.sounds.soundType].text"
        :opts="_sound.soundsDropdown((v) => _reports.sounds.soundType == v, (v) => _reports.sounds.soundType = v)"
      />

      <SwitchText v-model="_reports.sounds.muteLowAmount" :header="t('reports.sound.only-highlight')" />
    </SettingsContainer>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-y-auto;
  @apply bg-grey-900 rounded-md;
}
</style>