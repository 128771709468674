import { RouteRecordRaw } from 'vue-router';
import LandingView from './LandingView.vue';
import LandingAbout from './pages/LandingAbout.vue';
import LandingPricing from './pages/LandingPricing.vue';

export enum LandingRoutes {
  Main = 'main',
}

export const LandingRouter: RouteRecordRaw = {
  path     : '/',
  name     : 'Landing',
  component: LandingView,
  children : [{
    path     : '/',
    component: LandingAbout
  }, {
    path     : '/pricing',
    component: LandingPricing
  }]
};
