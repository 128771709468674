<script setup lang="ts">
  import { useAppStore } from '@/stores/app.store';
  import { useRouter } from 'vue-router';
  import type { ProjectClientPersonalDto } from '@/api/backend/core/project/project.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import { ActivateClientInfoEditModal } from '@src/modals/client-info-edit/ClientInfoEditModal.vue';
  import { PanelModules } from '@panel/panel.routes';
  import { ActivateProjectCreateModal } from '@src/modals/project-create/ProjectCreateModal.vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { useConfigStore } from '@/stores/config/config.store';
  import { EnvVariables } from '@/stores/config/env.variables';

  const _app = useAppStore();
  const _router = useRouter();
  const { t } = useLocaleStore();

  const goToProject = (project: ProjectClientPersonalDto) => {
    _router.push({ name: PanelModules.Court, params: { project: project.domain } });
  };

  window.onscroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    }
  };
</script>

<template> 
  <div class="profile">
    <div class="header">
      <div class="user">
        <AvatarExtended 
          :url="_app.client?.avatar ? useConfigStore().Urls.Images(_app.client?.avatar) : undefined" 
          active
          class="!w-20 !h-20"
        /> 
        <div>
          <p class="name">{{ _app.client?.name }}</p>
          <span class="mail">{{ '@' + _app.client?.tag }}</span>
        </div>
      </div>
    </div>

    <template v-if="_app.projects && _app.projects.length == 0">
      <div class="warning">
        <p>{{ '⚠️' }}</p>
        <p class="text">{{ t('project.empty') }}</p>
      </div>
    </template>

    <div v-if="_app.projects?.length != 0" class="content">
      <div class="projects">
        <div
          v-for="project in _app.projects"
          :key="project.id"
          class="project"
          @click="goToProject(project)"
        >
          <div class="flex">
            <ListItem
              :image-url="project.avatar ? useConfigStore().Urls.Images(project.avatar) : ''"
              :title="project.name"
              :message="`${ EnvVariables.DomainSite }/${ project.domain }`"
              :skeleton="'avatar'"
            />
          </div>
          <Svg.arrow class="arrow" />
        </div>
      </div>
    </div>

    <div class="buttons">
      <Button class="button" :action="() => ActivateProjectCreateModal({})">
        <div class="flex items-center justify-start gap-2 w-full">
          <Svg.add />
          <p>
            {{ t('project.create-new') }}
          </p>
        </div>
      </Button>
      <a class="button" @click="ActivateClientInfoEditModal({})">
        <Svg.settings />
        <p>
          {{ t('project.button.settings') }}
        </p>
      </a>
      <a class="button" @click="_app.logout()">
        <Svg.logout />
        <p>
          {{ t('project.button.logout') }}
        </p>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile {
  width: 300px;
  @apply flex flex-col gap-6;
  .header {
    .user {
      @apply flex flex-col gap-2 items-center;
      @apply text-center;
      .name {
        @apply font-semibold text-grey-50 text-lg;
      }
      .mail {
        @apply text-grey-500;
      }
    }
  }
  .projects {
    @apply grid grid-cols-1 divide-y overflow-x-auto; 
    @apply rounded-md;
    @apply divide-grey-800;
    @apply bg-grey-850;
    max-height: 260px;

    .project {
      @apply flex justify-between items-center;
      @apply p-2;
      @apply cursor-pointer;

      .arrow { 
        @apply w-6 mr-1;
        @apply fill-grey-500;
      }

      &:hover { 
        @apply bg-grey-800; 

        .arrow { 
          @apply w-6 mr-0;
          @apply fill-grey-200;
        } 
      } 
    }
  }
  
  .buttons {
    @apply grid grid-cols-1 divide-y overflow-hidden; 
    @apply rounded-md;
    @apply divide-grey-800;
    @apply bg-grey-850;
  }
}

.button {
  @apply flex items-center gap-2;
  @apply p-2;
  @apply text-grey-400;
  @apply cursor-pointer;
  @apply select-none;
  @apply ease-in-out delay-0 duration-0;

  svg {
    @apply fill-grey-400;
    @apply w-6;
    @apply ease-in-out delay-0 duration-0;
  }

  &:hover {
    @apply bg-grey-800;
    @apply text-grey-50;
    
    svg {
      @apply fill-grey-50;
    }
  }

  &:deep(.wrapper) {
    @apply w-full;
  }
}

.warning {
  @apply flex gap-2 p-2;
  @apply rounded-md;
  @apply bg-amber-400/10;

  .text {
    @apply text-amber-400 text-sm;
  }
}

::-webkit-scrollbar { 
  width: 0px; 
}
</style>
