import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useAppStore } from '@/stores/app.store';
import { useRoute, useRouter } from 'vue-router';

import { ProjectClientPersonalDto, ProjectClientDto } from '@/api/backend/core/project/project.dto';
import { DebugService } from '@/api/backend/debug.service';
import { ClientRoutes } from '@src/views/client/client.routes';
import { ampli } from '@src/ampli';
import { useCoreApi } from '@src/api/backend/core/core.api';

export const usePanelStore = defineStore('panel', () => {
  const _app = useAppStore();
  const _route = useRoute();
  const _router = useRouter();

  const project = ref<ProjectClientPersonalDto>();
  const projectClients = ref<ProjectClientDto[]>([]);

  const updateProject = (externalDomain?: string) => {
    DebugService.debug(`Смена данных о проектах / ссылки, определяем проект`);

    const domain = externalDomain || _route.params.project;

    project.value = _app.projects?.find(v => v.domain === domain);
    
    if (!project.value) {
      DebugService.error(`Не удалось определить проект по ссылке`, { route: domain, list: _app.projects?.map(v => v.domain) });
      _router.push({ name: ClientRoutes.Profile });    
      return;
    }


    localStorage.setItem('last_project_id', project.value.id.toString());

    ampli.client.setGroup('project', project.value.id.toString());

    updateClients(true);
  };

  const updateClients = async (clean?: boolean) => {
    if (clean) {
      projectClients.value = [];
    }

    if (!project.value?.id) {
      return;
    }

    DebugService.debug(`Загрузка данных о модераторах проекта`);

    projectClients.value = await useCoreApi().project.getClients(project.value.id);
  };

  return {
    project,
    projectClients,
    
    updateProject,
    updateClients
  };
});
