<script lang="ts" setup>
  
  import { CheckStatsDto, CheckStatsEntryDto } from "@/api/backend/court/check/check.dto";
  import { computed, onMounted, ref, watch } from "vue";
  import Divider from '@/components/Divider.vue';
  import { BanStatsDto, BanStatsEntryDto } from "@/api/backend/court/ban/ban.dto";
  import AreaChartBase, { ChartSeries } from "@/components/quality/chart/AreaChartBase.vue";
  import ModeratorSettings from '../components/ModeratorSettings.vue';
  import dayjs from "dayjs";
  import ModeratorStats from "../components/ModeratorStats.vue";
  import { useLocaleStore } from "@/stores/locale.store";
  import { useCourtApi } from "@/api/backend/court/court.api";
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  
  const { t } = useLocaleStore();

  const moderatorSettings = ref<InstanceType<typeof ModeratorSettings>>();

  const detectIntervals = computed(() => {
    if (!moderatorSettings.value?.choosedInterval) {
      return null;
    }

    const [start, end] = moderatorSettings.value.choosedInterval;
    if (!start || !end) {
      return;
    }

    const diff = (+end - +start) + 24 * 60 * 60 * 1_000;
    const [prevStart, prevEnd] = [new Date(+start - diff), new Date(+end - diff)];

    return {
      current : [dayjs(start).startOf('day').toDate(), dayjs(end).endOf('day').toDate()],
      previous: [dayjs(prevStart).startOf('day').toDate(), dayjs(prevEnd).endOf('day').toDate()]
    };
  });
  
  const currentData = ref<{
    check: CheckStatsDto,
    bans: BanStatsDto
  }>();
  const previousData = ref<{
    check: CheckStatsDto,
    bans: BanStatsDto
  }>();
  
  onMounted(() => load());
  watch(() => moderatorSettings.value?.choosedClientId, () => load(), { deep: true });
  watch(() => detectIntervals.value, () => load(), { deep: true });

  const load = async () => {
    if (!moderatorSettings.value?.choosedInterval) {
      return;
    }

    const intervals = detectIntervals.value;
    if (!intervals?.current || !intervals.previous) {
      return;
    }

    currentData.value = undefined;
    previousData.value = undefined;

    const clientId = moderatorSettings.value.choosedClientId;
    const { current, previous } = intervals;

    try {
      const [checkCurrent, bansCurrent, checkPrevious, bansPrevious] = await Promise.all([
        useCourtApi().check.stats(+current[0], +current[1], clientId ?? undefined),
        useCourtApi().ban.stats(+current[0], +current[1], clientId ?? undefined),
        useCourtApi().check.stats(+previous[0], +previous[1], clientId ?? undefined),
        useCourtApi().ban.stats(+previous[0], +previous[1], clientId ?? undefined)
      ]);
  
      currentData.value = {
        check: checkCurrent,
        bans : bansCurrent
      };

      previousData.value = {
        check: checkPrevious,
        bans : bansPrevious
      };
    }
    catch (err: any) {
      if (err?.message?.includes('опция доступна начиная с тарифа')) {
        const checks: Record<number, CheckStatsEntryDto> = {};
        const checksOld: Record<number, CheckStatsEntryDto> = {};

        const bans: Record<number, BanStatsEntryDto> = {};
        const bansOld: Record<number, BanStatsEntryDto> = {};
        
        for (let i = 30; i > 0; i--) {
          const date = +dayjs().add(-i, 'day');

          checks[date] = {
            ban                 : +Math.round((Math.random() * 15)).toFixed(0),
            clear               : +Math.round((Math.random() * 7)).toFixed(0),
            canceled            : +Math.round((Math.random() * 5)).toFixed(0),
            count_day_interval  : 5,
            count_night_interval: 1,
            date                : date
          };

          checksOld[date] = {
            ban                 : +Math.round((Math.random() * 15)).toFixed(0),
            clear               : +Math.round((Math.random() * 7)).toFixed(0),
            canceled            : +Math.round((Math.random() * 5)).toFixed(0),
            count_day_interval  : 5,
            count_night_interval: 1,
            date                : date
          };

          bans[date] = {
            ban       : +Math.round((Math.random() * 15)).toFixed(0),
            with_proof: +Math.round((Math.random() * 6)).toFixed(0),
            unban     : +Math.round((Math.random() * 7)).toFixed(0),
            date      : date,
          };

          bansOld[date] = {
            ban       : +Math.round((Math.random() * 15)).toFixed(0),
            with_proof: +Math.round((Math.random() * 6)).toFixed(0),
            unban     : +Math.round((Math.random() * 7)).toFixed(0),
            date      : date,
          };
        }

        currentData.value = {
          check: { stats: checks, reasons: { 'Игрок чист': 100} },
          bans : { stats: bans, reasons: { 'Читы': 37 } }
        };

        previousData.value = {
          check: { stats: checksOld, reasons: { 'Отказ от проверки': 24} },
          bans : { stats: bansOld, reasons: { 'Макросы': 25 } }
        };

        return;
      }

      throw err;
    }
  };

  const seriesCheck = computed<ChartSeries[] | null>(() => {
    if (!currentData.value) {
      return null;
    }

    return [
      {
        name : t('moderator-stats-chart-check-ban'),
        data : Object.entries(currentData.value.check.stats ?? {}).map(([key, v]) => ({ x: +key, y: v.ban })),
        color: '#f43f5e',

        fillGradientOpacity: 0.5
      },
      {
        name : t('moderator-stats-chart-check-clear'),
        data : Object.entries(currentData.value.check.stats ?? {}).map(([key, v]) => ({ x: +key, y: v.clear })),
        color: '#3874EC',
        
        fillGradientOpacity: 0.5
      },
      {
        name : t('moderator-stats-chart-check-cancel'),
        data : Object.entries(currentData.value.check.stats ?? {}).map(([key, v]) => ({ x: +key, y: v.canceled })),
        color: '#535353',
        
        fillGradientOpacity: 0.5,

        dashValue: 4
      }];
  });

  const seriesBans = computed<ChartSeries[] | null>(() => {
    if (!currentData.value?.bans) {
      return null;
    }

    return [
      {
        name : t('moderator-stats-chart-ban-ban'),
        data : Object.entries(currentData.value.bans.stats ?? {}).map(([key, v]) => ({ x: +key, y: v.ban })),
        color: '#f43f5e',
        
        fillGradientOpacity: 0.5
      },
      {
        name : t('moderator-stats-chart-ban-unban'),
        data : Object.entries(currentData.value.bans.stats ?? {}).map(([key, v]) => ({ x: +key, y: v.unban })),
        color: '#10b981',
        
        fillGradientOpacity: 0.5
      }];
  });

  const calculate = computed(() => {
    if (!currentData.value) {
      return;
    }

    const totalChecks = Object.values(currentData.value.check.stats).reduce((acc, v) => acc + v.ban + v.clear, 0);
    const totalDay = Object.values(currentData.value.check.stats).reduce((acc, v) => acc + v.count_day_interval, 0);
    const totalNight = Object.values(currentData.value.check.stats).reduce((acc, v) => acc + v.count_night_interval, 0);

    const totalBans = Object.values(currentData.value.bans.stats).reduce((acc, v) => acc + v.ban, 0);

    if (!previousData.value) {
      return { totalChecks, totalBans };
    }

    const prevTotalChecks = Object.values(previousData.value.check.stats).reduce((acc, v) => acc + v.ban + v.clear, 0);
    const prevTotalBans = Object.values(previousData.value.bans.stats).reduce((acc, v) => acc + v.ban, 0);

    const diffChecks = +((totalChecks / prevTotalChecks) * 100 - 100).toFixed(1);
    const diffBans = +((totalBans / prevTotalBans) * 100 - 100).toFixed(1);

    const diffChecksValidated = Math.min(diffChecks, 999);
    const diffBansValidated = Math.min(diffBans, 999);

    return { totalChecks, totalDay, totalNight, totalBans, diffChecks: diffChecksValidated, diffBans: diffBansValidated };
  });

  const reformatReasons = (reasons: Record<string, number>) => {
    return Object.entries(reasons).map<[string, number]>(([key, value]) => {
      let realKey = key;

      switch (key) {
        case 'Canceled': {
          realKey = t('check.verdict.status.canceled');
          break;
        }
        case 'CanceledTimeout': {
          realKey = t('check.verdict.status.canceled-timeout');
          break;
        }
        case 'FinishedBan': {
          realKey = t('check.verdict.status.finished-ban-placeholder');
          break;
        }
        case 'FinishedClear': {
          realKey = t('check.verdict.status.finished-clear');
          break;
        }
      }

      return [realKey, value];
    }).sort((a, b) => b[1] - a[1]);
  };
</script>

<template>
  <ModeratorSettings ref="moderatorSettings" />
  <ModeratorStats
    :prev-interval="detectIntervals?.previous as [Date, Date]"
    :checks="calculate?.totalChecks"
    :day="calculate?.totalDay"
    :night="calculate?.totalNight"
    :bans="calculate?.totalBans"
    :check-diff="calculate?.diffChecks"
    :bans-diff="calculate?.diffBans"
  />

  <Divider
    class="my-8"
    :text="t('general.graphs')"
  />

  <div class="flex flex-col gap-5">
    <AreaChartBase
      :title="t('moderator-stats-chart-check')"
      :series="seriesCheck"
      class="h-[275px]"
    />
    <AreaChartBase
      :title="t('moderator-stats-chart-ban')"
      :series="seriesBans"
      class="h-[275px]"
    />
  </div>

  <Divider
    v-if="currentData?.check.reasons"
    class="my-8"
    :text="t('moderator-stats-divider-verdicts-reasons')"
  />

  <div v-if="currentData?.check.reasons" class="verdicts-reasons">
    <div class="container">
      <p class="header">{{ t('moderator-stats-chart-verdicts') }}</p>
      <div class="items">
        <template v-if="reformatReasons(currentData?.check.reasons).length">
          <div
            v-for="(keyValue) in reformatReasons(currentData?.check.reasons)"
            :key="keyValue[0]"
            class="item"
          >
            <p class="text">{{ keyValue[0] }}</p>
            <p class="value">{{ t('general.amount-st', { amount: keyValue[1]}) }}</p>
          </div>
        </template>
        <EmptyImage
          v-else
          :title="t('emptyimage-nothing-title')"
          :subtitle="t('emptyimage-nothing-subtitle')"
          class="p-5"
        />
      </div>
    </div>

    <div class="container">
      <p class="header">{{ t('moderator-stats-chart-reasons') }}</p>
      <div class="items">
        <template v-if="reformatReasons(currentData?.bans.reasons).length">
          <div
            v-for="(keyValue) in reformatReasons(currentData?.bans.reasons)"
            :key="keyValue[0]"
            class="item"
          >
            <p class="text">{{ keyValue[0] }}</p>
            <p class="value">{{ t('general.amount-st', { amount: keyValue[1]}) }}</p>
          </div>
        </template>
        <EmptyImage
          v-else
          :title="t('emptyimage-nothing-title')"
          :subtitle="t('emptyimage-nothing-subtitle')"
          class="p-5"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.client-suggester {
  @apply p-2;
}

.verdicts-reasons {
  @apply grid grid-cols-2 gap-5;

  .container {
    @apply flex flex-col w-full h-fit;
    @apply rounded-md;
    @apply bg-grey-900;
    @apply border border-grey-900;

    .header {
      @apply text-grey-50 p-2.5;
    }
    
    .items {
      @apply flex flex-col max-h-[350px] overflow-auto;
      @apply divide-y divide-grey-900;
      @apply bg-grey-950;
    }

    .item {
      @apply flex justify-between p-2.5 gap-10;

      .text {
        @apply text-grey-500 truncate;
      }
      .value {
        @apply text-grey-50 flex-shrink-0;
      }
    }
  }
}

[device="mobile"], [device="tablet"] {
  .verdicts-reasons {
    @apply grid-cols-1;
  }
}
</style>