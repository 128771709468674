import { PaginatedResponse, PaginationQuery } from "@/api/backend/pagination.service";

import { SignageDto } from "./signages.dto";
import DataLoader from "dataloader";
import { Axios } from "axios";

export const useCourtSignagesApi = (api: Axios) => {
  const batchNetIds = new DataLoader<string, SignageDto | undefined>((keys) => findBatchNetIds(keys), {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });

  const findBatchNetIds = async (keys: readonly string[]) => {
    const data = await browse({ page: 0, net_ids: Array.from(new Set(keys)), limit: keys.length, hide_destroyed: false });

    return keys.map(v => data.results.find(d => d.net_id === v));
  };

  const browse = async (query: PaginationQuery & { search?: string; steam_id?: string; server_id?: number; hide_destroyed?: boolean; hide_actual?: boolean; net_ids?: string[]; only_liked?: boolean; }) => {
    const result = await api.get<PaginatedResponse<SignageDto>>("/signage/browse", {
      params: query
    });

    return result.data;
  };

  const like = async (server_id: number, net_id: string, like: boolean) => {
    const result = await api.post("/signage/like", {
      server_id,
      net_id,
      like
    });

    return result.data;
  };

  const deleteOne = async (server_id: number, net_id: string) => {
    const result = await api.delete("/signage", {
      data: {
        server_id,
        net_id
      }
    });

    return result.data;
  };

  return {
    batchNetIds,
    findBatchNetIds,
    browse,
    like,
    deleteOne,
  };
};
