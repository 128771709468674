import { useDataStore } from "@/stores/data.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export type PlayerAlertFilter = {
  show_join_with_ip_ban: boolean;
  show_join_with_external_ban: boolean;
  detect_ra_ban: boolean;

  show_dug_up_stash: boolean;
  custom_api: boolean;
  custom_api_categories: Record<string, boolean>;
  vpn_detected: boolean;
  license_changed: boolean;
}

export const usePlayerAlertStore = defineStore('player-alerts', () => {
  const _data = useDataStore();

  const filters = _data.sync('player_alert_filters', ref<PlayerAlertFilter>({
    show_join_with_ip_ban      : true,
    show_join_with_external_ban: true,
    detect_ra_ban              : true,
    vpn_detected               : true,
  
    show_dug_up_stash    : true,
    custom_api           : true,
    custom_api_categories: { '': true },
    license_changed      : true,
  }));

  return { 
    filters
  };
});