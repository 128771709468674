<script lang="ts">
  export type NewsModalProps = {
    newsId: number;
    news?: NewsDto
  }

  export const ActivateNewsModal = (newsId: number, news?: NewsDto) => {
    const _modal = useModalsStore();

    _modal.open('news', {
      newsId,
      news
    });
  };
</script>

<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import GallerySwiper from '@/components/quality/gallery-swiper/GallerySwiper.vue';
  import { NewsDetailsDto, NewsDto } from '@/api/backend/core/utils/utils.dto';
  import { ModalInjectedProps } from '@/stores/modals.store';
  import { useModalsStore } from '@/stores/modals.store';
  import { useTimeStore } from '@/stores/time.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { watch } from 'vue';
  import { computed } from 'vue';
  import { onMounted } from 'vue';
  import { ref } from 'vue';
  import Divider from '@/components/Divider.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useCoreApi } from '@/api/backend/core/core.api';

  const _time = useTimeStore();
  const _locale = useLocaleStore();

  const props = defineProps<NewsModalProps & ModalInjectedProps>();

  const data = ref<NewsDto>();

  onMounted(async () => {
    if (props.news) {
      data.value = props.news;
      return;
    }

    const response = await useCoreApi().utils.getNews(props.newsId);
    if (!response) {
      props.injected.close();
      return;
    }

    data.value = response;
  });
  watch(() => props.news, (v) => data.value = v, { deep: true });

  const selected = computed<NewsDetailsDto | null>(() => {
    if (!data.value) {
      return null;
    }

    return _locale.lang === 'ru' ? data.value.ru : data.value.en;
  });
</script>

<template>
  <div class="news-modal relative !bg-grey-900 text-white">
    <Loading :can-view="!!selected" />

    <template v-if="!!selected && data">
      <div class=" header">
        <div>
          <p class="title">{{ selected.title }}</p> 
          <p class="subtitle">{{ _time.format(data.created_at, 'DD MMMM YYYY # HH:mm', true, true) }}</p>
        </div>

        <Svg.close class="close" @click="injected.close()" />
      </div>

      <div class="content">
        <div v-if="selected.image_gallery" class="news-section">
          <GallerySwiper :images="selected.image_gallery" class="rounded-md overflow-hidden" />
        </div>
  
        <div v-if="selected?.highlights" class="news-section">
          <template v-for="(value, _) in selected.highlights" :key="`${value.title}-${value.text}-${_}`">
            <div>
              <div class="text-title flex items-center gap-1.5">
                <TooltipBase v-if="value.subscription" trigger="hover">
                  <template #content>
                    <div class="px-2 py-1">
                      <span>
                        {{ _locale.t('required-plan-tooltip') }}
                      </span>
                      <span class="capitalize">{{ value.subscription }}</span>
                    </div>
                  </template>
                  
                  <div
                    class="icon"
                    :class="value.subscription"
                  >
                    <Svg.lightning :style="`fill: url(#${value.subscription});`" />
                  </div>
                </TooltipBase>
                {{ value.title }}
              </div>
              <p class="text-default" v-html="value.text" />

              <GallerySwiper
                v-if="value.image_gallery"
                :images="value.image_gallery"
                class="rounded-md overflow-hidden mt-4"
              />
            </div>
          </template>
        </div>

        <template v-if="selected?.patch">
          <Divider />
        </template>
  
        <div v-if="selected?.patch">
          <p class="text-title">{{ selected.patch.title }}</p>
          <div class="change-list">
            <template v-for="value in selected.patch.patches" :key="`${value.type}-${value.text}`">
              <ul class="badge" :class="{ [value.type]: true }">
                <li>{{ value.text }}</li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.news-modal {
  width: 95vw;
  max-width: 540px;

  min-height: 400px;
  max-height: min(1050px, 95svh);
  @apply flex flex-col;
}

:deep(.link) {
  @apply text-primary-700 hover:text-primary-600;
}

.header {
  @apply flex items-center justify-between w-full p-5;
  @apply text-grey-50 font-semibold;
  @apply border-b border-grey-1000;

  .title {
    @apply text-white;
    @apply font-semibold;
    @apply block;
  }
  .subtitle {
    @apply text-grey-400;
    @apply font-normal;
    @apply text-sm;
    @apply block;
  }
  .close {
    @apply h-9 w-9 cursor-pointer flex-shrink-0;
    @apply fill-grey-700;

    &:hover {
      @apply fill-grey-500;
    }
  }
}
.icon {
  @apply p-0.5 rounded-md;
  
  &.starter {
    @apply bg-gradient-to-r from-[#D4FC79]/15 to-[#96E6A1]/15;
  }
  &.basic {
    @apply bg-gradient-to-r from-[#56CCF2]/15 to-[#2F80ED]/15;
  }
  &.pro {
    @apply bg-gradient-to-r from-[#FFE259]/15 to-[#FFA751]/15;
  }

  svg {
    @apply w-4;
  }
}

.content {
  @apply flex flex-col gap-5 overflow-y-scroll p-5;
  
  .news-section {
    @apply flex flex-col gap-5;
  }

  .text-title {
    @apply text-grey-50 text-base font-medium mb-1;
  }

  .text-default {
    @apply text-grey-400 text-sm;
  }

  .change-list {
    @apply flex flex-col gap-1;

    .badge {
      @apply ml-5 list-disc text-grey-400 text-sm;

      &.new {
        @apply marker:text-lime-400;
      }
      &.fix {
        @apply marker:text-primary-500;
      }
      &.delete {
        @apply marker:text-red-400;
      }
    }
  }
}

:deep(.swiper) {
  @apply w-full;
  height: 260px !important;
}
</style>