<script lang="ts">
  export type * from './PanelPageNavigationItem.vue';
</script>

<script setup lang="ts">
  import { computed } from 'vue';
  import PanelPageNavigationItem, { type PageNavigationSection } from './PanelPageNavigationItem.vue';

  import Divider from '@/components/Divider.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { SettingsRoutes } from '@settings/settings.routes';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { ref } from 'vue';
  import { useDataStore } from '@/stores/data.store';
  import { onMounted } from 'vue';

  const props = defineProps<{
    sections: PageNavigationSection[];
  }>();

  const { t } = useLocaleStore();
  const _data = useDataStore();
  const _courtSubscription = useCourtSubscriptionStore();

  const seen = _data.sync('panel_navigation_new', ref<string[]>([]));

  onMounted(() => {
    if (seen.value.length > 100) {
      seen.value = [];
    }

    return;
  });

  const computedRoutes = computed((): PageNavigationSection[] => {
    const _permissions = usePermissionsStore();

    return [
      ...props.sections,
      { 
        name : t('court.navigation.project.text'), 
        items: { 
          [SettingsRoutes.About]: { 
            name  : t('court.navigation.settings.info'), 
            icon  : Svg.folders,
            hidden: !_permissions.isAdminOrOwner(),
          }, 
          [SettingsRoutes.Clients]: { 
            name: t('court.navigation.settings.clients'), 
            icon: Svg.members,
          },
          [SettingsRoutes.Billing]: {
            name   : t('court.navigation.settings.billing'),
            icon   : Svg.payment,
            hidden : !_permissions.isAdminOrOwner(),
            warning: (_courtSubscription.state?.pay_error || _courtSubscription.state?.is_trial || _courtSubscription.state?.usage_error)
          }
        } 
      }
    ];
  });
</script>

<template>
  <div class="module-navigation">
    <div class="sections">
      <template v-for="section in computedRoutes" :key="JSON.stringify(section)">
        <div>
          <template v-if="section.name !== undefined && Object.values(section.items).some(v => !v.hidden)">
            <Divider style="margin: 18px 0;" class="px-2" />
            <p class="section">{{ section.name }}</p>
          </template>

          <div class="items">
            <template v-for="(item, route) of section.items" :key="`${route}-${item.paid?.()}`">
              <PanelPageNavigationItem
                v-if="item"
                :item="item"
                :route="route"
                :show-new="!!item.new && !seen.includes(item.new)"
                @click="() => !!item.new ? (!seen.includes(item.new) ? seen.push(item.new) : undefined) : () => {}"
              />
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[left-bar], [device='mobile'] {
  .divider-wrapper {
    display: none;
  }
}

:root:not([device='mobile']):not([left-bar]) {
  .section {
    display: none;
  }

  .sections {
    @apply items-center;
  }
} 

.module-navigation {
  @apply overflow-y-auto;
  @apply flex flex-col gap-5 h-full w-full;

  .sections {
    @apply flex flex-col gap-3;

    .section {
      @apply text-grey-600;
      @apply text-sm;
      @apply mb-1;
    }

    .items {
      @apply flex flex-col gap-0.5;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>