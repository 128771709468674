import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useDataStore } from '@/stores/data.store';
import { ChatFilterTypes, ChatSearchOpts, ChatSettingsCensure, ChatSettingsDesign, EmojiView } from '../types/chat.dto';
import { russianCursed } from '../types/cursed.dto';
import { useLocaleStore } from '@/stores/locale.store';

export const useChatStore = defineStore('chat', () => {
  const _data = useDataStore();
  const { t } = useLocaleStore();

  /**
   * Variables
   */

  const search = ref<ChatSearchOpts[]>([]);
  const reply = ref<{ steam_id: string, steam_avatar: string, steam_name: string; server_id: number }>();

  const types = _data.sync('chat_filter_types', ref<ChatFilterTypes>({
    global: true,
    direct: true,
    team  : true
  }));

  const design = _data.sync('chat_settings_design', ref<ChatSettingsDesign>({
    avatars: true,
    time   : true,
    server : false,
    
    invertColors: false,
    emojiView   : 'preview'
  }));

  const censure = _data.sync('chat_settings_censure', ref<ChatSettingsCensure>({
    highlight: true,

    extraWordsHighlight: false,
    extraWords         : [],
    
    notification: false,
    soundType   : 'None'
  }));

  if (censure.value.extraWords.includes('?')) {
    censure.value.extraWords = censure.value.extraWords.filter(v => v != '?');
  }

  /**
   * Methods
   */

  const searchActive = computed(() => search.value.length > 0);

  const searchContains = (steam_id: string) => search.value.find(v => ('steam_id' in v) ? v.steam_id == steam_id : null);
  const searchStart = (opts: ChatSearchOpts[]) => search.value = opts;
  const searchStop = () => search.value = [];

  
  const customCensureRegex = computed(() => censure.value.extraWordsHighlight ? censure.value.extraWords.map(v => v) : []);
  const cursedCensureRegex = computed(() => [censure.value.highlight ? russianCursed.toString() : '']);

  const combinedCensureRegex = computed(() => [...customCensureRegex.value, ...cursedCensureRegex.value].filter(v => v));
  
  /**
   * Emoji
   */
  const emojiViews: Record<EmojiView, string> = {
    'none'   : t('chat.filters.effects.emoji.none'),
    'hash'   : t('chat.filters.effects.emoji.hash'),
    'preview': t('chat.filters.effects.emoji.preview'),
  };

  const emojiRawDictionary = import.meta.glob(`/public/emoji/*`);
  const emojiDictionary = Object.fromEntries(Object.keys(emojiRawDictionary).map(path => {
    const nameExtension = path.replace('/public/emoji/', '');

    const extension = nameExtension.split('.')[nameExtension.split('.').length - 1];
    const name = nameExtension.replace(`.${extension}`, '');

    return [name, `/emoji/${name}.${extension}`];
  }));
  
  return {
    types,
    design,
    censure,
    reply,

    emojiViews,
    emojiDictionary,

    search,
    searchContains,
    searchActive,
    searchStart,
    searchStop,

    customCensureRegex,
    cursedCensureRegex,
    combinedCensureRegex
  };
});
