<script setup lang='ts'>
  import { Svg } from '@src/assets/auto_gen_types.dto';
  
  const show = defineModel<boolean>({ default: true });

  const toggle = () => {
    show.value = !show.value;
  };
</script>

<template>
  <div class="accordion">
    <div class="header" @click="toggle">
      <slot name="header" />
      <Svg.arrow class="chevron" :class="show ? '-rotate-90' : 'rotate-90'" />
    </div>
    <div class="body" :class="{ '-opened': show }">
      <div class="inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>

.accordion {
  border-radius: 6px;
  @apply overflow-hidden;

  .header {
    @apply bg-grey-850;
    @apply p-2.5;
    @apply relative;
    @apply text-grey-200;
    @apply cursor-pointer;
    @apply flex items-center justify-between;

    .chevron {
      @apply w-5;
      transition: all .2s;
      @apply fill-grey-500;
    }
  }
  .body {
    display: grid;
    grid-template-rows: 0fr;
    transition-property: grid-template-rows, padding;
    transition-duration: .2s;
    overflow: hidden;
    @apply bg-grey-1000;

    .inner {
      min-height: 0;
      @apply px-2.5;
    }

    &.-opened {
      grid-template-rows: 1fr;
      @apply py-2.5;
    }
  }
}

</style>