<script setup lang='ts'>
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { useChatStore } from '../stores/chat.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';

  const _chat = useChatStore();
  const { t } = useLocaleStore();

  const compiler = (word: string) => {
    if (word.includes("\\")) {
      useRuntimeNotification('warning', t('chat.extra-words-limits'));
      return null;
    }

    if (word === '?') {
      useRuntimeNotification('warning', t('chat.extra-words-limits-question'));
      return null;
    }
    
    return word;
  };
</script>

<template>
  <div>
    <SwitchText 
      v-model="_chat.censure.extraWordsHighlight" 
      :header="t('chat.filters.censor.options.list-words')" 
    />

    <SuggesterInput
      v-if="_chat.censure.extraWordsHighlight"
      v-model="_chat.censure.extraWords"
      :compiler="compiler" 
      class="suggester-input mt-2.5"
      theme="light"
      :placeholder="t('chat.filters.censor.options.list-words-placeholder')"
    >
      <template #default="{ value }">
        <div class="item">
          <p>{{ value }}</p>
        </div>
      </template>
    </SuggesterInput>
  </div>
</template>

<style lang='scss' scoped>
.suggester-input {
  ::placeholder {
    @apply pl-1;
  }
}
.item {
  @apply flex;
  @apply gap-1;
  @apply rounded-md;
  @apply items-center;
  @apply px-1.5 py-1;
  @apply cursor-pointer;
  @apply text-sm font-medium;
  @apply text-grey-400;

  &:hover {
    @apply line-through;
  }
}
</style>