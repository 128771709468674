<script setup lang='ts'>
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import SelectIcon from '@src/assets/icons/select.svg';
  import { BanCheckStatusResult } from '../stores/ban.storage';
  import { BanCheckFinishReason } from '../types/ban.dto';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { computed } from 'vue';

  const { t } = useLocaleStore();

  const checkFinishResults = defineModel<BanCheckStatusResult>({ required: true });
  
  const availableReasons: Record<BanCheckFinishReason, string> = {
    FinishedClear: t('modal.ban.verdict.reason.clean'),
    FinishedBan  : t('modal.ban.verdict.reason.ban'),
    Canceled     : t('modal.ban.verdict.reason.cancel')
  };

  const values = computed(() => {
    return Object.entries(availableReasons).map<SelectValue<BanCheckFinishReason>>(([name, text]) => {
      return {
        value: name as BanCheckFinishReason,
        text
      };
    });
  });

</script>

<template>
  <div class="flex flex-col gap-5">
    <div>
      <p class="label">{{ t('modal.ban.verdict.reason.title') }}</p>
      <SelectInput  
        v-model="checkFinishResults.status"
        :values="values"
        :icon="SelectIcon"
        variant="modal"
        :placeholder="t('modal.ban.verdict.reason.subtitle')"
        style="min-width: 170px;"
        insta-suggest
      />
    </div>
    <TextInput
      v-model="checkFinishResults.comment"
      textarea
      no-border
      height="80"
      :label="t('modal.ban.proofs.comment.title')"
      :placeholder="t('modal.ban.proofs.comment.subtitle')"
      class="!align-text-top"
    />
  </div>
</template>

<style lang='scss' scoped>

.suggest {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply px-1.5 py-1;
  transition: none;
}

:deep(.check-fihish-reason-suggester) {
  &.dark,
  &.light {
    .input-box {
      .value {
        @apply bg-transparent;
      }
    }
  }
}
</style>