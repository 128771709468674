import { PaginatedResponse, PaginationQuery } from "@/api/backend/pagination.service";

import { PlayerAlertDto, PlayerAlertBrowseOpts } from "./player-alert.dto";
import { Axios } from "axios";

export const useCourtPlayerAlertApi = (api: Axios) => {
  const browseFull = async (opts: PaginationQuery & PlayerAlertBrowseOpts) => {
    const result = await api.get<PaginatedResponse<PlayerAlertDto>>(`/player-alert/browse`, {
      params: opts
    });

    return result.data;
  };

  const getCategories = async () => {
    const result = await api.get<string[]>(`/player-alert/categories`);

    return result.data;
  };
  
  const deleteCategory = async (category: string) => {
    const result = await api.delete<string[]>(`/player-alert/categories`, {
      params: {
        category
      }
    });

    return result.data;
  };

  return {
    browseFull,
    getCategories,
    deleteCategory,
  };
};
