<script setup lang="ts">
  import { PlayerAlertDetectRaBanMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { computed, onMounted, ref } from 'vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { ActivateRaBanModal } from '../modals/RaBansModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<{
    meta: PlayerAlertDetectRaBanMetaDto;
    date: number;
  }>();

  const {generate} = useUnknownPlayer();
  const player = ref<PlayerFullDto>();
  
  onMounted(async () => {
    const result = await useCourtApi().player.browseFullBatchLoader.load(props.meta.steam_id);
    if (!result || !('steam_id' in result)) {
      player.value = generate(props.meta.steam_id);
      return;
    }
    player.value = result;
  });

  const is_kicked = computed(() => {
    return props.meta.bans.some(v => v.auto_kicked);
  });
</script>

<template>
  <PlayerAlertBase
    icon="player-alert-types/apple.spoiled.png"
    :date="date"
    :ready="!!player"
  >
    <template v-if="player">
      <template v-if="is_kicked">
        <span 
          class="bright cursor-pointer" 
          @click="ActivatePlayerDetailsModal(player.steam_id)"
        > 
          {{ player.steam_name }} 
        </span>

        <span> {{ t('alert-detect-ra-ban-1') }}</span>

        <span 
          class="!text-orange-300 cursor-pointer" 
          @click="player ? ActivateRaBanModal(player, meta) : undefined"
        >
          {{ t('alert-detect-ra-ban-2') }}
        </span>

        <span>{{ t('alert-detect-ra-ban-3') }}</span>
      </template>
      <template v-else>
        <span> {{ t('alert-detect-ra-ban-4') }}</span>

        <span 
          class="bright cursor-pointer" 
          @click="ActivatePlayerDetailsModal(player.steam_id)"
        > 
          {{ player.steam_name }} 
        </span>

        <span> {{ t('alert-detect-ra-ban-5') }}</span>

        <span 
          class="!text-orange-300 cursor-pointer" 
          @click="player ? ActivateRaBanModal(player, meta) : undefined"
        >
          {{ t('alert-detect-ra-ban-6') }}
        </span>

        <span>{{ t('alert-detect-ra-ban-3') }}</span>
      </template>
    </template>
  </PlayerAlertBase>
</template>