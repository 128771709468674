import { useCoreClientApi } from "./client/client.api";
import { useApiBase } from "../../base.api";
import { defineStore } from "pinia";
import { useCoreProjectApi } from "./project/project.api";
import { useCoreAuthApi } from "./auth/auth.api";
import { useCoreUtilsApi } from "./utils/utils.api";
import { useConfigStore } from "@/stores/config/config.store";

export const useCoreApi = defineStore('core-api', () => {
  const _config = useConfigStore();

  const api = useApiBase(_config.Urls.Core);

  const auth = useCoreAuthApi(api.api);
  const client = useCoreClientApi(api.api);
  const project = useCoreProjectApi(api.api);
  const utils = useCoreUtilsApi(api.api);

  return {
    auth,
    client,
    project,
    utils
  };
});