<script lang="ts" setup>
  import { DicordWebhookDetails } from '@court/pages/integrations/types/discord-webhook.dto';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import SettingsNumeric from '@/components/inputs/numeric-input/SettingsNumeric.vue';
  import { onMounted } from 'vue';
  import { watch } from 'vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { CourtDiscordDto, CourtDiscordType } from '@/api/backend/court/court-discord/court-discord.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import TextInput from '@/components/inputs/TextInput.vue';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    details: DicordWebhookDetails<CourtDiscordType>; 
    updater?: () => unknown 
  }>();

  const webhook = defineModel<CourtDiscordDto>({ required: true });

  onMounted(() => setDefaultValues());
  watch(() => props.details, () => setDefaultValues(), { deep: true });

  const setDefaultValues = () => {
    props.details.options.forEach(v => {
      const target = webhook.value.data as Record<string, unknown>;

      if (v.type === 'number') {
        target[v._key] = v.meta.default;
      } else if (v.type === 'boolean') {
        target[v._key] = v.meta.default;
      } else if (v.type === 'dropdown') {
        target[v._key] = v.meta.default;
      } else if (v.type === 'text') {
        target[v._key] = v.meta.default;
      }
    });
  };
</script>

<template>
  <div class="content">
    <template v-if="!details.options.length">
      <EmptyImage
        class="!h-full !items-center justify-center min-h-[150px]"
        :type="'empty'"
        :title="t('discord-modal-empty-title')"
        :subtitle="t('discord-modal-empty-subtitle')"
      />
    </template>

    <template v-for="opt in details.options" :key="opt._key">
      <template v-if="opt.type === 'boolean'">
        <SwitchText
          v-model="(webhook.data[opt._key] as boolean)"
          :header="opt.title"
          :placeholder="opt.subtitle"
        />
      </template>
      <template v-if="opt.type === 'number'">
        <SettingsNumeric
          v-model="(webhook.data[opt._key] as number)"
          :header="opt.title"
          :placeholder="opt.subtitle"
          :min="(webhook.data[opt.meta.link_key_min ?? ''] as number || opt.meta.min) ?? 0"
          :max="opt.meta.max ?? 100"
        />
      </template>
      <template v-else-if="opt.type === 'dropdown'" />
      <template v-else-if="opt.type === 'text'">
        <TextInput
          v-model="(webhook.data[opt._key] as string)"
          :label="opt.title"
          :placeholder="opt.subtitle"
        />
      </template>
    </template>
  </div>
</template>

<style lang='scss' scoped>
.content {
  @apply flex flex-col gap-5;

  .input-group {
    @apply flex flex-col gap-1;

    .header {
      @apply flex justify-between items-center;

      .label {
        @apply text-grey-400;
        transition: none;
        @apply flex items-center justify-between;
      }

      .add {
        @apply text-primary-700 cursor-pointer;

        &.inactive {
          @apply pointer-events-none text-grey-600;
        }

        &:hover {
          @apply text-primary-600;
        }
      }
    }
  }

  .inputs {
    @apply flex flex-col gap-2.5 w-full;

    .additional-commands {
      @apply flex gap-2.5 items-center w-full;
    }

    .remove {
      @apply bg-red-500/15 text-red-500 rounded-md w-[40px] h-[40px] px-5;

      svg {
        @apply w-5 fill-red-500;
      }

      &:hover {
        @apply bg-red-500/20;
      }
    }
  }

  .variables {
    @apply flex flex-col gap-2;

    .variable {
      @apply flex gap-1 items-center text-sm;

      .main {
        @apply px-1 py-0.5 rounded;
        @apply bg-grey-850;
        @apply text-grey-300;
      }
      .text {
        @apply text-grey-500;
        @apply truncate;
      }
    }
  }
}

.tooltip-content {
  @apply max-w-72 px-1.5 py-1 text-sm text-center;
  @apply text-grey-100;
}
</style>