<script setup lang="ts">
  import { computed } from 'vue';

  const props = withDefaults(defineProps<{
    modelValue?: boolean;
    readonlyValue?: boolean | undefined;
    small?: boolean;
  }>(), {
    modelValue   : undefined,
    readonlyValue: undefined,
    small        : false
  });

  const emit = defineEmits(['update:modelValue']);

  const toggle = () => {
    if (props.modelValue !== undefined) {
      emit('update:modelValue', !props.modelValue);
    }
  };

  const checked = computed(() => {
    if (props.readonlyValue !== undefined) {
      return props.readonlyValue;
    }

    if (props.modelValue !== undefined) {
      return props.modelValue;
    }

    return false;
  });

  defineExpose({ toggle });
</script>

<template>
  <label :class="['custom-switch', { small: small }]">
    <input
      class="hidden peer"
      type="checkbox"
      :checked="checked"
      :disabled="modelValue === undefined"
      @click.stop="toggle"
    >
    <div class="slider peer" />
  </label>
</template>

<style lang="scss" scoped>
.custom-switch {
  position: relative;
  display: inline-flex;
  @apply items-center;
  @apply cursor-pointer;

  .slider {
    @apply w-10 h-6 bg-grey-1000;
    @apply rounded-full;
  }

  .peer::after {
    content: '';
    @apply absolute;
    @apply top-1;
    @apply left-1;
    @apply rounded-full;
    @apply w-4 h-4;
    @apply ease-in-out delay-0 duration-200;
    @apply bg-grey-850
  }
  .peer:hover::after {
    @apply bg-grey-750;
  }
  .peer:checked + .peer {
    @apply bg-primary-700;
  }
  .peer:checked + .peer::after {
    @apply translate-x-full;
    @apply bg-grey-50;
  }

  &.small {
    .slider {
      @apply w-7 h-4;
    }
    .peer::after {
      @apply w-3 h-3;
      @apply top-0.5 left-0.5;
    }
  }
}
</style>
