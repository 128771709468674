import { Centrifuge, PublicationContext } from "centrifuge";
import { DebugService } from "@/api/backend/debug.service";
import { CentrifugoChatMessage, CentrifugoReportDeletedPayload, CentrifugoSituation, CentrifugoStatusPayload, CentrifugoSubscribe } from "./centrifugo.dto";
import { defineStore } from "pinia";
import { AuditDto } from "../backend/court/audit/audit.dto";
import { BanDto } from "../backend/court/ban/ban.dto";
import { CheckActivityDto, CheckDto } from "../backend/court/check/check.dto";
import { PlayerAlertDto } from "../backend/court/player-alert/player-alert.dto";
import { ReportBrowseDto } from "../backend/court/report/report.dto";
import { SignageDto } from "../backend/court/signages/signages.dto";
import { useConfigStore } from "@/stores/config/config.store";

export const Channels = {
  SITUATION: () => 'situation:history',
  REFRESH  : () => 'situation:refresh',
  
  AUDIT_CREATE  : (project_id: number) => `project:audit-create/${project_id}`,
  CHATS         : (project_id: number) => `project:server-chat/${project_id}`,
  REPORTS       : (project_id: number) => `project:server-report/${project_id}`,
  REPORTS_DELETE: (project_id: number) => `project:server-report-deleted/${project_id}`,
  PLAYER_STATUS : (project_id: number) => `project:player-status/${project_id}`,
  PLAYER_ALERT  : (project_id: number) => `project:player-alert-updates/${project_id}`,
  BAN_UPDATED   : (project_id: number) => `project:ban-updates/${project_id}`,
  CHECK_STARTED : (project_id: number) => `project:check-started/${project_id}`,
  CHECK_FINISHED: (project_id: number) => `project:check-finished/${project_id}`,
  CHECK_ACTIVITY: (project_id: number) => `project:check-activity/${project_id}`,
  SIGNAGE_UPDATE: (project_id: number) => `project:signage-update/${project_id}`,

  CLIENT_NOTIFICATION: (client_id: number) => `updates:client#${client_id}`,
  PLAYER_STATES      : (steam_id: string, project_id: number) => `player_states:player$${steam_id},${project_id}`,
};

export const useCentrifugoApi = defineStore('centrifugo', () => {
  const debug = new DebugService(`Centrifugo:mediumpurple`);
  let centrifuge = new Centrifuge(useConfigStore().Urls.Centrifugo);

  const subscribePlayerStatus = (project_id: number, callback: (data: CentrifugoStatusPayload) => void) => {
    const channel = Channels.PLAYER_STATUS(project_id);

    return listen(channel, callback);
  };

  const subscribeBanUpdate = (project_id: number, callback: (data: BanDto) => void) => {
    const channel = Channels.BAN_UPDATED(project_id);

    return listen(channel, callback);
  };

  const subscribeReports = (project_id: number, callback: (data: ReportBrowseDto) => void) => {
    const channel = Channels.REPORTS(project_id);

    return listen(channel, callback);
  };

  const subscribeReportsDelete = (project_id: number, callback: (data: CentrifugoReportDeletedPayload) => void) => {
    const channel = Channels.REPORTS_DELETE(project_id);

    return listen(channel, callback);
  };

  const subscribeSignageUpdate = (project_id: number, callback: (data: SignageDto) => void) => {
    const channel = Channels.SIGNAGE_UPDATE(project_id);

    return listen(channel, callback);
  };

  const subscribeChatMessages = (project_id: number, callback: (data: CentrifugoChatMessage) => void) => {
    const channel = Channels.CHATS(project_id);

    return listen(channel, callback);
  };

  const subscribeSituation = (callback: (data: CentrifugoSituation | null) => void) => {
    const channel = Channels.SITUATION();

    return listen(channel, callback);
  };

  const subscribeRefresh = (callback: (data: CentrifugoSituation | null) => void) => {
    const channel = Channels.REFRESH();

    return listen(channel, callback);
  };

  const subscribeCheckActivity = (projectId: number, callback: (data: CheckActivityDto<unknown>) => void) => {
    const channel = Channels.CHECK_ACTIVITY(projectId);

    return listen(channel, callback);
  };
  
  const subscribeCheckStarted = (project_id: number, callback: (data: CheckDto) => void) => {
    const channel = Channels.CHECK_STARTED(project_id);

    return listen(channel, callback);
  };
  
  const subscribeAudit = (project_id: number, callback: (data: AuditDto) => void) => {
    const channel = Channels.AUDIT_CREATE(project_id);

    return listen(channel, callback);
  };
  
  const subscribePlayerAlert = (project_id: number, callback: (data: PlayerAlertDto) => void) => {
    const channel = Channels.PLAYER_ALERT(project_id);

    return listen(channel, callback);
  };

  const subscribeCheckFinished = (project_id: number, callback: (data: CheckDto) => void) => {
    const channel = Channels.CHECK_FINISHED(project_id);

    return listen(channel, callback);
  };

  const onError = (callback: (err: unknown) => void) => {
    centrifuge.on('error', (err) => callback(err));
  };
  
  const onConnected = (callback: () => void) => {
    centrifuge.on('connected', () => callback());
  };

  const disconnect = () => {
    centrifuge.disconnect();
  };

  const connect = (token?: string) => {
    centrifuge.disconnect();
 
    centrifuge = new Centrifuge(useConfigStore().Urls.Centrifugo, {
      token: token,
    });

    centrifuge.on("error", (ctx) => {
      debug.warn(`Exception`, ctx);
    });

    centrifuge.on("connecting", (ctx) => {
      debug.debug("Connecting", ctx);
    });

    centrifuge.on("connected", (ctx) => {
      debug.debug("Connected", ctx);
    });

    centrifuge.on("disconnected", (ctx) => {
      debug.warn("Disconnected", ctx);
    });

    centrifuge.connect();
  };

  const subscribe = (channel: string) => {
    const exists = centrifuge.getSubscription(channel);
    if (exists) {
      exists.subscribe();
      return exists;
    }

    const sub = centrifuge.newSubscription(channel);

    sub.on("subscribing", (ctx) => {
      debug.debug("Subscribing", ctx);
    });

    sub.on("subscribed", (ctx) => {
      debug.debug("Subscribed", ctx);
    });

    sub.on("unsubscribed", (ctx) => {
      debug.debug("Unsubscribed", ctx);
    });

    sub.subscribe();

    return sub;
  };

  const listen = <T>(channel: string, callback: (data: T) => void): CentrifugoSubscribe => {
    const sub = subscribe(channel);

    const listener = (ctx: PublicationContext) => callback(ctx.data);

    debug.debug("Add event listener to channel", channel);
    sub.on("publication", listener);

    return {
      sub,
      unsubscribe: () => {
        debug.debug("Remove event listener from channel", channel);

        sub.removeListener('publication', listener);

        if (sub.listeners('publication').length === 0) {
          unsubscribe(channel);
        }
      }
    };
  };

  const unsubscribe = (channel: string) => {
    const exists = centrifuge.getSubscription(channel);
    exists?.removeAllListeners("publication");
    exists?.unsubscribe();
  };

  return {
    subscribePlayerStatus,
    subscribeBanUpdate,
    subscribeReports,
    subscribeReportsDelete,
    subscribeSignageUpdate,
    subscribeChatMessages,
    subscribeSituation,
    subscribeRefresh,
    subscribeCheckActivity,
    subscribeCheckStarted,
    subscribeAudit,
    subscribePlayerAlert,
    subscribeCheckFinished,
    onError,
    onConnected,
    disconnect,
    connect,
    subscribe,
    listen,
    unsubscribe,
  };
});
