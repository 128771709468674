
import { Axios } from "axios";
import { CourtSubscriptionStateDto } from "../court/court.dto";

export const useCourtUtilsApi = (api: Axios) => {
  const getSubscription = async () => {
    const result = await api.get<CourtSubscriptionStateDto>(`/utils/subscription`);

    return result.data;
  };

  return { getSubscription };
};
