<script lang="ts"> 
  export type ChartSeries = { 
    name: string; 
 
    color: string; 
    customStrokeColor?: string; 
 
    dashValue?: number; 
    fillGradientOpacity?: number; 
 
    defaultHidden?: boolean; 
 
    data: ChartSeriesData[] 
  }  
 
  export type ChartSeriesData = { 
    x: number | null; // date timestamp 
    y: number; // value 
  } 
</script> 
 
<script lang="ts" setup> 
  import Loading from '@/components/Loading.vue'; 
  import { ApexOptions } from 'apexcharts'; 
  import { ref } from 'vue'; 
  import { computed } from 'vue'; 
  import { useLocaleStore } from "@/stores/locale.store";
  import dayjs from 'dayjs';
  
  const { t } = useLocaleStore();
 
  const props = defineProps<{ 
    title: string; 
 
    series: ChartSeries[] | null; 
    options?: ApexOptions; 

    mode: 'days' | 'monthes';
 
    sharedGroup?: string; 
  }>(); 
 
  const chartId = ref(Math.random().toString()); 
  const useCumulativeChart = ref<boolean>(false); 
 
  const computedSeries = computed(() => { 
    if (!props.series) { 
      return null; 
    } 
 
    if (useCumulativeChart.value) { 
      return props.series.map(s => { 
        return { 
          ...s, 
 
          data: s.data.reduce<ChartSeriesData[]>((acc, v) => { 
            const prev = acc[acc.length - 1]; 
 
 
            acc.push({ 
              x: v.x, 
              y: (prev?.y ?? 0) + (v?.y ?? 0) 
            }); 
 
            return acc; 
          }, []) 
        }; 
      }); 
    } 
 
    return props.series; 
  }); 
 
  const computedOptions = computed(() => { 
    if (!computedSeries.value) { 
      return; 
    } 

 
    const defaultOptions: ApexOptions = { 
      chart: { 
        id     : chartId.value, 
        group  : props.sharedGroup, 
        toolbar: { 
          show: false, 
        }, 
        type: 'bar', 
        zoom: { 
          enabled: false 
        }, 
        dropShadow: { 
          enabled: false, 
          blur   : 12 
        }, 
        foreColor : "#F2F2F2",
        animations: {
          enabled: true,
          easing : 'easeinout',
          speed  : 200,
        },
      },

      legend: {
        show: false,
      },

      markers: { 
        size        : 0, 
        colors      : ["#1E1E1E"], 
        strokeColors: computedSeries.value.map(v => v.customStrokeColor ?? v.color), 
        strokeWidth : 2 
      }, 

      noData: { 
        text : 'No Data', 
        style: { 
          color     : '#F2F2F2', 
          fontSize  : '14px', 
          fontFamily: 'inherit' 
        } 
      }, 

      grid: { 
        show           : true, 
        borderColor    : '#3D3D3D', 
        strokeDashArray: 4, 
        xaxis          : { 
          lines: { 
            show: false, 
          }, 
 
        }, 
        yaxis: { 
          lines: { 
            show: true 
          } 
        }, 
        padding: { 
          top   : -12, 
          right : 18, 
          bottom: 0, 
          left  : 18 
        },  
      },

      xaxis: { 
        type  : 'datetime', 
        labels: { 
          show   : true,
          offsetY: -2, 
          style  : { 
            cssClass: 'apexcharts-xaxis-label', 
          },

        }, 
 
        axisBorder: { 
          show: false,
        }, 
        axisTicks: { 
          show: false,  
        }, 
        tooltip: { 
          enabled: false, 
        }, 
        crosshairs: { 
          show   : true,
          opacity: 0.4, 
        }, 
      }, 

      yaxis: { 
        max       : Math.max(...(props.series ?? []).map(v => v.data).flat(1).map(v => v.y), 12),
        show      : true, 
        floating  : true,
        tickAmount: 6,
        labels    : { 
          show   : true, 
          align  : 'left', 
          offsetY: 0, 
          offsetX: 4, 
          style  : { 
            cssClass: 'apexcharts-yaxis-label', 
          },
          formatter: function(value) {
            return Math.floor(value).toString();
          },
          
        },
        decimalsInFloat: 1
      }, 

      dataLabels: { 
        enabled: false 
      }, 

      tooltip: { 
        enabled     : true,
        followCursor: true,
        theme       : 'false', 
        x           : { 
          show: true, 
          
          
        },
        y: {
          formatter: function(value) {
            return value.toFixed(1);
          }
        },
        style: {
          fontFamily: 'Inter',
        },intersect: false,
        marker   : {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          barHeight: '100%'
        }
      },
    }; 
 
    return Object.assign(defaultOptions, props.options); 
  }); 
 
</script> 
 
<template> 
  <div class="item relative"> 
    <Loading :can-view="!!(computedOptions && computedSeries)" class="!bg-grey-900" /> 
 
    <div class="header"> 
      {{ title }} 
    </div> 
    <div class="chart"> 
      <template v-if="computedOptions && computedSeries"> 
        <apexchart 
          width="100%" 
          height="100%"  
          class="h-full w-full" 
          :options="computedOptions" 
          :series="computedSeries" 
        /> 
      </template> 
    </div> 
  </div> 
</template> 
 
 
<style  lang="scss" scoped> 
 
:deep(.apexcharts-canvas), :deep(.apexcharts-yaxis) { 
  .apexcharts-tooltip { 
    @apply bg-grey-800 text-grey-200;
    box-shadow: rgba(20, 20, 20, 0.8) 0px 5px 20px;
  } 
 
  .apexcharts-yaxis-label { 
    @apply fill-grey-600; 
  } 
  .apexcharts-xaxis-label { 
    @apply fill-grey-600; 
  } 
  .apexcharts-xaxis-label, .apexcharts-yaxis-label { 
    @apply font-medium; 
  } 
 
  .apexcharts-tooltip-title { 
    @apply bg-grey-700 text-grey-50; 
    @apply font-semibold; 
    margin-bottom: 6px; 
  } 
  .apexcharts-tooltip-series-group { 
    @apply text-grey-400; 
    padding: 0px 6px 6px 6px; 
  } 
  .apexcharts-tooltip-marker { 
    margin-right: 6px; 
    width: 8px; 
    height: 8px; 
    border-radius: 2px; 
  } 
  .apexcharts-tooltip-text-y-value { 
    @apply ml-0; 
    @apply text-grey-200; 
  } 
  .apexcharts-tooltip-y-group { 
    @apply p-0; 
  } 
 
  .apexcharts-legend-series { 
    margin: 0px !important; 
  } 
  .apexcharts-legend { 
    @apply flex gap-2; 
  } 
  .apexcharts-legend-text { 
    @apply text-grey-400 #{!important}; 
  } 
  .apexcharts-legend-marker { 
    margin-right: 5px; 
  } 
} 
 
.item { 
  @apply flex flex-col relative; 
  @apply rounded-md; 
  @apply bg-grey-850; 
 
  .chart { 
    @apply grow; 
  } 
 
  .header { 
    @apply p-2.5; 
    @apply shrink-0; 
    @apply text-grey-400 text-xs font-medium;
    @apply border-b border-grey-900;
  } 
} 
 
</style>