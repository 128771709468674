<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';

  const props = withDefaults(defineProps<{
    withoutControls?: boolean;
    step?: number;
    inputtable?: boolean;
    disabled?: boolean;

    label?: string;
    inputHeight?: string; 
    autofocus?: boolean; 
    max?: number;
    min?: number;
    autowidth?: boolean;
    center?: boolean;
  
    theme?: 'light' | 'middle' | 'default' | 'dark' | 'blur';
  }>(), {
    withoutControls: false,
    step           : 1,
    inputtable     : true,
    disabled       : false,

    label      : undefined,
    placeholder: undefined,
    inputHeight: '40px',

    max: Infinity,
    min: -Infinity,
    
    theme: 'default',
  });

  const getBackground = computed(() => {
    switch (props.theme) {
      case 'light': {
        return 'bg-grey-750 placeholder:text-grey-750';
      }

      case 'middle': {
        return 'bg-grey-900 placeholder:text-grey-600';
      }

      case 'dark': {
        return 'bg-grey-950 placeholder:text-grey-750';
      }

      case 'blur': {
        return 'bg-grey-1000/25 placeholder:text-grey-750';
      }

      case 'default':
      default: {
        return 'bg-grey-1000 placeholder:text-grey-750';
      }
    }
  });

  const modelValue = defineModel<number>({ required: true });
  const internalModelValue = ref<string | number>(0);

  // Должны реагировать на внешние изменение модели и подгонять свою
  onMounted(() => syncModels());
  watch(() => modelValue.value, () => syncModels(), { deep: true });

  // Реагируем на своё состояние
  watch(() => internalModelValue.value, (v) => {
    // Если всё стёрто, или введен "-" - не обновляем родительскую модель, чтобы не сбивать юзера с возможности написать -1
    if (v.toString() == '-' || v.toString().length == 0) {
      return;
    }    

    apply();
  });

  watch(() => props.min, () => {
    syncModels();
  });

  const syncModels = () => {
    internalModelValue.value = (modelValue.value ?? '').toString();

    apply();
  };

  const apply = () => {
    const correct = repairNumber(+internalModelValue.value);
  
    modelValue.value = correct;
    internalModelValue.value = correct;
  };

  const repairNumber = (number: number) => {
    if (!Number.isInteger(number)) {
      return Number.isInteger(props.min) ? props.min : 0;
    }

    if (number > props.max || number < props.min) {
      return Math.max(Math.min(props.max, number), props.min);
    }

    return number;
  };

  const plusDisabled = computed(() => modelValue.value >= props.max || props.disabled);
  const minusDisabled = computed(() => modelValue.value <= props.min || props.disabled);

  // ретёрн, если значение УЖЕ больше max или БУДЕТ после прибавления (например step = 10)
  const plus = () => {
    internalModelValue.value = Math.max(props.min, modelValue.value + props.step);

    apply();
  };

  const minus = () => {
    internalModelValue.value = Math.max(props.min, modelValue.value - props.step);

    apply();
  };
</script>

<template>
  <div class="input-container">
    <label class="text-14-500 text-grey-400">
      {{ label }}
    </label>
    <div class="input-box" :class="[getBackground, { 'no-controls': withoutControls}]">
      <input
        v-model="internalModelValue"
        type="number"
        inputmode="numeric"
        :min="min"
        :max="max"
        :style="`width: ${internalModelValue.toString().length || 1}ch`"
        :class="{'text-center': center}"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="!inputtable"
        @focusout="apply"
        @keydown="(e) => e.key === 'e' ? e.preventDefault() : undefined"
      >
      <template v-if="!withoutControls">
        <div
          class="control minus"
          :class="{disabled: minusDisabled}"
          @click="minus"
        >
          -
        </div>
        <div
          class="control plus"
          :class="{disabled: plusDisabled}"
          @click="plus"
        >
          +
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input-container {

  .input-box {
    @apply rounded-md overflow-hidden;
    @apply relative;
    height: v-bind(inputHeight);
    padding: 0 calc(v-bind(inputHeight) + 6px) ;

    &.no-controls {
      padding: 0 10px;
    }

    input {
      @apply bg-transparent;
      @apply text-grey-50;
      @apply w-full h-full;
      @apply tabular-nums;
    }

    .control {
      @apply absolute top-0 bottom-0;
      @apply h-full aspect-square;
      @apply text-center;
      @apply cursor-pointer select-none;
      @apply bg-grey-700;
      @apply text-18-600 text-grey-400;
      line-height: calc(v-bind(inputHeight) - 2px);
      transition: all .2s;

      &.disabled {
        @apply bg-grey-800 text-grey-600;
        @apply pointer-events-none;
      }

      &:hover {
        @apply bg-grey-650;
        @apply text-grey-50;
      }

      &.minus {
        @apply left-0;
      }

      &.plus {
        @apply right-0;
      }
    }
  }
  &.error {
    label {
      @apply text-red-500;
    }

    input {
      @apply text-red-500;
    }
  }
}
</style>