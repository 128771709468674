import { ampli } from "@src/ampli";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export type ViewDevice = 'desktop' | 'laptop' | 'tablet' | 'mobile';
export type ViewMode = 'left' | 'right' | 'both' | 'none';

export const useAdaptiveStore = defineStore('adaptive', () => {
  // Слушаем событие чтобы во время обновлять размер окна
  window.addEventListener('resize', () => onResize());

  const isTouchDevice = () => (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);

  const onResize = () => {
    if (window.innerWidth < 600) {
      switchDevice('mobile');
      return;
    }
    
    if (window.innerWidth < 1160) {
      switchDevice('tablet');
      return;
    }
    
    if (window.innerWidth < 1400) {
      switchDevice('laptop');
      return;
    }

    switchDevice('desktop');
  };

  const viewDevice = ref<ViewDevice>('mobile');

  const leftBar = ref<boolean>();
  const rightBar = ref<boolean>();

  watch(() => leftBar.value, (v) => document.documentElement.toggleAttribute('left-bar', v));
  watch(() => rightBar.value, (v) => document.documentElement.toggleAttribute('right-bar', v));

  const editLeftBar = (value?: boolean) => {
    leftBar.value = value === undefined ? !leftBar.value : value;
  };

  const editRightBar = (value?: boolean, force?: boolean) => {
    if (value && ['mobile', 'tablet', 'laptop'].includes(viewDevice.value) && !force) {
      return;
    }

    rightBar.value = value === undefined ? !rightBar.value : value;
  };

  const switchDevice = (value: ViewDevice) => {
    if (value === 'desktop' || value === 'laptop') {
      editLeftBar(true);
    } else {
      editLeftBar(false);
      editRightBar(false);
    }
    
    if (viewDevice.value != value) {
      ampli.client.setGroup('version', document.getElementsByTagName('body')?.[0]?.getAttribute?.('version') ?? 'no-version');
      ampli.client.setGroup('adaptive', value);

      ampli.client.setGroup('maxTouchPoints', navigator.maxTouchPoints?.toString());
      ampli.client.setGroup('msMaxTouchPoints', (navigator as any).msMaxTouchPoints?.toString());
      ampli.client.setGroup('onTouchStartDocument', ('ontouchstart' in document.documentElement) ? 'yes' : 'no');
      ampli.client.setGroup('onTouchStartWindow', ('ontouchstart' in window) ? 'yes' : 'no');
    }

    viewDevice.value = value;

    document.documentElement.setAttribute('device', viewDevice.value);
  };

  onResize();

  const isDesktop = computed(() => viewDevice.value === 'desktop');
  const isLaptop = computed(() => viewDevice.value === 'laptop');
  const isTablet = computed(() => viewDevice.value === 'tablet');
  const isMobile = computed(() => viewDevice.value === 'mobile');

  return { isTablet, isMobile, isLaptop, isDesktop, isTouchDevice, editLeftBar, editRightBar };
});