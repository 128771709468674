<script setup lang="ts">
  import TableBase, { TableHeaders } from '@/components/table/TableBase.vue';
  import { PlayerAlertCustomApiMetaDto, PlayerAlertDetectRaBanMetaDto, PlayerAlertDict, PlayerAlertDto, PlayerAlertDugUpStashMetaDto, PlayerAlertJoinWithExternalBanMetaDto, PlayerAlertJoinWithIpBanMetaDto, PlayerAlertLicenseChangedMetaDto, PlayerAlertType, PlayerAlertVpnDetectedMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { Ref, computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import PlayerAlertJoinWithIpBan from './PlayerAlertJoinWithIpBan.vue';
  import PlayerAlertDugUpStash from './PlayerAlertDugUpStash.vue';
  import PlayerAlertCustomApi from './PlayerAlertCustomApi.vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerAlertJoinWithExternalBan from './PlayerAlertJoinWithExternalBan.vue';
  import { usePlayerAlertStore } from '../stores/player-alert.store';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import PlayerAlertDetectRaBan from './PlayerAlertDetectRaBan.vue';
  import PlayerAlertVpnDetected from './PlayerAlertVpnDetected.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import PlayerAlertLicenseChanged from './PlayerAlertLicenseChanged.vue';
  
  export type PlayerAlertHeaders = TableHeaders<('main')>;

  const { t } = useLocaleStore();
  const _panel = usePanelStore();
  const _playerAlerts = usePlayerAlertStore();

  const getUsageTypes = computed(() => {
    const types: PlayerAlertType[] = [];

    if (_playerAlerts.filters.show_dug_up_stash) {
      types.push('dug_up_stash');
    }

    if (_playerAlerts.filters.show_join_with_external_ban) {
      types.push('join_with_external_ban');
    }

    if (_playerAlerts.filters.show_join_with_ip_ban) {
      types.push('join_with_ip_ban');
    }

    if (_playerAlerts.filters.detect_ra_ban) {
      types.push('detect_ra_ban');
    }

    if (_playerAlerts.filters.vpn_detected) {
      types.push('vpn_detected');
    }

    if (_playerAlerts.filters.license_changed) {
      types.push('license_changed');
    }

    const categories = Object.entries(_playerAlerts.filters.custom_api_categories).filter(([key, value]) => !!value).map(([key]) => key);
    if (_playerAlerts.filters.custom_api && categories.length > 0) {
      types.push('custom_api');
    }

    if (types.length == Object.keys(PlayerAlertDict).length) {
      return undefined;
    }

    return types;
  });

  const loader = computed(() => {
    const types = getUsageTypes.value || undefined;
    const categories = Object.entries(_playerAlerts.filters.custom_api_categories).filter(([key, value]) => !!value).map(([key]) => key);

    return new PaginationService<PlayerAlertDto>((page, limit) => {
      if (types && !types.length) {
        return Promise.resolve({
          limit  : limit,
          page,
          results: []
        });
      }

      return useCourtApi().playerAlert.browseFull({ page, limit, type: types, categories: categories, hide_resolved: true });
    });
  });
  
  const headers = computed<PlayerAlertHeaders>(() => ({ 
    main: {
      text: ''
    },
  }));

  const items = ref<PlayerAlertDto[] | null>(null) as Ref<PlayerAlertDto[] | null>;

  const centrifugoAlertCreated = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoAlertCreated.value = useCentrifugoApi().subscribePlayerAlert(_panel.project.id, (alert) => {
      if (!items.value) {
        return;
      }

      if (alert.hide_in_table) {
        return;
      }

      if (alert.type == "join_with_ip_ban" && _playerAlerts.filters.show_join_with_ip_ban) { 
        items.value.unshift(alert);
      }

      if (alert.type == "dug_up_stash" && _playerAlerts.filters.show_dug_up_stash) { 
        items.value.unshift(alert);
      }

      if (alert.type == "join_with_external_ban" && _playerAlerts.filters.show_join_with_external_ban) { 
        items.value.unshift(alert);
      }

      if (alert.type == "detect_ra_ban" && _playerAlerts.filters.detect_ra_ban) { 
        items.value.unshift(alert);
      }

      if (alert.type === 'vpn_detected' && _playerAlerts.filters.vpn_detected) {
        items.value.unshift(alert);
      }

      if (alert.type === 'license_changed' && _playerAlerts.filters.license_changed) {
        items.value.unshift(alert);
      }

      if (alert.type == 'custom_api' && _playerAlerts.filters.custom_api) {
        if (_playerAlerts.filters.custom_api_categories[(alert.meta as PlayerAlertCustomApiMetaDto).category ?? '']) {
          items.value.unshift(alert);
        }
      }
    });
  });

  onUnmounted(() => {
    centrifugoAlertCreated.value?.unsubscribe();
  });
  
  watch(() => loader.value, () => {
    items.value = null;
    next();
  });

  const next = async () => {
    const loaderHash = loader.value.id;
    
    const result = await loader.value.next();

    if (loader.value.id !== loaderHash) {
      return;
    }    
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  const warningText = computed(() => {
    if (getUsageTypes.value === undefined) {
      return undefined;
    }

    return t('general.no-data-filters');
  });
</script>

<template>
  <TableBase
    :next="next"
    hide-header-line
    class="table-base"
    :items="items"
    :item-key="(o) => o.id.toString()"
    :headers="headers"

    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"

    :missing-data-warning="warningText"
  >
    <template #main="{item}">
      <template v-if="!item">
        <PlayerAlertBase />
      </template>

      <template v-else>
        <div class="flex items-center gap-5">
          <!-- <template v-if="item">
            <span class="text-grey-400 font-medium tabular-nums">{{ _time.format(item.created_at, 'DD MMM HH:mm', true) }}</span>
          </template> -->
          <template v-if="item.type === 'join_with_ip_ban'">
            <PlayerAlertJoinWithIpBan :meta="(item.meta as PlayerAlertJoinWithIpBanMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'join_with_external_ban'">
            <PlayerAlertJoinWithExternalBan :meta="(item.meta as PlayerAlertJoinWithExternalBanMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'dug_up_stash'">
            <PlayerAlertDugUpStash :meta="(item.meta as PlayerAlertDugUpStashMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'custom_api'">
            <PlayerAlertCustomApi :meta="(item.meta as PlayerAlertCustomApiMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'detect_ra_ban'">
            <PlayerAlertDetectRaBan :meta="(item.meta as PlayerAlertDetectRaBanMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'license_changed'">
            <PlayerAlertLicenseChanged :meta="(item.meta as PlayerAlertLicenseChangedMetaDto)" :date="item.created_at" />
          </template>
          <template v-if="item.type === 'vpn_detected'">
            <PlayerAlertVpnDetected :meta="(item.meta as PlayerAlertVpnDetectedMetaDto)" :date="item.created_at" />
          </template>
        </div> 
      </template>
    </template>
  </TableBase>
</template>

<style lang="scss" scoped>
.table-base {
  &:deep(.content-layer) {
    @apply w-full;
    .row {
      @apply h-auto;
    }
  }
}
</style>