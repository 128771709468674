<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { PlayerAlertDugUpStashMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<{
    meta: PlayerAlertDugUpStashMetaDto;
    date: number;
  }>();

  const { t } = useLocaleStore();
  const { generate } = useUnknownPlayer();

  const player = ref<PlayerMinimalDto>();
  const owner = ref<PlayerMinimalDto>();

  onMounted(async () => {
    const [loadedPlayer, loadedOwner] = await useCourtApi().player.browseMinimalBatchLoader.loadMany(
      [
        props.meta.steam_id, 
        props.meta.owner_steam_id
      ]
    );

    if (!loadedPlayer || !('steam_id' in loadedPlayer)) {
      player.value = generate(props.meta.steam_id);
    } else {
      player.value = loadedPlayer;
    }

    if (!loadedOwner || !('steam_id' in loadedOwner)) {
      owner.value = generate(props.meta.owner_steam_id);
    } else {
      owner.value = loadedOwner;
    }
  });

  const openPlayer = (id: string) => {
    ActivatePlayerDetailsModal(id);
  };
</script>

<template>
  <PlayerAlertBase
    icon="player-alert-types/stash.png"
    :date="date"
    :ready="!!player && !!owner"
  >
    <template v-if="player">
      <span> {{ t('alerts.stash.player') }} </span>
      
      <span 
        class="bright cursor-pointer" 
        @click="openPlayer(props.meta.steam_id)"
      > {{ player?.steam_name }} </span>

      <span> {{ t('alerts.stash.dug-up') }} </span>

      <span 
        class="bright cursor-pointer" 
        @click="openPlayer(props.meta.owner_steam_id)"
      > {{ owner?.steam_name }} </span>

      <span> {{ t('alerts.stash.square') }} </span>
      <span class="bright"> {{ meta.square }} </span>
    </template>
  </PlayerAlertBase>
</template>

<style lang="scss" scoped>
</style>