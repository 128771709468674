<script lang="ts" setup>
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed, ref, toRefs } from 'vue';
  const { t } = useLocaleStore();
  const props = defineProps<{
    perRow: number;
    title?: string;
    maxRows?: number;
    amount: number;
  }>();

  const isOpened = ref<boolean>(false);

  const shownCellsAmount = computed(() => {
    // если 
    //   а) открыта карточка 
    //   б) нет ограничений по кол-ву строк 
    //   в) все айтемы помещаются в ограничение
    if (isOpened.value || !props.maxRows || props.amount <= (props.perRow * props.maxRows)) {
      return props.amount;
    }

    return props.maxRows * props.perRow;
  });

  const amountForFullRow = computed(() => {
    const remainder = shownCellsAmount.value % props.perRow;
    return remainder ? (props.perRow - remainder) : 0;
  });
</script>

<template>
  <div class="table-card">
    <div class="w-full px-2.5 py-1.5">
      <p class="text-grey-50 text-16-500">{{ title }}</p>
    </div>
    <div class="cells" :class="`grid-cols-${perRow}`">
      <template v-for="i in shownCellsAmount" :key="i">
        <div class="cell">
          <slot :name="`col${i - 1}`" />
        </div>
      </template>

      <!-- фейковые клеточки  -->
      <template v-for="_ in amountForFullRow" :key="_">
        <div class="cell fake" />
      </template>

      <div
        v-if="maxRows && amount > (perRow * maxRows)"
        class="cell full"
        @click="isOpened = !isOpened"
      >
        <Svg.chevron_grabber_vertical class="w-4" />
        <p>{{ isOpened ? t('player-hide') : t('player-show-all', { amount: amount - shownCellsAmount }) }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-card {
  @apply rounded-md;
  @apply bg-grey-850;
  
  .cells {
    @apply p-px;
    @apply grid gap-px;
    @apply rounded-[8px];
    @apply overflow-hidden;

    .cell {
      @apply text-white;
      @apply bg-grey-900;
      @apply p-2.5;

      &.full {
        @apply text-center;
        grid-column: 1 / -1;
        @apply cursor-pointer;
        @apply flex items-center justify-center;
        @apply text-primary-700;
        @apply text-sm font-semibold;

        svg {
          @apply fill-primary-700;
        }

        &:hover {
          @apply text-primary-500;

          svg {
            @apply fill-primary-500;
          }
        }
      }
    }
  }
}
</style>