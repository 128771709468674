<script lang="ts">
  export const ActivateEmailConfirm = () => {
    const _modal = useModalsStore();

    _modal.open('email-confirm', {});
  };
</script>

<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import DividedCard from '@/components/DividedCard.vue';
  import CodeInput from '@/components/inputs/CodeInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ref } from 'vue';

  const { t } = useLocaleStore();

  const _app = useAppStore();

  const props = defineProps<ModalInjectedProps>();

  const confirm = async (code?: string) => {
    if (!_app.client || !code) {
      return;
    }

    try {
      await useCoreApi().auth.emailConfirmFinish(code);

      _app.client.email_confirmed = true;

      props.injected.close();
    }
    catch (err: any) {
      if (err?.message?.includes?.('Confirmation object not found')) {
        useRuntimeNotification('error', t('email-confirm-bad'));
        codeInput.value?.reset();
        return;
      }

      throw err;
    }
  };
  const codeInput = ref<InstanceType<typeof CodeInput>>();
</script>

<template>
  <div class="modal">
    <DividedCard no-content-padding>
      <template #default>
        <div class="content">
          <div class="header">
            <p class="title">{{ t('enter-email-confirm-code') }}</p>
            <p class="subtitle">{{ t('we-sent-confirm-email') }} {{ _app.client?.email || 'unknown@gmail.com' }}</p>
          </div>

          <CodeInput
            ref="codeInput"
            :amount="4"
            height="55"
            class="mt-5"
            @submit="(e) => confirm(e)"
          />

          <div class="w-full text-center text-base mt-5">
            <span class="text-grey-500">{{ t('no-email-code-question') }}</span>
            <a
              href="https://t.me/rustapp_help"
              target="_blank"
              class="text-primary-500 cursor-pointer"
            >{{ ' ' + t('connect-us') }}</a>
          </div>
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  width: 95vw;
  max-width: min(95vw, 450px);
  min-height: 200px;

  .content {
    @apply p-10;

    .header {
      @apply flex flex-col gap-1;

      .title {
        @apply text-center;
        @apply text-xl font-semibold text-grey-50;
      }

      .subtitle {
        @apply text-center;
        @apply text-base text-grey-500;
      }
    }
  }
}
</style>