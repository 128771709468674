<script lang="ts" setup>
  import TextInput from '@/components/inputs/TextInput.vue';
  import { computed } from 'vue';
  import { AllowedLanguage, useLocaleStore } from '@/stores/locale.store';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { CourtDiscordDto } from '@/api/backend/court/court-discord/court-discord.dto';

  const { t } = useLocaleStore();
  const _validator = useValidators();

  const info = defineModel<CourtDiscordDto>({ required: true });
  
  const languages = computed<SelectValue<AllowedLanguage>[]>(() => {
    return [
      {
        value: 'ru',
        text : t('account-menu.language.ru')
      },
      {
        value: 'en',
        text : t('account-menu.language.en')
      },
      {
        value: 'uk',
        text : t('account-menu.language.uk')
      }
    ];
  });
</script>

<template>
  <div class="content">
    <SelectInput
      v-model="info.language"
      class="w-full"
      variant="modal"
      :values="languages"
      :label="t('dw-edit-language-title')"
    />
    
    <TextInput
      v-model="info.webhook"
      :label="t('dw-edit-webhook-title')"
      :placeholder="t('dw-edit-webhook-placeholder')"
      :validator="_validator.Url"
    />
  </div>
</template>

<style lang='scss' scoped>
.content {
  @apply flex flex-col gap-5;
}

.label {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}

.suggester-input {
  ::placeholder {
    @apply pl-0.5;
  }
}

.suggest {
  @apply w-full;
  @apply flex gap-1.5;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;

  svg {
    @apply w-4;
  }

  &.green {
    svg {
      @apply fill-lime-500;
    }
  }
  &.yellow {
    svg {
      @apply fill-amber-500;
    }
  }
  &.red {
    svg {
      @apply fill-red-500;
    }
  }
  &.admin {
    svg {
      @apply fill-grey-200;
    }
  }
}

.item {
  @apply flex gap-1.5;
  @apply py-1 px-1.5;
  @apply text-base leading-5;

  svg {
    @apply w-4;
  }

  &.green {
    svg {
      @apply fill-lime-500;
    }
  }
  &.yellow {
    svg {
      @apply fill-amber-500;
    }
  }
  &.red {
    svg {
      @apply fill-red-500;
    }
  }
  &.admin {
    svg {
      @apply fill-grey-200;
    }
  }
}

.tooltip-content {
  @apply px-2 py-1 max-w-[250px] text-sm;
}
</style>