/* eslint-disable no-console */
import { EnvVariables } from "@/stores/config/env.variables";

export class DebugService {
  public static debug(message: string, args?: unknown, context?: string) {
    if (!EnvVariables.IsProd()) {
      DebugService.log(message, args, context);
    }
  }

  public static log(message: string, args?: unknown, context?: string) {
    if (!EnvVariables.IsProd()) {
      console.log(...DebugService.buildTag(context), message, args);
    }
  }

  public static error(message: string, args?: unknown, context?: string) {
    if (!EnvVariables.IsProd()) {
      console.error(...DebugService.buildTag(context), message, args);
    }
  }

  public static warn(message: string, args?: unknown, context?: string) {
    if (!EnvVariables.IsProd()) {
      console.warn(...DebugService.buildTag(context), message, args);
    }
  }

  static buildTag(context?: string) {
    const tag = context ? context : `-:silver`;

    const global = "border-radius: 10px; font-weight: medium;";

    const [name, color] = tag.split(":");

    return [`%c ${name} `, `background: ${color}; ${global}`];
  }

  constructor(private name: string) {}

  public debug(message: string, arg?: unknown) {
    DebugService.debug(message, arg, this.name);
  }

  public log(message: string, arg?: unknown) {
    DebugService.log(message, arg, this.name);
  }

  public warn(message: string, arg?: unknown) {
    DebugService.warn(message, arg, this.name);
  }

  public error(message: string, arg?: unknown) {
    DebugService.error(message, arg, this.name);
  }
}
