<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { PlayerIpHistoryDto } from '@/api/backend/court/player/player.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { computed, onMounted, ref } from 'vue';
  import PlayerDetailsCard, { PlayerDetailsCardRow } from '../../card/PlayerDetailsCard.vue';
  import { useTimeStore } from '@/stores/time.store';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerDetailsCardBadge from '../../card/PlayerDetailsCardBadge.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Button from '@/components/Button.vue';
  import BaseTip from '@panel/components/BaseTip.vue';
  import Divider from '@/components/Divider.vue';

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const props = defineProps<{ steamId: string }>();

  const data = ref<PlayerIpHistoryDto[] | null>(null);
  const loader = new PaginationService((page, limit) => useCourtApi().player.browsePlayerIpHistory(props.steamId, {
    page,
    limit
  }), 20);
  
  onMounted(() => next());

  const next = async () => {
    const { results } = await loader.next();

    if (!data.value) {
      data.value = [];
    }

    data.value?.push(...results);
  };

  const rows = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title : t('general.ip'),
        inline: false
      },
      {
        title : t('general.country'),
        inline: false
      },
      {
        title     : t('general.provider'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });
</script>

<template>
  <template v-if="data && data.length == 0">
    <div class="empty-image-container">
      <EmptyImage
        type="empty"
        :title="t('general.empty.no-data.title')"
        :subtitle="t('general.empty.no-data.subtitle')"
      />
    </div>
  </template>
  <template v-else>
    <Loading :can-view="data != null" class="!bg-transparent" />
    
    <template v-if="data != null">
      <BaseTip
        type="hint"
        disposable="ip-history"
        class="mb-5"
      >
        {{ t('basetip-ip-history') }}
      </BaseTip>

      <div class="content">
        <template v-for="value in data" :key="value.created_at">
          <PlayerDetailsCard
            :date-from="_time.format(value.created_at)"
            :rows="rows"
          >
            <template #badges>
              <PlayerDetailsCardBadge
                v-if="value.computed_ip?.proxy"
                preset="amber"
              >
                <template #default>
                  <p class="!px-1 text-sm">{{ 'VPN' }}</p>
                </template>
              </PlayerDetailsCardBadge>
              <PlayerDetailsCardBadge
                v-if="value.project_id == -1"
                preset="blue"
                :tooltip-text="t('general.data-from-another-project')"
              >
                <template #default>
                  <Svg.share />
                </template>
              </PlayerDetailsCardBadge>
            </template>
            <template #row0>
              <p class="hyperlink" @click="ActivateIpDetailsModal(value.ip)">{{ value.ip }}</p>
            </template>
            <template #row1>
              <p class="country">
                <img
                  v-if="value.computed_ip?.country_code"
                  class="flag"
                  style="height: 16px; width: 16px;"
                  :src="`https://hatscripts.github.io/circle-flags/flags/${value.computed_ip?.country_code.toLowerCase()}.svg`"
                >
                {{ value.computed_ip?.country_name || t('general.unknown') }}
              </p>
            </template>
            <template #row2>
              {{ value.computed_ip?.provider || t('general.unknown') }}
            </template>
            <template #actions>
              <Button
                preset="default-light"
                class="!p-1 !rounded"
                :action="() => ActivateIpDetailsModal(value.ip)"
              >
                <Svg.open class="fill-frey-50 !w-[20px]" />
              </Button>
            </template>
          </PlayerDetailsCard>
        </template>
      </div>
    </template>

    <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
  </template>
</template>

<style scoped lang="scss">
.content {
  @apply flex flex-col gap-2.5;
}

.country {
  @apply flex items-center;

  .flag {
    @apply object-cover bg-grey-900 rounded-half mr-1;
  }
}
</style>