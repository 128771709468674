import { StatsDto } from "@/api/types/general.dto";

import { CheckActivityDto, CheckDto, CheckFinishPayloadDto, CheckStatsDto } from "./check.dto";
import { PaginatedResponse } from "@/api/backend/pagination.service";
import { Axios } from "axios";

export const useCourtCheckApi = (api: Axios) => {
  const browse = async(opts: { page: number; limit: number; steam_ids?: string[]; search?: string; client_ids?: number[]; contact?: string; }): Promise<PaginatedResponse<CheckDto>> => {
    const results = await api.get<PaginatedResponse<CheckDto>>("/check/browse", {
      params: {
        ...opts
      },
    });

    return results.data;
  };

  const stats = async(from: number, to: number, client_id?: number) => {
    const results = await api.get<CheckStatsDto>(`/check/stats`, {
      params: {
        from: from, 
        to  : to,
        client_id
      }
    });

    return results.data;
  };

  const getActivity = async(check_id: number) => {
    const result = await api.get<CheckActivityDto<unknown>[]>(`/check/${check_id}/activity`);

    return result.data;
  };

  const checkNotice = async(check_id: number) => {
    const result = await api.get<boolean>(`/check/${check_id}/notice-state`);

    return result.data;
  };

  const updateNotice = async(check_id: number, value: boolean, rcc_call: boolean, moderator_steam_id?: string) => {
    const result = await api.put<boolean>(`/check/${check_id}/notice-state`, {
      rcc_call              : rcc_call,
      rcc_moderator_steam_id: moderator_steam_id
    }, {
      params: {
        active: value
      }
    });

    return result.data;
  };

  const create = async(steam_id: string) => {
    const result = await api.post<number>('/check', { steam_id });

    return result.data;
  };

  const exact = async(check_id: number) => {
    const result = await api.get<CheckDto>(`/check/${check_id}`);

    return result.data;
  };

  const finish = async(check_id: number, opts: CheckFinishPayloadDto) => {
    const result = await api.delete(`/check/${check_id}`, {
      data: opts
    });

    return result.data;
  };

  return {
    browse,
    stats,
    getActivity,
    checkNotice,
    updateNotice,
    create,
    exact,
    finish,
  };
};
