<script setup lang='ts'>
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  defineProps<{
    bans: BanDto[] | undefined
  }>();

  const { t } = useLocaleStore();

  const openBanModal = (ban: BanDto) => {
    ActivateBanViewModal({ ban, banId: ban.id });
  };
</script>

<template>
  <div class="container">
    <div class="flex justify-between">
      <p class="title">{{ t('check.verdict.details.mass') }}</p>
      <p v-if="bans" class="subtitle">{{ t('general.amount-st', { amount: bans?.length}) }}</p>
    </div>

    <div class="items-row">
      <template v-if="!bans">
        <div class="item">
          <PlayerBox
            style="width: 200px;"
            :player="null"
          />
        </div>
        <div class="item">
          <PlayerBox
            style="width: 200px;"
            :player="null"
          />
        </div>
      </template>
      <template v-else>
        <template
          v-for="ban in bans"
          :key="ban?.id"
        >
          <div
            class="item" 
            @click="openBanModal(ban)"
          >
            <PlayerBox
              :player="ban.player ?? null"
              :subtext="ban.reason"
              use-jackal-avatar
              class="pointer-events-none max-w-[340px]"
            />
            <Svg.open class="icon" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply flex flex-col gap-2.5;

  .title {
    @apply text-grey-50;
    @apply font-medium;
  }
  .subtitle {
    @apply text-grey-400;
  }
}
</style>