
<script setup lang='ts'>
  import Accordion from '@/components/Accordion.vue';
  import JsonViewer from '@/components/json-viewer/JsonViewer.vue';
  import { CourtCommandResponseDataDto } from '@/api/backend/court/commands/commands.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';

  const { t } = useLocaleStore();

  defineProps<{
    responses: CourtCommandResponseDataDto[] | null
  }>();

  const overrideResponse = (data: object) => {
    if (data === null) {
      return t('command-executed-but-no-response');
    }

    return data;
  };
</script>

<template>
  <div class="flex flex-col gap-2.5">
    <template v-if="responses">
      <template v-for="(res, index) in responses" :key="res.command">
        <Accordion :active-index="[0,1,2,3,4,5,6]">
          <template #header>
            <p>{{ t('command-response.response', { num: index + 1 }) }}</p>
          </template>
          <template #default>
            <div class="text-grey-600 mb-4">
              > {{ res.command }}
            </div>
            
            <JsonViewer
              :value="overrideResponse(res.data)"
              expanded
              copyable
            />
          </template>
        </Accordion>
      </template>
    </template>
    <template v-else>
      <EmptyImage
        :title="t('command-response.empty.title')"
        :subtitle="t('command-response.empty.subtitle')"
        class="p-5"
      />
    </template>
  </div>
</template>

<style lang='scss' scoped>

.command-response-modal {
  width: 95vw;
  max-width: 440px;
  max-height: min(600px, 90vh);
}
</style>