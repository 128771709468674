<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';  
  import { useValidators } from '@src/composable/validate.composable';
  import { SignUpData } from '@src/views/client/types/auth.dto';
  import { ref } from 'vue';

  const { t } = useLocaleStore();
  
  const emits = defineEmits<({
    (e: 'signIn'): void,
    (e: 'signUpComplete', data: SignUpData): void
  })>();

  const _validators = useValidators();

  const data = ref({
    email   : '',
    password: ''
  });

  const emailInput = ref<InstanceType<typeof TextInput>>();
  const passwordInput = ref<InstanceType<typeof TextInput>>();
  const button = ref<InstanceType<typeof Button>>();

  const next = async () => {
    if (!emailInput.value || !!emailInput.value?.activeException ||!passwordInput.value || !!passwordInput.value?.activeException) {
      return;
    }

    const res = await useCoreApi().auth.classicValidate(data.value.email);
    if (res.exists) {
      useRuntimeNotification(`error`, t('auth-sign-up-email-busy'));
      return;
    }

    emits('signUpComplete', {
      email        : true,
      auth_email   : data.value.email,
      auth_password: data.value.password
    });
  };
</script>

<template>
  <div class="auth">
    <div class="header">
      <p class="title">{{ t('auth-sign-up-header') }}</p>
      <span class="subtitle">
        {{ t('auth-sign-up-subtitle') }}

        <span class="link" @click="emits('signIn')">{{ t('auth-sign-up-subtitle-action') }}</span>.
      </span>
    </div>

    <div class="content">
      <div class="inputs">
        <TextInput 
          ref="emailInput" 
          v-model="data.email" 
          type="email"
          name="email" 
          autocomplete="email"
          inputmode="email"
          :label="t('auth.email.input.label')"
          placeholder="garry@example.com" 
          :validator="_validators.Email" 
          height="42"
        />

        <TextInput 
          ref="passwordInput"
          v-model="data.password" 
          type="password"
          :autocomplete="'new-password'"
          :label="t('auth.password.input.label')"
          placeholder="•••••••••••••••" 
          :validator="_validators.Password" 
          height="42" 
          @on-enter="() => button?.submit()" 
        />
      </div>

      <Button
        ref="button"
        :disabled="!emailInput || !!emailInput?.activeException ||!passwordInput || !!passwordInput?.activeException" 
        preset="primary"
        class="btn"
        :text=" t('auth.button.sign-up')"
        :action="next"
      />
    </div>
  </div>
</template>