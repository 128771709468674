<script setup lang="ts">
  import { computed } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaCommandEdit } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';

  const props = defineProps<AuditDto<AuditMetaCommandEdit>>();

  const { t } = useLocaleStore();

  const audit = ref<InstanceType<typeof AuditBase>>();

  const actionName = computed(() => {
    switch (props.meta.action) {
      case 'create': {
        return t('audit-command-edit-create');
      }
      case 'edit': {
        return t('audit-command-edit-edit');
      }
      case 'toggle-on': {
        return t('audit-command-edit-toggle-on');
      }
      case 'toggle-off': {
        return t('audit-command-edit-toggle-off');
      }
      case 'delete': {
        return t('audit-command-edit-delete');
      }
    }

    return null;
  });
</script>

<template>
  <AuditBase
    v-if="actionName"
    ref="audit"
    :client_id="client_id"
    :icon="Svg.edit"
    icon_styles="fill-grey-500"
    :date="created_at"
    ready
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ actionName }}</span>
    <span class="light">{{ meta.command?.group ?? t('general.unknown') }}</span>    
    <span>-></span>
    <span class="light">{{ meta.command?.name ?? t('general.unknown') }}</span>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>