<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';
  import { ExtendedMenuItem } from '../quality/menu-extended/MenuExtended.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';

  const emits = defineEmits(['update:modelValue']);
  const props = defineProps<{ 
    header: string; 
    placeholder?: string; 
    choosed: string, 
    disabled?: boolean;
    modelValue: string | number, 
    opts: ExtendedMenuItem[] 
  }>();

  const value = ref<string | number>();

  onMounted(() => value.value = props.modelValue);
  watch(() => props.modelValue, () => {
    value.value = props.modelValue;
  });

  watch(
    () => value.value,
    () => emits('update:modelValue', value.value),
  );
</script>

<template>
  <div
    class="switch-wrapper"
    :class="{ disabled }"
  >
    <div class="switch-text">
      <span class="title">{{ header }}</span>
      <span class="subtitle">{{ placeholder }}</span>
    </div>
    <div
      class="flex gap-1 text-grey-200 text-sm cursor-pointer"
      @click="(e) => useContextMenu().open(e, opts)"
    >
      <span
        class="truncate" 
      >
        {{ choosed }}
      </span>
      <span>{{ `↓` }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.switch-wrapper {
  @apply flex justify-between gap-2.5 items-center;

  &.disabled {
    @apply opacity-40 pointer-events-none;
  }

  .switch-text {
    @apply flex flex-col;

    .title {
      @apply text-grey-50;
    }
    .subtitle {
      @apply text-grey-500 text-sm leading-tight;
    }
  }
}
</style>
