<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import DiscordWebhook from '../components/DiscordWebhook.vue';

  import { useDiscordWebhookStorage } from './../types/discord-webhook.storage';
  import { ref } from 'vue';
  import { CourtDiscordDto } from '@/api/backend/court/court-discord/court-discord.dto';
  import { onMounted } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const _webhookStorage = useDiscordWebhookStorage();

  const data = ref<CourtDiscordDto[]>();

  onMounted(() => {
    // Господи, как же мне в падлу прокидывать пропсы
    (window as any).refreshDiscordPage = () => load();

    load();
  });

  const load = async () => {
    data.value = await useCourtApi().discord.getAllDiscords();
  };
</script>

<template>
  <div class="relative">
    <div class="section"> 
      <div class="header">
        <p class="title">
          {{ t('discord-page-header') }}
        </p>
        <span class="subtitle">{{ t('discord-page-subheader') }}</span>
      </div>
      <div class="webhooks">
        <template v-for="(value, _) of _webhookStorage" :key="_">
          <DiscordWebhook :details="value" :value="data?.find(v => v.type === value.type)" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>.section {
  @apply flex flex-col gap-5;

  .header {
    @apply flex flex-col gap-2;

    .title {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    .subtitle {
      @apply text-grey-400;
      @apply text-sm;
    }

    :deep(.bright) {
      @apply text-amber-500;
    }
  }

  .webhooks {
    @apply grid grid-cols-1 gap-3;
  }
}
</style>