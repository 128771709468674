<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted } from 'vue';
  import PaymentsTabMain from './tabs/PaymentsTabMain.vue';
  import PaymentsTabHistory from './tabs/PaymentsTabHistory.vue';
  import { usePaymentsStore } from './store/payments.store';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import TabbedNavigation from '@/components/tabbed-navigation/TabbedNavigation.vue';
  import { TabbedNavigationHeader } from '@/components/tabbed-navigation/tabbed-navigation.dto';

  const { t } = useLocaleStore();

  const _payments = usePaymentsStore();

  onMounted(() => _payments.warmup(true));


  type PaymentsHeaders = 'general' | 'transactions';

  const headers = computed<TabbedNavigationHeader<PaymentsHeaders>[]>(() =>[{
    key : 'general',
    text: t('payments.tabs.general')
  }, {
    key : 'transactions',
    text: t('payments.tabs.history')
  }]);

</script>

<template>
  <TitledPage
    :top-title="t('billing.page.title')"
    :top-subtitle="t('billing.page.subtitle')"
    :headers="headers"
    no-divider
  >
    <TabbedNavigation :headers="headers">
      <template #general>
        <PaymentsTabMain />
      </template>
      <template #transactions>
        <PaymentsTabHistory />
      </template>
    </TabbedNavigation>
  </TitledPage>
</template>

<style lang='scss' scoped>
.pay-warning {
  @apply justify-center flex gap-2;
  @apply p-2 mb-5 rounded-md;
  @apply bg-red-400/10 text-red-400;

  svg {
    @apply w-5 flex-shrink-0 mx-1;
    @apply fill-red-400;
  }

  &.frozen {
    @apply bg-red-400/10 text-red-400;
    
    svg {
      @apply fill-red-400;
    }
  }

  &.payNow {
    @apply bg-amber-400/10 text-amber-400;
    
    svg {
      @apply fill-amber-400;
    }
  }
}

.custom-title {
  @apply text-2xl font-semibold text-grey-50 mb-1;
  @apply inline-flex gap-1.5 items-center;

  .button-deposit {
    @apply cursor-pointer;

    svg {
      @apply w-5;
      @apply fill-primary-700;
    }

    &:hover {
      svg {
        @apply fill-primary-500;
      }
    }
  }
}

.custom-subtitle {
  @apply text-base font-normal;
  @apply text-grey-400;
  @apply h-6;
}

[device='mobile'] {
  .custom-title {
    @apply text-20-600;
  }
}
</style>