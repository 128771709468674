import { useCoreApi } from "@/api/backend/core/core.api";
import { onMounted, ref } from "vue";

export const useSteamAuthStart = async () => {
  return await new Promise<string>((res, rej) => {
    const link = useCoreApi().auth.steamLink();
          
    const ref = window.open('', "auth-window", "menubar=0,resizable=0,width=1200,height=745");
      
    link.then(v => {
      if (!ref) {
        return;
      }
  
      ref.location = v;
    });
  
    window?.addEventListener('message', (msg) => {
      const obj = JSON.parse(msg.data) as { query?: string };
      if (!obj.query) {
        return;
      }

      clearInterval(interval);
      res(obj.query);

      ref?.close();
    });

    const interval = setInterval(() => {
      if (ref?.closed) {
        rej({ message: 'Subwindow closed' });
        clearInterval(interval);
        return;
      }
    }, 500);
  });
};

export const useSteamAuthFinish = () => {
  const connecting = ref<boolean>(false);

  onMounted(async () => {
    const query = window.location.search;
    if (!query.includes('openid.claimed_id')) {
      return;
    }

    window.opener.postMessage(JSON.stringify({ query: query.replace('?', '') }));
  });

  return { connecting };
};