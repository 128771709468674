<script setup lang='ts'>
  import Divider from '@/components/Divider.vue';
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtStore } from '@court/stores/court.store';
  import { onMounted, watch, ref } from 'vue';
  import CustomizationReasonPicker from '../components/CustomizationReasonPicker.vue';
  import { computed } from 'vue';
  import Button from '@/components/Button.vue';
  import type { CourtOptionsDto } from '@/api/backend/court/court/court.dto';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { ActivateGlobalUnbanModal } from '@court/modals/global-unban/GlobalUnbanModal.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import SwitchText from '@/components/switch/SwitchText.vue';


  const { t } = useLocaleStore();

  const _court = useCourtStore();
  const _permissions = usePermissionsStore();

  const { options } = _court.court!;
  const original = ref<CourtOptionsDto>();

  onMounted(() => {
    original.value = JSON.parse(JSON.stringify(options));
  });

  const canSaveCustomizationReasonPicker = computed(() => {
    return JSON.stringify({
      ban_reasons     : options.ban_reasons,
      ban_mass_reasons: options.ban_mass_reasons,
      check_verdicts  : options.check_verdicts,
    }) !== JSON.stringify({
      ban_reasons     : original.value?.ban_reasons,
      ban_mass_reasons: original.value?.ban_mass_reasons,
      check_verdicts  : original.value?.check_verdicts,
    });
  });

  const save = async (componentChanged = '') => {
    await useCourtApi().court.updateOptions(options);

    useRuntimeNotification(`success`, t('settings-saved'));

    if (componentChanged === 'CustomizationReasonPicker') {
      original.value = JSON.parse(JSON.stringify(options));
    }
  };

  const reset = () => {
    Object.assign(options, JSON.parse(JSON.stringify(original.value)));
  };
  
  watch(() => options.announce_reporters_after_action, () => save('SwitchText'));
  watch(() => options.delete_reports_after_ban, () => save('SwitchText'));
  watch(() => options.delete_reports_after_check, () => save('SwitchText'));
  watch(() => options.ignore_reports_after_check, () => save('SwitchDropdown'));
  watch(() => options.ignore_reports_after_days, () => save('SwitchDropdown'));
  watch(() => options.check_start_min_report, () => save('NumberPicker'));
  watch(() => options.check_start_broadcast, () => save('SwitchText'));
  watch(() => options.check_finished_clear_broadcast, () => save('SwitchText'));
  watch(() => options.ban_broadcast, () => save('SwitchText'));

  const globalUnban = () => {
    if (!_permissions.isOwner()) {
      ActivateConfirmModal({
        type       : 'warn',
        title      : t('general.no-access'),
        description: t('general.only-owner')
      });
      return;
    }

    ActivateGlobalUnbanModal();
  };

</script>

<template>
  <div v-if="_court.court" class="relative">
    <div class="section">
      <p class="text-head">
        {{ t('general.common-parameters') }}
      </p> 
      <div class="section">
        <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
          <SwitchText
            v-model="options.ban_broadcast"
            :header="t('customization.bans.ban_broadcast.header')"
            :placeholder="t('customization.bans.ban_broadcast.placeholder')"
          />
        </SettingsContainer>
      </div>
    </div>

    <Divider class="my-8" />
  
    <div class="section">
      <p class="text-head">
        {{ t('customization.bans.reasons.title') }}
      </p>
      <div class="flex flex-col gap-5 bg-grey-900 rounded-md p-5">
        <CustomizationReasonPicker
          v-model="options.ban_reasons"
          :label="t('customization.bans.reasons.ban.label')"
          :placeholder="t('customization.bans.reasons.ban.placeholder')"
          :tooltip-vars="['{staff_tag}', '{staff_name}']"
        />
        <CustomizationReasonPicker
          v-model="options.ban_mass_reasons"
          :label="t('customization.bans.reasons.mass-ban.label')"
          :placeholder="t('customization.bans.reasons.ban_mass_reasons.placeholder')"
          :tooltip-vars="['{main_steam_id}', '{main_steam_name}', '{staff_tag}', '{staff_name}']"
        />
        <CustomizationReasonPicker
          v-model="options.check_verdicts"
          :label="t('customization.bans.reasons.check-verdict.label')"
          :placeholder="t('customization.bans.reasons.check-verdict.placeholder')"
          :tooltip-vars="['{staff_tag}', '{staff_name}']"
        />

        <template v-if="canSaveCustomizationReasonPicker">
          <Divider class="my-1" />

          <div class="flex gap-2.5">
            <Button
              :text="t('discord.save')"
              preset="primary"
              :disabled="!canSaveCustomizationReasonPicker"
              class="w-fit"
              :action="() => save('CustomizationReasonPicker')"
            />
            <Button
              :text="t('general.cancel')"
              preset="default"
              class="w-fit"
              :action="reset"
            />
          </div>
        </template>
      </div>
    </div>

    <Divider class="my-8" />
    
    <div class="section">
      <p class="text-head">
        {{ t('customization.bans.banlist.title') }}
      </p>
      <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
        <div class="destructive-action" @click="globalUnban">
          <div class="switch-text">
            <span class="title">{{ t('customization.bans.banlist.unban.title') }}</span>
            <span class="subtitle">{{ t('customization.bans.banlist.unban.subtitle') }}</span>
          </div>
          <Svg.arrow class="arrow" />
        </div>
      </SettingsContainer>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.row {
  @apply flex justify-end;
}

.section {
  @apply flex flex-col gap-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }
}

.btn-unban {
  @apply h-fit mr-1.5 flex-shrink-0 p-0;
  @apply text-sm text-red-500 hover:text-red-400;
}
</style>