import { PlayerFullDto, PlayerSearchIpDto } from "@/api/backend/court/player/player.dto";
import { ref, watch } from "vue";
import { BanCheckFinishReason } from "../types/ban.dto";
import { UploadedFile } from "@/components/quality/uploader/utils/uploader.dto";
import { BanDto } from "@/api/backend/court/ban/ban.dto";
import { useCourtApi } from "@/api/backend/court/court.api";


export type BanSharedOpts = {
  reason: string;

  expired?: number;
}

export type BanCheckStatusResult = {
  status?: BanCheckFinishReason,
  comment: string
};

export type BanDataStorage = {
  ban?: BanDto;

  target: {
    steamId: string;
    ip?: string;

    player?: PlayerFullDto;
  } & BanSharedOpts;

  team: {
    enabled: boolean;

    players: PlayerFullDto[];
    
    ip_active: boolean;
  } & BanSharedOpts;

  server_ids: number[];

  proofs: UploadedFile[];
  proofs_global: boolean;
  comment: string;

  // Состояние блокировок IP адресов (ключ - steamId, значение - вкл или выкл)
  ips: Record<string, boolean>;
  //                            найдено         грузим   ошибка
  ipsLoaders: Record<string, PlayerSearchIpDto | null | false>;

  // ! Костыль, чтобы модалка авто-высоту ставила, точно нужно фиксить, но кому не плевать?
  updater?: () => unknown;
};

export const useBanData = (steamId: string, meta: { player?: PlayerFullDto, players?: PlayerFullDto[], ban?: BanDto }) => {
  if (meta.ban && meta.player) {
    meta.player.ip = meta.ban.ban_ip ?? meta.player.ip;
  }

  const storage = ref<BanDataStorage>({
    ban: meta.ban,

    target: {
      steamId: steamId,
      ip     : meta.ban?.ban_ip ?? meta.player?.ip,

      player: meta.player,

      reason : meta.ban?.reason ?? '',
      expired: meta.ban?.expired_at
    },
    team: {
      enabled: false,

      players: meta.players ?? [],

      reason   : '',
      ip_active: false,
      expired  : meta.ban?.expired_at
    },

    server_ids: meta.ban?.server_ids ?? [],

    proofs       : (meta.ban?.proofs ?? []).map(v => ({ s3_id: v })),
    proofs_global: false,
    
    comment: meta.ban?.comment ?? '',
    
    ips: {
      [steamId]: meta?.ban?.ban_ip_active ?? false
    },
    ipsLoaders: {}
  });

  const updateIps = () => {
    const steamIds = [storage.value.target.steamId, ...(storage.value.team?.players ?? []).map(v => v.steam_id)];

    storage.value.ips = Object.fromEntries(steamIds.map(v => ([v, storage.value.ips[v] ?? false])));
  };

  watch(() => storage.value.target, () => updateIps(), { deep: true });
  watch(() => storage.value.team, () => updateIps(), { deep: true });

  watch(() => storage.value.team.ip_active, (active) => {
    storage.value.team.players.map(v => v.steam_id).forEach(v => storage.value.ips[v] = active);
  });

  watch(() => storage.value.ips, () => {
    Object.entries(storage.value.ips).forEach(async ([steamId, value]) => {
      if (!value) {
        return;
      }

      const rawIp = storage.value.target.steamId == steamId ? storage.value.target.ip : storage.value.team.players?.find(v => v.steam_id == steamId)?.ip;
      if (!rawIp) {
        return;
      }

      if (storage.value.ipsLoaders[rawIp] !== undefined) {
        return;
      }

      storage.value.ipsLoaders[rawIp] = null;

      const info = await useCourtApi().player.findByIpBatchLoader.load(rawIp);

      storage.value.ipsLoaders[rawIp] = info ?? false;
    });
  }, { deep: true });

  updateIps();

  return storage;
};