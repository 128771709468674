<script lang="ts">
  export type PermissionsStepsOpts = {
    permissions: string[];
    isImmutable: boolean;
  };
</script>

<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import PermissionsRow from '../components/PermissionsRow.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import { PermissionDescription, PermissionVariantSingle, PermissionVariantTuple, usePermissionStorage } from '../permissions-edit.dto';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import Divider from '@/components/Divider.vue';
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useAppStore } from '@/stores/app.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { PermissionsDictionary } from '@/api/backend/core/project/project.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ModalInjectedProps } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';

  const props = defineProps<{
    client: ClientDto;
    updateHeight?: () => void;
  } & ModalInjectedProps>();

  const { t } = useLocaleStore();

  const _app = useAppStore();
  const _panel = usePanelStore();
  const _permission = usePermissionsStore();

  const permissionsModel = defineModel<PermissionsStepsOpts>({ required: true });

  const { permissionsSections } = usePermissionStorage();

  const clientIsAdmin = ref<boolean>(false);
  const clientPermissions = ref<Record<string, boolean>>({});

  onMounted(() => {
    updateFields();
  });

  const updateFields = () => {
    if (!props.client) {
      return;
    }

    clientPermissions.value = {};
    clientIsAdmin.value = permissionsModel.value.isImmutable;

    permissionsModel.value.permissions.forEach(v => {
      clientPermissions.value[v] = true;
    });

    
    if (!_permission.isAdminOrOwner()) {
      for (const perm in clientPermissions.value) {
        if (!_permission.can([perm as keyof PermissionsDictionary])) {
          props.injected.setBeforeClose(async () => {
            await ActivateConfirmModal({
              type       : 'warn',
              title      : t('general.ponyatno'),
              description: t('permissions-cant-edit-more-rights')
            });

            return true;
          });

          props.injected.close();
          return;
        }
      }
    }

    props.updateHeight?.();
  };

  watch(() => props.client, () => {
    updateFields();
  });

  watch(() => clientPermissions.value, (v) => {
    permissionsModel.value.permissions = Object.entries(v).filter(([_, value]) => value).map(([key]) => key);
  }, { deep: true });

  watch(() => clientIsAdmin.value, (v) => {
    permissionsModel.value.isImmutable = v;
  });

  const onChecked = (description: PermissionDescription, variant?: PermissionDescription<PermissionVariantSingle>) => {
    if (variant && 'variants' in description) {
      const checkedIndexOf = description.variants.findIndex(v => v.permission === variant.permission);
      
      description.variants.forEach((value, index) => {
        if (index <= checkedIndexOf) {
          onChecked(value);
        }
      });
      return;
    }

    if ('variants' in description) {
      onChecked(description.variants[0]);
    }

    if ('permission' in description) {
      clientPermissions.value[description.permission] = true;
    }
  }; 

  const onUnchecked = (description: PermissionDescription, variant?: PermissionDescription<PermissionVariantSingle>) => {
    const disabledPermission = 'permission' in description ? description.permission : variant?.permission;
    
    permissionsSections.map(v => v.descriptions).flat().forEach(v => {
      if (disabledPermission && v.enabled_by && v.enabled_by.includes(disabledPermission)) {
        onUnchecked(v);
      }
    });

    if (variant && 'variants' in description) {
      const checkedIndexOf = description.variants.findIndex(v => v.permission === variant.permission);

      description.variants.forEach((value, index) => {
        if (index >= checkedIndexOf) {
          onUnchecked(value);
        }
      });
      return;
    }

    if ('variants' in description) {
      description.variants.forEach(v => onUnchecked(v));
    }

    if ('permission' in description) {
      delete(clientPermissions.value[description.permission]);
    }
  };

  const isVariantDescriptionChecked = (description: PermissionDescription<PermissionVariantTuple>) => {
    return description.variants.some(v => clientPermissions.value[v.permission]);
  }; 

  const isDisabled = (description: PermissionDescription) => {
    if (!_permission.isAdminOrOwner() && 'permission' in description) {
      if (!_permission.can([description.permission])) {
        return ['no'];
      }
    }

    if (typeof description.enabled_by === 'string') {
      if (!clientPermissions.value[description.enabled_by]) {
        return [description.enabled_by];
      }
    }

    if (Array.isArray(description.enabled_by)) {
      const missing = description.enabled_by.find(v => !clientPermissions.value[v]);

      if (missing) {
        return description.enabled_by.filter(v => !clientPermissions.value[v]);
      }

      return false;
    }

    return false;
  };

  const getPanelOwnerId = computed(() => _panel.project?.owner_client_id);
  const isOwner = () => _app.client?.id === getPanelOwnerId.value;

  const getDescription = (permission: keyof PermissionsDictionary) => {
    for (const section of permissionsSections.map(v => v.descriptions).flat()) {
      if ('permission' in section && section.permission == permission) {
        return section.name;
      }

      if ('variants' in section) {
        const correct = section.variants.find(v => v.permission === permission);
        
        if (correct) {
          return `${correct.name}`;
        }
      }
    }
  };

  const onlyYoursText = computed(() => t('permission-edit-only-yors'));
</script>

<template>
  <div class="permissions-edit-modal">
    <template v-if="isOwner()">
      <SwitchText
        v-model="clientIsAdmin"
        class="admin-switch"
        :header="t('permissions.admin.title')"
        :placeholder="t('permissions.admin.subtitle')"
      />

      <Divider class="mt-1" />
    </template>

    <template v-for="section in permissionsSections" :key="section.name">
      <div class="section">
        <p class="font-medium" :class="clientIsAdmin ? 'text-grey-400' : 'text-grey-50'">{{ section.name }}</p>

        <template v-for="description in section.descriptions" :key="description.name">
          <template v-if="'variants' in description">
            <div>
              <!-- Сами типа не являются опциями, содержат под-опции -->
              <TooltipBase
                trigger="hover"
                :placement="'bottom-start'"
              >
                <template #default>
                  <PermissionsRow 
                    :permission="description"
                    :disabled="!!isDisabled(description) || clientIsAdmin" 
                    :checked="isVariantDescriptionChecked(description) || clientIsAdmin"
                    class="mb-2"
                    @on-checked="() => onChecked(description)"
                    @on-unchecked="() => onUnchecked(description)"
                  />
                </template>

                <template v-if="isDisabled(description)" #content>
                  <div class="px-2 py-1 text-xs">
                    <p>{{ 'Не хватает прав:' }}</p>
                    <template v-for="desc in isDisabled(description)" :key="desc">
                      <p>- {{ desc == 'no' ? onlyYoursText : getDescription(desc) }}</p>
                    </template>
                  </div>
                </template>
              </TooltipBase>

              <!-- Под-опции -->
              <div class="subsection">
                <template v-for="variant in description.variants" :key="variant.name">
                  <TooltipBase trigger="hover" :placement="'bottom-start'">
                    <PermissionsRow 
                      circle
                      :permission="variant"
                      :checked="!!clientPermissions[variant.permission] || clientIsAdmin"
                      :disabled="!!isDisabled(variant) || !!isDisabled(description) || clientIsAdmin"
                      @on-checked="() => onChecked(description, variant)"
                      @on-unchecked="() => onUnchecked(description, variant)"
                    />

                    <template v-if="isDisabled(variant)" #content>
                      <div class="px-2 py-1 text-xs">
                        <p>{{ 'Не хватает прав:' }}</p>
                        <template v-for="desc in isDisabled(variant)" :key="desc">
                          <p>- {{ desc == 'no' ? onlyYoursText : getDescription(desc) }}</p>
                        </template>
                      </div>
                    </template>
                  </TooltipBase>
                </template>
              </div>
            </div>
          </template>

          <!-- Обычные строки -->
          <template v-else>
            <TooltipBase trigger="hover" :placement="'bottom-start'">
              <template #default>
                <PermissionsRow 
                  :permission="description"
                  :checked="!!clientPermissions[description.permission] || clientIsAdmin"
                  :disabled="!!isDisabled(description) || clientIsAdmin"
                  @on-checked="() => onChecked(description)"
                  @on-unchecked="() => onUnchecked(description)"
                />
              </template>

              <template v-if="isDisabled(description)" #content>
                <div class="px-2 py-1 text-xs">
                  <p>{{ 'Не хватает прав:' }}</p>
                  <template v-for="desc in isDisabled(description)" :key="desc">
                    <p>- {{ desc == 'no' ? onlyYoursText : getDescription(desc) }}</p>
                  </template>
                </div>
              </template>
            </TooltipBase>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.permissions-edit-modal {
  @apply p-5;
  max-height: 440px;
  @apply overflow-y-auto flex flex-col;
  @apply gap-5;

  .admin-switch {
    @apply bg-grey-850;
    @apply p-2.5;
    @apply rounded-md;
    @apply items-start;

    &:deep(.text-subtitle) {
      @apply pt-1;
      @apply text-grey-300;
    }
  }

  .section {
    @apply flex flex-col gap-2.5;
    .subsection {
      @apply ml-5;
      @apply flex flex-col gap-1;
    }
  }
  &:deep(.divided-card) {
    .content {
      max-height: 600px;
    }
  }
}
</style>