<script setup lang="ts">
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaSignageDelete } from '@/api/backend/court/audit/audit.dto';
  import { onMounted, ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { SignageDto } from '@/api/backend/court/signages/signages.dto';
  import { ActivateImageGalleryModal } from '@src/modals/image-gallery/ImageGalleryModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<AuditDto<AuditMetaSignageDelete>>();

  const { t } = useLocaleStore();

  const audit = ref<InstanceType<typeof AuditBase>>();

  const sign = ref<SignageDto>();

  onMounted(async () => {
    const result = await useCourtApi().signages.batchNetIds.load(props.meta.net_id);

    sign.value = result;
  });
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="Svg.images_1"
    icon_styles="fill-red-500" 
    :date="created_at"
    ready
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ t('audit-player-signage-delete-text-1') }}</span>
    
    <span
      class="sign"
      @click="ActivateImageGalleryModal({
        images: sign?.variants.sort((a,b) => b.created_at - a.created_at).map(v => ({ hash: v.url })) ?? [],
        index: 0
      })"
    >{{ t('audit-player-signage-delete-text-2') }}</span>
  </AuditBase>
</template>

<style lang="scss" scoped>
.sign {
  @apply text-grey-400;
  @apply cursor-pointer;
  @apply underline underline-offset-4;

  &:hover {
    @apply text-grey-200;
  }
}
.gallery {
  @apply h-[220px] overflow-hidden rounded;
  background-image: radial-gradient(#A6A09A 1px, #CDC6BF 1px);
  background-size: 10px 10px;
}
</style>