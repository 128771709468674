/* eslint-disable no-unused-vars */
import { NewsDto } from '@/api/backend/core/utils/utils.dto';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useDataStore } from './data.store';
import { ActivateNewsModal } from '@src/modals/news/NewsModal.vue';
import { useCoreApi } from '@/api/backend/core/core.api';

export const useNewsStore = defineStore('news', () => {
  const last_news = ref<NewsDto>();
  
  const _data = useDataStore();
  const seen = _data.sync('news_readed', ref<number[]>([]));
  
  const warmup = async () => {
    const data = await useCoreApi().utils.getNews();

    last_news.value = data ?? undefined;

    check();
  }; 

  const check = () => {
    if (!last_news.value) {
      return;
    }

    if (seen.value.includes(last_news.value.id)) {
      return;
    }

    ActivateNewsModal(last_news.value.id, last_news.value);

    seen.value.push(last_news.value.id);
  };

  return { warmup };
});
