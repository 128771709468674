<script lang="ts" setup>
  import { DebugService } from '@/api/backend/debug.service';
  import { vIntersectionObserver } from '@vueuse/components';
  import { whenever } from '@vueuse/core';
  import { computed, ref } from 'vue';

  const props = defineProps<{ next: () => Promise<unknown>, reversed?: boolean; offset?: number }>();

  const isLoading = ref<boolean>(false);
  const intersection = ref<boolean>(false);

  whenever(intersection, async () => {
    if (isLoading.value) {
      return;
    }

    DebugService.log(`Загружаем следующую страницу с интерсептора`);

    try {
      isLoading.value = true;

      await props.next();
    }
    catch (err) {
      throw err;
    } finally {
      isLoading.value = false;
    }
  });

  const dynamicOffset = computed(() => {
    const offset = (props.offset ? props.offset : -300) * (!!props.reversed ? -1 : 1);

    return `transform: translateY(${offset}px)`;
  });

</script>

<template>
  <section
    v-intersection-observer="([{ isIntersecting }]) => intersection = isIntersecting"
    class="observer"
    :class="{ reversed }"
    :style="dynamicOffset"
  />
</template>

<style lang="scss" scoped>
.observer {
  @apply absolute w-1/2 h-4 z-10;

  &.reversed {
    @apply static; 
    flex-shrink: 0;
  }
}
</style>