<script lang="ts">
  export type EmptyImageType = 'default' | 'empty' | 'add' | 'lock' | 'warning' | 'settings' | 'frozen';
</script>

<script setup lang="ts">
  import { computed } from 'vue';
  import EmptyImageDefault from './variants/EmptyImageDefault.vue';
  import EmptyImageIcon from './variants/EmptyImageIcon.vue';
  import EmptyImageLock from './variants/EmptyImageLock.vue';
  import EmptyImageFrozen from './variants/EmptyImageFrozen.vue';
  import EmptyImageAdd from './variants/EmptyImageAdd.vue';
  import EmptyImageWarning from './variants/EmptyImageWarning.vue';
  import EmptyImageSettings from './variants/EmptyImageSettings.vue';

  const props = defineProps<{ 
    type?: EmptyImageType;
    title?: string;
    subtitle?: string;
  }>();

  const component = computed(() => {
    switch (props.type) {
      case 'default': {
        return EmptyImageDefault;
      }
      case 'empty': {
        return EmptyImageIcon;
      }
      case 'add': {
        return EmptyImageAdd;
      }
      case 'lock': {
        return EmptyImageLock;
      }
      case 'frozen': {
        return EmptyImageFrozen;
      }
      case 'warning': {
        return EmptyImageWarning;
      }
      case 'settings': {
        return EmptyImageSettings;
      }
    }

    return EmptyImageDefault;
  });
</script>

<template>
  <div class="flex flex-col gap-3 items-center">
    <component :is="component" v-if="props.type" />

    <div class="text-center">
      <p class="text-grey-100 font-semibold mb-1">
        {{ title }}
      </p>
      <p style="max-width: 300px;" class="text-grey-300 text-sm">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>