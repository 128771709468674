import { Axios } from "axios";
import { PaginatedResponse } from "@/api/backend/pagination.service";
import { ProjectClientPersonalDto, ProjectCreateOrUpdatePayload, ProjectWalletDto, TransactionDto, ProjectFullDto, ProjectClientDto } from "./project.dto";

export const useCoreProjectApi = (api: Axios) => {
  const getMy = async () => {
    const result = await api.get<ProjectClientPersonalDto[]>(`/project`);

    return result.data;
  };

  const create = async (data: ProjectCreateOrUpdatePayload) => {
    const result = await api.post<ProjectClientPersonalDto>("/project", data);

    return result.data;
  };
  
  const getWallet = async (projectId: number) => {
    const result = await api.get<ProjectWalletDto>(`/project/${projectId}/wallet`);

    return result.data;
  };
  
  const depositeWallet = async (projectId: number, sum: number, method: string) => {
    const result = await api.post<string>(`/project/${projectId}/wallet`, {
      method,
      sum,

      return_url: window.location.href
    }); 

    return result.data;
  };

  const getOperations = async (projectId: number, page: number) => {
    const result = await api.get<PaginatedResponse<TransactionDto<object>>>(`/project/${projectId}/wallet-operations`, { params: { page }});

    return result.data;
  };

  const getDetails = async (projectId: number) => {
    const result = await api.get<ProjectFullDto>(`/project/${projectId}/general`);

    return result.data;
  };

  const updateDetails = async (projectId: number, data: ProjectCreateOrUpdatePayload) => {
    const result = await api.put(`/project/${projectId}/general`, data);

    return result.data;
  };

  const getClients = async (projectId: number) => {
    const result = await api.get<ProjectClientDto[]>(`/project/${projectId}/clients`);

    return result.data;
  };

  const editClient = async (clientId: number, projectId: number, permissions: string[], is_immutable: boolean) => {
    const result = await api.put<void>(`/project/${projectId}/clients`, {
      client_id: clientId,

      permissions,
      is_immutable
    });

    return result.data;
  };

  const editClientSettings = async (projectId: number, twofa_strict: boolean, steam_strict: boolean) => {
    const result = await api.put<void>(`/project/${projectId}/clients-settings`, {
      twofa_strict,
      steam_strict
    });

    return result.data;
  };

  const deleteClient = async (clientId: number, projectId: number) => {
    const result = await api.delete(`/project/${projectId}/clients`, {
      params: { targetClientId: clientId }
    });

    return result.data;
  };

  const selfKick = async (projectId: number) => {
    const result = await api.delete(`/project/${projectId}/self-kick`);

    return result.data;
  };

  return {
    getMy,
    create,
    getWallet,
    depositeWallet,
    getOperations,
    getDetails,
    updateDetails,
    getClients,
    editClient,
    editClientSettings,
    deleteClient,
    selfKick,
  };
};