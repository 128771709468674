<script setup lang="ts">
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import PricingMock from '../components/PricingMock.vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useLocaleStore } from '@/stores/locale.store';

  const _adaptive = useAdaptiveStore();
  const { t } = useLocaleStore();
</script>

<template>
  <div class="section-pricing bg-dot-pattern-2" :class="{'-mobile': _adaptive.isMobile}">
    <PricingMock />

    <div class="questions">
      <span class="text-grey-200">{{ t('landing.pricing.questions.title') }}</span>
      <span class="mt-2 text-grey-50 text-[32px]">{{ t('landing.pricing.questions.subtitle') }}</span>

      <Button
        preset="default-stroke"
        class="support-btn"
        href="https://t.me/rustapp_help"
      >
        <Svg.telegram />
        {{ t('landing.pricing.support-chat') }}
        <Svg.link class="!w-4 ml-2" />
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-pricing {
  @apply flex flex-col justify-center items-center pt-[140px] pb-20;

  &.-mobile {
    @apply px-10 pt-[100px];
  }

  .questions {
    @apply flex flex-col items-center text-center max-w-[580px];

    .support-btn {
      @apply py-2.5 text-14-500 w-fit mt-5;
      @apply text-grey-50 fill-grey-50 border-grey-700;

      svg {
        @apply w-[17px];
        @apply fill-grey-50;
      }

      &:hover {
        @apply bg-grey-850;
      }
    }
  }
}
</style>