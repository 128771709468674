<script setup lang='ts' generic="T">
  import type { Component } from 'vue';
  import { ref, computed, Ref } from 'vue';
  import { watchDebounced, useMagicKeys, whenever } from '@vueuse/core';
  import { DebugService } from '@/api/backend/debug.service';
  import Loader from '@/components/Loader.vue';

  interface SuggestionHolder {
    requestId?: string;
    value: T[] | null;
  }

  const modelValue = defineModel<undefined | T | T[]>({ required: true }) as Ref<undefined | T | T[]>;

  const inputElement = ref<HTMLInputElement | null>(null);
  const inputValue = ref<string>();

  const props = withDefaults(defineProps<{ 
    placeholder?: string;

    middleware?: (value: T) => T[] | T | undefined;
    suggester?: (value: string) => Promise<T[]> | T[];

    // Если компилер отсутствует, это запрещает возможность вписать текст самому, не используя предложения
    compiler?: (value: string) => T;

    theme?: 'dark' | 'light';
    icon?: Component;
    noWrap?: boolean;

    // Предложения будут создаваться моментально на @input, а не с задержкой
    instaSuggest?: boolean;
    disableInput?: boolean;

    max?: number;
  }>(), {
    suggester  : undefined,
    middleware : undefined,
    placeholder: undefined,
    compiler   : undefined,
    
    max  : undefined,
    icon : undefined,
    theme: 'dark'
  });

  const isValueString = computed(() => typeof modelValue.value === 'string');

  if (isValueString.value) {
    inputValue.value = modelValue.value as string;
  }

  /**
   * Предложения могут быть:
   * T[] {length: 0} -> предложений нет
   * null -> предложения загружаются
   * T[] {length: N} -> предложения загружены, можно выбирать
   */
  const suggestions = ref<SuggestionHolder>({ value: []}) as Ref<SuggestionHolder>;

  const suggest = async () => {
    // Если нет метода для предложения - игнорируем
    if (!props.suggester) {
      return;
    }
    
    // Сносим старые предложения
    suggestions.value = { value: [] };
    
    const requestId = Math.random().toString();   

    try {
      suggestions.value = { requestId, value: null };

      const response = await props.suggester(trimInput.value ?? '');
    
      if (suggestions.value.requestId !== requestId && !props.instaSuggest) {
        DebugService.debug(`Предложения уже неактуальны`);
        return;
      }

      suggestions.value = { value: response };
    }
    catch (err) {
      suggestions.value = {
        value: []
      };
      throw err;
    }
  };

  const debounceOptions = computed(() => {
    if (props.instaSuggest) { return {}; }

    return { debounce: 250, maxWait: 1500 };
  });

  watchDebounced(modelValue, () => {
    if (isValueString.value) {
      inputValue.value = modelValue.value as string;
    }
  });

  watchDebounced(inputValue, () => suggestions.value.requestId = undefined);

  watchDebounced(inputValue, async () => {
    // Если мы сейчас загружаемся, или не для чего предлагать - игнорируем
    if (!suggestions.value || inputElement.value !== document.activeElement) {
      return;
    }

    await suggest();
  }, debounceOptions.value);

  watchDebounced(suggestions, () => {
    keyNavigationIndex.value = null;
  });

  const compile = () => {
    const compiled = props.compiler?.(trimInput.value);
    if (compiled) {
      confirm(compiled);
      return;
    }
  };

  const confirm = (value: T) => {
    if (Array.isArray(modelValue.value)) {
      const same = (modelValue.value as Array<unknown>).some(v => JSON.stringify(v) === JSON.stringify(value));
      if (same) {
        modelValue.value = modelValue.value.filter(v => JSON.stringify(v) !== JSON.stringify(value));
        return false;
      }

      const override = props.middleware?.(value);
      if (override) {
        modelValue.value = override;
      } else {
        modelValue.value.push(value);

        if (!inputValue.value?.length) {
          return false;
        }
      }
    } else {
      const override = props.middleware?.(value);

      modelValue.value = override ?? value;
    }

    if (isValueString.value) {
      inputValue.value = value as string;
    } else {
      inputValue.value = '';
    }
    
    if (!Array.isArray(modelValue.value)) {
      inputElement.value?.blur();
    }

    return true;
  };

  const trimInput = computed(() => {
    return inputValue.value?.trim() ?? '';
  });
  
  /**
   * Keyboard handler
   */

  /**
   * Индекс выбранного при помощи клавиш
   * null - ничего не выбрано
   */
  const keyNavigationIndex = ref<number | null>(null);

  const { arrowup, arrowdown, backspace } = useMagicKeys({
    passive     : false,
    onEventFired: (e) => (e.key === 'ArrowUp' || e.type === 'ArrowDown' ? e.preventDefault() : undefined)
  });

  whenever(arrowup, () => switchKeyboard('up'));
  whenever(arrowdown, () => switchKeyboard('down'));
  whenever(backspace, () => {
    if (isValueString.value || inputValue.value?.length) {
      return;
    }

    if (document.activeElement != inputElement.value) {
      return;
    }

    if (Array.isArray(modelValue.value)) {
      modelValue.value = modelValue.value.slice(0, modelValue.value.length - 1);
    } else {
      modelValue.value = undefined;
    }
  });

  const switchKeyboard = (dir: 'up' | 'down') => {
    if (!suggestions.value.value?.length) {
      return;
    }

    if (keyNavigationIndex.value === null && dir === 'up') {
      return;
    }

    const unsafeNextKey = (keyNavigationIndex.value ?? -1) + (dir === 'up' ? -1 : 1);
    const nextKey = Math.min(Math.max(unsafeNextKey, -1), (suggestions.value.value?.length ?? 1) - 1);
    
    if (nextKey === -1) {
      keyNavigationIndex.value = null; // Точка выхода, фокусимся снова
      return;
    }

    keyNavigationIndex.value = nextKey; // Точка входа, и обновлений, проверяем анфокус
  };

  const keyboardSelected = computed(() => {
    if (keyNavigationIndex.value === null || !suggestions.value.value?.length) {
      return;
    }

    return suggestions.value.value[keyNavigationIndex.value];
  });

  const onInputFocus = async () => {
    await suggest();
  };

  const onInputBlur = (e: Event) => {
    suggestions.value = { value: [] };

    // Если модель - строка, визуал всегда должен соответствовать действительности
    if (isValueString.value) {
      compile();

      inputValue.value = modelValue.value as string;
    } else {
      inputValue.value = '';
    }
  };

  const onInputEnter = () => {
    // Если выбрана какая-то навигация, пробуем подтвердить
    if (keyboardSelected.value) {
      const hide = confirm(keyboardSelected.value);

      if (hide) {
        keyNavigationIndex.value = null;
      }
    } else {
      compile();
    }
  };

  const renderValues = computed(() => {
    if (Array.isArray(modelValue.value)) {
      return modelValue.value;
    }

    if (!modelValue.value) {
      return [];
    }

    return [modelValue.value];
  });

  const suggestUsed = (value: unknown) => {
    if (Array.isArray(modelValue.value)) {
      return modelValue.value.some(v => JSON.stringify(v) === JSON.stringify(value));
    }

    return JSON.stringify(modelValue.value) == JSON.stringify(value);
  };
</script>

<template>
  <div
    class="suggester-input-wrapper"
    :class="[theme, { 'no-wrap': noWrap }]"
    @click="inputElement?.focus()"
  >
    <div class="input-box">
      <template v-if="!isValueString && $slots.default">
        <template v-for="value in renderValues" :key="JSON.stringify(value)">
          <div class="value" @click="confirm(value)">
            <slot name="default" v-bind="{ value }" />
          </div>
        </template>
      </template>

      <input
        ref="inputElement" 
        v-model="inputValue" 
        :readonly="disableInput || (!!max && Array.isArray(modelValue) && modelValue.length >= max)"
        :placeholder="(!Array.isArray(modelValue) && !!modelValue) || (Array.isArray(modelValue) && modelValue.length) ? '' : placeholder"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @keypress.enter="onInputEnter"
      >
    </div>

    <component
      :is="icon"
      v-if="icon"
      class="fill-white/25 w-6 flex-shrink-0"
    />

    <template v-if="suggestions.value?.length !== 0">
      <div class="suggestions-wrapper">
        <template v-if="suggestions.value?.length">
          <template v-for="(value, index) in suggestions.value" :key="index">
            <div
              class="suggestion"
              :class="{ selected: index === keyNavigationIndex }"
              @mousedown.prevent="confirm(value)"
              @touchend.prevent="confirm(value)"
            >
              <slot name="suggest" v-bind="{ value, selected: index === keyNavigationIndex, used: suggestUsed(value) }" />
            </div>
          </template>
        </template>
        <template v-else>
          <div class="flex items-center justify-center w-full h-40">
            <Loader />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
  .suggester-input-wrapper {
    min-width: 200px;

    @apply relative;
    @apply rounded-md; 
    @apply p-1;
    min-height: 40px;
    height: auto;
    @apply flex items-center;
    @apply cursor-pointer;
    .input-box {
      @apply flex flex-1 flex-wrap gap-1;

      .value {
        @apply flex items-center;
        @apply rounded;
      }
    }

    &.no-wrap {
      .input-box {
        flex-wrap: nowrap;
        overflow-x: auto;
        
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        input {
          min-width: 100px;
        }
      }
    }
    &.dark {
      @apply bg-grey-1000;
      .input-box {
        .value {
          @apply bg-grey-900 text-white;
        }
      }
    }

    &.light {
      @apply bg-grey-900;
      .input-box {
        .value {
          @apply bg-white/5 text-white;
        }
      }
    }

    input {
      @apply bg-transparent;
      @apply text-white;
      @apply text-base;
      width: 0;
      min-width: 50px;
      flex: 1 1;
      &::placeholder {
        @apply text-grey-750;
      }
      &:read-only {
        cursor: pointer;
      }
    }
    
    .suggestions-wrapper {
      @apply absolute top-full right-0;
      @apply flex flex-col w-full p-1.5 mt-1;
      @apply rounded-md;
      @apply bg-grey-800 shadow-lg;
      @apply z-50;
      max-height: 170px;
      height: fit-content;
      overflow-y: auto;
      
      .suggestion {
        @apply flex items-center text-grey-50;
        @apply w-full;
        @apply cursor-pointer hover:bg-grey-700 rounded;

        &.selected {
          @apply bg-grey-650 rounded-md;
        }
      }
    }
  }
</style>