<script setup lang="ts">
  import { ref } from 'vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import PlanIcon from '@court/components/plans/PlanIcon.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useLocaleStore } from '@/stores/locale.store';

  const _adaptive = useAdaptiveStore();
  const { t } = useLocaleStore();

  const plans = ref([
    {
      name    : 'starter',
      price   : '$6',
      features: [
        t('landing.pricing.features.db', {count: 25}),
        t('landing.pricing.features.servers', {count: 2}),
        t('landing.pricing.features.player-state'),
        t('landing.pricing.features.player-base-info'),
        t('landing.pricing.features.player-steam-info'),
        t('landing.pricing.features.player-nick-history'),
        t('landing.pricing.features.player-activity'),
        t('landing.pricing.features.chat-system'),
        t('landing.pricing.features.report-system'),
        t('landing.pricing.features.inspection-system'),
        t('landing.pricing.features.ban-system'),
        t('landing.pricing.features.proofs'),
        t('landing.pricing.features.restriction-system'),
        t('landing.pricing.features.alert-system'),
        t('landing.pricing.features.staff-system'),
        t('landing.pricing.features.custom-actions'),
        t('landing.pricing.features.audit'),
        t('landing.pricing.features.discord-int')
      ],
      button: {
        text  : t('landing.pricing.get-free-trial'),
        preset: 'primary',
      },
      bgColor      : 'bg-none',
      gradientColor: 'rgba(67, 166, 240, 0.15)',
      checkColor   : '#43A6F0'
    },
    {
      name    : 'basic',
      price   : '$17',
      features: [
        t('landing.pricing.features.db', {count: 100}),
        t('landing.pricing.features.servers', {count: 5}),
        t('landing.pricing.features.all-from-starter'),
        t('landing.pricing.features.player-stats'),
        t('landing.pricing.features.combatlog'),
        t('landing.pricing.features.ban-share'),
        t('landing.pricing.features.staff-stats'),
        t('landing.pricing.features.team-history'),
        t('landing.pricing.features.drawings'),
        t('landing.pricing.features.custom-alerts'),
        t('landing.pricing.features.rcc-int')
      ],
      button: {
        text  : t('landing.pricing.get-started'),
        preset: 'default-light',
      },
      bgColor      : 'bg-none',
      gradientColor: 'rgba(180, 241, 142, 0.15)',
      checkColor   : '#B4F18E'
    },
    {
      name    : 'pro',
      price   : '$33',
      features: [
        t('landing.pricing.features.unlim-player-bd'),
        t('landing.pricing.features.servers', {count: 10}),
        t('landing.pricing.features.all-from-basic'),
        t('landing.pricing.features.api-access')
      ],
      button: {
        text  : t('landing.pricing.get-started'),
        preset: 'default-light',
      },
      bgColor      : 'bg-none',
      gradientColor: 'rgba(255, 197, 85, 0.15)',
      checkColor   : '#FFC555'
    }
  ]);
</script>

<template>
  <div class="pricing" :class="{'-mobile': _adaptive.isMobile}">
    <div class="offer">
      <p class="offer-title">{{ t('landing.pricing.title') }}</p>
      <p class="offer-subtitle">{{ t('landing.pricing.subtitle') }}</p>
    </div>

    <div class="plans bg-dot-pattern">
      <div
        v-for="plan in plans"
        :key="plan.name"
        class="plan"
        :class="plan.bgColor"
      >
        <div
          class="header"
          :style="`background: radial-gradient(50% 80% at 50% 100%, ${plan.gradientColor} 0%, rgb(67, 166, 240, 0) 100%), radial-gradient(20% 35% at 50% 100%, ${plan.gradientColor} 0%, rgb(67, 166, 240, 0) 100%);`"
        >
          <span class="flex justify-between text-[24px] font-semibold capitalize">
            {{ plan.name }}
            <PlanIcon
              :plan="plan.name"
              :width="28"
              :width-icon="20"
            />
          </span>
          <span class="text-[28px]">
            {{ plan.price }}<span class="text-[18px] text-grey-400">{{ '/' + t('landing.pricing.month') }}</span>
          </span>
          <RouterLink to="/profile">
            <Button
              :preset="plan.button.preset as any"
              :text="plan.button.text"
              class="!py-2.5 !text-14-500 w-full"
            />
          </RouterLink>
        </div>
        <div class="features">
          <div
            v-for="feature in plan.features"
            :key="feature"
            class="gap-1 items-center"
          >
            <Svg.checkmark_1 />
            <span>
              {{ feature }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pricing {
  @apply flex flex-col items-center w-full;

  .offer {
    @apply w-full max-w-[580px] mx-auto;
    @apply flex flex-col items-center;

    .offer-title {
      @apply text-[40px] text-center;
      @apply font-black;
      background: radial-gradient(circle at center, #F2F2F2 40%, #b6b6b6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .offer-subtitle {
      @apply text-grey-200;
      @apply text-[22px] text-center;
      @apply text-grey-200;
      @apply mt-4;
    }
  }

  .plans {
    @apply grid grid-cols-3 divide-x divide-grey-850 my-20;
    @apply rounded-xl;
    @apply border border-grey-850;
    mask-image: radial-gradient(circle at bottom right, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 50%);

    .plan {
      @apply w-[300px];

      .header {
        @apply flex flex-col gap-5 text-grey-50 p-5;
        @apply border-b border-grey-850;
      }
      .features {
        @apply flex flex-col gap-2.5 text-grey-200 p-5;

        svg {
          @apply inline mr-1;
          @apply w-[18px] fill-grey-600;
        }
      }
    }
  }

  &.-mobile {
    .offer {
      .offer-title {
        @apply text-[34px];
      }
      .offer-subtitle {
        @apply text-base;
      }
    }

    .plans {
      @apply flex flex-col divide-none my-10;
      mask-image: none;
    }
  }
}
</style>