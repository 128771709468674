import { AllowedLanguage, useLocaleStore } from "@/stores/locale.store";
import { ref } from "vue";

export const useMoney = () => {
  const balance = ref<number | null>(null);

  const _locale = useLocaleStore();

  const toLocaleBalance = (balance: number, locale: AllowedLanguage = _locale.lang, fixed?: number) => {
    const useRubbles = _locale.lang === 'ru' || _locale.lang === 'uk';

    let formattedBalance = useRubbles ? balance : balance / 95;

    if (fixed !== undefined) {
      formattedBalance = +(formattedBalance).toFixed(fixed);

      if (useRubbles) {
        formattedBalance = +formattedBalance.toFixed(0);
      }
    }

    return (useRubbles ? '₽' : '$') + formattedBalance.toLocaleString(`${locale}-${locale.toUpperCase()}`);
  };

  const toMajorUnits = (amount: number) => {
    return amount / 100;
  };

  return {
    balance,
    toLocaleBalance,
    toMajorUnits
  };
};