<script setup lang='ts'>
  import PageNavigation, { type PageNavigationSection } from '@panel/components/PanelPageNavigation.vue';
  import { computed } from 'vue';

  import { useLocaleStore } from '@/stores/locale.store';
  import SearchButton from './search/SearchButton.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { CourtRoutes } from '@court/court.routes';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCourtStore } from '@court/stores/court.store';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  
  const { t } = useLocaleStore();

  const _court = useCourtStore();
  const _subscription = useCourtSubscriptionStore();
  const _permissions = usePermissionsStore(); 

  const courtNavigationSections = computed((): PageNavigationSection[] => {
    return [
      {
        items: {
          // [CourtRoutes.Dashboard]: {
          //   name: t('court.navigation.dashboard'),
          //   icon: Svg.window,
          // },
          [CourtRoutes.Welcome]: {
            name: t('court.navigation.welcome'),
            icon: Svg.warning_circle,
          },
        },
      },
      {
        name : t('court.navigation.moderation.text'),
        items: {
          [CourtRoutes.Players]: {
            name: t('court.navigation.main.players'),
            icon: Svg.players,

            hidden: !_permissions.can(['Court_PlayerReadDetails']),
            //paid  : () => _subscription.needSubscription('Page_Players')
          },
          [CourtRoutes.Chat]: {
            name: t('court.navigation.main.chats'),
            icon: Svg.chat,
            
            hidden: !_permissions.can(['Court_ChatReadPublic']),
            //paid  : () => _subscription.needSubscription('Page_Chat')
          },
          [CourtRoutes.Reports]: {
            name  : t('court.navigation.main.reports'),
            icon  : Svg.report,
            hidden: !_permissions.can(['Court_ReportRead']),
            //paid  : () => _subscription.needSubscription('Page_Reports')
          },
          [CourtRoutes.Checks]: {
            name  : t('court.navigation.main.checks'),
            icon  : Svg.check,
            hidden: !_permissions.can(['Court_CheckRead']),
            //paid  : () => _subscription.needSubscription('Page_Checks')
          },
          [CourtRoutes.Signages]: {
            name  : t('court.navigation.main.signages'),
            icon  : Svg.images_circle,
            hidden: !_permissions.can(['Court_SignageControl']),
          },
          [CourtRoutes.Alerts]: {
            name  : t('court.navigation.main.alerts'),
            hidden: !_permissions.can(['Court_AlertRead']),
            icon  : Svg.alert,
            //paid  : () => _subscription.needSubscription('Page_Alerts'),
          },
          [CourtRoutes.Bans]: {
            name  : t('court.navigation.main.bans'),
            icon  : Svg.lock,
            hidden: !_permissions.can(['Court_PlayerBanRead']),
            //paid  : () => _subscription.needSubscription('Page_Bans'),
          }
        },
      },
      {
        name : t('court.navigation.settings.text'),
        items: {
          [CourtRoutes.Stats]: {
            name: t('court.navigation.settings.stats'),
            icon: Svg.statistics,
          },
          [CourtRoutes.Servers]: {
            name   : t('court.navigation.settings.servers'),
            icon   : Svg.server,
            hidden : !_permissions.can(['Court_ServerRead']),
            warning: _court.court?.servers.some(v => !v.updated) ?? false
          },
          [CourtRoutes.Audit]: {
            name  : t('court.navigation.settings.audit'),
            icon  : Svg.document,
            hidden: !_permissions.can(['Court_AuditRead'])
          },
          [CourtRoutes.Discord]: {
            name  : t('court.navigation.settings.integrations'),
            icon  : Svg.folder_link,
            hidden: !_permissions.isAdminOrOwner()
          },
          [CourtRoutes.Settings]: {
            name  : t('court.navigation.settings.customization'),
            icon  : Svg.settings_3,
            hidden: !_permissions.isAdminOrOwner(),
          },
        },
      },
    ];
  });
</script>

<template>
  <template v-if="_permissions.can(['Court_PlayerReadDetails'])">
    <SearchButton class="mb-4" />
  </template>

  <PageNavigation
    :sections="courtNavigationSections"
  />
</template>