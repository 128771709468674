<script setup lang="ts">
  import { onBeforeUnmount, ref } from 'vue';
  import TextInput from './TextInput.vue';
  import { watch } from 'vue';
  import { onMounted } from 'vue';

  const props = defineProps<{ amount: number, height: string }>();

  const emits = defineEmits(['submit']);

  const reset = () => {
    const obj: Record<string, string> = {};

    for (let i = 0; i < props.amount; i++) {
      obj[i] = '';
    }

    numbers.value = obj;
  };

  const numbers = ref<Record<string, string>>({});

  onMounted(() => {
    reset();
  });

  const firstEmpty = () => {
    return Object.values(numbers.value).findIndex((v) => v === '');
  };

  const onPaste = (e: ClipboardEvent) => {
    const type = (e.target as HTMLInputElement)?.type;
    if (type != 'text') {
      e.preventDefault();
      return;
    }

    const clipboard = e.clipboardData?.getData('Text');
    if (!clipboard || clipboard.length > 6) {
      e.preventDefault();
      return;
    }

    const cData = clipboard.replace(' ', '').replace(' ', '').split('');

    // Reset input before paste
    reset();

    cData.forEach((n, index) => {
      numbers.value[index] = n;
    });

    e.preventDefault();
  };

  const onDelete = (e: KeyboardEvent) => {
    if (e.keyCode != 8) {
      return;
    }

    emits('submit', undefined);

    let index = firstEmpty() === -1 ? Object.keys(numbers.value).length - 1 : firstEmpty() - 1;
    const element = numbers.value[index];
    if (!element || element?.length == 0) {
      return;
    }

    numbers.value[index] = '';
  };

  onMounted(() => {
    document.addEventListener('paste', onPaste);
    window.addEventListener('keydown', onDelete);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('paste', onPaste);
    window.removeEventListener('keydown', onDelete);
  });

  watch(
    () => firstEmpty(),
    (newValue) => {
      if (newValue < 0) {
        submit();
      }
    },
  );

  const submit = () => {
    emits('submit', Object.values(numbers.value).join(''));
  };

  defineExpose({ reset });
</script>

<template>
  <div class="code-input px-4.5">
    <template v-for="(_, key) of numbers" :key="key">
      <TextInput
        v-model="numbers[key]"
        :max-length="1"
        type="number"
        inputmode="numeric"
        :autofocus="key === firstEmpty().toString()"
        style="width: 55px"
        :height="props.height"
      />
    </template>
  </div>
</template>

<style lang="scss">
.code-input {
  @apply flex justify-center gap-5;

  input {
    text-align: center;
    font-size: 26px;
  }

  input::placeholder {
    text-align: center;
    font-size: 26px !important;
  }

  input:focus::placeholder {
    color: transparent;
    caret-color: transparent !important;
  }
}
</style>
