<script setup lang="ts">
  type LoaderProps = {
    color?: string;
    size?: string;
    speed?: string;
    stroke?: string;
    position?: string;
  }

  const props = withDefaults(defineProps<LoaderProps>(), {
    color   : "#A9A9A9",
    size    : "30px",
    speed   : "1s",
    stroke  : "2px",
    position: "absolute"
  });
</script>

<template>
  <div 
    class="loader" 
    :style="{
      '--uib-color': props.color,
      '--uib-size': props.size,
      '--uib-speed': props.speed,
      '--uib-stroke': props.stroke,
      '--uib-position': props.position
    }"
  >
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
    <div class="line" />
  </div>
</template>

<style scoped lang="scss">
  .loader {
    --uib-color: var(--uib-color, #A9A9A9);
    --uib-size: var(--uib-size, 30px);
    --uib-speed: var(--uib-speed, 1s);
    --uib-stroke: var(--uib-stroke, 2px);
    --uib-position: var(--uib-position, absolute);
    position: var(--uib-position);
    display: flex;
    height: var(--uib-size);
    width: var(--uib-size);
  }

  .line {
    position: absolute;
    top: 0;
    left: calc(50% - var(--uib-stroke) / 2);
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: var(--uib-stroke);
  }

  .line::before {
    content: '';
    height: 22%;
    width: 100%;
    border-radius: calc(var(--uib-stroke) / 2);
    background-color: var(--uib-color);
    animation: pulse calc(var(--uib-speed)) ease-in-out infinite;
    transition: background-color 0.3s ease;
    transform-origin: center bottom;
  }

  .line:nth-child(1) {
    transform: rotate(calc(360deg / -12 * 1));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 1);
    }
  }
  .line:nth-child(2) {
    transform: rotate(calc(360deg / -12 * 2));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 2);
    }
  }
  .line:nth-child(3) {
    transform: rotate(calc(360deg / -12 * 3));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 3);
    }
  }
  .line:nth-child(4) {
    transform: rotate(calc(360deg / -12 * 4));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 4);
    }
  }
  .line:nth-child(5) {
    transform: rotate(calc(360deg / -12 * 5));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 5);
    }
  }
  .line:nth-child(6) {
    transform: rotate(calc(360deg / -12 * 6));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 6);
    }
  }
  .line:nth-child(7) {
    transform: rotate(calc(360deg / -12 * 7));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 7);
    }
  }
  .line:nth-child(8) {
    transform: rotate(calc(360deg / -12 * 8));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 8);
    }
  }
  .line:nth-child(9) {
    transform: rotate(calc(360deg / -12 * 9));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 9);
    }
  }
  .line:nth-child(10) {
    transform: rotate(calc(360deg / -12 * 10));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 10);
    }
  }
  .line:nth-child(11) {
    transform: rotate(calc(360deg / -12 * 11));

    &::before {
      animation-delay: calc(var(--uib-speed) / -12 * 11);
    }
  }

  @keyframes pulse {
    0%,
    80%,
    100% {
      transform: scaleY(0.75);
      opacity: 0;
    }
    20% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
</style>