<script lang="ts">
  import { useModalsStore } from '@/stores/modals.store';
  import DividedCard from '@/components/DividedCard.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  export type TermsModalOpts = {
  }

  export const ActivateTermsModal = async (props: TermsModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('terms', props);
  };
</script>

<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';

  const { t } = useLocaleStore();
  const _modal = useModalsStore();
</script>

<template>
  <div class="terms-modal">
    <DividedCard>
      <template #header>
        <div class="header">
          <span class="title">{{ t('terms-modal-title') }}</span>
          <Svg.cross_large class="close" @click="_modal.close" />
        </div>
      </template>
      <template #default>
        <div class="container">
          <p>{{ t('terms-intro') }}</p>
          <div>
            <h2>{{ t('terms-agreement-subject') }}</h2>
            <p>{{ t('terms-service-access') }}</p>
            <p>{{ t('terms-user-consent') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-usage-conditions') }}</h2>
            <p>{{ t('terms-legal-compliance') }}</p>
            <p>{{ t('terms-prohibited-uses') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-services') }}</h2>
            <p>{{ t('terms-free-paid-services') }}</p>
            <p>{{ t('terms-paid-services-features') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-payment') }}</h2>
            <p>{{ t('terms-service-payment') }}</p>
            <p>{{ t('terms-payment-methods') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-refund-policy') }}</h2>
            <p>{{ t('terms-no-refund') }}</p>
            <p>{{ t('terms-refund-conditions') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-liability-limits') }}</h2>
            <p>{{ t('terms-no-liability') }}</p>
            <p>{{ t('terms-user-responsibility') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-termination') }}</h2>
            <p>{{ t('terms-user-termination') }}</p>
            <p>{{ t('terms-company-termination') }}</p>
          </div>
          <div>
            <h2>{{ t('terms-changes') }}</h2>
            <p>{{ t('terms-policy-changes') }}</p>
          </div>
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.terms-modal {
  width: 95vw;
  max-width: 600px;

  :deep(.content) {
    max-height: 540px;
  }

  .header {
    @apply flex justify-between items-center w-full;

    .title {
      @apply text-grey-50;
      @apply font-semibold;
      @apply block;
    }

    .close {
      @apply w-5;
      @apply fill-grey-400;
      @apply cursor-pointer;

      &:hover {
        @apply fill-grey-200;
      }
    }
  }

  .container {
    @apply flex flex-col gap-5;
    
    h2 {
      @apply text-grey-100 font-medium text-lg;
    }

    p {
      @apply text-grey-400;
    }
  }
}
</style>