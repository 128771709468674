<script setup lang='ts'>
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import Switch from '@/components/switch/Switch.vue';
  import Button from '@/components/Button.vue';
  import { DicordWebhookDetails } from '@court/pages/integrations/types/discord-webhook.dto';
  import { ActivateDiscordWebhookModal } from '@src/modals/discord-webhook/DiscordWebhookModal.vue';
  import { CourtDiscordDto, CourtDiscordType } from '@/api/backend/court/court-discord/court-discord.dto';
  import { computed } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<{ details: DicordWebhookDetails<CourtDiscordType>; value?: CourtDiscordDto }>(); 

  const toggle = async () => {
    if (!props.value) {
      ActivateDiscordWebhookModal(props.details);
      return;
    }

    const newValue = !props.value.active;
    Object.assign(props.value, { active: newValue });

    await useCourtApi().discord.toggle(props.details.type, newValue);
  };

  const computedBadge = computed(() => {
    if (props.value?.active) {
      return 'green';
    }

    return 'admin';
  });
</script>

<template>
  <div
    class="action relative !gap-2"
  >
    <slot />
    <ListItem
      :svg="{ component: details.icon, classes: 'fill-grey-600' }"
      :title="details.title"
      :message="details.subtitle"
      :skeleton="'avatar'"
      title-wrap
      message-wrap
    />
    <div class="right">
      <Button @click="toggle"> 
        <Switch
          ref="child"
          :readonly-value="value?.active ?? false"
        />
      </Button>
      <Svg.dropdown @click="(e: MouseEvent) => ActivateDiscordWebhookModal(details, props.value)" />
    </div>
    <div class="badge" :class="computedBadge" />
  </div>
</template>

<style lang='scss' scoped>
.action {
  @apply flex items-center justify-between overflow-hidden;
  @apply p-3;
  @apply rounded-md;
  @apply bg-grey-900;

  :deep(.img-box) {
    @apply bg-grey-1000 p-2;
  }
  
  .right {
    @apply flex gap-2;

    svg {
      @apply w-5 fill-grey-600;
      @apply cursor-pointer;

      &:hover {
        @apply fill-grey-400;
      }
    }
  }

  .badge {
    position: absolute;
    left: 0px;
    @apply h-[20px] w-[2px];
    @apply rounded-r-full;

    &.green {
      @apply bg-lime-500;
      box-shadow: #84cc16 0px 0px 12px 0.5px;
    }
    &.yellow {
      @apply bg-amber-500;
      box-shadow: #f59e0b 0px 0px 12px 0.5px;
    }
    &.red {
      @apply bg-red-500;
      box-shadow: #ff5031 0px 0px 12px 0.5px;
    }
    &.admin {
      @apply bg-grey-500;
      box-shadow: #383838 0px 0px 12px 0.5px;
    }
  }
}
</style>../types/discord-webhook.storage