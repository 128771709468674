import { defineStore } from "pinia";
import { useLocaleStore } from "./locale.store";
import { ExtendedMenuItem } from "@/components/quality/menu-extended/MenuExtended.vue";

export type SoundType = 'None' | 'Ping' | 'Din-Don';

export const useSoundStore = defineStore('sound', () => {
  const _locale = useLocaleStore();

  const soundsAvailable: Record<SoundType, { text: string, url?: string }> = {
    'None'   : { text: _locale.t('sounds.none') },
    'Ping'   : { text: _locale.t('sounds.ping'), url: 'https://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3' },
    'Din-Don': { text: _locale.t('sounds.din-don'), url: 'https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3' },
  };

  const audios: Record<SoundType, HTMLAudioElement | undefined> = Object.fromEntries(Object.entries(soundsAvailable).map(([key, value]) => {
    return [key, value.url ? new Audio(value.url) : undefined];
  })) as Record<SoundType, HTMLAudioElement | undefined>;

  const soundsDropdown = (active: (v: SoundType) => boolean, confirm: (v: SoundType) => void): ExtendedMenuItem[] => {
    return Object.entries(soundsAvailable).map<ExtendedMenuItem>(([key, value]) => {
      return {
        label : value.text,
        active: () => active(key as SoundType),
        action: () => { 
          confirm(key as SoundType);
          play(key as SoundType);

          return false;
        }
      };
    });
  };

  const getSoundName = (sound: SoundType) => soundsAvailable[sound];

  const play = (sound: SoundType, volume: number = 0.2) => {
    const audio = audios[sound];
    if (!audio) {
      return;
    }

    audio.volume = volume;

    try {
      audio.pause();
    } catch {

    }

    audio.currentTime = 0;
    audio.play().catch(_ => undefined);
  };

  return { soundsAvailable, soundsDropdown, getSoundName, play };
});