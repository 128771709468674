<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import Loading from '@/components/Loading.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useModalsStore } from '@/stores/modals.store';

  defineProps<{
    mobile: boolean,
    showFooter?: boolean,
    onCancel?: () => void,
    onConfirm?: () => void
    loading?: boolean;
  }>();

  const { t } = useLocaleStore();

  const close = () => {
    useModalsStore().close();
  };
</script>

<template>
  <div class="modal-menu-tab relative" :class="{ '-mobile': mobile }">
    <Loading :can-view="!loading" />

    <div class="tab-content">
      <slot />
    </div>
    <div
      class="footer"
      :class="{ '-hidden': (!onCancel && !onConfirm) && !mobile }"
    >
      <Button
        preset="primary"
        :disabled="!onConfirm"
        :action="onConfirm"
      >
        {{ t('general.save') }}
      </Button>
      <Button
        preset="default"
        :action="() => onCancel ? onCancel() : close?.()"
      >
        {{ onCancel ? t('general.toCancel') : t('general.close') }}
      </Button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal-menu-tab {
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-hidden;

  &.-mobile {
    .tab-content {
      height: 800px;
    }
  }

  .tab-content {
    @apply overflow-auto;
    @apply p-5;
    @apply flex flex-col gap-10;
    @apply grow;
  }

  .footer {
    @apply border-t border-grey-1000;
    @apply flex gap-2.5 justify-start;
    @apply px-5 py-4;
    @apply duration-200;

    &.-hidden {
      @apply h-0;
      @apply py-0;
      @apply border-t-0;
    }
  }

  &.-mobile {
    .tab-content {
      @apply p-5 gap-5;
    }
    .footer {
      @apply px-5 flex-row-reverse;
    }
  }
}
</style>