<script setup lang='ts'>
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { ProjectPublicDto } from '@/api/backend/core/project/project.dto';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();

  defineProps<{ project: ProjectPublicDto; subtext: string; }>();
</script>

<template>
  <ListItem
    :image-url="useConfigStore().Urls.Images(project.avatar)"
    :title="project.name"
    :message="subtext"
    :skeleton="'avatar'"
  >
    <template #title>
      <div class="title">
        <p>{{ project.name }}</p>

        <TooltipBase trigger="hover"> 
          <Svg.tick v-if="project.verified" class="tick" />

          <template #content> 
            <div class="px-1.5 py-1 text-xs text-center max-w-[240px]"> 
              <p>{{ t('general.verified') }}</p> 
            </div> 
          </template> 
        </TooltipBase>
      </div>  
    </template>
  </ListItem>
</template>

<style lang='scss' scoped>

.title {
  @apply flex items-center justify-start gap-1;

  .tick {
    @apply h-[18px] w-[18px] fill-primary-600;
  }
}
</style>