<script setup lang="ts">
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import Loading from '@/components/Loading.vue';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import { SignageDto } from '@/api/backend/court/signages/signages.dto';
  import SignageCard from '@court/pages/signages/components/SignageCard.vue';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import BaseTip from '@panel/components/BaseTip.vue';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import { SignageMock } from '@court/pages/signages/mock/signage.mock';
  import { useUnknownPlayer } from '@court/utils/player.utils';

  const props = defineProps<{ 
    steamId: string;
    mode: 'actual' | 'destroyed';
  }>();

  const { t } = useLocaleStore();
  const signages = ref<SignageDto[]>();

  const _panel = usePanelStore();

  const loader = computed(() => {
    return new PaginationService<SignageDto>((page, limit) => useCourtApi().signages.browse({
      limit         : 10,
      page,
      steam_id      : props.steamId,
      hide_destroyed: props.mode === 'actual',
      hide_actual   : props.mode === 'destroyed'
    }));
  });

  onMounted(() => next());

  const centrifugoSub = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoSub.value = useCentrifugoApi().subscribeSignageUpdate(_panel.project.id, (data) => {
      if (!signages.value) {
        return;
      }

      if (props.steamId != data.variants[0].player.steam_id) {
        return;
      }

      const exists = signages.value.find(v => v.project_id == data.project_id && v.server_id == data.server_id && v.net_id == data.net_id);
      if (exists) {
        if (data.destroyed_at) {
          onDeleted(data);
          return;
        }

        Object.assign(exists, data);
        return;
      }
      
      if (data.destroyed_at) {
        return;
      }

      signages.value.unshift(data);
    });
  });

  const onDeleted = (data: SignageDto) => {
    if (!signages.value) {
      return;
    }

    const indexOf = signages.value.findIndex(v => v.project_id == data.project_id && v.server_id == data.server_id && v.net_id == data.net_id);
        
    if (indexOf >= 0) {
      signages.value.splice(indexOf, 1);
    }
  };

  onUnmounted(() => {
    centrifugoSub.value?.unsubscribe();
  });

  watch(() => loader.value, (v) => {
    if (!v) {
      return;
    }

    signages.value = undefined;
    next();
  }, {
    deep: true
  });
  
  const next = async () => {
    
    try {
      const { results } = await loader.value.next();

      if (!signages.value) {
        signages.value = [];
      }

      signages.value?.push(...results);
    }
    catch(err: any) {
      
      if (!err?.message?.includes('опция доступна')) {
        throw err;
      }

      signages.value = SignageMock.map<SignageDto>(v => {
        const variants = [{
          url       : v,
          created_at: Date.now(),
          player    : useUnknownPlayer().generate('765611981211000397'),
        }];

        if (Math.random() > 0.5) {
          variants.push({
            url       : v,
            created_at: Date.now(),
            player    : useUnknownPlayer().generate('765611981211000397'),
          });
        }

        return {
          net_id    : '123456789',
          position  : '102.12, 24.21, 24.52',
          project_id: 1,
          server_id : 1,
          square    : 'F1',
          type      : 'sign.small.wood',
          variants  : variants,
          liked_at  : Math.random() > 0.8 ? Date.now() : undefined,
        };
      });
    }
  };
</script>

<template>
  <CourtNoSubscriptionContent :type="'DrawingsSystem'" color="bg-grey-900/80">
    <template v-if="signages && signages.length == 0">
      <div class="empty">
        <EmptyImage
          type="empty"
          :title="t('modal.player.signages.empty.title')"
          :subtitle="t('modal.player.signages.empty.subtitle')"
        />
      </div>
    </template>
    <div class="relative h-full w-full" v-else>
      <Loading :can-view="signages != null" class="!bg-transparent" />

      <template v-if="signages != null">
        <BaseTip
          v-if="props.mode === 'destroyed'"
          type="hint"
          disposable="signages-profile-destroyed"
          class="mb-5"
        >
          {{ t('basetip-signages-profile-all') }}
        </BaseTip>
        <div class="signages pb-4">
          <template v-for="(value, _) in signages" :key="_">
            <SignageCard
              :signage="value"
              :like-only="false"
              :removed="() => onDeleted(value)"
              profile
            /> 
          </template>
        </div>

        <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
      </template>
    </div>
  </CourtNoSubscriptionContent>
</template>

<style lang='scss' scoped>
.empty {
  @apply flex flex-col justify-center items-center h-full;
}

.signages {
  @apply grid grid-cols-2 gap-5;
}

[device='mobile'] {
  .signages {
    @apply grid-cols-1 gap-5;
  }
}
</style>