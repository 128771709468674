<script setup lang="ts">
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import AuditTable from './components/AuditTable.vue';
  import AuditSidebar from './components/AuditSidebar.vue';
  import { ref } from 'vue';

  const { t } = useLocaleStore();

  const sidebar = ref<InstanceType<typeof AuditSidebar>>();
</script>

<template>
  <LayoutPage :header="t('audit.header')">
    <AuditTable :client_id="sidebar?.choosedClientId ?? null" :type="sidebar?.choosedType ?? null" />

    <template #custom>
      <AuditSidebar ref="sidebar" />
    </template>
  </LayoutPage>
</template>