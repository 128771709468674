<script setup lang="ts">
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import Divider from '@/components/Divider.vue';
  import PageHeader from '../PageHeader.vue';
  import Loading from '../Loading.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  defineProps<{
    topTitle?: string;
    topSubtitle?: string;
    bottomTitle?: string;
    bottomSubtitle?: string;

    loading?: boolean;
    noDivider?: boolean,
    noMargin?: boolean;

    customClass?: string;
  }>();

  const { t } = useLocaleStore();
  const _adaptive = useAdaptiveStore();
</script>

<template>
  <PageHeader
    v-if="_adaptive.isMobile"
    :title="t('global.menu')"
    class="page-header"
  />
  
  <div class="titled-layout" :class="customClass">
    <div>
      <p v-if="topTitle" class="top-title">{{ topTitle }}</p>
      <template v-else>
        <slot name="top-title" />
      </template>
        
      <p v-if="topSubtitle" class="top-subtitle">{{ topSubtitle }}</p>
      <template v-else>
        <slot name="top-subtitle" />
      </template>
    </div>

    <Divider v-show="!noDivider" class="mt-8" />

    <div class="relative h-fit" :class="{ 'mt-8': !noMargin }">
      <Loading :can-view="!loading" style="height: 200px;" />

      <div v-show="!loading" class="h-fit">
        <slot />

        <Divider v-if="bottomTitle || bottomSubtitle" class="my-8" />
        <p class="bottom-title">{{ bottomTitle }}</p>
        <p class="bottom-subtitle">{{ bottomSubtitle }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[device='mobile'] {
  .titled-layout {
    @apply pt-5 pb-10 px-5;
    @apply overflow-auto;
  }
}
.titled-layout {
  @apply mx-auto;
  width: 100%;
  max-width: 820px;
  @apply px-10 pb-20 pt-20;
  .top {
    &-title {
      @apply text-2xl font-semibold text-grey-50 mb-1;
    }
    &-subtitle {
    @apply text-base font-normal;
    @apply text-grey-400;
      
    }
  }

  .bottom {
    &-title {
      @apply text-base font-normal text-grey-50 mb-1;
    }

    &-subtitle {
      @apply text-base font-normal;
      @apply text-grey-400;
    }
  }
}

.page-header {
  @apply border-white/5;
  @apply bg-grey-1000;
}
</style>