import { PaginatedResponse, PaginationQuery } from "@/api/backend/pagination.service";

import { ReportBrowseDto } from "./report.dto";
import { Axios } from "axios";

export const useCourtReportApi = (api: Axios) => {
  const browse = async(opts: PaginationQuery & { sort: 'date' | 'count'; min?: number; steam_id?: string; search?: string; hide_offline?: boolean; unique_initiators?: boolean; }) => {
    const result = await api.get<PaginatedResponse<ReportBrowseDto>>('/reports/browse', {
      params: {
        ...opts
      }
    });

    return result.data;
  };

  const deleteOne = async(reportId: number) => {
    await api.delete(`/reports`, {
      params: {
        report_id: reportId
      }
    });
  };

  const deleteAll = async() => {
    await api.delete(`/reports/all`);
  };

  const deleteAllOnPlayer = async(steamId: string) => {
    await api.delete(`/reports`, {
      params: {
        target_steam_id: steamId
      }
    });
  };
  
  return {
    browse,
    delete: deleteOne,
    deleteAll,
    deleteAllOnPlayer,
  };
};
