import { defineStore } from "pinia";
import { ref, watch } from "vue";


import { CourtPublic } from "@/api/backend/court/court/court.dto";
import { DebugService } from "@/api/backend/debug.service";
import { usePlayerStatusStore } from "./player-status.store";
import { usePanelStore } from "@panel/stores/panel.store";
import { useLocaleStore } from "@/stores/locale.store";
import { ActivateConfirmModal } from "@src/modals/confirm/ConfirmModal.vue";
import { usePermissionsStore } from "@panel/stores/permissions.store";
import { usePluginDownload } from "@court/utils/plugin.utils";
import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
import { useCourtSubscriptionStore } from "./subscription.store";
import { CourtCommandDto } from "@/api/backend/court/commands/commands.dto";
import { useBanStore } from "./ban.store";
import { useCourtApi } from "@/api/backend/court/court.api";
import { lt } from "lodash";

export const useCourtStore = defineStore("court", () => {
  usePlayerStatusStore();

  const { t } = useLocaleStore();
  const _panel = usePanelStore();
  const _ban = useBanStore();
  const _subscription = useCourtSubscriptionStore();
  
  const debug = new DebugService(`Court:#8bc94e`);

  const court = ref<CourtPublic>();
  const commands = ref<CourtCommandDto[]>();

  watch(() => _panel.project, () => warmup(), { deep: true });

  const warmupCommands = async () => {
    if (!_panel.project) {
      return;
    }

    //commands.value = undefined;
    
    const [commandDetails] = await Promise.all([
      useCourtApi().commands.get()
    ]);

    commands.value = commandDetails;
  };

  const warmup = async () => {
    if (!_panel.project) {
      return;
    }

    debug.debug(`Загрузка данных для мод. панели`);

    try {
    court.value = undefined;
      commands.value = undefined;

      const [courtDetails, commandDetails] = await Promise.all([
        useCourtApi().court.get(),
        useCourtApi().commands.get(),
        _subscription.warmup(false),
      ]);

      _ban.warmup();

      court.value = courtDetails;
      commands.value = commandDetails;

      debug.debug(`Загружена информация о мод. панели`, court.value);

      setDefaults();
      alertUpdate();

      //if (court.value.servers.length === 0 && _permissions.isAdminOrOwner()) {
        //_router.push({ name: CourtRoutes.Servers });
      //}
    }
    catch (err) {
      debug.error(`Не удалось загрузить информацию о панеле`, err);
    }
  };

  const isAllServerUpdatedUpTo = (version: string) => {
    return !(court.value?.servers ?? []).some((v) => v.version && lt(v.version, version));
  };
  
  const setDefaults = () => {
    if (!court.value) {
      return;
    }
    if (!court.value.options.ban_reasons.length) {
      court.value.options.ban_reasons.push(
        t('modal.ban.general.reason.cheat'),
        t('modal.ban.general.reason.macros'),
        t('modal.ban.general.reason.abuse'),
        t('modal.ban.general.reason.breaking-rules'),
        t('modal.ban.general.reason.multiacc'),
        '1+',
        '2+',
        '3+'
      );
    }

    if (!court.value.options.ban_mass_reasons.length) {
      court.value.options.ban_mass_reasons.push(
        t('modal.ban.general.reason.playwithviolator'),
        t('modal.ban.general.reason.cheaterteammate'),
        '1+',
        '2+',
        '3+'
      );
    }

    if (!court.value.options.check_verdicts.length) {
      court.value.options.check_verdicts.push(
        t('modal.ban.general.reason.cheat'),
        t('modal.ban.general.reason.macros'),
        t('modal.ban.general.reason.abuse'),
        t('modal.ban.general.reason.leave'),
        t('modal.ban.general.reason.ignore'),
        t('modal.ban.general.reason.refusal'),
        t('modal.ban.general.reason.basedonresults'),
      );
    }
  };

  const alertUpdate = () => {
    const notUpdated = court.value?.servers.find(v => !v.updated);
    
    if (!notUpdated || !court.value) {
      return;
    }

    const key = `server-update-${court.value.project_id}-${notUpdated.version}`;
    const value = window.localStorage.getItem(key) as string | null;

    if (value && Date.now() < +value) {
      if (usePermissionsStore().can(['Court_ServerManage'])) {  
        useRuntimeNotification(`warning`, t('update-plugin.notification'));
      }

      return;
    }

    const onClosed = () => {
      window.localStorage.setItem(key, (Date.now() + 24 * 60 * 60 * 1_000).toString());
    };

    if (usePermissionsStore().can(['Court_ServerManage'])) {
      ActivateConfirmModal({
        type       : 'warn',
        title      : t('update-plugin.confirm-header') ,
        description: t('update-plugin.confirm-subtitle-admin'),
        onConfirmed: () => {
          const { pluginDownload } = usePluginDownload();
    
          pluginDownload('default');
          
          ActivateConfirmModal({
            type       : 'warn',
            title      : t('plugin-update-title'),
            description: t('plugin-update-subtitle')
          });
        },
        onClosed: onClosed
      });
    } else {
      ActivateConfirmModal({
        type       : 'warn',
        title      : t('update-plugin.confirm-header') ,
        description: t('update-plugin.confirm-subtitle-all'),
        onClosed   : onClosed
      });
    }
    
    /**
     * 
    
     */
  };

  const serverNameById = (id?: number) => {
    if (!court.value || !id) {
      return t('general.server-loading');
    }

    const server = court.value.servers.find((v) => v.id === id);

    return server?.name ?? t('general.server-not-found');
  };

  return {
    court,
    commands,

    warmup,
    warmupCommands,
    serverNameById,

    isAllServerUpdatedUpTo
  };
});
