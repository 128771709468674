
import { Axios } from "axios";
import { CourtCommandDto, CourtCommandPayloadDto, CourtCommandPresetDto, CourtExecuteCommandResponseDto } from "./commands.dto";

export const useCourtCommandsApi = (api: Axios) => {
  const get = async () => {
    const response = await api.get<CourtCommandDto[]>(`/court-commands`);

    return response.data;
  };
  const getPresets = async () => {
    const response = await api.get<CourtCommandPresetDto[]>(`/court-commands/preset`);

    return response.data;
  };

  const prepare = async (command_id: number, opts: { steam_id: string; custom_server_id?: number; custom_args?: Record<string, string> }) => {
    const response = await api.post<CourtExecuteCommandResponseDto>(`/court-commands/${command_id}/prepare`, opts);

    return response.data;
  };

  const execute = async (command_id: number, opts: { steam_id: string; custom_server_id?: number; custom_args?: Record<string, string> }) => {
    const response = await api.post<CourtExecuteCommandResponseDto>(`/court-commands/${command_id}/execute`, opts);

    return response.data;
  };

  const create = async (data: CourtCommandPayloadDto) => {
    const response = await api.post(`/court-commands/`, data);
    
    return response.data;
  };

  const edit = async (command_id: number, data: CourtCommandPayloadDto) => {
    const response = await api.put(`/court-commands/${command_id}/edit`, data);
    
    return response.data;
  };

  const editOrder = async (commandIdsInOrder: number[]) => {
    const result = await api.put<boolean>('/court-commands/save-order', null, {
      params: {
        'command-ids-in-order': commandIdsInOrder
      }
    });

    return result.data;
  };

  const toggle = async (command_id: number, active: boolean) => {
    const response = await api.post(`/court-commands/${command_id}/toggle`, {
      active
    });
    
    return response.data;
  };

  const deleteCommand = async (command_id: number) => {
    await api.delete(`/court-commands/${command_id}`);
  };

  return {
    get,
    getPresets,
    prepare,
    execute,
    create,
    edit,
    editOrder,
    toggle,
    delete: deleteCommand,
  };
};
