<script setup lang='ts'>
  import { CheckActivityMetaNoticeChangedDto, CheckDto } from '@/api/backend/court/check/check.dto';
  import CheckActivityBase from './CheckActivityBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, ref, watch } from 'vue';
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useCoreApi } from '@/api/backend/core/core.api';

  const { t } = useLocaleStore();
  const _time = useTimeStore();

  const props = defineProps<{ 
    check: CheckDto | null,
    meta: CheckActivityMetaNoticeChangedDto, 
    reversed: boolean,
    created: number,
  }>();

  const moderator = ref<ClientDto>();

  onMounted(() => updateModerator());
  watch(() => props.check, () => updateModerator(), { deep: true });

  const updateModerator = async () => {
    if (!props.check || moderator.value) {
      return;
    }

    if (props.check.client_id === props.meta.client_id || !props.check.client_id || !props.meta.client_id) {
      return;
    }

    moderator.value = await useCoreApi().client.browseBatchLoader.load(props.meta.client_id);
  };

  const title = computed(() => {
    if (props.meta.active) {
      if (props.meta.error) {
        return t('check.activity.notice-changed.title.showed-error');
      }

      return t('check.activity.notice-changed.title.showed');
    }

    if (props.meta.error) {
      return t('check.activity.notice-changed.title.hidden-error');
    }

    return t('check.activity.notice-changed.title.hidden');
  });

  const subtitle = computed(() => {
    if (props.meta.error) {
      switch (props.meta.error) {
        case 'player.offline':{
          return t('check.activity.notice-changed.subtitle.error.player-offline');
        }
        case 'no.response':{
          return t('check.activity.notice-changed.subtitle.error.no-response');
        }
        case 'queue.down':{
          return t('check.activity.notice-changed.subtitle.error.queue-down');
        }
        case 'plugin.declined': {
          return t('check.activity.notice-changed.subtitle.error.plugin-declined');
        }
      }

      return props.meta.error;
    }

    if (props.meta.active) {
      return t('check.activity.notice-changed.subtitle.showed');
    }

    return t('check.activity.notice-changed.subtitle.hidden');
  });

  const circleColor = computed(() => {
    if (props.meta.error) {
      return `bg-grey-1000 !border-red-500 border-dashed !rounded-none`;
    }

    if (!props.meta.active) {
      return `bg-grey-1000 !border-grey-300 border-dotted`;
    }

    return 'bg-grey-1000 !border-grey-300';
  });
</script>

<template>
  <CheckActivityBase
    :reversed="reversed"
    :title="title"
    :created="created"
    :subtitle="subtitle"
    :circle-class="`${circleColor}`"
  >
    <template #content>
      <div>
        <p class="title">{{ title }}</p>
        <p class="subtitle">{{ subtitle }}</p> 

        <template v-if="meta.player_cache && !meta.error">
          <div class="states">
            <div
              v-if="meta.player_cache?.position"
              class="list-item bg-dot-pattern"
              :class="{ 'warning': created - (meta.player_cache.position.time ?? created) > 20_000 }"
            >
              <p class="text">{{ t('modal.player.general.play-info.last-move') }}</p>
              <p class="value">{{ !meta.player_cache.position.time ? t('general.unknown') : _time.fromDate(created, meta.player_cache.position.time ?? 0) }}</p>
            </div>

            <div v-if="meta.player_cache?.home" class="list-item bg-dot-pattern">
              <p class="text">{{ t('modal.player.general.play-info.last-home') }}</p>
              <p class="value">
                <template v-if="!meta.player_cache.home.time">
                  {{ t('general.unknown') }}
                </template>
                <template v-else>
                  {{ meta.player_cache.home.value ? t('general.yes') : _time.fromDate(created, meta.player_cache.home.time ?? 0) }}
                </template>
              </p>
            </div>

            <div class="list-item bg-dot-pattern" :class="{ 'warning': !meta.reports_number }">
              <p class="text">{{ t('check.activity.notice-changed.meta.reports') }}</p>
              <p class="value">{{ t('general.amount-st', { amount: meta.reports_number ?? '0'}) }}</p>
            </div>

            <div
              v-if="meta.player_cache?.raid.value"
              class="list-item bg-dot-pattern"
              :class="{ 'warning': meta.player_cache?.raid.value }"
            >
              <p class="text">{{ t('check.activity.notice-changed.meta.raidblock') }}</p>
              <p class="value">{{ t('general.yes') }}</p>
            </div>
          </div>
        </template>
      </div>
    </template>
  </CheckActivityBase>
</template>

<style lang='scss' scoped>
.title {
  @apply text-grey-50;
  @apply text-lg;
  @apply font-medium;
}
.subtitle {
  @apply flex gap-1 items-center w-fit;
  @apply text-grey-400;
}

.states {
  @apply flex gap-2.5 mt-2.5;
  @apply overflow-hidden;

  .list-item {
    @apply p-2;
    @apply flex flex-col gap-0.5;
    @apply rounded-md;
    @apply bg-grey-900;

    .text {
      @apply text-grey-500 text-14-400;
      @apply truncate;
    }

    .value {
      @apply text-grey-200 text-16-500;
      @apply truncate;
    }
  }
}

@media (max-width: 800px) {
  .states {
    @apply grid grid-cols-2 w-full;
  }
}

@media (max-width: 400px) {
  .states {
    @apply grid grid-cols-1 w-full;
  }
}
</style>