<script setup lang='ts'>
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const _adaptive = useAdaptiveStore();

  const { t } = useLocaleStore();

  defineProps<{
    label?: string;
    placeholder?: string;
    tooltipVars?: string[],
  }>();

  const modelValue = defineModel<string[]>( {required: true});
 
  /*const removeAll = () => {
    modelValue.value = [];
  };*/

  const errorMessage = t('customization.bans.reasons.error');
</script>

<template>
  <div>
    <label class="label">
      <div class="flex flex-1 items-center gap-1 w-full">
        <span class="truncate">
          {{ label }}
        </span>
      
        <TooltipBase trigger="hover" class="tooltip">
          <div>
            <Svg.circle_info class="w-4 fill-grey-400 cursor-pointer" />
          </div>

          <template #content>
            <p class="tooltip-content">
              <span>{{ t('customization.bans.reasons.tooltip.1') }}</span>
              <template v-for="(tooltipVar, _) in tooltipVars" :key="tooltipVar">
                <span class="ml-1 text-amber-400">{{ tooltipVar }}</span>
                <span>,</span>
              </template>
              <span>{{ t('customization.bans.reasons.tooltip.2') }}</span>
            </p>
          </template>
        </TooltipBase>
      </div>
    </label>
    <SuggesterInput
      v-model="modelValue"
      :class="{ error: !modelValue.length }"
      :compiler="(value: string) => value" 
      class="suggester"
      theme="dark"
      :placeholder="placeholder"
    >
      <template #default="{ value }">
        <div class="item">
          <p>{{ value }}</p>
          <Svg.clear />
        </div>
      </template>
    </SuggesterInput>
  </div>
</template>

<style lang='scss' scoped>

.label {
  @apply flex items-center justify-between;
  @apply text-grey-400;
}

.suggester {
  @apply p-1;
  @apply mt-1.5;
  min-height: 36px;

  &:deep(.input-box) {
    @apply gap-1;
  }

  &.error {
    @apply mb-[19px];
    @apply border-red-500/25;

    &::after {
      content: v-bind(errorMessage);
      top: calc(100% + 4px);
      @apply absolute left-0;
      @apply text-14-400;
      @apply text-red-500/75;
    }
  }
  

  .item {
    @apply bg-grey-900;
    @apply py-1 px-1.5;
    @apply rounded-[4px];
    @apply text-grey-400;
    @apply flex gap-1 items-center;

    &:hover {
      svg {
        @apply fill-grey-500;
      }
    }

    svg {
      @apply shrink-0;
      @apply w-4;
      @apply fill-grey-700;
    }
  }
}

.tooltip-content {
  @apply max-w-72 px-1.5 py-1 text-sm text-center;
  @apply text-grey-100;
}


.suggester {
  ::placeholder {
    @apply pl-1;
  }
}
</style>