<script setup lang="ts">
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { ActivateBanViewModal } from '../BanViewModal.vue';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  defineProps<{
    bans?: BanDto[]
  }>();
  const { t } = useLocaleStore();
  
  const openBanModal = (ban: BanDto) => {
    ActivateBanViewModal({ ban, banId: ban.id, projectId: ban.project_id });
  };

  const { generate } = useUnknownPlayer();
</script>

<template>
  <p class="title !pb-2.5">{{ t('modal.ban-view.related-bans') }}</p>
  <div v-if="bans?.length" class="items-row">
    <template v-for="ban in bans" :key="ban.id">
      <div class="item" @click="openBanModal(ban)">
        <PlayerBox
          :player="ban ? ban?.player ?? generate(ban.steam_id, ban.player_minimal?.steam_name, ban.player_minimal?.steam_avatar) : null"
          :subtext="ban.reason"
          disable-hover
          disable-click
          :custom-click="() => openBanModal(ban)"
          class="max-w-[280px]"
        />
        <Svg.open class="icon" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.title {
  @apply text-grey-50;
  @apply font-medium pt-5;
}
.player-inline {
  @apply px-1.5 py-1;
  @apply text-grey-200 font-medium;
  @apply bg-grey-800;
  @apply rounded-md;
  @apply transition-all duration-200 ease-out;

  &:hover {
    @apply opacity-75;
  }
}
</style>