<script setup lang="ts">
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed } from 'vue';

  type NotifyProps = {
    type?: "yellow" | "red";
    title: string;
    subtitle: string;
  }

  const props = withDefaults(defineProps<NotifyProps>(), {
    type: "yellow"
  });

  const iconComponent = computed(() => {
    switch (props.type) {
      case 'yellow':
        return Svg.time_flies;
        
      case 'red':
        return Svg.snow_flakes;

      default:
        return Svg.warning_circle;
    }
  });
</script>

<template>
  <div class="notify bg-dot-pattern" :class="props.type">
    <p class="title">
      <component :is="iconComponent" />
      {{ props.title }}
    </p>
    <p class="subtitle">{{ props.subtitle }}</p>
  </div>
</template>

<style scoped lang="scss">
.notify {
  @apply flex flex-col gap-1 p-5;
  @apply rounded-md;
  @apply bg-grey-900;

  .title {
    @apply font-semibold text-grey-50;

    svg {
      @apply inline w-5 align-text-top;
    }
  }
  .subtitle {
    @apply text-grey-50/50;
  }

  &.red {
    @apply bg-red-500/15;

    .title {
      svg {
        @apply fill-red-500;
      }
    }
  }

  &.yellow {
    @apply bg-amber-400/15;
    
    .title {
      svg {
        @apply fill-amber-400;
      }
    }
  }
}
</style>