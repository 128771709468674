<script setup lang='ts'>
  import { computed } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useSituationStore } from '@panel/stores/situation.store';
  import { Svg } from '@src/assets/auto_gen_types.dto'; 

  const _locale = useLocaleStore();
  const _situation = useSituationStore();

  const text = computed((): string => {
    if (!_situation.current?.value) {
      return '';
    }

    return _locale.isRu ? _situation.current.value.text_ru : _situation.current.value.text_en;
  });

  const iconComponent = computed(() => {
    switch (_situation.current?.value?.type) {
      case 'warn':
        return Svg.warning;
        
      case 'critical':
        return Svg.warning;

      case 'connection':
        return Svg.chart;

      case 'success':
        return Svg.check_circle;

      default:
        return null;
    }
  });
</script>

<template>
  <div
    v-if="_situation.current?.value?.id"
    class="situation"
    :class="_situation.current.value?.type" 
  >
    <div class="text">
      <component :is="iconComponent" />
      <p>
        {{ text }}
      </p>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.situation {
  flex: 0 1 auto;
  @apply px-2 py-2;
  @apply bg-grey-900;
  @apply border-b border-grey-1000;
  @apply flex justify-between items-center;
  
  .text {
    @apply flex items-center;
    @apply text-grey-50;
    @apply text-sm font-semibold;

    svg {
      flex: 1 1 auto;
      @apply flex-shrink-0;
      @apply w-5 mr-2;
      @apply fill-grey-200;
    }
  }

  &.warn {
    @apply bg-yellow-500;
    svg {
      @apply fill-grey-900;
    }
    .text {
      @apply text-grey-900;
    }
  }

  &.critical {
    @apply bg-red-600;
    svg {
      @apply fill-grey-50;
    }
    .text {
      @apply text-grey-50;
    }
  }

  &.connection {
    @apply bg-yellow-500;
    svg {
      @apply fill-grey-900;
    }
    .text {
      @apply text-grey-900;
    }
  }

  &.success {
    @apply bg-lime-500;
    svg {
      @apply fill-grey-900;
    }
    .text {
      @apply text-grey-900;
    }
  }
}
</style>