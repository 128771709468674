<script setup lang="ts">
  import UploaderAreaDefault from './component/UploaderAreaDefault.vue';
  import UploaderAreaList from './component/UploaderAreaList.vue';
  import { ref } from 'vue';
  import { UploadedFile } from './utils/uploader.dto';
  import { UploadAvailableType } from '@/api/backend/core/utils/utils.dto';
  import { useEventListener } from '@vueuse/core';
  import { useStatisStore } from '@/stores/static.store';
  import { v4 as uuidv4 } from 'uuid';
  import { useCoreApi } from '@/api/backend/core/core.api';

  const _static = useStatisStore();

  const props = defineProps<{ 
    header: string, 
    multiple: boolean, 
    type: UploadAvailableType,
    label?: string
  }>();
  const emit = defineEmits(['delete']);
  const input = ref<HTMLInputElement>();

  useEventListener('paste', (e) => {
    var item = Array.from(e.clipboardData?.items ?? []).find(x => /^image\//.test(x.type));
    if (!item) {
      return;
    }
      
    const file = item.getAsFile();
    if (!file) {
      return;
    }

    const dt = new DataTransfer();

    dt.items.add(file);

    change(dt.files);
  });

  const files = defineModel<UploadedFile[]>({ default: [] });

  const choose = () => {
    if (!input.value) {
      return;
    }

    input.value.click();
  };

  const change = (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    for (const file of fileList) {
      if (files.value.length >= _static.uploader.maxImages) {
        alert('Очень много картинок, притормози!');
        return;
      }

      const temp_file_id = uuidv4();
      const obj: UploadedFile = { file, temp_file_id };

      files.value.push(obj);

      upload(file, temp_file_id);
    }
  };

  const upload = async (file: File, temp_file_id: string) => {
    const id = await useCoreApi().utils.upload(file, props.type);

    const exists = files.value.find(v => v.temp_file_id === temp_file_id);
    if (!exists) {
      return;
    }

    exists.s3_id = id;

    setTimeout(() => {
      const files = document.getElementsByClassName('file');
      const lastFile = files[files.length - 1];
      lastFile?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 0);
  };
</script>

<template>
  <template v-if="files.length === 0">
    <UploaderAreaDefault
      :header="header"
      :label="label"
      @click="choose()"
    />
  </template>
  <template v-else>
    <UploaderAreaList
      v-model="files"
      :header="header"
      :multiple="multiple"
      :choose="choose"
      @delete="emit('delete')"
    />
  </template>

  <input
    :key="files.length"
    ref="input"

    type="file"
    class="invisible absolute"
    :multiple="multiple"
    accept="image/*"

    @change="e => change((e.target as HTMLInputElement).files)"
  >
</template>
