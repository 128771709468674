import { Dictionary } from "lodash";
import { useAppStore } from "../app.store";
import { EnvVariables } from "./env.variables";
import { defineStore } from "pinia";
import { AvatarsLibraryStaff } from "./data/avatars-staff.dto";
import { AvatarsLibraryProject } from "./data/avatars-project.dto";

export const useConfigStore = defineStore('config', () => {
  const CreateUrl = (sub: string, domain: string, extra?: string, protocol = 'https') =>
    `${protocol}://${sub}.${domain}${extra ?? ''}`;

  const Avatars = {
    // Show this avatar when real avatar is not loaded
    PlayerAvatarPlaceholder: 'https://s3.rustapp.io/avatar-user/1704281217881-3ecd831bb95e4bef780a4846.jpeg',

    // For case when we know only steam-id and not gonna load avatar
    PlayerNotExistsPlaceholder: 'https://s3.rustapp.io/avatar-user/1704281217881-3ecd831bb95e4bef780a4846.jpeg',

    // Avatar for moderator when we don't want to show real avatar
    StaffHiddenPlaceholder: 'https://s3.rustapp.io/custom/moder.png',

    LibraryStaff  : AvatarsLibraryStaff,
    LibraryProject: AvatarsLibraryProject
  };

  const Branding = {
    RustAppName  : 'RustApp',
    RustAppAvatar: 'https://s3.rustapp.io/avatar-user/1705069601395-c90df605ab7257802c5dde1b.png',
  };

  const Uploader = {
    UploaderMaxSize: '10MB'
  };

  const Urls = {
    Images: (image: string) => {
      if (image.includes('https')) {
        return image;
      }

      if (!image.includes('/')) {
        return Avatars.PlayerNotExistsPlaceholder; 
      }

      if (image.includes('plugin-sign')) {
        return CreateUrl('r2', EnvVariables.DomainApi, `/${image}`);
      }
      
      return CreateUrl('s3', EnvVariables.DomainApi, `/${image}`);
    },
    Core      : CreateUrl('core', EnvVariables.DomainApi),
    Court     : CreateUrl('court', EnvVariables.DomainApi),
    Centrifugo: CreateUrl('centrifugo', EnvVariables.DomainApi, '/connection/websocket', 'wss'),
  };

  return { Uploader, Branding, Avatars, Urls };
});