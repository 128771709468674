<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useEventListener, useInterval } from '@vueuse/core';
  import TextInput from '@/components/inputs/TextInput.vue';

  export type ConfirmModalOpts = {
    type: 'default' | 'error' | 'warn' | 'success' | 'pro',
    title: string,
    description: string,

    delay?: number;

    prompt?: string;
    promptStrict?: boolean;

    beforeConfirm?: (prompt?: string) => Promise<void>,
    
    onConfirmed?: (prompt?: string) => void,
    onCanceled?: () => void,

    onClosed?: () => void
  }

  export const ActivateConfirmModal = async (props: ConfirmModalOpts) => {
    const _modal = useModalsStore();

    const result = await new Promise<boolean>(res => {
      let finishedWithSuccess: true | false = false;
      let finishedWithPrompt: string | undefined = undefined;

      _modal.open('confirm', { 
        ...props,
        
        beforeConfirm: props.beforeConfirm || props.onConfirmed ? async (prompt) => {
          await props.beforeConfirm?.(prompt);

          finishedWithSuccess = true;
          finishedWithPrompt = prompt;
        } : undefined,
        onClosed: () => {
          // По закрытию всегда вызываем себя, но заод
          props.onClosed?.();

          if (props.onConfirmed || props.onCanceled) {
            if (finishedWithSuccess) {
              props.onConfirmed?.(finishedWithPrompt);
            } else {
              props.onCanceled?.();
            }
          }

          res(finishedWithSuccess);
        }
      });
    });

    return result;
  };

  export const ActivateConfirmModalSync = (props: Omit<ConfirmModalOpts, 'onClosed'>) => {
    const _modal = useModalsStore();

    _modal.open('confirm', {
      ...props
    });
  };
</script>

<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import TripleWarningIcon from '@src/assets/icons/triple-warning.svg';
  import ProIcon from '@src/assets/icons/pro-big.svg';
  import { computed, onMounted, ref, watch } from 'vue';
  import Button from '@/components/Button.vue';

  const props = defineProps<ConfirmModalOpts & ModalInjectedProps>();

  const { t } = useLocaleStore();

  onMounted(() => setupOptions()); 
  watch(() => props.injected.modal.id, () => setupOptions(), { deep: true }); 
 
  const setupOptions = () => { 
    props.injected.setAfterClose(async () => { 
      props.onClosed?.(); 

    }); 
  }; 
  
  const resolve = async() => {
    if (props.delay && counter.value < props.delay) {
      return;
    }

    await props.beforeConfirm?.(promptText.value);

    await props.injected.close();
  };

  const isPro = computed(() => {
    return props.type === 'pro';
  });

  useEventListener('keypress', (k) => {
    if (k.key != 'Enter' || !props.beforeConfirm) {
      return;
    }

    resolve();
  }, { passive: true });

  const promptText = ref<string>('');

  const counter = useInterval(1_000);

  const disabledText = computed(() => {
    if (!props.delay || counter.value > props.delay) {
      return;
    }

    return `${props.delay - counter.value} ${t('general.seconds-small')}`;
  });
</script>

<template>
  <div class="confirm">
    <div class="content">
      <div class="mt-2 mb-3" :class="`icon-${type}`">
        <ProIcon v-if="isPro" />
        <TripleWarningIcon v-else />
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="title" v-html="isPro ? t('modal.pro.title') : title" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="description" v-html="isPro ? t('modal.pro.description') : description" />

      <template v-if="prompt">
        <TextInput
          v-model="promptText"
          :placeholder="prompt"
          class="mt-4 w-full"
        />
      </template>
    </div>

    <div class="buttons">
      <Button
        :class="beforeConfirm ? 'basis-1/2' : 'basis-full'"
        :text="beforeConfirm ? t('general.cancel') : t('general.ponyatno')"
        :action="injected.close"
        preset="default"
      />
      <Button
        v-if="beforeConfirm"
        :class="[type, 'basis-1/2']"
        :text="disabledText ? disabledText.toString() : (isPro ? t('general.more-details') : t('general.confirm.resolve'))"
        :disabled="!!disabledText"
        :action="resolve"
        preset="primary"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.confirm {
  @apply flex flex-col p-5 gap-6;
  width: 340px;

  .content {
    @apply flex flex-col items-center;

    .icon {
      &-default {
        @apply fill-[#575757];
        filter: drop-shadow(0px 0px 35px rgba(87, 87, 87, 0.4));
      }
      &-error {
        @apply fill-[#F0503A];
        filter: drop-shadow(0px 0px 35px rgba(240, 80, 58, 0.4));
      }
      &-warn {
        @apply fill-[#FFD62A];
        filter: drop-shadow(0px 0px 35px rgba(255, 212, 41, 0.4));
      }
      &-success {
        @apply fill-[#8BC94E];
        filter: drop-shadow(0px 0px 35px rgba(139, 201, 78, 0.4));
      }
    }

    .title {
      @apply text-lg;
      @apply font-semibold;
      @apply text-center;
      @apply text-grey-50;
      @apply mb-1.5;
    }
    .description {
      @apply text-grey-400;
      @apply font-normal;
      @apply text-center;
      width: 100%;
    }
  }
  .buttons {
    @apply flex w-full gap-3;
  }
}
.pro {
  p {
    @apply text-grey-900;
  }
  background: linear-gradient(69deg, #8BB4C1 0%, #F3D482 100%);
}
</style>