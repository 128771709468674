
<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { ampli } from '@src/ampli';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type ServerPairModalOpts = { 
    server?: ServerFullDto;
    onUpdated: () => Promise<void>;
  };

  export const ActivateServerPairModal = async (props: ServerPairModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('server-pair', props);
  };
</script>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { ref } from 'vue';
  import { ServerFullDto, ServerPairDto } from '@/api/backend/court/servers/servers.dto';
  import { useIntervalFn } from '@vueuse/core';
  import Loading from '@/components/Loading.vue';
  import PairCommandStep from './ServerPairCommandStep.vue';
  import PairConfirmStep from './ServerPairConfirmStep.vue'; 
  import { useLocaleStore } from '@/stores/locale.store';

  const { t } = useLocaleStore();

  const props = defineProps<ServerPairModalOpts & ModalInjectedProps>();

  const serverPair = ref<ServerPairDto>();
  const startPairing = async () => {
    const result = await useCourtApi().servers.startPair();
  
    serverPair.value = result;

    ampli.clientServerPairStart({ server_id: props.server?.id });
  };

  const updatePairing = async () => {
    if (!serverPair.value) {
      return;
    }

    try {
      serverPair.value = await useCourtApi().servers.getPairData(serverPair.value?.code);
    }
    catch (err: any) {
      if (err?.message?.includes('Code not exists')) {
        ampli.clientServerPairComplete({ server_id: props.server?.id, result: 'error' });
    
        pause();

        props.injected.setBeforeClose(async () => {
          await ActivateConfirmModal({
            type       : 'error',
            title      : t('modal.server.connect.expired.title'),
            description: t('modal.server.connect.expired.subtitle')
          });

          return true;
        });
        
        props.injected.close();        
        return;
      }
    }
  };

  onMounted(async () => {
    await startPairing();
  });

  const { pause, resume } = useIntervalFn(() => updatePairing(), 1_000);
</script>

<template>
  <div class="relative server-pair">
    <Loading :can-view="!!serverPair" />

    <template v-if="!serverPair?.plugin">
      <PairCommandStep
        :server="server"
        :pair-data="serverPair"
      />
    </template>
    <template v-else>
      <PairConfirmStep
        :injected="injected"
        :pair-data="serverPair"
        :server="server"
        :on-updated="onUpdated"
        @pause="() => pause()"
        @resume="() => resume()"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.server-pair {
  width: 95vw;
  max-width: 420px;
  @apply relative;
}
 
</style>
