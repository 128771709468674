<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import ModalMenuListItem from '@/components/modal-menu/components/ModalMenuListItem.vue';
  import ModalMenuSection from '@/components/modal-menu/components/ModalMenuSection.vue';
  import ModalMenuTab from '@/components/modal-menu/components/ModalMenuTab.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useConfigStore } from '@/stores/config/config.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { ClientRoutes } from '@src/views/client/client.routes';
  import { useRoute, useRouter } from 'vue-router';

  defineProps<{
    mobile: boolean
  }>();

  const _app = useAppStore();
  const _route = useRoute();
  const _router = useRouter();
  const { t } = useLocaleStore();

  /**
   * const positionString = (project) => {
    const from = _time.format(project.start_date, 'DD.MM.YYYY');
    const to = project.end_date ? _time.format(project.end_date, 'DD.MM.YYYY', false, true) : t('modal.client.projects.history.now');

    return `${project.position}, ${t('modal.client.projects.history.from')} ${from} ${t('modal.client.projects.history.to')} ${to}`;
  };
  */
 
  const selfKick = async (projectId: number) => {
    await ActivateConfirmModal({
      type         : 'warn',
      title        : t('general.sure'),
      description  : t('you-lose-project-access'),
      beforeConfirm: async () => {
        const prj = _app.projects?.find(v => v.id === projectId);
        if (!prj) {
          return;
        }

        await useCoreApi().project.selfKick(projectId);
  
        _app.projects = _app.projects?.filter(v => v.id !== projectId);

        if (_route.params.project == prj.domain) {
          _router.push({ name: ClientRoutes.Profile });
        }
      }
    });
  };
</script>

<template>
  <ModalMenuTab
    :mobile="mobile"
  >
    <!--<ModalMenuSection :title="t('modal.client.projects.invites.title')" :mobile="mobile">
      <template v-for="invite in invites" :key="invite.id">
        <ModalMenuListItem
          :title="invite.name"
          :image-url="useConfigStore().Urls.Images(invite.avatar)"
          :message="invite.domain"
        >
          <template #right>
            <Button
              preset="primary" 
              class="!text-14-500"
            >
              {{ t('modal.client.projects.invites.confirm') }}
            </Button>
          </template>
        </ModalMenuListItem>
      </template>
    </ModalMenuSection><-->
    <ModalMenuSection :title="t('modal.client.projects.current.title')" :mobile="mobile">
      <template v-for="project in _app.projects" :key="project.id">
        <ModalMenuListItem
          :title="project.name"
          :image-url="useConfigStore().Urls.Images(project.avatar)"
          :message="project.domain"
        >
          <template #right>
            <Button 
              class="!text-14-500"
              preset="destructive"
              :action="() => selfKick(project.id)"
              :disabled="project.owner_client_id === _app.client?.id"
            >
              {{ t('modal.client.projects.current.leave') }}
            </Button>
          </template>
        </ModalMenuListItem>
      </template>
    </ModalMenuSection>
    <!--<ModalMenuSection :title="t('modal.client.projects.history.title')" :mobile="mobile">
      <template v-for="project in history" :key="project.id">
        <ModalMenuListItem
          :title="project.name"
          :image-url="useConfigStore().Urls.Images(project.avatar)"
          :message="positionString(project)"
        />
      </template>
    </ModalMenuSection>-->
  </ModalMenuTab>
</template>

<style lang='scss' scoped>
</style>