<script setup lang='ts'>
  import { Placement, Tooltip } from 'floating-vue';
  import { computed } from 'vue';

  const props = withDefaults(defineProps<{
    text?: string,
    trigger?: 'click' | 'hover',
    shown?: boolean,
    placement?: Placement
    arrowPadding?: number
  }>(),{
    text     : '',
    trigger  : 'click',
    placement: 'top',
  });

  const triggers = computed(() => {
    return props.trigger === 'hover' ? (['hover', 'click']) : [props.trigger];
  });
</script>

<template>
  <Tooltip
    :shown="shown"
    :distance="8"
    :placement="placement"
    :delay="{ show: 0, hide: 0 }"
    :triggers="triggers as any"
    :auto-hide="trigger === 'hover'"
    instant-move
    :arrow-padding="200"
    :disabled="!text && !$slots.content"
    :hide-triggers="[trigger]"
  >
    <slot />
    <template #popper> 
      <p v-if="text">{{ text }}</p>
      <slot v-else name="content" />
    </template>
  </Tooltip>
</template>

<style lang='scss'>
.v-popper--theme-tooltip {

  &.v-popper__popper {
    @apply z-50;
  }

  .v-popper__wrapper {
    .v-popper__inner {
      @apply rounded-md;
      @apply bg-grey-600;
      @apply p-0;
    }
    .v-popper__arrow-container {
      .v-popper__arrow-outer {
        @apply border-grey-600;
      }
      .v-popper__arrow-inner {
        display: none;
      }
    }
  }
}
</style>