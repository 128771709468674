<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import Divider from '@/components/Divider.vue';

  const { t } = useLocaleStore();
</script>

<template>
  <div class="flex flex-col p-4 gap-4 bg-grey-950 border border-grey-900 rounded-md">
    <p class="text-lg font-medium">{{ t('welcome.cbt.contact.links') }}</p>
    <div class="links">
      <a
        class="item"
        href="https://t.me/rustapp"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/ualrDXD.jpeg"
          :title="`Telegram`"
          message="t.me/rustapp"
          :skeleton="'avatar'"
        />
      </a>
      <a
        class="item"
        href="https://vk.com/rustapp"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/3ad6mrH.jpeg"
          :title="`ВКонтакте`"
          message="vk.com/rustapp"
          :skeleton="'avatar'"
        />
      </a>
      <a
        class="item"
        href="https://github.com/rust-app-io"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/3ykeDOH.jpeg"
          :title="`Github`"
          message="github.com/rust-app-io"
          :skeleton="'avatar'"
        />
      </a>
      <a
        class="item"
        href="https://docs.rustapp.io/"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/1K6xF0d.png"
          :title="t('general.docs')"
          message="docs.rustapp.io"
          :skeleton="'avatar'"
        />
      </a>
      <a
        class="item"
        href="https://t.me/rustapp_chat"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/gXaE2v6.jpeg"
          :title="t('welcome.media.chat')"
          message="t.me/rustapp_chat"
          :skeleton="'avatar'"
        />
      </a>
      <a
        class="item"
        href="https://t.me/rustapp_help"
        target="_blank"
      >
        <ListItem
          image-url="https://i.imgur.com/82T1E3B.jpeg"
          :title="t('general.support')"
          message="t.me/rustapp_help"
          :skeleton="'avatar'"
        />
      </a>
    </div>

    <Divider class="my-2" />

    <p class="text-grey-400">{{ t('welcome.cbt.chat') }}</p>
  </div>
</template>

<style lang='scss' scoped>
.links {
  @apply grid grid-cols-2 gap-2;

  :deep(.image) {
    img {
      @apply rounded-md;
    }
  }

  .item {
    @apply cursor-pointer;
    @apply p-2;
    @apply rounded-md;

    &:hover {
      @apply bg-grey-900;
    }
  }
}

[device="mobile"] {
  .links {
    @apply grid-cols-1;
  }
}

.chat { 
  @apply text-16-400 text-grey-400; 
  @apply pb-5;
} 

</style>