import { PaginationQuery } from "@/api/backend/pagination.service";
import { CourtSubscriptionTypes } from "../court/court.dto";
import { CourtCommandPayloadDto, CourtExecuteCommandResponseDto } from "../commands/commands.dto";

export type AuditBrowseOpts = {
  types?: string[];
  client_id?: number;
} & PaginationQuery;

export const AuditDict = {
  PlayerKick        : 'PlayerKick',
  PlayerIgnore      : 'PlayerIgnore',
  PlayerBan         : 'PlayerBan',
  PlayerCheck       : 'PlayerCheck',
  PlayerReportDelete: 'PlayerReportDelete',
  BanEdit           : 'BanEdit',
  CommandEdit       : 'CommandEdit',
  CommandExecute    : 'CommandExecute',
  EditSettings      : 'EditSettings',
  EditClient        : 'EditClient',
  EditSubscription  : 'EditSubscription',
  ChatMessage       : 'ChatMessage',
  SignageDelete     : 'SignageDelete'
};

export type AuditType = keyof (typeof AuditDict)

export type AuditDto<T = Record<string, string>> = {
  id: number;

  client_id: number;
  type: AuditType;
  meta: T;

  created_at: number;
}

export type AuditMetaPlayerKick = {
  target_steam_id: string;
  reason: string;
}

export type AuditMetaSignageDelete = {
  server_id: number;
  net_id: string;
}

export type AuditMetaPlayerIgnore = {
  target_steam_id: string;
  until?: number;
}

export type AuditMetaPlayerBan = {
  target_steam_id: string;
  ban_id: number;
}

export type AuditMetaPlayerCheck = {
  target_steam_id: string;
  check_id: number;
}

export type AuditMetaPlayerReportType = 'all' | 'single';
export type AuditMetaPlayerReportDelete = {
  target_steam_id?: string;
  action: AuditMetaPlayerReportType;
}

export type AuditMetaBanType = 'edit' | 'unban' | 'global-unban';
export type AuditMetaBanEdit = {
  ban_id: number;
  action: AuditMetaBanType;
  amount?: number;
}

export type AuditMetaCommandEditType = 'create' | 'edit' | 'toggle-on' | 'toggle-off' | 'delete';
export type AuditMetaCommandEdit = {
  command_id: number;
  command?: CourtCommandPayloadDto;
  action: AuditMetaCommandEditType;
}

export type AuditMetaCommandExecute= {
  target_steam_id: string;

  data: CourtExecuteCommandResponseDto;
}

export type AuditMetaEditSettingsType = 'court' | 'discord' | 'rcc' | 'server-pair' | 'server-edit' | 'server-delete';
export type AuditMetaEditSettings = {
  target: AuditMetaEditSettingsType;
  server_id?: number;
}

export type AuditMetaEditClientType = 'invite' | 'edit' | 'kick';
export type AuditMetaEditClient = {
  target_client_id: number;
  action: AuditMetaEditClientType;
}

export type AuditMetaEditSubscription= {
  from: CourtSubscriptionTypes;
  to: CourtSubscriptionTypes;
}

export type AuditMetaChatMessage = {
  reply_to?: string;
  message?: string;
}