<script setup lang='ts'>
  import Divider from '@/components/Divider.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import CustomAction from '../components/CustomAction.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateCommandCreateModal } from '@court/modals/commands/create/CommandCreateModal.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import Loading from '@/components/Loading.vue';
  import { firstBy, } from 'thenby';
  import { computed } from 'vue';
  import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';
  import { ActivateCommandPresetModal } from '@court/modals/commands/preset/CommandPresetModal.vue';
  import { VueDraggableNext } from 'vue-draggable-next';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import Button from '@/components/Button.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const _adaptive = useAdaptiveStore();
  const _court = useCourtStore();


  const ordered = computed(() => {
    if (!_court.commands) {
      return [];
    };

    const copy = [..._court.commands];

    return copy.sort(
      firstBy((a: CourtCommandDto, b: CourtCommandDto) => +(b.access === 'green') - +(a.access === 'green'))
        .thenBy((a: CourtCommandDto, b: CourtCommandDto) => +(b.access === 'yellow') - +(a.access === 'yellow'))
        .thenBy((a: CourtCommandDto, b: CourtCommandDto) => +(b.access === 'red') - +(a.access === 'red'))
        .thenBy((a: CourtCommandDto, b: CourtCommandDto) => +(b.access === 'admin') - +(a.access === 'admin'))
        .thenBy('group', 'asc')
        .thenBy('order', 'asc')
    );
  });

  const onDeleted = (item: CourtCommandDto) => {
    if (!_court.commands) {
      return;
    }

    const indexOf = _court.commands?.findIndex(v => v.id === item.id);
    if (indexOf < 0) {
      return;
    }

    _court.commands?.splice(indexOf, 1);
  };

  type MoveData<T> = { element: T | undefined, index: number, futureIndex: number, oldIndex: number, newIndex: number };
  type AllMoveData<T> = { draggedContext: MoveData<T>, relatedContext: MoveData<T> };

  const editOrder = async (data: { moved: MoveData<CourtCommandDto>}) => {
    if (!data.moved) {
      return;
    }

    ordered.value.forEach((v, index) => v!.order = index);

    await useCourtApi().commands.editOrder(ordered.value.map(v => v?.id ?? 0));
  };

  const orderMiddleware = (data: AllMoveData<CourtCommandDto>) => {
    if (!data.draggedContext.element || useAdaptiveStore().isMobile) {
      return false;
    }

    if (data.draggedContext.element.group != data.relatedContext.element?.group || data.draggedContext.element.access != data.relatedContext.element?.access) {
      return false;
    }
     
    return null;
  };
</script>

<template>
  <div class="relative">
    <Loading
      :can-view="!!_court.commands"
    />

    <div class="section">
      <div class="header">
        <p class="title">
          {{ t('customization.actions.actions.title') }}
        </p>
        <span class="subtitle">{{ t('customization.actions.actions.subtitle') }}</span>
      </div>
      <div class="actions">
        <div class="btn-group"> 
          <div class="add-action new" @click="ActivateCommandCreateModal()"> 
            <Svg.add /> 
            <p>{{ t('customization.actions.add') }}</p> 
          </div> 
          <div class="add-action list" @click="ActivateCommandPresetModal()"> 
            <Svg.folders /> 
            <p>{{ t('customization.actions.list') }}</p> 
          </div> 
        </div>
        <VueDraggableNext
          :list="ordered"
          class="actions"
          :ghost-class="_adaptive.isMobile ? '' : 'ghost'"
          :move="orderMiddleware"
          :disabled="_adaptive.isTouchDevice()"
          @change="editOrder"
        >
          <template v-for="(value) in ordered" :key="value.id">
            <CustomAction :command="value" :on-deleted="() => onDeleted(value)" />
          </template>
        </VueDraggableNext>
      </div>
    </div>
    <Divider class="my-8" />
    
    <div class="section">
      <div class="header">
        <p class="title">
          {{ t('customization.actions.variables.title') }}
        </p> 
        <p class="subtitle" v-html="t('customizations.actions.info')" />
        <div class="variables">
          <Button
            preset="primary"
            class="w-fit mt-2"
            :text="t('general.docs')"
            href="https://docs.rustapp.io/ru/start/actions/commands-basic.html"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>

.ghost {
  @apply opacity-0;
}

.section {
  @apply flex flex-col gap-5;

  .header {
    @apply flex flex-col gap-2;

    .title {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    .subtitle {
      @apply text-grey-400;
      @apply text-sm;
    }

    :deep(.bright) {
      @apply text-amber-500;
    }
  }

  .actions {
    @apply grid grid-cols-1 gap-3;

    .add-action {
      @apply flex items-center justify-center gap-1 h-[64px] w-full;
      @apply rounded-md cursor-pointer;
      @apply bg-grey-950 border-2 border-dashed border-grey-900;
      @apply font-medium;

      &.new {
        @apply text-primary-700;

        svg {
          @apply w-5 fill-primary-700;
        }
      }

      &.list {
        @apply text-grey-500;

        svg {
          @apply w-5 fill-grey-500;
        }
      }

      &:hover {
        @apply border-grey-850;

        &.new {
          @apply text-primary-600;

          svg {
            @apply fill-primary-600;
          }
        }

        &.list {
          @apply text-grey-400;

          svg {
            @apply fill-grey-400;
          }
        }
      }
    }

    .btn-group {
      @apply grid grid-cols-2 gap-3;
    }

    @media (max-width: 700px) {
      .btn-group {
        @apply grid-cols-1;
      }
    }
  }

  .variables {
    @apply flex flex-col gap-2;

    .variable {
      @apply flex gap-1 items-center text-sm;

      .main {
        @apply px-1 py-0.5 rounded;
        @apply bg-grey-850;
        @apply text-grey-300;
      }
      .text {
        @apply text-grey-500;
        @apply truncate;
      }
    }
  }

  .info {
    @apply pl-3;
    @apply text-grey-400 text-sm;
    @apply border-l-3 border-grey-600;
  }
}
</style>