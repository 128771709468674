import { CheckDto } from "@/api/backend/court/check/check.dto";
import { useLocaleStore } from "@/stores/locale.store";

export function useCheckUtils() {
  const { t } = useLocaleStore();

  const verdict = (check: CheckDto) => {
    if (check.verdict) {
      return check.verdict;
    }

    switch (check.status) { 
      case 'Canceled': {
        return t('check.verdict.status.canceled');
      }
      case 'CanceledTimeout': {
        return t('check.verdict.status.canceled-timeout');
      }
      case 'FinishedBan': {
        return t('check.verdict.status.finished-ban-placeholder');
      }
      case 'FinishedClear': {
        return t('check.verdict.status.finished-clear');
      }
      case 'Pending': {
        return t('check.verdict.status.pending');
      }
    }
  };

  return { verdict };
}