<script setup lang="ts">
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { PlayerKillFeedEntry } from '@/api/backend/court/player/player.dto';
  import { SignageDto } from '@/api/backend/court/signages/signages.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import Button from '@/components/Button.vue';
  import Loading from '@/components/Loading.vue';
  import TableBase, { TableHeaders } from '@/components/table/TableBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useTimeStore } from '@/stores/time.store';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed, onMounted, ref } from 'vue';
  import { ActivateCombatlogModal } from '@court/modals/combatlog/CombatlogModal.vue';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import { AxiosError } from 'axios';

  const { t } = useLocaleStore();

  const _time = useTimeStore();
  const props = defineProps<{ steamId: string }>();
  const data = ref<PlayerKillFeedEntry[]>();

  type KillFeedHeaders = 'date' | 'initiator' | 'target' | 'weapon' | 'actions';

  const headers = ref<TableHeaders<KillFeedHeaders>>({
    date: {
      text: t('general.date'),
      min : '80px',
      max : '80px'
    },
    initiator: {
      text: t('stats-header-initiator'),
      min : '120px',
      max : '120px'
    },
    target: {
      text: t('stats-header-target'),
      min : '120px',
      max : '120px'
    },
    weapon: {
      text: t('stats-weapon'),
      min : '120px',
      max : '120px'
    },
    actions: {
      text : 'Combatlog',
      max  : '100%',
      right: true
    }
  });
  
  const loader = computed(() => {
    return new PaginationService<PlayerKillFeedEntry>((page, limit) => useCourtApi().player.getKillFeed(props.steamId, page, Date.now() - 30 * 24 * 60 * 60 * 1_000, Date.now()));
  });

  onMounted(() => next());

  const next = async () => {
    try {
      const loaderHash = loader.value.id;
      
      const result = await loader.value.next();
      

      if (loader.value.id !== loaderHash) {
        return;
      }    
      
      if (!data.value) {
        data.value = [];
      }

      data.value.push(...result.results);
    }
    catch (err) {
      if ((err as AxiosError).message?.includes('доступна начиная с тарифа')){
        data.value = [
          {
            "id"         : 159648,
            "project_id" : 2571,
            "server_id"  : 3,
            "game_time"  : "22:34:00",
            "weapon"     : "assets/prefabs/weapons/ak47u/ak47u.entity.prefab",
            "distance"   : 22.9961357,
            "kill_bone"  : "head",
            "hit_history": [
              {
                "ad"               : false,
                "pi"               : 1,
                "pm"               : 0,
                "ammo"             : "riflebullet",
                "bone"             : "head",
                "info"             : "killed",
                "time"             : 1.01367188,
                "desync"           : 81,
                "hp_new"           : 0,
                "hp_old"           : 59.32,
                "target"           : "you",
                "weapon"           : "assets/prefabs/weapons/ak47u/ak47u.entity.prefab",
                "attacker"         : "player",
                "distance"         : 23,
                "proj_hits"        : 0,
                "proj_travel"      : 0.08312087,
                "target_steam_id"  : '76561100000000000',
                "attacker_steam_id": "76561100000000000"
              },
              {
                "ad"               : false,
                "pi"               : 1,
                "pm"               : 0,
                "ammo"             : "riflebullet",
                "bone"             : "head",
                "info"             : "killed",
                "time"             : 1.01367188,
                "desync"           : 81,
                "hp_new"           : 0,
                "hp_old"           : 59.32,
                "target"           : "you",
                "weapon"           : "assets/prefabs/weapons/ak47u/ak47u.entity.prefab",
                "attacker"         : "player",
                "distance"         : 23,
                "proj_hits"        : 0,
                "proj_travel"      : 0.08312087,
                "target_steam_id"  : '76561100000000000',
                "attacker_steam_id": "76561100000000000"
              },
              {
                "ad"               : false,
                "pi"               : 1,
                "pm"               : 0,
                "ammo"             : "riflebullet",
                "bone"             : "head",
                "info"             : "killed",
                "time"             : 1.01367188,
                "desync"           : 81,
                "hp_new"           : 0,
                "hp_old"           : 59.32,
                "target"           : "you",
                "weapon"           : "assets/prefabs/weapons/ak47u/ak47u.entity.prefab",
                "attacker"         : "player",
                "distance"         : 23,
                "proj_hits"        : 0,
                "proj_travel"      : 0.08312087,
                "target_steam_id"  : '76561100000000000',
                "attacker_steam_id": "76561100000000000"
              }
            ],
            "connected_steam_ids": [
              "76561100000000000"
            ],
            "created_at": 1726820906559,
            "initiator" : {
              "steam_id"    : "76561100000000000",
              "steam_name"  : "Алексей Алексеевич",
              "steam_avatar": "https://avatars.akamai.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg"
            },
            "target": {
              "steam_id"    : "76561100000000000",
              "steam_name"  : "дед бом-бом",
              "steam_avatar": "https://avatars.akamai.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg"
            }
          }
        ];
        return;
      }

      throw err;
    }
  };

  const getWeaponName = (item?: PlayerKillFeedEntry) => {
    if (!item) return '';
    return item.weapon.match(/\/([^\/]+)\.(entity|weapon)\.prefab$/)?.[1] || item.weapon;
  };

</script>

<template>
  <div class="content">
    <Loading :can-view="!!data" class="!bg-grey-850" />
    
    <CourtNoSubscriptionContent
      v-if="data"
      type="PlayerStatistics"
      color="bg-grey-900/80"
    >
      <TableBase
        :headers="headers"
        :items="data ?? null"
        :item-key="v => v.id.toString()"
        class="h-full kill-feed-table"
        :next="next"
        :missing-data-title="t('stats-killfeed-missing-title')"
        :missing-data-subtitle="t('stats-killfeed-missing-subtitle')"
        missing-data-type="empty"
      >
        <template #date="{ item }">
          <p class="main-text">{{ _time.format(item!.created_at, 'DD.MM.YY', true, true) }}</p>
          <p class="sub-text">{{ _time.format(item!.created_at, 'HH:mm:ss') }}</p>
        </template>
        <template #initiator="{ item }">
          <p 
            class="main-text bigger" 
            :class="{ 'diff-player': item?.initiator?.steam_id != steamId }"
            @click="item?.initiator?.steam_id != steamId ? ActivatePlayerDetailsModal(item!.initiator!.steam_id) : undefined"
          >
            {{ item?.initiator?.steam_name }}
          </p>
        </template>
        <template #target="{ item }">
          <p 
            class="main-text bigger" 
            :class="{ 'diff-player': item?.target?.steam_id != steamId }"
            @click="item?.target?.steam_id != steamId ? ActivatePlayerDetailsModal(item!.target!.steam_id) : undefined"
          >
            {{ item?.target?.steam_name }}
          </p>
        </template>
        <template #weapon="{ item }">
          <p class="main-text" :title="getWeaponName(item)">{{ getWeaponName(item) }}</p>
          <div class="sub-text">
            <span class="distance-text">{{ t('stats-killfeed-distance', {distance: item?.distance.toFixed(0)}) }}</span>
            <span v-if="item?.kill_bone == 'head'" class="text-grey-600">{{ ' |' }}</span>
            <span v-if="item?.kill_bone == 'head'" class="text-amber-400">{{ ' ' + t('stats-killfeed-hs') }}</span>
          </div>
        </template> 
        <template #actions="{ item }">
          <div class="flex justify-end">
            <Button
              v-if="item?.connected_steam_ids.length != 0"
              preset="default-light"
              class="!p-1 !rounded"
              :action="() => item ? ActivateCombatlogModal({ target_steam_id: item.target!.steam_id, connected_steam_ids: item?.connected_steam_ids, hit_history: item?.hit_history }) : undefined"
            >
              <Svg.book class="w-[17px]" />
            </Button>
          </div>
        </template>
      </TableBase>
    </CourtNoSubscriptionContent>
  </div>
</template>

<style lang="scss" scoped>
.content {
  @apply h-full overflow-y-hidden relative;
  @apply rounded-md;
  overscroll-behavior: none;
}

.main-text {
  @apply text-sm;
  @apply text-grey-50 truncate;

  &.diff-player {
    @apply cursor-pointer;
    @apply text-primary-700;

    &:hover {
      @apply text-primary-500;
    }
  }
}

.sub-text {
  @apply text-xs;
  @apply text-grey-400 truncate;
}

:deep(.kill-feed-table) {
  @apply bg-grey-850;

  .header {
    @apply bg-grey-850 rounded-t-md;
    @apply border-b border-grey-900;
    height: 38px !important;

    .element {
      @apply p-2;
      @apply text-sm;
    }
  }

  .row {
    @apply border-grey-900 #{!important};
    height: 52px !important;

    &.defaultHover {
      &:hover {
        @apply bg-transparent #{!important};
      }
    }
  }

  .element {
    @apply p-2;
  }

  &::-webkit-scrollbar-button:start {
    @apply bg-grey-850;
    height: 38px;
  }
}
</style>

