<script setup lang='ts'>
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import ModalMenuListItem from '@/components/modal-menu/components/ModalMenuListItem.vue';
  import ModalMenuSection from '@/components/modal-menu/components/ModalMenuSection.vue';
  import ModalMenuTab from '@/components/modal-menu/components/ModalMenuTab.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateConnectTwoFactorModal } from '@src/modals/2fa/ConnectTwoFactorModal.vue';
  import { ActivateEnterTwoFactorConfirm } from '@src/modals/2fa/EnterTwoFactorModal.vue';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { ActivateEmailConfirm } from '@src/modals/email-confirm/EmailConfirmModal.vue';

  const _app = useAppStore();
  const { t, lang } = useLocaleStore();

  defineProps<{
    mobile: boolean
  }>();

  const restorePassword = async () => {
    if (!_app.client?.email) {
      return;
    }

    await ActivateConfirmModal({
      type         : 'warn',
      title        : t('general.sure'),
      description  : t('send-restore-link'),
      beforeConfirm: async () => {
        if (!_app.client?.email) {
          return;
        }
    
        await useCoreApi().auth.classicRestoreStart(_app.client?.email, window.location.origin + `/client/auth`, lang);
      },
      onConfirmed: () => {
        useRuntimeNotification(`success`, t('check-your-email-tolink'));
      }
    });
  };

  const confirmEmail = async () => {
    await useCoreApi().auth.emailConfirmStart(lang);

    ActivateEmailConfirm();
  };

  const start2fa = async () => {
    const link = await useCoreApi().auth.start2fa();

    ActivateConnectTwoFactorModal(link);
  };

  const delete2fa = async () => {
    await ActivateEnterTwoFactorConfirm(async (code) => {
      if (!_app.client) {
        return;
      }
    
      if (!code) {
        return;
      }
      await useCoreApi().auth.enable2fa(code);
  
      await useCoreApi().auth.disable2fa(code);
    
      _app.client.twofactor_active = false;
    });
  };
</script>

<template>
  <ModalMenuTab
    :mobile="mobile"
  >
    <ModalMenuSection :title="t('modal.client.security.title')" :mobile="mobile">
      <template v-if="_app.client?.primary_auth === 'email'">
        <ModalMenuListItem
          :title="t('modal.client.security.email.title')"
          :message="_app.client?.email ?? ''"
        >
          <template v-if="!_app.client?.email_confirmed" #title-icon>
            <Svg.warning class="fill-ra-afk w-4" />
          </template>
          <template #right>
            <Button
              class="!text-14-500"
              preset="warning"
              loading-color="#fbbf24"
              :disabled="_app.client.email_confirmed"
              :action="confirmEmail"
            >
              {{ !_app.client.email_confirmed ? t('modal.client.security.email.confirm') : t('modal.client.security.email.confirmed') }}
            </Button>
          </template>
        </ModalMenuListItem>
        <ModalMenuListItem
          :title="t('modal.client.security.password.title')"
          :message="t('modal.client.security.password.subtitle')"
        >
          <template #right>
            <Button 
              class="!text-14-500"
              preset="default"
              :action="restorePassword"
            >
              {{ t('general.edit') }}
            </Button>
          </template>
        </ModalMenuListItem>
      </template>
      <ModalMenuListItem
        :title="t('modal.client.security.2fa.title')"
        :message="t('modal.client.security.2fa.subtitle')"
      >
        <template #right>
          <Button
            :preset="_app.client?.twofactor_active ? 'destructive' : 'default'" 
            class="!text-14-500"
            :action="_app.client?.twofactor_active ? delete2fa : start2fa"
          >
            {{ _app.client?.twofactor_active ? t('general.remove') : t('modal.client.security.2fa.set') }}
          </Button>
        </template>
      </ModalMenuListItem>
    </ModalMenuSection>
    <!--<ModalMenuSection :title="t('modal.client.security.sessions.title')" :mobile="mobile">
      <div class="sessions">
        <template v-for="session in sessions" :key="session.name + session.time">
          <div class="session">
            <p>
              <span class="block text-grey-200 text-16-500">{{ session.name }}</span>
              <span class="text-grey-500 text-14-400">{{ session.version }} • {{ _time.format(session.time, 'DD.MM.YYYY HH:mm', false, true) }}</span>
            </p>
            <Button
              preset="transparent"
              class="!p-0"
              :action="removeSession"
            >
              <Svg.close class="!w-6 h-6 shrink-0" />
            </Button>
          </div>
        </template>
      </div>
    </ModalMenuSection>-->
  </ModalMenuTab>
</template>

<style lang='scss' scoped>
.sessions {
  @apply flex flex-col;

  .session {
    @apply bg-grey-850;
    @apply p-2.5 pr-[15px];
    @apply flex items-center justify-between;
    @apply border-b border-grey-750;

    &:first-child {
      @apply rounded-t-md;
    }
    &:last-child {
      @apply rounded-b-md;
      @apply border-b-0;
    }
  }
}
</style>