import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
import { useLocaleStore } from "@/stores/locale.store";
import { Svg } from "@src/assets/auto_gen_types.dto";
import * as Sentry from "@sentry/vue";

export const useErrorHandler = (e: unknown) => {
  const { t } = useLocaleStore();

  const error = (e as unknown as PromiseRejectedResult).reason ?? e;
    
  // eslint-disable-next-line no-console
  console.log(`Unhandled exception`, error, e);

  if (error.message.includes('has failed the validation:')) {
    useRuntimeNotification('warning', t('general.exception.bad-form.text'));
    return;
  }
  if (['not.found', 'password.missmatch'].includes(error.message)) {
    useRuntimeNotification('warning', t('general.exception.bad-password.text'));
    return;
  }

  if (error.message.includes("Popup window closed")) {
    return;
  }

  if (error.message.includes('You are not allowed to perform this action') || error.message.includes('You are not part of a project')) {
    useRuntimeNotification('error', t('general.rights.text'));
    return;
  }

  if (error.message.includes('SteamID already connected')) {
    useRuntimeNotification(`warning`, t('steam-already-connected'));
    return;
  }

  if (error.message.includes('No code provided')) {
    useRuntimeNotification(`warning`, t('you-rejected-2fa-code'));
    return;
  }

  if (error.message.includes('Project limit exceeded')) {
    useRuntimeNotification(`warning`, t('project-limit-exceeded'));
    return;
  }

  if (error.message.includes('Subwindow closed')) {
    useRuntimeNotification('warning', t('subwindow-closed'));
    return;
  }

  if (error.message.includes("Player have active check")) {
    useRuntimeNotification('error', t('general.notify.already-called.subtitle'));
    return;
  }

  if (error.message.includes("Can't execute on offline player")) {
    useRuntimeNotification('warning', t('general.notify.command-on-offline'));
    return;
  }

  if (error.message.includes("Command is not active or deleted")) {
    useRuntimeNotification('warning', t('general.notify.command-not-active'));
    return;
  }

  if (error.message.includes("Client tag is busy")) {
    useRuntimeNotification('warning', t('general.notify.client-tag-active'));
    return;
  }

  if (error.message.includes("Project domain is busy")) {
    useRuntimeNotification('warning', t('general.notify.project-domain-is-busy'));
    return;
  }

  if (error.message.includes('Network Error')) {
    useRuntimeNotification('error', t('general.notify.network-error.title'), {
      overrideIcon: Svg.chart
    });
    return;
  }

  Sentry.captureException(e);

  useRuntimeNotification('error', error.message, {
    overrideTitle: t('general.unhandled-exception.title')
  });

  // eslint-disable-next-line no-console
  console.log(error);
};