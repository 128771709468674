<script setup lang="ts">
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import dayjs from 'dayjs';
  import Skeleton from '@/components/Skeleton.vue';
  import { computed } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  
  const { t } = useLocaleStore();

  const props = defineProps<{
    title: string;
    value?: number;
    diff?: number;
    prevInterval: [Date, Date];
  }>();

  const formattedDateRange = computed(() =>
    `${dayjs(props.prevInterval[0]).format('DD.MM.YYYY')} - ${dayjs(props.prevInterval[1]).format('DD.MM.YYYY')}`
  );
</script>

<template>
  <div v-if="value == null" class="card">
    <div
      class="absolute gradient skeleton"
    />
    <div class="card-title">
      <Skeleton style="height: 20px;" class="rounded !max-w-[80px]" />
      <Skeleton style="height: 20px;" class="rounded !max-w-[50px]" />
    </div>
    <Skeleton style="height: 40px;" class="rounded !max-w-[50px]" />
  </div>
  <div v-else class="card relative">
    <div
      v-if="diff"
      class="absolute gradient"
      :class="diff > 0 ? 'positive' : 'negative'"
    />
    <div class="card-title">
      <p>{{ title }}</p>
      <TooltipBase v-if="diff" trigger="hover">
        <p :class="diff > 0 ? 'positive' : 'negative'">
          {{ diff > 0 ? '+' : '' }}{{ diff }}%
        </p>
        <template #content>
          <div class="px-1.5 py-1 text-xs">
            <p>{{ t('moderator-stats-card-relative', { time: formattedDateRange }) }}</p>
          </div>
        </template>
      </TooltipBase>
    </div>
    <p class="card-value">{{ value }}</p>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  @apply grid grid-cols-3 gap-5 mt-2;

  .card {
    @apply min-h-[100px];
    @apply w-full bg-grey-900 rounded-md p-3 overflow-hidden relative;
    @apply flex flex-col justify-between;
    

    &.empty {
      @apply bg-grey-900 border border-grey-850 opacity-40;
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(45, 45, 45, 0.50) 20px, rgba(45, 45, 45, 0.50) 35px);
    }

    .gradient {
      top: -10px;
      right: 20px;
      transform: translate(50%, -50%);
      height: 150px;
      width: 150px;
      pointer-events: none;
      
      &.negative {
        background: #f03312;
        background: radial-gradient(circle, rgba(240,51,18,0.1518942577030813) 0%, rgba(240,51,18,0) 70%);
      }
      &.positive {
        background: #8BC94E;
        background: radial-gradient(circle, rgba(139, 201, 78,0.1418942577030813) 0%, rgba(240,51,18,0) 70%);
      }
      &.skeleton {
        background: #545454;
        background: radial-gradient(circle, rgba(90, 90, 90, 0.1418942577030813) 0%, rgba(240,51,18,0) 70%);
      }
    }

    .card-title {
      @apply flex justify-between w-full text-grey-500;

      p.positive {
        @apply text-ra-online;
      }

      p.negative {
        @apply text-red-500;
      }
    } 

    .card-value {
      @apply text-grey-50 text-[40px] font-bold;
    }
  }
}

@media (max-width: 700px) {
  .card-wrapper {
    @apply grid-cols-1;
  }
}
</style>