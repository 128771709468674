<script lang="ts">
  export type HotKeyOpts = {
    code: number; 
    text: string;
  };

</script>

<script setup lang="ts">
  import { useEventListener } from '@vueuse/core';
  import { ref } from 'vue';

  const props = defineProps<{ keys: HotKeyOpts[] }>();

  const active = ref(new Map<number, boolean>());

  useEventListener(window, 'keyup', (e) => {
    const exists = props.keys.find(v => v.code === e.keyCode);
    if (!exists) {
      return;
    }

    active.value.set(exists.code, false);
  });

  useEventListener(window, 'keydown', (e) => {
    const exists = props.keys.find(v => v.code === e.keyCode);
    if (!exists) {
      return;
    }

    active.value.set(exists.code, true);
  });
</script>

<template>
  <template v-for="(key, index) in keys" :key="key.code">
    <div :class="['hot-key', { pressed: !!active.get(key.code) }]">
      <p class="text">
        {{ key.text }}
      </p>
    </div>
    <p v-if="index !== keys.length - 1" class="plus">+</p>
  </template>
</template>

<style lang="scss" scoped>
.hot-key {
  @apply bg-grey-700;
  @apply rounded;
  @apply px-1.5;
  @apply font-medium;
  .text {
    @apply text-center;
    @apply text-xs text-grey-400;
    line-height: 20px;
  }

  &.pressed {
    @apply bg-grey-650;
    
    .text {
      @apply text-grey-350;
    }
  }
}

.plus {
  @apply text-grey-350;
  @apply text-xs;
  @apply font-medium;
}
</style>
