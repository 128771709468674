<script setup lang="ts">
  import TextInput from '@/components/inputs/TextInput.vue';
  import Divider from '@/components/Divider.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';
  import DOMPurify from 'dompurify';
  import { useCourtStore } from '@court/stores/court.store';
  import { computed } from 'vue';
  import { ref } from 'vue';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { onMounted } from 'vue';
  import { watch } from 'vue';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';

  type BanServerSuggest = {
    name: string;
    id: number;
  }

  const { t } = useLocaleStore();

  defineProps<{ command: CourtCommandDto }>();

  const _court = useCourtStore();

  const server_id = defineModel<number>('server_id', { required: true });
  const values = defineModel<Record<string, string>>('arguments', { required: true });


  const cleanKey = (key: string) => {
    if (!key.includes(':')) {
      return key;
    }

    return key.split(':')[0];
  };

  const prepareCommand = (cmd: string) => {
    if (!values.value) {
      return; 
    }

    let str = cmd.toString();

    Object.keys(values.value).forEach(rawKey => {
      const key = cleanKey(rawKey);

      if (values.value?.[rawKey]) {
        str = str.replace(`{${rawKey}}`, `${values.value?.[rawKey]}`).replace(`{${rawKey}}`, `${values.value?.[rawKey]}`).replace(`{${rawKey}}`, `${values.value?.[rawKey]}`).replace(`{${rawKey}}`, `${values.value?.[rawKey]}`).replace(`{${rawKey}}`, `${values.value?.[rawKey]}`);
      } else {
        str = str.replace(`{${rawKey}}`, `<span style="color: #f59e0b">{${key}}</span>`).replace(`{${rawKey}}`, `<span style="color: #f59e0b">{${key}}</span>`).replace(`{${rawKey}}`, `<span style="color: #f59e0b">{${key}}</span>`).replace(`{${rawKey}}`, `<span style="color: #f59e0b">{${key}}</span>`).replace(`{${key}}`, `<span style="color: #f59e0b">{${key}}</span>`);
      }
    });

    return DOMPurify.sanitize(str, { ALLOWED_TAGS: ['span']});
  };
  
  const availableServers = computed<Record<string, number>>(() => {
    const obj: Record<string, number> = {
    };

    if (!_court.court) {
      return obj;
    }

    _court.court.servers.filter(v => !v.deleted).forEach(server => {
      obj[server.name] = server.id;
    });
    
    return obj;
  });


  const serverSuggester = (value: string) => {
    const servers = Object.entries(availableServers.value).map<BanServerSuggest>(([name, id]) => {
      return {
        id,
        name,
      };
    });

    return servers.filter(v => v.name.toLowerCase().includes(value.toLowerCase()));
  };

  const server = ref<BanServerSuggest>(serverSuggester('')[0]);

  onMounted(() => server.value = serverSuggester('').find(v => v.id === server_id.value) ?? serverSuggester('')[0]);
  watch(() => server_id.value, () => server.value = serverSuggester('').find(v => v.id === server_id.value) ?? serverSuggester('')[0]);
  watch(() => server.value, (v) => server_id.value = v.id);

  const protected_keys = ['steam_id', 'steam_name', 'player_ip', 'staff_steam_id', 'staff_name', 'staff_tag'];

  const getKeySelect = (key: string) => {
    if (key === 'reasons_ban') {
      return (_court.court?.options.ban_reasons ?? []).map<SelectValue<string>>(v => ({
        text : v,
        value: v
      }));
    }

    if (key === 'reasons_verdict') {
      return (_court.court?.options.check_verdicts ?? []).map<SelectValue<string>>(v => ({
        text : v,
        value: v
      }));
    }

    if ([':', '/'].some(v => !key.includes(v))) {
      return;
    }

    const opts = key.split(':')[1].split(':')[0].split('/');
    if (opts.length <= 1) {
      return;
    }

    return opts.map<SelectValue<string>>((v) => {
      return {
        text : v,
        value: v
      };
    });
  };
</script>

<template>
  <div v-if="command.allow_server_choose" class="mb-2.5">
    <p class="label mb-1">{{ t('customization.actions.modal-create.suggesterinput.server.label') }}</p>
    <SuggesterInput
      v-model="server"
      :suggester="serverSuggester"
      :compiler="(value) => null"
      :placeholder="t('customization.actions.modal-create.suggesterinput.server.label')"
      class="server-suggester !p-1.5"
      :icon="Svg.select"
      insta-suggest
      disable-input
    >
      <template #default="{ value }">
        <p class="!py-1 !px-1.5 text-base leading-5 hover:line-through">{{ value?.name }}</p>
      </template>

      <template #suggest="{ value }">
        <div class="suggest">
          <p>{{ value?.name }}</p>
        </div>
      </template>
    </SuggesterInput>
  </div>

  <div v-if="Object.keys(values).filter(v => !protected_keys.includes(v)).length != 0">
    <div class="fields-list">
      <template v-for="(_, key) of values" :key="key">
        <div v-if="!protected_keys.includes(key)" class="field">
          <template v-if="!!getKeySelect(key)">
            <SelectInput
              v-model="values[key]" 
              :values="(getKeySelect(key) as SelectValue<string>[])"
              variant="modal"
              no-label-padding
              :label="`${t('execute-request-input-key-label')} {${cleanKey(key)}}`"
              :placeholder="t('general.placeholder-choose')"
            />
          </template>
          <template v-else>
            <TextInput
              v-model="values[key]"
              :label="`${t('execute-request-input-key-label')} {${key}}`"
              :disabled="protected_keys.includes(key)"
              :placeholder="t('execute-request-input-key-placeholder')"
            />
          </template>
        </div>
      </template>
    </div>

    <Divider class="py-5" />
  </div>

  <div class="commands-group">
    <p class="label">{{ command.commands.length > 1 ? t('execute-request-commands-label') : t('execute-request-command-label') }}</p>

    <div class="command-list">
      <template v-for="(cmd, index) in command.commands" :key="`${cmd}-${index}`">
        <div class="command-box">
          <div
            class="command"
            :title="`Command #${index + 1}`"
            v-html="prepareCommand(cmd)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fields-list {
  :deep(.options-list) {
    max-height: 150px !important;
  }
}

.execute-command-modal {
  width: 95vw;
  max-width: 420px;
}

:deep(.server-suggester) {
  .suggestions-wrapper {
    z-index: 9999 !important;
    max-height: 150px;
  }
  ::placeholder {
    @apply pl-0.5;
  }
}
:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}

.header {
  @apply flex items-center justify-between w-full;
  @apply text-grey-50 font-semibold;
  .title {
    @apply text-white;
    @apply font-semibold;
    @apply block;
  }
  .subtitle {
    @apply text-grey-400;
    @apply font-normal;
    @apply text-sm;
    @apply block;
  }
}

.fields-list {
  @apply flex flex-wrap gap-2.5;

  .field {
    @apply flex-grow justify-between;
    @apply w-[45%];
  }
}
.commands-group {
  @apply flex flex-col gap-1;

  .command-list {
    @apply flex flex-col gap-2.5;

    .command-box {
      @apply flex items-stretch;
      @apply h-full;
      @apply w-full;

      .command {
        @apply grow w-full;
        @apply p-2.5;
        @apply rounded-md;
        @apply text-grey-50 text-14-400;
        @apply bg-grey-850 border border-grey-850;
        @apply break-words;

        background-image: linear-gradient(45deg, #2D2D2D 25%, transparent 0, transparent 50%, #2D2D2D 0, #2D2D2D 75%, transparent 0, transparent);
        background-size: 1rem 1rem;
        animation: progress-bar-stripes 1s linear infinite;
      }
    }
  }
}

.label {
  @apply inline-flex justify-between w-full;
  @apply text-grey-400;
}

@keyframes progress-bar-stripes {
  0% {
      background-position: 1rem 0
  }

  to {
      background-position: 0 0
  }
}
</style>