<script setup lang="ts">
  import { computed } from 'vue';
  import Highlighter from 'vue-highlight-words';

  const props = withDefaults(defineProps<{ visibleText: string, search?: string[] }>(), {
    enabled: true,
    search : undefined
  });

  const computedSearch = computed(() => {    
    if (props.search) {
      return props.search;
    }

    return [''];
  });
</script>

<template>
  <Highlighter
    v-if="visibleText?.length >= 1"
    v-slot="items"
    :search-words="computedSearch"
    :text-to-highlight="visibleText"
    auto-escape
  >
    <template v-for="{ chunk, text } in items" :key="text">
      <span :class="['chunk', { light: chunk.highlight }]">{{ text }}</span>
    </template>
  </Highlighter>
</template>

<style lang="scss" scoped>
span {
  &.light {
    @apply text-primary-500 underline underline-offset-2;
  }
}
</style>
