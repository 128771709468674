<script setup lang='ts'>
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useChatStore } from './stores/chat.store';
  import { storeToRefs } from 'pinia';
  import { useSoundStore } from '@/stores/sound.store';
  import SwitchDropdown from '@/components/switch/SwitchDropdown.vue';
  import ChatExtraWords from './components/ChatExtraWords.vue';
  import { EmojiView } from './types/chat.dto';
  import { computed } from 'vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import BaseTip from '@panel/components/BaseTip.vue';

  const { t } = useLocaleStore();
  
  const _permissions = usePermissionsStore();
  const _sound = useSoundStore();
  const _chat = useChatStore();

  const { types, design, censure } = storeToRefs(_chat);
  
  const emojiDropdown = computed<ExtendedMenuItem[]>(() => {
    return Object.entries(_chat.emojiViews).map<ExtendedMenuItem>(([key, value]) => {
      return {
        label : value,
        active: () => _chat.design.emojiView === key,
        action: () => {
          _chat.design.emojiView = key as EmojiView;

          return false;
        }
      };
    });
  });
</script>

<template>
  <div class="container">
    <SettingsContainer :title="t('chat.filters.messages.title')">
      <SwitchText 
        v-model="types.global" 
        :header="t('chat.filters.messages.options.global-messages')" 
      />

      <template v-if="_permissions.can(['Court_ChatReadDirect'])">
        <SwitchText 
          v-model="types.direct" 
          :header="t('chat.filters.messages.options.direct-messages')" 
        />
        <SwitchText 
          v-model="types.team" 
          :header="t('chat.filters.messages.options.team-messages')" 
        />
      </template>
    </SettingsContainer>

    <BaseTip type="info" disposable="chat-pm">
      {{ t('basetip-chatpm-0') }}
      <a
        class="inline hyperlink underline"
        href="https://docs.rustapp.io/ru/dev/chat.html"
        target="_blank"
      >
        {{ 'API' }}
      </a>
      {{ t('basetip-chatpm-1') }}
    </BaseTip>

    <SettingsContainer :title="t('chat.filters.censor.title')">
      <SwitchText 
        v-model="censure.highlight" 
        :header="t('chat.filters.censor.options.curse-words')" 
      />
      <ChatExtraWords />
      <SwitchDropdown
        v-model="censure.soundType"
        :header="t('chat.filters.censor.options.play-sound')"
        :choosed="_sound.soundsAvailable[censure.soundType].text"
        :opts="_sound.soundsDropdown((v) => censure.soundType == v, (v) => censure.soundType = v)"
      />
    </SettingsContainer>

    
    <SettingsContainer :title="t('chat.filters.effects.title')">
      <SwitchText 
        v-model="design.avatars" 
        :header="t('chat.filters.effects.options.show-avatar')" 
      />
      <SwitchText 
        v-model="design.invertColors" 
        :header="t('chat.filters.effects.options.highlight-messages')" 
      />
      <SwitchText 
        v-model="design.time" 
        :header="t('chat.filters.effects.options.show-time')" 
      />
      <SwitchText 
        v-model="design.server" 
        :header="t('chat.filters.effects.options.show-server')" 
      />
      <SwitchDropdown
        v-model="design.emojiView"
        :header="t('chat.filters.censor.options.emoji')"
        :choosed="_chat.emojiViews[design.emojiView]"
        :opts="emojiDropdown"
      />
    </SettingsContainer>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply flex flex-col gap-5;
  @apply bg-grey-900 rounded-md p-5; 
  @apply h-full; 
  @apply overflow-y-auto;

  :deep() {
    .settings-container {
      @apply pb-0;
    }
  }
}

.item {
  @apply flex;
  @apply gap-1;
  @apply bg-white/5;
  @apply rounded-md;
  @apply items-center;
  @apply p-1;
  @apply cursor-pointer;
  @apply text-sm;
  @apply text-grey-400;
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
</style>