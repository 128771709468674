<script setup lang="ts">
  import Skeleton from '@/components/Skeleton.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const _config = useConfigStore();

  const emits = defineEmits(['update:modelValue', 'error']);
  
  defineProps<{
    url?: string;
    active: boolean;

    basicSquare?: boolean;
    enableShadow?: boolean;
    enableHoverEffects?: boolean;
  }>();
</script>

<template>
  <div class="aspect-square wrapper" :class="{ hoverable: enableHoverEffects, active, square: basicSquare }">
    <div
      v-if="enableShadow && active"
      class="shadow"
      :style="`background-image: url('${url}');`"
    />
    <div class="avatar-box">
      <template v-if="url">
        <div v-if="url.includes('.png')" class="h-full w-full bg-grey-850 absolute inset-0 -z-10" />
        <img
          class="w-full h-full"
          style="height: 100%; object-fit: cover"
          :src="url"
          :onerror="`this.onerror=null; this.src='${_config.Avatars.PlayerNotExistsPlaceholder}';`"
          @error="emits('error')"
        >
      </template>
      <template v-else>
        <Skeleton class="w-full h-full" />
      </template>
      
      <div v-if="enableShadow" class="border" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  @apply h-full w-full relative;
  filter: grayscale(0%);
  
  @apply transition-all ease-in-out delay-0 duration-300;

  .shadow {
    @apply transition-all ease-in-out delay-0 duration-300;
    @apply absolute;
    @apply inset-1;
    @apply opacity-50;
    border-radius: 50%;
    filter: blur(12px);
    background-size: cover;
  }

  .border {
    @apply transition-all ease-in-out delay-0 duration-300;
    @apply absolute inset-0;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  &.active, &.hoverable:hover {
    filter: grayscale(0%);

    &.hoverable:hover, &.square {
      .shadow {
        @apply rounded-2xl;
      }

      .avatar-box {
        .border {
          @apply rounded-2xl;
        }

        img {
          @apply rounded-2xl;
        }

        @apply rounded-2xl;
      }
    }
  }

  .avatar-box {
    @apply transition-all ease-in-out delay-0 duration-300;
    @apply h-full w-full;
    @apply flex items-center justify-center;
    border-radius: 50%;
    @apply relative;
    @apply overflow-hidden;
  }
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
