<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { ChatNavHeader, ChatSearchPlayer, ChatSearchText } from './types/chat.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { CentrifugoChatMessage, CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { ChatMessageDto } from '@/api/backend/court/chat/chat.dto';
  import { useCourtStore } from '@court/stores/court.store';
  import { vIntersectionObserver } from '@vueuse/components';
  import { useChatStore } from './stores/chat.store';
  import { useSoundStore } from '@/stores/sound.store';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useAppStore } from '@/stores/app.store';
  import { onKeyDown, useEventListener } from '@vueuse/core';
  import { CourtRoutes } from '@court/court.routes'; 
  import { useRouter } from 'vue-router';

  import Loading from '@/components/Loading.vue';
  import NavBase from './components/nav/NavBase.vue';
  import ChatMessage from './components/ChatMessage.vue';
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import ChatPageSidebar from './ChatPageSidebar.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ChatSearch from './components/ChatSearch.vue';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import Button from '@/components/Button.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { useDataStore } from '@/stores/data.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import { useConfigStore } from '@/stores/config/config.store';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const { t } = useLocaleStore();

  const _app = useAppStore();
  const _panel = usePanelStore();
  const _court = useCourtStore();
  const _sound = useSoundStore();
  const _permissions = usePermissionsStore();
  const _chat = useChatStore();
  const _router = useRouter(); 
  const _adaptive = useAdaptiveStore();
    
  const getNavHeaders = computed((): ChatNavHeader[] => {
    const obj: ChatNavHeader[] = [];

    if (_court.court) {
      if (_court.court.servers.filter(v => !v.deleted).length > 1) {
        obj.push({
          name: t('chat.nav.all'),
          meta: undefined
        });
      }

      const servers = _court.court.servers.filter(v => !v.deleted).sort((a,b) => (a.order ?? 0) - (b.order ?? 0)).map<ChatNavHeader>(v => ({ name: v.name, meta: { serverId: v.id, serverName: v.name } }));

      obj.push(...servers);
    }
    
    return obj;
  });

  
  const activeTypes = computed(() => {
    return Object
      .entries(_chat.types)
      .filter(([_, value]) => !!value)
      .map(([key]) => key);
  });

  const canSeeMessage = (msg: CentrifugoChatMessage, serverId?: number) => {
    // Пользователь может выбрать определенный сервер для просмотра
    if (serverId && msg.server_id != serverId) {
      return false;
    }

    // Сообщенрие может быть скрыто типами (GLOBAL/DIRECT/TEAM)
    if (!activeTypes.value.includes(msg.type.toLowerCase())) {
      return false;
    }

    if (!_permissions.can(['Court_ChatReadDirect'])) {
      if (msg.type === 'Team') {
        return false;
      }

      if (msg.type === 'Direct' && msg.steam_id.length === 17) {
        return false;
      }
    }

    if (_chat.searchActive) {
      const [text] = _chat.search.filter(v => 'text' in v) as ChatSearchText[];
      if (text && !msg.text.toLowerCase().includes(text.text?.trim().toLowerCase() ?? '')) {
        return false;
      }
      
      const [original, target] = _chat.search.filter(v => 'steam_id' in v) as ChatSearchPlayer[];

      if (original) {
        // Если нет цели, то все входящие / исходящие
        if (!target) {
          return msg.steam_id === original.steam_id || msg.target_steam_id === original.steam_id;
        }

        if (msg.steam_id === original.steam_id && msg.target_steam_id === target.steam_id) {
          return true;
        }

        if (msg.steam_id === target.steam_id && msg.target_steam_id === original.steam_id) {
          return true;
        }

        return false;
      }
    }

    return true;
  };

  const _contextMenu = useContextMenu();
  const selected = ref<ChatNavHeader>(getNavHeaders.value[0]);

  const chatInput = ref<InstanceType<typeof TextInput>>(); 

  watch(
    () => _chat.reply?.steam_id, 
    () => {
      chatInput.value?.select();
    }
  );

  onKeyDown('Escape', () => _chat.reply = undefined);

  const scrollToBottom = () => {
    navbase.value?.scrollable.scrollTo({ top: -10, behavior: 'smooth' });
  };

  const inBottom = ref<boolean>(true);

  const navbase = ref<{ scrollable: HTMLInputElement; scrollableHeight: { value: number }; }>();
  const isReady = ref<boolean>(false);

  onMounted(async () => {
    await new Promise<void>(async (resolve) => {
      setTimeout(() => resolve(), 2_000);
      
      await setupCentrifugo();

      resolve();
    });

    isReady.value = true;

    if (!navbase.value?.scrollable) {
      return;
    }
  
    const observer = new MutationObserver((mutations) => {
      if (!navbase.value?.scrollable) {
        return;
      }

      if (navbase.value?.scrollable.scrollTop < -200) {
        return;
      }

      if (_contextMenu.menu?.visible) {
        return;
      }

      navbase.value?.scrollable.scrollTo({ top: -10, behavior: 'smooth' });
    });

    observer.observe(navbase.value?.scrollable, {
      childList: true
    });
  });

  const centrifugoOffset = ref<number>();
  const centrifugoMessages = ref<CentrifugoChatMessage[] | null>();
  const centrifugoSubscribe = ref<CentrifugoSubscribe>();

  const setupCentrifugo = async () => {
    if (!_panel.project?.id) {
      return;
    }

    centrifugoSubscribe.value = await useCentrifugoApi().subscribeChatMessages(_panel.project?.id, (ctx) => {
      if (!centrifugoMessages.value) {
        return;
      }
      
      addMessage(ctx);
    });

    const result = await centrifugoSubscribe.value.sub.history({ limit: 50, reverse: true });
  
    centrifugoMessages.value = result.publications.map(v => v.data as CentrifugoChatMessage);

    const lastIndex = Math.max(centrifugoMessages.value.length - 1, 0);

    centrifugoOffset.value = centrifugoMessages.value[lastIndex]?.created_at ?? 0;
  };

  const backendMessages = ref<CentrifugoChatMessage[] | null>();
  const backendLoader = computed(() => {
    selected.value?.meta?.serverId;
    centrifugoOffset;

    const players = (_chat.search.filter(v => 'steam_id' in v) as ChatSearchPlayer[]).map(v => v.steam_id);
    const text = (_chat.search.find(v => 'text' in v) as ChatSearchText | undefined)?.text;

    const types = activeTypes.value.map(v => v[0].toUpperCase() + v.substring(1)) as ("Global" | "Team" | "Direct")[];

    return new PaginationService<ChatMessageDto>((page, limit) => useCourtApi().chat.browse({
      steam_ids: players,
      time_to  : centrifugoOffset.value,
      search   : text,
      server_id: selected.value?.meta?.serverId,
      types    : types,

      page,
      limit
    }));
  });

  watch(() => _chat.search, (v) => {
    const [first, second] = v.filter(v => 'steam_id' in v);

    // если нет никого, или есть оба
    if (!first || second) {
      _chat.reply = undefined;
      return;
    } 

    if (!('steam_id' in first)) {
      return;
    }

    _chat.reply = {
      steam_avatar: useConfigStore().Avatars.PlayerNotExistsPlaceholder,
      ...first,
      server_id   : _chat.reply?.server_id ?? selected.value.meta?.serverId ?? 0
    };
  }, { deep: true, immediate: true });

  watch(() => backendLoader.value, () => {
    backendMessages.value = null;
  });

  const backendLoad = async () => {
    // return;
    const response = await backendLoader.value.next();

    if (!backendMessages.value) {
      backendMessages.value = [];
    }
    
    backendMessages.value.push(...response.results);

  };

  const combinedMessages = computed<CentrifugoChatMessage[] | null>(() => {
    if (!backendMessages.value && !centrifugoMessages.value) {
      return null;
    }

    const messages = [ ...(centrifugoMessages.value ?? []), ...(backendMessages.value ?? [])];

    return messages;
  });

  const addMessage = (msg: CentrifugoChatMessage) => {
    const same = centrifugoMessages.value?.filter(v => v.id).find(v => v.text === msg.text && v.server_id === msg.server_id && v._internal_sending);
    if (same) {
      same._internal_sending = false;
      return;
    }

    centrifugoMessages.value?.unshift(msg);

    if (_chat.combinedCensureRegex.find(v => msg.text.match(v) || msg.text.toLowerCase().includes(v.toLowerCase()))) {
      if (msg.type === 'Global' || _permissions.can(['Court_ChatReadDirect'])) {
        if (_chat.types.direct && msg.type === 'Direct') {
          _sound.play(_chat.censure.soundType, 0.2);
        }
        if (_chat.types.global && msg.type === 'Global') {
          _sound.play(_chat.censure.soundType, 0.2);
        }
        if (_chat.types.team && msg.type === 'Team') {
          _sound.play(_chat.censure.soundType, 0.2);
        }
      }
      
    }
  };

  onUnmounted(() => {
    _chat.search = [];
    _chat.reply = undefined;
  
    centrifugoSubscribe.value?.unsubscribe();
  });
  
  const message = ref<string>('');

  const sendMessage = async () => {
    if (!_app.client?.tag || !_app.client.avatar || !_court.court || message.value.length == 0) {
      return;
    }

    let serverPending = _court.court?.servers.filter(v => !v.deleted).map(v => v.id);
    
    if (_chat.reply?.server_id) {
      serverPending = [_chat.reply.server_id];
    } else if (selected.value?.meta?.serverId) {
      serverPending = [selected.value?.meta?.serverId];
    }

    const initiatorName = `${_app.client.name}`;

    for (const serverId of serverPending) {
      const id = Math.random().toString();

      const obj: CentrifugoChatMessage = {
        id,

        steam_id    : _app.client.id.toString(),
        steam_avatar: _app.client.avatar,
        steam_name  : initiatorName,

        target_steam_id    : _chat.reply?.steam_id,
        target_steam_name  : _chat.reply?.steam_name,
        target_steam_avatar: _chat.reply?.steam_avatar,
        
        type: _chat.reply ? 'Direct' : 'Global',

        text: message.value,

        server_id : serverId,
        created_at: Date.now(),

        _internal_sending: true,
      };

      centrifugoMessages.value?.unshift(obj);

      useCourtApi().chat.send({
        initiator_name : initiatorName,
        target_steam_id: _chat.reply?.steam_id,
        message        : message.value,
        mode           : 'chat',
        server_id      : serverId
      }).then(v => {
        const elem = centrifugoMessages.value?.find(v => v.id === id);
        if (!elem) {
          return;
        }

        if (!v.includes(serverId)) {
          elem._internal_sending = false;
          elem._internal_sending_error = true;
        }
      }).catch(err => {
        const elem = centrifugoMessages.value?.find(v => v.id === id);
        if (!elem) {
          return;
        }

        elem._internal_sending = false;
        elem._internal_sending_error = true;
      });
    }

    if (messageHistory.value[0] != message.value) {
      messageHistory.value.unshift(message.value);
      messageHistory.value.length = Math.min(messageHistory.value.length, 25);
    }

    message.value = '';
    currentIndex.value = -1;
  };

  const messageHistory = useDataStore().sync(`last-messages`, ref<string[]>([]));

  const currentIndex = ref<number>(-1);
  useEventListener(window, 'keydown', (e) => {
    if (e.key != 'ArrowUp' && e.key != 'ArrowDown') {
      return;
    }
    
    currentIndex.value += e.key == 'ArrowUp' ? 1 : -1;

    currentIndex.value = Math.max(currentIndex.value, 0);
    currentIndex.value = Math.min(currentIndex.value, messageHistory.value.length - 1);

    const nextMessage = messageHistory.value[currentIndex.value];
    if (!nextMessage) {
      return;
    }

    message.value = nextMessage;
  }, { capture: true });
</script>

<template>
  <LayoutPage
    class="chat"
    :header="t('court.navigation.main.chats')"
    auto-open
  >
    <div class="chat-container">
      <Loading :can-view="isReady" />
      <Transition name="slide">
        <Button
          v-show="!inBottom && getNavHeaders.length !== 0"
          preset="default-light"
          class="button-to-bottom !rounded-half backdrop-blur-lg z-50"
          style="-webkit-backdrop-filter: blur(16px);"
          :action="() => scrollToBottom()"
        >
          <Svg.arrow_chavron class="rotate-180 scale-125" />
        </Button>
      </Transition>

      <template v-if="getNavHeaders.length === 0">
        <div class="min-h-full flex flex-col gap-5 justify-center items-center">
          <EmptyImage
            type="settings"
            :title="t('chat.bad-servers')"
            :subtitle="t('chat.bad-servers-subtitle')"
          />
          <Button
            preset="primary"
            :action="() => _router.push({ name: CourtRoutes.Servers })"
            class="text-sm !py-1.5 !px-2"
            :text="t('general.connect')"
          />
        </div>
      </template>
      <template v-else>
        <NavBase
          ref="navbase"
          class="nav-base-chat"
          disable-padding-top
          :headers="getNavHeaders"
          @select="(s) => selected = s"
        >
          <template v-if="!activeTypes.length">
            <div class="min-h-full flex justify-center items-center">
              <EmptyImage
                type="empty"
                :title="t('chat.bad-types')"
                :subtitle="t('chat.bad-types-subtitle')"
              />
            </div>
          </template>
          <template v-else>
            <div
              id="scroll-anchor"
              v-intersection-observer="([{ isIntersecting }]) => inBottom = isIntersecting"
              class="-translate-y-40 w-full"
            />
            <template v-for="msg in combinedMessages" :key="`${msg.created_at}-${msg.steam_id}`">
              <div
                v-show="canSeeMessage(msg, selected?.meta?.serverId)" 
                class="flex pl-5"
              >
                <ChatMessage
                  :msg="msg" 
                  :server="selected?.meta?.serverId ? undefined : _court.serverNameById(msg.server_id)"
                  :class="[msg._internal_sending ? 'opacity-50' : msg._internal_sending_error ? 'opacity-50' : 'opacity-100']"
                />
            
                <template v-if="msg._internal_sending">
                  <span>
                    <Svg.loading class="loading-icon w-4" />
                  </span>
                </template>

                <template v-if="msg._internal_sending_error">
                  <Svg.warning class="inline w-4 fill-red-500 ml-1" />
                </template>
              </div>
            </template>

            <div
              v-if="isReady && (!backendLoader.done || (_chat.search.length && !backendMessages?.length))"
              class="flex justify-center items-center relative shrink-0" 
              :style="`height: ${backendMessages?.length ? 20 : 100}%`"
            >
              <template v-if="!backendLoader.done">
                <IntersectionObserver
                  :key="`${(selected?.meta?.serverId ?? 'global')}-${backendMessages?.length}`"
                  style="bottom: 100px; transform: none;"
                  class="pointer-events-none"
                  :next="backendLoad"
                />
                <Loading :can-view="false" />
              </template>
              <template v-else-if="_chat.search.length && (!backendMessages?.length || backendLoader.done)">
                <EmptyImage
                  type="empty"
                  :title="t('chat.finished-last')" 
                  :subtitle="t('chat.finished-last-subtitle')"
                />
              </template>
            </div>
          </template>
        </NavBase>
      </template>
    </div>


    <div v-if="_permissions.can(['Court_ChatWriteReply']) && _court.court?.servers.filter(v => !v.deleted).length" class="input">
      <template v-if="_chat.reply">
        <div class="flex items-center gap-1">
          <Svg.arrow_corner_down_right_2 class="fill-grey-600 w-5 flex-shrink-0" />
          <p class="text-grey-400 truncate">
            {{ t('chat.reply') }}
            <a class="text-grey-50">{{ _chat.reply.steam_name }}</a>
          </p>

          <a class="cancel" @click="_chat.reply = undefined">{{ !_adaptive.isMobile ? t('chat-page-reply-cancel') : t('general.cancel') }}</a>
        </div>
      </template>
      
      <TextInput
        ref="chatInput"
        v-model="message"
        theme="middle"
        :class="!_permissions.can(['Court_ChatWriteGlobal']) ? 'opacity-75' : 'opacity-100'"
        :disabled="!_permissions.can(['Court_ChatWriteGlobal']) && !_chat.reply || _chat.search.filter(v => 'steam_id' in v).length > 1"
        :placeholder="!_permissions.can(['Court_ChatWriteGlobal']) && !_chat.reply ? t('chat.send.placeholder-only-reply') : t('chat.send.placeholder')"

        height="44"
        
        @on-enter="sendMessage"
      >
        <div v-if="!(!_permissions.can(['Court_ChatWriteGlobal']) && !_chat.reply)" class="pl-0.5 pr-2.5">
          <Svg.chat class="fill-white/25 w-5" />
        </div>
      </TextInput>
    </div>
    
      
    <template #side-bar>
      <ChatPageSidebar />
    </template>

    <template #search>
      <ChatSearch />
    </template>
  </LayoutPage>
</template>

<style lang="scss" scoped>

.chat {
  .chat-container {
    @apply h-full relative;
  }

  :deep() {
    .side-bar {
      @apply p-0;
    }
  }
}

:deep(.nav-base-chat) {
  ::-webkit-scrollbar-button:start {
    @apply bg-grey-1000;
    height: 20px;
  }
}

.button-to-bottom {
  @apply absolute bottom-5 right-5;
  @apply w-10 h-10;
}

.chat-suspect {
  @apply bg-white/10;
  @apply hover:line-through;
  @apply w-fit;
  @apply mb-4 mt-10 px-2 py-1;
  @apply rounded-md;
  @apply flex items-center;
  @apply cursor-pointer;
}

.chat-messages {
  @apply h-full flex flex-col-reverse overflow-auto gap-3 pb-2;
  flex-grow: 1;
}

.input {
  @apply flex flex-col gap-2;
  @apply px-5 py-4;
  @apply border-t border-grey-900;
}

.slide-enter-active {
  transition: all 0.2s linear;
}

.slide-leave-active {
  transition: all 0.2s linear;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.cancel {
  @apply ml-auto;
  @apply text-grey-400;
  @apply cursor-pointer;

  &:hover {
    @apply text-grey-200;
  }
}

.loading-icon {
  @apply inline w-4 h-4 ml-1 animate-spin fill-white;
}
</style>
