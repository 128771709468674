<script setup lang='ts'>
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ModalMenuListItem from '@/components/modal-menu/components/ModalMenuListItem.vue';
  import ModalMenuSection from '@/components/modal-menu/components/ModalMenuSection.vue';
  import ModalMenuTab from '@/components/modal-menu/components/ModalMenuTab.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { useSingleFileDialogV2 } from '@/components/quality/uploader/utils/uploader.composable';
  import { useAppStore } from '@/stores/app.store';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { AllowedLanguage, useLocaleStore } from '@/stores/locale.store';
  import { ClientInfoUpdate } from '@/api/backend/core/client/client.dto';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { ActivateAvatarPickerModal } from '@src/modals/avatar-picker/AvatarPickerModal.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { watch } from 'vue';
  import { ref, onMounted, computed } from 'vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const _app = useAppStore();
  const _validators = useValidators(); 
  const { t } = useLocaleStore(); 
  const _locale = useLocaleStore();
  
  defineProps<{
    mobile: boolean;
  }>();
  
  const nameInput = ref<InstanceType<typeof TextInput>>(); 
  const tagInput = ref<InstanceType<typeof TextInput>>();

  const info = ref<ClientInfoUpdate>();
  
  onMounted(() => setupDefaultInfo());
  watch(() => _app.client, (v) => setupDefaultInfo(v), { deep: true });

  const setupDefaultInfo = (data = _app.client) => {
    if (!_app.client) {
      return;
    }

    info.value = {
      name  : _app.client.name,
      tag   : _app.client.tag,
      bio   : _app.client.bio,
      avatar: _app.client.avatar
    };
  };

  const { open, preview, isLoading } = useSingleFileDialogV2(
    'avatar-user', 
    { accept: 'image/*' }, 
    () => {
      if (!info.value) {
        return undefined;
      }

      return useConfigStore().Urls.Images(info.value.avatar);
    }, 
    (s3_id) => {
      if (!info.value) {
        return;
      }

      info.value.avatar = s3_id;
    }
  );
   
  const contextItems: ExtendedMenuItem[] = [{ 
    label : t('modal.client.avatar.from-file'), 
    action: () => open() 
  }, { 
    label : t('modal.client.avatar.from-lib'), 
    action: () => ActivateAvatarPickerModal({ 
      images  : useConfigStore().Avatars.LibraryStaff, 
      current : () => info.value?.avatar || '',
      callback: (image) => { 
        if (!info.value) {
          return;
        }

        info.value.avatar = image; 
      }}
    ), 
  }]; 
  
  const complete = async () => { 
    if (!info.value) { 
      return; 
    } 
 
    try { 
      await useCoreApi().client.updateClient(info.value); 
    } 
    catch (err) { 
      if ((err as any)?.message == 'Tag seems to be busy' || (err as any)?.message?.includes('property tag has failed the following constraints: isNotIn')) { 
        useRuntimeNotification('warning', t('auth.error.variant.tag-busy'));
        return; 
      } 
       
      throw err; 
    } 
 
    await _app.warmup(undefined, true);
  }; 
  
  const infoChanged = computed(() => {
    if (!info.value) {
      return false;
    }

    const { avatar, name, tag, bio } = info.value;
    if (_app.client?.avatar === avatar && _app.client.name === name && _app.client.tag === tag && _app.client.bio === bio) { 
      return false; 
    } 
 
    return true; 
  });

  const allCorrect = computed(() => {
    if (!info.value) {
      return false;
    }

    if (nameInput?.value?.activeException) { 
      return false; 
    } 
 
    if (tagInput?.value?.activeException) { 
      return false; 
    } 
 
    if (!info.value.avatar.length) { 
      return false; 
    }

    return true;
  });

  const showContext = (e: MouseEvent) => useContextMenu().open(e, contextItems);

  const languages = computed<SelectValue<AllowedLanguage>[]>(() => {
    return [
      {
        value: 'ru',
        text : t('account-menu.language.ru')
      },
      {
        value: 'en',
        text : t('account-menu.language.en')
      },
      {
        value: 'uk',
        text : t('account-menu.language.uk')
      }
    ];
  });

  const currentLang = ref<AllowedLanguage>(_locale.lang);

  watch(() => currentLang.value, (newV) => {
    _locale.set(newV);
  });
  
</script>

<template>
  <ModalMenuTab
    :mobile="mobile"
    :on-cancel="infoChanged ? setupDefaultInfo : undefined"
    :on-confirm="(allCorrect && infoChanged) ? complete : undefined"
  >
    <template v-if="info">
      <ModalMenuSection :title="t('modal.client.profile.general')" :mobile="mobile">
        <ListItem
          v-show="!mobile"
          :image-url="preview"
          :skeleton="'avatar'"
          class="avatar-changer"
          :class="{ 'loading disabled': isLoading }"
          @click="showContext"
        >
          <template #title>
            <p class="title">{{ t('modal.client.profile.avatar.title') }}</p>
          </template>
          <template #message>
            <span
              class="edit"
            >
              {{ t('general.edit') }}
            </span>
          </template>
        </ListItem>
        
        <TextInput
          ref="nameInput"
          v-model="info.name" 
          :label="t('modal.client.name.label')" 
          :placeholder="_app.client?.name" 
          :validator="_validators.DisplayName"
          no-border 
          height="38"
        /> 
        
        <TextInput 
          ref="tagInput" 
          v-model="info.tag" 
          :label="t('modal.client.tag.label')" 
          :placeholder="_app.client?.tag" 
          :validator="_validators.Tag" 
          no-border 
          height="38" 
          no-padding 
        > 
          <div> 
            <span class="font-normal">{{ '@' }}</span> 
          </div> 
        </TextInput>
          
        <TextInput
          ref="bioInput" 
          v-model="info.bio"
          :label="t('modal.client.bio.label')" 
          :placeholder="t('modal.client.bio.placeholder')" 
          :validator="_validators.Bio"
          no-border
          textarea
          height="82"
        />
      </ModalMenuSection>
    </template>
  </ModalMenuTab>
</template>

<style lang='scss' scoped>
.avatar-changer {
  @apply w-fit;
  @apply cursor-pointer;
  @apply transition-all ease-in-out delay-0 duration-300;

  .title {
    @apply font-normal text-grey-50;
  }
  .edit {
    @apply text-primary-700;
    @apply lowercase;
  }

  &:hover {
    @apply opacity-75;
  }

  &.loading {
    @apply filter grayscale animate-pulse;
  }
}
</style>