<script setup lang='ts'>
  import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ReportHeaders } from './../types/reports.dto';
  import TableBase from '@/components/table/TableBase.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import SimpleServerName from '@court/components/server/SimpleServerName.vue';
  import { ReportBrowseDto } from '@/api/backend/court/report/report.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useReportsStore } from '../stores/report.store';
  import { useSoundStore } from '@/stores/sound.store';
  import { useTimeStore } from '@/stores/time.store';
  import { PaginationService } from '@/api/backend/pagination.service';
  import TextExtended from '@/components/TextExtended.vue';
  import { CentrifugoReportDeletedPayload, CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { ampli } from '@src/ampli';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import Button from '@/components/Button.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import Loader from '@/components/Loader.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useRouter } from 'vue-router';
  import { CourtRoutes } from '@court/court.routes';

  const { t } = useLocaleStore();
  const _sound = useSoundStore();
  const _panel = usePanelStore();
  const _reports = useReportsStore();
  const _router = useRouter();
  const _permissions = usePermissionsStore();
  const _time = useTimeStore();
  const _contextMenu = useContextMenu();
  const _adaptive = useAdaptiveStore();

  const props = defineProps<{ search: string }>();

  const headers = computed<ReportHeaders>(() => {
    return {
      date: {
        text: t('reports.headers.date'),
        min : '100px',
        max : '140px',
      },
      server: {
        text: t('reports.headers.server'),
        min : '120px',
        max : '160px',
      },
      initiator: {
        text: t('reports.headers.initiator'),
        min : '200px',
        max : '240px',
      },
      arrow: {
        text: '',
        min : '20px',
        max : '80px',
      },
      target: {
        text: t('reports.headers.target'),
        min : '200px',
        max : '240px',
      },
      empty: {
        text: '',
        min : '0px',
        max : '50px',
      },
      amount: {
        text: _reports.filters.unique_initiators ? t('reports.headers.amount-uniq') : t('reports.headers.amount'),
        min : '90px',
        max : '160px',
      },
      reason: {
        text: t('reports.headers.reason'),
        min : '150px',
        max : '250px',
      },
      actions: {
        text: '',
        min : '100px',
        max : '100%',
      },
    };
  });

  const loader = computed(() => {
    props.search;
    _reports.filters.hide_offline;
    _reports.filters.minimalToShow;
    _reports.filters.sort;
    _reports.filters.unique_initiators;

    return new PaginationService((page, limit) => useCourtApi().report.browse({
      page,
      limit,


      search           : props.search.length ? props.search : undefined,
      sort             : _reports.filters.sort,
      min              : props.search.length || _reports.filters.minimalToShow == 1 ? undefined : _reports.filters.minimalToShow - 1,
      hide_offline     : _reports.filters.hide_offline,
      unique_initiators: _reports.filters.unique_initiators ? true : undefined
    }), 50);
  });

  watch(() => loader.value, () => {
    items.value = null;
    next();
  }, {
    deep: true
  });
  
  const items = ref<(ReportBrowseDto | undefined)[] | null>(null) as Ref<(ReportBrowseDto | undefined)[] | null>;

  const computedItems = computed(() => {
    if (!items.value) {
      return items.value;
    }

    if (!_reports.filters.unique_initiators) {
      return items.value;
    }

    return items.value.filter(v => !v || v.total_reports_unique >= _reports.filters.minimalToShow);
  });

  const next = async () => {
    const loaderHash = loader.value.id;
    
    const result = await loader.value.next();

    if (loader.value.id !== loaderHash) {
      return;
    }    
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  /**
   * Пакет сокетов
   */
  const centrifugoCreateSubscription = ref<CentrifugoSubscribe>();
  const centrifugoDeleteSubscription = ref<CentrifugoSubscribe>();
  const centrifugoCheckStartedSubscription = ref<CentrifugoSubscribe>();
  const centrifugoCheckFinishedSubscription = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoCreateSubscription.value = useCentrifugoApi().subscribeReports(_panel.project.id, (report) => appendReport(report));
    centrifugoDeleteSubscription.value = useCentrifugoApi().subscribeReportsDelete(_panel.project.id, (data) => onCentrifugoDelete(data));
    centrifugoCheckStartedSubscription.value = useCentrifugoApi().subscribeCheckStarted(_panel.project.id, (data) => {
      const connected = items.value?.find(v => v?.target_steam_id == data.suspect.steam_id);
      if (!connected) {
        return;
      }

      connected.active_check_id = data.id;
    });
    centrifugoCheckFinishedSubscription.value = useCentrifugoApi().subscribeCheckFinished(_panel.project.id, (data) => {
      const connected = items.value?.find(v => v?.target_steam_id == data.suspect.steam_id);
      if (!connected) {
        return;
      }

      connected.active_check_id = undefined;
    });
  });

  onUnmounted(() => {
    centrifugoCreateSubscription.value?.unsubscribe();
    centrifugoDeleteSubscription.value?.unsubscribe();
    centrifugoCreateSubscription.value?.unsubscribe();
    centrifugoDeleteSubscription.value?.unsubscribe();
  });

  const appendReport = (report: ReportBrowseDto) => {
    if (!items.value) {
      return;
    }

    if (props.search.length) {
      return;
    }

    items.value = items.value.filter(v => v && (!report.target || v.target?.steam_id !== report.target.steam_id));
    
    if ((_reports.filters.unique_initiators ? report.total_reports_unique : report.total_reports) < _reports.filters.minimalToShow) {
      return;
    }
    
    items.value.unshift(report);

    if (_reports.filters.sort === 'count') {
      if (_reports.filters.unique_initiators) {
        items.value = items.value.sort((a, b) => (b?.total_reports_unique ?? 0) - (a?.total_reports_unique ?? 0));
      } else {
        items.value = items.value.sort((a, b) => (b?.total_reports ?? 0) - (a?.total_reports ?? 0));
      }
    }

    if ((_reports.filters.unique_initiators ? report.total_reports_unique : report.total_reports) > _reports.filters.minimalToHighlight || !_reports.sounds.muteLowAmount) {
      _sound.play(_reports.sounds.soundType);
    } 
  };

  const onCentrifugoDelete = (data: CentrifugoReportDeletedPayload) => {
    if (data.global) {
      items.value = [];
      return;
    }

    if (!items.value) {
      return;
    }
    
    if (data.report_id) {
      const original = items.value.find(v => v?.id === data.report_id);
      if (!original) {
        return;
      }
    
      const indexOf = items.value.findIndex(v => v?.id === data.report_id);
      if (indexOf >= 0) {
        if (!data.replace_with) {
          items.value.splice(indexOf, 1);
          return;
        }

        const current = items.value[indexOf];
        if (!current || current.total_reports == 1) {
          items.value.splice(indexOf, 1);
          return;
        }

        Object.assign(items.value[indexOf]!, { ...data.replace_with, total_reports: current.total_reports - 1 });
      }
    } else if (data.target_steam_id) {
      items.value = items.value.filter(v => v?.target?.steam_id !== data.target_steam_id);
    }
  };

  const tempAfterDelete = async () => {
    // todo: fix bug - реагирует на любое событие удаление, необязательно своё
    await new Promise<boolean>(res => {
      centrifugoDeleteSubscription?.value?.sub?.once('publication', () => res(true));
    });
  };

  const onContext = (e: MouseEvent, item: ReportBrowseDto | undefined) => {
    if (!item) {
      return;
    }
    
    const menuOpts = [
      {
        label : t('delete-report'),
        action: async () => {
          if (!item) {
            return;
          }
          await Promise.all([
            tempAfterDelete(),
            useCourtApi().report.delete(item?.id),
          ]);
        },
      }
    ];

    if (item.total_reports > 1) {
      menuOpts.push({
        label : t('delete-reports', {count: item?.total_reports}),
        action: async () => {
          if (!item?.target?.steam_id) {
            return;
          }
          await useCourtApi().report.deleteAllOnPlayer(item?.target?.steam_id);
        }
      });
    }

    _contextMenu.open(e, menuOpts);

    ampli.clientUseContextMenu({ target: 'report' });
  };
</script>

<template>
  <TableBase
    :headers="headers" 
  
    :items="computedItems"
    :item-key="(i) => i?.id.toString() ?? Math.random().toString()"

    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"

    :next="next"
    :on-context="onContext"
  >
    <template #date="{ item }">
      <template v-if="item">
        <ListItem
          :title="_time.format(item.created_at, 'DD.MM.YY', true, true)"
          :message="_time.format(item.created_at, 'HH:mm:ss')"
          no-avatar
          class="date"
        />
      </template>
      <template v-else>
        <div class="flex flex-col gap-1.5">
          <Skeleton
            style="height: 14px; width: 80px"
            class="w-full rounded-md"
          />
          <Skeleton
            style="height: 12px; width: 60px"
            class="w-full rounded-md"
          />
        </div>
      </template>
    </template>
    <template #server="{ item }">
      <SimpleServerName :server-id="item?.server_id" class="font-medium" />
    </template>
    <template v-if="!_adaptive.isMobile" #initiator="{ item }">
      <PlayerBox
        class="w-full"
        :player="item?.initiator ?? null"
        :search="search?.length ? [search] : undefined"
        :subtext="item?.initiator?.steam_id"
      />
    </template>
    <template v-if="!_adaptive.isMobile" #arrow="{ }">
      <div class="arrow-container">
        <Svg.arrow class="arrow" />
      </div>
    </template>
    <template #target="{ item }">
      <PlayerBox
        class="w-full"
        :player="item?.target ?? null"
        :search="search?.length ? [search] : undefined"
        :subtext="item?.target?.steam_id"
      />
    </template>
    <template v-if="!_adaptive.isMobile" #empty="{}" />
    <template #amount="{ item }">
      <template v-if="item?.total_reports">
        <p
          class="text-grey-400 font-medium"
          :class="{ 'highlight-amount': (_reports.filters.unique_initiators ? item.total_reports_unique : item.total_reports) > _reports.filters.minimalToHighlight }"
        >
          {{ t('general.amount-st', { amount: _reports.filters.unique_initiators ? item.total_reports_unique : item.total_reports}) }}
        </p>
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 40px"
          class="rounded-md"
        />
      </template>
    </template>
    <template #reason="{ item }">
      <template v-if="item?.reason || item?.message">
        <TextExtended :data="(item ? { reason: item.reason, message: item.message } : null)" />
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 80px"
          class="rounded-md"
        />
      </template>
    </template>
    <template #actions="{ item }">
      <template v-if="_permissions.can(['Court_ReportDelete']) && !item?.active_check_id">
        <template v-if="item">
          <div class="flex justify-end">
            <Button
              preset="default-stroke"
              class="!px-2"
              :action="() => useCourtApi().report.delete(item?.id)"
              @contextmenu.stop.prevent="() => {}"
            >
              <Svg.delete_stroke />
            </Button>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-end">
            <Skeleton
              style="height: 38px; width: 38px"
              class="rounded-md"
            />
          </div>
        </template>
      </template>
      <template v-if="_permissions.can(['Court_CheckRead']) && item?.active_check_id">
        <div class="flex justify-end">
          <TooltipBase trigger="hover" placement="left">
            <template #content>
              <div class="px-2 py-1">
                {{ t('chekc-in-progress') }}
              </div>
            </template>

            <Button
              class="!p-2 bg-primary-700/15 rounded-md"
              :action="() => _router.push({ name: CourtRoutes.Checks, params: { id: item.active_check_id }})"
              @contextmenu.stop.prevent="() => {}"
            >
              <Loader
                size="20px"
                color="#0099ff"
                position="relative"
              />
            </Button>
          </TooltipBase>
        </div>
      </template>
    </template>
  </TableBase>
</template>

<style lang='scss' scoped>

.verdict {
  @apply flex items-center max-w-full;
  @apply px-1.5 py-1 gap-1.5;
  @apply bg-grey-850 rounded-md;
  &.Pending {
    @apply bg-primary-700/15;
    
    .text {
      @apply text-primary-700;
    }

    svg {
      @apply fill-primary-700;
    }
  }
}
.highlight-amount {
  @apply text-ra-yellow;
}

.delete {
  @apply w-6 cursor-pointer;
  @apply fill-grey-700;
  @apply select-none;

  &:hover {
    @apply fill-grey-400;
  }

  &:active {
    @apply fill-grey-50;
  }
}

.arrow-container {
  @apply flex justify-center;

  .arrow {
    @apply w-6 fill-grey-600;
  }
}

.date {
  :deep(.lines) {
    .title {
      @apply text-grey-400 font-medium;
    }
    .message {
      @apply text-grey-600 font-medium;
    }
  }
}
</style>