<template>
  <div class="button-select-container">
    <div
      v-for="item in options"
      :key="item.value"
      class="button-select"
      :class="{'-active': modelValue === item.value}"
      @click="modelValue = item.value"
    >
      <p class="button-text">
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
  export interface ButtonSelectOption {
    value: string;
    text: string;
  }

  defineProps<{
    options: ButtonSelectOption[];
  }>();

  const modelValue = defineModel<string>();
</script>

<style lang="scss" scoped>
.button-select-container {
  @apply w-full;
  @apply grid gap-1;
  grid-auto-flow: column;
  @apply bg-grey-1000;
  @apply p-1;
  @apply h-[38px];
  @apply rounded-md;

  .button-select {
    @apply px-2 overflow-hidden;
    @apply duration-200;
    @apply rounded;
    @apply flex items-center justify-center;
    @apply text-grey-600;
    @apply text-14-500;
    @apply cursor-pointer;

    .button-text {
      @apply truncate;
    }

    &.-active {
      @apply bg-grey-900;
      @apply text-grey-50;
    }
  }
}
</style>