<script setup lang="ts">
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import PlayerDetailsCardBadge from './PlayerDetailsCardBadge.vue';

  export type PlayerDetailsCardRowInfo = {
    title: string;
    inline?: boolean;
    noTruncate?: boolean;
  }

  export type PlayerDetailsCardRow = (string | PlayerDetailsCardRowInfo);

  const props = defineProps<{
    dateFrom?: string;
    dateTo?: string;
    rows: PlayerDetailsCardRow[];
  }>();

  const getRowOpts = (row: PlayerDetailsCardRow): PlayerDetailsCardRowInfo => {
    if (typeof row === 'object') {
      return row;
    }

    return {
      title: row
    };
  };
</script>

<template>
  <div class="player-details-card">
    <div class="card-header">
      <PlayerDetailsCardBadge v-if="dateFrom">
        <template #default>
          <Svg.calendar />
          {{ dateFrom }}
        </template>
      </PlayerDetailsCardBadge>
      <PlayerDetailsCardBadge v-if="dateTo">
        <template #default>
          <Svg.calendar />
          {{ dateTo }}
        </template>
      </PlayerDetailsCardBadge>
      <slot name="badges" />

      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
    <template v-for="(row, index) in rows" :key="row">
      <div
        v-if="$slots[`row${index}`]"
        class="card-row"
        :class="{ 'inline-variant': getRowOpts(row).inline }"
      >
        <p class="row-title shrink-0">{{ getRowOpts(row).title }}:</p>
        <div class="row-value" :class="{ 'truncate': !getRowOpts(row).noTruncate }">
          <slot :name="`row${index}`" />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.player-details-card {
  @apply bg-grey-850;
  @apply p-2.5;
  @apply rounded-md;
  @apply flex flex-col gap-2.5;
  @apply overflow-hidden;

  .card-header {
    @apply flex items-center justify-between gap-2;

    .actions {
      @apply ml-auto;
      @apply flex gap-2.5;
    }
  }
  .card-row {
    @apply flex items-center gap-[5px];
    @apply overflow-hidden;

    &.inline-variant {
      @apply inline;

      .row-title {
        @apply inline;
      }
      .row-value {
        @apply inline ml-1;
      }
    }
    
    .row-title {
      @apply text-16-400;
      @apply text-grey-400;
    }
    .row-value {
      @apply text-16-400;
      @apply text-grey-50;
      @apply leading-tight;
    }
  }
}
</style>