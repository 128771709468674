<script lang="ts">

  export type RccBanModalProps = {
    player: PlayerFullDto;
    alert: PlayerAlertDetectRaBanMetaDto;
  };

  export const ActivateRaBanModal = async (player: PlayerFullDto, alert: PlayerAlertDetectRaBanMetaDto) => {
    const _modal = useModalsStore();

    _modal.open('ra-bans', { player, alert });
  };
</script>

<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import { PlayerAlertDetectRaBanMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import ProjectListItem from '@panel/components/project/ProjectListItem.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  defineProps<RccBanModalProps & ModalInjectedProps>();

  const { t } = useLocaleStore();
 
</script>

<template>
  <div class="rcc-ban-modal">
    <DividedCard>
      <template #header>
        <PlayerBox :player="player" />
      </template>

      <template #default>
        <div class="content">
          <div class="label">
            <p class="text-grey-50">
              {{ t('ban-sync-ban-modal-title' ) }}
            </p>
            <p class="text-grey-600">{{ t('general.amount-st', { amount: alert.bans.length }) }}</p>
          </div>

          <div class="items-row">
            <template v-for="ban in alert.bans" :key="ban.banID">
              <div class="item" @click="ActivateBanViewModal({ banId: ban.ban_id, projectId: ban.project_id })">
                <ProjectListItem
                  v-if="ban.project"
                  :project="ban.project"
                  :subtext="ban.ban_reason"
                />
                <Svg.open class="icon ml-4" />
              </div>
            </template>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-2.5">
          <Button
            :text="t('general.close')"
            preset="default"
            :action="() => { injected.close() }"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang='scss' scoped>

.rcc-ban-modal {
  width: 95vw;
  max-width: 440px;

  .content {
    @apply flex flex-col gap-2;
    .label {
      @apply flex justify-between;
    }
  }
}
</style>