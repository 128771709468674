<script setup lang="ts">
  import { computed, onMounted, ref, useSlots } from 'vue';
  import ButtonSelect, { ButtonSelectOption } from '../ButtonSelect.vue';

  const slots = useSlots();

  const selected = ref<string>(``);
  const options = computed((): ButtonSelectOption[] => {
    var whitelist = ['default', 'actions'];

    const keys = Object.keys(slots).filter(v => !whitelist.includes(v));

    return keys.map(v => ({
      text : v,
      value: v
    }));
  });

  onMounted(() => {
    if (!options.value.length) {
      return;
    }

    selected.value = options.value[0].value;
  });

  defineExpose({ selected });
</script>

<template>
  <div class="modal-menu-page-layout">
    <div v-if="options.length > 0 || $slots.actions" class="modal-menu-page-layout-header">
      <div class="modal-menu-page-layout-filters">
        <ButtonSelect
          v-model="selected"
          :options="options"
        />
      </div>
      <div class="modal-menu-page-layout-actions">
        <slot name="actions" v-bind="{ selected }" />
      </div>
    </div>
    <div class="modal-menu-page-layout-content relative">
      <slot :name="$slots[selected] ? selected : 'default'" v-bind="{ selected }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-menu-page-layout {
  @apply flex flex-col h-full;
  @apply grow;


  .modal-menu-page-layout-header {
    @apply flex justify-between items-center gap-2.5;
    @apply p-5;

    .modal-menu-page-layout-filters {
      @apply w-fit;
    }
    .modal-menu-page-layout-actions {
      @apply flex h-full items-center;
    }
    &:first-child {
      @apply pt-5;
    }
  }

  .modal-menu-page-layout-content {
    @apply grow overflow-y-auto px-5 pb-5;
    
    &:first-child {
      @apply pt-5;
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>