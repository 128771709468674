<script lang="ts" setup>
  import Button from '@/components/Button.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';

  const { t } = useLocaleStore();

  const props = defineProps<{ updater?: () => unknown }>();

  const maxCommands = ref<number>(3);
  const data = defineModel<Omit<CourtCommandDto, 'id'>>({ required: true });

  const addCommand = () => {
    data.value.commands.push('');

    setTimeout(() => props.updater?.(), 5);
  };

  const deleteCommand = (index: number) => {
    data.value.commands.splice(index, 1);
    
    setTimeout(() => props.updater?.(), 5);
  };
</script>

<template>
  <div class="content">
    <div class="input-group">
      <div class="header">
        <p class="label">{{ t('action.input.label', {length: `${data.commands.length}/${maxCommands}`}) }}</p>
        <div
          class="add"
          :class="{ 'inactive': data.commands.length >= maxCommands }"
          @click="addCommand"
        >
          {{ t('general.all') }}
        </div>
      </div>

      <div class="inputs">
        <TextInput v-model="data.commands[0]" :placeholder="t('action.input.placeholder.enter')" />

        <template v-for="(_, index) in data.commands.length" :key="index">
          <div v-if="index != 0" class="additional-commands">
            <TextInput
              v-model="data.commands[index]"
              :placeholder="t('action.input.placeholder.enter')"
              class="w-full"
            />
            <Button class="remove" @click="deleteCommand(index)">
              <Svg.delete />
            </Button>
          </div>
        </template>
      </div>
    </div>

    <div class="variables">
      <div class="variable">
        <span class="main">{{ '{steam_id}' }}</span>
        <span class="text">{{ t('actions.default.variable.1') }}</span>
      </div>
      <div class="variable">
        <span class="main">{{ '{steam_name}' }}</span>
        <span class="text">{{ t('actions.default.variable.2') }}</span>
      </div>
      <div class="variable">
        <span class="main">{{ '{player_ip}' }}</span>
        <span class="text">{{ t('actions.default.variable.ip') }}</span>
      </div>
      <div class="variable">
        <span class="main">{{ '{staff_steam_id}' }}</span>
        <span class="text">{{ t('actions.default.variable.3') }}</span>
        <TooltipBase trigger="hover" class="tooltip">
          <div>
            <Svg.warning class="w-4 fill-amber-500 cursor-pointer" />
          </div>

          <template #content>
            <div class="max-w-72 px-1.5 py-1 text-sm text-center text-grey-100">
              <p>{{ t('customizations.actions.tooltip') }}</p>
            </div>
          </template>
        </TooltipBase>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.content {
  @apply flex flex-col gap-3;

  .input-group {
    @apply flex flex-col gap-1;

    .header {
      @apply flex justify-between items-center;

      .label {
        @apply text-grey-400;
        transition: none;
        @apply flex items-center justify-between;
      }

      .add {
        @apply text-primary-700 cursor-pointer;

        &.inactive {
          @apply pointer-events-none text-grey-600;
        }

        &:hover {
          @apply text-primary-600;
        }
      }
    }
  }

  .inputs {
    @apply flex flex-col gap-2.5 w-full;

    .additional-commands {
      @apply flex gap-2.5 items-center w-full;
    }

    .remove {
      @apply bg-red-500/15 text-red-500 rounded-md w-[40px] h-[40px] px-5;

      svg {
        @apply w-5 fill-red-500;
      }

      &:hover {
        @apply bg-red-500/20;
      }
    }
  }

  .variables {
    @apply flex flex-col gap-2;

    .variable {
      @apply flex gap-1 items-center text-sm;

      .main {
        @apply px-1 py-0.5 rounded;
        @apply bg-grey-850;
        @apply text-grey-300;
      }
      .text {
        @apply text-grey-500;
        @apply truncate;
      }
    }
  }
}

.tooltip-content {
  @apply max-w-72 px-1.5 py-1 text-sm text-center;
  @apply text-grey-100;
}
</style>