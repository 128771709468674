<script setup lang="ts">
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerNavigationTeamHistory from './PlayerNavigationTeamHistory.vue';
  import PlayerNavigationTeamCurrent from './PlayerNavigationTeamCurrent.vue';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import { onMounted, watch } from 'vue';

  const { t } = useLocaleStore();

  const emits = defineEmits(['badgeUpdate']);
  const props = defineProps<{ 
    player: PlayerFullDto,
    team: PlayerFullDto[] | null
  }>();

  onMounted(() => {
    emits('badgeUpdate', props.team?.length ?? 0);
  });

  watch(() => props.team, (v) => {
    emits('badgeUpdate', v?.length ?? 0);
  }, { deep: true });
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template #[t(`modal.player.nav.team`)]>
      <PlayerNavigationTeamCurrent
        :player="player"
        :team="team"
      />
    </template>
    <template #[t(`modal.player.nav.history`)]>
      <PlayerNavigationTeamHistory :steam-id="player.steam_id" />
    </template>
  </ModalMenuExtendedPageLayout>
</template>

<style lang="scss" scoped>
</style>