<script setup lang='ts'>
  import Skeleton from '@/components/Skeleton.vue';
  import { useCourtStore } from '@court/stores/court.store';

  const _court = useCourtStore();

  const props = defineProps<{ serverId: number | undefined }>();
</script>

<template>
  <div class="w-full">
    <template v-if="serverId">
      <p class="text-grey-400 line-clamp">
        {{ _court.serverNameById(props.serverId) }}
      </p>
    </template>
    <template v-else>
      <Skeleton
        style="height: 20px; width: 80px"
        class="rounded-md"
      />
    </template>
  </div>
</template>

<style lang='scss' scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>