<script lang="ts">
  export type EnterTwoFactorModalProps = {
    callback: (code: string | null) => Promise<unknown>
  }

  export const ActivateEnterTwoFactorConfirm = (callback: (code: string | null) => Promise<unknown>) => {
    const _modal = useModalsStore();

    _modal.open('2fa-enter', {
      callback
    });
  };
</script>

<script setup lang="ts">
  import DividedCard from '@/components/DividedCard.vue';
  import CodeInput from '@/components/inputs/CodeInput.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ref } from 'vue';
  import { onMounted } from 'vue';

  const { t } = useLocaleStore();

  onMounted(() => {
    props.injected.setBeforeClose(async () => {
      props.callback(null);

      return true;
    });
  });

  const props = defineProps<ModalInjectedProps & EnterTwoFactorModalProps>();


  const confirm = async (code?: string) => {
    if (!code) {
      return;
    }

    try {
      await props.callback(code);

      props.injected.setBeforeClose(() => true);

      props.injected.close();
    }
    catch (err: any) {
      if (err.message.includes('Bad 2fa code')) {
        useRuntimeNotification(`warning`, t('bad-2fa-code-setup'));
        codeInput?.value?.reset();
        return;
      }

      throw err;
    }
  };

  const codeInput = ref<InstanceType<typeof CodeInput>>();
</script>

<template>
  <div class="modal">
    <DividedCard no-content-padding>
      <template #default>
        <div class="content">
          <div class="header">
            <p class="title">{{ t('2fa-enter-header-title') }}</p>
            <p class="subtitle">{{ t('2fa-enter-header-subtitle') }}</p>
          </div>
          <CodeInput
            ref="codeInput"
            :amount="6"
            height="47.83"
            @submit="(e) => confirm(e)"
          />
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  width: 95vw;
  max-width: 420px;

  .content {
    @apply flex flex-col gap-5 p-10;

    .header {
      @apply flex flex-col gap-1;

      .title {
        @apply text-center;
        @apply text-lg font-semibold text-grey-50;
      }

      .subtitle {
        @apply text-center;
        @apply text-base text-grey-500;
      }
    }

    :deep(.code-input) {
      @apply gap-2.5;
    }
  }
}
</style>