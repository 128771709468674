<script setup lang="ts">
  import { onMounted } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaPlayerReportDelete } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<AuditDto<AuditMetaPlayerReportDelete>>();

  const { t } = useLocaleStore();

  const player = ref<PlayerMinimalDto>();

  onMounted(async () => {
    if (!props.meta.target_steam_id) {
      return;
    }

    player.value = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.target_steam_id ?? '');
  });

  const audit = ref<InstanceType<typeof AuditBase>>();
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="Svg.delete"
    :icon_styles="props.meta.target_steam_id ? 'fill-grey-500' : 'fill-red-500'"
    :date="created_at"
    :ready="!!player || !props.meta.target_steam_id"
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <template v-if="props.meta.target_steam_id">
      <span>{{ meta.action == "all" ? t('audit-player-report-delete-target-all') : t('audit-player-report-delete-target') }}</span>
      <span class="bright cursor-pointer" @click="() => !!player ? ActivatePlayerDetailsModal(player.steam_id) : {}">{{ player?.steam_name }}</span>
    </template>
    <template v-else>
      <span>{{ t('audit-player-report-delete-all') }}</span>
    </template>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>