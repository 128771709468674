<script setup lang='ts'>
  import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import TableBase from '@/components/table/TableBase.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { useRouter } from 'vue-router';
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import { useTimeStore } from '@/stores/time.store'; 
  import { PaginationService } from '@/api/backend/pagination.service';
  import { usePanelStore } from '@panel/stores/panel.store';
  import ClientListItem from '@court/components/client/ClientListItem.vue';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import Button from '@/components/Button.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import TooltipCard from '@/components/tooltip/TooltipCard.vue';
  import Loader from '@/components/Loader.vue';
  
  import { TableHeaders } from "@/components/table/table.dto";
  import { useCheckUtils } from '@court/utils/check.utils';
  import { CourtRoutes } from '@court/court.routes';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Highlight from '@/components/Highlight.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';

  export type ChecksHeaders = TableHeaders<'date' | 'moderator' | 'arrow' | 'suspect' | 'empty' | 'discord' | 'verdict' | 'actions'>

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _panel = usePanelStore();
  const _router = useRouter();
  const _adaptive = useAdaptiveStore();

  const props = defineProps<{ search: string }>();

  const headers = computed((): ChecksHeaders => {
    return {
      date: {
        text: t('checks.headers.date'),
        min : '100px',
        max : '140px',
      },
      moderator: {
        text: t('checks.headers.moderator'),
        min : '150px',
        max : '160px',
      },
      arrow: {
        text: null,
        min : '20px',
        max : '80px',
      },
      suspect: {
        text: t('checks.headers.suspect'),
        min : '200px',
        max : '240px',
      },
      empty: {
        text: '',
        min : '0px',
        max : '60px',
      },
      discord: {
        text: t('checks.headers.contact'),
        min : '140px',
        max : '240px',
      },
      verdict: {
        text: t('checks.headers.verdict'),
        min : '200px',
        max : '100%',
      },
      actions: {
        text : '',
        min  : '50px',
        right: true,
      },
    };
  });

  const openCheck = (check_id: number) => {
    _router.push({ name: CourtRoutes.Checks, params: { id: check_id.toString() }});
  };

  const startSubscriber = ref<CentrifugoSubscribe>();
  const finishSubscriber = ref<CentrifugoSubscribe>();

  const subscribeCheckStart = () => {
    if (!_panel.project) {
      return;
    }
    startSubscriber.value = useCentrifugoApi().subscribeCheckStarted(_panel.project.id, (check) => {
      if (!items.value) {
        items.value = [];
      }

      if (props.search.length) {
        return;
      }

      items.value.unshift(check);
    });
  };

  const subscribeCheckFinish = () => {
    if (!_panel.project) {
      return;
    }
    
    finishSubscriber.value = useCentrifugoApi().subscribeCheckFinished(_panel.project.id, (check) => {
      if (!items.value) {
        return;
      }

      const foundItem = items.value.find(v => v.id === check.id);
      if (!foundItem) {
        return;
      }

      Object.assign(foundItem, check);
    });
  };

  onMounted(() => {
    subscribeCheckStart();
    subscribeCheckFinish();
  });
  
  onUnmounted(() => {
    startSubscriber.value?.unsubscribe();
    finishSubscriber.value?.unsubscribe();
  });
  
  const loader = computed(() => {
    props.search;

    const searchAsModerator = props.search.replace('@', '');

    const exactModerator = _panel.projectClients.find(v => v.client.name === searchAsModerator || v.client.tag === searchAsModerator);
    const suitableModerators = _panel.projectClients.filter(v => v.client.name?.toLowerCase()?.includes(searchAsModerator.toLowerCase()) || v.client.tag?.includes(searchAsModerator.toLowerCase()));

    let search: string | undefined = props.search.length ? props.search : undefined;
    let client_ids: number[] | undefined = undefined;

    if (props.search.length > 0) {
      if (suitableModerators) {
        client_ids = suitableModerators.map(v => v.client.id);
      }

      if (exactModerator) {
        search = undefined;

        client_ids = [exactModerator.client.id];
      }
    }

    return new PaginationService((page, limit) => 
      useCourtApi().check.browse({ page, limit, search, client_ids })
    );
  });

  watch(() => loader.value, () => {
    items.value = null;
    next();
  }, {
    deep: true
  });

  const items = ref<CheckDto[] | null>(null) as Ref<CheckDto[] | null>;

  const next = async () => {
    const loaderHash = loader.value.id;
    
    const result = await loader.value.next();

    if (loader.value.id !== loaderHash) {
      return;
    }    
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  const { verdict } = useCheckUtils();
</script>

<template>
  <TableBase
    :headers="headers"
    
    :items="items"
    :item-key="(i) => i.id.toString()"

    :next="next"
    
    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"
  >
    <template #date="{ item }">
      <template v-if="item">
        <ListItem
          :title="_time.format(item?.created_at, 'DD.MM.YY', true, true)"
          :message="_time.format(item?.created_at, 'HH:mm:ss')"
          no-avatar
          class="date"
        />
      </template>
      <template v-else>
        <div class="flex flex-col gap-1.5">
          <Skeleton
            style="height: 14px; width: 80px"
            class="w-full rounded-md"
          />
          <Skeleton
            style="height: 12px; width: 60px"
            class="w-full rounded-md"
          />
        </div>
      </template>
    </template>
    <template #moderator="{ item }">
      <ClientListItem
        :client-id="item?.client_id"
        :search="search"
        class="w-full"
      />
    </template>
    <template v-if="!_adaptive.isMobile" #arrow="{ }">
      <div class="arrow-container">
        <Svg.arrow class="arrow" />
      </div>
    </template>
    <template #suspect="{ item }">
      <PlayerBox
        class="w-full"
        :player="item?.suspect ?? null"
        :subtext="item?.suspect?.steam_id"
        :search="[search]"
      />
    </template>
    <template v-if="!_adaptive.isMobile" #empty="{}" />
    <template #discord="{ item }">
      <template v-if="item">
        <div
          v-if="item.contact"
          class="value action cursor-pointer"
          @click="useCopyClipboard(item.contact, true)"
        >
          <p class="truncate">
            <Highlight
              :visible-text="item.contact"
              :search="[search]"
            />
          </p>
          <Svg.copy />
        </div>
        <!-- <Svg.dropdown class="w-4 h-4 fill-grey-600 rotate-90" /> -->
        <TooltipBase
          v-else
          trigger="hover"
          class="w-fit"
        > 
          <p class="not-received">{{ t('checks.no-discord') }}</p>
       
          <template #content> 
            <div class="tooltip-text"> 
              <p>{{ t('checks.no-discord-tooltip') }}</p> 
            </div> 
          </template> 
        </TooltipBase>
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 120px"
          class="rounded-md"
        />
      </template>
    </template>
    <template #verdict="{ item }">
      <template v-if="item">
        <div class="flex gap-1">
          <div
            class="verdict"
            :class="item.status"
          >
            <Svg.check_circle v-if="item.status === 'FinishedClear'" />
            <Svg.lock v-if="item.status === 'FinishedBan'" />
            <Svg.clear v-if="item.status === 'Canceled' || item.status === 'CanceledTimeout'" />
            <Loader
              v-if="item.status === 'Pending'"
              size="16px"
              color="#0099ff"
              position="relative"
            />

            <p class="text" :title="verdict(item)">
              {{ verdict(item) }}
            </p>
          </div>
          <!--<TooltipCard
            v-if="item.comment"
            trigger="hover"
            distance="8"
            :message="item.comment"
          >
            <div v-if="item.comment" class="comment">
              <Svg.chat />
            </div>
          </TooltipCard>-->
        </div>
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 140px"
          class="rounded-md"
        />
      </template>
    </template>
    <template #actions="{ item }">
      <template v-if="item">
        <div class="flex justify-end">
          <Button
            preset="default-stroke"
            class="!px-2"
            :action="() => openCheck(item.id)"
          >
            <Svg.open />
          </Button>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-end">
          <Skeleton
            style="height: 38px; width: 38px"
            class="rounded-md"
          />
        </div>
      </template>
    </template>
  </TableBase>
</template>

<style lang='scss' scoped>
.value {
  @apply flex gap-1 items-center truncate;
  @apply text-grey-400 font-medium;
  
  svg {
    @apply fill-grey-600 shrink-0;
    height: 18px;
    width: 18px;
  }
  
  &.action:hover {
    @apply text-grey-50;
    @apply select-none;

    svg {
      @apply fill-grey-50;
    }
  }
}

.not-received {
  @apply text-grey-600;
  @apply truncate;
  @apply cursor-default;
}

.verdict {
  @apply flex items-center max-w-full;
  @apply px-1.5 py-1 gap-1.5;
  @apply bg-grey-900 rounded-md;
  @apply border border-grey-850;

  .text {  
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
    @apply text-grey-400 font-medium;
  }

  svg {
    @apply shrink-0;
    @apply w-4 h-4 fill-grey-400;
  }

  &.Pending {
    @apply bg-primary-700/15;
    @apply border-transparent;
    
    .text {
      @apply text-primary-700;
    }

    svg {
      @apply fill-primary-700;
    }
  }

  &.FinishedClear {
    svg {
      @apply fill-emerald-500;
    }
  }

  &.FinishedBan {
    svg {
      @apply fill-rose-500;
    }
  }
}

.comment {
  @apply flex justify-center p-1.5;
  @apply rounded-md;
  @apply bg-grey-850;

  svg {
    @apply w-4;
    @apply fill-grey-50;
  }
}

.arrow-container {
  @apply flex justify-center;

  .arrow {
    @apply w-6 fill-grey-600;
  }
}

.date {
  :deep(.lines) {
    .title {
      @apply text-grey-400 font-medium;
    }
    .message {
      @apply text-grey-600 font-medium;
    }
  }
}
</style>