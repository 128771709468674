<script setup lang='ts'>
  import { CheckDto, CheckActivityDto, CheckActivityMetaFinishedDto, CheckActivityMetaNoticeChangedDto, CheckActivityMetaMessageFromPlayerDto, CheckActivityMetaMessageFromModeratorDto, CheckActivityMetaPlayerTeamChangedDto, CheckActivityMetaPlayerDisconnectedDto, CheckActivityMetaStartedDto, CheckActivityMetaRccCall } from '@/api/backend/court/check/check.dto';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import CheckActivityFinished from './activity/CheckActivityFinished.vue';
  import CheckActivityNoticeChanged from './activity/CheckActivityNoticeChanged.vue';
  import CheckActivityStarted from './activity/CheckActivityStarted.vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import CheckActivityMessageFromPlayer from './activity/CheckActivityMessageFromPlayer.vue';
  import CheckActivityMessageFromModerator from './activity/CheckActivityMessageFromModerator.vue';
  import CheckActivityDisconnected from './activity/CheckActivityDisconnected.vue';
  import CheckActivityTeamChanged from './activity/CheckActivityTeamChanged.vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import Loading from '@/components/Loading.vue';
  import CheckActivityRccCall from './activity/CheckActivityRccCall.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import { useSoundStore } from '@/stores/sound.store';
  import { useInterfaceStore } from '@court/stores/interface.store';

  const props = defineProps<{ checkId: number, check: CheckDto | null }>();

  const _panel = usePanelStore();
  const _sound = useSoundStore();
  const _interface = useInterfaceStore();

  const activities = ref<CheckActivityDto<unknown>[] | null>(null);

  const loadActivity = async () => {
    activities.value = null;

    activities.value = await useCourtApi().check.getActivity(props.checkId);
  }; 

  const computedActivity = computed<CheckActivityDto<unknown>[]>(() => {
    if (!activities.value) {
      return [];
    }

    if (!reversed.value) {
      return activities.value;
    }
    
    return activities.value.slice().reverse();
  });

  watch(() => props.checkId, () => subscribeActivity());

  const activitySubscriber = ref<CentrifugoSubscribe>();

  const subscribeActivity = () => {
    unsubscribeActivity();

    activitySubscriber.value = useCentrifugoApi().subscribeCheckActivity(_panel.project!.id, (activity) => {
      if (activity.check_id !== props.checkId) {
        return;
      }

      activities.value?.push(activity);

      if (activity.type === 'MessageFromPlayer' && (activity.meta as CheckActivityMetaMessageFromPlayerDto).is_contact) {
        if (_interface.settings.play_sound === 'None') {
          return;
        }
        
        _sound.play(_interface.settings.play_sound);
      }
    });
  };

  const unsubscribeActivity = () => {
    activitySubscriber?.value?.unsubscribe();
  };

  const reversed = computed(() => !props.check?.finished_at);

  onMounted(async () => {
    await loadActivity();

    subscribeActivity();
  });

  onUnmounted(() => {
    unsubscribeActivity();
  });

  defineExpose({ activities });
</script>

<template>
  <div
    class="activity"
    :class="{ reversed }"
  >
    <template v-if="!check || activities == undefined">
      <div class="h-full w-full flex justify-center items-center">
        <Loading :can-view="!!check && !!activities" />
      </div>
    </template>
    <template v-else>
      <template v-for="activity in computedActivity" :key="activity.id">
        <template v-if="activity.type === 'Started' && check">
          <CheckActivityStarted
            :check="check"
            :meta="(activity.meta as CheckActivityMetaStartedDto)"
            :reversed="reversed"
            :created="activity.created_at"
          />
        </template>      

        <template v-if="activity.type === 'NoticeChanged'">
          <CheckActivityNoticeChanged
            :check="check"
            :meta="(activity.meta as CheckActivityMetaNoticeChangedDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'RccCall'">
          <CheckActivityRccCall
            :meta="(activity.meta as CheckActivityMetaRccCall)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'Finished'">
          <CheckActivityFinished
            :meta="(activity.meta as CheckActivityMetaFinishedDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'MessageFromPlayer'">
          <CheckActivityMessageFromPlayer
            :check="check"
            :meta="(activity.meta as CheckActivityMetaMessageFromPlayerDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'MessageFromModerator'">
          <CheckActivityMessageFromModerator
            :check="check"
            :meta="(activity.meta as CheckActivityMetaMessageFromModeratorDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'PlayerDisconnected'">
          <CheckActivityDisconnected
            :check="check"
            :meta="(activity.meta as CheckActivityMetaPlayerDisconnectedDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
        <template v-if="activity.type === 'PlayerTeamChanged'">
          <CheckActivityTeamChanged
            :check="check"
            :meta="(activity.meta as CheckActivityMetaPlayerTeamChangedDto)"
            :reversed="reversed" 
            :created="activity.created_at"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<style lang='scss' scoped>

.activity {
  @apply flex flex-col;
  @apply w-full;
  @apply relative;
  @apply p-6;
  overflow-y: scroll;
  &.reversed {
    @apply flex-col-reverse; 
  }
}
</style>