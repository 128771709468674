<script setup lang="ts">
  import { useLocaleStore } from '@/stores/locale.store';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import PlayerNavigationSignagesVariant from './PlayerNavigationSignagesVariant.vue';

  defineProps<{ steamId: string }>();

  const { t } = useLocaleStore();
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <template #[t(`signages-filter-actual`)]>
      <PlayerNavigationSignagesVariant :steam-id="steamId" mode="actual" />
    </template>
    <template #[t(`signages-filter-destroyed`)]>
      <PlayerNavigationSignagesVariant :steam-id="steamId" mode="destroyed" />
    </template>
  </ModalMenuExtendedPageLayout>
</template>
