<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivatePlayerDetailsModal } from '../player-details/PlayerDetailsModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto'; 
  import { ampli } from '@src/ampli';
  import Loader from '@/components/Loader.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type PlayerSearchModalOpts = {};

  export const ActivatePlayerSearchModal = async (props: PlayerSearchModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('player-search', {});
  };
</script>

<script setup lang="ts">
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { ref, watch } from 'vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import SearchIcon from '@src/assets/icons/search.svg';
  import PlayerBox from '@court/components/player/PlayerBox.vue';

  const { t } = useLocaleStore();
  const _modal = useModalsStore();

  const props = defineProps<ModalInjectedProps>();

  watch(() => props.injected.visible, (v) => { 
    if (!v) {
      return;
    }

    setTimeout(() => { 
      inputRef?.value?.select?.();
    });
  });
  const inputRef = ref<InstanceType<typeof TextInput>>();


  const lastSearchId = ref<string>();
  const search = ref<string>('');


  const foundPlayers = ref<PlayerFullDto[] | null>(null);

  watch(() => search.value, () => foundPlayers.value = null);

  const loadPlayers = async () => {
    if (!search.value.trim().length) {
      return;
    }

    foundPlayers.value = null;

    const id = Math.random().toString();

    lastSearchId.value = id;

    const results = await useCourtApi().player.browseFull({
      offline: true,
      search : search.value.trim(),
      limit  : 25,
      page   : 0
    });

    ampli.useSearch({ variant: 'ctrl-f' });

    if (lastSearchId.value !== id) {
      return;
    }

    foundPlayers.value = results.results;
  };
</script>

<template>
  <div class="fake-wrapper">
    <div class="fake-modal-border">
      <div class="fake-modal"> 
        <TextInput
          ref="inputRef"
          v-model="search"
          class="text-search"
          without-effects
          theme="middle"
          no-border
          height="50"
          autofocus
          :placeholder="t('modal.search.placeholder')"
          :debounce="500"
          @debounced-update="loadPlayers"
        >
          <div class="icon pr-2.5">
            <SearchIcon style="width: 24px;" class="stroke-grey-500 stroke-2" />
          </div>

          <template #append>
            <div 
              v-if="search.length > 0"
              class="pr-3.5 cursor-pointer"
              @click="search = ''"
            >
              <Svg.clear class="fill-grey-600" style="width: 20px;" />
            </div>
          </template>
        </TextInput>
        <div
          v-if="search?.length > 0"
          class="body"
        >
          <div v-if="foundPlayers === null" class="loading">
            <Loader />
          </div>

          <div
            v-if="!foundPlayers?.length && foundPlayers !== null"
            class="empty"
          >
            <div class="content flex flex-col gap-4">
              <EmptyImage
                type="empty"
                :title="t('modal.search.not-found.title')"
                :subtitle="t('modal.search.not-found.subtitle')"
              />
            </div>
          </div>
          <div
            v-if="foundPlayers && foundPlayers.length"
            id="list"
            class="list"
          >
            <div
              v-for="player in foundPlayers"
              :key="player.steam_id"
              class="player-item"
              @click.prevent="() => ActivatePlayerDetailsModal(player.steam_id, player)"
            >
              <PlayerBox
                :player="player"
                :subtext="player.ip.includes(search) ? player.ip : player.steam_id"
                :search="[search]"
                class="w-full"
                disable-hover
              />
              <Svg.arrow class="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!search.length"
      class="clickable"
      @click="_modal.close()"
    />
  </div>
</template>

<style lang="scss" scoped>
$height: 400px;

$header-height: 60px;

.fake-wrapper {
  height: calc($height + 20px);
  width: 95vw;
  max-width: 460px;
  @apply overflow-y-hidden;

  .fake-modal-border {
    @apply bg-grey-900/15;
    width: 100%;
    .fake-modal {
      @apply bg-grey-900;
      @apply flex flex-col;
      @apply rounded-lg overflow-hidden;
    }
  }

  .clickable {
    @apply w-full;
    @apply cursor-pointer;
    height: calc($height - $header-height);
  }
}

.body {
  height: calc($height - $header-height);
  @apply border-t border-grey-1000;

  .loading {
    @apply h-full flex justify-center items-center;
  }

  .empty {
    @apply flex w-full h-full items-center justify-center; 
  }

  .list {
    @apply h-full py-3;
    @apply flex flex-col;
    @apply overflow-y-scroll;

    .player-item {
      @apply flex justify-between items-center px-5 py-3;
      @apply cursor-pointer;

      .arrow {
        @apply w-6;
        @apply fill-transparent;
      }

      &:hover {
        @apply bg-grey-850/50;

        .arrow {
          @apply fill-grey-200;
        }
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  @apply bg-white/10;
}
</style>