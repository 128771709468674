import DataLoader from "dataloader";
import { useCoreApi } from "../core.api";
import { ClientBrowseQueryDto, ClientDto, ClientInfoUpdate } from "./client.dto";
import { Axios } from "axios";

export const useCoreClientApi = (api: Axios) => {
  const browseBatchLoader = new DataLoader<number, ClientDto | undefined>(async (keys) => {
    const data = await browseRequest({ client_ids: keys as number[] });

    return keys.map(v => data.find(d => d.id === v));
  }, {
    batchScheduleFn: (callback) => {
      setTimeout(callback, 100);
    },
    maxBatchSize: 50,
  });

  const browseRequest = async (opts: ClientBrowseQueryDto) => {
    const result = await api.get<ClientDto[]>(`/client/browse`, {
      params: opts
    });

    return result.data;
  };

  const updateClient = async (update: ClientInfoUpdate) => {
    await api.put<void>(`/client/profile`, update);
  };

  return { browseBatchLoader, updateClient };
};