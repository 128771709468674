<script lang="ts">

  export type RccBanModalProps = {
    player: PlayerFullDto;
    bans: any[];
  };

  export const ActivateRccBanModal = async (player: PlayerFullDto, bans: any[]) => {
    const _modal = useModalsStore(); 

    _modal.open('rcc-bans', { player, bans });
  };
</script>

<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useTimeStore } from '@/stores/time.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';

  defineProps<RccBanModalProps & ModalInjectedProps>();

  const { t } = useLocaleStore();
  
  const _time = useTimeStore();
 
</script>

<template>
  <div class="rcc-ban-modal">
    <DividedCard>
      <template #header>
        <PlayerBox :player="player" />
      </template>

      <template #default>
        <div class="content">
          <div class="label">
            <p class="text-grey-50">
              {{ t('ban-rcc-data' ) }}
            </p>
            <p class="text-grey-600">{{ t('general.amount-st', { amount: bans.length}) }}</p>
          </div>

          <div class="container">
            <template v-for="ban in bans" :key="ban.banID">
              <div class="ban" :class="{ 'removed': ban.active !== true }">
                <div class="server-name">
                  <p class="text">{{ ban.serverName.replace('<i>', '').replace('</i>', '') }}</p>
                  <span v-if="ban.active !== true" class="unban">{{ t('modal.player.bans.ban.unban') }}</span>
                </div>
                <div class="subtitle">
                  <p class="tabular-nums">{{ _time.format(+ban.banDate * 1000, 'DD.MM.YYYY', true, true) }}</p>
                  <p class="text-grey-600">•</p>
                  <p class="truncate" :title="ban.reason">{{ ban.reason || t('ban-rcc-no-reason') }}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-2.5">
          <Button
            :text="t('general.close')"
            preset="default"
            :action="() => { injected.close() }"
          />
          <a
            :href="`https://rustcheatcheck.ru/panel/player/${player.steam_id}`"
            target="_blank"
          >
            <Button
              :text="t('ban-rcc-more')"
              preset="primary"
              :action="() => { }"
            />
          </a>
        </div>
      </template>
    </DividedCard>
  </div>
</template>

<style lang='scss' scoped>

.rcc-ban-modal {
  width: 95vw;
  max-width: 440px;

  .content {
    @apply flex flex-col gap-2;
    .label {
      @apply flex justify-between;
    }
    .container {
      @apply grid grid-cols-1 divide-y overflow-hidden; 
      @apply rounded-md;
      @apply divide-grey-800;

      .ban {
        @apply flex flex-col;
        @apply p-2;
        @apply bg-grey-850;

        .server-name {
          @apply flex gap-1 max-w-fit;

          .text {
            @apply text-grey-50 font-medium;
            @apply truncate;
          }

          .unban {
            @apply px-1;
            @apply text-sm;
            @apply bg-lime-500/10 text-lime-500/75;
            @apply rounded;
          }
        }
        .subtitle {
          @apply flex gap-1 items-center;
          @apply text-grey-400;
          @apply truncate;
        }

        &.removed {
          background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, #242424 20px, #242424 40px);

          .text, .subtitle  {
            @apply opacity-30;
          }
        }
      }
    }
  }
}
</style>