<script setup lang="ts">
  import { PlayerAlertJoinWithExternalBanMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { onMounted, ref } from 'vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { ActivateRccBanModal } from '../modals/RccBansModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  
  const props = defineProps<{
    meta: PlayerAlertJoinWithExternalBanMetaDto;
    date: number;
  }>();

  const { t } = useLocaleStore();

  const {generate} = useUnknownPlayer();
  const player = ref<PlayerFullDto>();
  
  onMounted(async () => {
    const result = await useCourtApi().player.browseFullBatchLoader.load(props.meta.steam_id);
    if (!result || !('steam_id' in result)) {
      player.value = generate(props.meta.steam_id);
      return;
    }
    player.value = result;
  });
</script>

<template>
  <PlayerAlertBase
    icon="player-alert-types/apple.spoiled.png"
    :date="date"
    :ready="!!player"
  >
    <template v-if="player">
      <span> {{ t('alert-join-with-external-ban-1') }}</span>

      <span 
        class="bright cursor-pointer" 
        @click="ActivatePlayerDetailsModal(player.steam_id)"
      > 
        {{ player.steam_name }} 
      </span>

      <span> {{ t('alert-join-with-external-ban-2') }} </span>

      <span 
        class="!text-orange-300 cursor-pointer" 
        @click="player ? ActivateRccBanModal(player, meta.bans) : undefined"
      >
        {{ t('alert-join-with-external-ban-3') }}
      </span>

      <span>{{ t('alert-join-with-external-ban-4') }}</span>
    </template>
  </PlayerAlertBase>
</template>