<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ServerFullDto } from '@/api/backend/court/servers/servers.dto';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import ServerCard from './components/ServerCard.vue';
  import { ActivateServerPairModal } from '@court/modals/server-pair/ServerPairModal.vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import Button from '@/components/Button.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useCourtStore } from '@court/stores/court.store';
  import Divider from '@/components/Divider.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useWindowSize } from '@vueuse/core';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { VueDraggableNext } from 'vue-draggable-next';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import BaseTip from '@panel/components/BaseTip.vue';

  const { t } = useLocaleStore();

  const _court = useCourtStore(); 
  const _adaptive = useAdaptiveStore();
  const _subscription = useCourtSubscriptionStore();
  const _permissions = usePermissionsStore();

  const { width } = useWindowSize();
  onMounted(async () => updateServers());

  const updateServers = async () => {
    servers.value = null;

    servers.value = await useCourtApi().servers.get();
  };

  const servers = ref<ServerFullDto[] | null>();

  const computedServerList = computed((): ((ServerFullDto | null)[]) => {
    if (!_court.court) {
      return new Array(2).fill(null);
    }

    if (!servers.value) {
      return new Array(_court.court.servers.filter(v => !v.deleted).length);
    }

    return [...servers.value].sort((a,b) => (a.order ?? 0) - (b.order ?? 0));
  });

  
  const perRow = computed(() => {
    if (width.value <= 600) {
      return 1;
    }
    /**
     * 
    if (width.value <= 1160) {
      return 2;
    }
    
    */
    return 2;
  });

  // узнает, сколько не хватает количеству картинок, чтобы делиться на perRow без остатка
  const amountForFullRow = computed(() => {
    const remainder = computedServerList.value.length % perRow.value;
    return remainder ? (perRow.value - remainder) : 0;
  });

  const canConnectServer = computed(() => {
    if (!computedServerList.value?.length || !_subscription.state) {
      return false;
    }

    return computedServerList.value.length < _subscription.state.current.limits.servers;
  });

  type MoveData<T> = { element: T | undefined, index: number, futureIndex: number, oldIndex: number, newIndex: number };
    
  type AllMoveData<T> = { draggedContext: MoveData<T>, relatedContext: MoveData<T> };

  const editOrder = async (data: { moved: MoveData<ServerFullDto>}) => {
    if (!data.moved) {
      return;
    }

    computedServerList.value.forEach((v, index) => v!.order = index);

    await useCourtApi().servers.editOrder(computedServerList.value.map(v => v?.id ?? 0));
  };

  const orderMiddleware = (data: AllMoveData<ServerFullDto>) => {
    if (!data.draggedContext.element || useAdaptiveStore().isMobile) {
      return false;
    }
     
    return null;
  };

  const addServer = () => {
    if (canConnectServer.value) {
      ActivateServerPairModal({ onUpdated: updateServers });
      return;
    }

    ActivateConfirmModal({
      type: 'warn',

      title      : t('cant-connect-server-limit-title'),
      description: t('cant-connect-server-limit-subtitle'),
    });
  };
</script>

<template>
  <TitledPage
    :top-title="t('servers.title')"
    :top-subtitle="t('servers.subtitle')"
  >
    <div class="servers-page">
      <template v-if="computedServerList?.length">
        <div class="flex items-center mb-5">
          <span class="text-head">
            {{ t('servers.servers') }}
          </span>
          <span
            v-if="_permissions.can(['Court_ServerManage'])"
            class="text-add" 
            @click="addServer"
          >{{ t('servers.add') }}</span>
        </div>

        <BaseTip
          v-if="_court.court?.servers.some(v => !v.updated) ?? false"
          type="warning"
          class="mb-5"
        >
          {{ t('servers.required-update') }}
        </BaseTip>

        <div>
          <VueDraggableNext
            :list="computedServerList"
            tag="transition-group"
            class="server-list"
            :move="orderMiddleware"
            :ghost-class="_adaptive.isMobile ? '' : 'ghost'"
            :disabled="_adaptive.isTouchDevice()"
            @change="editOrder"
          >
            <template 
              v-for="server in computedServerList"
              :key="server?.id"
            >
              <ServerCard
                :on-updated="updateServers"
                :loaded="!!servers?.length"
                :server="server ?? null"
              />
            </template>
            <template v-for="(_) in amountForFullRow" :key="_">
              <div class="empty" />
            </template>
          </VueDraggableNext>
        </div>
      </template>
      <template v-else>
        <div class="empty-list">
          <EmptyImage
            type="empty"
            :title="t('servers.empty.title')"
            :subtitle="t('servers.empty.subtitle')"
          />
          <Button
            v-if="_permissions.can(['Court_ServerManage'])"
            preset="primary"
            class="text-sm !py-1.5 !px-2"
            :text="t('servers.connect')"
            :action="() => { ActivateServerPairModal({ onUpdated: updateServers }) }"
          />
        </div>
      </template>
    </div>
    
    <template v-if="_permissions.isAdminOrOwner()">
      <Divider class="my-8" />

      <div class="page-tip">
        <p class="title">{{ t('servers.delete-warning.title') }}</p>
        <p class="subtitle">{{ t('servers.delete-warning.subtitle') }}</p>
      </div>
    </template>
  </TitledPage>
</template>

<style lang="scss" scoped>
$gap: 20px;
$per-row: v-bind('perRow');

.ghost {
  @apply opacity-0; 
}

.servers-page {
  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }
  .text-add {
    @apply ml-auto;
    @apply cursor-pointer;
    @apply text-primary-700;

    &:hover {
      @apply text-primary-600;
    }
  }

  .server-list {
    @apply grid;
    grid-template-columns: repeat($per-row, 1fr);
    gap: $gap;
  }

  .empty-list {
    @apply flex flex-col items-center gap-4 py-20;
    @apply rounded-md;
    background-image: radial-gradient(#272727 1px, #181818 1px);
    background-size: 18px 18px;
  }

  .empty {
    @apply rounded-md;
    @apply bg-grey-950;
  }
}
</style>
