/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/hougan/RustApp%20-%20Production/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: 'b55680ee6416340be9d0e0af89beb182'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: 'c9892f15-26b1-407d-a937-3eeb22d9dcf0'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface CentrifugoTroublesProperties {
  error: string;
}

export interface ClientBanTogetherProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  ban_id?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  target_client_id?: number;
}

export interface ClientCheckDiscordSeenProperties {
  check_contact: string;
}

export interface ClientFastSearchProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click, key |
   */
  by_action?: "click" | "key";
}

export interface ClientProjectCreateProperties {
  project_name: string;
  project_url: string;
}

export interface ClientServerDeleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  server_id?: number;
  server_name?: string;
}

export interface ClientServerPairCompleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | success, error |
   */
  result: "success" | "error";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  server_id?: number;
  server_name?: string;
}

export interface ClientServerPairStartProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  server_id?: number;
}

export interface ClientSignUpProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | classic, google |
   */
  type: "classic" | "google";
}

export interface ClientTagClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | check, ban |
   */
  tag_type: "check" | "ban";
}

export interface ClientUseContextMenuProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | player-box, report, chat-message |
   */
  target: "player-box" | "report" | "chat-message";
}

export interface CustomActionCreateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  preset_id?: number;
}

export interface CustomActionUseProperties {
  access: string;
}

export interface UseSearchProperties {
  variant: string;
}

export class CentrifugoTroubles implements BaseEvent {
  event_type = 'centrifugo_troubles';

  constructor(
    public event_properties: CentrifugoTroublesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientBanTogether implements BaseEvent {
  event_type = 'client_ban_together';

  constructor(
    public event_properties?: ClientBanTogetherProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientCheckDiscordSeen implements BaseEvent {
  event_type = 'client_check_discord_seen';

  constructor(
    public event_properties: ClientCheckDiscordSeenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientFastSearch implements BaseEvent {
  event_type = 'client_fast_search';

  constructor(
    public event_properties?: ClientFastSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientHandshake implements BaseEvent {
  event_type = 'client_handshake';
}

export class ClientProjectCreate implements BaseEvent {
  event_type = 'client_project_create';

  constructor(
    public event_properties: ClientProjectCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientProjectSwitch implements BaseEvent {
  event_type = 'client_project_switch';
}

export class ClientServerDelete implements BaseEvent {
  event_type = 'client_server_delete';

  constructor(
    public event_properties?: ClientServerDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientServerPairComplete implements BaseEvent {
  event_type = 'client_server_pair_complete';

  constructor(
    public event_properties: ClientServerPairCompleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientServerPairStart implements BaseEvent {
  event_type = 'client_server_pair_start';

  constructor(
    public event_properties?: ClientServerPairStartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientSignUp implements BaseEvent {
  event_type = 'client_sign_up';

  constructor(
    public event_properties: ClientSignUpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientTagClicked implements BaseEvent {
  event_type = 'client_tag_clicked';

  constructor(
    public event_properties: ClientTagClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientUseContextMenu implements BaseEvent {
  event_type = 'client_use_context_menu';

  constructor(
    public event_properties: ClientUseContextMenuProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomActionCreate implements BaseEvent {
  event_type = 'custom_action_create';

  constructor(
    public event_properties?: CustomActionCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomActionUse implements BaseEvent {
  event_type = 'custom_action_use';

  constructor(
    public event_properties: CustomActionUseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UseSearch implements BaseEvent {
  event_type = 'use_search';

  constructor(
    public event_properties: UseSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * centrifugo_troubles
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/centrifugo_troubles)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   */
  centrifugoTroubles(
    properties: CentrifugoTroublesProperties,
    options?: EventOptions,
  ) {
    return this.track(new CentrifugoTroubles(properties), options);
  }

  /**
   * client_ban_together
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_ban_together)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. ban_id)
   * @param options Amplitude event options.
   */
  clientBanTogether(
    properties?: ClientBanTogetherProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientBanTogether(properties), options);
  }

  /**
   * client_check_discord_seen
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_check_discord_seen)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. check_contact)
   * @param options Amplitude event options.
   */
  clientCheckDiscordSeen(
    properties: ClientCheckDiscordSeenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientCheckDiscordSeen(properties), options);
  }

  /**
   * client_fast_search
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_fast_search)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. by_action)
   * @param options Amplitude event options.
   */
  clientFastSearch(
    properties?: ClientFastSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientFastSearch(properties), options);
  }

  /**
   * client_handshake
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_handshake)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clientHandshake(
    options?: EventOptions,
  ) {
    return this.track(new ClientHandshake(), options);
  }

  /**
   * client_project_create
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_project_create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. project_name)
   * @param options Amplitude event options.
   */
  clientProjectCreate(
    properties: ClientProjectCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientProjectCreate(properties), options);
  }

  /**
   * client_project_switch
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_project_switch)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clientProjectSwitch(
    options?: EventOptions,
  ) {
    return this.track(new ClientProjectSwitch(), options);
  }

  /**
   * client_server_delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_server_delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. server_id)
   * @param options Amplitude event options.
   */
  clientServerDelete(
    properties?: ClientServerDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientServerDelete(properties), options);
  }

  /**
   * client_server_pair_complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_server_pair_complete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. result)
   * @param options Amplitude event options.
   */
  clientServerPairComplete(
    properties: ClientServerPairCompleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientServerPairComplete(properties), options);
  }

  /**
   * client_server_pair_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_server_pair_start)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. server_id)
   * @param options Amplitude event options.
   */
  clientServerPairStart(
    properties?: ClientServerPairStartProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientServerPairStart(properties), options);
  }

  /**
   * client_sign_up
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_sign_up)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  clientSignUp(
    properties: ClientSignUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientSignUp(properties), options);
  }

  /**
   * client_tag_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_tag_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. tag_type)
   * @param options Amplitude event options.
   */
  clientTagClicked(
    properties: ClientTagClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientTagClicked(properties), options);
  }

  /**
   * client_use_context_menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/client_use_context_menu)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. target)
   * @param options Amplitude event options.
   */
  clientUseContextMenu(
    properties: ClientUseContextMenuProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientUseContextMenu(properties), options);
  }

  /**
   * custom_action_create
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/custom_action_create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. preset_id)
   * @param options Amplitude event options.
   */
  customActionCreate(
    properties?: CustomActionCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomActionCreate(properties), options);
  }

  /**
   * custom_action_use
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/custom_action_use)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. access)
   * @param options Amplitude event options.
   */
  customActionUse(
    properties: CustomActionUseProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomActionUse(properties), options);
  }

  /**
   * use_search
   *
   * [View in Tracking Plan](https://data.amplitude.com/hougan/RustApp%20-%20Production/events/main/latest/use_search)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. variant)
   * @param options Amplitude event options.
   */
  useSearch(
    properties: UseSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new UseSearch(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
