import { useLocaleStore } from "@/stores/locale.store";
import axios from "axios";
import { ref } from "vue";

export const usePluginDownload = () => {
  const pluginDownloaded = ref<boolean>(false);

  const getLink = (version: 'default' | '236' | '261' | '266') => {
    switch (version) {
      case 'default': {
        return `https://raw.githubusercontent.com/rust-app-io/plugin/main/plugins/rust/RustApp.cs`;
      }
      case '236': {
        return `https://raw.githubusercontent.com/rust-app-io/plugin/main/plugins/rust/devblog/236/RustApp.cs`;
      }
      case '261': {
        return `https://raw.githubusercontent.com/rust-app-io/plugin/main/plugins/rust/devblog/261/RustApp.cs`;
      }
      case '266': {
        return `https://raw.githubusercontent.com/rust-app-io/plugin/main/plugins/rust/devblog/266/RustApp.cs`;
      }
    }

    return `https://github.com/rust-app-io/plugin/blob/main/plugins/rust/RustApp.cs`;
  };
  
  const pluginDownload = async (version: 'default' | '236' | '261' | '266') => {
    const response = await axios.get(getLink(version), {
      responseType: 'blob'
    });


    const { lang } = useLocaleStore();
    if (lang === 'en') {
      const text = await (response.data as Blob).text();

      response.data = new Blob([text.replace('#define RU', '')], { type: 'text/plain' });
    }

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'RustApp.cs'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    pluginDownloaded.value = true;
  };
  
  const extensionDownload = async (ext: string) => {
    const url = `https://raw.githubusercontent.com/rust-app-io/plugin/main/plugins/rust/extensions/${ext}.cs`;

    const response = await axios.get(url, {
      responseType: 'blob'
    });

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${ext}.cs`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    pluginDownloaded.value = true;
  };

  return { pluginDownloaded, pluginDownload, extensionDownload };
};