<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import CustomBanTab from './tabs/CustomBanTab.vue';
  import CustomActionsTab from './tabs/CustomActionsTab.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import Loading from '@/components/Loading.vue';
  import CustomAPI from './tabs/CustomAPI.vue';
  import { TabbedNavigationHeader } from '@/components/tabbed-navigation/tabbed-navigation.dto';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import TabbedNavigation from '@/components/tabbed-navigation/TabbedNavigation.vue';
  import CustomReportsTab from './tabs/CustomReportsTab.vue';
  import CustomChecksTab from './tabs/CustomChecksTab.vue';


  const _court = useCourtStore();
  const { t } = useLocaleStore();

  type SettingsHeaders = 'bans' | 'checks' | 'restrictions' | 'reports' | 'custom-actions' | 'api';
  
  const headers = computed<TabbedNavigationHeader<SettingsHeaders>[]>(() =>[ {
    key : 'bans',
    text: t('customization.bans.title'),
  }, {
    key : 'checks',
    text: t('customization.checks.title')
  }, {
    key : 'reports',
    text: t('customization.reports.title')
  }, {
    key : 'restrictions',
    text: t('customization.restrictions.title')
  }, {
    key : 'custom-actions',
    text: t('customization.actions.title'),
  },{
    key : 'api',
    text: t('customization.api.title'),
  },]);

</script>

<template>
  <TitledPage
    :top-title="t('customization.title')"
    :top-subtitle="t('customization.subtitle')"

    no-divider
  >
    <TabbedNavigation :headers="headers">
      <template #bans>
        <div>
          <Loading :can-view="!!_court.court" />
        
          <CustomBanTab v-if="_court.court" />
        </div>
      </template>
      <template #reports>
        <div>
          <Loading :can-view="!!_court.court" />

          <CustomReportsTab v-if="_court.court" />
        </div>
      </template>
      <template #checks>
        <div>
          <Loading :can-view="!!_court.court" />

          <CustomChecksTab v-if="_court.court" />
        </div>
      </template>
      <template #custom-actions>
        <div>
          <Loading :can-view="!!_court.court" />

          <CustomActionsTab v-if="_court.court" />
        </div>
      </template>
      <template #api>
        <div>
          <Loading :can-view="!!_court.court" />

          <CustomAPI v-if="_court.court" />
        </div>
      </template>
    </TabbedNavigation>
  </TitledPage>
</template>

<style lang='scss' scoped>
.tab {
  @apply pt-8;
}
.section {
  @apply flex flex-col gap-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }

  :deep(.settings) {
    @apply divide-white/5;
  }
}
.settings-container {
  &:deep(.settings) {
    &:not(.transparent) {
      @apply bg-grey-900;
    }
  }
}
</style>