<script setup lang="ts">
  import { useCoreApi } from '@/api/backend/core/core.api';
  import Button from '@/components/Button.vue';
  import Divider from '@/components/Divider.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useSteamAuthStart } from '@src/composable/steam-connect.composable';
  import { SignUpData } from '@src/views/client/types/auth.dto';
  import { googleTokenLogin } from 'vue3-google-login';

  const { t } = useLocaleStore();
  const props = defineProps<{ confirm: (token: string) => Promise<unknown> }>();
  const emits = defineEmits<{
    (e: 'signUpComplete', data: SignUpData): void
  }>();

  const onGoogleConfirmed = async () => {
    const { access_token } = await googleTokenLogin();

    const { email, exists } = await useCoreApi().auth.googleValidate(access_token);
    
  
    if (exists) {
      const token = await useCoreApi().auth.googleSignIn(access_token);

      await props.confirm(token);
    } else {
      emits('signUpComplete', { 
        google       : true, 
        email_preview: email, 
        google_token : access_token 
      });
    }
  };

  const onSteamConfirmed = async () => {
    const query = await useSteamAuthStart();

    const data = await useCoreApi().auth.steamValidate(query);
  
    if (data.exists) {
      const token = await useCoreApi().auth.steamSignIn(query);

      await props.confirm(token);
    } else {
      emits('signUpComplete', {
        steam: true,
        
        steam_query : query,
        steam_id    : data.steam_id,
        steam_name  : data.steam_name,
        steam_avatar: data.steam_avatar
      });
    }
  };

  const onDiscordConfirmed = async () => {
    useRuntimeNotification('warning', t('auth-sign-in-discord-not-available'));  
  };
</script>

<template>
  <Divider :text="t('general.or')" /> 

  <div class="other-methods">
    <Button
      preset="default"
      class="w-full !py-2.5"
      :action="onGoogleConfirmed"
    >
      <Svg.google_2 />
    </Button>
    
    <Button
      preset="default"
      class="w-full !py-2.5"
      :action="onSteamConfirmed"
    >
      <Svg.steam />
    </Button>

    <Button
      preset="default"
      class="w-full !py-2.5"
      :action="onDiscordConfirmed"
    >
      <Svg.discord />
    </Button>
  </div>
</template>

<style lang="scss" scoped>
.other-methods {
  @apply flex gap-5;
  
  .btn {
    @apply w-full h-[42px];
    @apply rounded-md;

    svg {
      @apply w-5.5;
    }

    &.google {
      @apply bg-grey-100;
      @apply fill-grey-50;
    }

    &.steam {
      @apply bg-[#0B88B9];
      @apply fill-grey-50;
    }

    &.discord {
      @apply bg-[#5865F2];
      @apply fill-grey-50;
    }
  }
}
</style>