<script setup lang='ts'>
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import DiscordPage from './tabs/DiscordPage.vue';
  import RccPage from './tabs/RccPage.vue';
  import TabbedNavigation from '@/components/tabbed-navigation/TabbedNavigation.vue';
  import { TabbedNavigationHeader } from '@/components/tabbed-navigation/tabbed-navigation.dto';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import BanSyncPage from './tabs/BanSyncPage.vue';


  const { t } = useLocaleStore();

  type IntegrationHeaders = 'discord' | 'rcc' | 'ban-sync';

  const headers = computed<TabbedNavigationHeader<IntegrationHeaders>[]>(() =>[{
    key : 'discord',
    text: t('integrations.discord.title'),
  }, {
    key : 'ban-sync',
    text: t('integrations.ban-sync.title')
  }, {
    key : 'rcc',
    text: t('integrations.rcc.title'),
  }]);

</script>

<template>
  <TitledPage
    :top-title="t('integrations.title')"
    :top-subtitle="t('integrations.subtitle')"
    no-divider
  >
    <TabbedNavigation
      :headers="headers"
    >
      <template #discord>
        <div>
          <DiscordPage />
        </div>
      </template>
      <template #ban-sync>
        <div>
          <BanSyncPage />
        </div>
      </template>
      <template #rcc>
        <div>
          <RccPage />
        </div>
      </template>
    </TabbedNavigation>
  </TitledPage>
</template>

<style lang='scss' scoped>
.tab {
  @apply pt-8;
}
</style>