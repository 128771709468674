<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import Divider from '@/components/Divider.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import SwitchDropdown from '@/components/switch/SwitchDropdown.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { CourtOptionsDto } from '@/api/backend/court/court/court.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtStore } from '@court/stores/court.store';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { computed, onMounted, ref } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const { t } = useLocaleStore();
  const _court = useCourtStore();
  const _permissions = usePermissionsStore();

  type AvailablePeriodIgnore = '0' | '1' | '3' | '7' | '30' | '365' | '10000';
  type AvailablePeriodDays = '1' | '3' | '7' | '14';

  const periodsIgnore: Record<AvailablePeriodIgnore, string> = {
    '0'    : t('customization.bans.other.ignore.no'),
    '1'    : t('customization.bans.other.ignore.1'),
    '3'    : t('customization.bans.other.ignore.3'),
    '7'    : t('customization.bans.other.ignore.7'),
    '30'   : t('customization.bans.other.ignore.30'),
    '365'  : t('customization.bans.other.ignore.365'),
    '10000': t('customization.bans.other.ignore.forever'),
  };

  const periodsDays: Record<AvailablePeriodDays, string> = {
    '1' : t('customization.bans.other.ignore.1'),
    '3' : t('customization.bans.other.ignore.3'),
    '7' : t('customization.bans.other.ignore.7'),
    '14': t('customization.bans.other.ignore.14'),
  };

  const periodsDropdownIgnore = computed((): ExtendedMenuItem[] => {
    return Object.entries(periodsIgnore).map(([key, value]) => {
      return {
        label : value,
        active: () => options.ignore_reports_after_check === +key,
        action: () => {
          options.ignore_reports_after_check = +key;
        },
      };
    });
  });

  const periodsDropdownDays = computed((): ExtendedMenuItem[] => {
    return Object.entries(periodsDays).map(([key, value]) => {
      return {
        label : value,
        active: () => options.ignore_reports_after_days === +key,
        action: () => {
          options.ignore_reports_after_days = +key;
        },
      };
    });
  });

  const { options } = _court.court!;
  const original = ref<CourtOptionsDto>();

  onMounted(() => {
    original.value = JSON.parse(JSON.stringify(options));
  });

  const reportsDelete = async () => {
    if (!_permissions.can(['Court_ReportDeleteGlobal'])) {
      useRuntimeNotification(`warning`, t('general.no-access'));
      return;
    }

    ActivateConfirmModal({
      type         : 'warn',
      title        : t('reports.delete-all.warn.title'),
      description  : t('reports.delete-all.warn.subtitle'),
      beforeConfirm: async () => { await useCourtApi().report.deleteAll(); },
      onConfirmed  : () => { useRuntimeNotification('success', t('reports.delete-all.success')); }
    });
  };

</script>

<template>
  <div class="section">
    <p class="text-head">
      {{ t('general.common-parameters') }}
    </p> 
    <div class="section">
      <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
        <SwitchText
          v-model="options.delete_reports_after_ban"
          :header="t('customization.bans.other.after-ban.header')"
          :placeholder="t('customization.bans.other.after-ban.placeholder')"
        />
        <SwitchText
          v-model="options.delete_reports_after_check"
          :header="t('customization.bans.other.after-check.header')"
          :placeholder="t('customization.bans.other.after-check.placeholder')"
        />
        <SwitchDropdown
          v-model="options.ignore_reports_after_days"
          :opts="periodsDropdownDays"
          :choosed="periodsDays[options.ignore_reports_after_days.toString() as AvailablePeriodDays]"
          :header="t('customization.bans.other.ignore-days.header')"
          :placeholder="t('customization.bans.other.ignore-days.placeholder')"
        />
        <SwitchDropdown
          v-model="options.ignore_reports_after_check"
          :opts="periodsDropdownIgnore"
          :choosed="periodsIgnore[options.ignore_reports_after_check.toString() as AvailablePeriodIgnore]"
          :header="t('customization.bans.other.ignore.header')"
          :placeholder="t('customization.bans.other.ignore.placeholder')"
        />
      </SettingsContainer>
    </div>
  </div>

  <Divider class="my-8" />
  
  <div class="section">
    <p class="text-head">
      {{ t('customization.reports-list.title') }}
    </p>
    <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
      <div class="destructive-action" @click="reportsDelete">
        <div class="switch-text">
          <span class="title">{{ t('customization.report-delete-title') }}</span>
          <span class="subtitle">{{ t('customization.report-delete-subtitle') }}</span>
        </div>
        <Svg.arrow class="arrow" />
      </div>
    </SettingsContainer>
  </div>
</template>

<style lang="scss" scoped>
.row {
  @apply flex justify-end;
}

.section {
  @apply flex flex-col gap-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }
}

.btn-unban {
  @apply h-fit mr-1.5 flex-shrink-0 p-0;
  @apply text-sm text-red-500 hover:text-red-400;
}
</style>