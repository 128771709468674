<script lang="ts">
  export type BanDateUnits = "hour" | "day" | "month";
  export type BanUnitSuggest = { 
    name: BanDateUnits, 
    text: string
  }

  export type BanDate = {
    amount: number;
    units: BanUnitSuggest
  }
</script>

<script setup lang="ts">
  import { defineAsyncComponent } from 'vue';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ref, watch } from 'vue';
  import { onMounted } from 'vue';
  import Calendar from 'primevue/calendar';
  import dayjs from 'dayjs';

  const { t } = useLocaleStore();

  const SelectIcon = defineAsyncComponent(() => import('@src/assets/icons/select.svg'));
  
  const availableUnits: Record<BanDateUnits, string> = {
    hour : t('modal.ban.general.date.hour'),
    day  : t('modal.ban.general.date.day'),
    month: t('modal.ban.general.date.month'),
  };

  const suggester = () => {
    return Object.entries(availableUnits).map<BanUnitSuggest>(([name, text]) => {
      return {
        name: name as BanDateUnits,
        text
      };
    });
  };

  const props = defineProps<{ update?: () => unknown; edit: boolean; }>();
  const expired = defineModel<number | undefined>();

  onMounted(() => {
    if (expired.value) {
      permanent.value = false;
    }
  });


  const concreteDay = ref(new Date(Date.now() - 65 * 1_000));
  const permanent = ref<boolean>(true);
  const interval = ref<BanDate>({
    amount: 30,
    units : suggester()[1]
  });

  // Вызываем апдейт чтобы высоту подстроить и обновляем значение экспайреда
  watch(() => permanent.value, (v) => {
    props.update?.();

    expired.value = getExpiredAt(v, interval.value);
  });

  // Обновленияем значение экспайреда
  watch(() => interval.value, (v) => {
    expired.value = getExpiredAt(permanent.value, v);
  }, { deep: true });

  // Обновленияем значение экспайреда
  watch(() => concreteDay.value, (v) => {
    expired.value = +v;
  }, { deep: true });

  const getExpiredAt = (permanent: boolean, date: BanDate) => {
    if (permanent) {
      return undefined;
    }

    switch (date.units.name) {
      case 'day': {
        return Date.now() + date.amount * 24 * 60 * 60 * 1_000;
      }
      case 'month': {
        return Date.now() + date.amount * 30 * 24 * 60 * 60 * 1_000;
      }
      case 'hour': {
        return Date.now() + date.amount * 60 * 60 * 1_000;
      }
    }
  };

  const isOriginalDate = ref<boolean>(false);
  onMounted(() => {
    if (expired.value) {
      concreteDay.value = new Date(expired.value);
      isOriginalDate.value = true;
    }
  });

  const refCalendar = ref<InstanceType<typeof Calendar>>();
</script>

<template>
  <div>
    <SwitchText
      v-model="permanent"
      :header="t('modal.ban.general.permanent.title')"
    />

    <div
      v-if="!permanent"
      class="flex gap-0.5 mt-2.5 w-full"
    >
      <template v-if="!edit || !isOriginalDate">
        <TextInput
          v-model="interval.amount"
          :placeholder="t('modal.ban.general.date.subtitle')"
          class="expired-input flex-1"
          no-border
          type="number"
        />
        <SuggesterInput
          v-model="interval.units"
          :icon="SelectIcon"
          disable-input
          class="!rounded-s-none !p-2 date-suggester"
          style="min-width: 170px;"
          :suggester="suggester"
          insta-suggest
        >
          <template #default="{ value }">
            <p>{{ value.text }}</p>
          </template>
          <template #suggest="{ value }">
            <div class="suggest">
              <p>{{ value.text }}</p>
            </div>
          </template>
        </SuggesterInput>
      </template>
      <template v-else>
        <Calendar
          ref="refCalendar"
          v-model="concreteDay"
          selection-mode="single"
          :manual-input="false"
          class="!w-full !h-[40px]"
          input-class="cursor-pointer !bg-grey-1000 !h-[40px] rounded-md !w-full !text-grey-50"
          date-format="d MM yy"
          :min-date="dayjs().toDate()"
          lang="ru"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.date-suggester {
  .input-box {
    .value {
      background: transparent !important;
    }
  }
}
.expired-input {
  .input-box {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
</style>