<script setup lang="ts">
  import { PlayerAllStatsDto, PlayerEntityDto, PlayerFullDto, PlayerKillsStats, PlayerTagDto } from '@/api/backend/court/player/player.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, ref } from 'vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useCourtStore } from '@court/stores/court.store';
  import { CourtRoutes } from '@court/court.routes';
  import { useRouter } from 'vue-router';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { usePermissionsStore } from '@panel/stores/permissions.store';

  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import PlayerDetailsTag from '../../PlayerDetailsTag.vue';

  import Skeleton from '@/components/Skeleton.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { ampli } from '@src/ampli';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import dayjs from 'dayjs';
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue';
  import PlayerDetailsTableCard from '../../table/PlayerDetailsTableCard.vue';
  import PlayerDetailsTableCardListItem from '../../table/PlayerDetailsTableCardListItem.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useIntervalFn } from '@vueuse/core';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<{ 
    entity: PlayerEntityDto | null, 
    player: PlayerFullDto, 
    isMobile: boolean,
    team: PlayerFullDto[] | null,
    
    lastCheck: CheckDto | undefined, 
    activeBan: BanDto | undefined 
  }>();

  const _permissions = usePermissionsStore();

  const _time = useTimeStore();
  const _subscription = useCourtSubscriptionStore();
  const _router = useRouter();
  const _panel = usePanelStore();
  const _court = useCourtStore();
  const online = computed(() => {
    return props.player?.status === 'online' || props.player?.status === 'afk';
  });

  const computedTags = computed<PlayerTagDto[]>(() => {
    const tags: PlayerTagDto[] = [];

    if (props.activeBan && props.player.status === 'offline') {
      tags.push({
        icon   : '⛔️',
        text   : t('modal.player.general.tags.banned'),
        tooltip: `${props.activeBan.reason}`,
        onClick: () => {
          ActivateBanViewModal({ ban: props.activeBan, banId: props.activeBan?.id ?? 0 });

          ampli.clientTagClicked({ tag_type: 'ban' });
        }
      });
    }

    if (!props.team?.length) {
      tags.push({
        icon: '👶',
        text: t('modal.player.general.tags.solo'),
      });
    }
    if (props.team?.length == 1) {
      tags.push({
        icon: '👭',
        text: t('modal.player.general.tags.duo'),
      });
    }
    if (props.team?.length == 2) {
      tags.push({
        icon: '👪',
        text: t('modal.player.general.tags.trio'),
      });
    }
    if ((props.team?.length ?? 0) > 2) {
      tags.push({
        icon: '️‍👩‍👩‍👧‍👧',
        text: t('modal.player.general.tags.clan'),
      });
    }

    if (props.player.ignore_reports_until && Date.now() < props.player.ignore_reports_until) {
      const until = dayjs(props.player.ignore_reports_until).year() > 2030 ? t('bans.forever-tag') : `${t('tag-mute-reports-tag')} ${_time.format(props.player.ignore_reports_until, 'DD.MM.YY HH:mm')}`;

      tags.push({
        icon   : '🔇',
        text   : t('modal.player.general.tags.ignore-reports'),
        tooltip: until
      });
    }

    if (props.entity?.raid.value) {
      tags.push({
        icon: '📛',
        text: t('modal.player.general.tags.raidblock')
      });
    }

    const moderator = _panel.projectClients.find(v => v.client.steam?.steam_id == props.player.steam_id);
    if (moderator) {
      tags.push({
        icon   : '👨🏼‍💻',
        text   : t('modal.player.general.tags.moderator'),
        onClick: () => ActivateClientDetailsModal(moderator.client.tag, moderator.client)
      });
    }

    if ((props.player.steam_data?.hours_total || Infinity) < 100 && props.player.steam_data?.hours_total != 0) {
      tags.push({
        icon: '🕙',
        text: t('modal.player.general.tags.hours')
      });
    }

    if (props.player.ip_details?.proxy) {
      tags.push({
        icon: '🌐',
        text: t('modal.player.general.tags.vpn')
      });
    }

    if (props.player.last_no_license) {
      tags.push({
        icon: '‍☠️',
        text: t('modal.player.general.tags.nosteam')
      });
    }

    if ((props.player.steam_data?.ban_data?.vac_ban.count ?? 0) >= 1) {
      tags.push({
        icon: '‍☢️',
        text: t('modal.player.general.tags.vac')
      });
    }

    if ((props.player.steam_data?.ban_data?.game_ban.count ?? 0) >= 1) {
      tags.push({
        icon: '‍☣️',
        text: t('modal.player.general.tags.gameban')
      });
    }

    if (props.player.last_language != 'unknown') {
      tags.push({
        icon   : '‍🌍',
        text   : props.player.last_language.toUpperCase(),
        tooltip: `Выбранный в игре язык`,
      });
    }

    if (props.entity?.is_alive != undefined && !props.entity?.is_alive) {
      tags.push({
        icon: '‍💀',
        text: t('modal.player.general.tags.dead')
      });
    }

    if (props.lastCheck && props.lastCheck.status === 'FinishedClear') {
      const fromNow = _time.fromNow(props.lastCheck.created_at);

      tags.push({
        icon   : '✅',
        text   : t('modal.player.general.tags.checked', { fromNow }),
        tooltip: _time.format(props.lastCheck.created_at, 'DD.MM.YY # HH:mm', true, true),
        onClick: () => {
          ampli.clientTagClicked({ tag_type: 'check' });

          _router.push({ name: CourtRoutes.Checks, params: { id: props.player.last_check_id} });
        }
      });
    }
    
    if (props.player.active_check_id) {
      tags.push({
        icon   : '🔎',
        text   : t('modal.player.general.tags.checking'),
        onClick: () => {
          ampli.clientTagClicked({ tag_type: 'check' });

          _router.push({ name: CourtRoutes.Checks, params: { id: props.player.active_check_id} });
        }
      });
    }

    return tags;
  });

  const serverText = computed(() => {
    switch (props.player?.status) {
      case 'online': {
        return t('modal.player.general.about.server-online');
      }
      case 'offline': {
        return t('modal.player.general.about.server-offline');
      }
      case 'joining': {
        return t('modal.player.general.about.server-joining');
      }
      case 'queued': {
        return t('modal.player.general.about.server-queued');
      }
      case 'unstable': {
        return t('modal.player.general.about.server-offline');
      }
    }

    return t('modal.player.general.about.server-online');
  });

  const lastHomeText = computed(() => {
    if (!props.entity?.home.time) {
      return '—';
    }
    if (props.entity.home.value) {
      return t('general.now');
    }
    return _time.fromNow(props.entity.home.time ?? 0, true);
  });

  const steamHoursText = computed(() => {
    if (props.player.steam && (props.player.steam_data?.hours_total ?? 0) > 0) {
      return (props.player.steam_data?.hours_total ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return t('general.unknown');
  });
  
  const isRecentlyBanned = computed(() => props.player.steam_data?.ban_data?.days_since_last_ban !== undefined && props.player.steam_data.ban_data.days_since_last_ban > 0);

  const banInfo = computed(() => {
    const gameBanCount = props.player.steam_data?.ban_data.game_ban.count ?? 0;
    const vacBanCount = props.player.steam_data?.ban_data.vac_ban.count ?? 0;

    if (gameBanCount === 0 && vacBanCount === 0) {
      return t('modal.player.general.steam.no_bans');
    }

    return `${gameBanCount} / ${vacBanCount}`;
  });
  
  const statsData = ref<PlayerKillsStats>();

  onMounted(async () => {
    if (!!_subscription.needSubscription('PlayerStatistics') && _permissions.can(['Court_PlayerStatsRead'])) {
      return;
    }

    try {
      statsData.value = await useCourtApi().player.getStatsSmall(props.player.steam_id, Date.now() - 7 * 24 * 60 * 60 * 1_000, Date.now());
    }
    catch {

    }
  });


  const computedLastMovedTime = computed(() => {
    _time.now;

    if (!props.entity || !props.entity.position.time) {
      return '—';
    }

    return !props.entity.last_moved_seconds ? '—' : _time.fromNow(+_time.now - props.entity.last_moved_seconds, true);
  });

  useIntervalFn(() => {
    if (!props.entity?.last_moved_seconds) {
      return;
    }

    Object.assign(props.entity, {
      last_moved_seconds: new Date(+props.entity.last_moved_seconds + 100)
    });
  }, 100);
</script>

<template>
  <ModalMenuExtendedPageLayout>
    <div class="general">
      <div class="tags">
        <div
          v-for="tag in computedTags"
          :key="tag.id"
        >
          <PlayerDetailsTag
            :tag="tag"
          />
        </div>
      </div>
      
      <!-- Состояние -->
      <PlayerDetailsTableCard
        v-if="player.status !== 'offline' && player.status !== 'queued' && player.status !== 'joining'"
        :per-row="isMobile ? 2 : 4"
        :max-rows="isMobile ? 2 : 1"
        :amount="4"
        :title="t('modal.player.general.play-info.state-title')"
      >
        <template #col0>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.play-info.last-move')" 
            :subtitle="computedLastMovedTime"
          />
        </template>
        <template #col1>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.play-info.last-home')" 
            :subtitle="lastHomeText"
          />
        </template>
        <template #col2>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.play-info.location')"
          >
            <template #subtitle>
              <p class="truncate">{{ _permissions.can(['Court_PlayerViewGameInfo']) ? entity?.coords.value ?? '—' : t('hidden') }}</p>
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col3>
          <TooltipBase trigger="hover" distance="6"> 
            <PlayerDetailsTableCardListItem 
              :title="t('modal.player.general.play-info.session-time')" 
              :subtitle="entity ? _time.difference(entity?.connected_at ?? 0, _time.now, true) : '—'"
            />
            <template v-if="entity?.connected_at" #content> 
              <div class="tooltip-text"> 
                <p>{{ t('modal.player.general.play-info.session-time-tooltip', {date: _time.format(entity?.connected_at, 'DD.MM.YYYY # HH:mm', false, true)}) }}</p> 
              </div> 
            </template> 
          </TooltipBase>
        </template>
      </PlayerDetailsTableCard>

      <!-- Статистика -->
      <PlayerDetailsTableCard
        v-if="!_subscription.needSubscription('PlayerStatistics')"
        :per-row="isMobile ? 2 : 4"
        :max-rows="isMobile ? 2 : 1"
        :amount="4"
        :title="t('modal.player.general.stats')"
      >
        <template #col0>
          <PlayerDetailsTableCardListItem 
            :title="'K/D'" 
            :subtitle="statsData?.kda?.toFixed(2) ?? '—'"
          />
        </template>
        <template #col1>
          <PlayerDetailsTableCardListItem 
            :title="t('player-stats-general-kills')" 
            :subtitle="statsData?.kills.toString() ?? '—'"
          />
        </template>
        <template #col2>
          <PlayerDetailsTableCardListItem 
            :title="t('player-stats-general-in-head')" 
            :subtitle="statsData?.bone_kills['head']?.toString() ?? '—'"
          />
        </template>
        <template #col3>
          <PlayerDetailsTableCardListItem 
            :title="t('player-stats-general-deaths')" 
            :subtitle="statsData?.deathes.toFixed(0) ?? '—'"
          />
        </template>
      </PlayerDetailsTableCard>

      <!-- Команда -->
      <PlayerDetailsTableCard
        v-if="(team?.length ?? player.player_team?.teammates?.length ?? 0) > 0"
        :per-row="isMobile ? 2 : 2"
        :max-rows="isMobile ? 1 :2"
        :amount="team?.length ?? player.player_team?.teammates?.length ?? 0"
        :title="t('modal.player.general.team')"
      >
        <template
          v-for="(teammate, _) in (team ?? new Array(player.player_team?.teammates?.length ?? 0).fill(null))"
          :key="teammate?.steam_id ?? Math.random()"
          #[`col${_}`]
        >
          <PlayerBox
            :player="teammate"
            class="w-full"
            disable-hover
            @click="() => teammate ? ActivatePlayerDetailsModal(teammate.steam_id, teammate) : null"
          />
        </template>
      </PlayerDetailsTableCard>

      <!-- Об игроке -->
      <PlayerDetailsTableCard
        :per-row="2"
        :amount="6"
        :title="t('modal.player.general.about.title')"
      >
        <template #col0>
          <PlayerDetailsTableCardListItem 
            :title="serverText" 
            :subtitle=" _court.serverNameById(player.server_id)"
          />
        </template>
        <template #col1>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.about.steam-id')" 
          >
            <template #subtitle>
              <div class="actions" @click="useCopyClipboard(player.steam_id, true)">
                <p class="truncate">{{ player.steam_id }}</p>
                <Svg.copy />
              </div>
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col2>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.about.first-seen')" 
            :subtitle="_time.format(player.created_at, 'DD.MM.YYYY # HH:mm', true, true)"
          />
        </template>
        <template #col3>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.about.last-ip')" 
          >
            <template #subtitle>
              <div class="ip hyperlink" @click="ActivateIpDetailsModal(player.ip, player.ip_details)">
                <p class="truncate">{{ player.ip }}</p>
                <Svg.open />
              </div>
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col4>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.about.country-city')" 
          >
            <template #subtitle>
              <div v-if="player.ip_details" class="country">
                <img
                  v-if="player.ip_details.country_code"
                  class="flag"
                  :src="`https://hatscripts.github.io/circle-flags/flags/${player.ip_details.country_code?.toLowerCase()}.svg`"
                >
                <p class="truncate">
                  {{ player.ip_details.country_name ?? 'Unknown country' }}, {{ player.ip_details.city ?? 'Unknown city' }}
                </p>
              </div>
              <div v-else class="flex gap-1">
                <Skeleton style="width: 19px;" class="skeleton !rounded-half" />
                <Skeleton class="skeleton !max-w-12" />
                <Skeleton class="skeleton !max-w-24" />
              </div>
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col5>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.about.provider')"
          >
            <template #subtitle>
              <p v-if="player.ip_details?.provider?.length" class="truncate">
                {{ player.ip_details.provider ?? 'N/A' }}
              </p>
              <Skeleton v-else class="skeleton !max-w-30" />
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
      </PlayerDetailsTableCard>

      <!-- Информация из Steam -->
      <PlayerDetailsTableCard
        :per-row="2"
        :amount="4"
        :title="t('modal.player.general.steam.title')"
      >
        <template #col0>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.steam.private')" 
            :subtitle="props.player.steam_data?.visible ? t('modal.player.general.steam.open') : t('modal.player.general.steam.closed')"
          />
        </template>
        <template #col1>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.steam.hours')"
            :subtitle="steamHoursText"
          >
            <!--<template #subtitle>
              <TooltipBase
                trigger="hover"
                distance="6"
                class="w-fit"
              > 
                <p
                  class="truncate"
                  :class="{ 'warning': (props.player.steam_data?.hours_total || Infinity) < 100 && props.player.steam_data?.hours_total != 0 }"
                >
                  {{ steamHoursText }}
                </p>
                <template v-if="props.player.steam_data?.hours_closed_at && props.player.steam_data?.hours_total != null && props.player.steam_data?.hours_total != 0" #content> 
                  <div class="tooltip-text"> 
                    <p>{{ t('modal.player.general.steam.closed-at') }}</p> 
                  </div> 
                </template> 
              </TooltipBase>
            </template>-->
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col2>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.steam.vac')"
          >
            <template #subtitle>
              <TooltipBase
                trigger="hover"
                distance="6"
                class="w-fit"
              > 
                <p class="truncate" :class="{ 'warning': isRecentlyBanned}">
                  {{ banInfo }}
                </p>
                <template v-if="isRecentlyBanned" #content> 
                  <div class="tooltip-text"> 
                    <p>{{ t('modal.player.general.steam.dslb', {days: props.player.steam_data?.ban_data.days_since_last_ban}) }}</p> 
                  </div> 
                </template> 
              </TooltipBase>
            </template>
          </PlayerDetailsTableCardListItem>
        </template>
        <template #col3>
          <PlayerDetailsTableCardListItem 
            :title="t('modal.player.general.steam.account-created')" 
            :subtitle="props.player.steam_data?.signed_at ? _time.format(props.player.steam_data.signed_at, 'DD.MM.YYYY # HH:mm', true, true) : t('general.unknown')"
          />
        </template>
      </PlayerDetailsTableCard>
    </div> 
  </ModalMenuExtendedPageLayout>
</template>

<style lang="scss" scoped>
.general {
  @apply flex flex-col gap-5;
  @apply relative;

  .tags {
    @apply flex flex-wrap gap-2;
  }
}

.country {
  @apply flex items-center gap-1.5;
}

.flag {
  @apply mt-[2px];
  @apply w-[14px] h-[14px];
  @apply self-center;
}

.skeleton {
  @apply rounded-md;
  height: 19px;
  width: 80%;
}

.actions {
  @apply flex cursor-pointer gap-0.5 items-center;
  @apply select-none;
  
  svg {
    @apply flex-shrink-0;
    @apply fill-grey-200;
    height: 18px;
    width: 18px;
  }
  
  &:hover {
    @apply text-grey-50;
    svg {
      @apply fill-grey-50;
    }
  }
}

.ip {
  @apply flex gap-0.5 items-center select-none;

  svg {
    @apply w-[18px];
  }
}

.warning {
  @apply text-amber-400;
}
</style>