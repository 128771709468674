<script lang="ts">
</script>

<script setup lang="ts">
  import { ModalInjectedProps, ModalInstance, useModalsStore } from '@/stores/modals.store';
  import { useMagicKeys, useWindowSize, whenever } from '@vueuse/core';
  import { Transition, ref } from 'vue';
  import { useRoute } from 'vue-router';

  const _route = useRoute();
  const _modal = useModalsStore();

  const { width } = useWindowSize();

  const generateOptions = (modal: ModalInstance<any>): ModalInjectedProps => {
    const visible = modal.isVirtual 
      ? _modal.cachedVirtualModals[0]?.id === modal.id 
      : _modal.cachedVirtualModals.length == 0 && modal.isEqual(_route.query);
    
    return {
      injected: {
        modal,
        close: async () => { 
          return await _modal.close(); 
        },

        visible,

        setAfterClose : (cb?) => modal.afterClose = cb,
        setBeforeClose: (cb?) => modal.beforeClose = cb,
      }
    };
  };

  const { escape } = useMagicKeys();

  whenever(escape, () => _modal.close(true));

  const lastCloseTime = ref<number>();

  const tapClose = () => {
    if (lastCloseTime.value && Date.now() - lastCloseTime.value < 250) {
      return;
    }

    lastCloseTime.value = Date.now();
    _modal.close();
  };
</script>

<template>
  <div class="relative">
    <Transition name="fade">
      <div
        v-if="_modal.cachedRoutedModals.length || _modal.cachedVirtualModals.length"
        class="modal-backdrop relative"
        @click="tapClose"
      />
    </Transition>
    
    <TransitionGroup name="pop">
      <template v-for="(modal) in _modal.cachedVirtualModals" :key="`modal:${modal.id}`">
        <div
          v-show="generateOptions(modal).injected.visible"
          :class="[
            'modal-border', 
            '!z-40', 
            { 
              transparent: modal.definition.options?.transparent,
              'full-size': modal.definition.options?.fullSizeMinWidth && (width < modal.definition.options?.fullSizeMinWidth) 
            }
          ]"
          @click.stop=""
        >
          <div class="modal">
            <component
              :is="modal.definition.component"
              :key="modal.id"
              v-bind="{...modal.props, ...generateOptions(modal) }"
              :internal-id="modal.id"
            />
          </div>
        </div>
      </template>

      <template v-for="modal in _modal.cachedRoutedModals" :key="`modal:${modal.id}`">
        <div
          v-show="generateOptions(modal).injected.visible"
          :class="[
            'modal-border', 
            '!z-40', 
            { 
              transparent: modal.definition.options?.transparent,
              'full-size': modal.definition.options?.fullSizeMinWidth && (width < modal.definition.options?.fullSizeMinWidth) 
            }
          ]"
          @click.stop=""
        >
          <div class="modal">
            <component
              :is="modal.definition.component"
              :key="modal.id"
              v-bind="{...modal.props, ...generateOptions(modal) }"
              :internal-id="modal.id"
            />
          </div>
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>

<style scoped lang="scss">
.modal-backdrop {
  content: '';
  @apply fixed inset-0;
  @apply bg-[#080808]/80;
  @apply flex items-center justify-center;
  @apply cursor-pointer;

  @apply z-30;
}

.modal {  
  @apply bg-grey-900;
  @apply overflow-x-auto;
  @apply flex flex-col;
  @apply rounded-lg;
}

.modal-border {
  @apply fixed inset-0;
  @apply h-fit w-fit;
  @apply m-auto;
  @apply z-40;

  &.transparent {
    @apply bg-transparent;
    backdrop-filter: none;
    .modal {
      @apply bg-transparent;
    }
  }
  
  &.full-size { 
    @apply h-full w-full;

    .modal {
      @apply h-full w-full;
      @apply rounded-none;
    }
  }
}

.fade-enter-active {
  transition: opacity .2s cubic-bezier(0.36, 0.01, 0, 1);
} 
.fade-leave-active {
  transition: opacity .2s cubic-bezier(0.71, 0.16, 0, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active {
  transition: all .2s cubic-bezier(0.36, 0.01, 0, 1);
}
.pop-leave-active {
  transition: all .2s cubic-bezier(0.71, 0.16, 0, 1);
}

.pop-enter-from,
.pop-leave-to {
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
}
</style>
