import { DebugService } from "@/api/backend/debug.service";
import { usePanelStore } from "@panel/stores/panel.store";
import { defineStore } from "pinia";
import { Ref, watch } from "vue";

export const useDataStore = defineStore('data', () => {
  const _panel = usePanelStore();

  const sync = <T extends (string | object)>(type: string, ref: Ref<T>, watchProject?: boolean) => {
    const correctName = (id = _panel.project?.id) => {
      if (!watchProject || !id) {
        return type;
      }

      return `${type}(${id})`;
    };

    
    watch(() => ref.value, () => write(correctName(), ref.value), { deep: true });

    if (watchProject) {
      watch(() => _panel.project, (v) => { 
        if (!v) {
          return;
        }
        
        ref.value = readValue(v.id).value;
      }, { deep: true });
    }

    const readValue = (id?: number) => {
      const data = read(correctName(id)) as T;

      if (!data) {
        return ref;
      }
  
      if (typeof ref.value != typeof data) {
        DebugService.error(`Не удалось восстановить несовместимые типы данных`, { ref: ref.value, data });
        write(correctName(id), ref.value);
        return ref;
      }
  
      if (typeof data === 'object') {
        ref.value = Object.assign(ref.value, data);
      } else {
        ref.value = data;
      }
  
      return ref;
    };

    return readValue();
  };

  const read = (type: string) => {
    const strObj = localStorage.getItem(type) ?? '{}';
    const obj = JSON.parse(strObj);

    return ['string', 'object'].includes(typeof obj) ? obj : undefined;
  };

  const write = (type: string, data: unknown) => {
    localStorage.setItem(type, JSON.stringify(data));
  };

  return { sync, read };
});