<script setup lang="ts">
  import { onMounted } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaPlayerIgnore } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();
  const _time = useTimeStore();

  const props = defineProps<AuditDto<AuditMetaPlayerIgnore>>();

  const player = ref<PlayerMinimalDto>();

  onMounted(async () => {
    player.value = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.target_steam_id ?? '');
  });

  const audit = ref<InstanceType<typeof AuditBase>>();
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="meta.until ? Svg.mute : Svg.unmute"
    :icon_styles="meta.until ? 'fill-amber-400' : 'fill-lime-400'"
    :date="created_at"
    :ready="!!player"
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ meta.until ? t('audit-player-ignore-enable-until') : t('audit-player-ignore-disable') }}</span>
    <span class="bright cursor-pointer" @click="() => !!player ? ActivatePlayerDetailsModal(player.steam_id) : {}">{{ player?.steam_name }}</span>
    
    <template v-if="meta.until">
      <span>{{ t('general.until').toLowerCase() }}</span>
      <span class="light">{{ _time.format(meta.until, 'DD.MM.YY HH:mm', true, true) }}</span>
    </template>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>