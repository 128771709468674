<script setup lang='ts'>
  import ReportsTable from './components/ReportsTable.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import ReportsSidebar from './ReportsSidebar.vue';
  import { ref } from 'vue';

  const { t } = useLocaleStore();

  const search = ref<string>('');
</script>

<template>
  <LayoutPage
    :header="t('reports.header')"
    always-absolute
    :default-search="e => search = e"
  >
    <ReportsTable :search="search" />

    <template #side-bar>
      <ReportsSidebar />
    </template>
  </LayoutPage>
</template>