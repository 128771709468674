<script lang="ts" setup>
  import { usePanelStore } from "@panel/stores/panel.store";
  import Calendar from 'primevue/calendar';
  import { Svg } from "@src/assets/auto_gen_types.dto";
  import dayjs from "dayjs";
  import { computed, onMounted, ref } from "vue";
  import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
  import { usePermissionsStore } from "@panel/stores/permissions.store";
  import { useAppStore } from "@/stores/app.store";
  import { watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useLocaleStore } from "@/stores/locale.store";
  import SelectInput from "@/components/inputs/SelectInput.vue";
  
  const { t } = useLocaleStore();

  const _app = useAppStore();
  const _panel = usePanelStore();
  const _route = useRoute();
  const _router = useRouter();
  const _permissions = usePermissionsStore();

  onMounted(() => setup());

  watch(() => _app.client, () => setup(), { deep: true });

  const setup = () => {
    if (_permissions.isAdminOrOwner()) {
      choosedClientId.value = clientList.value[0].value;
    } else {
      choosedClientId.value = clientList.value.find(v => v.value == _app.client?.id)?.value ?? null;
    }

    if (_route.query.client) {
      choosedClientId.value = clientList.value.find(v => v.value == _route.query?.client)?.value ?? null;
    }

    if (_route.query.from || _route.query.to) {
      choosedInterval.value = [
        _route.query.from ? dayjs(_route.query.from?.toString()).toDate() : null as any, 
        _route.query.to ? dayjs(_route.query.to?.toString()).toDate() : null as any
      ];

      validateDays(true);
    }
  };
  
  const choosedInterval = ref<[Date, Date]>([
    dayjs().startOf('month').startOf('day').toDate(),
    dayjs().toDate()
  ]);

  const validatedInterval = ref<[Date, Date]>(choosedInterval.value);

  const validateDays = (force: boolean) => {
    const normalDates = choosedInterval.value?.filter(v => !!v) as Date[];
    if (normalDates.length == 1 && force) {
      const [firstDate] = normalDates;

      choosedInterval.value = [firstDate, firstDate];
    } else if (normalDates.length == 2) {
      const interval = dayjs.duration(+normalDates[1] - +normalDates[0], 'millisecond').asDays();

      if (interval > 31) {
        useRuntimeNotification('warning', t('moderator-stats-notification-error-interval'));

        choosedInterval.value = [dayjs(normalDates[1]).add(-30, 'day').toDate(), normalDates[1]];
        return;
      }
    }

    if (force || normalDates.length == 2) {
      if (+validatedInterval.value[0] != +choosedInterval.value[0] || +validatedInterval.value[1] != +choosedInterval.value[1]) {
        validatedInterval.value = [new Date(choosedInterval.value[0]), new Date(choosedInterval.value[1])];
      }
    }
  };

  const minCalendarDate = computed(() => {
    return new Date(Math.max(+dayjs().set('M', 1).set('D', 1).set('y', 2024), +dayjs().add(-6, 'M')));
  });

  watch(() => validatedInterval.value, (v) => {
    _router.replace({ query: { ..._route.query, from: dayjs(v[0]).format('MM.DD.YYYY'), to: dayjs(v[1]).format('MM.DD.YYYY') }});
  }, { deep: true });

  const choosedClientId = ref<number | null>(null);

  watch(() => choosedClientId, (v) => {
    _router.replace({ query: { ..._route.query, client: v.value?.toString() }});
  }, { deep: true });


  const clientList = computed(() => {
    return [
      { 
        value: null, 
        text : t('moderator-stats-settings-suggester-all-moders'),
        icon : undefined
      },
      ..._panel.projectClients.map(v => ({ 
        value: v.client.id, 
        text : v.client.name, 
        icon : v.client.avatar 
      }))
    ];
  });

  defineExpose({ choosedInterval: validatedInterval, choosedClientId });
</script>

<template>
  <div class="flex justify-between items-center">
    <SelectInput
      v-model="choosedClientId"
      :values="clientList"
    />

    <div class="rounded-md flex items-center px-2 gap-1 !cursor-pointer pr-0">
      <Calendar
        v-model="choosedInterval"
        selection-mode="range"
        :manual-input="false"
        input-class="cursor-pointer"
        date-format="dd.mm"
        :min-date="minCalendarDate"
        :max-date="dayjs().toDate()"
        lang="ru"
        @date-select="() => validateDays(false)"
        @hide="() => validateDays(true)"
      />

      <Svg.calendar_clock class="h-4 w-4 fill-grey-300" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.client-suggester {
  min-width: 100px;
  padding-left: 0px !important;

  &:deep(.suggestions-wrapper) {
    min-width: max-content;
    left: 0;
  }

  &:deep(.input-box) {
    input {
      display: block;
      min-width: 0 !important;
      width: 0 !important;
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
    }

    .value {
      background: transparent !important;
    }
  }
}

:deep(.p-calendar input) {
  text-align: right;
  max-width: 110px !important;
}
</style>