<script lang="ts">
  import { useModalsStore } from '@/stores/modals.store';
  import DividedCard from '@/components/DividedCard.vue';
  import Button from '@/components/Button.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCoreApi } from '@src/api/backend/core/core.api';

  export type PaymentModalOpts = {
  }

  export const ActivatePaymentsModal = async (props: PaymentModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('payments', props);
  };
</script>

<script setup lang="ts">
  import { ref, computed, onMounted } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import TextInput from '@/components/inputs/TextInput.vue';

  const { t, lang } = useLocaleStore();
  const _panel = usePanelStore();
  const _modal = useModalsStore();
  const _validators = useValidators();

  const methods = computed(() => {
    const list = [{
      value: 'card',
      icon : Svg.giro_cards,
      text : t('payments.modal.methods.card'),
    }, {
      value: 'card-eu',
      icon : Svg.giro_cards,
      text : t('payments.modal.methods.card-eu'),
    }, {
      value: 'sbp',
      icon : Svg.sbp,
      text : t('payments.modal.methods.sbp'),
    }, {
      value: 'card-eu-paypal',
      icon : Svg.paypal,
      text : t('payments.modal.methods.paypal'),
    },{
      value: 'card-eu-google-pay',
      icon : Svg.gpay,
      text : t('payments.modal.methods.google'),
    }, {
      value: 'crypto',
      icon : Svg.bitcoin,
      text : t('payments.modal.methods.crypto'),
    }];

    return list;
  });

  const method = ref<string>(methods.value[lang != 'ru' ? 1 : 0].value);
  const info = ref<{ sum: string }>({
    sum: ''
  });
  

  const depositeSumRef = ref<InstanceType<typeof TextInput>>();

  const deposite = async () => {
    if (!_panel.project?.id) {
      return;
    }
    
    const sum = lang === 'en' ? +info.value.sum * 95 : +info.value.sum;

    const realMethod = method.value.includes('card-eu') ? 'card-eu' : method.value;

    const link = await useCoreApi().project.depositeWallet(_panel.project.id, sum, realMethod);

    window.location.href = link;
  };

  const selectMethod = (value: string) => {
    method.value = value;
  };

</script>

<template>
  <div class="payments-modal">
    <DividedCard class="divided-card">
      <template #header>
        <p>{{ t('payments.modal.header.title') }}</p>
      </template>
      <template #default>
        <div class="payments-content">
          <div class="methods">
            <div
              v-for="methodItem in methods"
              :key="methodItem.value"
              class="method"
              :class="{ selected: methodItem.value === method }"
              @click="selectMethod(methodItem.value)"
            >
              <component
                :is="methodItem.icon"
              />
              <p class="text">{{ methodItem.text }}</p>
              <div v-if="methodItem.value === method" class="checkmark">
                <Svg.checkmark />
              </div>
            </div>
          </div>
          <TextInput
            ref="depositeSumRef"
            v-model="info.sum"
            type="number"
            inputmode="numeric"
            :label="t('payments.modal.methods.input-sum.label')"
            :placeholder="t('payments.modal.methods.input-sum.placeholder')"
            :validator="_validators.Deposite"
            no-border
          >
            <template #append>
              <p class="input-append">{{ lang === 'en' ? '$' : '₽' }}</p>
            </template>
          </TextInput>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-2 items-center w-full"> 
          <Button
            :text="t('modal.client.logout')" 
            preset="default" 
            :action="_modal.close" 
          />
          <Button
            :text="t('general.deposit')"
            preset="primary" 
            :disabled="!depositeSumRef || !!depositeSumRef.activeException" 
            :action="deposite" 
          />
        </div> 
      </template>
    </DividedCard>
  </div>
</template>

<style lang="scss" scoped>
.payments-modal {
  width: 95vw;
  max-width: 400px;
}

.payments-content {
  @apply flex flex-col gap-4 ;
}

:deep(.divided-card) {
  .content {
    overflow: visible;
  }
}

:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}

:deep(.label) {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}

:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}

.methods {
  @apply grid grid-cols-3 gap-1.5 rounded-md bg-grey-1000 p-1.5;

  .method {
    @apply relative flex flex-col p-2.5 gap-0.5;
    @apply overflow-hidden;
    @apply bg-grey-900;
    @apply rounded;
    @apply cursor-pointer;

    svg {
      @apply w-[18px];
      @apply fill-grey-400;
    }

    .text {
      @apply text-grey-400;
      @apply text-sm;
      @apply truncate;
    }

    &:hover {
      @apply bg-grey-850;

      svg {
        @apply fill-grey-200;
      }

      .text {
        @apply text-grey-200;
      }
    }

    &.selected {
      @apply bg-grey-800;

      svg {
        @apply fill-grey-50;
      }

      .text {
        @apply text-grey-50;
      }
    }

    .checkmark {
      @apply absolute top-2 right-2;
      @apply rounded;
      @apply bg-primary-700;
      box-shadow: 0 0px 25px 2px #0099ff;
      
      svg {
        @apply w-3.5;
        @apply fill-grey-50;
      }
    }
  }
}

.input-append {
  @apply mr-3;
  @apply text-grey-600;
}
</style>