import { CourtDiscordType } from "@/api/backend/court/court-discord/court-discord.dto";
import { useLocaleStore } from "@/stores/locale.store";
import { Svg } from "@src/assets/auto_gen_types.dto";
import { DicordWebhookDetails } from "@court/pages/integrations/types/discord-webhook.dto";
import { ref } from "vue";

export const useDiscordWebhookStorage = () => {
  const { t } = useLocaleStore();

  const data = ref<DicordWebhookDetails<CourtDiscordType>[]>([{
    title   : t('dw-report-title'),
    subtitle: t('dw-report-subtitle'),
    icon    : Svg.report,
    type    : 'Report',
    options : [{
      _key    : 'mention_tag',
      title   : t('webhook-rustapp-mention'),
      subtitle: '@everyone',
      type    : 'text',
      meta    : {
        default: '@everyone',
      }
    },
    {
      _key: 'minimal_for_mention',

      title   : t('discord.settings.minToTag.title'),
      subtitle: t('discord.settings.minToTag.subtitle'),

      type: 'number',
      meta: {
        min    : 1,
        max    : 999,
        default: 5,

        link_key_min: 'minimal_to_send' 
      }
    },
    {
      _key: 'minimal_to_send',

      title   : t('discord.settings.minToAlert.title'),
      subtitle: t('discord.settings.minToAlert.subtitle'),

      type: 'number',
      meta: {
        min    : 1,
        max    : 999,
        default: 5
      }
    },
    {
      _key: 'send_only_with_comment',

      title   : t('discord.settings.withComments.title'),
      subtitle: t('discord.settings.withComments.subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }, 
    {
      _key: 'send_minimal_embed',

      title   : t('discord.settings.minimalist.title'),
      subtitle: t('discord.settings.minimalist.subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }]
  }, {
    title   : t('dw-check-title'),
    subtitle: t('dw-check-subtitle'),
    icon    : Svg.check,
    type    : 'Check',
    options : [{
      _key    : 'started',
      title   : t('dw-checks-created-title'),
      subtitle: t('dw-checks-created-subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }, {
      _key    : 'finished',
      title   : t('dw-checks-finished-title'),
      subtitle: t('dw-checks-finished-subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }, 
    {
      _key: 'send_minimal_embed',

      title   : t('discord.settings.minimalist.title'),
      subtitle: t('discord.settings.minimalist.subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }]
  },
  {
    title   : t('dw-ban-title'),
    subtitle: t('dw-ban-subtitle'),
    icon    : Svg.lock,
    type    : 'Ban',
    options : [{
      _key    : 'created',
      title   : t('dw-ban-created-title'),
      subtitle: t('dw-ban-created-subtitle'),
      type    : 'boolean',
      meta    : {
        default: false
      }
    }, {
      _key    : 'editted',
      title   : t('dw-ban-editted-title'),
      subtitle: t('dw-ban-editted-subtitle'),
      type    : 'boolean',
      meta    : {
        default: true
      }
    }, {
      _key    : 'unban',
      title   : t('dw-ban-unban-title'),
      subtitle: t('dw-ban-unban-subtitle'),
      type    : 'boolean',
      meta    : {
        default: true
      }
    }, 
    {
      _key: 'send_minimal_embed',

      title   : t('discord.settings.minimalist.title'),
      subtitle: t('discord.settings.minimalist.subtitle'),
      type    : 'boolean',
      meta    : {
        default: true
      }
    }]
  },
  {
    title   : t('webhook-rustapp-detect-ban'),
    subtitle: t('webhook-rustapp-detect-ban-subtitle'),
    icon    : Svg.circle_ban_sign,
    type    : 'RustAppBan',
    options : [{
      _key    : 'mention_tag',
      title   : t('webhook-rustapp-mention'),
      subtitle: '@everyone',
      type    : 'text',
      meta    : {
        default: '@everyone',
      }
    }]
  }, {
    title   : t('webhook-rustapp-many-bans-title'),
    subtitle: t('webhook-rustapp-many-bans-subtitle'),
    icon    : Svg.blur,
    type    : 'ManyBans',
    options : [{
      _key    : 'mention_tag',
      title   : t('webhook-rustapp-mention'),
      subtitle: '@everyone',
      type    : 'text',
      meta    : {
        default: '@everyone',
      }
    },
    {
      _key    : 'min_ban_amount',
      title   : t('webhook-rustapp-many-bans-ban-amount-title'),
      subtitle: t('webhook-rustapp-many-bans-ban-amount-subtitle'),
      type    : 'number',
      meta    : {
        default: 20,
        min    : 0,
        max    : 200,
      }
    },
    {
      _key    : 'interval_minutes',
      title   : t('webhook-rustapp-many-bans-min-amount-title'),
      subtitle: t('webhook-rustapp-many-bans-min-amount-subtitle'),
      type    : 'number',
      meta    : {
        default: 5,
        min    : 0,
        max    : 180,
      }
    }]
  }]);

  return data;
};