<script setup lang='ts'>
  import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import TableBase from '@/components/table/TableBase.vue';
  import Skeleton from '@/components/Skeleton.vue';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { TableHeaders } from "@/components/table/table.dto";
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import TextExtended from '@/components/TextExtended.vue';
  import ClientListItem from '@court/components/client/ClientListItem.vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { useUnknownClient, useUnknownPlayer } from '@court/utils/player.utils';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import Button from '@/components/Button.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  
  export type BanHeaders = TableHeaders<'date' | 'moderator' | 'arrow' | 'suspect' | 'empty' | 'reason' | 'server' | 'expired' | 'actions'>;

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _court = useCourtStore();
  const _panel = usePanelStore();
  const _adaptive = useAdaptiveStore();

  const props = defineProps<{ search: string; selectedProject?: number }>();

  const headers = computed<BanHeaders>(() => {
    return {
      date: {
        text: t('bans.headers.date'),
        min : '100px',
        max : '140px',
      },
      moderator: {
        text: t('bans.headers.moderator'),
        min : '150px',
        max : '160px',
      },
      arrow: {
        text: '',
        min : '20px',
        max : '80px',
      },
      suspect: {
        text: t('bans.headers.suspect'),
        min : '240px',
        max : '240px',
      },
      empty: {
        text: '',
        min : '0px',
        max : '40px',
      },
      server: {
        text: t('bans.headers.server'),
        min : '100px',
        max : '180px',
      },
      expired: {
        text: t('bans.headers.expired'),
        min : '180px',
        max : '200px',
      },
      reason: {
        text: t('bans.headers.verdict'),
        min : '100px',
        max : '240px',
      },
      actions: {
        text : '',
        min  : '50px',
        right: true,
      },
    };
  });

  const lastSearchStamp = ref<number>();
  const searchHandler = ref<{ search: string, players: PlayerMinimalDto[] }>();

  watch(() => props.search, async (v) => {
    const id = Math.random();

    lastSearchStamp.value = id;

    if (!v.length) {
      searchHandler.value = undefined;
      return;
    }

    const players = await useCourtApi().player.browseMinimal({ search: v, limit: 25, offline: true, page: 0 });

    if (lastSearchStamp.value != id) {
      return;
    }

    searchHandler.value = { search: v, players: players.results };
  });

  const loader = computed(() => {
    searchHandler.value;
    props.selectedProject;

    const searchAsModerator = searchHandler.value?.search.replace('@', '');
    
    const exactModerator = !searchAsModerator ? undefined : _panel.projectClients.find(v => v.client.name === searchAsModerator || v.client.tag === searchAsModerator);
    const suitableModerators = !searchAsModerator ? undefined : _panel.projectClients.filter(v => v.client.name?.toLowerCase()?.includes(searchAsModerator.toLowerCase()) || v.client.tag?.includes(searchAsModerator.toLowerCase()));

    let search: string | undefined = searchHandler.value?.search.length ? searchHandler.value?.search : undefined;
    let client_ids: number[] | undefined = undefined;

    if (suitableModerators) {
      client_ids = suitableModerators.map(v => v.client.id);
    }

    if (exactModerator) {
      search = undefined;

      client_ids = [exactModerator.client.id];
    }

    return new PaginationService((page, limit) => useCourtApi().ban.browse({
      page,
      limit,
 
      project_id   : props.selectedProject || undefined,
      steam_ids    : searchHandler.value?.players.map(v => v.steam_id),
      by_client_ids: client_ids,
      search       : search,
      sort_by      : 'created',
    }), 50);
  });

  watch(() => loader.value, () => {
    items.value = null;
    next();
  }, {
    deep: true
  });
  
  const items = ref<BanDto[] | null>(null) as Ref<BanDto[] | null>;

  const next = async () => {
    const loaderHash = loader.value.id;
    
    const result = await loader.value.next();

    if (loader.value.id !== loaderHash) {
      return;
    }    
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  /**
   * Пакет сокетов
   */
  const centrifugoBanUpdate = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoBanUpdate.value = useCentrifugoApi().subscribeBanUpdate(_panel.project.id, (ban) => {
      if (!items.value) {
        return;
      }

      if (ban.project_id != props.selectedProject) {
        return;
      }

      const origin = items.value.find(v => v.id === ban.id);
      if (!origin) {
        items.value?.unshift(ban);
      } else {
        Object.assign(origin, ban);
      }
    });
  });

  onUnmounted(() => {
    centrifugoBanUpdate.value?.unsubscribe();
  });
  
  const serverTooltipText = (ban: BanDto) => {
    return ban.server_ids.map(v => _court.serverNameById(v)).join(', ');
  };

  const openBanModal = (ban: BanDto) => {
    ActivateBanViewModal({ ban, banId: ban.id, projectId: ban.project_id });
  };

  const { generate } = useUnknownPlayer();
</script>

<template>
  <TableBase
    :headers="headers"
  
    :items="items"
    :item-key="(i) => i.id.toString()"

    :next="next"
    
    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"

    :missing-data-warning="selectedProject != _panel.project?.id ? 'Поиск в чужом бан-листе работает только по SteamID64' : ''"
  >
    <template #date="{ item }">
      <template v-if="item">
        <ListItem
          :title="_time.format(item.created_at, 'DD.MM.YY', true, true)"
          :message="_time.format(item.created_at, 'HH:mm:ss')"
          no-avatar
          class="date"
        />
      </template>
      <template v-else>
        <div class="flex flex-col gap-1.5">
          <Skeleton
            style="height: 14px; width: 80px"
            class="w-full rounded-md"
          />
          <Skeleton
            style="height: 12px; width: 60px"
            class="w-full rounded-md"
          />
        </div>
      </template>
    </template>

    <template #moderator="{ item }">
      <template v-if="!item || item.client_id">
        <ClientListItem :client-id="item?.client_id" :search="searchHandler?.search" />
      </template>
      <template v-else>
        <ClientListItem :preload="useUnknownClient().generate()" />
      </template>
    </template>

    <template v-if="!_adaptive.isMobile" #arrow="{ }">
      <div class="arrow-container">
        <Svg.arrow class="arrow" />
      </div>
    </template>

    <template v-if="!_adaptive.isMobile" #empty="{}" />

    <template #suspect="{ item }">
      <PlayerBox
        class="w-full"
        :search="[searchHandler?.search ?? '']"
        :player="item ? item?.player ?? generate(item.steam_id, item.player_minimal?.steam_name, item.player_minimal?.steam_avatar) : null"
        :subtext="item?.steam_id"
      />
    </template>

    <template v-if="!_adaptive.isMobile" #server="{ item }">
      <template v-if="item">
        <template v-if="item.server_ids.length > 0">
          <template v-if="item.server_ids.length > 1">
            <TooltipBase
              trigger="hover"
              distance="8"
              class="w-fit"
            >
              <p class="few">{{ t('bans.few') }}</p>
              <template #content>
                <div class="flex p-1.5 gap-1 text-center" style="max-width: 200px;">
                  <p>{{ serverTooltipText(item) }}</p>
                </div>
              </template>
            </TooltipBase>
          </template>
          <template v-else>
            <p class="text-grey-400">{{ serverTooltipText(item) }}</p>
          </template>
        </template>
        <template v-else>
          <p class="text-grey-400">{{ t('bans.all') }}</p>
        </template>
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 100px"
          class="rounded-md"
        />
      </template>
    </template>

    <template #expired="{ item }">
      <div>
        <template v-if="item">
          <template v-if="item.computed_is_active">
            <p class="text-grey-400">{{ item.expired_at ? t('bans.date', { date: _time.format(item.expired_at, 'DD.MM.YY', true, true) }) : t('bans.forever') }}</p>
          </template>
          <template v-else>
            <p class="text-emerald-500">{{ t('bans.unbanned') }}</p>
          </template>
        </template>
        <template v-else>
          <Skeleton
            style="height: 20px; width: 80px"
            class="rounded-md"
          />
        </template>
      </div>
    </template>

    <template #reason="{ item }">
      <template v-if="item">
        <TextExtended :data="(item ? { message: item.comment?.split('~BAN_SYNC_COPY~')[0], reason: item.reason } : null)" :search="searchHandler?.search" />
      </template>
      <template v-else>
        <Skeleton
          style="height: 20px; width: 120px"
          class="rounded-md"
        />
      </template>
    </template>

    <template #actions="{ item }">
      <template v-if="item">
        <div class="flex justify-end">
          <Button
            preset="default-stroke"
            class="!px-2"
            :action="() => openBanModal(item)"
          >
            <Svg.open />
          </Button>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-end">
          <Skeleton
            style="height: 38px; width: 38px"
            class="rounded-md"
          />
        </div>
      </template>
    </template>
  </TableBase>
</template>

<style lang='scss' scoped>
.few {
  @apply text-grey-400 hover:text-grey-50;
  @apply cursor-pointer;
  @apply underline underline-offset-2;
  @apply truncate;
}
.open {
  @apply w-6 cursor-pointer;
  @apply fill-grey-700;
  @apply select-none;

  &:hover {
    @apply fill-grey-400;
  }

  &:active {
    @apply fill-grey-50;
  }
}

.arrow-container {
  @apply flex justify-center;

  .arrow {
    @apply w-6 fill-grey-600;
  }
}

.date {
  :deep(.lines) {
    .title {
      @apply text-grey-400 font-medium;
    }
    .message {
      @apply text-grey-600 font-medium;
    }
  }
}
</style>