import { useApiBase } from "../../base.api";
import { defineStore } from "pinia";
import { useCourtAuditApi } from "./audit/audit.service";
import { useCourtBanApi } from "./ban/ban.service";
import { useCourtChatApi } from "./chat/chat.service";
import { useCourtCheckApi } from "./check/check.service";
import { useCourtCommandsApi } from "./commands/commands.service";
import { useCourtCourtApi } from "./court/court.service";
import { useCourtCourtApiApi } from "./court-api/court-api.service";
import { useCourtDiscordApi } from "./court-discord/court-discord.service";
import { useCourtIntergrationsApi } from "./court-integrations/court-integrations.service";
import { useCourtPlayerApi } from "./player/player.service";
import { useCourtPlayerAlertApi } from "./player-alert/player-alert.service";
import { useCourtReportApi } from "./report/report.service";
import { useCourtServersApi } from "./servers/servers.service";
import { useCourtSignagesApi } from "./signages/signages.service";
import { useCourtUtilsApi } from "./utils/utils.service";
import { useConfigStore } from "@/stores/config/config.store";

export const useCourtApi = defineStore('court-api', () => {
  const _config = useConfigStore();

  const api = useApiBase(_config.Urls.Court);

  const audit = useCourtAuditApi(api.api);
  const ban = useCourtBanApi(api.api);
  const chat = useCourtChatApi(api.api);
  const check = useCourtCheckApi(api.api);
  const commands = useCourtCommandsApi(api.api);
  const court = useCourtCourtApi(api.api);
  const courtApi = useCourtCourtApiApi(api.api);
  const courtDiscord = useCourtDiscordApi(api.api);
  const courtIntegrations = useCourtIntergrationsApi(api.api);
  const player = useCourtPlayerApi(api.api);
  const playerAlerts = useCourtPlayerAlertApi(api.api);
  const report = useCourtReportApi(api.api);
  const servers = useCourtServersApi(api.api);
  const signages = useCourtSignagesApi(api.api);
  const utils = useCourtUtilsApi(api.api);

  return {
    audit,
    ban,
    chat,
    check,
    commands,
    court,
    api         : courtApi,
    discord     : courtDiscord,
    integrations: courtIntegrations,
    player,
    playerAlert : playerAlerts,
    report,
    servers,
    signages,
    utils,
  };
});