<script setup lang="ts">
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import ChecksBrowseTable from './components/ChecksBrowseTable.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ref } from 'vue';

  const { t } = useLocaleStore();

  const search = ref<string>('');
</script>

<template>
  <LayoutPage
    :header="t('checks.header')" 
    :default-search="e => search = e"
  >
    <ChecksBrowseTable :search="search" />
  </LayoutPage>
</template>

<style lang="scss" scoped>
.search-input {
  max-width: 240px;
  height: 32px !important;
  &:deep(.input-box) {
    height: 32px !important;
  }
  &:deep(input) {
    @apply px-2 #{!important};
  }
}

[device='mobile'] {
  .search-input {
    max-width: 160px;
  }
} 

p {
  @apply text-grey-400;

  &.discord {
    @apply text-grey-400;
    &.received {
      @apply font-semibold text-ra-discord;
    }
  }

  &.verdict {
    @apply px-2 py-1 rounded-md;
    @apply whitespace-nowrap overflow-hidden text-ellipsis;

    &:not(.final) {
      @apply bg-primary-700/20 text-primary-700 animate-pulse;
    }
    &.final {
      @apply bg-white/10 text-white;
    }
  }
}
</style>