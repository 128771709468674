<script setup lang="ts">
  import TextInput from '@/components/inputs/TextInput.vue';
  import { useAppStore } from '@/stores/app.store';
  import ChatIcon from '@src/assets/icons/chat.svg';
  import { ref } from 'vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import Loader from '@/components/Loader.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const _app = useAppStore();
  const _permissions = usePermissionsStore();

  const { t } = useLocaleStore();
  const props = defineProps<{ steam_id: string }>();

  const message = ref<string>('');
  const busy = ref<boolean>(false);

  const send = async () => {
    if (!_app.client?.tag || message.value.length == 0) {
      return;
    }

    if (busy.value) {
      return;
    }

    busy.value = true;

    try {
      await useCourtApi().chat.send({
        initiator_name    : `${_app.client.name}`,
        message           : message.value,
        mode              : "chat",
        initiator_steam_id: _app.client.id.toString(),
        target_steam_id   : props.steam_id
      });

      message.value = '';
    }
    catch (err: any) {
      if (err?.message?.includes('Player is offline')) {
        useRuntimeNotification('warning', t('check.chat-send-offline'));
        return;
      }

      if (err?.message?.includes('Message is not sent')) {
        useRuntimeNotification('warning', t('check.chat-send-error'));
        return;
      }

      throw err;
    }
    finally {
      busy.value = false;

      setTimeout(() => {
        input.value?.inputRef?.focus();
      });
    }
  };

  const input = ref<InstanceType<typeof TextInput>>();
</script>

<template>
  <TextInput
    ref="input"
    v-model="message"
    theme="middle"
    :class="!_permissions.can(['Court_ChatWriteReply']) ? 'opacity-75' : 'opacity-100'"
    :disabled="!_permissions.can(['Court_ChatWriteReply']) || busy"
    :placeholder="!_permissions.can(['Court_ChatWriteReply']) ? t('chat.send.placeholder-no-rights') : t('chat.send.placeholder')"
    height="44"
    @on-enter="send"
  >
    <div class="pr-2.5">
      <template v-if="busy">
        <Loader size="20px" position="relative" />
      </template>
      <template v-else>
        <ChatIcon class="fill-grey-600 w-5" />
      </template>
    </div>
  </TextInput>
</template>