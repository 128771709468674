<script setup lang="ts">
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import dayjs from 'dayjs';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import Skeleton from '@/components/Skeleton.vue';
  import StatsCard from './StatsCard.vue';
  import { usePanelStore } from '@panel/stores/panel.store';

  const { t } = useLocaleStore();
  const _panel = usePanelStore();
  
  defineProps<{
    checks?: number;
    checkDiff?: number;

    day?: number;
    night?: number;

    bans?: number;
    bansDiff?: number;

    prevInterval: [Date, Date]
  }>();

  const _adaptive = useAdaptiveStore();
</script>

<template>
  <div class="card-wrapper">
    <template v-if="_panel.project?.id === 11814">
      <div v-if="checks == null" class="card">
        <div class="card-title">
          <Skeleton
            style="height: 20px;"
            class="rounded-md !max-w-[80px]"
          />
          <Skeleton
            style="height: 20px;"
            class="rounded-md !max-w-[50px]"
          />
        </div>
        <Skeleton
          style="height: 40px;"
          class="rounded-md !max-w-[50px]"
        />
      </div>
      <div v-else class="card relative">
        <div
          v-if="checkDiff"
          class="absolute gradient"
          :class="checkDiff > 0 ? 'positive' : 'negative'"
        />
  
        <div class="card-title">
          <p>{{ t('moderator-stats-card-checks') }}</p>
          <TooltipBase
            v-if="checkDiff"
            trigger="hover"
          >
            <p 
              :class="checkDiff > 0 ? 'positive' : 'negative'"
            >
              {{ checkDiff > 0 ? '+' : '' }}{{ checkDiff }}%
            </p>

            <template #content>
              <div class="px-1.5 py-1 text-xs">
                <p>
                  {{ t('moderator-stats-card-relative', { time: `${dayjs(prevInterval[0]).format('DD.MM.YYYY')} - ${dayjs(prevInterval[1]).format('DD.MM.YYYY')}` }) }}
                </p>
              </div>
            </template>
          </TooltipBase>
        </div>
    
        <div class="flex gap-1">
          <TooltipBase
            trigger="hover"
            distance="5"
          >
            <span class="card-value cursor-default">
              {{ day }}
            </span>

            <template #content>
              <div class="tooltip-text">
                <p>
                  {{ '08 - 22 МСК' }}
                </p>
              </div>
            </template>
          </TooltipBase>
          <span class="text-[40px] text-grey-600">
            {{ '/' }}
          </span>
          <TooltipBase
            trigger="hover"
            distance="5"
          >
            <span class="card-value cursor-default">
              {{ night }}
            </span>

            <template #content>
              <div class="tooltip-text">
                <p>
                  {{ '22 - 08 МСК' }}
                </p>
              </div>
            </template>
          </TooltipBase>
        </div>
      </div>
    </template>
    
    <template v-else>
      <StatsCard
        :title="t('moderator-stats-card-checks')"
        :value="checks"
        :diff="checkDiff"
        :prev-interval="prevInterval"
      />
    </template>

    <StatsCard
      :title="t('moderator-stats-card-bans')"
      :value="bans"
      :diff="bansDiff"
      :prev-interval="prevInterval"
    />

    <div v-if="!_adaptive.isMobile" class="card empty" />
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  @apply grid grid-cols-3 gap-5 mt-2;

  .card {
    @apply min-h-[100px];
    @apply w-full bg-grey-900 rounded-md p-3 overflow-hidden relative;
    @apply flex flex-col justify-between;
    

    &.empty {
      @apply bg-grey-900 border border-grey-850 opacity-40;
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(45, 45, 45, 0.50) 20px, rgba(45, 45, 45, 0.50) 35px);
    }

    .gradient {
      top: -10px;
      right: 20px;
      transform: translate(50%, -50%);
      height: 150px;
      width: 150px;
      pointer-events: none;
      
      &.negative {
        background: #f03312;
        background: radial-gradient(circle, rgba(240,51,18,0.1518942577030813) 0%, rgba(240,51,18,0) 70%);
      }
      &.positive {
        background: #8BC94E;
        background: radial-gradient(circle, rgba(139, 201, 78,0.1418942577030813) 0%, rgba(240,51,18,0) 70%);
      }
    }

    .card-title {
      @apply flex justify-between w-full text-grey-500;

      p.positive {
        @apply text-ra-online;
      }

      p.negative {
        @apply text-red-500;
      }
    } 

    .card-value {
      @apply text-grey-50 text-[40px] font-bold;
    }
  }
}

@media (max-width: 700px) {
  .card-wrapper {
    @apply grid-cols-1;
  }
}
</style>