import { defineStore } from 'pinia';
import { useLocaleStore } from './locale.store';
import { useNow } from '@vueuse/core';
import { DebugService } from '@/api/backend/debug.service';
import dayjs from 'dayjs';
import { computed } from 'vue';

export const useTimeStore = defineStore('time', () => {
  const _locale = useLocaleStore();

  const rawNow = useNow({ interval: 500 });  

  const computedNow = computed(() => {
    return rawNow.value;
  });

  const fromDate = (from: number, date: number, shortMinutes?: boolean, fullIfLong?: boolean): string => {
    const diff = +new Date(date) - from;

    if (diff > 0 && diff < 30000) {
      date = +new Date();
    }

    const secs = +((+from - +new Date(date)) / 1000).toFixed(0);
    if (fullIfLong && secs > 60*60*5) {
      return format(date, 'DD.MM.YY # HH:mm', true);
    }
    if (secs > 3 * 60) {
      let result = dayjs(+new Date(date)).from(from);
      if (shortMinutes) {
        result = result
          .replace("минуту", "мин.")
          .replace("минуты", "мин.")
          .replace("минут", "мин.")
          .replace("minutes", "min");

      }
      return result;
    }

    return `${secs} ${_locale.t('time.sec-ago')}`;
  };

  const fromNow = (date: number, shortMinutes?: boolean, fullIfLong?: boolean): string => {
    return fromDate(+computedNow.value, date, shortMinutes, fullIfLong);
  };

  const format = (
    time: number, 
    format: string = 'DD.MM.YYYY # HH:mm',
    showToday: boolean = false,
    showCurrentYear: boolean = false,
  ) => {
    const target = dayjs(time);

    let fixedFormat = format.replace('#', `[${_locale.t('time.delimitter')}]`);
    
    if (!showToday && target.format('DD.MM.YYYY') == dayjs().format('DD.MM.YYYY')) {
      const dayLimits = ['D', 'M', 'Y'];

      dayLimits.forEach(l => {
        while (fixedFormat.includes(`${l}.`) || fixedFormat.includes(l)) {
          fixedFormat = fixedFormat.replace(`${l}.`, '').replace(l, '').trim();
        }
      });
    }

    if (target.format('YYYY') === dayjs().format('YYYY') && !showCurrentYear) {
      const dayLimits = ['Y'];

      dayLimits.forEach(l => {
        while (fixedFormat.includes(`${l}.`) || fixedFormat.includes(l)) {
          fixedFormat = fixedFormat.replace(`${l}.`, '').replace(`.${l}`, '').replace(l, '').trim();
        }
      });
    }

    if (fixedFormat.length == 0) {
      DebugService.warn(`После адаптации формат стал пустым, используем стандартный`);

      fixedFormat = `⚠ ${format}`;
    }
    return target.format(fixedFormat).trim();
  };

  const difference = (dateFrom: number | string | Date, dateTo: number | string | Date, ltm: boolean = false) => {    
    const from = dayjs(dateFrom);
    const to = dayjs(dateTo);

    if (Math.abs(+from - +to) < 60_000 && ltm) {
      return useLocaleStore().t('less-then-minute');
    }

    return dayjs.duration(from.diff(to)).humanize();
  };

  return {
    now: computedNow,

    format,
    difference,

    fromNow,
    fromDate
  };
});
