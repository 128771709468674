<script lang="ts">
  export type PageNavigationSection = {
    name?: string;
    items: Partial<Record<CourtRoutes | SettingsRoutes, PageNavigationItem>>;
  }

  export type PageNavigationItem = {
    name: string;
    query?: object;
    icon?: Component;

    hidden?: boolean;
    todo?: boolean;
    warning?: boolean;
    new?: string;

    paid?: () => string | true | undefined;
  }

</script>

<script setup lang="ts">
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import { CourtRoutes } from '@court/court.routes';
  import { SettingsRoutes } from '@settings/settings.routes';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import type { Component } from 'vue';

  const _adaptive = useAdaptiveStore();

  defineProps<{ route: string, item: PageNavigationItem, showNew?: boolean }>();
</script>
  
<template>
  <component
    :is="typeof item.paid?.() == 'string' ? 'div' : 'router-link'"
    v-if="!item.hidden"
    :to="{ name: route, query: { ...(item.query ?? {})} }"
    active-class="active-link"

    class="nav-item cursor-pointer"
    :class="{ disabled: (!!item.todo) }"
    @click="_adaptive.isMobile ? _adaptive.editLeftBar(false) : () => {}"
  >
    <div class="flex items-center gap-2">
      <component
        :is="item.icon"
        v-if="item.icon"
        class="icon"
        style="min-width: 18px; width: 18px;"
      />

      <p class="text pl-2">
        {{ item.name }}
      </p>
    </div>

    <Svg.warning_circle v-if="item.warning" class="badge fill-amber-400 h-4" />

    <template v-if="item.paid?.() === 'basic'">
      <Svg.plan_basic :id="Math.random()" class="h-4" />
    </template>
    <template v-else-if="item.paid?.() === 'pro'">
      <Svg.plan_pro :id="Math.random()" class="h-4" />
    </template>

    <template v-if="showNew">
      <div v-if="!_adaptive.isMobile && !_adaptive.isTablet" class="bg-lime-400/15 text-lime-400 text-xs px-1 rounded">
        {{ `New` }}
      </div>
    </template>
  </component>
</template>

<style lang="scss" scoped>
:root:not([device='mobile']):not([left-bar]) {
  .text, .soon, .badge {
    display: none;
  }
}

.disabled {
  @apply pointer-events-none opacity-80;
  filter: blur(2px);
}

.fade-leave-from {
  opacity: 0;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.nav-item {
  @apply flex w-full items-center justify-between;
  @apply p-2;
  @apply rounded-md;
  @apply overflow-hidden;
  @apply select-none;

  .icon {
    flex-shrink: 1;
    @apply fill-grey-450;
  }

  .text {
    @apply text-grey-450;
    @apply text-lg font-medium;
    @apply leading-none;
  }

  &:hover {
    .text {
      @apply text-grey-300;
    }
    .icon {
      @apply fill-grey-300;
    }
  }

  &.active-link {
    @apply bg-grey-850;

    .text {
      @apply text-grey-50;
    }

    .icon {
      @apply fill-grey-50;
    }
  }
}
</style>