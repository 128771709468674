<script setup lang="ts">
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination } from 'swiper/modules';
  import { ActivateImageGalleryModal } from '@src/modals/image-gallery/ImageGalleryModal.vue';
  import { ref } from 'vue';

  defineProps<{ images: string[]; variant?: 'cover' | 'fit' }>();

  const index = ref<number>(0);

  defineExpose({ index });
</script>

<template>
  <swiper
    :space-between="20"
    :pagination="{
      clickable: true,
    }"
    :modules="[Pagination]"
    @active-index-change="e => index = e.activeIndex"
  >
    <template v-for="value in images" :key="value">
      <swiper-slide class="h-full" style="">
        <img
          :src="value"
          class="cursor-pointer h-full w-full"
          :class="variant === 'fit' ? 'object-contain' : 'object-cover'"
          @click="ActivateImageGalleryModal({ images: (images ?? []).map(v => ({ hash: v })), index: 0 })"
        >
      </swiper-slide>
    </template>
  </swiper>
</template>

<style lang="scss" scoped>
:deep(.swiper-pagination-bullet) {
  @apply bg-grey-50 opacity-50;
  @apply drop-shadow;

  &.swiper-pagination-bullet-active {
    @apply bg-grey-50 opacity-100;
  }
}
</style>