<script setup lang="ts">
  import Divider from '@/components/Divider.vue';
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { BanSyncFollowersDto, BanSyncSubscriptionsDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ActivateBanSyncAvailableModal } from '@court/modals/ban-sync/ban-sync-available/BanSyncAvailableModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { onMounted, ref } from 'vue';
  import { useBanStore } from '@court/stores/ban.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import ProjectListItem from '@panel/components/project/ProjectListItem.vue';
  import { ActivateBanSyncEditModal } from '@court/modals/ban-sync/ban-sync-edit/BanSyncEditModal.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';

  const { t } = useLocaleStore();
  const _ban = useBanStore();
  const _time = useTimeStore();
  const _panel = usePanelStore();

  const data = ref<{
    is_public: boolean;
    share_details: boolean;
    share_moders: boolean;
    total_bans: number;
  }>();

  onMounted(async () => {
    const result = await useCourtApi().ban.getSettings();

    data.value = Object.assign({ 
      is_public    : false,
      share_details: false,
      share_moders : false,
      total_bans   : 0
    }, result.data ?? {});
  });

  const sendUpdate = async () => {
    if (!data.value) {
      return;
    }

    await useCourtApi().ban.setSettings(data.value.is_public, data.value.share_details, data.value.share_moders).catch(err => undefined);

    await _ban.warmup();
  };


  const followers = ref<BanSyncFollowersDto[]>();

  onMounted(async () => {
    const result = await useCourtApi().ban.getFollowers();

    followers.value = result.data;
  });

  const getSubscriptionSubtext = (sub: BanSyncSubscriptionsDto) => {
    if (!sub.is_public) {
      return t('ban-sync-owner-stop-is-public'); 
    }

    if (!data.value?.is_public) {
      return t('ban-sync-you-are-not-public');
    }

    if (sub.auto_kick) {
      return t(`ban-sync-players-will-be-kicked`);
    }

    if (sub.send_notification) {
      return t('ban-sync-only-ban-notification');
    }

    return t('ban-sync-just-read-ban-list');
  };

  const createContext = (e: MouseEvent, data: BanSyncSubscriptionsDto) => {
    const _context = useContextMenu();

    _context.open(e, [{
      label : t('general.settings'),
      action: () => ActivateBanSyncEditModal(data)
    }, {
      label : t('general.unsubscribe'),
      action: () => {
        ActivateConfirmModal({
          type : 'warn',
          title: t('ban-sync-confirm-unsubscribe-title'),
          
          description  : t('ban-sync-confirm-unsubscribe-subtitle'),
          beforeConfirm: async () => {
            await useCourtApi().ban.deleteSubscriptions(data.project_id_original);

            await _ban.warmup();
          }
        });
      },
      class: 'destructive'
    }]);
  };
</script>

<template>
  <template v-if="data">
    <div class="section">
      <div class="header">
        <span class="title">
          {{ t('ban-sync-settings-header') }}
        </span>
        <span class="subtitle">{{ t('ban-sync-settings-subheader') }}</span>
      </div>

      <SettingsContainer colors="!bg-grey-900 !divide-grey-1000">
        <SwitchText
          v-model="data.is_public"
          :header="t('ban-sync-settings-is-public-header')"
          :placeholder="t('ban-sync-settings-is-public-subheader')"
          :subscription="'BanShare'"
          @click="sendUpdate"
        />
        <SwitchText
          v-model="data.share_details"
          :header="t('ban-sync-settings-share-details-header')"
          :placeholder="t('ban-sync-settings-share-details-subheader')"
          :disabled="!data.is_public"
          @click="sendUpdate"
        />
        <SwitchText
          v-model="data.share_moders"
          :header="t('ban-sync-settings-share-moders-header')"
          :placeholder="t('ban-sync-settings-share-moders-subheader')"
          :disabled="!data.is_public"
          @click="sendUpdate"
        />
      </SettingsContainer>

      <div v-if="data.is_public && (_panel.project?.avatar === useConfigStore().Avatars.LibraryProject[0])" class="warning">
        <p class="title">{{ t('ban-sync-page-warning-title') }}</p>
        
        <template v-if="_panel.project?.avatar === useConfigStore().Avatars.LibraryProject[0]">
          <p class="subtitle">{{ t('ban-sync-page-warning-subtitle-avatar') }}</p>
        </template>
        <template v-else-if="data.total_bans === 0">
          <p class="subtitle">{{ t('ban-sync-page-warning-subtitle-analyze') }}</p>
        </template>
      </div>
    </div>
  
    <Divider class="my-10" />

    <div class="section">
      <div class="header">
        <span class="title">
          {{ t('ban-sync-subscriptions-header') }}
        </span>
        <span class="subtitle">{{ t('ban-sync-subscriptions-subheader') }}</span>
      </div>

      <div class="items">
        <div class="add-action new" @click="ActivateBanSyncAvailableModal({ public: data.is_public })"> 
          <Svg.folders /> 
          <p>{{ t('ban-sync-subscription-browse') }}</p> 
        </div> 
        <template v-for="value in _ban.subscription" :key="value.project_id_original">
          <div class="item" :class="{ disabled: !data.is_public || !value.is_public }">
            <ProjectListItem 
              :project="value.project_original"
              :subtext="getSubscriptionSubtext(value)"
            />
            <div class="right" @click="e => createContext(e, value)">
              <Svg.dropdown />
            </div>
          </div>
        </template>
      </div>
    </div>

    <template v-if="followers?.length != 0 && data.is_public">
      <Divider class="my-10" />
    
      <div class="section">
        <div class="header">
          <span class="title">
            {{ t('ban-sync-followers-header') }}
          </span>
          <span class="subtitle">{{ t('ban-sync-followers-subheader') }}</span>
        </div>

        <div class="items">
          <template v-for="value in followers" :key="value.project_id_follower">
            <div class="item" :class="{ disabled: !data.is_public }">
              <ProjectListItem 
                :project="value.project_follower"
                :subtext="data.is_public ? t('ban-sync-page-item-subtext-subs', {time: _time.format(value.created_at)}) : t('ban-sync-page-item-subtext-not-shared')"
              />
            </div>
          </template>
        </div>
      </div>
    </template>
  </template>
</template>

<style lang="scss" scoped>
.section {
  @apply flex flex-col gap-5;
  
  .header {
    @apply flex flex-col gap-2;

    .title {
      @apply font-medium;
      @apply text-grey-50;
      @apply text-lg;
    }
    .subtitle {
      @apply text-grey-400;
      @apply text-sm;
    }
    .badge {
      @apply px-1 py-0.5 ml-2 text-sm rounded-md;
      @apply bg-lime-500/15;
      @apply text-lime-500;
      
      &.limit {
        @apply bg-amber-400/15;
        @apply text-amber-400;
      }
    }
  }

  .items {
    @apply flex flex-col gap-3;

    .item {
      @apply flex items-center justify-between overflow-hidden;
      @apply p-3;
      @apply rounded-md;
      @apply bg-grey-900;

      @apply border border-grey-900;
      
      &.disabled {
        background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
        @apply border border-red-500;
      }
      
      .right {
        @apply flex gap-2;

        svg {
          @apply w-5 fill-grey-600;
          @apply cursor-pointer;

          &:hover {
            @apply fill-grey-400;
          }
        }
      }
    }

    .add-action {
      @apply flex items-center justify-center gap-1 h-[64px] w-full;
      @apply rounded-md cursor-pointer;
      @apply bg-grey-950 border-2 border-dashed border-grey-900;
      @apply font-medium;

      &.new {
        @apply text-primary-700;

        svg {
          @apply w-5 fill-primary-700;
        }
      }

      &.list {
        @apply text-grey-500;

        svg {
          @apply w-5 fill-grey-500;
        }
      }

      &:hover {
        @apply border-grey-850;

        &.new {
          @apply text-primary-600;

          svg {
            @apply fill-primary-600;
          }
        }

        &.list {
          @apply text-grey-400;

          svg {
            @apply fill-grey-400;
          }
        }
      }
    }
  }
}

.warning {
  @apply p-4 rounded-md;
  @apply bg-grey-950;
  @apply border border-amber-500;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
  .title {
    @apply text-base font-medium text-grey-50 mb-1;
  }
  .subtitle {
    @apply text-sm font-normal;
    @apply text-grey-400;
  }
  .btn {
    @apply mt-4 px-2 py-1.5 #{!important};
    @apply text-sm;
  }
}
</style>