/* eslint-disable no-console */
import { DebugService } from "@/api/backend/debug.service";
import BanCreateModal from "@court/modals/ban-control/BanCreateModal.vue";
import BanEditModal from "@court/modals/ban-control/BanEditModal.vue";
import BanViewModal from "@court/modals/ban-view/BanViewModal.vue";
import IpDetailsModal from "@court/modals/ip-details/IpDetailsModal.vue";
import PlayerDetailsModal from "@court/modals/player-details/PlayerDetailsModal.vue";
import PlayerKickModal from "@court/modals/player-kick/PlayerKickModal.vue";
import PlayerSearchModal from "@court/modals/player-search/PlayerSearchModal.vue";
import ServerEditModal from "@court/modals/server-edit/ServerEditModal.vue";
import ServerPairModal from "@court/modals/server-pair/ServerPairModal.vue";
import ClientManageModal from "@settings/modals/permissions-edit/ClientManageModal.vue";
import AvatarPickerModal from "@src/modals/avatar-picker/AvatarPickerModal.vue";
import ClientDetailsModal from "@src/modals/client-details/ClientDetailsModal.vue";
import ClientInfoEditModal from "@src/modals/client-info-edit/ClientInfoEditModal.vue";
import ConfirmModal from "@src/modals/confirm/ConfirmModal.vue";
import ImageGalleryModal from "@src/modals/image-gallery/ImageGalleryModal.vue";
import ProjectCreateModal from "@src/modals/project-create/ProjectCreateModal.vue";
import PrivacyModal from "@src/views/landing/modals/PrivacyModal.vue";
import TermsModal from "@src/views/landing/modals/TermsModal.vue";
import PaymentsModal from "@src/views/panel/modules/settings/modals/payments/PaymentModal.vue";

import { defineStore } from "pinia";
import { Component, ref } from "vue";
import {  LocationQueryRaw, useRouter } from "vue-router";
import { useLocaleStore } from "./locale.store";
import { useRuntimeNotification } from "@/components/RuntimeNotification.vue";
import CourtPlanModal from "@court/modals/plan/CourtPlanModal.vue";
import RccBansModal from "@court/pages/player-alerts/modals/RccBansModal.vue";
import CommandExecuteModal from "@court/modals/commands/execute/CommandExecuteModal.vue";
import CommandCreateModal from "@court/modals/commands/create/CommandCreateModal.vue";
import CommandPresetModal from "@court/modals/commands/preset/CommandPresetModal.vue";
import NewsModal from "@src/modals/news/NewsModal.vue";
import EmailConfirmModal from "@src/modals/email-confirm/EmailConfirmModal.vue";
import ConnectTwoFactorModal from "@src/modals/2fa/ConnectTwoFactorModal.vue";
import EnterTwoFactorModal from "@src/modals/2fa/EnterTwoFactorModal.vue";
import DiscordWebhookModal from "@src/modals/discord-webhook/DiscordWebhookModal.vue";
import BanSyncAvailableModal from "@court/modals/ban-sync/ban-sync-available/BanSyncAvailableModal.vue";
import BanSyncEditModal from "@court/modals/ban-sync/ban-sync-edit/BanSyncEditModal.vue";
import RaBansModal from "@court/pages/player-alerts/modals/RaBansModal.vue";
import GlobalUnbanModal from "@court/modals/global-unban/GlobalUnbanModal.vue";
import CombatlogModal from "@court/modals/combatlog/CombatlogModal.vue";

export type ModalInjectedProps = {
  injected: {
    modal: Omit<ModalInstance<ModalType>, 'beforeClose' | 'afterClose'>;
    close: () => Promise<boolean>;

    visible: boolean;

    setBeforeClose: (cb?: () => Promise<boolean> | boolean) => void;
    setAfterClose: (cb?: () => Promise<void> | void) => void;
  }
};

/**
 * Опция открытия модалки (влияет на набор свойств у query)
 */
export type ModalRouteOption = string | true | false;

/**
 * Генератор наборов ключей для модалки
 */
export type ModalRouteKeys<Type extends ModalType, ModalRouteOption> = 
  ModalRouteOption extends string // ModalRouteKeys<'basic', 'test' | 'test-2'> -> 'basic' | 'basic-test' | 'basic-test-2'
    ? `${Type}-${ModalRouteOption}`
    : ModalRouteOption extends true // ModalRouteKeys<'basic', true> -> 'basic'
      ? Type
      : undefined; // ModalRouteKeys<'basic', false> -> undefined

/**
 * Из набор ключей генерирует объект, ключ - значение (строка) или undefined
 */
export type ModalRoute<Type extends ModalType, ModalRouteOption> = 
  ModalRouteKeys<Type, ModalRouteOption> extends string ? {
    [K in ModalRouteKeys<Type, ModalRouteOption>]: string
  } : undefined;

export type ModalDefinitionToPropMethod<Type extends ModalType, Query extends ModalRouteOption> = 
  (query: Partial<ModalRoute<Type, Query>>) => Query extends false ? undefined : Omit<ModalDefinitionProps[Type], keyof ModalInjectedProps> | false;
export type ModalDefinitionToQueryMethod<Type extends ModalType, Query extends ModalRouteOption> = 
  (props: ModalDefinitionProps[Type]) => Query extends false ? undefined : ModalRoute<Type, Query>;

/**
 * Набор параметров которые будут включены в любую модалку
 */
export type DefaultModalEvents = {
  afterClose?: () => Promise<unknown> | unknown
}

export class ModalDefinition<Type extends ModalType, Query extends ModalRouteOption> {
  static toQueryString(query: LocationQueryRaw) {
    if (Object.keys(query).length === 0) {
      return '';
    }
  
    return `?${Object.entries(query).map(([key, value]) => `&${key}=${value}`).join('').replace('&', '')}`;
  }

  type!: Type;
  component!: Component;

  toProp!: ModalDefinitionToPropMethod<Type, Query>;
  toQuery!: ModalDefinitionToQueryMethod<Type, Query>;

  options?: ModalOptions;

  constructor(plain: ModalDefinition<Type, Query>) {
    Object.assign(this, plain);
  }
};

export type ModalOptions = {
  transparent?: boolean;
  fullSizeMinWidth?: number;
};

export type ModalDefinitionProps = {
  // Выбор аватарки
  'avatar-picker': InstanceType<typeof AvatarPickerModal>['$props'],

  // Создание проекта
  'project-create': InstanceType<typeof ProjectCreateModal>['$props'],

  // Основная модалка игрока
  'player': InstanceType<typeof PlayerDetailsModal>['$props'],

  // Просмотр игроков с одинаковыми IP
  'player-ip': InstanceType<typeof IpDetailsModal>['$props'],

  // Кик игроков
  'player-kick': InstanceType<typeof PlayerKickModal>['$props'],

  // Поиск игроков
  'player-search': InstanceType<typeof PlayerSearchModal>['$props'],

  // Создание блокировки
  'ban-create': InstanceType<typeof BanCreateModal>['$props'],

  // Просмотр блокировки
  'ban-view': InstanceType<typeof BanViewModal>['$props'],

  // Редактирование блокировки
  'ban-edit': InstanceType<typeof BanEditModal>['$props'],

  // Модалка клиента (просмотр)
  'staff': InstanceType<typeof ClientDetailsModal>['$props'],

  // Модалка клиента (редактирование)
  'client-info-edit': InstanceType<typeof ClientInfoEditModal>['$props'],

  // Редактирование прав клиента проекта
  'client-permissions': InstanceType<typeof ClientManageModal>['$props'],

  // Галлерея
  'image-gallery': InstanceType<typeof ImageGalleryModal>['$props'],

  // Подключение сервера
  'server-pair': InstanceType<typeof ServerPairModal>['$props'],

  // Настройка сервера
  'server-edit': InstanceType<typeof ServerEditModal>['$props'],

  'confirm': InstanceType<typeof ConfirmModal>['$props'],

  // Политика конфиденциальности
  'privacy': InstanceType<typeof PrivacyModal>['$props'],

  // Пользовательское соглашение
  'terms': InstanceType<typeof TermsModal>['$props'],
  
  // Выбор тарифа
  'court-plan': InstanceType<typeof CourtPlanModal>['$props']

  // Модалка пополнения
  'payments': InstanceType<typeof PaymentsModal>['$props'],

  // Модалка банов из РЦЦ в алёртах
  'rcc-bans': InstanceType<typeof RccBansModal>['$props'],

  // Модалка банов из РЦЦ в алёртах
  'ra-bans': InstanceType<typeof RaBansModal>['$props'],

  // Выполнение команды
  'command-execute': InstanceType<typeof CommandExecuteModal>['$props'],

  // Создание команды
  'command-create': InstanceType<typeof CommandCreateModal>['$props'],

  // Пресеты для команд
  'command-preset': InstanceType<typeof CommandPresetModal>['$props'],

  // Новости
  'news': InstanceType<typeof NewsModal>['$props'],

  // Подтверждение почты
  'email-confirm': InstanceType<typeof EmailConfirmModal>['$props'],

  // 2fa setup
  '2fa-setup': InstanceType<typeof ConnectTwoFactorModal>['$props'],

  // 2fa enter
  '2fa-enter': InstanceType<typeof EnterTwoFactorModal>['$props'],

  'discord-webhook': InstanceType<typeof DiscordWebhookModal>['$props'],

  'ban-sync-available': InstanceType<typeof BanSyncAvailableModal>['$props'],

  'ban-sync-edit': InstanceType<typeof BanSyncEditModal>['$props'],

  'global-unban': InstanceType<typeof GlobalUnbanModal>['$props'],

  'combatlog': InstanceType<typeof CombatlogModal>['$props']
}

export const ModalDefinitionDictionary: { [K in ModalType]: ModalDefinition<K, any> } = {
  'avatar-picker': new ModalDefinition<'avatar-picker', false>({
    type     : 'avatar-picker',
    component: AvatarPickerModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'project-create': new ModalDefinition<'project-create', false>({
    type     : 'project-create',
    component: ProjectCreateModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'player': new ModalDefinition<'player', true>({
    type     : 'player',
    component: PlayerDetailsModal,

    toProp: (query) => {
      if (!query.player) {
        return false;
      }
      
      return { steamId: query.player, };
    },
    toQuery(props) {
      return {
        player: props.steamId,
      };
    },
    options: {  
      fullSizeMinWidth: 700, 
    } 
  }),
  'player-ip': new ModalDefinition<'player-ip', true>({
    type     : 'player-ip',
    component: IpDetailsModal,

    toProp(query) {
      if (!query["player-ip"]) {
        return false;
      }

      return { ip: query["player-ip"] };
    },
    toQuery(props) {
      return { "player-ip": props.ip };
    }
  }),
  'player-kick': new ModalDefinition<'player-kick', false>({
    type     : 'player-kick',
    component: PlayerKickModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'player-search': new ModalDefinition<'player-search', true>({
    type     : 'player-search',
    component: PlayerSearchModal,

    toProp: (query) => {
      return { };
    },
    toQuery: (props) => {
      return { 'player-search': '' };
    },

    options: { transparent: true }
  }),
  'ban-create': new ModalDefinition<'ban-create', false>({
    type     : 'ban-create',
    component: BanCreateModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'ban-view': new ModalDefinition<'ban-view', 'id' | 'project'>({
    type     : 'ban-view',
    component: BanViewModal,

    toProp: (query) => {
      if (!query['ban-view-id'] || !query['ban-view-project']) {
        return false;
      }

      return { banId: +query['ban-view-id'], projectId: +query['ban-view-project'] };
    },
    toQuery(props) {
      return {
        'ban-view-id'     : props.banId.toString(),
        "ban-view-project": props.projectId?.toString()
      };
    },
  }),
  'ban-edit': new ModalDefinition<'ban-edit', false>({
    type     : 'ban-edit',
    component: BanEditModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'staff': new ModalDefinition<'staff', true>({
    type     : 'staff',
    component: ClientDetailsModal,

    toProp: (query) => {
      if (!query['staff']) {
        return false;
      }

      return { clientSearch: query['staff'] };
    },
    toQuery: (props) => {
      return {
        "staff": props.clientSearch.toString() 
      };
    },
  }),
  'client-info-edit': new ModalDefinition<'client-info-edit', true>({
    type     : 'client-info-edit',
    component: ClientInfoEditModal,

    toProp: (query) => {
      return { };
    },
    toQuery: (props) => {
      return {"client-info-edit": '' };
    }
  }),
  'client-permissions': new ModalDefinition<'client-permissions', false>({
    type     : 'client-permissions',
    component: ClientManageModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'image-gallery': new ModalDefinition<'image-gallery', false>({
    type     : 'image-gallery',
    component: ImageGalleryModal, 

    toProp : () => undefined,
    toQuery: () => undefined,

    options: {
      transparent: true
    }
  }),
  'server-pair': new ModalDefinition<'server-pair', false>({
    type     : 'server-pair',
    component: ServerPairModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'server-edit': new ModalDefinition<'server-edit', false>({
    type     : 'server-edit',
    component: ServerEditModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'confirm': new ModalDefinition<'confirm', false>({ 
    type     : 'confirm',
    component: ConfirmModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'privacy': new ModalDefinition<'privacy', true>({
    type     : 'privacy',
    component: PrivacyModal,

    toProp: (query) => {
      return { };
    },
    toQuery: (props) => {
      return {"privacy": 'show' };
    }
  }),
  'terms': new ModalDefinition<'terms', true>({
    type     : 'terms',
    component: TermsModal,

    toProp: (query) => {
      return { };
    },
    toQuery: (props) => {
      return {"terms": 'show' };
    }
  }),
  'court-plan': new ModalDefinition<'court-plan', true>({
    type     : 'court-plan',
    component: CourtPlanModal,

    toProp: (query) => {
      return { landing: query["court-plan"] == "true" };
    },
    toQuery: (props) => {
      return {"court-plan": props.landing.toString() };
    }
  }),
  'payments': new ModalDefinition<'payments', true>({
    type     : 'payments',
    component: PaymentsModal,

    toProp: (query) => {
      return { };
    },
    toQuery: (props) => {
      return {"payments": 'show' };
    }
  }),
  'rcc-bans': new ModalDefinition<'rcc-bans', false>({
    type     : 'rcc-bans',
    component: RccBansModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }), 
  'ra-bans': new ModalDefinition<'ra-bans', false>({
    type     : 'ra-bans',
    component: RaBansModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }), 
  'command-execute': new ModalDefinition<'command-execute', false>({
    type     : 'command-execute',
    component: CommandExecuteModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'command-create': new ModalDefinition<'command-create', false>({
    type     : 'command-create',
    component: CommandCreateModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'command-preset': new ModalDefinition<'command-preset', false>({
    type     : 'command-preset',
    component: CommandPresetModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'news': new ModalDefinition<'news', true>({
    type     : 'news',
    component: NewsModal,

    toProp: (query) => {
      if (!query['news']) {
        return false;
      }

      return { newsId: +query['news'] };
    },
    toQuery: (props) => {
      return {
        news: props.newsId.toString()
      };
    }
  }),
  'email-confirm': new ModalDefinition<'email-confirm', false>({
    type     : 'email-confirm',
    component: EmailConfirmModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  '2fa-setup': new ModalDefinition<'2fa-setup', false>({
    type     : '2fa-setup',
    component: ConnectTwoFactorModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  '2fa-enter': new ModalDefinition<'2fa-enter', false>({
    type     : '2fa-enter',
    component: EnterTwoFactorModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'discord-webhook': new ModalDefinition<'discord-webhook', false>({
    type     : 'discord-webhook',
    component: DiscordWebhookModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'ban-sync-available': new ModalDefinition<'ban-sync-available', false>({
    type     : 'ban-sync-available',
    component: BanSyncAvailableModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'ban-sync-edit': new ModalDefinition<'ban-sync-edit', false>({
    type     : 'ban-sync-edit',
    component: BanSyncEditModal,

    toProp : () => undefined,
    toQuery: () => undefined
  }),
  'global-unban': new ModalDefinition<'global-unban', true>({
    type     : 'global-unban',
    component: GlobalUnbanModal,

    toProp: () => {
      return {};
    },
    toQuery: () => {
      return { 'global-unban': 'yes' };
    }
  }),
  'combatlog': new ModalDefinition<'combatlog', false>({
    type     : 'combatlog',
    component: CombatlogModal,

    toProp : () => undefined,
    toQuery: () => undefined
  })
};

export type ModalType = keyof ModalDefinitionProps;

export class ModalInstance<T extends ModalType> {
  public definition: ModalDefinition<T, any>;
  public props: ModalDefinitionProps[T];

  private overrideInternal?: boolean;

  beforeClose?: () => Promise<boolean> | boolean;
  afterClose?: () => Promise<void> | void;

  constructor(defintion: ModalDefinition<any, any>, props: ModalDefinitionProps[T], virtual?: boolean) {
    this.definition = defintion;
    this.props = props;

    this.overrideInternal = virtual;
  }

  public get id() {
    return ModalDefinition.toQueryString(this.definition.toQuery(this.props) ?? { [this.definition.type]: '' });
  }

  public get isVirtual() {
    if (this.overrideInternal) {
      return this.overrideInternal;
    }

    return this.definition.toProp({}) === undefined;
  }

  public isEqual(query: LocationQueryRaw) {
    return ModalDefinition.toQueryString(query).replace('?', '').replace('&', '').replace('&', '').replace('&', '').replace('&', '').replace('&', '').includes(this.id.replace('?', '').replace('&', '').replace('&', '').replace('&', '').replace('&', ''));
  }
}

export const useModalsStore = defineStore('modals', () => {
  const debug = new DebugService(`Modal:#fcba03`);

  const { t } = useLocaleStore();
  const firstPageHistory = ref<number>();
  const _router = useRouter();

  const cachedRoutedModals = ref<ModalInstance<any>[]>([]);
  const cachedVirtualModals = ref<ModalInstance<any>[]>([]);

  const closeVirtualModal = async (): Promise<boolean | undefined> => {
    const [modal] = cachedVirtualModals.value;

    const canClose = await closeModal(modal);
    if (canClose) {
      cachedVirtualModals.value.splice(0, 1);

      try {
        debug.log(`🎊 Вызываем событие "после закрытия"`);

        modal.afterClose?.();
      }
      catch(err) {
        debug.error(`Ошибка на событии "после закрытия модалки"`, err);
      }
    } 

    return canClose;
  };

  const closeRoutedModal = async (query: LocationQueryRaw): Promise<boolean | undefined> => {
    const modal = cachedRoutedModals.value.find(v => v.isEqual(query));

    const canClose = await closeModal(modal);
    if (canClose) {
      cachedVirtualModals.value.splice(0, 1);

      try {
        debug.log(`🎊 Вызываем событие "после закрытия"`);

        modal?.afterClose?.();
      }
      catch(err) {
        debug.error(`Ошибка на событии "после закрытия модалки"`, err);
      }
    } 

    return canClose;
  };

  const closeModal = async (modal?: ModalInstance<ModalType>): Promise<boolean | undefined> => {
    if (!modal) {
      return undefined;
    }
    debug.log(`Происходит попытка закрыть модалку ${modal.id} [Виртуальная: ${modal.isVirtual}]`);

    if (!modal.beforeClose) {
      debug.log(`У модалки нет защитника перед закрытием`);
      return true;
    }

    debug.log(`Обработка защитника модалки`);
    const close = await modal.beforeClose();
    if (!close) {
      debug.warn(`Защитник модалки запретил закрытие модалки`);
      return false;
    }

    debug.log(`Защитник разрешил закрытие модалки`);
    return true;
  };

  _router.beforeEach(async (to, from) => {
    if (!firstPageHistory.value) {
      firstPageHistory.value = history.state.position;
    }

    if (!Object.keys(to.query) && !Object.keys(from.query).length) {
      // Игнорируем простые переходы
      return;
    }

    debug.log(`🛬 Навигация: ${from.fullPath} на ${to.fullPath}`, `${firstPageHistory.value} - ${history.state.position} - ${history.length}`);

    // Если есть открытая виртуальная модалка, то перед любым переходом мы сначала закрываем её
    const override = await closeVirtualModal();

    if (override !== undefined) {
      return false;
    }  

    if (Object.keys(from.query).length > 0) {
      const override = await closeRoutedModal(from.query);
      if (override === false) {
        return false;
      }  
    }

    if (Object.keys(to.query).length > 0) {
      const ready = createFromQuery(to.query);
      if (ready === null) {        
        if (!from.name) {
          debug.log(`Не удалось открыт модалку по ссылке, а страница открыта впервые. Чиним навигацию...`);

          return { ...to, query: {} };
        }

        useRuntimeNotification('warning', t('general.modal-not-recoverable'));
        return false;
      } else if (ready === false) {
        if (cachedRoutedModals.value.length > 0) {
          cachedRoutedModals.value = [];
    
          debug.log(`✅ Судя по всему, навигация между модалками завершена`);
        }
      }
    } else {
      if (cachedRoutedModals.value.length > 0) {
        cachedRoutedModals.value = [];
  
        debug.log(`✅ Судя по всему, навигация между модалками завершена`);
      }
    }
  });

  const createFromQuery = (query: LocationQueryRaw): boolean | null => {
    debug.log(`~ Проверяем соответствие модалки и нового пути`, query);

    const keys = Object.keys(query);
    if (!keys.length) {
      // Это значит что нет Query у запроса, скорее всего модалка создаваться и не должна
      return false;
    }

    const exists = cachedRoutedModals.value.find(v => v.isEqual(query));
    if (exists) {
      debug.log(`↖️ Модалка существует в кэше, ничего не делаем...`);
      return true;
    }

    const definition = Object.values(ModalDefinitionDictionary).find(v => keys.find(k => k === v.type)) ?? Object.values(ModalDefinitionDictionary).find(v => keys.find(k => k.startsWith(v.type)));
    if (!definition) {
      debug.log(`⚠️ Обнаружены ключи, однако ни один из них не подходит под условия модалок`, definition);
      return false;
    }

    const props = definition.toProp(query as any);
    if (!props && typeof props !== 'object') {
      debug.log(`⚠️ Данная модалка не может быть открыта при помощи ссылки`, props);
      return null;
    } else {
      const modal = new ModalInstance(definition, props);

      cachedRoutedModals.value.push(modal);

      debug.log(`🆕 Создали новую модаль по текущему роуту`);

      return true;
    }
  };

  const createFromArgs = async <T extends ModalType>(type: T, props: Omit<ModalDefinitionProps[T], keyof ModalInjectedProps>, virtual?: boolean) => {
    debug.log(`⏩ Создаём модаль из аргументов`, { type, props, virtual });

    const definition = ModalDefinitionDictionary[type];
  
    const modal = new ModalInstance(definition, props, cachedVirtualModals.value.length ? true : undefined);

    const query = modal.definition.toQuery(props) as any as LocationQueryRaw;

    const exists = [...cachedRoutedModals.value, ...cachedVirtualModals.value].find(v => v.id === modal.id);
    if (!exists) {
      debug.log(`🆕 Создали новую модаль по параметрам`);

      if (modal.isVirtual) {
        cachedVirtualModals.value.unshift(modal);
      } else {
        cachedRoutedModals.value.push(modal);
      }
    } else {
      debug.log(`↖️ Обновили параметры для этой модалки`);

      exists.props = props;
    }

    if (!modal.isVirtual) {
      await _router.push({ query });
    }

    return modal;
  };

  const open = async <T extends ModalType>(type: T, props: Omit<ModalDefinitionProps[T], keyof ModalInjectedProps>, virtual?: boolean) => {
    await createFromArgs(type, props, virtual);
  };

  const close = async (escape?: boolean) => {
    debug.log(`↘️ Закрытие модалки по команде на сайте, ESC: `, !!escape);

    const override = await closeVirtualModal();
    if (override !== undefined) {
      return false;
    }

    if (escape) {
      if (cachedVirtualModals.value.length) {
        const override = await closeVirtualModal();
        if (override !== undefined) {
          return true;
        }
      }

      debug.log(`✅ Модалка будет закрыта при помощи навигации "вперёд"`);
      await _router.push({ query: {} });
      return true;
    }

    if (history.state.position === firstPageHistory.value) {
      debug.log(`🍉 Ситуация, в которой мы не можем закрыть модалку кнопкой назад`);
      
      await _router.push({ query: {} });
    } else {
      debug.log(`✅ Модалка будет закрыта при помощи навигации "назад"`);
      _router.back();
    }

    return true;
  };

  return { 
    cachedRoutedModals,
    cachedVirtualModals,

    open, 
    close,

    confirm,
  };
});

