<script setup lang="ts">
  import { useConfigStore } from '@/stores/config/config.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  
  defineProps<{ header: string; label?: string }>();

  const { t } = useLocaleStore();
  const _config = useConfigStore();
</script>

<template>
  <div>
    <p class="label">{{ label }}</p>
    <div class="upload-area">
      <div class="upload-preview">
        <Svg.photo class="fill-grey-350" style="padding: 22%;" />
      </div>
      <div class="upload-text">
        <p class="font-semibold text-grey-400 truncate">
          {{ header }}
        </p>
        <span class="text-sm text-grey-600 truncate">
          {{ t('uploader.max-size-text', { size: _config.Uploader.UploaderMaxSize }) }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.label {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}
.upload-area {
  @apply flex flex-row p-4;
  @apply overflow-hidden;
  @apply items-center;
  @apply rounded-md;
  @apply cursor-pointer;
  @apply border-dashed border-2 border-grey-750;
  @apply transition-all ease-in-out delay-0 duration-200;

  &:hover {
    @apply border-grey-650;
  }
  .upload-preview {
    @apply bg-white/10;
    @apply aspect-square;
    @apply mr-3;
    @apply rounded-full;
    @apply flex justify-center items-center;
    @apply text-lg font-semibold;

    width: 50px;
    height: 50px;
  }

  .upload-text {
    @apply flex flex-col;
    @apply leading-6 truncate;
  }
}
</style>