<script setup lang='ts'>
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import Switch from '@/components/switch/Switch.vue';
  import Button from '@/components/Button.vue';
  import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';
  import { ActivateCommandCreateModal } from '@court/modals/commands/create/CommandCreateModal.vue';
  import { computed } from 'vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { useContextMenu } from '@/stores/contextmenu.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const _context = useContextMenu();
  const props = defineProps<{ command: CourtCommandDto; onDeleted: () => void }>(); 

  const toggle = async () => {
    const newVal = !props.command.active;

    Object.assign(props.command, { active: newVal });

    await useCourtApi().commands.toggle(props.command.id, newVal);
  };

  const menu = computed((): ExtendedMenuItem[] => {
    return [{
      label : t('general.edit'),
      action: () => ActivateCommandCreateModal(props.command)
    }, {
      label : t('general.delete'),
      action: async () => {
        await useCourtApi().commands.delete(props.command.id);

        props.onDeleted();
      },
      class: 'destructive'
    }];
  });
</script>

<template>
  <div
    class="action relative"
  >
    <ListItem
      :svg="{ component: Svg.code_brackets, classes: 'fill-grey-600' }"
      :title="command.name"
      :message="command.group"
      :skeleton="'avatar'"
    />
    <div class="right">
      <Button @click="toggle"> 
        <Switch
          ref="child"
          :readonly-value="command.active"
        />
      </Button>
      <Svg.dropdown @click="(e: MouseEvent) => _context.open(e, menu)" />
    </div>
    <div class="badge" :class="command.access" />
  </div>
</template>

<style lang='scss' scoped>
.action {
  @apply flex items-center justify-between gap-2 overflow-hidden;
  @apply p-3;
  @apply rounded-md;
  @apply bg-grey-900;
  @apply cursor-grab;

  :deep(.img-box) {
    @apply bg-grey-1000 p-2;
  }
  
  .right {
    @apply flex gap-2;

    svg {
      @apply w-5 fill-grey-600;
      @apply cursor-pointer;

      &:hover {
        @apply fill-grey-400;
      }
    }
  }

  .badge {
    position: absolute;
    left: 0px;
    @apply h-[20px] w-[2px];
    @apply rounded-r-full;

    &.green {
      @apply bg-lime-500;
      box-shadow: #84cc16 0px 0px 12px 0.5px;
    }
    &.yellow {
      @apply bg-amber-500;
      box-shadow: #f59e0b 0px 0px 12px 0.5px;
    }
    &.red {
      @apply bg-red-500;
      box-shadow: #ff5031 0px 0px 12px 0.5px;
    }
    &.admin {
      @apply bg-grey-200;
      box-shadow: #C5C5C5 0px 0px 12px 0.5px;
    }
  }
}
</style>