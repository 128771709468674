<script setup lang='ts'>
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import { onMounted, watch } from 'vue';
  import { useClientComposable } from './client.composable';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  const props = defineProps<{ 
    clientId?: number, 
    preload?: ClientDto & { steam_id?: string };
    noAvatar?: boolean;
    disableClick?: boolean;
  }>();

  const { client, load } = useClientComposable();
  
  onMounted(async () => {
    if (props.preload) {
      client.value = props.preload;
    }

    await load(props.clientId);
  });

  watch(() => props.clientId, (v) => load(v));
</script>

<template>
  <p
    v-if="client"
    class="client"
    @click="!disableClick && ActivateClientDetailsModal(client.tag, client)"
  >
    <img
      v-if="!noAvatar"
      class="avatar mr-1"
      :src="useConfigStore().Urls.Images(client?.avatar ?? '')"
    >
    <span class="nickname">{{ client?.name }}</span>
  </p>
</template>

<style lang='scss' scoped>
.client {
  @apply flex items-center;
  .avatar {
    @apply bg-white/5;
    @apply inline rounded-full;
    @apply aspect-square object-cover;
    height: 1em;
  }
}
</style>