<script setup lang="ts">
  import type { PlayerDetailsDto, PlayerFullDto } from '@/api/backend/court/player/player.dto';

  import Loading from '@/components/Loading.vue';

  import ModalMenuExtended from '@/components/modal-menu-extended/ModalMenuExtended.vue';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useDocumentVisibility, useIntervalFn, useTimeoutFn } from '@vueuse/core';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Button from '@/components/Button.vue';
  import PlayerBox from '../PlayerBox.vue';
  import { usePlayerMenu } from '@court/menus/player.menu';
  import PlayerNavigationGeneral from './navigation/general/PlayerNavigationGeneral.vue';
  import { usePlayerDetailsNavigationDictionary } from './navigation/player-navigation.dto';
  import PlayerDetailsLinks from './PlayerDetailsLinks.vue';
  import { usePlayerStatusStore } from '@court/stores/player-status.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import PlayerNavigationTeam from './navigation/team/PlayerNavigationTeam.vue';
  import PlayerNavigationChecks from './navigation/PlayerNavigationChecks.vue';
  import PlayerNavigationAlerts from './navigation/PlayerNavigationAlerts.vue';
  import PlayerNavigationBans from './navigation/bans/PlayerNavigationBans.vue';
  import PlayerNavigationSignages from './navigation/signages/PlayerNavigationSignages.vue';
  import PlayerNavigationReports from './navigation/reports/PlayerNavigationReports.vue';
  import PlayerNavigationHistory from './navigation/history/PlayerNavigationHistory.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import ModalMenuExtendedNavigationButton from '@/components/modal-menu-extended/ModalMenuExtendedNavigationButton.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ActivatePlayerKickModal } from '@court/modals/player-kick/PlayerKickModal.vue';
  import PlayerNavigationStats from './navigation/stats/PlayerNavigationStats.vue';

  export type PlayerDetailsBasic = {
    steamId: string;
    player?: PlayerFullDto;
  };

  const AUTO_UPDATE_INTERVAL = 5_000;

  const props = defineProps<{
    initial?: PlayerDetailsBasic;
    defaultSection?: string;
    noHeader?: boolean;
    visible?: boolean;
    mobileWidth: number; 
    useBigHeader?: boolean
  }>();

  const { t } = useLocaleStore();
  const _permissions = usePermissionsStore();
  const _playerStatus = usePlayerStatusStore();
  const _visibility = useDocumentVisibility();
  const _playerMenu = usePlayerMenu();

  const details = ref<PlayerDetailsDto>();

  const maxActualPlayer = computed(() => {
    if (details.value) {
      return _playerStatus.getPlayerWithStatus(details.value.player);
    }

    if (props.initial?.player) {
      return _playerStatus.getPlayerWithStatus(props.initial.player);
    }

    return null;
  });  

  onMounted(async () => {
    await updatePlayerDetails('initial');
  });
  

  watch(() => props.initial?.steamId, () => updatePlayerDetails('propsSteamId'));

  watch(() => maxActualPlayer.value?.status, (n, o) => {
    if (!o) {
      return;
    }

    updatePlayerDetails('statusChanged');
  });

  watch(() => props.visible, () => {
    updatePlayerDetails('visible change');
  });

  const updatePlayerDetails = async (reason: string, force: boolean = false) => {
    if (isUnloaded) {
      timeout.stop();
      return;
    }

    timeout.stop();

    try {
      if (!props.initial || (!props.visible && !props.noHeader)) {
        return;    
      } 

      if (_visibility.value === 'hidden' ) {
        if (!force) {
          return; 
        }  
      }

      const update = await useCourtApi().player.getDetails(props.initial.steamId).catch(err => {
        if (err.message != 'Player not found') {
          throw err;
        }

        emits('load-error');
        return undefined;
      });

      if (!update) {
        return;
      }

      details.value = update;
    }
    finally {
      const movedAgoSeconds = details?.value?.entity?.last_moved_seconds ?? 0;
      const compensation = movedAgoSeconds > AUTO_UPDATE_INTERVAL ? 0 : movedAgoSeconds;

      timeout.stop();
      timeout = useTimeoutFn(() => updatePlayerDetails('auto'), AUTO_UPDATE_INTERVAL - compensation);
    }    
  };

  var isUnloaded = false;

  onUnmounted(() => {
    isUnloaded = true;

    timeout.stop();
  });

  let timeout = useTimeoutFn(() => updatePlayerDetails('auto'), AUTO_UPDATE_INTERVAL);; 

  const emits = defineEmits(['load-error', 'badgeUpdate']);

  const checkNavigation = ref<InstanceType<typeof PlayerNavigationChecks>>();
  const banNavigation = ref<InstanceType<typeof PlayerNavigationBans>>();

  defineExpose({ maxActualPlayer });
</script>
 
<template>
  <div
    :class="['player-modal', { big: useBigHeader }]" 
  >
    <Loading :can-view="!!maxActualPlayer" class="!bg-grey-900" />

    <template v-if="maxActualPlayer">
      <ModalMenuExtended 
        :headers="Object.values(usePlayerDetailsNavigationDictionary())"
        :mobile-width="mobileWidth"
        :no-header="noHeader"
        :default-section="'general'"
        :header-image="maxActualPlayer.steam_avatar"
        :dropdown-fn="(e: any) => _playerMenu.open(e, maxActualPlayer!, details?.team ?? [])"
      >
        <template #header="{ isMobile }">
          <PlayerBox
            class="player"
            :vertical="isMobile"
            :player="maxActualPlayer"
            disable-hover
            disable-click
          />
        </template>

        <template #nav>
          <PlayerBox
            :player="maxActualPlayer"
            size="m-small"
            hide-text
            :custom-click="e => maxActualPlayer ? _playerMenu.open(e, maxActualPlayer) : undefined"
          />
        </template>

        <template #custom-menu>
          <ModalMenuExtendedNavigationButton 
            v-if="_permissions.can(['Court_CheckProcess']) && maxActualPlayer && maxActualPlayer.status != 'offline'" 
            mobile
            no-arrow
            @click="_playerMenu.startCheck(maxActualPlayer)"
          >
            <Svg.square_checklist_magnifying_glass />
            <p>{{ t('modal.player.header.check') }}</p>
          </ModalMenuExtendedNavigationButton>

          <ModalMenuExtendedNavigationButton
            v-if="_permissions.can(['Court_PlayerBan'])" 
            preset="destructive"
            mobile
            no-arrow
            @click="_playerMenu.playerBan(maxActualPlayer, details?.team)"
          >
            <Svg.lock />
            <p>{{ t('modal.player.header.ban') }}</p>
          </ModalMenuExtendedNavigationButton>

          <ModalMenuExtendedNavigationButton
            v-if="_permissions.can(['Court_PlayerKick']) && maxActualPlayer && maxActualPlayer.status != 'offline'" 
            preset="destructive"
            mobile
            no-arrow
            @click="ActivatePlayerKickModal({ player: maxActualPlayer })"
          >
            <Svg.logout />
            <p>{{ t('modal.player.header.kick') }}</p>
          </ModalMenuExtendedNavigationButton>
        </template>

        <template #actions="{ isMobile }">
          <div class="actions">
            <PlayerDetailsLinks :is-mobile="isMobile" :player="maxActualPlayer" />

            <Button 
              v-if="!isMobile"
              preset="default-light"
              class="btn"
              @click="(e) => _playerMenu.open(e, maxActualPlayer!, details?.team ?? [])"
            >
              <Svg.dropdown />
            </Button>
          </div>
        </template>
        <template #general="{ isMobile }">
          <PlayerNavigationGeneral
            :is-mobile="isMobile"
            :player="maxActualPlayer"
            :entity="details?.entity ?? null"
            :team="details?.team ?? null"
            :active-ban="banNavigation?.activeBan"
            :last-check="checkNavigation?.lastCheck"
          />
        </template>
        <template #team="{ isMobile, setBadge }">
          <PlayerNavigationTeam
            :size="maxActualPlayer?.team.length ?? 0"
            :team="details?.team ?? null"
            :player="maxActualPlayer"
            @badge-update="(e) => setBadge(e)"
          />
        </template>
        <template #history="{ isMobile }">
          <PlayerNavigationHistory
            :steam-id="maxActualPlayer.steam_id" 
          />
        </template>
        <template #reports="{ isMobile, setBadge }">
          <PlayerNavigationReports
            :steam-id="maxActualPlayer.steam_id"
            @badge-update="(e) => setBadge(e)"
          />
        </template>
        <template #checks="{ isMobile, setBadge }">
          <PlayerNavigationChecks
            ref="checkNavigation"
            :steam-id="maxActualPlayer.steam_id" 
            @badge-update="(e) => setBadge(e)"
          />
        </template>
        <template #alerts="{ isMobile }">
          <PlayerNavigationAlerts :steam-id="maxActualPlayer.steam_id" />
        </template>
        <template #bans="{ isMobile, setBadge }">
          <PlayerNavigationBans
            ref="banNavigation"
            :steam-id="maxActualPlayer.steam_id"
            @badge-update="(e) => setBadge(e)"
          />
        </template>
        <template #stats>
          <PlayerNavigationStats 
            :steam-id="maxActualPlayer.steam_id"
          />
        </template>
        <template #signs>
          <PlayerNavigationSignages
            :steam-id="maxActualPlayer.steam_id"
          />
        </template>
      </ModalMenuExtended>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.player-modal {
  @apply h-full;

  min-width: 250px;

  @apply flex flex-col;
  @apply relative;

  &.big {
    height: 100%;
    max-height: 100%;
  }
}

.actions {
  @apply flex gap-2 items-center;
  @apply overflow-hidden;
  z-index: 10;

  .btn {
    @apply w-full p-1.5 #{!important};

    svg {
      @apply w-5;
    }
  }
}

.content {
  @apply p-5;
}

.player {
  :deep(.message) {
    @apply text-grey-50/50 #{!important};
  }
}
</style>