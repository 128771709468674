import { DebugService } from "./debug.service";

export type PaginatedResponse<T> = {
  results: T[];
  limit: number;
  page: number;
  total?: number;
}

export type PaginationQuery = {
  page: number;
  limit: number;
}

export type PaginatedResults<T> = PaginatedResponse<T> & {
  done: boolean;
}

export type PaginationCallback<T> = Promise<PaginatedResponse<T>>;

export class PaginationService<T> {
  public id = Math.random().toString();

  public page = -1;
  public total?: number;

  public request: Promise<PaginatedResponse<T>> | null = null;
  
  // Означает что все данные с этого пагинаторы были загружены
  public done = false;

  constructor(
    public callback: (page: number, limit: number) => PaginationCallback<T>,
    public limit: number = 50
  ) {}

  public async load(page: number): Promise<PaginatedResponse<T>> {
    if (page > this.page + 1) {
      DebugService.warn(`Вызов запроса с неследующей страницей`);
    }

    this.page = page;

    if (this.request) {
      DebugService.log(`Обнаружен незавершённый запрос. Дожидаемся завершения.`, { page });
      await this.request;
      DebugService.log(`Запрос завершился, выполняем следующий...`, { page });
    }

    const request = new Promise<PaginatedResponse<T>>(async (res, rej) => {
      try {
        const results = await this.callback(page, this.limit);

        this.request = null;
  
        res(results);
      }
      catch (err) {
        rej(err);
      } 
    });

    this.request = request;

    return await request;
  }

  public async next(): Promise<PaginatedResults<T>> {
    if (this.done) {
      return { results: [], done: true, limit: this.limit, page: this.page, total: this.total };
    }

    const response = await this.load(this.page + 1);

    this.total = response.total;
    this.limit = response.limit;
    this.done = response.results.length < this.limit;

    return { results: response.results, limit: this.limit, done: this.done, page: this.page, total: response.total };
  }
}