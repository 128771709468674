<script setup lang='ts'>
  import { useTimeStore } from '@/stores/time.store';

  defineProps<{
    reversed: boolean;
    title?: string;
    subtitle?: string;
    circleClass?: string;
    created: number;
  }>();

  const _time = useTimeStore();
</script>

<template>
  <div class="activity-item" :class="{ reversed }">
    <p class="text-white/15 tabular-nums text-sm" :title="_time.format(created, 'DD.MM.YY # HH:mm:ss', true)">
      {{ _time.format(created, 'HH:mm:ss') }}
    </p>
    <div class="point-box">
      <template v-if="$slots.circle">
        <div class="slot-circle">
          <slot name="circle" />
        </div>
      </template>

      <template v-else>
        <div class="circle" :class="circleClass" />
      </template>
    </div>

    <div class="content">
      <template v-if="$slots.content">
        <slot name="content" />
      </template>

      <template v-else>
        <div class="title">
          <template v-if="title">
            {{ title }}
          </template>
          <template v-else-if="$slots.title">
            <slot name="title" />
          </template>
        </div>

        <div class="subtitle">
          <template v-if="subtitle">
            {{ subtitle }}
          </template>
          <template v-else-if="$slots.subtitle">
            <slot name="subtitle" />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang='scss' scoped>

$circle-size: 33px;
$gap-size: 25px;

$circle-line-space: 4px;
.activity-item {
  @apply w-full flex items-center gap-3;
  @apply relative;

  .point-box {
    min-width: $circle-size;
    width: $circle-size;
    height: 100%;
    flex: 0 1 $circle-size;
    @apply flex items-center justify-center;
    @apply relative;

    &::before {
      content: '';
      @apply bg-grey-850;
      @apply -z-10;
      @apply absolute;
      width: 2px;
      left: calc(($circle-size - 1px) / 2);
      top: 0;
      height: calc(100% / 2);
    }

    &::after {
      content: '';
      @apply bg-grey-850;
      @apply -z-10;
      @apply absolute;
      width: 2px;
      left: calc(($circle-size - 1px) / 2);
      bottom: 0;
      height: calc(100% / 2);
    }

    .slot-circle {
      @apply rounded-half;
      @apply bg-grey-900;
      @apply ring-4 ring-grey-1000;
    }
    .circle {
      min-width: 14px;
      width: 14px;
      height: 14px;
      @apply border-2 border-transparent;
      @apply ring-4 ring-grey-1000;
      @apply rounded-full;
    }
  }
  .content {
    flex: 1 1;
    padding-bottom: calc($gap-size / 2);
    padding-top: calc($gap-size / 2);
    .title {
      @apply text-grey-50;
      @apply text-lg;
      @apply font-medium;
    }

    .subtitle {
      @apply text-grey-400;

      .name {
        @apply text-grey-450 underline-offset-4 underline;
      }
    }
  }
  
  &:last-child {
    .point-box {
      &::after {
        content: none;
      }
    }

    &.reversed {
      .point-box {
        &::before {
          content: none;
        }
        &::after {
          content: '';
        }
      }
    }
  }

  &:first-child {
    .point-box::before {
      content: none;
    }
    &.reversed {
      .point-box {
        
        &::after {
          content: none;
        }
      }
      &:not(:last-child) {
        .point-box {
          &::before {
            content: '';
          }
        }
      }
    }
  }
}
</style>