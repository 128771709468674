
import { Axios } from "axios";
import type { ServerEditDto, ServerPairDto, ServerFinishConnectPayload, ServerFullDto } from "./servers.dto";

export const useCourtServersApi = (api: Axios) => {
  const get = async () => {
    const result = await api.get<ServerFullDto[]>("/server");

    return result.data;
  };

  const startPair = async () => {
    const result = await api.post<ServerPairDto>("/server/pair");

    return result.data;
  };

  const getPairData = async (code: string) => {
    const result = await api.get<ServerPairDto>(`/server/pair`, { params: { code }});

    return result.data;
  };

  const completePair = async (payload: ServerFinishConnectPayload) => {
    const result = await api.put<boolean>('/server/pair', payload);

    return result.data;
  };

  const editOrder = async (serverIdsInOrder: number[]) => {
    const result = await api.put<boolean>('/server/save-order', null, {
      params: {
        'server-ids-in-order': serverIdsInOrder
      }
    });

    return result.data;
  };

  const getConfirmation = async (serverId: number) => {
    const result = await api.get<boolean>(`/server/${serverId}/confirmation`);

    return result.data;
  };

  const edit = async (serverId: number, data: ServerEditDto) => {
    await api.put<void>(`/server/${serverId}`, data);
  };

  const deleteServer = async (serverId: number) => {
    await api.delete<void>(`/server/${serverId}`);
  };

  return {
    get,
    startPair,
    getPairData,
    completePair,
    editOrder,
    getConfirmation,
    edit,
    deleteServer,
  };
};
