import { useAppStore } from '@/stores/app.store';
import { createRouter, createWebHistory } from 'vue-router';
import { DebugService } from '@/api/backend/debug.service';
import { useTitle } from '@vueuse/core';
import { TypeTranslateComposer, useLocaleStore } from '@/stores/locale.store';
import { ClientRouter, ClientRoutes } from './views/client/client.routes';
import { PanelRouter } from '@panel/panel.routes';
import { CourtRoutes } from '@court/court.routes';
import { SettingsRoutes } from '@settings/settings.routes';
import { usePanelStore } from '@panel/stores/panel.store';
import { LandingRouter } from './views/landing/landing.routes';

const whiteList = ['/', '/client/auth', '/pricing'];

export type SiteRoutes = CourtRoutes | SettingsRoutes | ClientRoutes;
const translations: Record<SiteRoutes, (t: TypeTranslateComposer) => string> = {
  [CourtRoutes.Alerts]   : (t) => t('site-title.variable.router-alerts'),
  [CourtRoutes.Bans]     : (t) => t('site-title.variable.router-bans'),
  [CourtRoutes.Chat]     : (t) => t('site-title.variable.router-chat'),
  [CourtRoutes.Checks]   : (t) => t('site-title.variable.router-checks'),
  [CourtRoutes.Dashboard]: (t) => t('site-title.variable.router-dashboard'),
  [CourtRoutes.Welcome]  : (t) => t('site-title.variable.router-welcome'),
  [CourtRoutes.Players]  : (t) => t('site-title.variable.router-players'),
  [CourtRoutes.Reports]  : (t) => t('site-title.variable.router-reports'),
  [CourtRoutes.Stats]    : (t) => t('site-title.variable.router-stats'),
  [CourtRoutes.Audit]    : (t) => t('site-title.variable.audit'),
  [CourtRoutes.Servers]  : (t) => t('site-title.variable.router-servers'),
  [CourtRoutes.Discord]  : (t) => t('site-title.variable.router-discord'),
  [CourtRoutes.Signages] : (t) => t('site-title.variable.signages'),
  [CourtRoutes.Settings] : (t) => t('site-title.variable.router-court-settings'),

  [SettingsRoutes.About]  : (t) => t('site-title.variable.router-settings-about'),
  [SettingsRoutes.Billing]: (t) => t('site-title.variable.router-settings-billing'),
  [SettingsRoutes.Clients]: (t) => t('site-title.variable.router-settings-clients'),

  [ClientRoutes.Auth]   : (t) => t('site-title.variable.router-client-auth'),
  [ClientRoutes.Profile]: (t) => t('site-title.variable.router-client-profile'),
};

export const useAppRouter = () => {
  const router = createRouter({
    history: createWebHistory(),
    routes : [LandingRouter, ClientRouter, PanelRouter],
  });

  router.beforeEach(async (to, from): Promise<any> => {
    const debug = new DebugService(`RouteGuard:#8BC94E`);

    const _app = useAppStore();
    const _panel = usePanelStore();

    if (!_app.isReady) {
      await _app.warmup();
    }

    if (to.params.project === 'last') {
      const id = localStorage.getItem('last_project_id');
      if (id) {
        const byId = _app.projects?.find(v => v.id.toString() === id);
        if (byId) {
          return { ...to, params: { project: byId.domain }};
        }
      }
    }

    const byId = _app.projects?.find(v => v.id.toString() === to.params.project);
    if (byId) {
      return { ...to, params: { project: byId.domain }};
    }

    if (to.params.project && _panel.project?.domain !== to.params.project) {
      _panel.updateProject(to.params.project as string);
    }

    // Бяка кака, по другому не придумал :/
    const isInitial = !from.params.project;
    const toWithExtraParams = Object.keys(to.params).length > 1;
    const fromWithExtraParams = Object.keys(from.params).length > 1;

    if (!isInitial && toWithExtraParams) {
      const sameProject = to.params.project === from.params.project;

      if (!sameProject && fromWithExtraParams) {
        return { name: to.name, params: { project: to.params.project }};
      }
    }

    if (!_app.isAuthed && !whiteList.includes(to.path)) {
      debug.error(`Подготовка не завершена, просмотр запрещенной страницы. Переход на авторизацию`);
      return { name: ClientRoutes.Auth };
    }

    return true;
  });

  router.afterEach((to, from) => {
    if (to.path != from.path || Object.keys(to.query).length === 0) {
      const { t } = useLocaleStore();

      const translate = translations[to.name as SiteRoutes]?.(t);
      if (!translate) {
        useTitle(t('site-title.general-without-dot'));
        return;
      }
      
      useTitle(t('site-title.general', { variable: translate }));
    }
  });

  return router;
};
