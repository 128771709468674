<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import Divider from '@/components/Divider.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import TitledPage from '@/components/layout/TitledPage.vue';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import { useSingleFileDialog } from '@/components/quality/uploader/utils/uploader.composable';
  import { ProjectCreateOrUpdatePayload } from '@/api/backend/core/project/project.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useValidators } from '@src/composable/validate.composable';
  import { computed, onMounted, ref, watch } from 'vue';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { useConfigStore } from '@/stores/config/config.store';
  import { EnvVariables } from '@/stores/config/env.variables';

  const { t } = useLocaleStore();
  const _panel = usePanelStore();
  const _validators = useValidators();
  const _permissions = usePermissionsStore();

  const { uploaded, open } = useSingleFileDialog('avatar-project', { accept: 'image/*' }, _panel.project?.avatar);
  
  const loadedInfo = ref<ProjectCreateOrUpdatePayload>();
  const info = ref<ProjectCreateOrUpdatePayload>({
    name       : '',
    domain     : '',
    avatar     : '',
    contact_url: ''
  });

  onMounted(async () => {
    if (!_panel.project?.id) {
      return;
    }

    const { name, domain, avatar, contact_url} = _panel.project;
    loadedInfo.value = {
      name       : name,
      domain     : domain,
      avatar     : avatar,
      contact_url: contact_url
    };

    info.value = {...loadedInfo.value};
  });
  
  const avatarUrl = computed(() => {
    if (uploaded.value.file && !uploaded.value.s3_id) {
      return undefined;
    }

    if (uploaded.value.s3_id) {
      return useConfigStore().Urls.Images(uploaded.value.s3_id);
    }

    return useConfigStore().Urls.Images(info.value.avatar || '');
  });

  watch(() => avatarUrl.value, (newValue) => {
    if (newValue) {
      info.value.avatar = newValue;
    }
  });

  const nameRef = ref<InstanceType<typeof TextInput>>();
  const domainRef = ref<InstanceType<typeof TextInput>>();
  const contactRef = ref<InstanceType<typeof TextInput>>();

  const canSave = computed(() => {
    if (nameRef.value?.activeException || domainRef.value?.activeException || contactRef.value?.activeException) {
      return false;
    }

    if (!loadedInfo.value) {
      return false;
    }

    return somethingChanged.value;
  });

  const somethingChanged = computed(() => {
    if (!loadedInfo.value) {
      return false;
    }

    const originalCopy = JSON.stringify(loadedInfo.value);
    const infoCopy = JSON.stringify(info.value);

    return originalCopy !== infoCopy;
  });

  const saveSettings = async () => {
    if (!canSave.value || !_panel.project?.id) {
      return;
    }
    try {
      await useCoreApi().project.updateDetails(_panel.project.id, info.value);

      window.location.href = window.location.href.replace(_panel.project.domain, info.value.domain);
    } catch (error: any) {

      if (error.message?.[0]?.includes('domain should not be one of the following') || error.message == 'Possible domain is busy') {
        useRuntimeNotification('warning', t('project-create-domain'));
        return;
      }

    } 
  };

  const beta = () => { 
    ActivateConfirmModal({ 
      type       : 'warn', 
      title      : t('project-settings.confirm.beta-title'), 
      description: t('project-settings.confirm.beta-subtitle'),
    }); 
  }; 
</script>

<template>
  <TitledPage 
    :top-title="t('project-settings.header.title')" 
    :top-subtitle="t('project-settings.header.subtitle')"
  >
    <div class="section">
      <p class="text-head">
        {{ t('project-settings.logo.title') }}
      </p>
      <div class="logo-uploader">
        <AvatarExtended
          :url="avatarUrl" 
          active
          class="logo-avatar"
          @click="open()"
        /> 
        <p>
          <span class="uploader-title ">
            {{ t('project-settings.logo.uploader.title') }}
          </span>
          <span class="uploader-subtitle ">
            {{ t('project-settings.logo.uploader.subtitle') }}
          </span>
        </p>
      </div>
    </div>

    <Divider class="my-8" />

    <div class="section">
      <p class="text-head">
        {{ t('project-settings.general.title') }}
      </p>
      <TextInput
        ref="nameRef"
        v-model="info.name"
        theme="middle"
        :label="t('modal.project-create.name.label')"
        :placeholder="t('modal.project-create.name.placeholder')"
        :validator="_validators.ProjectName"
        no-border
      />

      <TextInput
        ref="domainRef"
        v-model="info.domain"
        theme="middle"
        :label="t('modal.project-create.domain.label')"
        :placeholder="t('modal.project-create.tag.placeholder')"
        :validator="_validators.ProjectDomain"
        no-border
        no-padding
      >
        <div>
          <span class="text-grey-400">{{ EnvVariables.DomainSite }}/</span>
        </div>
      </TextInput>

      <TextInput
        ref="contactRef"
        v-model="info.contact_url"
        theme="middle"
        :label="t('modal.project-create.url.label')"
        :placeholder="t('modal.project-create.url.placeholder')"
        :validator="_validators.Url"
        no-border
      />
      <Button
        v-if="somethingChanged"
        :disabled="!canSave"
        preset="primary"
        :text="t('general.save')"
        class="w-fit"
        :action="saveSettings"
      />
    </div>

    <Divider v-if="_permissions.isOwner()" class="my-8" />

    <div v-if="_permissions.isOwner()" class="section">
      <div class="page-tip">
        <p class="title">{{ t('project-settings.delete.title') }}</p>
        <p class="subtitle">{{ t('project-settings.delete.subtitle') }}</p>
        <Button
          :text="t('project-settings.delete.button')"
          preset="destructive"
          class="btn"
          :action="beta"
        />
      </div>
    </div>
  </TitledPage>
</template>

<style lang='scss' scoped>
.section {
  @apply flex flex-col gap-5;

  .text-head {
    @apply font-medium;
    @apply text-grey-50;
    @apply text-lg;
  }

  .logo-uploader {
    @apply flex items-center gap-5;
    .logo-avatar {
      @apply w-20 h-20 #{!important};
      @apply cursor-pointer;

      &:hover {
        @apply opacity-75;
      }
    }

    .uploader-title {
      @apply text-18-400 text-grey-50 mb-1 block;
    }
    .uploader-subtitle {
      @apply text-16-400 text-grey-400;
    }
  }
}
</style>