<script lang="ts">
  import { computed } from 'vue';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Step } from '@/components/stepper/stepper.dto';
  import StepperCard from '@/components/stepper/StepperCard.vue';
  import DiscordWebhookInfo from './components/DiscordWebhookInfo.vue';
  import DiscordWebhookOptions from './components/DiscordWebhookOptions.vue';
  import { ref } from 'vue';
  import { onMounted } from 'vue';
  import { DicordWebhookDetails } from '@court/pages/integrations/types/discord-webhook.dto';
  import { CourtDiscordDto, CourtDiscordType } from '@/api/backend/court/court-discord/court-discord.dto';
  import { watch } from 'vue';
  import { isURL } from 'class-validator';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import axios from 'axios';
  import { useCourtApi } from '@/api/backend/court/court.api';

  export type DiscordWebhookProps = {
    details: DicordWebhookDetails<CourtDiscordType>;
    value?: CourtDiscordDto;
  }

  export const ActivateDiscordWebhookModal = (details: DicordWebhookDetails<CourtDiscordType>, value?: CourtDiscordDto) => {
    const _modal = useModalsStore();

    _modal.open('discord-webhook', { details, value });
  };

</script>

<script setup lang="ts">
  const { t } = useLocaleStore();

  const props = defineProps<DiscordWebhookProps & ModalInjectedProps>();

  const webhookData = ref<CourtDiscordDto>({
    active  : true,
    data    : {},
    language: 'ru',
    webhook : '',
    type    : 'Ban'
  });

  onMounted(() => mergeData());
  watch(() => props.value, () => mergeData(), { deep: true });

  const mergeData = () => webhookData.value = Object.assign(webhookData.value, props.value ?? { type: props.details.type });
  
  const computedSteps = computed(() => {
    const result: Step<'info' | 'options'>[] = [];

    result.push({
      name  : 'info',
      header: {
        title   : t('dw-modal-info-title'),
        subtitle: t('dw-modal-info-subtitle'),
        svg     : props.details.icon,
        svgOpts : 'w-10 h-10 bg-grey-1000 rounded-half p-2 fill-grey-600'
      },
      nextDisabledCondition: () => {
        if (!isURL(webhookData.value.webhook)) {
          return true;
        }
        
        return false;
      },
      nextMiddleware: async () => { 
        const response = await axios.get(webhookData.value.webhook).catch(err => {
          return undefined;
        });
        if (!response?.data?.channel_id) {
          useRuntimeNotification(`warning`, `Вы вставили неверную ссылку на веб-хук`);
          return false;
        }

        return true;
      },
    });

    result.push({
      name  : 'options',
      header: {
        title   : t('dw-modal-opts-title'),
        subtitle: t('dw-modal-opts-subtitle'),
        svg     : props.details.icon,
        svgOpts : 'w-10 h-10 bg-grey-1000 rounded-half p-2 fill-grey-600'
      }
    });

    return result;
  });

  const updater = ref<() => void>();
  const setUpdater = (func: () => void) => {
    updater.value = func;
  };

  const finish = async () => {
    await useCourtApi().discord.upsertDiscord(webhookData.value);

    useRuntimeNotification('success', t('general.settings-saved'));

    await (window as any).refreshDiscordPage?.();

    props.injected.close();
  };

</script>

<template>
  <div class="discord-webhook-modal">
    <StepperCard
      :steps="computedSteps"
      :callback="finish"
      @updater="setUpdater"
    > 
      <template #info>
        <DiscordWebhookInfo v-model="webhookData" />
      </template>
    
      <template #options>
        <DiscordWebhookOptions
          v-model="webhookData"
          :details="details"
          :updater="updater"
        />
      </template>
    </StepperCard>
  </div>
</template>

<style lang="scss" scoped>
.discord-webhook-modal {
  width: 95vw;
  max-width: 450px;

  min-height: 230px;
}
</style>