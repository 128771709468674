<script setup lang='ts'>
  import { CentrifugoChatMessage } from '@/api/centrifugo/centrifugo.dto';
  import { useChatStore } from '../stores/chat.store';
  import ChatMessageDropdown from './ChatMessageDropdown.vue';
  import Highlighter from 'vue-highlight-words';
  import { computed, onMounted, ref } from 'vue';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useClientComposable } from '@court/components/client/client.composable';
  import { useChatMessageMenu } from '@court/menus/chat-message.menu';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useConfigStore } from '@/stores/config/config.store';

  const _config = useConfigStore();
  const _chat = useChatStore();
  const _time = useTimeStore();
  const _chatMessageMenu = useChatMessageMenu();

  const props = defineProps<{ msg: CentrifugoChatMessage, server?: string }>();

  const splitMessageByEmoji = computed(() => {
    const parts: { msg: CentrifugoChatMessage; text?: string; emoji?: string }[] = [];

    const detectedEmoji = props.msg.text.match(/:(\w|\.)*:/gm);

    if (!detectedEmoji?.length) {
      return [{ msg: props.msg, text: props.msg.text }];
    }

    let modifiedText = props.msg.text;
    const obj = { msg: props.msg, text: undefined, emoji: undefined };

    detectedEmoji.forEach(rawEmoji => {
      const emoji = rawEmoji.replace(':', '').replace(':', '');

      const indexOf = modifiedText.indexOf(rawEmoji);

      const beginningText = modifiedText.slice(0, indexOf);
      if (beginningText.length) {
        parts.push({ ...obj, text: beginningText });
      }

      parts.push({ ...obj, emoji: emoji });

      modifiedText = modifiedText.slice(indexOf + rawEmoji.length);
    });

    // Если остался текст в конце
    if (modifiedText.length) {
      parts.push({ ...obj, text: modifiedText });
    }

    return parts;
  });

  const emojiLoadErrors = ref<Record<string, boolean>>({});
  const getEmojiLink = (emoji: string) => {
    const cachedEmoji = _chat.emojiDictionary[emoji];
    if (cachedEmoji) {
      return cachedEmoji;
    }

    // Emoji судя по всему будет предметом
    return `https://www.rustedit.io/images/imagelibrary/${emoji}.png`;
  };


  const { client, load } = useClientComposable();

  onMounted(() => {
    if (!isModerator.value) {
      return;
    }

    load(+props.msg.steam_id);
  });

  const isModerator = computed(() => props.msg.steam_id.length != 17);

  const message = computed<CentrifugoChatMessage>(() => {
    if (!client.value) {
      return props.msg;
    }

    const message = JSON.parse(JSON.stringify(props.msg)) as CentrifugoChatMessage;

    message.steam_name = props.msg.steam_name;
    message.steam_avatar = client.value.avatar ? useConfigStore().Urls.Images(client.value.avatar) : _config.Branding.RustAppAvatar;

    return message;
  });
</script>

<template>
  <div class="flex items-center">
    <div>
      <span
        v-if="_chat.design.time"
        class="text-grey-600 inline mr-1.5 tabular-nums"
        :title="_time.format(msg.created_at, 'DD.MM.YYYY # HH:mm:ss', true, true)"
      >{{ _time.format(msg.created_at, 'DD.MM.YY HH:mm', false, true) }}</span>

      
      <span v-if="msg.type == 'Team'" class="font-medium text-lime-400 mr-1.5">{{ `[TEAM]` }}</span>
      <span
        v-if="msg.type == 'Direct'"
        :class="[isModerator ? 'text-primary-500' : 'text-amber-200', 'font-medium mr-1.5']"
      >{{ isModerator ? `[Reply]` : `[PM]` }}</span>

      <span
        style="font-size: 18px;"
      >
        <ChatMessageDropdown :msg="message" @contextmenu="(e) => _chatMessageMenu.open(e, msg, false)" />
        
        <template v-if="msg.target_steam_id && msg.target_steam_name && msg.target_steam_avatar">
          <Svg.arrow class="inline fill-grey-500 w-4" />

          <ChatMessageDropdown
            :msg="msg"
            target
            @contextmenu="(e) => _chatMessageMenu.open(e, msg, true)"
          />
        </template>

        <span class="font-normal">: </span>

        <!-- eslint-disable-next-line vue/valid-v-for -->
        <template v-for="(part, _) in splitMessageByEmoji" :key="`${msg.target_steam_id}-${part.msg.created_at}-${part.text ?? part.emoji}`">
          <template v-if="part.text">
            <Highlighter
              v-slot="items"
              :search-words="_chat.combinedCensureRegex"
              :text-to-highlight="part.text ?? part.emoji ?? ''" 
            >
              <template v-for="{ chunk, text } in items" :key="`${part.msg.text}-${part.msg.created_at}-${JSON.stringify(chunk)}-${_}`">
                <span
                  :class="[_chat.design.invertColors ? 'text-grey-50' : 'text-grey-400', { 
                    'censure': chunk.highlight,
                    'custom': _chat.customCensureRegex.some(v => v.toLowerCase().includes(text.toLowerCase()))
                  }]"
                  style="word-break: break-word"
                >{{ text }}</span>
              </template>
            </Highlighter>
          </template>
          <template v-else-if="part.emoji">
            <template v-if="_chat.design.emojiView == 'preview' && !emojiLoadErrors[part.emoji]">
              <TooltipBase
                trigger="hover"
                distance="10"
                class="inline"
              >
                <img
                  :src="getEmojiLink(part.emoji)"
                  class="inline"
                  :on-error="() => emojiLoadErrors[part.emoji!] = true"
                  style="height: 1.2225em; margin-bottom: 0.188em;"
                >
                <template #content>
                  <div class="flex flex-col items-center p-2">
                    <img
                      :src="getEmojiLink(part.emoji)"
                      class="h-12 w-12"
                      :on-error="() => emojiLoadErrors[part.emoji!] = true"
                      style="margin-bottom: 0.188em;"
                    >
                    <div class="emoji">
                      <span style="max-width: 100px;">:{{ part.emoji }}:</span>
                      <span class="text-grey-400">{{ 'emoji' }}</span>
                    </div>
                  </div>
                </template>
              </TooltipBase>
            </template>
            <template v-else-if="_chat.design.emojiView == 'hash' || emojiLoadErrors[part.emoji!] || splitMessageByEmoji.length == 1">
              <span :class="[_chat.design.invertColors ? 'opacity-100' : 'opacity-50', 'italic font-thin']">:{{ part.emoji }}:</span>
            </template>
          </template>
        </template>

        <template v-if="server && _chat.design.server">
          <span class="opacity-10 ml-1">[{{ server }}]</span>
        </template>
      </span>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.censure {
  @apply underline underline-offset-4 opacity-100;
  @apply text-red-500;

  &.custom {
    @apply text-amber-500;
  }
}

.emoji {
  @apply flex flex-col text-center;
  @apply text-sm text-grey-50;
  @apply break-all;
}
</style>