<script lang="ts" setup>
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useTimeStore } from '@/stores/time.store';
  import { useLocaleStore } from '@/stores/locale.store';

  import ClientInline from '@court/components/client/ClientInline.vue';
  import { useUnknownClient } from '@court/utils/player.utils';
  import { computed } from 'vue';

  const props = defineProps<{
    ban: BanDto
  }>();

  const _time = useTimeStore();
  const { t } = useLocaleStore();

  const isUnbanned = computed(() => !props.ban.computed_is_active);
  const isUnbannedByClient = computed(() => !props.ban.computed_is_active && props.ban.unban_time);

</script> 

<template>
  <template v-if="isUnbanned || isUnbannedByClient">
    <div class="row">
      <template v-if="!ban.unban_time">
        {{ t('bans.status-unbanned-time-text') }}
      </template>
      <template v-else>
        <template v-if="ban.unban_client_id !== undefined">
          <template v-if="ban.unban_client_id">
            <ClientInline
              no-avatar
              :client-id="ban.unban_client_id"
              class="client"
            />
          </template>
          <template v-else>
            <ClientInline 
              no-avatar
              :preload="useUnknownClient().generate()"
            />
          </template>
        </template>

        <span>
          {{ ' ' + t('bans.status-unbanned-client-text') + ' ' }} 
        </span>

        <span>
          {{ _time.format(ban.unban_time) }}
        </span>
      </template>
    </div>
  </template>
</template>

<style scoped lang="scss">
.row {
  @apply overflow-hidden;
  @apply bg-emerald-500/20 text-emerald-500;
  @apply text-center w-full mb-4;
  @apply p-2;
  @apply rounded-md;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, #10b9810d 20px, #10b9810d 40px);
}

.client {
  @apply inline;
  @apply cursor-pointer;
  @apply font-medium hover:text-emerald-500;
  @apply underline underline-offset-2;
}
</style>