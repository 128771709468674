<script setup lang="ts">
  import GallerySwiper from '@/components/quality/gallery-swiper/GallerySwiper.vue';
  import { SignageDto } from '@/api/backend/court/signages/signages.dto';
  import { useTimeStore } from '@/stores/time.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { computed, ref } from 'vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import Highlight from '@/components/Highlight.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { useSignagesStore } from '../stores/signages.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';
  import Divider from '@/components/Divider.vue';

  const _court = useCourtStore();
  const _signages = useSignagesStore();

  const { t } = useLocaleStore();
  const props = defineProps<{ signage: SignageDto; search?: string; removed: () => void; likeOnly: boolean; profile: boolean }>();
  
  const orderedVariants = computed(() => {
    return [...props.signage.variants].sort((a, b) => b.created_at - a.created_at);
  });

  const gallery = ref<InstanceType<typeof GallerySwiper>>();

  const activeUser = computed(() => {
    return orderedVariants.value[gallery?.value?.index || 0]?.player ?? useUnknownPlayer();
  });

  const _time = useTimeStore();
  const activeTime = computed(() => {
    return orderedVariants.value[gallery.value?.index ?? 0]?.created_at ?? Date.now();
  });

  const remove = async () => {
    try {
      await useCourtApi().signages.deleteOne(props.signage.server_id, props.signage.net_id);
    }
    catch (err: any) {
      if (!err?.message?.includes('опция доступна')) {
        throw err;
      }
    }

    Object.assign(props.signage, {
      destroyed_at: Date.now()
    });

    props.removed();
    useRuntimeNotification('success', t('signaged-removed'));
  };

  const like = async () => {
    if (props.likeOnly && props.signage.liked_at) {
      const result = await ActivateConfirmModal({
        type       : 'warn',
        title      : t('general.sure'),
        description: t('signage-can-be-losed'),
        onConfirmed: () => {}
      });

      if (!result) {
        return;
      }
    }

    Object.assign(props.signage, {
      liked_at: props.signage.liked_at ? null : Date.now()
    });
    
    useCourtApi().signages.like(props.signage.server_id, props.signage.net_id, !!props.signage.liked_at).catch(err => {
      if (!err?.message?.includes('опция доступна')) {
        throw err;
      }
    });

    useRuntimeNotification(props.signage.liked_at ? 'success' : 'warning', props.signage.liked_at ? t('signaged-liked') : t('signaged-disliked'));
  };

  const variant = computed(() => {
    const fitArr = ['photoframe.portrait', 'sign.pole.banner.large', 'sign.hanging.banner.large', 'sign.huge.wood', 'sign.pictureframe.tall', 'sign.pictureframe.portrait', 'spinner.wheel.deployed'];

    if (fitArr.includes(props.signage.type)) {
      return 'fit';
    }

    return 'cover';
  });
</script>

<template>
  <div class="signage-card" :class="{ profile: profile }">
    <div>
      <GallerySwiper
        ref="gallery"
        :images="orderedVariants.map(v => useConfigStore().Urls.Images(v.url))"
        class="gallery"
        :variant="variant"
      />
    </div>

    <div v-if="!profile" class="footer">
      <div class="flex justify-between">
        <PlayerBox
          class="max-w-[200px]"
          :player="activeUser"
          :search="search ? [search] : undefined"
          :subtext="_time.format(activeTime, 'DD.MM.YY # HH:mm', true, true)"
        />
        <div class="buttons">
          <Button
            :preset="!!signage.liked_at ? 'liked' : 'default'"
            class="!p-1.5 !rounded-none"
            :action="like"
          >
            <Svg.heart_solid />
          </Button>
          <Button
            v-if="!_signages.filters.only_liked"  
            preset="destructive"
            class="!p-1.5 !rounded-none"
            :disabled="!!signage.destroyed_at || !!signage.liked_at"
            :action="remove"
          >
            <Svg.delete />
          </Button>
        </div>
      </div>

      <div v-if="!_signages.filters.only_liked" class="meta-info">
        <div class="row">
          <p class="text">{{ t('signage-card-type') }}</p>
          <p class="value">{{ signage.type }}</p>
        </div>
        <div v-if="!_signages.filters.server_id" class="row">
          <p class="text">{{ t('signage-card-server') }}</p>
          <p class="value">
            {{ _court.serverNameById(signage.server_id) }}
          </p>
        </div>
        <div class="row">
          <p class="text">{{ t('signage-card-square') }}</p>
          <TooltipBase trigger="hover">
            <p
              class="value copy"
              @click="useCopyClipboard(`teleportpos ${signage.position.replace(' ', '').replace(' ', '')}`, true)"
            >
              <Highlight
                v-if="signage.square"
                :visible-text="signage.square || ''"
                :search="search?.length ? [search] : undefined"
              />
              <Svg.copy />
            </p>
       
            <template #content> 
              <div class="px-1.5 py-1 text-xs text-center max-w-[240px]"> 
                <p>{{ `teleportpos ${signage.position.replace(' ', '').replace(' ', '')}` }}</p> 
              </div> 
            </template> 
          </TooltipBase>
        </div>
      </div>
    </div>

    <div v-else class="footer">
      <div class="meta-info">
        <div class="row">
          <p class="text">{{ t('signage-card-type') }}</p>
          <p class="value">{{ signage.type }}</p>
        </div>
        <div class="row">
          <p class="text">{{ t('signage-card-server') }}</p>
          <p class="value">
            {{ _court.serverNameById(signage.server_id) }}
          </p>
        </div>
        <div v-if="!signage.destroyed_at" class="row">
          <p class="text">{{ t('signage-card-square') }}</p>
          <TooltipBase trigger="hover">
            <p
              class="value copy"
              @click="useCopyClipboard(`teleportpos ${signage.position.replace(' ', '').replace(' ', '')}`, true)"
            >
              <Highlight
                v-if="signage.square"
                :visible-text="signage.square || ''"
                :search="search?.length ? [search] : undefined"
              />
              <Svg.copy />
            </p>
       
            <template #content> 
              <div class="px-1.5 py-1 text-xs text-center max-w-[240px]"> 
                <p>{{ `teleportpos ${signage.position.replace(' ', '').replace(' ', '')}` }}</p> 
              </div> 
            </template> 
          </TooltipBase>
        </div>
        <div class="row">
          <p class="text">{{ t('signage-card-date') }}</p>
          <p class="value">{{ _time.format(activeTime, 'DD.MM.YY # HH:mm', true, true) }}</p>
        </div>
      </div>
      <div v-if="!signage.destroyed_at" class="buttons">
        <Button
          :preset="!!signage.liked_at ? 'liked' : 'default-light'"
          class="!p-1.5 flex-shrink-0 !rounded"
          :action="like"
        >
          <Svg.heart_solid />
        </Button>
        <Button
          preset="destructive"
          class="!p-1.5 w-full !rounded"
          :text="!signage.destroyed_at ? t('general.delete') : t('general.nonexistent')"
          :disabled="!!signage.destroyed_at || !!signage.liked_at"
          :action="remove"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.signage-card {
  @apply flex flex-col w-full h-fit overflow-hidden;
  @apply bg-grey-900 rounded-md;

  .gallery {
    @apply h-[200px] overflow-hidden;
    background-image: radial-gradient(#A6A09A 1px, #CDC6BF 1px);
    background-size: 10px 10px;
  }

  .footer {
    @apply flex flex-col gap-5 p-5;

    .buttons {
      @apply flex overflow-hidden flex-shrink-0 gap-1 h-fit;
      @apply rounded-md;
    }

    .meta-info {
      @apply flex flex-col gap-1;

      .row {
        @apply flex justify-between gap-4;

        .text {
          @apply text-grey-500 truncate;
        }
        .value {
          @apply flex items-center gap-1 flex-shrink-0;
          @apply text-grey-200 truncate;

          svg {
            @apply w-4;
            @apply fill-grey-500;
          }

          &.copy {
            @apply cursor-pointer;

            &:hover {
              svg {
                @apply fill-grey-200;
              }
            }
          }
        }
      }
    }
  }

  &.profile {
    @apply bg-grey-850;

    .gallery {
      @apply h-[140px];
    }

    .footer {
      @apply p-2.5;
      @apply gap-2.5;

      .row {
        @apply text-sm;

        .value {
          svg {
            @apply w-3.5;
          }
        }
      }

      .buttons {
        @apply gap-2.5 rounded-none;

        :deep(.ra-button) {
          &.disabled {
            @apply bg-grey-900;
          }
        }
      }
    }
  }
}
</style>