<script setup lang='ts'>
  import Skeleton from '@/components/Skeleton.vue';
  import TableBase, { TableHeaders } from '@/components/table/TableBase.vue';
  import { OperationDepositeMeta, OperationRenewalMeta, TransactionDto } from '@/api/backend/core/project/project.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useTimeStore } from '@/stores/time.store';
  import { useCourtSubscriptionStore } from '@court/stores/subscription.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCoreApi } from '@src/api/backend/core/core.api';
  import { useMoney } from '@src/composable/money.composable';
  import { computed, onMounted, ref } from 'vue';


  export type TransactionHeaders = TableHeaders<'date' | 'name' | 'sum'>

  const _panel = usePanelStore();
  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _subscription = useCourtSubscriptionStore();
  const { toLocaleBalance, toMajorUnits } = useMoney();

  const transactions = ref<TransactionDto<object>[] | null>(null);
  
  const loader = computed(() => {
    return new PaginationService<TransactionDto<object>>((page) => useCoreApi().project.getOperations(_panel.project!.id, page));
  });

  onMounted(async () => {
    if (!_panel.project?.id) {
      return;
    }
  });

  const next = async () => {
    if (!_panel.project) {
      return;
    }
    const result = await loader.value.next();
    
    if (!transactions.value) {
      transactions.value = [];
    }

    transactions.value.push(...result.results.filter(v => v.success));
  };
  
  const headers = computed((): TransactionHeaders => {
    return {
      date: {
        text: t('payments.history.date'),
        min : '150px',
        max : '180px',
      },
      name: {
        text: t('payments.history.name'),
        min : '200px',
        max : '100%'
      },
      sum: {
        text : t('payments.history.sum'),
        min  : '100px',
        max  : '150px',
        right: true
      }
    };
  });

  const getTransactionDetails = (transaction: TransactionDto<object>) => {
    switch (transaction.meta.action) {
      case 'renewal': {
        return getRenewalDetails(transaction as TransactionDto<OperationRenewalMeta>);
      }

      case 'refund': {
        return getRefundDetails(transaction as TransactionDto<OperationRenewalMeta>);
      }

      case 'deposite': {
        return getDepositeDetails(transaction as TransactionDto<OperationDepositeMeta>);
      }
    }

    switch (transaction.type) {
      case 'in': {
        return getDefaultIn(transaction);
      }
      case 'out': {
        return getDefaultOut(transaction);
      }
    }
  };

  const getRenewalDetails = (transaction: TransactionDto<OperationRenewalMeta>) => {
    let target: string = t('general.unknown');

    switch (transaction.meta.target) {
      case 'court': {
        target = t('payments.target.court');
        break;
      }
    }

    _subscription;
    const realType = transaction.meta.type.replace(/\d/, '');
    const type = realType.charAt(0).toUpperCase() + realType.slice(1);

    const header = t('payments.history.renewal', { target, type });
    
    //const subtext = dayjs.duration(transaction.meta.renewal_interval).humanize(false);
    const subtext = `${t('tariff-default')} - ${type.toLowerCase()}`;

    return { header, subtext };
  };

  const getRefundDetails = (transaction: TransactionDto<OperationRenewalMeta>) => {
    const target = t('payments.target.court');

    const realType = transaction.meta.type.replace(/\d/, '');
    const type = realType.charAt(0).toUpperCase() + realType.slice(1);

    const header = t('payments.history.refund', { target, type: type });
  
    //const subtext = dayjs.duration(transaction.meta.renewal_interval).humanize(false);
    const subtext = `${t('tariff-default')} - ${type.toLowerCase()}`;

    return { header, subtext };
  };

  const getDepositeDetails = (transaction: TransactionDto<OperationDepositeMeta>) => {
    let header = t('payments.history.deposite-concrete');

    const subtext = `${transaction.meta.method} • ${transaction.meta.transaction_id}`;

    return { header, subtext };
  };

  const getDefaultOut = (transaction: TransactionDto<any>) => {
    const header = t('payments.history.withdraw');
    
    const subtext = transaction.reason;

    return { header, subtext };
  };

  const getDefaultIn = (transaction: TransactionDto<any>) => {
    const header = t('payments.history.deposite');

    const subtext = transaction.reason;

    return { header, subtext };
  };
   
</script>

<template>
  <div class="payments-tab-history">
    <div class="payments-table">
      <TableBase
        :headers="headers"
        :items="transactions"
        :next="next"
        :style="{ 'height': transactions?.length ? 'calc(80vh - 200px)' : '400px', 'min-height': '300px' }"
        :item-key="(o) => o.id.toString()"
    
        missing-data-type="empty"
        :missing-data-title="t('general.no-data-header')"
        :missing-data-subtitle="t('general.no-data-subtitle')"
      >
        <template #date="{item}">
          <template v-if="!item">
            <Skeleton class="!w-32 !h-5 rounded-md" />
          </template>
          <template v-else>
            <p class="text-grey-400 text-16-400">
              {{ _time.format(item.created_at, 'DD MMM HH:mm', true) }}
            </p>
          </template>
        </template>
        <template #name="{item}">
          <template v-if="!item">
            <Skeleton class="!w-32 !h-5 rounded-md" />
          </template>
          <template v-else>
            <p
              class="text-grey-50 text-16-400"
              :class="{ 'line-through': !item.success }"
              v-html="getTransactionDetails(item)?.header"
            />
            <p class="text-xs opacity-20">
              {{ !item.success ? (item.type == 'in' ? t('payments.history.canceled') : t('payments.history.underfunded')) : getTransactionDetails(item)?.subtext }}
            </p>
          </template>
        </template>
        <template #sum="{item}">
          <template v-if="!item">
            <Skeleton class="!w-32 !h-5 rounded-md" />
          </template>
          <template v-else>
            <p class="sum" :class="{ income: item.type === 'in', error: !item.success, 'line-through': !item.success }">{{ toLocaleBalance(toMajorUnits(item.amount), undefined, 2) }}</p>
          </template>
        </template>
      </TableBase>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.payments-tab-history {
  @apply flex flex-col;
  overflow-y: auto;
  height: 100%;

  .payments-table {
    @apply overflow-auto;
    @apply rounded-md;
    @apply bg-grey-950;
    height: auto;
  }

  .sum {
    @apply text-right text-16-400;
    white-space: nowrap;
    &::before {
      content: "- ";
    }

    &.income {
      @apply text-ra-online;

      &::before {
        content: "+ ";
      }
    }

    &.error {
      @apply text-ra-queued;
    }
  }
}
</style>