import PanelLayoutVue from '@panel/PanelLayout.vue';
import { RouteRecordRaw } from 'vue-router';
import { CourtRouter, CourtRoutes } from '@court/court.routes';
import CourtContent from '@court/CourtContent.vue';
import CourtNavigation from '@court/components/CourtNavigation.vue';
import { SettingsRouter } from '@settings/settings.routes';

export enum PanelModules {
  Court = 'Court'
}

export const PanelRouter: RouteRecordRaw = {
  path     : '/:project',
  name     : 'Panel',
  component: PanelLayoutVue,
  redirect : (_) => {
    return { name: CourtRoutes.Welcome };
  },
  children: [
    {
      path      : '',
      name      : PanelModules.Court,
      components: {
        content   : CourtContent,
        navigation: CourtNavigation,
      },
      redirect: (_) => {
        return { name: CourtRoutes.Welcome };
      },
      children: [
        ...CourtRouter,
        ...SettingsRouter
      ],
    },
    {
      path    : ':pathMatch(.*)*',
      redirect: () => ({ name: CourtRoutes.Welcome })
    }
  ],
};