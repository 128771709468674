<script setup lang="ts"> 
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue'; 
  import IntersectionObserver from '@/components/IntersectionObserver.vue'; 
  import Loading from '@/components/Loading.vue'; 
  import { BanDto } from '@/api/backend/court/ban/ban.dto'; 
  import { PaginationService } from '@/api/backend/pagination.service'; 
  import { useLocaleStore } from '@/stores/locale.store'; 
  import { useCourtStore } from '@court/stores/court.store'; 
  import { useTimeStore } from '@/stores/time.store'; 
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue'; 
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue'; 
 
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto'; 
  import { usePanelStore } from '@panel/stores/panel.store'; 
  import { useCourtApi } from '@/api/backend/court/court.api'; 
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api'; 
  import PlayerDetailsCard, { PlayerDetailsCardRow } from '../../card/PlayerDetailsCard.vue';
  import Button from '@/components/Button.vue'; 
  import { Svg } from '@src/assets/auto_gen_types.dto'; 
  import ClientInline from '@court/components/client/ClientInline.vue'; 
  import PlayerDetailsCardBadge from '../../card/PlayerDetailsCardBadge.vue'; 
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto'; 
  import ModalMenuExtendedPageLayout from '@/components/modal-menu-extended/ModalMenuExtendedPageLayout.vue'; 
  import { useBanStore } from '@court/stores/ban.store'; 
 
  const emits = defineEmits<ModalMenuExtendedEvents>(); 
  const props = defineProps<{ 
    steamId: string;
    variant: 'all' | 'own' | 'other'
  }>(); 
 
  const { t } = useLocaleStore(); 
  const bans = ref<BanDto[]>(); 
  const _time = useTimeStore(); 
  const _panel = usePanelStore(); 
  const _ban = useBanStore(); 
  const _court = useCourtStore(); 

  
  const otherProjectIds = computed(() => (_ban.subscription ?? []).filter(v => v.is_public && v.you_are_public).map(v => v.project_id_original));
 
  const loader = computed(() => {
    const projectIds: number[] = [];


    if (!!_panel.project?.id) {
      switch (props.variant) {
        case 'all': {
          projectIds.push(_panel.project.id, ...otherProjectIds.value);
          break;
        }
        case 'own': {
          projectIds.push(_panel.project.id);
          break;
        }
        case 'other': {
          projectIds.push(...otherProjectIds.value);
          break;
        }
      }
    }

    if (!projectIds.length || !_ban.subscription) {
      return null;
    }
    
    return new PaginationService((page, limit) => useCourtApi().ban.browse({ 
      steam_id: props.steamId, 
      sort_by : 'created', 
 
      project_ids: projectIds, 
 
      page, 
      limit, 
 
      include_total: true 
    }), 25);
  }); 
 
  const lastCount = ref<number>(); 
  const badgeUpdate = (count: number) => { 
    if (props.variant !== 'all') {
      return;
    }

    emits('badgeUpdate', count); 
     
    lastCount.value = count; 
  }; 
 
  const centrifugoBanUpdate = ref<CentrifugoSubscribe>(); 
 
  onMounted(() => { 
    if (!_panel.project) { 
      return; 
    } 
     
    emits('badgeUpdate', -1); 
    centrifugoBanUpdate.value = useCentrifugoApi().subscribeBanUpdate(_panel.project.id, (ban) => appendBan(ban)); 
  }); 
 
  const appendBan = (ban: BanDto) => { 
    if (!bans.value) { 
      return; 
    } 
 
    if (ban.steam_id !== props.steamId) { 
      return; 
    } 
 
    const origin = bans.value.find(v => v.id === ban.id); 
    if (origin) { 
      Object.assign(origin, ban); 
      return; 
    } 
 
    bans.value.unshift(ban); 
 
    badgeUpdate((lastCount.value ?? 0) + 1); 
  }; 
 
  onUnmounted(() => { 
    badgeUpdate(-1); 
 
    centrifugoBanUpdate.value?.unsubscribe(); 
  }); 
 
  onMounted(() => next()); 

  watch(() => loader.value, () => {
    bans.value = undefined;

    next();
  }, { deep: true });
   
  const next = async () => { 
    if (!loader.value) {
      return;
    }

    const { results, total } = await loader.value.next(); 
 
    if (!bans.value) { 
      bans.value = []; 
    } 
 
    bans.value?.push(...results); 
 
    badgeUpdate(total ?? 0); 
  }; 
   
  const openBanModal = (ban: BanDto) => { 
    ActivateBanViewModal({ ban, banId: ban.id }); 
  }; 
 
  const activeBan = computed(() => bans.value?.find(v => v.computed_is_active && v.project_id === _panel.project?.id)); 
 
  defineExpose({ activeBan }); 

  const rows_our = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title : t('modal.player.bans.ban.staff'),
        inline: false
      },
      {
        title     : t('modal.player.bans.ban.reason'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('modal.player.bans.ban.term'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('modal.player.bans.ban.comment'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });

  const rows_other = computed((): PlayerDetailsCardRow[] => {
    return [
      {
        title     : t('modal.player.bans-from-other-project'),
        inline    : true,
        noTruncate: true,
      },
      {
        title : t('modal.player.bans.ban.staff'),
        inline: false,
      },
      {
        title     : t('modal.player.bans.ban.reason'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('modal.player.bans.ban.term'),
        inline    : true,
        noTruncate: true,
      },
      {
        title     : t('modal.player.bans.ban.comment'),
        inline    : true,
        noTruncate: true,
      }
    ];
  });
</script> 
 
<template> 
  <template v-if="variant === 'other' && otherProjectIds.length == 0"> 
    <div class="flex flex-col justify-center items-center h-full"> 
      <EmptyImage 
        type="empty" 
        :title="t('modal.player.bans.empty-other.title')" 
        :subtitle="t('modal.player.bans.empty-other.subtitle')" 
      /> 
    </div> 
  </template> 
  <template v-else-if="bans && bans.length == 0"> 
    <div class="flex flex-col justify-center items-center h-full">
      <EmptyImage 
        type="empty" 
        :title="t('modal.player.bans.empty.title')" 
        :subtitle="t('modal.player.bans.empty.subtitle')" 
      /> 
    </div> 
  </template> 
  <template v-else> 
    <div class="h-max min-h-full w-full relative"> 
      <Loading :can-view="bans != null" class="!bg-transparent" /> 
      <template v-if="bans != null"> 
        <div class="flex flex-col gap-2.5"> 
          <template v-for="(ban, index) in bans" :key="ban.id"> 
            <PlayerDetailsCard 
              v-if="ban.project_id === _panel.project?.id"
              :rows="rows_our"  
              :date-from="_time.format(ban.created_at, 'DD.MM.YY # HH:mm', true, true)"  
            > 
              <template v-if="!ban.computed_is_active" #badges> 
                <PlayerDetailsCardBadge preset="green" :tooltip-text="t('general.unbanned')"> 
                  <template #default> 
                    <Svg.unlock /> 
                  </template> 
                </PlayerDetailsCardBadge> 
              </template> 
              <template v-if="ban.client_id" #row0> 
                <ClientInline class="staff" :client-id="ban.client_id" /> 
              </template> 
              <template #row1> 
                {{ ban.reason }} 
              </template> 
              <template #row2> 
                {{ ban.expired_at ? t('modal.player.bans.ban.unban-date', {date: _time.format(ban.expired_at, 'DD.MM.YY HH:mm', true, true)}) : t('modal.player.bans.ban.forever') }} 
              </template> 
              <template v-if="ban.comment" #row4> 
                {{ ban.comment }} 
              </template>  
              <template #actions> 
                <Button 
                  preset="default-light" 
                  class="!p-1 !rounded" 
                  :action="() => openBanModal(ban)" 
                > 
                  <Svg.open class="fill-frey-50 !w-[20px]" /> 
                </Button> 
              </template> 
            </PlayerDetailsCard> 

              
            <PlayerDetailsCard 
              v-else
              :rows="rows_other"  
              :date-from="_time.format(ban.created_at, 'DD.MM.YY # HH:mm', true, true)"  
            > 
              <template #badges> 
                <PlayerDetailsCardBadge
                  v-if="!ban.computed_is_active"
                  preset="green"
                  :tooltip-text="t('general.unbanned')"
                > 
                  <template #default> 
                    <Svg.unlock /> 
                  </template> 
                </PlayerDetailsCardBadge> 
                <PlayerDetailsCardBadge preset="blue" :tooltip-text="t('general.data-from-another-project')"> 
                  <template #default> 
                    <Svg.share /> 
                  </template> 
                </PlayerDetailsCardBadge> 
              </template> 
              <template #row0> 
                {{ _ban.subscription?.find(v => v.project_id_original === ban.project_id)?.project_original.name }}
              </template> 
              <template v-if="(ban.client_id ?? 0) > 10" #row1> 
                <ClientInline class="staff" :client-id="ban.client_id" /> 
              </template> 
              <template #row2> 
                {{ ban.reason }} 
              </template> 
              <template #row3> 
                {{ ban.expired_at ? t('modal.player.bans.ban.unban-date', {date: _time.format(ban.expired_at, 'DD.MM.YY HH:mm', true, true)}) : t('modal.player.bans.ban.forever') }}
              </template>
              <template #actions> 
                <Button 
                  preset="default-light" 
                  class="!p-1 !rounded" 
                  :action="() => openBanModal(ban)" 
                > 
                  <Svg.open class="fill-frey-50 !w-[20px]" /> 
                </Button> 
              </template> 
            </PlayerDetailsCard> 
          </template> 
        </div> 
 
        <IntersectionObserver :next="next" class="z-10 pointer-events-none" /> 
      </template> 
    </div> 
  </template> 
</template> 
 
<style lang='scss' scoped> 
.staff { 
  @apply cursor-pointer; 
   
  :deep(.nickname) { 
    @apply leading-tight; 
    @apply text-primary-700 font-semibold; 
 
    &:hover { 
      @apply text-primary-500; 
    } 
  } 
} 
</style> 
