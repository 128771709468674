import { useCourtApi } from "@/api/backend/court/court.api";
import { CourtSubscriptionStateDto, CourtSubscriptionTypes } from "@/api/backend/court/court/court.dto";
import { useLocaleStore } from "@/stores/locale.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export type CourtFeatureName = 
  | 'Base'

  //Starter
  | 'PlayerState'
  | 'PlayerInfo'
  | 'PlayerSteamInfo'
  | 'PlayerNicknames'
  | 'PlayerSession'
  | 'ChatSystem'
  | 'ReportSystem'
  | 'InspectionSystem'
  | 'BanSystem'
  | 'AttachingProofs'
  | 'RestrictionSystem'
  | 'AlertSystem'
  | 'StaffSystem'
  | 'CustomActions'
  | 'AuditLog'
  | 'DiscordIntegrations'

  //Basic
  | 'Starter'
  | 'PlayerStatistics'
  | 'Combatlog'
  | 'BanShare'
  | 'StaffStats'
  | 'TeamHistory'
  | 'DrawingsSystem'
  | 'CustomAlerts'
  | 'RCC'

  //Pro
  | 'Basic'
  | 'Verif'
  | 'API'

export type CourtFeatureDescription = {
  text: string;
  from_plan?: CourtSubscriptionTypes
  hidden?: boolean;
  tooltip?: {
    text?: string;
    image?: string;
  };
};

export const useCourtSubscriptionStore = defineStore('court-subscription', () => {
  const state = ref<CourtSubscriptionStateDto>();

  const { t } = useLocaleStore();

  const warmup = async (landing: boolean, clear = true) => {
    if (clear) {
      state.value = undefined;
    }

    if (landing) {
      state.value = await useCourtApi().utils.getSubscription();
    } else {
      state.value = await useCourtApi().court.getSubscription();
    }
  };

  const features = ref<Partial<Record<CourtFeatureName, CourtFeatureDescription>>>({
    /*Base: {
      text     : t('plan-base-text'),
      from_plan: '1free',
      tooltip  : {
        text: t('plan-base-tooltip'),
      }
    },*/

    //Starter
    PlayerState: {
      text     : t('landing.pricing.features.player-state'),
      from_plan: '1starter'
    },
    PlayerInfo: {
      text     : t('landing.pricing.features.player-base-info'),
      from_plan: '1starter'
    },
    PlayerSteamInfo: {
      text     : t('landing.pricing.features.player-steam-info'),
      from_plan: '1starter'
    },
    PlayerNicknames: {
      text     : t('landing.pricing.features.player-nick-history'),
      from_plan: '1starter'
    },
    PlayerSession: {
      text     : t('landing.pricing.features.player-activity'),
      from_plan: '1starter'
    },
    ChatSystem: {
      text     : t('landing.pricing.features.chat-system'),
      from_plan: '1starter'
    },
    ReportSystem: {
      text     : t('landing.pricing.features.report-system'),
      from_plan: '1starter'
    },
    InspectionSystem: {
      text     : t('landing.pricing.features.inspection-system'),
      from_plan: '1starter'
    },
    BanSystem: {
      text     : t('landing.pricing.features.ban-system'),
      from_plan: '1starter'
    },
    AttachingProofs: {
      text     : t('landing.pricing.features.proofs'),
      from_plan: '1starter'
    },
    RestrictionSystem: {
      text     : t('landing.pricing.features.restriction-system'),
      from_plan: '1starter'
    },
    AlertSystem: {
      text     : t('landing.pricing.features.alert-system'),
      from_plan: '1starter'
    },
    StaffSystem: {
      text     : t('landing.pricing.features.staff-system'),
      from_plan: '1starter'
    },
    CustomActions: {
      text     : t('landing.pricing.features.custom-actions'),
      from_plan: '1starter'
    },
    AuditLog: {
      text     : t('landing.pricing.features.audit'),
      from_plan: '1starter'
    },
    DiscordIntegrations: {
      text     : t('landing.pricing.features.discord-int'),
      from_plan: '1starter'
    },

    //Basic
    Basic: {
      text     : t('change-plan.features.from.basic'),
      from_plan: '2basic'
    },
    PlayerStatistics: {
      text     : t('landing.pricing.features.player-stats'),
      from_plan: '2basic'
    },
    Combatlog: {
      text     : t('landing.pricing.features.combatlog'),
      from_plan: '2basic'
    },
    BanShare: {
      text     : t('landing.pricing.features.ban-share'),
      from_plan: '2basic'
    },
    StaffStats: {
      text     : t('landing.pricing.features.staff-stats'),
      from_plan: '2basic'
    },
    TeamHistory: {
      text     : t('landing.pricing.features.team-history'),
      from_plan: '2basic'
    },
    DrawingsSystem: {
      text     : t('landing.pricing.features.drawings'),
      from_plan: '2basic'
    },
    CustomAlerts: {
      text     : t('landing.pricing.features.custom-alerts'),
      from_plan: '2basic'
    },
    RCC: {
      text     : t('landing.pricing.features.rcc-int'),
      from_plan: '2basic'
    },

    //Pro
    Starter: {
      text     : t('change-plan.features.from.starter'),
      from_plan: '3pro'
    },
    Verif: {
      text     : t('landing.pricing.features.verif'),
      from_plan: '3pro',
      tooltip  : {
        text: t('plan-plugin-verification-tooltip'),
      }
    },
    API: {
      text     : t('landing.pricing.features.api-access'),
      from_plan: '3pro'
    },
  });

  const needSubscription = (feature: CourtFeatureName) => {
    if (!state.value) {
      return undefined;
    }
    
    const description = features.value[feature];
    if (!description) {
      return undefined;
    }

    if (!description.from_plan) {
      return undefined;
    }

    if (description.from_plan > state.value.current.type) {
      return description.from_plan.replace(/\d/, '').replace(/\d/, '').replace(/\d/, '').replace(/\d/, '');
    }

    return undefined;
  };

  const planTypeBeautifier = (type: CourtSubscriptionTypes) => {
    const realType = type.replace(/\d/, '');
    return realType.charAt(0).toUpperCase() + realType.slice(1);
  };

  const getPlanList = () => {
    if (!state.value?.available) {
      return [];
    }
    const [ free ] = state.value.available;

    /**
     * const enterprise = {
      id    : 4,
      price : -1,
      limits: {
        players: 1000000,
        servers: 100,
      },
      type: '4enterprise' as CourtSubscriptionTypes,
    };
     */

    const result = state.value.available.slice(1);

    if (state.value.current.type === '1free') {
      result.unshift(free);
    } else {
      //result.push(enterprise);
    }

    return result;
  };
  

  return {
    warmup,

    state,
    features,

    needSubscription,
    planTypeBeautifier,
    getPlanList
  };
});