<script setup lang="ts">
  import { ref } from 'vue';
  import { SignUpData } from '../../types/auth.dto';
  import { useAppStore } from '@/stores/app.store';
  import { useRoute, useRouter } from 'vue-router';
  import { ClientRoutes } from '../../client.routes';
  import Button from '@/components/Button.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import SignInForm from './forms/SignInForm.vue';
  import SignUpForm from './forms/SignUpForm.vue';
  import RestoreForm from './forms/RestoreForm.vue';
  import { onMounted } from 'vue';
  import OpenAuth from './components/OpenAuth.vue';
  import CompleteForm from './forms/CompleteForm.vue';
  
  const _locale = useLocaleStore();
  const _app = useAppStore();
  const _route = useRoute();
  const _router = useRouter();
  
  const mode = ref<'sign-in'|'sign-up'|'restore'>('sign-in');

  onMounted(() => {
    if (_route.query.code || _route.query.email) {
      restore.value = {
        code : _route.query.code?.toString() || '',
        email: _route.query.email?.toString() || ''
      };

      _router.push({ name: ClientRoutes.Auth, force: true });
    }
  });

  const signUpCredentials = ref<SignUpData>();
  
  const restore = ref<{ code: string; email: string; }>();

  const confirmToken = async (token: string) => {
    await _app.updateToken(token);

    _router.push({ name: ClientRoutes.Profile });
  };

  const computedLangIcon = computed(() => {
    if (_locale.isEng) {
      return 'https://hatscripts.github.io/circle-flags/flags/gb.svg';
    }

    return `https://hatscripts.github.io/circle-flags/flags/ru.svg`;
  });

  const changeMode = (newMode: 'sign-in' | 'sign-up' | 'restore') => {
    restore.value = undefined;
    signUpCredentials.value = undefined;

    mode.value = newMode;
  };

  const signUpComplete = async (data: SignUpData) => {
    mode.value = 'sign-up';
    signUpCredentials.value = data;
  };
</script>

<template>
  <div class="back">
    <Button preset="default" @click="(signUpCredentials || mode === 'restore' || restore) ? changeMode('sign-in') : _router.push({ path: '/'})">
      {{ '<- ' + ((signUpCredentials || mode === 'restore' || restore) ? _locale.t('general.back') : _locale.t('general.home')) }}
    </Button>
  </div>
  
  <div class="lang">
    <Button
      preset="default"
      class="!px-2"
      @click="() => _locale.set(_locale.isRu ? 'en' : 'ru')"
    >
      <img
        class="flag-icon object-cover"
        :style="`height: ${20}px; width: ${20}px;`"
        :src="computedLangIcon"
      >
    </Button>
  </div>
  
  <div class="auth">
    <template v-if="mode === 'restore' || !!restore">
      <RestoreForm
        :code="restore?.code"
        :email="restore?.email"
        @sign-in="changeMode('sign-in')"
      />
    </template>
    <template v-else-if="mode == 'sign-in'">
      <SignInForm
        class="sign-in"
        :confirm="confirmToken"
        @restore="changeMode('restore')"
        @sign-up="changeMode('sign-up')"
      />
    </template>
    <template v-else-if="mode == 'sign-up'">
      <div :class="{ 'absolute opacity-0 pointer-events-none': signUpCredentials }">
        <SignUpForm
          @sign-in="changeMode('sign-in')"
          @sign-up-complete="(e) => signUpComplete(e)"
        />
      </div>
      <template v-if="signUpCredentials">
        <CompleteForm
          :confirm="confirmToken" 
          :credentials="signUpCredentials"
        />
      </template>
    </template>

    <template v-if="mode !== 'restore' && !restore && !signUpCredentials">
      <OpenAuth
        :confirm="confirmToken" 
        @sign-up-complete="(e) => signUpComplete(e)"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.back {
  @apply absolute top-4 left-4;
}
.lang {
  @apply absolute top-4 right-4;
}

.auth, :deep(.auth) {
  @apply flex flex-col gap-8;
  @apply w-[300px] sm:w-[360px];
  
  .header {
    @apply flex flex-col gap-1;
    @apply text-left;
    
    .title {
      @apply text-grey-50 text-xl font-semibold;
    }
    
    .subtitle {
      @apply text-grey-500;

      .link {
        @apply cursor-pointer;
        @apply text-primary-700 hover:text-primary-600;
      }
    }
  }

  .content {
    @apply flex flex-col gap-[30px];

    .inputs {
      @apply flex flex-col gap-5;
    }
  }

  .other-methods {
    @apply flex gap-5;
  }
  
  .btn {
    @apply w-full h-[42px];
    @apply rounded-md;

    svg {
      @apply w-5.5;
    }

    &.google {
      @apply bg-grey-100;
      @apply fill-grey-50;
      @apply hover:scale-105;
    }

    &.steam {
      @apply bg-[#0B88B9];
      @apply fill-grey-50;
      @apply hover:scale-105;
    }

    &.discord {
      @apply bg-[#5865F2];
      @apply fill-grey-50;
      @apply hover:scale-105;
    }
  }
}

</style>