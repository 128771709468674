import { UploadAvailableType } from '@/api/backend/core/utils/utils.dto';
import { UseFileDialogOptions, useFileDialog } from '@vueuse/core';
import { computed, ref } from 'vue';
import { UploadedFile } from './uploader.dto';
import { v4 } from 'uuid';
import { useCoreApi } from '@/api/backend/core/core.api';

const upload = async (file: File, type: UploadAvailableType) => {
  return await useCoreApi().utils.upload(file, type);
};

export const useSingleFileDialog = (type: UploadAvailableType, opts: UseFileDialogOptions, s3_id?: string) => {
  const { open, onChange } = useFileDialog(opts);

  const uploaded = ref<UploadedFile>({ s3_id, file: undefined, temp_file_id: undefined });

  onChange(async (fileList: FileList | null) => {
    const file = fileList?.[0];
    if (!file) {
      return;
    }

    const temp_file_id = v4();

    uploaded.value = {
      temp_file_id,
      file
    };

    const id = await upload(file, type);

    if (uploaded.value.temp_file_id !== temp_file_id) {
      return;
    }

    uploaded.value.s3_id = id;
  });

  return { open, uploaded };
};

export const useSingleFileDialogV2 = (type: UploadAvailableType, opts: UseFileDialogOptions, getPreview: () => string | undefined, applyChanges: (s3_id: string) => unknown) => {
  const { open, onChange } = useFileDialog(opts);

  const uploaderMeta = ref<{
    file: File,
    temp_file_id: string
  }>();

  const preview = computed(() => {
    if (!uploaderMeta.value) {
      return getPreview();
    }

    return window.URL.createObjectURL(uploaderMeta.value.file);
  });

  const isLoading = computed(() => {
    if (!uploaderMeta.value) {
      return false;
    }

    return true;
  });

  onChange(async (fileList: FileList | null) => {
    const file = fileList?.[0];
    if (!file) {
      return;
    }

    const temp_file_id = v4();

    uploaderMeta.value = {
      temp_file_id,
      file
    };

    const id = await upload(file, type);

    if (uploaderMeta.value.temp_file_id !== temp_file_id) {
      return;
    }

    uploaderMeta.value = undefined;

    applyChanges(id);
  });

  return { open, preview, isLoading };
};