<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import { DebugService } from '@/api/backend/debug.service';
  import { CheckActivityDto, CheckActivityMetaNoticeChangedDto, CheckActivityMetaRccCall, CheckDto } from '@/api/backend/court/check/check.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed, onMounted, ref, watch } from 'vue';
  import ClientListItem from '@court/components/client/ClientListItem.vue';
  import { useIntervalFn } from '@vueuse/core';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ActivateBanCreateModal } from '@court/modals/ban-control/BanCreateModal.vue';
  import { BanFinalResult } from '@court/modals/ban-control/types/ban.dto';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useTimeStore } from '@/stores/time.store';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<{
    check: CheckDto;
    activities: CheckActivityDto<unknown>[] | null
  }>();

  const { t } = useLocaleStore();

  const _app = useAppStore();
  const _time = useTimeStore();
  const _permissions = usePermissionsStore();

  const noticeState = ref<boolean | null>(null);

  const team = ref<PlayerFullDto[]>();

  onMounted(() => {
    load();
  
    if (_permissions.can(['Court_CheckProcess'])) {
      useIntervalFn(() => checkNotice(), 5_000);
    }
  });

  watch(() => props, () => load(), { deep: true });

  const load = async () => {
    if (props.check.suspect_team.length == 0) {
      team.value = [];
      return;
    }

    const players = await useCourtApi().player.browseFull({ steam_ids: props.check.suspect_team, page: 0, limit: 15, offline: true });
    
    team.value = players.results;
  };

  const checkNotice = async () => {
    if (props.check.finished_at) {
      return;
    }

    try {
      noticeState.value = await useCourtApi().check.checkNotice(props.check.id).catch(err => { 
        if (JSON.stringify(err).includes('Player is disconnected')) {
          return false;
        }

        throw err;
      });
    } catch (err) {
      throw err;
    }
  };

  const noticeText = computed(() => {
    return noticeState.value ? t('check.actions.notice-hide') : t('check.actions.notice-show');
  });

  onMounted(() => {
    if (_permissions.can(['Court_CheckProcess'])) {
      checkNotice();
    }
  });

  const updateNotice = async () => {
    if (noticeState.value === null) {
      return;
    }

    try {
      const alreadyShowed = props.activities?.find(v => v.type === 'RccCall' && (v.meta as CheckActivityMetaRccCall).status !== 'error');

      const rcc_call = !noticeState.value && !alreadyShowed;

      await useCourtApi().check.updateNotice(props.check.id, !noticeState.value, rcc_call, _app.client?.steam?.steam_id);
    
      noticeState.value = !noticeState.value;
    }
    catch (err) {
      return;
    }
  };

  const finish = async (data: BanFinalResult) => {
    if (!data.check) {
      DebugService.error(`Что-то не так, модалка бана закончилась без данных по проверке`, { data });
      return;
    }

    try {
      await useCourtApi().check.finish(props.check.id, {
        status: data.check?.status,
      
        comment: data.check?.comment,
        verdict: data.check?.verdict,

        bans: data.bans 
      });
    }
    catch (err) {
      if ((err as any)?.message === 'Ban-service is down') {
        useRuntimeNotification('error', t('bans.finish.ban-service-down'));
        return;
      }

      throw err;
    }
  };

  const getLastNotice = computed(() => {
    if (!props.activities?.length || props.check.status !== 'Pending') {
      return null;
    }

    const activity = props.activities?.filter(v => v.type == 'NoticeChanged');
    const [last] = activity.sort((a, b) => b.created_at - a.created_at);

    return last;
  });

  const getSecondsShowed = computed(() => {
    if (!getLastNotice.value) {
      return 0;
    }

    const meta = getLastNotice.value.meta as CheckActivityMetaNoticeChangedDto;

    if (!meta.active) {
      return 0;
    }

    const seconds = +((+_time.now - getLastNotice.value.created_at) / 1000).toFixed(0);

    if (seconds < 0) {
      return 0;
    }

    return seconds;
  });

  const formatSecondsShowed = computed(() => {
    const seconds = getSecondsShowed.value;

    const minutes = Math.floor(seconds / 60);

    const leftSeconds = seconds - minutes * 60;

    return `${minutes < 10 ? '0' + minutes : minutes}:${leftSeconds < 10 ? '0' + leftSeconds : leftSeconds}`;
  });

</script>

<template>
  <div class="check-moder">
    <ClientListItem
      :client-id="check.client_id"
      class="flex-shrink-0 max-w-[220px]"
    />
    <div class="flex gap-2">
      <template v-if="getSecondsShowed && noticeState">
        <TooltipBase
          trigger="hover"
          placement="top"
          distance="10"
        >
          <p
            class="timer"
          >
            {{ formatSecondsShowed }}
          </p>
          <template #content>
            <div class="px-1.5 py-1 text-xs text-center" style="max-width: 150px;">
              <p>{{ t('check.actions.notice.timer.tooltip') }}</p>
            </div>
          </template>
        </TooltipBase>
      </template>
      <Button
        v-if="!check.finished_at && _permissions.can(['Court_CheckProcess'])" 
        :text="noticeText"
        preset="default"
        :action="updateNotice"
        :loading="noticeState === null"
        class="!px-3 !py-2"
      />
      <Button
        v-if="!check.finished_at && _permissions.can(['Court_CheckProcess'])"
        :action="() => ActivateBanCreateModal({player: check.suspect, callback: (data) => finish(data), team: team ?? [], enableCheckStep: true })"
        :loading="team == null"
        :text="t('check.actions.finish')"
        class="!px-3 !py-2"
        preset="primary"
      />
      <Button
        v-if="check.finished_at"
        :text="t('check.actions.finished')"
        class="pointer-events-none !px-3 !py-2"
        preset="default"
        disabled
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.check-moder {
  @apply flex justify-between gap-5 w-full;
  @apply p-4;
  @apply bg-grey-900/50;
  @apply overflow-x-auto;
  @apply whitespace-nowrap;
}

.timer {
  @apply flex items-center px-3 h-full;
  @apply rounded-md;
  @apply bg-rose-400/25;
  @apply text-rose-400 font-medium tabular-nums;
}

.button-truncated {
  &:deep(.wrapper) {
    @apply truncate;
    p {
      @apply truncate;
      @apply w-full;
    }
  }  
}
</style>