<script setup lang='ts'>
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useTimeStore } from '@/stores/time.store';
  import { useCheckUtils } from '@court/utils/check.utils';
  import { useCopyClipboard } from '@src/composable/copy.composable';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  defineProps<{
    check: CheckDto
  }>();

  const { t } = useLocaleStore();
  const _time = useTimeStore();

  const { verdict } = useCheckUtils();
</script>

<template>
  <div class="container">
    <div class="rows">
      <div class="row">
        <p class="name"> {{ t('check.verdict.details.start') }} </p>
        <p class="value"> {{ _time.format(check.created_at, 'DD.MM.YY # HH:mm', true) }} </p>
      </div>

      <div v-if="check.finished_at" class="row">
        <p class="name"> {{ t('check.verdict.details.end') }} </p>
        <p class="value"> {{ _time.format(check.finished_at, 'DD.MM.YY # HH:mm', true) }} </p>
      </div>

      <div v-if="check.finished_at" class="row">
        <p class="name"> {{ t('check.verdict.details.time') }} </p>
        <p class="value"> {{ _time.difference(check.finished_at, check.created_at) }} </p>
      </div>

      <div class="row">
        <p class="name"> {{ t('check.verdict.details.contact') }} </p>
        <div
          v-if="check.contact"
          class="value copy"
          @click="useCopyClipboard(check.contact, true)"
        >
          {{ check.contact }}
          <Svg.copy />
        </div>
        <p v-else class="value warning">
          {{ t('check.verdict.details.no-contact') }}
        </p>
      </div>

      <div v-if="check" class="row">
        <p class="name"> {{ t('check.verdict.details.verdict') }} </p>
        <p class="value" :title="verdict(check)"> {{ verdict(check) }} </p>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply flex flex-col gap-2.5;
  
  .rows {
    @apply flex flex-col gap-3;

    .row {
      @apply flex justify-between gap-20;
    
      .name {
        @apply text-grey-400 shrink-0;
      }

      .value {
        @apply text-end text-grey-50;
        @apply truncate;
        
        &.copy {
          @apply flex gap-1;
          @apply cursor-pointer;
          @apply select-none;

          svg {
            @apply w-4 shrink-0;
            @apply fill-grey-50;
          }

          &:hover {
            @apply text-grey-200;
            
            svg {
              @apply fill-grey-200;
            }
          }
        }

        &.warning {
          @apply text-amber-400;
        }
      }
    }
  }
}
</style>