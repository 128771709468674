<script setup lang="ts">
  import { computed, ref, Ref, watch } from 'vue';

  import { useLocaleStore } from '@/stores/locale.store';
  import TableBase from '@/components/table/TableBase.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { TableHeaders } from "@/components/table/table.dto";
  import Skeleton from '@/components/Skeleton.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateIpDetailsModal } from '@court/modals/ip-details/IpDetailsModal.vue';
  import { usePlayerSidebarStore } from '../stores/player.store';
  import Highlight from '@/components/Highlight.vue';
  import TextExtended from '@/components/TextExtended.vue';
  import { ampli } from '@src/ampli';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { isIP } from 'class-validator';

  export type PlayersHeaders = TableHeaders<('player' | 'license' | 'ip' | 'country' | 'city' | 'provider' | 'server')>;
  const { t } = useLocaleStore();

  const _court = useCourtStore();
  const _playerSidebar = usePlayerSidebarStore();

  const props = defineProps<{
    search: string
  }>();

  let lastUsedSearch: string | undefined = undefined;

  const loader = computed(() => {
    _playerSidebar.filters.hide_offline;
    _playerSidebar.filters.server_id;
    _playerSidebar.filters.only_vpn;
    _playerSidebar.filters.only_report_ignore;

    const search = props.search;

    if (props.search?.length && lastUsedSearch != props.search) {
      ampli.useSearch({ variant: 'section' });

      lastUsedSearch = props.search;
    }
    
    return new PaginationService<PlayerFullDto>((page, limit) => useCourtApi().player.browseFull({
      limit             : 50,
      page,
      search            : search.length ? search : undefined,   
      geo_search        : search.length > 2 && !isIP(search) ? search : undefined,   
      offline           : !_playerSidebar.filters.hide_offline,
      only_vpn          : !!_playerSidebar.filters.only_vpn,
      only_report_ignore: !!_playerSidebar.filters.only_report_ignore,
      server_id         : _playerSidebar.filters.server_id != 0 ? _playerSidebar.filters.server_id : undefined,
      sort              : search.length ? 'last_online' :'created_at'
    }));
  });

  const warningText = computed(() => {
    if (!_playerSidebar.filters.hide_offline && !_playerSidebar.filters.server_id) {
      return undefined;
    }

    return t('general.no-data-filters');
  });

  const headers = computed<PlayersHeaders>(() => {
    props.search;

    return { 
      player: {
        text: t('players.headers.player'),
        min : '180px',
        max : '260px',
      },
      license: {
        text: t('players.headers.license'),
        min : '120px',
        max : '180px'
      },
      ip: {
        text: t('players.headers.ip'),
        min : '120px',
        max : '200px',
      },
      country: {
        text: t('players.headers.country'),
        min : '120px',
        max : '200px',
      },
      city: {
        text: t('players.headers.city'),
        min : '120px',
        max : '200px',
      },
      provider: {
        text: t('players.headers.provider'),
        min : '120px',
        max : '200px',
      },
      server: {
        text : t('players.headers.server'),
        min  : '120px',
        right: true,
      },
    };
  });

  const items = ref<PlayerFullDto[] | null>(null) as Ref<PlayerFullDto[] | null>;

  const next = async () => {
    const loaderId = loader.value.id;

    const result = await loader.value.next();

    if (loaderId !== loader.value.id) {
      return;
    }
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  watch(
    () => loader.value, 
    () => {
      items.value = null;
      next();
    }, 
    { deep: true }
  );

  const getLicense = (player: PlayerFullDto) => {
    if (player.last_no_license === undefined) {
      return t('player.license-unknown');
    }

    if (player.last_no_license) {
      return t('player.license-pirate');
    }

    return t('player.license-official');
  };
</script>

<template>
  <TableBase
    :headers="headers"
    
    :items="items"
    :item-key="(i) => i.steam_id"

    :next="next"

    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"
    :missing-data-warning="warningText"
  >
    <template #player="{ item }">
      <PlayerBox
        :player="item ?? null"
        class="w-full"
        :search="[search]"
        style="max-width: 200px;"
      />
    </template>
    <template v-if="_playerSidebar.design.show_license" #license="{ item }">
      <template v-if="item">
        <TextExtended :data="(item ? { reason: getLicense(item) } : null)" />
      </template>
      <template v-else>
        <Skeleton
          class="rounded-md"
          :style="`height: 18px; width: 90px`"
        />
      </template>
    </template>
    <template #ip="{ item }">
      <template v-if="item">
        <div
          class="value action cursor-pointer"
          :class="{ 'warning': item?.ip_details?.proxy && _playerSidebar.design.highlight_vpn }"
          @click="ActivateIpDetailsModal(item?.ip, item?.ip_details)"
        >
          <p class="truncate">
            <Highlight
              :visible-text="item?.ip"
              :search="[search]"
            />
          </p>
          <Svg.open />
        </div>
      </template>
      <template v-else>
        <Skeleton
          class="rounded-md"
          :style="`height: 18px; width: ${Math.min(80, Math.random() * 10 + 60)}%`"
        />
      </template>
    </template>
    <template #country="{ item }">
      <template v-if="item">
        <div class="value">
          <img
            v-if="item?.ip_details?.country_code"
            class="object-cover mr-0.5 bg-grey-900 rounded-half"
            style="height: 16px; width: 16px;"
            :src="`https://hatscripts.github.io/circle-flags/flags/${item?.ip_details?.country_code.toLowerCase()}.svg`"
          >

          
          <p :class="item?.ip_details?.country_name ? 'opacity-100' : 'opacity-50'">
            <Highlight
              :visible-text="item?.ip_details?.country_name ? item?.ip_details?.country_name : t('general.unknown')"
              :search="[search]"
            />
          </p>
        </div>
      </template>
      <template v-else>
        <div class="flex">
          <Skeleton style="width: 18px; height: 18px;" class="!rounded-half mr-1" />
          <Skeleton
            class="rounded-md"
            :style="`height: 18px; width: ${Math.min(80, Math.random() * 10 + 30)}%`"
          />
        </div>
      </template>
    </template>
    <template #city="{ item }">
      <template v-if="item">
        <div class="value">
          <p class="line-clamp" :class="item?.ip_details?.city ? 'opacity-100' : 'opacity-50'">
            <Highlight
              :visible-text="item?.ip_details?.city ? item?.ip_details?.city : t('general.unknown')"
              :search="[search]"
            />
          </p>
        </div>
      </template>
      <template v-else>
        <Skeleton
          class="rounded-md"
          :style="`height: 18px; width: ${Math.min(80, Math.random() * 30 + 40)}%`"
        />
      </template>
    </template>
    <template #provider="{ item }">
      <template v-if="item">
        <div class="value">
          <template v-if="item.ip_details?.provider">
            <p class="line-clamp" :class="item.ip_details.provider ? 'opacity-100' : 'opacity-50'">
              <Highlight
                :visible-text="item.ip_details.provider ? item.ip_details.provider : t('general.unknown')"
                :search="[search]"
              />
            </p>
          </template>
          <template v-else>
            <p class="line-clamp" :class="item?.ip_details?.city ? 'opacity-100' : 'opacity-50'">
              <Highlight
                :visible-text="item?.ip_details?.city ? item?.ip_details?.city : t('general.unknown')"
                :search="[search]"
              />
            </p>
          </template>
        </div>
      </template>
      <template v-else>
        <Skeleton
          class="rounded-md"
          :style="`height: 18px; width: ${Math.min(80, Math.random() * 30 + 40)}%`"
        />
      </template>
    </template>
    <template #server="{ item }">
      <template v-if="item">
        <p class="value flex justify-end">{{ _court.serverNameById(item?.server_id) }}</p>
      </template>
      <template v-else>
        <div class="flex justify-end">
          <Skeleton
            class="rounded-md"
            :style="`height: 18px; width: ${Math.min(60, Math.random() * 10 + 30)}%`"
          />
        </div>
      </template>
    </template>
  </TableBase>
</template>

<style lang="scss" scoped>
.value {
  @apply flex gap-1 items-center;
  @apply text-grey-400 font-medium;

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  svg {
    @apply fill-grey-600 shrink-0;
    height: 18px;
    width: 18px;
  }
  
  &.action:hover {
    @apply text-grey-50;
    @apply select-none;

    svg {
      @apply fill-grey-50;
    }
  }

  &.warning {
    @apply text-amber-400;

    svg {
      @apply fill-amber-400/50;
    }
  }
}
</style>