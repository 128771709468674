import { usePermissionsStore } from '@panel/stores/permissions.store';
import { useCopyClipboard } from '@src/composable/copy.composable';
import { useChatStore } from '@court/pages/chat/stores/chat.store';
import { useLocaleStore } from '@/stores/locale.store';
import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
import { useContextMenu } from '@/stores/contextmenu.store';
import { CentrifugoChatMessage } from '@/api/centrifugo/centrifugo.dto';
import { ampli } from '@src/ampli';
import { useCourtStore } from '@court/stores/court.store';
import { CourtCommandDto } from '@/api/backend/court/commands/commands.dto';
import { ActivateCommandExecuteModal } from '@court/modals/commands/execute/CommandExecuteModal.vue';
import { useAppStore } from '@/stores/app.store';
import { useAdaptiveStore } from '@/stores/adaptive.store';
import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';

export const useChatMessageMenu = () => {
  const _app = useAppStore();
  const _chat = useChatStore();
  const _court = useCourtStore();
  const _adaptive = useAdaptiveStore();
  const _permissions = usePermissionsStore();
  const { t } = useLocaleStore();

  const getFields = (msg: CentrifugoChatMessage, target: boolean) => {
    const prefix = target ? 'target_' : '';

    return {
      steam_id    : msg[`${prefix}steam_id`] ?? '',
      steam_name  : msg[`${prefix}steam_name`] ?? '',
      steam_avatar: msg[`${prefix}steam_avatar`] ?? '',
    }; 
  };
  
  const _contextMenu = useContextMenu();

  const open = (e: MouseEvent, msg: CentrifugoChatMessage, target: boolean) => {
    ampli.clientUseContextMenu({ target: 'chat-message' });

    const items: ExtendedMenuItem[] = [];
    const fields = getFields(msg, target);

    if (fields.steam_id.length == 17) {
      if (_adaptive.isMobile) {
        items.push({ 
          label : t('open-profile-chat-context'), 
          action: () => {
            ActivatePlayerDetailsModal(fields.steam_id);
          },
          visible: _permissions.can(['Court_PlayerReadDetails'])
        });
      }

      items.push({ 
        label : t('chat.action.reply'), 
        action: () => {
          _chat.reply = { ...fields, server_id: msg.server_id };
        },
        visible: _permissions.can(['Court_ChatWriteReply'])
      });

      items.push({
        label : t('chat.action.copy-steam-id'),
        action: () => useCopyClipboard(fields.steam_id, true)
      });
    }
    
    if (_court.commands && fields.steam_id.length == 17) {
      const refactor: Record<string, CourtCommandDto[]> = {};

      _court.commands.forEach(v => {
        if (!v.active) {
          return;
        }

        if (!refactor[v.group]) {
          refactor[v.group] = [];
        }

        refactor[v.group]?.push(v);
      });
      
      if (Object.keys(refactor).length > 0) {
        items.push({
          label: t('custom-actions-context'), 
          items: [
            { label: '', action: () => {} },
            ...Object.keys(refactor).map(v => ({
              label: v,
              items: [
                { label: '', action: () => {} },
                ...refactor[v].filter(v => v.active).map<ExtendedMenuItem>(c => ({
                  label : c.name,
                  action: async () => {
                    await ActivateCommandExecuteModal(fields.steam_id, c);
                  },
                  disabled: () => {
                    if (c.commands.some(cc => cc.includes('{staff_steam_id}')) && !_app.client?.steam?.steam_id) {
                      return true;
                    }
      
                    return false;
                  }
                }))
              ]
            }))
          ]
        });
      }
    }
    
    if (msg.target_steam_id) {
      items.push({
        label: t('chat.action.follow-messages'),
        items: [
          {
            label : msg.steam_name,    
            action: () => {
              _chat.searchStart([
                { steam_id: msg.steam_id, steam_name: msg.steam_name}
              ]);
            }
          }, {
            label : msg.target_steam_name ?? '',
            action: () => {
              _chat.searchStart([
                { steam_id: msg.target_steam_id ?? '', steam_name: msg.target_steam_name ?? ''}
              ]);
            }
          }, {
            label : t('chat.action.follow-messages-between'),
            action: () => {
              _chat.searchStart([
                { steam_id: msg.steam_id, steam_name: msg.steam_name},
                { steam_id: msg.target_steam_id ?? '', steam_name: msg.target_steam_name ?? ''}
              ]);
            }
          }
        ],
      });
    } else {
      items.push({
        label : t('chat.action.follow-messages-solo'),
        action: () => { 
          _chat.searchStart([{ steam_id: msg.steam_id, steam_name: msg.steam_name}]);
        }
      });
    }

    _contextMenu.open(e, items);
  };

  return { open };
};
  