<script setup lang="ts">
  import Loading from '@/components/Loading.vue';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import type { ReportBrowseDto, ReportPlayerDto } from '@/api/backend/court/report/report.dto';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { PaginationService } from '@/api/backend/pagination.service';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { CentrifugoReportDeletedPayload, CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import PlayerDetailsCard, { PlayerDetailsCardRow, PlayerDetailsCardRowInfo } from '../../card/PlayerDetailsCard.vue';
  import { usePlayerStatusStore } from '@court/stores/player-status.store';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useConfigStore } from '@/stores/config/config.store';
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import { ModalMenuExtendedEvents } from '@/components/modal-menu-extended/modal-menu-extended.dto';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';

  const props = defineProps<{ 
    steamId: string;
    mode: 'sent' | 'received'
  }>();

  const { t } = useLocaleStore();
  const _time = useTimeStore();
  const _panel = usePanelStore();
  const _playerStatus = usePlayerStatusStore();
  const _permissions = usePermissionsStore();

  const emits = defineEmits<ModalMenuExtendedEvents>();

  const reports = ref<ReportBrowseDto[] | null>(null);

  const loader = computed(() => {
    props.mode;

    return new PaginationService((page, limit) => {
      return useCourtApi().player.getReports(props.steamId, {
        page,
        limit,
        variant: props.mode === 'sent' ? 'sent' : 'received'
      });
    }, 10);
  });


  const centrifugoCreateSubscription = ref<CentrifugoSubscribe>();
  const centrifugoDeleteSubscription = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }
    
    centrifugoCreateSubscription.value = useCentrifugoApi().subscribeReports(_panel.project.id, (report) => appendReport(report));
    centrifugoDeleteSubscription.value = useCentrifugoApi().subscribeReportsDelete(_panel.project.id, (data) => onCentrifugoDelete(data));
  });

  const badgeUpdate = (val: number) => {
    if (props.mode !== 'received') {
      return;
    }

    emits('badgeUpdate', val);
  };

  const appendReport = (report: ReportBrowseDto) => {
    if (!reports.value) {
      return;
    }

    if (report.target?.steam_id === props.steamId) {
      if (props.mode == 'received') {
        reports.value.unshift(report);
      }

      lastTotal.value = (lastTotal.value ?? 0) + 1;
      badgeUpdate(lastTotal.value);
    }

    if (report.initiator?.steam_id === props.steamId && props.mode == 'sent') {
      reports.value.unshift(report);

      lastTotal.value = (lastTotal.value ?? 0) + 1;
      badgeUpdate(lastTotal.value);
    }
    
  };
  
  const onCentrifugoDelete = (report: CentrifugoReportDeletedPayload) => {
    if (!reports.value) {
      return;
    }

    if (report.report_id) {
      const indexOf = reports.value.findIndex(v => v.id === report.report_id);
      if (indexOf >= 0) {
        reports.value?.splice(indexOf, 1);

        const val = (lastTotal.value ?? 1) - 1;

        lastTotal.value = val;

        badgeUpdate(val);
        return;
      }
    } else if (report.target_steam_id === props.steamId) {
      reports.value = [];

      lastTotal.value = 0;
      badgeUpdate(0);
    }
  };

  onUnmounted(() => {
    centrifugoCreateSubscription.value?.unsubscribe();
    centrifugoDeleteSubscription.value?.unsubscribe();
  });

  onMounted(() => {
    badgeUpdate(-1);
    
    next();
  });

  const deleteReport = async (id: number) => {
    await useCourtApi().report.delete(id);
    useRuntimeNotification('success', t('delete-report-success'));
  };

  const lastTotal = ref<number>();

  const next = async () => {
    const { results, total } = await loader.value.next();
    lastTotal.value = total;

    if (!reports.value) {
      reports.value = [];
    }

    reports.value?.push(...results);

    badgeUpdate(total!);
  };

  watch(() => loader.value, (v) => {
    if (!v) {
      return;
    }
    reports.value = null;
    next();
  }, {
    deep: true
  });

  const getRequiredPlayer = (report: ReportBrowseDto): { name: string, avatar: string; status: string; player?: PlayerFullDto } => {
    if (props.mode === 'sent') {
      return {
        name  : report.target?.steam_name ?? '-',
        avatar: report.target?.steam_avatar.replace('_full', '') ?? useConfigStore().Avatars.PlayerNotExistsPlaceholder,
        status: _playerStatus.getDefaultStatusColors(report.target?.status ?? 'afk'),
        player: report.target
      };
    }

    return {
      name  : report.initiator?.steam_name ?? '-',
      avatar: report.initiator?.steam_avatar.replace('_full', '') ?? useConfigStore().Avatars.PlayerNotExistsPlaceholder,
      status: _playerStatus.getDefaultStatusColors(report.initiator?.status ?? 'afk'),
      player: report.initiator
    };
  };

  const rows = computed((): PlayerDetailsCardRow[] => {
    return [
      props.mode === 'received' ? t('modal.player.reports.report-from') : t('modal.player.reports.report-on'), 
      t('modal.player.reports.reason'), 
      {
        title     : t('modal.player.reports.comment'),
        inline    : true,
        noTruncate: true,
      }
    ];
  }); 
</script>

<template>
  <template v-if="reports && reports.length == 0">
    <div class="flex flex-col justify-center items-center h-full">
      <EmptyImage
        type="empty"
        :title="t('modal.player.reports.empty.title')"
        :subtitle="props.mode === 'received' ? t('modal.player.reports.empty-received.subtitle') : t('modal.player.reports.empty-sent.subtitle')"
      />
    </div>
  </template>
  <template v-else>
    <div class="min-h-full w-full relative">
      <Loading :can-view="reports != null" class="!bg-transparent" />
  
      <template v-if="reports != null">
        <div class="flex flex-col gap-2.5">
          <template v-for="report in reports" :key="report.id">
            <PlayerDetailsCard 
              :rows="rows" 
              :date-from="_time.format(report.created_at, 'DD.MM.YY # HH:mm', false)" 
            >
              <template #row0>
                <ListItem
                  v-if="report.initiator"
                  class="player"
                  :message="null"
                  size="x-small"
                  :image-url="getRequiredPlayer(report).avatar"
                  :image-status-color="getRequiredPlayer(report).status" 
                  @click="() => getRequiredPlayer(report).player ? ActivatePlayerDetailsModal(getRequiredPlayer(report).player!.steam_id) : null"
                >
                  <template #title>
                    <p class="nickname">
                      {{ getRequiredPlayer(report).name }}
                    </p>
                  </template>
                </ListItem>
              </template>
              <template #row1>
                {{ report.reason }}
              </template>
              <template v-if="report.message" #row2>
                {{ report.message }}
              </template> 
              <template v-if="_permissions.can(['Court_ReportDelete'])" #actions>
                <Button
                  preset="destructive"
                  class="!p-1 !rounded"
                  :action="() => deleteReport(report.id)"
                >
                  <Svg.delete class="fill-frey-50 !w-[17px]" />
                </Button>
              </template>
            </PlayerDetailsCard>
          </template>
        </div>
  
        <IntersectionObserver :next="next" class="z-10 pointer-events-none" />
      </template>
    </div>
  </template>
</template>

<style scoped lang="scss">
.player {
  @apply cursor-pointer max-w-[160px];

  .nickname {
    @apply leading-tight truncate;
    @apply text-primary-700;
  }

  &:hover {
    .nickname {
      @apply text-primary-500;
    }
  }
}
</style>
