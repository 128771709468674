
import { ClientDto } from "@/api/backend/core/client/client.dto";
import { useUnknownClient } from "@court/utils/player.utils";
import { usePanelStore } from "@panel/stores/panel.store";
import { useCoreApi } from "@src/api/backend/core/core.api";
import { ref, computed } from "vue";

export const useClientComposable = () => {  
  const _coreApi = useCoreApi();
  const _panel = usePanelStore();

  const load = async (id?: number) => {
    if (id === 0) {
      client.value = useUnknownClient().generate();
      return;
    }

    if (id === -1) {
      client.value = useUnknownClient().generateHiddenModer();
      return;
    }

    if (!id || client.value) {
      return;
    }
    
    const exists = _panel.projectClients.find(v => v.client.id === id);
    if (exists) {
      client.value = exists.client;
      return;
    }

    client.value = await _coreApi.client.browseBatchLoader.load(id);
  };

  const client = ref<ClientDto>();
  const subtext = computed(() => client.value?.tag ? `@${client.value?.tag}` : '@unknown' );

  return { client, subtext, load, _panel };
};