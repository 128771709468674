<script setup lang='ts'>
  import PlayerInline from '@court/components/player/PlayerInline.vue';
  import { useChatStore } from '../stores/chat.store';
  import { ChatSearchOpts, ChatSearchPlayer } from '../types/chat.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';

  const _panel = usePanelStore();
  const _chat = useChatStore();
  const { t } = useLocaleStore();

  const suggester = async (text: string): Promise<ChatSearchOpts[]> => {
    const suggestions: ChatSearchOpts[] = [];

    if (!text.length) {
      return [];
    }

    suggestions.push({ text });

    if (_chat.search.filter(v => 'steam_id' in v).length >= 2) {
      return suggestions;
    }

    const suitableClients = _panel.projectClients.filter(v => v.client.name?.toLowerCase()?.includes(text.toLowerCase()));

    const { results } = await useCourtApi().player.browseMinimal({
      limit  : Math.max(5 - suitableClients.length, 0),
      page   : 0,
      search : text,
      offline: true,
    });

    const totalResults: ChatSearchPlayer[] = [
      ...suitableClients.map<ChatSearchPlayer>(v => ({
        steam_id    : v.client.id.toString(),
        steam_name  : v.client.name ?? 'Unknown',
        steam_avatar: useConfigStore().Urls.Images(v.client.avatar ?? '')
      })),
      ...results
    ];

    const suitablePlayers = totalResults.filter(v => !_chat.search.some(c => ('steam_id' in c) && c.steam_id === v.steam_id));

    suggestions.push(...suitablePlayers.splice(0, 5));
  
    return suggestions;
  };

  const middleware = (opts: ChatSearchOpts) => {
    if ('text' in opts) {
      return [..._chat.search.filter(v => !('text' in v)), opts];
    } 

    return undefined;
  };
</script>

<template>
  <SuggesterInput
    v-model="_chat.search"
    no-wrap
    :placeholder="t('chat.search.placeholder')"
    :compiler="(text) => ({ text })"
    :suggester="suggester"
    style="min-height: 30px; max-width: 200px;"
    :middleware="middleware"
    class="search-suggester"
  >
    <template #default="{ value }">
      <div class="bg-transparent rounded text-grey-50 px-1 overflow-hidden">
        <template v-if="'text' in value">
          <div class="flex items-center gap-1 hover:line-through">
            <span class="text-grey-400 text-14-400">{{ t('chat.search.contains') }}</span>
            <p class="leading-normal text-14-400 whitespace-nowrap">{{ value.text }}</p>
          </div>
        </template>
        <template v-else>
          <span class="inline-flex items-center hover:line-through">
            <span class="mr-1 text-grey-400">{{ t('chat.search.from') }}</span>
            <PlayerInline
              disable-click
              type="small"
              :steam_id="value.steam_id"
              :steam_name="value.steam_name"
              :steam_avatar="value.steam_avatar"
              hide-avatars
              class="inline"
              style="max-width: 80px;"
            />
          </span>
        </template>
      </div>
    </template>
    <template #suggest="{ value }">
      <div class="item">
        <div class="content">
          <template v-if="'text' in value">
            <span class="before">{{ t('chat.search.contains') }}</span>
            <p class="truncate">{{ value.text }}</p>
          </template>
          <template v-else>
            <span class="before">{{ t('chat.search.from') }}</span>
            <div class="player">
              <img class="avatar" :src="value.steam_avatar ?? ''">
              <p class="truncate">{{ value.steam_name ?? '' }}</p>
            </div>
          </template>
        </div>
      </div>
    </template>
  </SuggesterInput>
</template>

<style lang='scss' scoped>

.search-suggester {
  ::placeholder {
    @apply pl-1;
  }
}

.item {
  @apply w-full;
  @apply p-1.5;
  @apply rounded overflow-hidden;
  @apply text-grey-50;

  .content {
    @apply flex gap-1;

    .before {
      @apply opacity-50;
    }

    .player {
      @apply leading-5;
      @apply flex gap-1 overflow-hidden;
      .avatar {
        @apply bg-white/5 rounded-full aspect-square w-5;
      }
    }
  }
}

</style>