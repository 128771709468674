<script setup lang="ts">
  import TableBase, { TableHeaders } from '@/components/table/TableBase.vue';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { Ref, computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditType } from '@/api/backend/court/audit/audit.dto';

  import AuditPlayerBan from './types/AuditPlayerBan.vue';
  import AuditBase from './AuditBase.vue';
  import AuditPlayerReportDelete from './types/AuditPlayerReportDelete.vue';
  import AuditBanEdit from './types/AuditBanEdit.vue';
  import AuditPlayerKick from './types/AuditPlayerKick.vue';
  import AuditCommandExecute from './types/AuditCommandExecute.vue';
  import AuditCommandEdit from './types/AuditCommandEdit.vue';
  import AuditChatMessage from './types/AuditChatMessage.vue';
  import AuditEditSubscription from './types/AuditEditSubscription.vue';
  import AuditPlayerCheck from './types/AuditPlayerCheck.vue';
  import AuditPlayerIgnore from './types/AuditPlayerIgnore.vue';
  import AuditEditSettings from './types/AuditEditSettings.vue';
  import AuditSignageDelete from './types/AuditSignageDelete.vue';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';
  import { useCourtApi } from '@/api/backend/court/court.api';
  
  export type AuditHeaders = TableHeaders<('main')>;

  const { t } = useLocaleStore();
  const _panel = usePanelStore();

  const props = defineProps<{ 
    client_id: number | null; 
    type: AuditType | null; 
  }>();

  const getUsageTypes = computed(() => {
    if (!props.type) {
      return [];
    }

    return [props.type];
  });

  const loader = computed(() => {
    const types = getUsageTypes.value;
    props.client_id;

    return new PaginationService<AuditDto>((page, limit) => {
      return useCourtApi().audit.browse({ page, limit, types: types, client_id: props.client_id ?? undefined });
    });
  });
  
  const headers = computed<AuditHeaders>(() => ({ 
    main: {
      text: ''
    },
  }));

  const items = ref<AuditDto[] | null>(null) as Ref<AuditDto[] | null>;

  const centrifugoAuditCreated = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project) {
      return;
    }

    centrifugoAuditCreated.value = useCentrifugoApi().subscribeAudit(_panel.project.id, (audit) => {
      if (!items.value) {
        return;
      }

      if (props.type && audit.type !== props.type) {
        return;
      }

      if (props.client_id && audit.client_id != props.client_id) {
        return;
      }

      items.value.unshift(audit);
    });
  });

  onUnmounted(() => {
    centrifugoAuditCreated.value?.unsubscribe();
  });
  
  watch(() => loader.value, () => {
    items.value = null;
    next();
  });

  const next = async () => {
    const loaderHash = loader.value.id;
    
    const result = await loader.value.next();

    if (loader.value.id !== loaderHash) {
      return;
    }    
    
    if (!items.value) {
      items.value = [];
    }

    items.value.push(...result.results);
  };

  const warningText = computed(() => {
    if (getUsageTypes.value === undefined) {
      return undefined;
    }

    return t('general.no-data-filters');
  });

  const detectAuditComponent = (audit: AuditDto) => {
    switch (audit.type) {
      case 'PlayerBan': {
        return AuditPlayerBan;
      }
      case 'PlayerReportDelete': {
        return AuditPlayerReportDelete;
      }
      case 'BanEdit': {
        return AuditBanEdit;
      }
      case 'PlayerKick': {
        return AuditPlayerKick;
      }
      case 'CommandExecute': { 
        return AuditCommandExecute; 
      } 
      case 'CommandEdit': {
        return AuditCommandEdit;
      }
      case 'ChatMessage': { 
        return AuditChatMessage; 
      } 
      case 'EditSubscription': { 
        return AuditEditSubscription; 
      } 
      case 'PlayerCheck': { 
        return AuditPlayerCheck; 
      } 
      case 'PlayerIgnore': { 
        return AuditPlayerIgnore; 
      }
      case 'EditSettings': {
        return AuditEditSettings;
      }
      case 'SignageDelete': {
        return AuditSignageDelete;
      }
    }

    return AuditBase;
  };
</script>

<template>
  <TableBase
    :next="next"
    hide-header-line
    class="table-base"
    :items="items"
    :item-key="(o) => o.id.toString()"
    :headers="headers"

    missing-data-type="empty"
    :missing-data-title="t('general.no-data-header')"
    :missing-data-subtitle="t('general.no-data-subtitle')"

    :missing-data-warning="warningText"
  >
    <template #main="{item}">
      <template v-if="!item">
        <AuditBase :client_id="0" />
      </template>

      <template v-else>
        <div class="flex items-center gap-5" :alt="item.type">
          <component :is="detectAuditComponent(item)" v-bind="item" />
        </div> 
      </template>
    </template>
  </TableBase>
</template>

<style lang="scss" scoped>
.table-base {
  &:deep(.content-layer) {
    @apply w-full;
    .row {
      @apply h-auto;
    }
  }
}
</style>