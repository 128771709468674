<script setup lang='ts'>
  import { DebugService } from '@/api/backend/debug.service';
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useAdaptiveStore } from '@/stores/adaptive.store';
  import MenuIcon from '@src/assets/icons/menu.svg';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const _adaptive = useAdaptiveStore();

  const _route = useRoute();
  const _router = useRouter();

  defineProps<{ title: string }>();

  const id = computed(() => {
    return _route.params.id;
  });

  const back = () => {
    if (!id.value) {
      return;
    }

    if (!_route.name) {
      DebugService.error(`Неправильный роутер, отсутствует имя в таблице навигации`);
      return;
    }

    _router.push({ name: _route.name });
  };
</script>

<template>
  <div class="header" style="min-height: 64px">
    <div class="text-md font-medium flex flex-row gap-4 items-center">
      <template v-if="_adaptive.isMobile">
        <MenuIcon class="fill-grey-650 aspect-square w-6 left-activator" @click="_adaptive.editLeftBar()" />
      </template>
      <div class="flex gap-0.5 items-center text-lg font-medium">
        <span
          :class="id ? 'text-grey-400 hover:text-grey-450 cursor-pointer' : 'text-grey-50'"
          @click="back"
        >{{ title }}</span>
        <template v-if="id">
          <Svg.arrow class="arrow" />
          <span class="text-grey-50">#{{ id }}</span>
        </template>
      </div>
    </div>

    <template v-if="$slots.search">
      <div class="flex justify-end items-center gap-2">
        <slot name="search" />
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
.header {
  @apply flex justify-between gap-5;
  @apply px-4;
  @apply text-grey-50 bg-grey-900;
  @apply items-center;
  @apply border-b border-grey-1000;
  @apply sticky left-0 z-30;
  
  transition: all $adaptive-resize-speed;
  @apply ease-in-out;

  .arrow {
    @apply fill-grey-500 w-5;
  }
}
</style>