import { RouteRecordRaw } from 'vue-router';
import AuthPage from '@src/views/client/pages/login/AuthPage.vue';
import ClientView from '@src/views/client/ClientView.vue';
import ProfilePage from '@src/views/client/pages/profile/ProfilePage.vue';

export enum ClientRoutes {
  Auth = 'auth',
  Profile = 'profile'
}

export const ClientRouter: RouteRecordRaw = {
  path     : '/client',
  name     : 'Client',
  component: ClientView,
  redirect(to) {
    return { name: ClientRoutes.Profile };
  },
  children: [
    {
      path     : 'profile',
      name     : ClientRoutes.Profile,
      component: ProfilePage,
    },
    {
      path     : 'auth',
      name     : ClientRoutes.Auth,
      component: AuthPage,
      props    : (to) => {
        return {
          email: to.query.email,
          code : to.query.code
        };
      },
    }
  ],
};
