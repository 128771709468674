
import { Axios } from "axios";
import { CourtDiscordDto, CourtDiscordType } from "./court-discord.dto";

export const useCourtDiscordApi = (api: Axios) => {
  const getAllDiscords = async () => {
    const result = await api.get<CourtDiscordDto[]>('/court-discord');

    return result.data;
  };

  const upsertDiscord = async (data: CourtDiscordDto) => {
    const result = await api.put('/court-discord', data);

    return result.data;
  };

  const toggle = async (type: CourtDiscordType, value: boolean) => {
    const result = await api.put(`/court-discord/${type}/toggle`, null, { params: { value }});

    return result.data;
  };

  return {
    getAllDiscords,
    upsertDiscord,
    toggle,
  };
};
