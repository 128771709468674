<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { BanDto, BanEditPayloadDto } from '@/api/backend/court/ban/ban.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';

  export type PlayerBanEditModalOpts = {
    ban: BanDto;
  }

  export const ActivateBanEditModal = async (props: PlayerBanEditModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('ban-edit', props);
  };
</script>

<script setup lang='ts'>
  import StepperCard from '@/components/stepper/StepperCard.vue';

  import { computed, onMounted, ref } from 'vue';
  import BanStepGeneral from './steps/BanCreateStepGeneral.vue';
  import BanStepProof from './steps/BanCreateStepProof.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Step } from '@/components/stepper/stepper.dto';
  import { BanDataStorage, useBanData } from './stores/ban.storage';

  const props = defineProps<PlayerBanEditModalOpts & ModalInjectedProps>();

  const { t } = useLocaleStore();
  const _config = useConfigStore();

  const suspectName = computed(() => props.ban.player?.steam_name ?? props.ban.steam_id);
  const suspectAvatar = computed(() => props.ban.player?.steam_avatar ?? _config.Avatars.PlayerNotExistsPlaceholder);

  const computedSteps = computed(() => {
    const result: Step<'main' | 'proof'>[] = [];

    result.push({
      name  : 'main',
      header: {
        title   : t('modal.ban-edit.general.header.title'),
        subtitle: t('modal.ban.general.header.subtitle', { 
          name: suspectName.value
        }),
        img: suspectAvatar.value
      }
    });

    result.push({
      name  : 'proof',
      header: {
        title   : t('modal.ban.general.header.title'),
        subtitle: t('modal.ban.proofs.header.subtitle'),
        img     : suspectAvatar.value
      },

      nextDisabledCondition: () => !!banStorage.value?.proofs.some(v => !v.s3_id && v.file)
    });

    return result;
  });


  const banStorage = ref<BanDataStorage>();

  onMounted(() => {
    banStorage.value = useBanData(props.ban.steam_id, { player: props.ban.player, ban: props.ban }).value;
    const copy = useBanData(props.ban.steam_id, { player: props.ban.player, ban: props.ban }).value;

    props.injected.setBeforeClose(async () => {
      const banStorageChanges = JSON.stringify(banStorage.value) !== JSON.stringify(copy);

      if (!banStorageChanges) {
        return true;
      }

      return await ActivateConfirmModal({
        type       : 'warn',
        title      : t('general.title.changes-can-be-lost'),
        description: t('general.subtitle.changes-can-be-lost'),
        onConfirmed: () => {}
      });
    });
  });

  const setUpdater = (func: () => void) => {
    banStorage.value!.updater = () => setTimeout(func);
  };

  const finish = async () => {
    if (!banStorage.value) {
      return;
    }

    if (banStorage.value.proofs.some(v => !v.s3_id)) {
      ActivateConfirmModal({
        type       : 'default',
        title      : t('bans.upload-not-loaded-title'),
        description: t('bans.upload-not-loaded-subtitle')
      });
      return;
    }
    
    const obj: BanEditPayloadDto = {
      ban_ip_active: banStorage.value.ips[banStorage.value.target.steamId],
      proofs       : banStorage.value?.proofs.map(v => v.s3_id) as string[],
      reason       : banStorage.value?.target.reason,
      server_ids   : banStorage.value?.server_ids,
      comment      : banStorage.value?.comment,
      expired_at   : banStorage.value?.target.expired
    };

    try {
      await useCourtApi().ban.edit(props.ban.id, obj);

      props.injected.setBeforeClose(undefined);

      Object.assign(props.ban, obj);
      
      props.injected.close();
    }
    catch {

    }
  };
</script>

<template>
  <div class="ban-modal">
    <StepperCard
      v-if="banStorage"

      :steps="computedSteps"
      :callback="finish"
      @updater="setUpdater"
    >
      <template #main>
        <BanStepGeneral v-model="banStorage" />
      </template>

      <template #proof>
        <BanStepProof v-model="banStorage" />
      </template>
    </StepperCard>
  </div>
</template>

<style lang='scss' scoped>
.ban-modal {
  width: 95vw;
  max-width: 430px;
}

:deep(.label) {
  @apply text-grey-400;
  @apply mb-1;
  transition: none;
  @apply flex items-center justify-between;
}


:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}

:deep(.expired-input) {
  .input-box {
    input {
      @apply rounded-e-none;
    }
  }
}
</style>