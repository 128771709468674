<script setup lang="ts">
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';

  const props = withDefaults(defineProps<{
    preset?: 'default' | 'amber' | 'green' | 'red' | 'blue' | 'grey'
    tooltipText?: string | null;
  }>(), {
    preset     : 'default',
    tooltipText: null,
  });
</script>

<template>
  <template v-if="!props.tooltipText">
    <div class="player-details-card-badge" :class="preset">
      <slot />
    </div>
  </template>
  <template v-else>
    <TooltipBase trigger="hover" distance="10"> 
      <div class="player-details-card-badge" :class="preset">
        <slot />
      </div>
       
      <template #content> 
        <div class="tooltip-text"> 
          <p>{{ props.tooltipText }}</p> 
        </div> 
      </template> 
    </TooltipBase>
  </template>
</template>

<style lang="scss" scoped>
.player-details-card-badge {
  @apply rounded;
  @apply p-1;
  @apply flex gap-1 items-center;
  @apply text-16-500;

  :slotted(svg) {
    @apply w-[20px];
  }

  &.default {
    @apply bg-grey-900;
    @apply text-grey-500;
    @apply fill-grey-500;
    :slotted(svg) {
      @apply fill-grey-500;
    }
  }

  &.green {
    @apply bg-emerald-500/15;
    @apply text-emerald-500;
    @apply fill-emerald-500; 
    :slotted(svg) {
      @apply fill-emerald-500;
    }
  }

  &.blue {
    @apply bg-primary-500/15;
    @apply text-primary-500;
    @apply fill-primary-500; 
    
    :slotted(svg) {
      @apply fill-primary-500;
    }
  }

  &.red {
    @apply bg-rose-500/15;
    @apply text-rose-500;
    @apply fill-rose-500; 
    
    :slotted(svg) {
      @apply fill-rose-500;
    }
  }

  &.amber {
    @apply bg-amber-400/15;
    @apply text-amber-400;
    @apply fill-amber-400; 
    
    :slotted(svg) {
      @apply fill-amber-400;
    }
  }

  &.grey {
    @apply bg-grey-700;
    @apply text-grey-200;
    @apply fill-grey-200; 
    
    :slotted(svg) {
      @apply fill-grey-200;
    }
  }
}
</style>