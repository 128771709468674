import { defineStore } from "pinia";
import ContextMenu from "primevue/contextmenu";
import { ref } from "vue";
import { ExtendedMenuItem } from "@/components/quality/menu-extended/MenuExtended.vue";

export const useContextMenu = defineStore('contextmenu', () => {
  const menu = ref<InstanceType<typeof ContextMenu> & { visible: boolean }>();

  const options = ref<ExtendedMenuItem[]>();
  
  const setup = (_menu: InstanceType<typeof ContextMenu>) => {
    menu.value = _menu as InstanceType<typeof ContextMenu> & { visible: boolean };
  };

  const open = (e: MouseEvent, opts: ExtendedMenuItem[]) => {
    if (!menu.value) {
      alert('Меню ещё не готово');
      return;
    }
    
    options.value = opts;

    menu.value.show(e);
  };

  const hide = () => {
    if (!menu.value) {
      alert('Меню ещё не готово');
      return;
    }

    menu.value.hide();
    options.value = undefined;
  };

  return { setup, open, hide, options, menu };
});