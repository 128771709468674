<script setup lang="ts">
  import { SignageDto } from '@/api/backend/court/signages/signages.dto';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import SignageCard from './components/SignageCard.vue';
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import { usePanelStore } from '@panel/stores/panel.store';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import { SignageMock } from './mock/signage.mock';
  import InfinityContent from '@/components/quality/layout/InfinityContent.vue';
  import { PaginationService } from '@/api/backend/pagination.service';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import Loading from '@/components/Loading.vue';
  import SignagesSidebar from './SignagesSidebar.vue';
  import { useSignagesStore } from './stores/signages.store';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';

  const _panel = usePanelStore();
  const { t } = useLocaleStore();

  const signages = ref<SignageDto[]>();
  const _signages = useSignagesStore();


  const search = ref<string>('');
  
  const loader = computed(() => {
    const s = search.value;
    const server_id = _signages.filters.server_id;
    const only_liked = _signages.filters.only_liked;

    return new PaginationService<SignageDto>((page, limit) => useCourtApi().signages.browse({
      limit         : 40,
      page,
      server_id     : server_id == 0 ? undefined : server_id,
      only_liked    : only_liked ? only_liked : undefined,
      hide_destroyed: only_liked ? false : true,
      search        : s,   
    }));
  });

  const next = async () => {
    const loaderId = loader.value.id;

    try {
      const result = await loader.value.next();

      if (loaderId !== loader.value.id) {
        return;
      }
    
      if (!signages.value) {
        signages.value = [];
      }

      signages.value.push(...result.results);
    }
    catch (err: any) {
      if (!err?.message?.includes('опция доступна')) {
        throw err;
      }

      signages.value = SignageMock.map<SignageDto>(v => {
        const variants = [{
          url       : v,
          created_at: Date.now(),
          player    : useUnknownPlayer().generate('765611981211000397'),
        }];

        if (Math.random() > 0.5) {
          variants.push({
            url       : v,
            created_at: Date.now(),
            player    : useUnknownPlayer().generate('765611981211000397'),
          });
        }

        return {
          net_id    : '123456789',
          position  : '102.12, 24.21, 24.52',
          project_id: 1,
          server_id : 1,
          square    : 'F1',
          type      : 'sign.small.wood',
          variants  : variants,
          liked_at  : Math.random() > 0.8 ? Date.now() : undefined,
        };
      });
    }
  };

  watch(
    () => loader.value, 
    () => {
      signages.value = undefined;
      next();
    }, 
    { deep: true }
  );

  onMounted(async () => next());

  const centrifugoSub = ref<CentrifugoSubscribe>();

  onMounted(() => {
    if (!_panel.project?.id) {
      return;
    }

    centrifugoSub.value = useCentrifugoApi().subscribeSignageUpdate(_panel.project.id, (data) => {
      if (!signages.value) {
        return;
      }

      if (_signages.filters.only_liked && !data.liked_at) {
        return;
      }

      if (_signages.filters.server_id && data.server_id != _signages.filters.server_id) {
        return;
      }

      const exists = signages.value.find(v => v.project_id == data.project_id && v.server_id == data.server_id && v.net_id == data.net_id);
      if (exists) {
        if (data.destroyed_at) {
          onDeleted(data);
          return;
        }

        Object.assign(exists, data);
        return;
      }
      
      if (data.destroyed_at) {
        return;
      }

      signages.value.unshift(data);
    });
  });

  const onDeleted = (data: SignageDto) => {
    if (!signages.value) {
      return;
    }

    const indexOf = signages.value.findIndex(v => v.project_id == data.project_id && v.server_id == data.server_id && v.net_id == data.net_id);
          
    if (indexOf >= 0) {
      signages.value.splice(indexOf, 1);
    }
  };

  onUnmounted(() => {
    centrifugoSub.value?.unsubscribe();
  });
</script>

<template>
  <LayoutPage
    :header="t('signages.header')"
    always-absolute
    :default-search="e => search = e"
  >
    <CourtNoSubscriptionContent
      type="DrawingsSystem"
      color="bg-grey-1000/90"
      class="!overflow-y-scroll !h-full relative"
    >
      <InfinityContent :next="next" class="w-full content">
        <template v-if="!signages">
          <div style="height: calc(100vh - 100px)" class="flex flex-col items-center justify-center">
            <Loading :can-view="false" />
          </div>
        </template>
        <template v-else-if="!signages.length">
          <div style="height: calc(100vh - 100px)" class="flex flex-col items-center justify-center">
            <EmptyImage
              type="empty"
              :title="t('no-signs-found-title')"
              :subtitle="t('no-signs-found-subtitle')"
            />
          </div>
        </template>
        <template v-else>
          <div class="signages w-full">
            <template v-for="(value, _) in signages" :key="_">
              <SignageCard  
                :profile="false"
                :signage="value"
                :search="search"
                :removed="() => onDeleted(value)"
                :like-only="_signages.filters.only_liked"
              /> 
            </template>
          </div>
        </template>
      </InfinityContent>
    </CourtNoSubscriptionContent>

    <template #side-bar>
      <SignagesSidebar />
    </template>
  </LayoutPage>
</template>

<style lang="scss" scoped>
.signages {
  @apply grid grid-cols-4 gap-5 p-5;
}

@media (max-width: 1600px) {
  .signages {
    @apply grid-cols-3;
  }
}
@media (max-width: 1300px) {
  .signages {
    @apply grid-cols-2;
  }
}
@media (max-width: 700px) {
  .signages {
    @apply grid-cols-1;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>