<script setup lang='ts'>
  import Button from '@/components/Button.vue';
  import ModalMenuListItem from '@/components/modal-menu/components/ModalMenuListItem.vue';
  import ModalMenuSection from '@/components/modal-menu/components/ModalMenuSection.vue';
  import ModalMenuTab from '@/components/modal-menu/components/ModalMenuTab.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useSteamAuthStart } from '@src/composable/steam-connect.composable';
  import { computed } from 'vue';
  import BaseTip from '@panel/components/BaseTip.vue';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';
  import { useCoreApi } from '@/api/backend/core/core.api';
  import ListItem from '@/components/quality/containers/ListItem.vue';

  const { t } = useLocaleStore();
  const _app = useAppStore();
  defineProps<{
    mobile: boolean
  }>();

  const services = computed(() => ([
    {
      name   : 'Steam',
      icon   : Svg.steam,
      color  : 'fill-[#fff]',
      connect: async () => {
        if (!_app.client) {
          return;
        }

        const link = await useSteamAuthStart();

        const steam = await useCoreApi().auth.steamConnectFinish(link);
      
        _app.client.steam = steam;
      },
      connected: !!_app.client?.steam,
      allowed  : true
    }, 
    {
      name     : 'Discord',
      icon     : Svg.discord,
      color    : 'fill-[#5865F2]',
      connect  : onDiscordConnect,
      connected: false,
      allowed  : false
    }, 
    {
      name     : 'Telegram',
      icon     : Svg.telegram,
      color    : 'fill-[#29A9EB]',
      connect  : onTelegramConnect,
      connected: false,
      allowed  : false
    }, 
  ]));
  
  const untieStem = async () => {
    if (!_app.client) {
      return;
    }

    await useCoreApi().auth.steamConnectDelete();

    _app.client.steam = undefined;
  };

  const onDiscordConnect = async () => {
    useRuntimeNotification('warning', t('modal.client.integrations.connect.discord'));  
  };
  const onTelegramConnect = async () => {
    useRuntimeNotification('warning', t('modal.client.integrations.connect.telegram'));  
  };

</script>

<template>
  <ModalMenuTab
    :mobile="mobile"
  >
    <ModalMenuSection :title="t('modal.client.integrations.title')" :mobile="mobile">
      <BaseTip
        type="hint"
        disposable="basetip-client-edit-modal-integration"
      >
        {{ t('modal.client.integrations.text') }}
      </BaseTip>

      <div class="integrations">
        <template v-for="service in services.filter(v => !v.connected)" :key="service.name">
          <div class="integration">
            <ListItem 
              :svg="{ component: service.icon, classes: `${service.color} !rounded-md` }"
              :title="service.name"
              :message="t('modal.client.integrations.connect.integratin', {int: service.name})"
              :class="service.name"
            />
            <Button
              class="!text-14-500 button-truncated flex-shrink-0"
              preset="default"
              :text="t('general.connect')"
              :action="() => service.connect?.()"
            />
          </div>
        </template>
      </div>
    </ModalMenuSection>
    <ModalMenuSection
      v-if="_app.client?.steam"
      :title="t('general.conntected')"
      :mobile="mobile"
    >
      <div class="flex justify-between items-center">
        <ListItem
          :image-url="_app.client.steam.steam_avatar"
          :title="'Steam'"
          :message="_app.client.steam.steam_name"
          :skeleton="'avatar'"
        />
        <Button
          class="!text-14-500"
          preset="destructive"
          :text="t('modal.client.integrations.disconnect')"
          :action="untieStem"
        />
      </div>
    </ModalMenuSection>
  </ModalMenuTab>
</template>

<style lang='scss' scoped>
.integrations {
  @apply flex flex-col gap-5;

  .integration {
    @apply flex justify-between items-center gap-2.5;

    :deep(.img-box) {
      @apply bg-grey-1000 p-2;
    }

    .Steam {
      :deep(.img-box) {
        @apply bg-[#fff]/15;
      }
    }

    .Discord {
      :deep(.img-box) {
        @apply bg-[#5865F2]/15;
      }
    }

    .Telegram {
      :deep(.img-box) {
        @apply bg-[#29A9EB]/15;
      }
    }
  }
}
</style>