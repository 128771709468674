<script setup lang="ts">
  import { useDataStore } from '@/stores/data.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { computed, onMounted, ref } from 'vue';

  const props = defineProps<{ type: "info" | "warning" | "hint" | "danger", disposable?: string }>();

  const _data = useDataStore();

  const readArray = ref<string[]>([]);

  onMounted(() => _data.sync('read-array-of-tip', readArray));

  const read = () => {
    if (!props.disposable) {
      return;
    }

    readArray.value.push(props.disposable);
  };

  const iconComponent = computed(() => {
    switch (props.type) {
      case 'info':
        return Svg.circle_info;
        
      case 'warning':
        return Svg.warning_circle;

      case 'hint':
        return Svg.book;

      case 'danger':
        return Svg.warning;

      default:
        return Svg.circle_info;
    }
  });

  const isRead = computed(() => readArray.value.includes(props.disposable || ''));
</script>

<template>
  <div
    v-if="!isRead || !disposable"
    class="tip bg-dot-pattern"
    :class="{ [type]: true }"
  >
    <div class="flex gap-2">
      <component :is="iconComponent" class="icon" />

      <span>
        <slot />
      </span>
    </div>

    <Svg.cross_large
      v-if="disposable"
      class="close"
      @click="read"
    />
  </div>
</template>

<style lang='scss' scoped>
.tip {
  @apply flex justify-between p-2 gap-2 rounded-md;
  @apply bg-grey-800;
  @apply text-grey-300 text-sm;

  .icon {
    @apply h-5 w-5 flex-shrink-0;
    @apply fill-grey-200;
  }

  .close {
    @apply h-4 w-4 flex-shrink-0;
    @apply fill-grey-50/25;
    @apply cursor-pointer;
  }

  &.info {
    @apply bg-grey-800 text-grey-200;

    .icon {
      @apply fill-grey-200;
    }

    .close {
      @apply fill-grey-200/50;
    }
  }

  &.warning {
    @apply bg-amber-400/15 text-amber-400;

    .icon {
      @apply fill-amber-400;
    }

    .close {
      @apply fill-amber-400/50;
    }
  }

  &.hint {
    @apply bg-primary-500/15 text-primary-500;

    .icon {
      @apply fill-primary-500;
    }

    .close {
      @apply fill-primary-500/50;
    }
  }

  &.danger {
    @apply bg-red-500/15 text-red-500;

    .icon {
      @apply fill-red-500;
    }

    .close {
      @apply fill-red-500/50;
    }
  }
}
</style>