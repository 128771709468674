<script setup lang="ts">
  import { useLocaleStore } from "@/stores/locale.store";
  import ModeratorTab from "./tabs/ModeratorTab.vue";
  import CourtNoSubscriptionContent from "@court/components/no-subscription/CourtNoSubscriptionContent.vue";
  import TitledPage from '@/components/layout/TitledPage.vue';
  import { useCourtSubscriptionStore } from "@court/stores/subscription.store";
  import { usePanelStore } from "@panel/stores/panel.store";

  const { t } = useLocaleStore();

  const _panel = usePanelStore();
  const _subscription = useCourtSubscriptionStore();
</script>

<template>
  <TitledPage
    :top-title="t('stats.title')"
    :top-subtitle="t('stats.subtitle')"
    :loading="!_subscription.state || !_panel.projectClients"
  >
    <CourtNoSubscriptionContent type="StaffStats" color="bg-grey-1000/80">
      <ModeratorTab />
    </CourtNoSubscriptionContent>
  </TitledPage>
</template>

<style lang="scss" scoped>

</style>