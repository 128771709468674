<script setup lang='ts'>
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import CheckExactPageLeftChatInput from './CheckExactLeftChatInput.vue';
  import CheckExactLeftActivity from './CheckExactLeftActivity.vue';
  import CheckExactLeftHeader from './CheckExactLeftHeader.vue';
  import { ref } from 'vue';

  defineProps<{ checkId: number, check: CheckDto | null }>();

  const activity = ref<InstanceType<typeof CheckExactLeftActivity>>();
</script>

<template>
  <div class="wrapper">
    <CheckExactLeftHeader
      v-if="check && activity?.activities"
      :check="check"
      :activities="activity?.activities ?? null"
      :finished="!!check.finished_at"
      class="check-header"
    />

    <CheckExactLeftActivity
      ref="activity"
      :check-id="checkId"
      :check="check"
      class="check-content"
    />
    
    <template v-if="check && !check.finished_at">
      <div class="check-footer">
        <CheckExactPageLeftChatInput :steam_id="check.suspect.steam_id" />
      </div>
    </template> 
  </div>
</template>

<style lang='scss' scoped>
.wrapper {
  @apply w-full;
  @apply flex-1;
  @apply flex flex-col;
  @apply overflow-auto;
}

.check-content {
  flex: 1 1;
  overflow-y: auto;
}

.check-footer {
  flex: 0 1;
  @apply border-t border-grey-900;
  @apply px-5 py-4;
}
</style>