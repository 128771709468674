<script lang="ts">
  export type BanSyncEditProps = {
    ban_sync: BanSyncAvailableSubscriptionsDto | BanSyncSubscriptionsDto;
  }

  export const ActivateBanSyncEditModal = (ban_sync: BanSyncAvailableSubscriptionsDto | BanSyncSubscriptionsDto) => {
    const _modal = useModalsStore();

    _modal.open('ban-sync-edit', { ban_sync });
  };
</script>

<script setup lang="ts">
  import Button from '@/components/Button.vue';
  import DividedCard from '@/components/DividedCard.vue';
  import Loading from '@/components/Loading.vue';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { BanSyncAvailableSubscriptionsDto, BanSyncSubscriptionsDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { useTimeStore } from '@/stores/time.store';
  import { useBanStore } from '@court/stores/ban.store';
  import ProjectListItem from '@panel/components/project/ProjectListItem.vue';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useValidators } from '@src/composable/validate.composable';
  import { computed, onMounted, ref } from 'vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();
  const _time = useTimeStore(); 
  const _ban = useBanStore();
  const _validator = useValidators();

  const props = defineProps<BanSyncEditProps & ModalInjectedProps>();

  const computedIntervals = computed((): SelectValue<number>[] => {
    return [ {
      text : t('ban-sync-interval-month'),
      value: 30
    }, {
      text : t('ban-sync-interval-3month'),
      value: 90
    }, {
      text : t('ban-sync-interval-6month'),
      value: 182
    }, {
      text : t('ban-sync-interval-year'),
      value: 365
    }];
  });

  const data = ref<{
    auto_kick: boolean;
    auto_kick_regex: string;
    auto_kick_time_limit: number;

    send_notification: boolean;
  }>();

  const original = ref<BanSyncAvailableSubscriptionsDto | BanSyncSubscriptionsDto>();

  onMounted(() => {
    data.value = {
      auto_kick           : 'auto_kick' in props.ban_sync ? props.ban_sync.auto_kick : false,
      auto_kick_regex     : 'auto_kick_regex' in props.ban_sync ? props.ban_sync.auto_kick_regex : '',
      auto_kick_time_limit: 'auto_kick_time_limit' in props.ban_sync ? props.ban_sync.auto_kick_time_limit : 30,

      send_notification: 'send_notification' in props.ban_sync ? props.ban_sync.send_notification : false,
    };

    if ('auto_kick' in props.ban_sync) {
      original.value = JSON.parse(JSON.stringify(data.value));
    }
  });

  const save = async () => {
    if (!data.value) {
      return;
    }

    try {
      await useCourtApi().ban.setSubscriptions(props.ban_sync.project_id_original, data.value.auto_kick, data.value.auto_kick_regex, data.value.auto_kick_time_limit, data.value.send_notification);
  
      const exists = _ban.subscription?.find(v => v.project_id_original === props.ban_sync.project_id_original);
      if (!exists) {
        _ban.warmup();
      } else {
        Object.assign(exists, data.value);
      }

      original.value = JSON.parse(JSON.stringify(data.value));

      props.injected.close();
    }
    catch (err: any) {
      if (err?.message?.includes('Your list is not public')) {
        props.injected.setBeforeClose(async () => {
          await ActivateConfirmModal({
            type       : 'warn',
            title      : t('ban-sync-list-confirm-no-share.title'),
            description: t('ban-sync-list-confirm-no-share.desc')
          });

          return true;
        });

        props.injected.close();
        return;
      }
    }
  };

  const subtext = computed(() => {
    if ('total_bans' in props.ban_sync) {
      return t('ban-sync-edit-modal-item-subtext', { value: props.ban_sync.total_bans.toLocaleString('de-DE')});
    }

    return t('ban-sync-page-item-subtext-subs-you', {time: _time.format(props.ban_sync.created_at)});
  });

  const regexInput = ref<InstanceType<typeof TextInput>>();

</script>

<template>
  <DividedCard class="sync-edit-modal">
    <template #header>
      <div class="header">
        <span class="title">{{ t('ban-sync-settings-subscribption') }}</span>
      </div>
    </template>

    <template #default>
      <div class="flex flex-col gap-4 relative">
        <Loading :can-view="!!data" />

        <template v-if="data">
          <ProjectListItem
            :project="ban_sync.project_original"
            :subtext="subtext"
            class="p-2 bg-grey-850 rounded-md mb-2"
          />

          <SwitchText
            v-model="data.auto_kick"
            :header="t('ban-sync-auto-kick-header')"
            :placeholder="t('ban-sync-auto-kick-subheader')"
          />

          <SelectInput
            v-if="data.auto_kick"
            v-model="data.auto_kick_time_limit"
            :values="computedIntervals"
            :label="t('ban-sync-auto-kick-interval-label')"
            variant="modal"
          />
  
          <TextInput
            v-if="data.auto_kick"
            ref="regexInput"
            v-model="data.auto_kick_regex"
            :label="t('ban-sync-regex-filter')"
            :placeholder="'\\b(cheat|macros)\\b'"
            :validator="_validator.IsRegex"
          />

          <SwitchText
            v-model="data.send_notification"
            :header="t('ban-sync-send-notify-header')"
            :placeholder="t('ban-sync-send-notify-subheader')"
          />
        </template>
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <Button
          :text="t('general.close')"
          preset="default"
          @click="injected.close()"
        />

        <Button
          :text="t('general.save')"
          preset="primary"
          :disabled="JSON.stringify(original) == JSON.stringify(data) || ((!regexInput || !!regexInput.activeException) && !!data?.auto_kick && !!data.auto_kick_regex.length)"
          :action="save"
        />
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>

  .header {
    @apply flex justify-between items-center w-full;

    .title {
      @apply text-grey-50;
      @apply font-semibold;
      @apply block;
    }

    .close {
      @apply w-5;
      @apply fill-grey-400;
      @apply cursor-pointer;

      &:hover {
        @apply fill-grey-200;
      }
    }
  }

.sync-edit-modal {
  width: 100vw;
  max-width: min(440px, 95vw);
}

:deep(.server-suggester) {
  .suggestions-wrapper {
    // max-height: 250px;
  }
  ::placeholder {
    @apply pl-0.5;
  }
}
:deep(.suggest) {
  @apply w-full;
  @apply flex justify-between;
  @apply text-base;
  @apply py-1 px-1.5;
  transition: none;
}

</style>