<script setup lang="ts">
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaBanEdit } from '@/api/backend/court/audit/audit.dto';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ref } from 'vue';
  import { onMounted } from 'vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const { t } = useLocaleStore();

  const props = defineProps<AuditDto<AuditMetaBanEdit>>();

  const audit = ref<InstanceType<typeof AuditBase>>();

  onMounted(() => load());

  const ban = ref<BanDto>();

  const load = async () => {
    ban.value = await useCourtApi().ban.browseBatchLoader.load(props.meta.ban_id);
  };
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="meta.action == 'edit' ? Svg.edit : Svg.unlock"
    :icon_styles="meta.action == 'edit' ? 'fill-grey-500' : 'fill-lime-400'"
    :date="created_at"
    ready
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">
      {{ audit?.client?.name }}
    </span>
    <span>{{ meta.action == 'edit' ? t('audit-edit-ban-text') : (meta.action === 'global-unban' ? t('audit-edit-ban-global-unban') : t('audit-edit-ban-unban')) }}</span>

    <template v-if="meta.amount">
      <span class="bright">{{ t('general.players', { n: meta.amount }) }}</span>
    </template>
    <template v-else>
      <span class="bright cursor-pointer" @click="() => !!ban?.player ? ActivateBanViewModal({ banId: ban.id, ban }) : {}">{{ ban?.player?.steam_name || t('general.unknown') }}</span>
    </template>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>