<script lang="ts">
  export type * from './table.dto';
</script>

<script setup lang="ts" generic="H extends string, T">
  import { TableHeaders } from './table.dto';
  import { computed, onMounted } from 'vue';
  import IntersectionObserver from '../IntersectionObserver.vue';
  import EmptyImage, { EmptyImageType } from '@/components/quality/empty/EmptyImage.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const emit = defineEmits(['rowClick']);
  const props = defineProps<{ 
    headers: TableHeaders<H>, 
    next: () => Promise<unknown>,

    items: T[] | null, 
    itemKey: (i: T) => string,

    missingDataType?: EmptyImageType,
    missingDataTitle?: string,
    missingDataSubtitle?: string,
    missingDataWarning?: string;

    hideHeaderLine?: boolean,

    onContext?: (e: MouseEvent, i: T) => unknown
  }>();

  const t = defineSlots<{
    [key in H | 'rowBackground']: (props: { item: T | undefined }) => unknown
  }>();

  onMounted(() => props.next()); 

  const values = computed<T[]>(() => {
    if (!props.items) {
      return new Array(25).fill(undefined) as T[];
    }
    
    return props.items;
  });

  const onContext = (e: MouseEvent, i: T) => {
    if (!i) {
      return;
    }

    props.onContext?.(e, i);
  };
</script>

<template>
  <div class="table-base relative overflow-auto flex-grow" :class="{ 'no-header': hideHeaderLine || (Array.isArray(values) && !values?.length) }">
    <div v-if="values?.length && !hideHeaderLine" class="header">
      <template v-for="(header, headerKey) in (headers as TableHeaders<H>)" :key="headerKey">
        <div 
          v-if="$slots[headerKey]"
          class="element"
          :class="{ 'text-right': header.right }"
          :style="{ 'min-width': header.min, 'max-width': header.max }"
        >
          <p>{{ header.text }}</p>
        </div>
      </template>
    </div>

    <div class="content-layer">
      <template v-if="values?.length">
        <template v-for="(item, _) in values" :key="item ? itemKey(item) : _">
          <div
            class="row relative"
            :class="{ defaultHover: !$slots.rowBackground }"
            @contextmenu.stop="(e) => onContext(e, item)"
          >
            <div v-if="$slots.rowBackground" class="absolute h-full w-full inset-0">
              <slot name="rowBackground" v-bind="{ item }" />
            </div>

            <template v-for="(header, headerKey) in (headers as TableHeaders<H>)" :key="item ? `${itemKey(item)}_${headerKey}` : `${headerKey}_${_}`">
              <div 
                v-if="$slots[headerKey]"
                class="element"
                :style="{ 'min-width': header.min, 'max-width': header.max, zIndex: 1 }"
              >
                <slot :name="headerKey" v-bind="{ item }" />
              </div>
            </template>
          </div>
        </template>

        <IntersectionObserver :next="next" />
      </template>
      <template v-else> 
        <div class="w-full h-full flex flex-col items-center justify-center">
          <EmptyImage
            :title="missingDataTitle"
            :subtitle="missingDataSubtitle"
            :type="missingDataType"
          />
          <div v-if="missingDataWarning" class="missing-data-warning">
            <Svg.warning />

            <p>{{ missingDataWarning }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$header-height: 44px;

.missing-data-warning {
  @apply flex items-center text-amber-400 text-sm gap-1 mt-4 bg-amber-400/10 px-2 py-2 rounded-md;

  svg {
    @apply fill-amber-400 h-4 w-4;
  }
}

.table-base {
  @apply relative ;
  width: fit-content;
  min-width: 100%;
  
  &.no-header {
    $header-height: 0;

    &::-webkit-scrollbar-button:start {
      @apply bg-grey-900/50;
      height: 0 !important;
    }

    .content-layer {
      height: 100% !important;
    }
  }

  &::-webkit-scrollbar-button:start {
    @apply bg-grey-900/50;
    height: $header-height;
  }

  .element {
    @apply w-full p-4;
  }

  .intersection {
    @apply absolute w-full;

    transform: translateY(-300px);
  }

  .content-layer {
    @apply h-full w-max; 
    height: calc(100% - $header-height);
    
    min-width: 100%;   

    .row {
      @apply w-full flex items-center;
      @apply border-b border-white/5;
      background-attachment: local;
      min-width: 100%;
      height: 72px;

      &.defaultHover {
        &:hover {
          @apply bg-grey-950/50;
        }
      }

      &:nth-last-child(2) {
        @apply border-none;
      }
    }
  }

  .header {
    @apply sticky top-0 w-max flex items-center z-10 overflow-hidden;
    @apply bg-grey-900/50 backdrop-blur-md;
    @apply text-grey-500 leading-10 truncate;

    min-width: 100%;
    height: $header-height;
    -webkit-backdrop-filter: blur(12px);
  }
}

.page-error {
  @apply w-full h-full flex flex-col justify-center items-center;
  @apply text-center;

  .emoji {
    @apply mb-2 text-6xl animate-bounce;
  }
  .title {
    @apply mb-1 text-xl text-grey-50 font-semibold leading-6;
  }
  .subtitle {
    @apply text-grey-500 text-base w-80;
  }
}

[device="mobile"] {
  .table-base {
    overscroll-behavior: none;
  }
}
</style>