<script setup lang='ts'>
  import { useAppStore } from '@/stores/app.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import { ActivateProjectCreateModal } from '@src/modals/project-create/ProjectCreateModal.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import Divider from '@/components/Divider.vue';
  import { useAccountMenu } from '@panel/menus/account.menu';
  import { ampli } from '@src/ampli';
  import { useConfigStore } from '@/stores/config/config.store';

  const _app = useAppStore();
  const _panel = usePanelStore();
  const _accountMenu = useAccountMenu();
</script>

<template>
  <div class="main-nav overflow-y-auto">
    <div class="top-group">
      <RouterLink
        :to="{ name: 'profile' }"
      >
        <div class="logo">
          <Svg.rustapp class="w-6" />
        </div>
      </RouterLink>

      <Divider class="my-3 mx-2.5" />

      <RouterLink
        v-for="project in _app.projects?.filter(v => v.accepted) ?? []"
        :key="project.domain"
        :to="{ params: { project: project.domain, id: undefined } }"
        active-class="selected"
        class="project"
        @click="ampli.clientProjectSwitch()"
      >
        <div class="point" />
        
        <AvatarExtended
          :url="project.avatar ? useConfigStore().Urls.Images(project.avatar) : ''"
          :active="_panel.project?.id === project.id"
          enable-shadow
          enable-hover-effects
        />
      </RouterLink>

      <div class="new-project" @click="() => ActivateProjectCreateModal({})">
        <Svg.plus_large class="h-5 w-5" />
      </div>
    </div>

    <div class="bottom-group">
      <AvatarExtended
        v-if="_app.client"
        :url="_app.client.avatar ? useConfigStore().Urls.Images(_app.client.avatar) : ''"
        enable-hover-effects
        active
        class="cursor-pointer"
        @click="_accountMenu.open"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
:deep(.divider) {
  .line {
    @apply bg-grey-700;
  }
  .text {
    @apply text-grey-700;
  }
}

.main-nav {
  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  
  @apply h-full w-full;
  @apply flex flex-col justify-between;

  .top-group {
    @apply flex flex-col gap-2;
  }
  .bottom-group {
    @apply flex flex-col items-center justify-center gap-5 mt-10;
  }

  .logo {
    @apply rounded-half;
    @apply bg-grey-800;
    @apply aspect-square;
    @apply flex items-center justify-center;
    @apply transition-all ease-in-out delay-0 duration-300;

    svg {
      @apply fill-grey-400;
      @apply transition-all ease-in-out delay-0 duration-300;
    }

    &:hover {
      @apply bg-grey-700;

      svg {
        @apply fill-grey-50;
      }
    }
  }

  .new-project {
    @apply aspect-square rounded-md;
    @apply flex items-center justify-center;
    @apply relative;
    @apply cursor-pointer;
    @apply transition-all ease-in-out rounded-3xl delay-0 duration-300;
    @apply bg-grey-800;

    svg {
      @apply fill-grey-400;
      @apply transition-all ease-in-out rounded-3xl delay-0 duration-300;
    }

    &:hover {
      @apply rounded-2xl;
      @apply bg-lime-500;

      svg {
        @apply fill-white;
      }
    }
  }
}

.project {
  @apply cursor-pointer;
  @apply relative flex justify-center items-center;

  &.selected {
    .point {
      @apply bg-white;
      @apply rounded-full;
      @apply absolute;
      @apply transition-all ease-in-out delay-0 duration-300;

      width: 8px;
      height: 8px;
      left: -20px;
    }

    &:hover {
      .point {
        height: 24px;
      }
    }
  }
}

.menu-button {
  @apply aspect-square;
  @apply flex items-center justify-center;
  @apply relative;
  @apply cursor-pointer;
  @apply bg-white/5;
  @apply rounded-3xl;
  @apply transition-all ease-in-out delay-0 duration-300;

  .svg-box {
    width: 100%;
    padding: 25%;
    @apply aspect-square;

    svg {
      @apply fill-white/25;
      @apply transition-all ease-in-out delay-0 duration-300;
    }
  }

  &:hover {
    @apply bg-primary-700/50;
    @apply rounded-2xl;

    svg {
      @apply fill-white/75;
    }
  }

  &.active-link {
    @apply bg-primary-700;

    .svg-box {
      svg {
        @apply fill-white;
      }
    }
  }
}

:deep(.divider-wrapper) {
  .line {
    @apply bg-white/10;
  }
}
</style>