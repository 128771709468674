<script setup lang='ts'>
  type SettingsContainerProps = {
    title?: string;
    colors?: string;
  };

  const props = withDefaults(defineProps<SettingsContainerProps>(), {
    title : undefined,
    colors: "#A9A9A9",
  });
</script>

<template>
  <div class="settings-container">
    <p v-if="props.title?.length" class="title">{{ props.title }}</p>
    <div class="settings" :class="props.colors">
      <slot />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.settings-container {
  &:not(:last-child) {
    @apply pb-5;
  }

  .title {
    @apply uppercase;
    @apply text-sm text-grey-400;
    @apply pb-1 ml-2.5;
  }

  .settings {
    @apply grid grid-cols-1 divide-y;
    @apply divide-grey-900;
    @apply rounded-md;
    @apply bg-grey-850;

    :deep() > * {
      @apply p-2.5;
    }
  }
}
</style>