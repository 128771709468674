<script setup lang='ts'>
  import { CheckDto } from '@/api/backend/court/check/check.dto';
  import PlayerDetails from '@court/components/player/details/PlayerDetails.vue';
  import CheckVerdict from './verdict/CheckVerdict.vue';
  import { onMounted, ref, watch } from 'vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import PlayerBox from '@court/components/player/PlayerBox.vue';
  import { useLocaleStore } from '@/stores/locale.store';

  const props = defineProps<{ check: CheckDto | null }>();

  const { t } = useLocaleStore();
  const bans = ref<BanDto[]>();

  const load = async () => {
    if (!props.check) {
      return;
    }

    const response = await useCourtApi().ban.browse({ ban_group_uuid: `check:${props.check.id}`, page: 0, limit: 50, sort_by: 'created' });
  
    bans.value = response.results;
  };

  onMounted(() => load());
  watch(() => props.check, () => load(), { deep: true });
</script>

<template>
  <template v-if="!check?.finished_at">
    <div class="h-full flex flex-col">
      <div class="verdict-header shrink-0">
        <PlayerBox
          size="large"
          style="max-width: 70%; width: 270px;"
          :player="check?.suspect ?? null"
          disable-highlight
        />
      </div>
      <div class="overflow-y-auto grow">
        <PlayerDetails
          :mobile-width="70000"
          :default-section="'general'"
          no-header
          :initial="check?.suspect?.steam_id ? { steamId: check?.suspect?.steam_id, player: check?.suspect } : undefined"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <CheckVerdict :check="check" :bans="bans" />
  </template>
</template>

<style lang='scss' scoped>
.verdict-header {
  @apply flex flex-col p-5 gap-5;
  @apply border-b border-grey-1000;
}
</style>