import dayjs from "dayjs";

import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

export const dayJsInit = () => {
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.extend(duration);
  
  dayjs.updateLocale('ru', {
    monthsShort: [
      "Янв", "Фев", "Мар", "Апр", "Мая", "Июн", 
      "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"
    ]
  });
};
