<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { ActivateBanEditModal } from '../ban-control/BanEditModal.vue';
  import { ActivatePlayerDetailsModal } from '../player-details/PlayerDetailsModal.vue';
  import AvatarExtended from '@/components/quality/containers/AvatarExtended.vue';
  import BanViewEditor from './components/BanViewEditor.vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useConfigStore } from '@/stores/config/config.store';
  import { useBanStore } from '@court/stores/ban.store';
  import { useRuntimeNotification } from '@/components/RuntimeNotification.vue';

  export type PlayerBanViewModalOpts = {
    banId: number;
    projectId: number;

    ban?: BanDto;
  }

  export const ActivateBanViewModal = async (props: Omit<PlayerBanViewModalOpts, 'projectId'> & { projectId?: number }) => {
    const { t } = useLocaleStore();
    const _modal = useModalsStore();
    const _panel = usePanelStore();
    const _permissions = usePermissionsStore();

    if (!props.projectId) {
      props.projectId = props.ban?.project_id || _panel.project?.id;
    }

    if (!_permissions.can(['Court_PlayerBanRead'])) {
      ActivateConfirmModal({
        type       : 'warn',
        title      : t('modal.ban.no-permissions-title'),
        description: t('modal.ban.no-permissions-subtitle'),
      });
      return;
    }

    _modal.open('ban-view', props as PlayerBanViewModalOpts);
  };
</script>

<script setup lang="ts">
  import DividedCard from '@/components/DividedCard.vue';
  import { BanDto } from '@/api/backend/court/ban/ban.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import BanViewInfo from './components/BanViewInfo.vue';
  import BanViewProofs from './components/BanViewProofs.vue';
  import BanViewRelated from './components/BanViewRelated.vue';
  import { onMounted, ref } from 'vue';
  import BanViewComment from './components/BanViewComment.vue';
  import Button from '@/components/Button.vue';

  const { t } = useLocaleStore();
  const _config = useConfigStore();
  const _panel = usePanelStore();
  const _permissions = usePermissionsStore();

  const props = defineProps<PlayerBanViewModalOpts & ModalInjectedProps>();

  const banDetails = ref<BanDto>();
  const relatedBans = ref<BanDto[]>();

  onMounted(async () => {
    banDetails.value = props.ban;

    if (!banDetails.value) {
      try {
        const response = await useCourtApi().ban.browse({
          project_id: props.projectId,
          ban_ids   : [props.banId],
          sort_by   : 'created',
          limit     : 1,
          page      : 0
        });
        
        banDetails.value = response.results[0];
      }
      catch (err: any) {
        if (err?.message?.includes('You have no access to this ban-list')) {
          props.injected.setBeforeClose(async () => {
            await ActivateConfirmModal({
              type       : 'warn',
              title      : t('modal-ban-view-confirm-no-access.title'),
              description: t('modal-ban-view-confirm-no-access.subtitle')
            });

            return true;
          });

          props.injected.close();
          return;
        }

        throw err;
      }

    }

    loadRelatedBans();
  });


  const loadRelatedBans = async () => {
    if (!banDetails.value?.ban_group_uuid) {
      relatedBans.value = [];
      return;
    }

    const result = await useCourtApi().ban.browse({ 
      project_id    : props.projectId,
      page          : 0, 
      limit         : 20,
      ban_group_uuid: banDetails.value.ban_group_uuid, 
      sort_by       : 'created', 
    });

    relatedBans.value = result.results.filter(v => (v.player?.steam_id ?? v.player_minimal?.steam_id) !== (banDetails.value?.player?.steam_id ?? banDetails.value?.player_minimal?.steam_id));
  }; 

  const edit = () => {
    if (!banDetails.value) {
      return;
    }

    ActivateBanEditModal({
      ban: banDetails.value
    });
  };

  const unban = async () => {
    if (!_permissions.can(['Court_PlayerBanUnban'])) {
      return;
    }

    if (!banDetails.value) {
      return;
    }

    await ActivateConfirmModal({
      type       : 'warn',
      title      : t('modal.ban-view.unban-title'),
      description: t('modal.ban-view.unban-subtitle'),

      // prompt       : t('ban-unban-reason'),
      beforeConfirm: async (comment) => {
        if (!banDetails.value?.id) {
          throw new Error(`No ban-ID`);
        }

        await useCourtApi().ban.unban(banDetails.value?.id, comment);
      },
      onClosed: async () => {
        banDetails.value = props.ban;

        banDetails.value = await useCourtApi().ban.browseBatchLoader.load(props.banId);

        loadRelatedBans();
      },
    });
  };  

  const copyBan = async (ban: BanDto) => {
    const prj = useBanStore().subscription?.find(v => v.project_id_original === ban.project_id)?.project_original;
    const extraInfo = `~BAN_SYNC_COPY~${ban.id}/${prj?.id ?? 0}/${prj?.name ?? 'Unknown'}`;

    const comment = ban.comment === 'x-factor-hidden' ? extraInfo : ban.comment + extraInfo;


    ActivateConfirmModal({
      type         : 'warn',
      title        : t('general.confirm.title'),
      description  : t('ban-copy-disclaimer'),
      beforeConfirm: async () => {
        await useCourtApi().player.ban(ban.steam_id, [{
          ban_ip_active: ban.ban_ip_active,
          ban_ip       : ban.ban_ip,
          proofs       : ban.proofs.some(v => v === 'x-factor-hidden') ? [] : ban.proofs,
          reason       : ban.reason,
          steam_id     : ban.steam_id,
          comment      : comment,
          server_ids   : [],
          expired_at   : ban.expired_at
        }]);

        useRuntimeNotification(`success`, t('ban-sync-copy-success'));
      }
    });

    
  };
</script>
  
<template>
  <DividedCard class="ban-view-modal" :is-loading="!banDetails || relatedBans == undefined">
    <template #header>
      <div class="ban-view-header">
        <p>
          <span class="header-title">{{ t('modal.ban-view.title', {id: banId}) }}</span>
          <span class="header-subtitle">{{ (banDetails?.player ?? banDetails?.player_minimal)?.steam_name ? t('modal.ban-view.subtitle', { player: (banDetails?.player ?? banDetails?.player_minimal)?.steam_name }) : banDetails?.steam_id }}</span>
        </p>
        <AvatarExtended
          class="append cursor-pointer bg-grey-850"
          :url="(banDetails?.player ?? banDetails?.player_minimal)?.steam_avatar ?? _config.Avatars.PlayerAvatarPlaceholder"
          active
          enable-hover-effects
          @click="banDetails ? ActivatePlayerDetailsModal(banDetails?.steam_id, banDetails.player) : undefined"
        />
      </div>
    </template>

    <template #default>
      <BanViewEditor v-if="banDetails" :ban="banDetails" />
      <BanViewInfo v-if="banDetails" :ban="banDetails" />
      <BanViewProofs v-if="banDetails?.proofs.length" :ban="banDetails" />
      <BanViewComment v-if="banDetails?.comment" :comment="banDetails.comment" />
      <BanViewRelated v-if="relatedBans?.length" :bans="relatedBans" />
    </template>

    <template #footer>
      <div class="flex justify-end w-full gap-2.5">
        <Button
          :text="t('general.close')"
          preset="default"
          class="button-truncated"
          :action="() => { 
            props.injected.close();
          }"
        />
        <Button
          v-if="banDetails?.computed_is_active && _permissions.can(['Court_PlayerBanEditGeneral']) && _panel.project?.id === banDetails?.project_id"
          :text="t('modal.ban-view.edit')"
          preset="default"
          class="button-truncated"
          :action="edit"
        />
        <Button
          v-if="banDetails?.computed_is_active && _permissions.can(['Court_PlayerBanUnban']) && _panel.project?.id === banDetails?.project_id"
          :text="t('modal.ban-view.unban')"
          preset="primary"
          class="button-truncated"
          :action="unban"
        />
        <Button
          v-if="banDetails && _permissions.can(['Court_PlayerBan']) && _panel.project?.id !== banDetails?.project_id"
          :text="t('modal.ban-view.ban-on-my-project')"
          preset="destructive"
          class="button-truncated"
          :action="() => copyBan(banDetails!)"
        />
      </div>
    </template>
  </DividedCard>
</template>

<style lang="scss" scoped>
.ban-view-modal {
  width: 95vw;
  max-width: 460px;
  min-height: 300px;
}
.ban-view-header {
  @apply w-full;
  @apply flex items-center justify-between;

  .header-title {
    @apply text-grey-50;
    @apply font-semibold;
    @apply block;
  }
  .header-subtitle {
    @apply max-w-[220px];
    @apply text-grey-400;
    @apply font-medium;
    @apply text-sm;
    @apply block;
    @apply truncate;
  }
  .append {
    @apply w-11;
    @apply rounded-half;
  }
}

</style>

