<script setup lang="ts">
  import { PlayerAlertJoinWithIpBanMetaDto } from '@/api/backend/court/player-alert/player-alert.dto';
  import PlayerAlertBase from './PlayerAlertBase.vue';
  import { onMounted, ref } from 'vue';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useUnknownPlayer } from '@court/utils/player.utils';
  import { ActivateBanViewModal } from '@court/modals/ban-view/BanViewModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  const props = defineProps<{
    meta: PlayerAlertJoinWithIpBanMetaDto,
    date: number
  }>();

  const { t } = useLocaleStore();
  const {generate} = useUnknownPlayer();
  const player = ref<PlayerMinimalDto>();
  
  onMounted(async () => {
    const result = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.steam_id);
    if (!result || !('steam_id' in result)) {
      player.value = generate(props.meta.steam_id);
      return;
    }

    player.value = result;
  });
</script>

<template>
  <PlayerAlertBase
    :date="date"
    icon="player-alert-types/worm.png"
    :ready="!!player"
  >
    <template v-if="player">
      <span 
        class="bright cursor-pointer" 
        @click="ActivatePlayerDetailsModal(player.steam_id)"
      > 
        {{ player.steam_name }} 
      </span>
      
      <span> {{ t('alerts.ip.connected') }} </span>

      <span 
        class="bright cursor-pointer" 
        @click="ActivateBanViewModal({ banId: meta.ban_id })"
      >
        {{ meta.ip }}
      </span>
      
      <span> {{ t('alerts.ip.banned') }} </span>
    </template>
  </PlayerAlertBase>
</template>