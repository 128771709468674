<script lang="ts">
  export type BanDateUnits = "hour" | "day" | "month";
  export type BanUnitSuggest = { 
    name: BanDateUnits, 
    text: string
  }

  
  export type BanDate = {
    amount: number;
    units: BanUnitSuggest
  }
</script>

<script setup lang="ts">
  import { defineAsyncComponent } from 'vue';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { computed } from 'vue';
  import { useCourtStore } from '@court/stores/court.store';
  import { useAppStore } from '@/stores/app.store';

  const { t } = useLocaleStore();

  const _app = useAppStore();
  const _court = useCourtStore();
  const props = defineProps<{ steam_id?: string; steam_name?: string; check?: boolean; }>();

  const SelectIcon = defineAsyncComponent(() => import('@src/assets/icons/select.svg'));
  
  /**
   * Доступные причины
   */
  const computedAvailableReasons = computed(() => {
    if (!_court.court || !_app.client?.name || !_app.client.tag) {
      return [];
    }

    const fillReasons = (reasons: string[]) => {
      if (!_court.court || !_app.client?.name || !_app.client.tag) {
        return reasons;
      }
    
      return reasons.map(v => {
        let r = v.toString();

        r = r      
          .replace('{client_name}', _app.client!.name)
          .replace('{client_tag}', '@' + _app.client!.tag)
          .replace('{stuff_name}', _app.client!.name)
          .replace('{stuff_tag}', '@' + _app.client!.tag)
          .replace('{staff_name}', _app.client!.name)
          .replace('{staff_tag}', '@' + _app.client!.tag);

        if (props.steam_id) {
          r = r.replace('{main_steam_id}', props.steam_id);
        }

        if (props.steam_name) {
          r = r.replace('{main_steam_name}', props.steam_name);
        }
        
        return r;
      });
    };

    if (!props.steam_id) {
      return fillReasons(props.check ? _court.court.options.check_verdicts : _court.court.options.ban_reasons);
    }

    return fillReasons(_court.court.options.ban_mass_reasons);
  });
  
  const suggester = (value: string) => {
    const filtered = computedAvailableReasons.value.filter(v => {
      return (v.toLowerCase().includes(value.toLowerCase())) && value.toLowerCase() != v.toLowerCase();
    });

    return filtered;
  };

  const reason = defineModel<string | undefined>();
</script>

<template>
  <div>
    <p class="label">{{ t('modal.ban.general.reason.title') }}</p>
    <SuggesterInput
      v-model="reason"
      :icon="SelectIcon"
      :suggester="suggester"
      :compiler="(value) => (value)"
      class="!p-2"
      :placeholder="t('modal.ban.general.reason.subtitle')"
      insta-suggest
    >
      <template #suggest="{ value }">
        <div class="suggest">
          <p>{{ value }}</p>
        </div>
      </template>
    </SuggesterInput>
  </div>
</template>