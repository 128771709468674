import { ExtendedMenuItem } from "@/components/quality/menu-extended/MenuExtended.vue";
import { useContextMenu } from "@/stores/contextmenu.store";
import { TypeTranslateComposer, useLocaleStore } from "@/stores/locale.store";
import { usePluginDownload } from "@court/utils/plugin.utils";

export const GetPluginVariants = (t: TypeTranslateComposer): ExtendedMenuItem[] => ([{
  label : t('plugin-version-default'),
  action: () => {
    usePluginDownload().pluginDownload('default');
  }
}, {
  label : t('plugin-version-devblog-266'), 
  action: () => {
    usePluginDownload().pluginDownload('266');
  }
}, {
  label : t('plugin-version-devblog-261'), 
  action: () => {
    usePluginDownload().pluginDownload('261');
  }
}, {
  label : t('plugin-version-devblog-236'),
  action: () => {
    usePluginDownload().pluginDownload('236');
  }
}]);

export const usePluginMenu = (e: MouseEvent) => {
  const { t } = useLocaleStore();

  useContextMenu().open(e, GetPluginVariants(t));
};