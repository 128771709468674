<script setup lang='ts'>
  import SettingsContainer from '@/components/SettingsContainer.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import SwitchText from '@/components/switch/SwitchText.vue';
  import { usePlayerSidebarStore } from './stores/player.store';
  import { computed } from 'vue';
  import { ExtendedMenuItem } from '@/components/quality/menu-extended/MenuExtended.vue';
  import { useCourtStore } from '@court/stores/court.store';
  import SwitchDropdown from '@/components/switch/SwitchDropdown.vue';
  import BaseTip from '@panel/components/BaseTip.vue';

  const { t } = useLocaleStore();
  
  const _court = useCourtStore();
  const _playerSidebard = usePlayerSidebarStore();

  const serverChoosed = computed(() => {
    if (!_playerSidebard.filters.server_id) {
      return t('player.filters.server-all');
    }

    return _court.serverNameById(_playerSidebard.filters.server_id);
  });

  const serverChooser = computed((): ExtendedMenuItem[] => {
    const res: ExtendedMenuItem[] = [{
      label : t('player.filters.server-all'),
      active: () => _playerSidebard.filters.server_id == 0,
      action: () => {
        _playerSidebard.filters.server_id = 0;
      }
    }];
    
    if (_court.court?.servers) {
      [..._court.court.servers].sort((a,b) => (a.order ?? 0) - (b.order ?? 0)).filter(v => !v.deleted).forEach(v => {
        res.push({
          label : v.name,
          active: () => _playerSidebard.filters.server_id === v.id,
          action: () => {
            _playerSidebard.filters.server_id = v.id;
          }
        });
      });
    }

    return res;
  });
</script>

<template>
  <div class="container">
    <BaseTip
      type="hint"
      disposable="player-sidebar-search"
      class="mb-4"
    >
      {{ t('player-search-tip') }}
    </BaseTip>
    
    <SettingsContainer :title="t('player.filters.title')">
      <SwitchDropdown
        v-model="_playerSidebard.filters.server_id"
        :header="t('player.filters.servers-filter')"
        :choosed="serverChoosed"
        :opts="serverChooser"
      />
      <SwitchText
        v-model="_playerSidebard.filters.hide_offline"
        :header="t('player.filters.hide-offline')"
      />
      <SwitchText
        v-model="_playerSidebard.filters.only_vpn"
        :header="t('player.filters.only_vpn')"
      />
      <SwitchText
        v-model="_playerSidebard.filters.only_report_ignore"
        :header="t('player.filters.only_ignore_reports')"
      />
    </SettingsContainer>
    <SettingsContainer :title="t('player.design.title')">
      <SwitchText
        v-model="_playerSidebard.design.show_license"
        :header="t('player.design.show_client_type')"
      />
      <SwitchText
        v-model="_playerSidebard.design.highlight_vpn"
        :header="t('player.design.highlight_vpn')"
      />
    </SettingsContainer>
  </div>
</template>

<style lang='scss' scoped>
.container {
  @apply bg-grey-900 rounded-md;
  @apply h-full;
  @apply flex flex-col;
  @apply overflow-y-auto;
}
.item {
  @apply flex;
  @apply gap-1;
  @apply bg-white/5;
  @apply rounded-md;
  @apply items-center;
  @apply p-1;
  @apply cursor-pointer;
  @apply text-sm;
  @apply text-grey-400;
}
</style>