import { ClientDto } from "@/api/backend/core/client/client.dto";
import { PlayerFullDto } from "@/api/backend/court/player/player.dto";
import { useConfigStore } from "@/stores/config/config.store";
import { useLocaleStore } from "@/stores/locale.store";

export const useUnknownPlayer = () => {
  const { t } = useLocaleStore();
  const _config = useConfigStore();

  const generate = (steamId: string, steamName?: string, steamAvatar?: string): PlayerFullDto => {
    return {
      steam_id    : steamId,
      steam_name  : steamName || t('general.player-unknown'),
      steam_avatar: steamAvatar ?? _config.Avatars.PlayerNotExistsPlaceholder,
      status      : 'offline'
    } as PlayerFullDto;
  };

  return {
    generate
  };
}; 

export const useUnknownClient = () => {
  const { t } = useLocaleStore();
  const _config = useConfigStore();

  const generate = (): ClientDto => {
    return {
      id    : 0,
      name  : _config.Branding.RustAppName,
      avatar: _config.Branding.RustAppAvatar,
      tag   : 'api',
      bio   : t('rustapp-bio')
    } as ClientDto;
  };

  const generateHiddenModer = (): ClientDto => {
    return {
      id    : 0,
      name  : 'Moder',
      avatar: _config.Avatars.StaffHiddenPlaceholder,
      tag   : 'hidden'
    } as ClientDto;
  };

  return {
    generate,
    generateHiddenModer
  };
}; 