<script setup lang="ts">
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import PlayerAlertTable from './components/PlayerAlertTable.vue';
  import PlayerAlertSidebar from './PlayerAlertSidebar.vue';

  const { t } = useLocaleStore();
</script>

<template>
  <LayoutPage :header="t('player-alert.header')">
    <PlayerAlertTable />

    <template #side-bar>
      <PlayerAlertSidebar />
    </template>
  </LayoutPage>
</template>