<script setup lang='ts'>
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import BansTable from './components/BansTable.vue';
  import { computed, onMounted, ref } from 'vue';
  import SelectInput, { SelectValue } from '@/components/inputs/SelectInput.vue';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { useBanStore } from '@court/stores/ban.store';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const { t } = useLocaleStore();

  const _ban = useBanStore();
  const _panel = usePanelStore();
  const search = ref<string>('');
  const _adaptive = useAdaptiveStore();  

  const selectedProject = ref<number>();

  onMounted(async () => {

    selectedProject.value = _panel.project?.id;
  });

  const values = computed((): SelectValue<number>[] => {
    if (!_ban.subscription) {
      return [];
    }

    const vals = _ban.subscription.filter(v => v.is_public && v.you_are_public).map<SelectValue<number>>(v => {
      return {
        text : v.project_original?.name,
        value: v.project_id_original,
        icon : v.project_original.avatar
      };
    });

    return [
      {
        text : _panel.project?.name,
        value: _panel.project?.id ?? 0,
        icon : _panel.project?.avatar
      },
      ...vals
    ];
  });
</script>

<template>
  <LayoutPage
    :header="t('bans.header')"
    always-absolute
    :default-search="e => search = e"
  >
    <BansTable :search="search" :selected-project="selectedProject" />

    <template #custom>
      <SelectInput
        v-if="selectedProject && values.length > 1"
        v-model="selectedProject"
        :values="values"
        :right="_adaptive.isMobile ? true : false"
        class="select-mobile"
        variant="tonal-dark"
      />
    </template>
  </LayoutPage>
</template>

<style lang='scss' scoped>
.search-input {
  max-width: 240px;
  height: 32px !important;
  &:deep(.input-box) {
    height: 32px !important;
  }
  &:deep(input) {
    @apply px-2 #{!important};
  }
}

:deep(.select-mobile) {
  .activator {
    span {
      display: none;
    }
  }

  .options-list {
    min-width: 150px;
  }
}
</style>