<script lang="ts">
  export type BanServerSuggest = {
    name: string;
    id: number;
  }
</script>

<script setup lang="ts">
  import { defineAsyncComponent } from 'vue';
  import SuggesterInput from '@/components/inputs/SuggesterInput.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useCourtStore } from '@court/stores/court.store';
  import { computed, onMounted, ref, watch } from 'vue';
  import { BanDataStorage } from '../stores/ban.storage';
  import { Svg } from '@src/assets/auto_gen_types.dto';

  const { t } = useLocaleStore();
  const _court = useCourtStore();

  const SelectIcon = defineAsyncComponent(() => import('@src/assets/icons/select.svg'));
  
  const availableServers = computed<Record<string, number>>(() => {
    const obj: Record<string, number> = {
      [t('modal.ban.general.all-servers')]: 0 
    };
 
    if (!_court.court) {
      return obj;
    }

    [..._court.court.servers].sort((a,b) => (a.order ?? 0) - (b.order ?? 0)).filter(v => !v.deleted).forEach(server => {
      obj[server.name] = server.id;
    });
    
    return obj;
  });


  const suggester = (value: string) => {
    const servers = Object.entries(availableServers.value).map<BanServerSuggest>(([name, id]) => {
      return {
        id,
        name,
      };
    });

    return servers.filter(v => v.name.toLowerCase().includes(value.toLowerCase()));
  };

  const middleware = (value: BanServerSuggest) => {
    const condition = (val: BanServerSuggest) => val.id;

    // Если добавляется сервер,а уже выбрано "на всех"
    if (condition(value) && servers.value.some(v => !condition(v))) {
      return [value];
    }

    if (!condition(value) && servers.value.some(v => condition(v))) {
      return [value];
    }
    
    return undefined;
  };

  const servers = ref<BanServerSuggest[]>([
    suggester('')[0]
  ]);

  onMounted(() => {
    if (!storage.value.server_ids.length) {
      return;
    }

    servers.value = storage.value.server_ids.map<BanServerSuggest>(v => {
      const server = _court.court?.servers.find(s => s.id == v);

      return { id: v, name: server?.name ?? t('general.unknown')};
    });
  });

  watch(() => servers.value, () => {
    storage.value.server_ids = servers.value.map(v => v.id).filter(v => v != 0);

    storage.value.updater?.();
  }, { deep: true });

  const storage = defineModel<BanDataStorage>({ required: true });
</script>

<template>
  <div>
    <p class="label">{{ t('modal.ban.general.server.title') }}</p>
    <SuggesterInput
      v-model="servers"
      :icon="SelectIcon"
      disable-input
      :middleware="middleware"
      :suggester="suggester"
      :placeholder="t('modal.ban.general.server.subtitle')"
      insta-suggest
      
      class="server-suggester !p-1.5"
    >
      <template #default="{ value }">
        <p class="!py-1 !px-1.5 text-base leading-5 hover:line-through">{{ value.name }}</p>
      </template>

      <template #suggest="{ value, used }">
        <div class="suggest">
          <p>{{ value.name }}</p>
          <Svg.checkmark v-if="used" class="w-4 fill-grey-50" />
        </div>
      </template>
    </SuggesterInput>
  </div>
</template>