<script lang="ts">
  import { ModalInjectedProps, useModalsStore } from '@/stores/modals.store';
  import { ActivateConfirmModal } from '@src/modals/confirm/ConfirmModal.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { usePermissionsStore } from '@panel/stores/permissions.store';
  import { useTitle } from '@vueuse/core';

  export type PlayerDetailsModalOpts = {
    steamId: string;
    player?: PlayerFullDto;
    defaultSection?: string;
  };

  export const ActivatePlayerDetailsModal = async (steamId: string, player?: PlayerFullDto, defaultSection?: undefined) => {
    const { t } = useLocaleStore();
    const _modal = useModalsStore();
    const _permissions = usePermissionsStore();

    if (!_permissions.can(['Court_PlayerReadDetails'])) {
      ActivateConfirmModal({
        type       : 'warn',
        title      : t('modal.player.no-permissions-title'),
        description: t('modal.player.no-permissions-subtitle'),
      });
      return;
    }

    _modal.open('player', {
      steamId: steamId,
      player : player ?? undefined,
    });
  };
</script>

<script setup lang='ts'>
  import { PlayerFullDto } from '@/api/backend/court/player/player.dto';
  import PlayerDetails from '@court/components/player/details/PlayerDetails.vue';
  import { ref, watch, computed, onMounted } from 'vue';
  import { useIntervalFn, useWindowSize } from '@vueuse/core';

  const { t } = useLocaleStore();
  const { width } = useWindowSize();

  const props = defineProps<PlayerDetailsModalOpts & ModalInjectedProps>();
  const details = ref<InstanceType<typeof PlayerDetails>>();

  const siteTitle = useTitle(null, {
    observe: true
  });

  onMounted(() => {
    updateSiteTitle(props.player?.steam_name);
  });

  watch(() => details.value?.maxActualPlayer?.steam_name, (v) => updateSiteTitle(v), { deep: true });
  watch(() => props.injected.visible, (v) => {
    if (!v) {
      return;
    }

    updateSiteTitle(details.value?.maxActualPlayer?.steam_name);
  });

  const updateSiteTitle = (name?: string) => {
    const title = t('site-title.general', { variable: name ?? t('site-title.variable.loading') });

    if (siteTitle.value == title) {
      return;
    }

    if (!props.injected.visible) {
      return;
    }

    siteTitle.value = title;
  };

  const onLoadError = async () => {
    await new Promise((res) => setTimeout(res, 500));

    props.injected.setAfterClose(() => {
      ActivateConfirmModal({
        type       : 'error',
        title      : t('modal.player.load-fail-title'),
        description: t('modal.player.load-fail-subtitle'),
      }); 
    });

    props.injected.close();
  };

  const mobileWidth = computed(() => props.injected.modal.definition.options?.fullSizeMinWidth ?? 0);

</script>

<template>
  <div class="player-details-wrapper" :class="{'-mobile': width < mobileWidth}">
    <PlayerDetails
      ref="details"
      :initial="{ steamId, player }"
      :mobile-width="mobileWidth"
      :visible="injected.visible"
      broadcast-title
      @load-error="onLoadError"
    />
  </div>
</template>

<style lang='scss' scoped>
.player-details-wrapper {
  // height: max(fit-content, 100svh);
  // max-height: 100vh;
  height: 90svh;

  max-height: 600px;

  width: 100vw;
  max-width: 820px;

  &.-mobile {
    height: 100svh;
    max-height: none;
  }
}
// [device='mobile'] {
//   .player-details-wrapper {
//     height: 100svh;
//   }
// }
</style>