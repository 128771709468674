<script setup lang="ts">
  import { computed } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaEditSettings } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { useCourtStore } from '@court/stores/court.store';

  const props = defineProps<AuditDto<AuditMetaEditSettings>>();

  const { t } = useLocaleStore();

  const _court = useCourtStore();
  const audit = ref<InstanceType<typeof AuditBase>>();

  const actionName = computed(() => {
    switch (props.meta.target) {
      case 'court': {
        return t('audit-command-edit-settings-court');
      }
      case 'discord': {
        return t('audit-command-edit-settings-discord');
      }
      case 'rcc': {
        return t('audit-command-edit-settings-rcc');
      }
      case 'server-delete': {
        return t('audit-command-edit-server-delete');
      }
      case 'server-edit': {
        return t('audit-command-edit-server-edit');
      }
      case 'server-pair': {
        return t('audit-command-edit-server-pair');
      }
    }

    return null;
  });
</script>

<template>
  <AuditBase
    v-if="actionName"
    ref="audit"
    :client_id="client_id"
    :icon="Svg.settings_3"
    icon_styles="fill-grey-500"
    :date="created_at"
    ready
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ actionName }}</span>

    <template v-if="meta.server_id">
      <span class="bright">{{ _court.serverNameById(meta.server_id) }}</span>
    </template>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>