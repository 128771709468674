<script setup lang="ts">
  import { onMounted } from 'vue';
  import AuditBase from './../AuditBase.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { AuditDto, AuditMetaPlayerKick } from '@/api/backend/court/audit/audit.dto';
  import { ref } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import { ActivateClientDetailsModal } from '@src/modals/client-details/ClientDetailsModal.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';

  const props = defineProps<AuditDto<AuditMetaPlayerKick>>();

  const { t } = useLocaleStore();

  const player = ref<PlayerMinimalDto>();

  onMounted(async () => {
    player.value = await useCourtApi().player.browseMinimalBatchLoader.load(props.meta.target_steam_id ?? '');
  });

  const audit = ref<InstanceType<typeof AuditBase>>();
</script>

<template>
  <AuditBase
    ref="audit"
    :client_id="client_id"
    :icon="Svg.kick"
    icon_styles="fill-amber-400"
    :date="created_at"
    :ready="!!player"
  >
    <span class="bright cursor-pointer" @click="() => !!audit?.client ? ActivateClientDetailsModal(client_id, audit.client) : {}">{{ audit?.client?.name }}</span>
    <span>{{ t('audit-kick-player') }}</span>
    <span class="bright cursor-pointer" @click="() => !!player ? ActivatePlayerDetailsModal(player.steam_id) : {}">{{ player?.steam_name }}</span>
    <span>{{ t('audit-kick-player-with-reason') }}</span>
    <span class="light">{{ meta.reason || t('general.unknown') }}</span>
  </AuditBase>
</template>

<style lang="scss" scoped>
</style>