import { useDataStore } from "@/stores/data.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export type PlayerFilters = {
  hide_offline: boolean;
  server_id: number;
  only_vpn: boolean;
  only_report_ignore: boolean;
}
export type PlayerDesign = {
  show_license: boolean;
  highlight_vpn: boolean;
}

export const usePlayerSidebarStore = defineStore('player-sidebar', () => {
  const _data = useDataStore();

  const filters = _data.sync('player_filters', ref<PlayerFilters>({
    hide_offline      : false,
    server_id         : 0,
    only_vpn          : false,
    only_report_ignore: false
  }));
  const design = _data.sync('player_design', ref<PlayerDesign>({
    show_license : true,
    highlight_vpn: true
  }));

  // Auto discard on reload
  filters.value.server_id = 0;
  // Auto discard on reload
  filters.value.only_report_ignore = false;
  // Auto discard on reload
  filters.value.only_vpn = false;

  return { 
    filters,
    design
  };
});