import { PaginatedResponse } from "@/api/backend/pagination.service";
import { AuditBrowseOpts, AuditDto } from "./audit.dto";
import { Axios } from "axios";

export const useCourtAuditApi = (api: Axios) => {
  const browse = async (opts: AuditBrowseOpts) => {
    const result = await api.get<PaginatedResponse<AuditDto>>(`/audit/browse`, {
      params: opts
    });

    return result.data;
  };

  return { browse };
};
