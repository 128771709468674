import { useDataStore } from "@/stores/data.store";
import { defineStore } from "pinia";
import { ref } from "vue";

export type SignagesFilters = {
  only_liked: boolean;
  server_id: number;
}

export const useSignagesStore = defineStore('signages', () => {
  const _data = useDataStore();

  const filters = _data.sync('signages_filters', ref<SignagesFilters>({
    only_liked: false,
    server_id : 0
  }));

  // Auto discard on reload
  filters.value.server_id = 0;
  // Auto discard on reload
  filters.value.only_liked = false;

  return { 
    filters
  };
});