<script lang="ts">
  import DividedCard from '@/components/DividedCard.vue';
  import { ClientDto } from '@/api/backend/core/client/client.dto';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useModalsStore } from '@/stores/modals.store';
  import { useSituationStore } from '@panel/stores/situation.store';
  import { computed } from 'vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import { ActivatePlayerDetailsModal } from '@court/modals/player-details/PlayerDetailsModal.vue';
  import { ref } from 'vue';
  import Loading from '@/components/Loading.vue';
  import { onMounted } from 'vue';
  import { watch } from 'vue';
  import { useCoreApi } from '@/api/backend/core/core.api';
  import { useConfigStore } from '@/stores/config/config.store';

  export type ClientDetailsModalOpts = {
    clientSearch: string | number;
    client?: ClientDto
  }

  export const ActivateClientDetailsModal = async (clientSearch: string | number, client?: ClientDto) => {
    const _modal = useModalsStore();

    _modal.open('staff', { clientSearch, client });
  };
</script>

<script setup lang="ts">
  const props = defineProps<ClientDetailsModalOpts>();

  const { t } = useLocaleStore();
  const _modal = useModalsStore();
  const _situation = useSituationStore();

  const data = ref<ClientDto>();

  onMounted(async () => {
    if (props.client) {
      data.value = props.client;
      return;
    }

    const res = isNaN(+props.clientSearch) ? await useCoreApi().utils.getClientByTag(props.clientSearch.toString()) : await useCoreApi().utils.getClientById(+props.clientSearch);

    data.value = res;
  });

  watch(() => props.client, (v) => data.value = v, { deep: true });

  const splittedBio = computed(() => {
    if (!data.value?.bio) {
      return [];
    }

    let bio = data.value.bio ?? '';

    while (bio.includes('\n\n\n')) {
      bio = bio.replace('\n\n\n', '');
    }
    
    if (!bio.split('').some(v => v != '\n')) {
      return [];
    }

    return bio.split('\n').map(v => v.length == 0 ? ' ' : v);
  });

  const onlineText = computed(() => {
    if (!data.value) {
      return '';
    }

    if (Object.values(_situation.clients).some(v => v == data.value!.id.toString())) {
      return 'онлайн';
    }

    return 'оффлайн';
  });
</script>

<template>
  <div class="client-details-modal">
    <Loading :can-view="!!data" class="overflow-hidden rounded-md" />

    <template v-if="data">
      <div class="header">
        <img
          class="avatar"
          :src="useConfigStore().Urls.Images(data.avatar ?? '')"
        >
        <div class="bg-gradient" />
        <div class="close" @click="_modal.close()">
          <Svg.close />
        </div>
        <p class="name">
          <span class="text-20-500 block">{{ data.name }}</span>
          <span class="opacity-50">{{ onlineText }}</span>
        </p>
      </div>
      <DividedCard>
        <template #default>
          <div class="info">
            <div class="row">
              <span class="text">{{ t('modal.client.tag.label') }}</span>
              <span class="value">{{ '@' + data.tag }}</span>
            </div>

            <div v-if="splittedBio.length" class="row">
              <span class="text">{{ t('modal.client.bio.label') }}</span>
              <div class="bio">
                <template v-for="(value, _) in splittedBio" :key="`${value}-${_}`">
                  <p>{{ value }}</p>
                </template>
              </div>
            </div>

            <div v-if="data.steam" class="row">
              <span class="text">{{ t('modal.client.integrations.label') }}</span>
              <div class="items-row">
                <div
                  class="item"
                  @click="() => ActivatePlayerDetailsModal(data!.steam?.steam_id ?? '')"
                >
                  <ListItem
                    :image-url="data.steam.steam_avatar"
                    :title="'Steam'"
                    :message="data.steam.steam_name"
                    :skeleton="'avatar'"
                  />
                  <Svg.open class="icon" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </DividedCard>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.client-details-modal {
  width: 95vw;

  max-width: 360px;

  min-height: 300px;

  @apply flex flex-col;

  .header {
    @apply relative;
    @apply w-full;
    height: 240px;
    @apply flex-shrink-0;

    .avatar {
      @apply absolute;
      @apply top-0 left-0 w-full h-full;
      @apply object-cover;
      @apply bg-grey-850;
    }

    .bg-gradient {
      @apply absolute;
      @apply top-0 left-0 w-full h-full;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.8) 100%);
    }

    .close {
      @apply absolute;
      @apply top-5 right-5;
      @apply bg-grey-50/25;
      @apply rounded-md;
      @apply cursor-pointer;
      @apply ease-in-out delay-0 duration-100;
      @apply shadow-md;
      @apply backdrop-blur-md;
      -webkit-backdrop-filter: blur(12px);

      svg {
        @apply w-7 fill-grey-50;
        @apply ease-in-out delay-0 duration-100;
      }

      &:hover {
        @apply opacity-75;
      }
    }

    .name {
      @apply absolute;
      @apply bottom-5 left-5;
      @apply text-grey-50;
    }
  }

  .info {
    @apply flex flex-col gap-4;

    .row {
      @apply flex flex-col gap-1;

      .text {
        @apply text-14-400 text-grey-400;
      }
      .value {
        @apply text-16-500 text-grey-50 block;
      }
      .bio {
        @apply overflow-auto max-h-[120px];
        @apply p-2 rounded-md;
        @apply text-grey-50 bg-grey-950;
      }
    }
  }
}
</style>