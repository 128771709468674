<script setup lang="ts">
  import type { CheckDto } from '@/api/backend/court/check/check.dto';
  import { onMounted, onUnmounted, ref } from 'vue';
  import CheckExactLeft from './components/CheckExactLeft.vue';
  import CheckExactRight from './components/CheckExactRight.vue';
  import LayoutPage from '@/components/layout/LayoutPage.vue';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useModalsStore } from '@/stores/modals.store';
  import { usePanelStore } from '@panel/stores/panel.store';
  import { DebugService } from '@/api/backend/debug.service';
  import { CentrifugoSubscribe } from '@/api/centrifugo/centrifugo.dto';
  import Button from '@/components/Button.vue';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useCentrifugoApi } from '@/api/centrifugo/centrifugo.api';

  const props = defineProps<{ id: number} >();

  const { t } = useLocaleStore();

  let timer: NodeJS.Timeout;

  const _panel = usePanelStore();
  const _modal = useModalsStore();
  const check = ref<CheckDto | null>(null);

  const subscriber = ref<CentrifugoSubscribe>();

  onMounted(async () => {
    _modal.close(true);
    
    subscriber.value = useCentrifugoApi().subscribeCheckFinished(_panel.project!.id, (update) => {
      if (update.id !== props.id) {
        return;
      }
      
      DebugService.debug(`Получили обновление проверки по сокетам`, { update });

      check.value = update;
    });
    
    await getCheck();
  });


  const getCheck = async () => {
    check.value = await useCourtApi().check.exact(props.id);
  };

  onUnmounted(() => {
    clearTimeout(timer);

    subscriber.value?.unsubscribe();
  });
</script>

<template>
  <LayoutPage
    :header="t('checks.header')"
    auto-open
    class="layout"
  >
    <CheckExactLeft :check-id="props.id" :check="check" />
    
    <template #side-bar>
      <CheckExactRight :check="check" />
    </template>
    <template #right-icon>
      <Button
        preset="default"
        class="!px-2"
      >
        <Svg.user />
      </Button>
    </template>
  </LayoutPage>
</template>

<style lang="scss" scoped>
.layout {
  @apply overflow-hidden;
  :deep() {
    .side-bar {
      @apply p-0;
    }
  }
}

.right-icon {
  @apply w-7;
  @apply fill-grey-600;

  &:hover {
    @apply fill-grey-400;
  }
}
</style>
