import { RouteRecordRaw } from "vue-router";
import SettingsClientPage from "./pages/client/SettingsClientPage.vue";
import SettingsProjectPage from "./pages/project-settings/SettingsProjectPage.vue";
import BillingPage from "./pages/payments/PaymentsPage.vue";


export enum SettingsRoutes {
  About = 'about',
  Clients = 'clients',
  Billing = 'billing'
}

export const SettingsRouter: RouteRecordRaw[] = [
  {
    path     : 'settings/about',
    name     : SettingsRoutes.About,
    component: SettingsProjectPage,
  },
  {
    path     : 'settings/clients',
    name     : SettingsRoutes.Clients,
    component: SettingsClientPage,
  },
  {
    path     : 'settings/billing',
    name     : SettingsRoutes.Billing,
    component: BillingPage,
  }
];


