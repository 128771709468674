<script setup lang="ts">
  import type { PlayerTeamDto, PlayerMinimalDto } from '@/api/backend/court/player/player.dto';
  import Loading from '@/components/Loading.vue';
  import { onMounted, ref } from 'vue';
  import PlayerInline from '@court/components/player/PlayerInline.vue';
  import PlusIcon from '@src/assets/icons/plus.svg';
  import MinusIcon from '@src/assets/icons/minus.svg';
  import TooltipBase from '@/components/tooltip/TooltipBase.vue';
  import { useTimeStore } from '@/stores/time.store';
  import { useCourtStore } from '@court/stores/court.store';
  import IntersectionObserver from '@/components/IntersectionObserver.vue';
  import { PaginationService } from '@/api/backend/pagination.service';
  import { useLocaleStore } from '@/stores/locale.store';
  import EmptyImage from '@/components/quality/empty/EmptyImage.vue';
  import CourtNoSubscriptionContent from '@court/components/no-subscription/CourtNoSubscriptionContent.vue';
  import { useCourtApi } from '@/api/backend/court/court.api';
  import { useAdaptiveStore } from '@/stores/adaptive.store';

  const _adaptive = useAdaptiveStore();

  const { t } = useLocaleStore();

  const _time = useTimeStore();
  const _court = useCourtStore();

  const props = defineProps<{ 
    steamId: string 
  }>();
  const teamHistories = ref<PlayerTeamDto[] | null>(null);

  const loader = new PaginationService((page, limit) => useCourtApi().player.browsePlayerTeam(props.steamId, {
    page,
    limit
  }), 20);

  onMounted(() => next());

  const next = async () => {
    try {
      const { results } = await loader.next();

      if (!teamHistories.value) {
        teamHistories.value = [];
      }

      teamHistories.value?.push(...results);
    }
    catch (err: any) {
      if (err?.message?.includes(' опция доступна начиная с тарифа')) {
        teamHistories.value = [{
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : [{
            steam_id    : '76561198121100397',
            steam_name  : 'Pablo Escobar',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077475023-49ad213d101fe749ee0d3b01.png'
          }, {
            steam_id    : '76561198121100397',
            steam_name  : 'Hougan',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Olkuts',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077691575-bb998ec3436e6a48be8823fb.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Xacku',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077705995-e570bc4dbba9a0c5dd64b1a4.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Oleg?',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          }]
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : [{
            steam_id    : '76561198121100397',
            steam_name  : 'Olkuts',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077691575-bb998ec3436e6a48be8823fb.png'
          }, {
            steam_id    : '76561198121100397',
            steam_name  : 'Hougan',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          }]
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : []
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : [{
            steam_id    : '76561198121100397',
            steam_name  : 'Hougan',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          }, {
            steam_id    : '76561198121100397',
            steam_name  : 'Xacku',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077705995-e570bc4dbba9a0c5dd64b1a4.png'
          }]
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : []
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : [{
            steam_id    : '76561198121100397',
            steam_name  : 'Xacku',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077705995-e570bc4dbba9a0c5dd64b1a4.png'
          }, {
            steam_id    : '76561198121100397',
            steam_name  : 'Hougan',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          }]
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : [{
            steam_id    : '76561198121100397',
            steam_name  : 'Oleg?',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077463398-a5be56b0f8679b58e7434ac7.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Sanya',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077464792-4762b8bd8065a3a56d6df37f.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Volodya',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077502013-50d532c0ef475d39e5456aeb.png'
          },{
            steam_id    : '76561198121100397',
            steam_name  : 'Penis Detrov',
            steam_avatar: 'https://s3.rustapp.io/avatar-user/1700077525656-0b03a107bce5ada1c1da0b89.png'
          }]
        }, {
          server_id   : _court.court?.servers[0].id ?? 0,
          created_at  : Date.now(),
          last_seen_at: Date.now(),
          friends     : []
        }];
        return;
      }
    }
  };

  const isNew = (steam_id: string, team?: PlayerMinimalDto[]) => {
    if (!team) {
      return true;
    }

    return !team.some((v) => v.steam_id === steam_id);
  };

  const missing = (team1: PlayerMinimalDto[], team2?: PlayerMinimalDto[]) => {
    if (!team2) {
      return [];
    }

    return team2.filter((v) => !team1?.some((t) => t.steam_id === v.steam_id));
  }; 
</script>

<template>
  <CourtNoSubscriptionContent color="bg-grey-900/80" type="TeamHistory">
    <template v-if="teamHistories && teamHistories.length === 0">
      <div class="empty-state-container">
        <EmptyImage
          type="empty"
          :title="t('modal.player.teamhistory.empty.title')"
          :subtitle="t('modal.player.teamhistory.empty.subtitle')"
        />
      </div>
    </template>
    <template v-else-if="teamHistories && teamHistories.length === 1 && teamHistories[0].friends.length == 0">
      <div class="empty-state-container">
        <EmptyImage
          type="empty"
          :title="t('modal.player.teamhistory.empty-solo.title')"
          :subtitle="t('modal.player.teamhistory.empty-solo.subtitle', { time: _time.format(teamHistories[0].created_at, 'D MMM HH:mm', true)})"
        />
      </div>
    </template>
    <div v-else class="content-container">
      <Loading :can-view="teamHistories != null" class="loading-overlay" />

      <template v-if="teamHistories != null">
        <template
          v-for="(team, key) in [...teamHistories.sort((a, b) => b.last_seen_at - a.last_seen_at)]"
          :key="team.server_id + team.created_at"
        >
          <div class="team-history-container">
            <div
              v-if="key != teamHistories.length - 1"
              class="separator"
              :style="{ height: key == teamHistories.length - 1 ? '0px' : 'calc(100% - 20px)' }"
            />

            <div class="icon-container">
              <div
                class="status-icon"
                :class="[
                  key == 0 ? 'status-online' : 'status-offline',
                ]"
              />
            </div>
            <div class="details-container">
              <div class="details-header">
                <p class="date-info">
                  {{ t('general.from') }} <span class="date">{{ _time.format(team.created_at, 'D MMM YY HH:mm') + ' ' }}</span>

                  <template v-if="key !== 0">
                    {{ t('general.to') }} <span class="date">{{ _time.format(team.last_seen_at, 'D MMM YY HH:mm') }}</span>
                  </template>
                </p>
                <p
                  v-if="!_adaptive.isMobile"
                  class="server-name"
                >
                  {{ _court.serverNameById(team.server_id) }}
                </p>
                <span
                  v-else
                  class="server-name-mobile"
                >
                  {{ t('general.server') + ':' }}
                  <span class="server-name-highlight">{{ _court.serverNameById(team.server_id) }}</span>
                </span>
              </div>
              <div v-if="team.friends.length > 0" class="friends-container">
                <template v-for="player in team.friends" :key="player.steam_id">
                  <div class="team-player">
                    <PlayerInline
                      :steam_id="player.steam_id"
                      :steam_name="player.steam_name"
                      :steam_avatar="player.steam_avatar ?? ''"
                      type="small"
                    />
                    <TooltipBase
                      v-if="team.server_id == teamHistories[key+1]?.server_id && isNew(player.steam_id, teamHistories[key + 1]?.friends)"
                      trigger="hover"
                      distance="14"
                    >
                      <PlusIcon class="icon-joined" />
                      <template #content>
                        <p class="tooltip-text">
                          {{ t('general.joined') }}
                        </p>
                      </template>
                    </TooltipBase>
                  </div>
                </template>
                <template 
                  v-if="team.server_id === teamHistories[key+1]?.server_id"
                >
                  <template
                    v-for="player in missing(team.friends, teamHistories[key + 1]?.friends)"
                    :key="player.steam_id"
                  >
                    <div class="team-player-missing">
                      <PlayerInline
                        :steam_id="player.steam_id"
                        :steam_name="player.steam_name"
                        :steam_avatar="player.steam_avatar ?? ''"
                        type="small"
                      />
                      <TooltipBase
                        trigger="hover"
                        distance="14"
                      >
                        <MinusIcon class="icon-left" />
                        <template #content>
                          <p class="tooltip-text">
                            {{ t('general.left') }}
                          </p>
                        </template>
                      </TooltipBase>
                    </div>
                  </template>
                </template>
              </div>
              <div v-else>
                <p class="solo-badge">{{ t('team-history.solo') }}</p>
              </div>
            </div>
          </div>
        </template>
      
        <IntersectionObserver class="intersection-observer" :next="next" />
      </template>
    </div>
  </CourtNoSubscriptionContent>
</template>

<style lang="scss" scoped>
.empty-state-container {
  @apply flex flex-col justify-center items-center h-full;
}

.content-container {
  @apply h-full w-full relative;
}

.loading-overlay {
  @apply bg-transparent;
}

.team-history-container {
  @apply flex gap-4 relative w-full overflow-hidden pb-8;
}

.separator {
  @apply absolute bg-grey-850;
  width: 2px;
  left: 4px;
  top: 21px;
}

.icon-container {
  @apply h-full;
  padding-top: 6px;
}

.status-icon {
  @apply rounded-full border-2;
  height: 10px;
  width: 10px;
}

.status-online {
  @apply bg-ra-online border-none;
}

.status-offline {
  @apply border-grey-600 bg-grey-900;
}

.details-container {
  @apply w-full;
}

.details-header {
  @apply flex justify-between items-center;
  @apply font-medium mb-2;
}

[device='mobile'] {
  .details-header {
    @apply flex flex-col items-start;
    @apply font-medium mb-2;
  }
} 

.date-info {
  @apply flex items-center gap-1;
  @apply text-grey-400 text-sm truncate;
}

.date {
  @apply text-grey-50;
}

.server-name {
  @apply truncate;
  @apply text-primary-700 text-sm font-medium;
  max-width: 150px;
}

.server-name-mobile {
  @apply truncate;
  @apply text-sm text-grey-400 font-medium lowercase;
  max-width: 150px;
}

.server-name-highlight {
  @apply text-primary-700 normal-case;
}

.friends-container {
  @apply flex flex-wrap gap-1 mt-2;
}

.team-player {
  @apply flex gap-1.5 items-center;
  @apply px-1.5 py-1 rounded-md;
  @apply font-medium text-base;
  @apply transition-all duration-200 ease-out;
  @apply text-grey-50;
  @apply bg-grey-800 hover:opacity-80;
}

.team-player-missing {
  @apply flex gap-1.5 items-center;
  @apply px-1.5 py-1 rounded-md;
  @apply font-medium text-base;
  @apply transition-all duration-200 ease-out;
  @apply text-grey-50;
  @apply bg-grey-850 hover:opacity-80;
}

.icon-joined {
  @apply bg-lime-300/25 rounded fill-lime-300;
  width: 1.25rem;
  height: 1.25rem;
}

.icon-left {
  @apply bg-orange-400/25 rounded fill-orange-400;
  width: 1.25rem;
  height: 1.25rem;
}

.tooltip-content {
  @apply py-1.5 px-2;
}

.solo-badge {
  @apply w-fit;
  @apply bg-grey-850;
  @apply text-grey-500;
  @apply px-1.5 py-1;
  @apply rounded-md;
}

.intersection-observer {
  @apply z-10 pointer-events-none;
}
</style>
