<script lang="ts">
  import { useModalsStore } from '@/stores/modals.store';
  import { Svg } from '@src/assets/auto_gen_types.dto';
  import ListItem from '@/components/quality/containers/ListItem.vue';
  import ModalMenu, { ModalMenuHeaderDef} from '@/components/modal-menu/ModalMenu.vue';
  import { useAppStore } from '@/stores/app.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import ClientEditModalIntegrations from './tabs/ClientEditModalIntegrations.vue';
  import ClientEditModalSecurity from './tabs/ClientEditModalSecurity.vue';
  import ClientEditModalProjects from './tabs/ClientEditModalProjects.vue';
  import ClientEditModalInterface from './tabs/ClientEditModalInterface.vue';
  import { useConfigStore } from '@/stores/config/config.store';

  export type ClientInfoEditModalOpts = {
  }

  export const ActivateClientInfoEditModal = async (props: ClientInfoEditModalOpts) => {
    const _modal = useModalsStore();

    _modal.open('client-info-edit', {});
  };
</script>

<script setup lang="ts"> 
  import { computed } from 'vue';  
  import ClientEditModalProfile from './tabs/ClientEditModalProfile.vue';

  const _app = useAppStore();
  const { t } = useLocaleStore();
  
  type HeaderValues = 'profile' | 'integrations' | 'interface' | 'security' | 'projects';

  const headers1 = computed<Partial<Record<HeaderValues, ModalMenuHeaderDef<HeaderValues>>>>(() => {
    return {
      profile: {
        value: 'profile',
        text : t('modal.client.profile.title'),
        icon : Svg.user 
      }, 
      integrations: {
        value: 'integrations',
        text : t('modal.client.integrations.title'),
        icon : Svg.folder_link 
      }, 
      interface: {
        value: 'interface',
        text : t('modal.client.interface.title'),
        icon : Svg.layout_sidebar 
      }, 
      security: {
        value: 'security',
        text : t('modal.client.security.title'),
        icon : Svg.passkeys 
      },
      projects: {
        value: 'projects',
        text : t('modal.client.project.title'),
        icon : Svg.cube 
      }
    };
  });

</script> 
 
<template> 
  <ModalMenu
    class="client-edit-modal"
    :headers="Object.values(headers1)"
    :mobile-width="700"
  >
    <template #header>
      <ListItem
        :image-url="useConfigStore().Urls.Images(_app.client?.avatar ?? '')"
        :title="_app.client?.name"
        :message="'@' + _app.client?.tag"
        :skeleton="'avatar'"
      />
    </template>
    <template #profile="{isMobile}">
      <ClientEditModalProfile :mobile="isMobile" />
    </template>
    <template #integrations="{isMobile}">
      <ClientEditModalIntegrations :mobile="isMobile" />
    </template>
    <template #security="{isMobile}">
      <ClientEditModalSecurity :mobile="isMobile" />
    </template>
    <template #interface="{isMobile}">
      <ClientEditModalInterface :mobile="isMobile" />
    </template>
    <template #projects="{isMobile}">
      <ClientEditModalProjects :mobile="isMobile" />
    </template>
  </ModalMenu>
</template> 
 
<style lang="scss" scoped> 
.bottom {
  @apply p-4 rounded-md;
  @apply bg-grey-950;
  @apply border border-red-500;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(255, 255, 255, 0.02) 20px, rgba(255, 255, 255, 0.02) 40px);
  .title {
    @apply text-base font-normal text-grey-50 mb-1;
  }
  .subtitle {
    @apply text-base font-normal;
    @apply text-grey-400;
  }
}

.links {
  @apply grid grid-cols-2 gap-2;

  :deep(.image) {
    img {
      @apply rounded-md;
    }
  }

  .item {
    @apply cursor-pointer;
    @apply p-2;
    @apply rounded-md;

    &:hover {
      @apply bg-grey-900;
    }
  }
}

.client-edit-modal {
  width: 95vw;
  max-width: 800px;

  min-height: 600px;
  height: auto;
  max-height: min(600px, 85vh);
  
  &:deep(.divided-card) { 
    .content { 
      overflow-y: hidden; 
    } 
  }

  .client {
    @apply flex items-center gap-3;
    .name {
      @apply font-semibold text-grey-50 text-lg leading-tight;
    }
    .mail {
      @apply text-grey-300 font-normal;
    }
  }

  &:deep(.divided-card) { 
    .content { 
      overflow-y: hidden; 
    } 
  }
}
label { 
  @apply inline-flex justify-between w-full mb-1; 
  @apply text-grey-400; 
}
.change-pass-button {
  @apply text-16-500;
  @apply text-primary-700;
  @apply cursor-pointer;
  &:hover {
    @apply text-primary-600;
  }
}
.avatar {
  @apply cursor-pointer;
  
  &:hover {
    @apply opacity-75;
  }
}

.button-truncated {
  &:deep(.wrapper) {
    @apply truncate;
    p {
      @apply truncate;
      @apply w-full;
    }
  }  
}

.integration-row {
  @apply overflow-hidden; 
  @apply rounded-md;
  @apply bg-grey-850;
  @apply border border-grey-800;

  .item {
    @apply flex justify-between items-center;
    @apply p-2;
    @apply bg-grey-800;

    .icon {
      @apply w-5 mr-2;
      @apply fill-grey-500;

      &:hover {
        @apply fill-grey-200;
      }
    }
  }

  .rap {
    @apply flex flex-col gap-1;
    @apply p-2;
    @apply text-grey-200 text-sm;
  }
}
</style>