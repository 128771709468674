import { ComposerTranslation, createI18n, useI18n } from 'vue-i18n';

import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import en from '@src/assets/locales/en.json';
import ru from '@src/assets/locales/ru.json';
import uk from '@src/assets/locales/uk.json';
import { useDataStore } from './data.store';
import dayjs from 'dayjs';
import { ampli } from '@src/ampli';

const customRule = (choice: number, choicesLength: number) => {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
};

export const createLocale = () => {
  let defaultLang = 'en';

  if (navigator.language === 'ru-RU' || navigator.language === 'ru') {
    defaultLang = 'ru';
  }

  return createI18n<[typeof ru], 'ru' | 'en' | 'uk'>({
    legacy          : false,
    locale          : defaultLang,
    allowComposition: true,
    fallbackLocale  : 'en',
    pluralRules     : {
      ru: customRule,
      uk: customRule
    },
    globalInjection: true,
    messages       : {
      en,
      ru,
      uk
    },
  });
};

const useLocale = () =>
  useI18n<[typeof ru], 'ru' | 'en' | 'uk'>({
    useScope: 'global',
    messages: {
      ru,
      en,
      uk
    },
  });
  
export type AllowedLanguage = 'ru' | 'en' | 'uk';

export interface TypeTranslateComposer extends ComposerTranslation<[typeof ru], 'ru' | 'en' | 'uk'> {}

export const useLocaleStore = defineStore('lang', () => {
  const { locale, t } = useLocale();
  const _data = useDataStore();

  let defaultLang: 'ru' | 'en' = 'en';

  if (navigator.language === 'ru-RU' || navigator.language === 'ru') {
    defaultLang = 'ru';
  }

  const lang = ref<AllowedLanguage>(defaultLang);
  
  _data.sync('lang', lang);
  
  watch(() => lang.value, (v) => {
    dayjs.locale(v);

    ampli.client.setGroup('language', v);
  });

  dayjs.locale(lang.value);

  ampli.client.setGroup('language', lang.value);

  const set = (value: AllowedLanguage) => {
    lang.value = value;
    
    // Update VueI18n
    locale.value = value;

    return value;
  };
  
  const isEng = computed(() => lang.value == 'en');
  const isRu = computed(() => lang.value == 'ru');
  const isUk = computed(() => lang.value == 'uk');

  set(lang.value);

  return { lang, set, isEng, isRu, isUk, t };
});